/** @format */

import Component from '@ember/component';

export default Component.extend({
	actions: {
		showEditModal(client) {
			if (this.get('tableActions.toggleEditClientModal')) {
				this.get('tableActions.toggleEditClientModal')(client);
			}
		},

		showDeleteModal(client) {
			if (this.get('tableActions.toggleDeleteClientModal')) {
				this.get('tableActions.toggleDeleteClientModal')(client);
			}
		},
	},
});
