/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	shouldBackgroundReloadAll(/* store, snapshotsArray */) {
		//? Treatment-types are a static set of data and
		//? does not change often, if ever. Therefore it
		//? does not need to background reload every time
		//? this model is loaded/called. It is best to just
		//? use the cached data once called the first time.
		return false;
	},
});
