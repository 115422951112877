/** @format */

import { inject as service } from '@ember/service';
import ApplicationAdapter from './application';
import { underscore } from '@ember/string';
import { computed } from '@ember/object';

export default ApplicationAdapter.extend({
	currentUser: service('current-user'),
	firmOrg: computed('currentUser.user.{firm,organization}', function () {
		//* If the currentUser belongs to an Org then firmOrg will equal "organization" otherwise default to "firm"
		return this.get('currentUser.user.organization') ? 'organization' : 'firm';
	}),
	pathForType(type) {
		return underscore(type);
	},

	urlForFindRecord() {
		const id = this.get(`currentUser.user.${this.firmOrg}.id`);
		return `${this.host}/${this.firmOrg}s/${id}/relationships/custom_branding`;
	},
});
