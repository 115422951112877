/** @format */

import ApplicationAdapter from './application';
import { underscore } from '@ember/string';

export default ApplicationAdapter.extend({
	pathForType(type) {
		return underscore(type);
	},
});
