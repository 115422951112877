/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

@classic
export default class UsersEditCell extends Component {
	attributeBindings = ['data-test-id'];
	'data-test-id' = 'component';

	@service permissions;
}
