/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { dateDisplay } from 'case-status/helpers/date-display';
import { inject as service } from '@ember/service';

export default Model.extend({
	company: service(),

	title: attr('string'),
	content: attr('string'),
	createdAt: attr('date'),
	updatedAt: attr('date'),

	typeAccess: attr('string'),
	isDefault: attr('boolean'),
	shortCodes: attr(),
	templateType: attr('string'),
	isSystem: attr('boolean'),
	shortcodesNotSupported: attr('boolean'),

	readableCreatedBy: computed(
		'createdAt',
		'createdBy.name',
		'company.info',
		'isSystem',
		function () {
			let readableCreatedBy = 'Created';

			const formattedDate = this.createdAt
				? dateDisplay([this.createdAt, this.company.info, false])
				: null;

			if (this.createdBy.get('name')) {
				readableCreatedBy += ` by ${this.createdBy.get('name')}`;
			} else if (this.isSystem) {
				readableCreatedBy += ` by System`;
			}

			if (formattedDate && formattedDate != 'Invalid Date') {
				readableCreatedBy += ` on ${formattedDate}`;
			}

			return readableCreatedBy === 'Created' ? null : readableCreatedBy;
		},
	),

	readableUpdatedBy: computed(
		'updatedAt',
		'updatedBy.name',
		'company.info',
		function () {
			let readableUpdatedBy = 'Updated';

			const formattedDate = this.updatedAt
				? dateDisplay([this.updatedAt, this.company.info, false])
				: null;

			if (this.updatedBy.get('name')) {
				readableUpdatedBy += ` by ${this.updatedBy.get('name')}`;
			}

			if (formattedDate && formattedDate != 'Invalid Date') {
				readableUpdatedBy += ` on ${formattedDate}`;
			}

			return readableUpdatedBy === 'Updated' ? null : readableUpdatedBy;
		},
	),

	//* Relationships
	firm: belongsTo('firm'),
	createdBy: belongsTo('user'),
	updatedBy: belongsTo('user'),
	fileAttachment: belongsTo('file'),
});
