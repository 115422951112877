/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import Table from 'ember-light-table';
import clickableRow from '../mixins/component/clickable-row';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend(clickableRow, {
	company: service(),
	columns: computed(function () {
		return [
			{
				label: ``,
				valuePath: 'isSelected',
				width: '100px',
				cellComponent: 'cell-select-user',
				sortable: false,
			},
			{
				label: 'Client',
				valuePath: 'name',
				sortable: false,
				cellClassNames: 'bold',
			},
			{
				label: 'Phone Number',
				valuePath: 'cellPhone',
				sortable: false,
			},
			{
				label: `Open ${capitalize(dynamicCaseLabel([this.company, true]))}`,
				valuePath: 'openCaseIds',
				cellComponent: 'client-cases-cell',
				sortable: false,
			},
		];
	}),

	model: null,
	table: null,

	init() {
		this._super(...arguments);

		let table = Table.create({
			columns: this.columns,
			rows: this.model,
			enableSync: true,
		});
		this.set('table', table);
	},

	actions: {
		onScroll(pos) {
			//For some reason onScrolledToBottom is not firing so we will manually track when to get more clients
			const page = this.page ? this.page : 1;
			if (pos >= 460 * page) {
				this.fetchMoreClients();
			}
		},
		onScrolledToBottom() {
			this.fetchMoreClients();
		},
	},
});
