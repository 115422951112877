/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	//* Make the rendered element a span tag instead of the default 'div' tag
	tagName: 'span',

	//* Bind onClick attribute to onClick property
	attributeBindings: ['onClick', 'data-test-id'],
	'data-test-id': 'content',

	//* Give the rendered element the class "link-like" so it will act like a anchor element without having an href to potentially mess with routing
	classNames: 'like-link',

	//* This will bind a function that was passed into the cs-table's cellActions to the onClick Attribute of the rendered element
	//* if it is not a function that was passed or do to unintentional reference mutation, we return null which will prevent the
	//* rendered element from having the onClick attribute bound to it
	//* The function requires the rawData from the model used to generate this element so as to set it as the selected request to view
	//* So we call on the cell.rawData in the params of the function inside an arrow function to store the required data inside before calling it
	onClick: computed('cell.cellActions.toggleShowRequest', function () {
		if (
			this.cell &&
			this.cell.cellActions &&
			typeof this.cell.cellActions.toggleShowRequest == 'function'
		)
			return () => this.cell.cellActions.toggleShowRequest(this.cell.rawData);
		return null;
	}),
});
