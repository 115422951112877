/** @format */

import Mixin from '@ember/object/mixin';
import { sort } from '@ember/object/computed';
import { observer } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Mixin.create({
	infinity: service(),
	notifications: service(),
	notificationsSort: ['createdAt:desc'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sortedNotifications: sort('inbox', 'notificationsSort'),
	currentFilter: 'All',
	currentFilterObserver: observer('currentFilter', function () {
		if (this.model) {
			debounce(this, this.reloadModel, 250);
		}
	}),

	async reloadModel() {
		if (!this.model) return false;
		this.set('loading', true);

		const currentFilter = this.currentFilter || '';
		const filterOptions = this.filterOptions || [];
		const notifOpts = {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
		};

		if (filterOptions.includes(currentFilter)) {
			notifOpts[`filter[${currentFilter.toLowerCase()}]`] = true;
		} else {
			var errorMsg = `Sorry, but there was an error trying to use the ${currentFilter} filter. Please refresh and try again. If the issue persists, please contact support.`;
			this.notifications.error(errorMsg, {
				canClose: true,
				autoClear: true,
				clearDuration: 10000,
			});
		}

		var getRegularNotifications = true;
		var getChatNotifications = true;

		if (currentFilter === 'Collaboration') {
			// Only show Chat Notifications so we flush the regular Notifications out.
			getRegularNotifications = false;
		} else if (currentFilter === 'Client') {
			// Client only shows Notifications "from" Clients
			// thus we must flush out the Chat Notifications
			getChatNotifications = false;
		}

		if (getRegularNotifications) {
			const notifications = await this.infinity.model(
				'notification',
				notifOpts,
			);
			this.infinity.replace(this.get('model.notifications'), notifications);
		} else {
			this.infinity.flush(this.get('model.notifications'));
		}

		if (getChatNotifications) {
			const chatNotifications = await this.infinity.model(
				'chat-notification',
				notifOpts,
			);
			this.infinity.replace(
				this.get('model.chatNotifications'),
				chatNotifications,
			);
		} else {
			this.infinity.flush(this.get('model.chatNotifications'));
		}

		// The All shows All
		// No filter at all shows Unread Notifications and Chat Notification (pending)

		next(() => {
			this.set('loading', false);
		});
	},

	actions: {
		updateFilter(option) {
			this.set('currentFilter', option);
		},
	},
});
