/**
 * Date Range with Shortcuts
 *    created by Matthew Aderhold (AderCode) on October 14th, 2019
 *
 *   Accepted Params
 *   - minDate
 *     - Used to set the minimum date allowed for the date picker (idea is not to let them choose a date before their firm was even created, but might be needed to go further back if information was imported with an earlier date)
 *   - startDate
 *     - This is the value that will be updated when the date picker is used to set a startDate
 *   - endDate
 *     - This is the value that will be updated when the date picker is used to set a endDate
 *   - onStartDateChange
 *     - This accepts an action to be called when startDate is changed and is passed the newly selected date
 *   - onEndDateChange
 *     - This accepts an action to be called when endDate is changed and is passed the newly selected date
 *
 * @format
 */

import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Component.extend({
	customBranding: service('custom-branding'),

	selected: 'Mtd',
	maxDate: null,
	startDate: null,
	startDateVal: null,
	endDate: null,
	endDateVal: null,

	datesObserver: observer('startDate', 'endDate', function () {
		const startDate = this.startDate;
		const startDateVal = this.startDateVal;
		const endDate = this.endDate;
		const endDateVal = this.endDateVal;

		if (moment(startDate).dayOfYear() !== moment(startDateVal).dayOfYear()) {
			this.set('startDateVal', startDate);
		}

		if (moment(endDate).dayOfYear() !== moment(endDateVal).dayOfYear()) {
			this.set('endDateVal', endDate);
		}
	}),

	selectedObserver: observer('selected', function () {
		const selected = this.selected;
		const minDate = this.minDate;
		const maxDate = this.maxDate;
		const quarter = moment(maxDate).quarter();
		let newStartDate;

		switch (selected) {
			case '7d':
				newStartDate = moment(maxDate).subtract(1, 'week');
				break;
			case '30d':
				newStartDate = moment(maxDate).subtract(30, 'day');
				break;
			case '1y':
				newStartDate = moment(maxDate).subtract(1, 'year');
				break;
			case 'Mtd':
				newStartDate = moment(maxDate).date(1);
				break;
			case 'Qtd':
				switch (quarter) {
					case 1:
						newStartDate = moment(maxDate).month(0).date(1);
						break;
					case 2:
						newStartDate = moment(maxDate).month(3).date(1);
						break;
					case 3:
						newStartDate = moment(maxDate).month(6).date(1);
						break;
					case 4:
						newStartDate = moment(maxDate).month(9).date(1);
						break;
				}
				break;
			case 'Ytd':
				newStartDate = moment(maxDate).dayOfYear(1);
				break;
			case 'All':
				this.onStartDateChange(minDate);
				this.onEndDateChange(maxDate);
				return;
			case 'Custom':
				return;
		}

		this.set('startDate', new Date(newStartDate.toLocaleString()));

		this.onStartDateChange(this.startDate);
		this.onEndDateChange(maxDate);
	}),

	init() {
		this._super(...arguments);
		const startDate = this.startDate;
		const endDate = this.endDate;
		this.set('maxDate', new Date());

		if (startDate) {
			this.set('startDateVal', moment(startDate, 'MM-DD-YYYY').toDate());
		}

		if (endDate) {
			this.set('endDateVal', moment(endDate, 'MM-DD-YYYY').toDate());
		}
	},

	actions: {
		updateShortcut(selection) {
			this.set('selected', selection);
		},

		onStartDateChangeHandler(params) {
			if (moment(this.startDate).dayOfYear() === moment(params).dayOfYear())
				return;

			this.set('selected', 'Custom');
			this.onStartDateChange(params);
			this.onEndDateChange(this.endDate);
		},

		onEndDateChangeHandler(params) {
			if (moment(this.endDate).dayOfYear() === moment(params).dayOfYear())
				return;

			this.set('selected', 'Custom');
			this.onEndDateChange(params);
		},
	},
});
