/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import Errors from '../../../../constants/errors';
import { hash } from 'rsvp';
import CompanySettingsRouteMixin from '../../../../mixins/route/settings/company';

export default Route.extend(CompanySettingsRouteMixin, {
	store: service(),
	currentUser: service(),
	session: service(),
	ajax: service(),

	model() {
		return hash({
			organization: this.store.findRecord(
				'organization',
				this.get('currentUser.user.organization.id'),
			),
		});
	},

	actions: {
		sendInvite(user, result) {
			const store = this.store;
			const roleModel = user.model.toLowerCase();
			const controller = this.controller;

			let newUser = store.createRecord(roleModel, user);

			controller.set('inviteSentSuccess', false);
			controller.set('inviteSentErrors', null);

			newUser
				.save()
				.then(() => {
					controller.set('inviteSentSuccess', true);
					controller.set(
						'inviteSentSuccessMessage',
						`We sent a link to ${user.emailAddress}, that they can use to register for your organization.`,
					);
					result.resolve();
				})
				.catch((errors) => {
					controller.set('inviteSentErrors', Errors.mapResponseErrors(errors));
					result.reject();
				});
		},

		deleteUser(user, result) {
			user
				.destroyRecord()
				.then(() => {
					result.resolve();
				})
				.catch((errors) => {
					result.reject(errors);
				});
		},
	},
});
