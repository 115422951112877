import { helper } from '@ember/component/helper';
import { safeHtml } from 'case-status/helpers/safe-html';
import { truncate } from 'case-status/helpers/truncate';

export function linkDisplay([url, classes, truncateLimit]) {
	if (!url) return '';

	let formattedUrl = `${url}`; //String copy to prevent mutating the original.
	let formattedLink;
	const isHttp = formattedUrl.indexOf('http:');
	const isHttps = formattedUrl.indexOf('https:');

	if (isHttp === -1 && isHttps === -1) {
		// Defaulting to http because non ssl sites don't redirect from https to http but most ssl sites do redirect from http to https
		formattedUrl = `http://${formattedUrl}`;
	} else if (isHttps > 0) {
		formattedUrl = formattedUrl.slice(0, isHttps);
	} else if (isHttp > 0) {
		formattedUrl = formattedUrl.slice(0, isHttp);
	}
	formattedLink = `<a class="${classes}" href="${formattedUrl}" target="_blank" rel="noopener" alt="${formattedUrl}">${truncate(
		[formattedUrl, !truncateLimit, true, truncateLimit],
	)}</a>`;
	return safeHtml([formattedLink]);
}

export default helper(linkDisplay);
