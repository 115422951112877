/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	preventNetworkRequest: false,
	urlForFindRecord(id) {
		return `${this.host}/v2/api/cases/${id}`;
	},
	urlForQuery: function (query, modelName) {
		return `${this.host}/v2/api/cases`;
	},
	urlForQueryRecord(slug = {}) {
		if (slug.integrationId) {
			return `${this.host}/cases/third_party/${slug.integrationId}`;
		}
	},

	updateRecord(store, type, snapshot) {
		if (!this.preventNetworkRequest) {
			return this._super(store, type, snapshot);
		} else {
			this.set('preventNetworkRequest', false);
			return {
				data: {
					type: snapshot.modelName,
					id: snapshot.id,
				},
			};
		}
	},
});
