/** @format */

import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';

export default Route.extend({
	currentUser: service('current-user'),
	session: service(),
	ajax: service(),
	store: service(),

	beforeModel() {
		this._super(...arguments);
		this.send(
			'gatekeeper',
			'settings_integrations',
			'settings.message-templates',
		);
	},

	model(params) {
		this.set('params', params);
		return this.get('currentUser.user');
	},

	activate() {
		if (
			this.get('params.integration_success') ||
			this.get('params.integration_error')
		) {
			later(
				'afterRender',
				() => {
					let objDiv = document.getElementById('review');
					window.scrollTo(0, objDiv.offsetTop);
				},
				300,
			);
		}
	},

	setupController(controller, model) {
		this._super(controller, model);
		controller.set('reviewLink', model.get('firm.reviewLink'));
		controller.set('credential', this.store.createRecord('credentials'));
	},

	resetController(controller, isExiting, transition) {
		// eslint-disable-line no-unused-vars
		this._super.apply(this, arguments);

		if (isExiting) {
			controller.set('integration_success', null);
			controller.set('integration_error', null);
		}
	},

	actions: {
		updateSmartAdvocateCredentials(username, password, method, results) {
			const url = ENV.host + '/credentials/smart_advocate';

			return this.ajax
				.request(url, {
					method,
					data: {
						username,
						password,
					},
				})
				.then(() => results.resolve())
				.catch((errors) => results.reject(errors));
		},
	},
});
