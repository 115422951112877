export const defaultNotifOptions = {
	canClose: true,
	autoClear: true,
	clearDuration: 5000,
};

export const persistentNotifOptions = {
	canClose: true,
	autoClear: false,
};

export const permanentNotifOptions = {
	canClose: false,
	autoClear: false,
};

export const infoNotifOptions = {
	canClose: true,
	autoClear: true,
	clearDuration: 7000,
};

export const warningNotifOptions = {
	canClose: true,
	autoClear: true,
	clearDuration: 5000,
};

export const errorNotifOptions = {
	canClose: true,
	autoClear: true,
	clearDuration: 5000,
};

export default {
	default: defaultNotifOptions,
	error: errorNotifOptions,
	info: infoNotifOptions,
	warning: warningNotifOptions,
	persistent: persistentNotifOptions,
	permanent: permanentNotifOptions,
};
