import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title='Client Interactions'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@onChange={{action 'handleOnChange'}}\n/>", {"contents":"<FilterDropdown2\n\t@title='Client Interactions'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@onChange={{action 'handleOnChange'}}\n/>","moduleName":"case-status/components/cases-filters/client-interaction-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/client-interaction-filters.hbs"}});
/** @format */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CasesFiltersClientInteractionFiltersComponent extends Component {
	constructor() {
		super(...arguments);

		//* Need to find a better way
		const options = [
			{
				value: 'Has used the app',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasUsedApp',
			},
			{
				value: 'Has not used the app',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasNotUsedApp',
			},
			{
				value: 'Has sent messages',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasSentMessages',
			},
			{
				value: 'Has not sent messages',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasNotSentMessages',
			},
			{
				value: 'Review requested',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasSentReview',
			},
			{
				value: 'Review not requested',
				checked: false,
				classNames: 'filter-lg',
				param: 'hasNotSentReview',
			},
		];
		options.forEach((option) => {
			option.checked = this.args.selections?.includes(option.param);
		});
		this.options = options;

		if (this.args.onChange && typeof this.args.onChange === 'function') {
			this.onChange = this.args.onChange;
		} else {
			this.onChange = () => {};
		}
	}

	@tracked options;

	@action
	handleOnChange(selections = []) {
		const results = {};

		this.options = this.options.map((opt) => {
			const checked = Boolean(
				selections.find((sel) => sel.param === opt.param),
			);
			opt.checked = checked;
			results[opt.param] = checked;
			return opt;
		});
		this.onChange(results);
	}
}
