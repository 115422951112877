/** @format */

import Controller from '@ember/controller';
import RSVP, { reject } from 'rsvp';
import Validations from '../constants/validations';
import { buildValidations } from 'ember-cp-validations';

export default Controller.extend(buildValidations(Validations.Password), {
	queryParams: ['email_address', 'token'],
	email_address: null,
	token: null,
	confirmPassword: null,
	password: null,

	actions: {
		saveButtonPressed() {
			this.set('errors', null);

			const result = RSVP.defer();
			const errors = this.get('validations.messages');
			this.set('showError', true);
			if (errors.length > 0) {
				return reject();
			} else {
				const password = this.password;
				const token = this.token;
				const emailAddress = this.email_address;
				this.send('setPassword', token, emailAddress, password, result);
			}

			return result.promise;
		},
	},
});
