/** @format */

import Model, { attr } from '@ember-data/model';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tzAbvFromDate } from 'case-status/constants/datetime';

export default class ActivityStatisticPeriodModel extends Model {
	@service company;

	@attr('string') period; //* Don't use date data-type or it will back it up by 1 day since it is coming through in the mm/dd/yyyy format, so it assumes it is UTC at 12am
	@attr('number') totalActivityCount;

	showMore = false;

	get dateTitle() {
		const now = moment(new Date());
		return moment(this.period).calendar(now, {
			sameDay: '[Today]',
			nextDay: '[Tomorrow]',
			nextWeek: 'dddd',
			lastDay: '[Yesterday]',
			lastWeek: '[Last] dddd',
			sameElse: 'dddd',
		});
	}

	get dateDisplay() {
		const now = moment(new Date());
		//todo need to adjust to say Jan 2022 when a year has lapsed
		return (
			moment(this.period).calendar(now, {
				sameDay: 'D MMM',
				nextDay: 'D MMM',
				nextWeek: 'D MMM',
				lastDay: 'D MMM',
				lastWeek: 'D MMM',
				sameElse: 'D MMM',
			}) +
			' (' +
			tzAbvFromDate(this.company.get('info.timezone')) +
			')'
		);
	}

	@attr activities;
	@attr statistics;

	//     @hasMany('activity', { inverse: 'activityGroup' }) activities;
	//     @hasMany('activity-statistic', { inverse: 'activityGroup' }) statistics;
}
