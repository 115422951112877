/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	name: attr('string'),

	caseStatusTemplates: hasMany('case-status-template'),
	firm: belongsTo('firm'),
});
