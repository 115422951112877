/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	// Attributes
	permissionType: attr('string'),
	name: attr('string'),
	settings: attr(),
	createdAt: attr('date'),
	updatedAt: attr('date'),
	firmId: attr('number'),
	organizationId: attr('number'),

	// Relationships
	firm: belongsTo('firm'),
	organization: belongsTo('organization'),
});
