/**
 * Created by kevin on 6/29/17.
 *
 * @format
 */

export default {
	mapResponseErrors(response) {
		if (!response) return ['Unknown error'];
		let errors = [];
		if (response.isAdapterError) {
			errors = response.errors;
		} else {
			if (response.responseJSON) {
				errors = response.responseJSON.errors;
			} else if (response.payload) {
				if (response.payload.errors) {
					errors = response.payload.errors;
				} else {
					return [response.payload.error];
				}
			} else if (response.errors) {
				errors = response.errors;
			} else if (typeof response === 'string') {
				return [response];
			} else {
				return ['Unknown error'];
			}
		}
		return errors.map(function (error) {
			return error.detail;
		});
	},
	presenceError: '{description} is a required field.',
};

export const MESSAGING_OFF_APPOINTMENTS_ERROR =
	'Appointments can not be created at this time as firm messaging has temporarily been disabled. Please reach out to support or try again later';
