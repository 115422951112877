/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { defer } from 'rsvp';
import { captureException } from 'logrocket';

export default Route.extend({
	ajax: service(),

	// Route Methods
	postJSON({ url = '', data = {}, result = defer() }) {
		return this.ajax
			.post(url, {
				type: 'POST',
				contentType: 'application/json',
				data,
			})
			.then((res) => {
				result.resolve(res);
			})
			.catch((err) => {
				result.reject(err);
				captureException(err);
			});
	},

	actions: {
		createNewFirm({ newFirm = {}, result = defer() }) {
			const url = `${ENV.host}/firms/new_account`;
			const data = newFirm;

			this.postJSON({ url, data, result });
		},
		createNewOrg({ newOrg = {}, result = defer() }) {
			const url = `${ENV.host}/organizations`;
			const data = newOrg;

			this.postJSON({ url, data, result });
		},
	},
});
