/** @format */

import Component from '@ember/component';

export default Component.extend({
	actions: {
		toggleShowReceiptModal() {
			if (this.get('tableActions.toggleShowReceiptModal')) {
				this.get('tableActions.toggleShowReceiptModal')(
					this.get('row.content'),
				);
			}
		},
	},
});
