/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { dateDisplay } from '../helpers/date-display';
import { inject as service } from '@ember/service';

export default Component.extend({
	currentUser: service(),
	company: service(),
	elementId: 'requests-list',

	columns: computed(function () {
		let columns = [
			{
				label: 'ID',
				value: 'id',
				colSize: 1,
			},
			{
				label: 'Submitted',
				value: 'submittedAt',
				colSize: 3,
				format: (value) => {
					return dateDisplay([value, this.currentUser.user.firm, true]);
				},
			},
		];

		if (this.currentUser.userType === 'member') {
			columns.push(
				{
					label: 'Internal Notes',
					value: 'organizationNotes',
					colSize: 2,
					format: (value) => {
						return value ? value : 'N/A';
					},
				},
				{
					label: 'Request Notes',
					value: 'requestNotes',
					colSize: 2,
					format: (value) => {
						return value ? value : 'N/A';
					},
				},
				{
					label: 'Requested By',
					value: 'requestedBy',
					colSize: 2,
					format: (value) => {
						return value.name ? value.name : 'N/A';
					},
				},
				{
					colSize: 2,
					align: 'right',
					content: `View Request`,
					value: 'parsedFormResponse',
					cellComponent: 'view-request-cell',
				},
			);
		} else {
			columns.push(
				{
					label: 'Request Notes',
					value: 'requestNotes',
					colSize: 3,
					format: (value) => {
						return value ? value : 'N/A';
					},
				},
				{
					label: 'Requested By',
					value: 'requestedBy',
					colSize: 3,
					format: (value) => {
						return value.name ? value.name : 'N/A';
					},
				},
				{
					colSize: 2,
					align: 'right',
					content: `View Request`,
					value: 'parsedFormResponse',
					cellComponent: 'view-request-cell',
				},
			);
		}

		return columns;
	}),
});
