import Component from '@glimmer/component';
import { action } from '@ember/object';
import $ from 'jquery';
import { imgExtensions } from '../constants/file-uploads';

export default class MessageVideoRequest extends Component {
	get attachmentUrl() {
		return this.args?.attachment?.get('url');
	}

	get isAttachmentImage() {
		const extension = this.args?.attachment?.get('extension');
		return extension && imgExtensions.includes(extension);
	}

	get attachmentName() {
		return this.args?.attachment?.get('fileName');
	}

	@action
	uploadVideo() {
		// eslint-disable-next-line ember/no-jquery
		$('#dz-uploader-videos').click();
	}
}
