/** @format */

import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default Model.extend({
	//* Attributes
	createdAt: attr('date'),
	name: attr('string'),
	phoneNumber: attr('string'),
	phoneNumberRegion: attr('string'),
	about: attr('string'),
	descriptor: attr('string'),
	featureFunding: attr('boolean'),
	featureRecordRetrieval: attr('boolean'),
	formId: attr('string'),
	deactivated: attr('boolean'),

	//* Relationships
	firms: hasMany('firm', { async: true }), //* Loaded on property requests only
	customBranding: belongsTo('custom-branding'),
});
