/** @format */

import Component from '@ember/component';

export default Component.extend({
	attributeBindings: ['style'],
	classNameBindings: ['headerClasses', '_defaultClassNames'],
	_defaultClassNames:
		'cs-table-headers col-xs-12 row full-width small mid-grey-text text-600',
});
