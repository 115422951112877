/** @format */

import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';

export default Component.extend({
	tagName: '',
	store: service(),
	permissions: service(),

	showLogTreatment: computed(
		'treatment.{type,treatmentNotes,appointmentDate,case.allowMessaging}',
		function () {
			const now = moment(new Date());
			return (
				!this.get('treatment.treatmentNotes') &&
				moment(this.get('treatment.appointmentDate')).isBefore(now) &&
				this.get('treatment.case.allowMessaging')
			);
		},
	),
	isTreatment: computed('treatment', function () {
		return true;
	}),
	createUser: computed('createdActvity', function () {
		if (this.createdActvity) {
			return (
				this.store.peekRecord(
					this.createdActvity.userType,
					this.createdActvity.childId,
				) ||
				this.store.findRecord(
					this.createdActvity.userType,
					this.createdActvity.childId,
				)
			);
		}
	}),
	updateUser: computed('updatedActivity', function () {
		if (this.updatedActivity) {
			return (
				this.store.peekRecord(
					this.updatedActivity.userType,
					this.updatedActivity.childId,
				) ||
				this.store.findRecord(
					this.updatedActivity.userType,
					this.updatedActivity.childId,
				)
			);
		}
		return null;
	}),
	formattedDate: computed('treatment.appointmentDate', function () {
		return `${moment(this.treatment.appointmentDate).format(
			'MMM DD, YYYY h:mm a',
		)} (${moment.tz.guess()})`;
	}),
	createdActvity: computed('treatment.treatmentActivities.@each', function () {
		const createdActivities = this.treatment.treatmentActivities.filter(
			(activity) => {
				return activity.action === 'created';
			},
		);
		if (createdActivities.length) {
			return createdActivities[0];
		}
		return null;
	}),
	formattedCreatedDate: computed('createdActvity', function () {
		if (this.createdActvity)
			return moment(this.createdActvity.createdAt).format('MMM DD, YYYY');
	}),
	updatedActivity: computed('treatment.treatmentActivities.@each', function () {
		const updatedActivities = this.treatment.treatmentActivities
			.filter((activity) => {
				return activity.action === 'updated';
			})
			.sort((a, b) => {
				if (moment(a.createdAt).isAfter(b.createdAt)) {
					return -1;
				} else if (moment(b.createdAt).isAfter(a.createdAt)) {
					return 1;
				}
				return 0;
			});
		if (updatedActivities.length) {
			return updatedActivities[0];
		}
		return null;
	}),
	formattedUpdatedDate: computed('updatedActivity', function () {
		return moment(this.updatedActivity).format('MMM DD, YYYY');
	}),
	feelingRatingText: computed('treatment.feelingRating', function () {
		const feelingRating = this.treatment.feelingRating;
		switch (feelingRating) {
			case 1:
				return 'Terrible';
			case 2:
				return 'Bad';
			case 3:
				return 'Okay';
			case 4:
				return 'Good';
			case 5:
				return 'Great';
		}
		return 'N/A';
	}),
	feelingRatingClass: computed('treatment.feelingRating', function () {
		const feelingRating = this.treatment.feelingRating;
		return 'treatment-face-' + feelingRating;
	}),

	actions: {
		onEditClick() {
			if (this.onEditClick) {
				this.onEditClick();
			}
		},
	},
});
