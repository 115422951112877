/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import Errors from '../constants/errors';

export default Route.extend(UnauthenticatedRouteMixin, {
	ajax: service(),
	routeIfAlreadyAuthenticated: ENV.routeAfterAuthentication,
	welcome: service(),

	actions: {
		setPassword(token, emailAddress, password, result) {
			const url = ENV.host + '/reset_password/' + token;
			let data = {
				password: password,
			};
			this.ajax
				.post(url, {
					contentType: 'application/json',
					dataType: 'json',
					data: JSON.stringify(data),
				})
				.then(() => {
					this.session
						.authenticate('authenticator:oauth2', emailAddress, password)
						.then(() => {
							this.set('welcome.firstTime', true);
						})
						.catch(() => {
							this.controller.set('errors', [
								'Your username and password combination is not correct. Please try again.',
							]);
							result.reject();
						});
				})
				.catch((response) => {
					result.reject();
					this.controller.set('errors', Errors.mapResponseErrors(response));
				});
		},
	},
});
