import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! Triple handlebars does not escape html }}\n{{{this.content}}}", {"contents":"{{! Triple handlebars does not escape html }}\n{{{this.content}}}","moduleName":"case-status/components/link-to-href.hbs","parseOptions":{"srcName":"case-status/components/link-to-href.hbs"}});
import { safeHtml } from 'case-status/helpers/safe-html';
import Component from '@glimmer/component';
import anchorme from 'anchorme';

export default class LinkToHrefComponent extends Component {
	constructor() {
		super(...arguments);
		this.content = this.linkify(this.args.content);
	}
	linkify(inputText) {
		if (inputText) {
			// Issue where , was included in url
			let deconstructedInput = inputText.replace(',', ' ,');
			// Convert links to anchor tags
			let anchorHtml = anchorme(deconstructedInput);
			// Revert comma formatting
			let reconstructedInput = anchorHtml.replace(' ,', ',');
			// Prevent malicious code from running in browser
			return safeHtml([reconstructedInput]);
		}
		return inputText;
	}
}
