/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';

export default Model.extend({
	createdAt: attr('date'),
	extension: attr('string'),
	filename: attr('string'),
	size: attr('number'),
	updatedByUserId: attr('number'),
	url: attr('string'),
	apiUrl: attr('string'),
	title: attr('string'),
	aspectRatio: attr('string'),

	formattedCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).format('MMMM D, YYYY');
	}),

	sortableCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).valueOf();
	}),

	confirmDelete: false,

	fileType: computed('extension', function () {
		return this.extension.replace('.', '').toUpperCase();
	}),

	init() {
		this._super(...arguments);
	},
});
