import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { safeHtml } from 'case-status/helpers/safe-html';

function getUnmarriedTags(truncStr = '') {
	const elementTags = new RegExp(/<\/*[a-z]+[\s]*[^>]*[1-5]*>/g);
	const anchorTag = new RegExp(/<[/]*[a][\s]*[^>]*>/g);
	const breakTag = new RegExp(/<br>/g);
	const openingAnchorTagRegex = new RegExp(/<[a][\s]*[^>]*>/g);
	const closingAnchorTag = '</a>';

	//* Check for formatting tags
	const elementTagsPresent = truncStr.match(elementTags) || [];

	//* Check that all tags have a spouse
	const unmarriedTags = [];
	const workingTagArray = [...elementTagsPresent];

	for (let i = 0; i < elementTagsPresent.length; i++) {
		const tag = elementTagsPresent[i];

		const isClosingTag = tag.includes('/') ? tag.indexOf('/') == 1 : false;
		const closingTag = isClosingTag ? tag : tag.replace('<', '</');
		const openingTag = isClosingTag ? tag.replace('</', '<') : tag;
		const isAnchorTag = anchorTag.test(tag);

		if (isClosingTag) {
			if (!isAnchorTag) {
				if (
					!workingTagArray.includes(openingTag) &&
					workingTagArray.indexOf(tag) > workingTagArray.indexOf(openingTag)
				) {
					unmarriedTags.push(tag);
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				} else {
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				}
			} else {
				let res;

				//* Using a for loop instead of a forEach because it is synchronous and can be broken out of.
				for (let i2 = 0; i2 < workingTagArray.length; i2++) {
					const testTag = elementTagsPresent[i2];
					res =
						openingAnchorTagRegex.test(testTag) &&
						workingTagArray.indexOf(tag) < i2;
					if (res) break;
				}

				if (res) {
					unmarriedTags.push(tag);
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				}
			}
		} else {
			if (isAnchorTag) {
				if (!workingTagArray.includes(closingAnchorTag)) {
					unmarriedTags.push(tag);
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				} else {
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				}
			} else {
				if (!breakTag.test(tag) && !workingTagArray.includes(closingTag)) {
					unmarriedTags.push(tag);
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				} else {
					workingTagArray.splice(workingTagArray.indexOf(tag), 1);
				}
			}
		}
	}

	return unmarriedTags.filter((tag) => {
		return (
			tag !== '<div>' &&
			tag !== '<br>' &&
			tag !== '<p>' &&
			tag !== '</div>' &&
			tag !== '</p>'
		);
	});
}

function stripTrailingBlankSpace(truncStr = '') {
	const nbspRegex = new RegExp(/&nbsp;/gim);

	truncStr = truncStr.replace(nbspRegex, ' ');

	return truncStr.trim();
}

function stripTrailingLineBreaks(truncStr = '') {
	const lineBreakRegex = new RegExp(/<br\s*\/?>/gim);
	const openingDivBreakRegex = new RegExp(/(<div>)/gim);
	const closingDivBreakRegex = new RegExp(/(<\/div>)/gim);

	truncStr = truncStr
		.replace(lineBreakRegex, '')
		.replace(closingDivBreakRegex, '')
		.replace(openingDivBreakRegex, '');

	return stripTrailingBlankSpace(truncStr.trim());
}

export function truncate(params = []) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			`truncate helper requires params passed in as an array`,
		);
	}

	//* All params are technically optional, but without a string passed it will return an empty string
	const [str = '', showFull = false, ellipses = true, charLimit = 125] = params;

	//* Make sure if str param exists that it is a string, if it doesn't just return an empty string
	if (str && typeof str != 'string') {
		throw new EmberError(
			`truncate helper requires first parameter be a String`,
		);
	} else if (!str) {
		return '';
	}

	//* Make sure that if a custom charLimit is passed, that it is a number
	if (Number.isNaN(charLimit)) {
		throw new EmberError(
			`truncate helper requires last parameter to be a number, if provided`,
		);
	}

	//* Remove excess spaces from string before processing
	const trimmedStr = str.trim();

	//* Return an empty string if no string is passed or string was just empty spaces (hence the trim).
	if (!trimmedStr) return '';

	//* Return full string, trimmed, if showFull is true, useful for dynamic truncation
	if (showFull || trimmedStr.length < charLimit) return safeHtml([trimmedStr]);

	const elementOpening = new RegExp(/<[a-z]+[1-5]*>/g);
	//* Use this to test the string for HTML element closing tag to attempt not to break in between the tags.
	const elementClosure = new RegExp(/<\/[a-z]+[1-5]*>/g);

	//* Choose only the part of the string that goes over the specified charLimit
	const overflowStr = trimmedStr.slice(0, charLimit);

	//* Search for the starting index of an HTML element opening tag in the overflow String
	const overflowOpeningTagIndex = overflowStr.search(elementOpening);

	//* Search for the starting index of an HTML element closing tag in the overflow String
	let overflowClosureStartIndex = overflowStr.search(elementClosure);

	if (overflowOpeningTagIndex !== -1) {
		//* If there is a opening tag in the overflow string let's check which comes first
		if (overflowClosureStartIndex > overflowOpeningTagIndex) {
			//* If the opening tag comes before closure tag, let's check if they are the same type of tag
			if (overflowClosureStartIndex > overflowOpeningTagIndex) {
				//* If they are the same type of tag, let's ignore the closure tag in the overflow altogether
				if (
					overflowStr[overflowClosureStartIndex + 2] ===
					overflowStr[overflowOpeningTagIndex + 1]
				) {
					overflowClosureStartIndex = -1;
				}
			}
		}
	}

	//* Calculate and find the ending index of the HTML element closing tag
	const closureEndIndex =
		overflowClosureStartIndex != -1
			? trimmedStr.indexOf('>', overflowClosureStartIndex + charLimit) + 1
			: -1;

	//* Set the truncIndex to be used.
	//* - If closureEndIndex isn't -1, check to make sure it isn't the end of the string, if it is the end of the string, set truncIndex to the last available index.
	//* - If closureEndIndex is -1, check to make sure there is a charLimit (including 0, in case conditional truncation is needed in this way) and use the specified charLimit, else if it is falsey, default to 125
	const truncIndex =
		closureEndIndex != -1
			? closureEndIndex != trimmedStr.length - 1
				? closureEndIndex
				: trimmedStr.length - 1
			: charLimit || charLimit === 0
			? charLimit
			: 125;

	//* Now cut the trimmed string down to the indicated truncIndex and add the ellipses unless specified not to, and return the final truncated string.
	let truncStr = trimmedStr.substring(0, truncIndex).trim();

	//* Check for a trailing tag opening bracket and remove it
	if (truncStr.lastIndexOf('<') > truncStr.lastIndexOf('>')) {
		truncStr = truncStr.substring(0, truncStr.lastIndexOf('<')).trim();
	}

	//* Check for trailing blank space unicode and remove it
	truncStr = stripTrailingBlankSpace(truncStr);

	//* Get an Array of the unmarried tags
	const unmarriedTags = getUnmarriedTags(truncStr);

	truncStr = stripTrailingLineBreaks(truncStr);

	const ellips = ellipses ? '...' : '';

	//* Create hot & ready singles in the area for the lonely bachelor tags
	let finalTruncStr = '';

	if (unmarriedTags.length > 0) {
		finalTruncStr =
			truncStr.trim() +
			unmarriedTags
				.reverse()
				.map((tag) => {
					return tag.includes('/') ? '' : tag.replace('<', '</');
				})
				.join('')
				.trim() +
			ellips;
	} else {
		finalTruncStr = truncStr.trim() + ellips;
	}

	//* Show off the prize-winning string at the county fair in a html-safe manner
	return safeHtml([finalTruncStr]);
}

export default helper(truncate);

//TODO Still need to figure out a way to exclude formatting tags from the charLimit count and still need to account for a trailing space inside a format tag, since it technically isn't trailing on the string itself. But This accounts for so much more than it did before!
