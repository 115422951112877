/**
 * eslint-disable
 *
 * @format
 */

import Controller from '@ember/controller';
import { A } from '@ember/array';
import moment from 'moment';
import { action } from '@ember/object';

export default class MyController extends Controller {
	sample = ['Michael Hale', 'Bettie Johnson', 'Sue Barber', 'Isabelle Price'];
	@action
	cmsStageAction(cmsStageRecord) {
		alert(`Action with record of: ${cmsStageRecord.name}`);
	}
	@action
	updateCmsRecordsCB(cmsStageRecords) {
		this.cmsStageRecords = cmsStageRecords;
	}
	cmsStageRecordsOne = [];
	cmsStageRecordsTwo = [
		{ name: 'Settlement', id: 1, order: 1 },
		{ name: 'Treatment and Recovery', id: 2, order: 1 },
		{ name: 'Close', id: 4, order: 1 },
	];
	cmsStageOptions = [
		{
			label: 'Move to Stage 1',
			value: 'Stage 1',
			action: this.cmsStageAction,
		},
		{
			label: 'Move to Stage 2',
			value: 'Stage 2',
			action: this.cmsStageAction,
		},
	];
	errorSample = [
		'Michael Hale',
		'Bettie Johnson',
		'Sue Barber',
		'Isabelle Price',
	];
	pillDropdownOptions = [
		{ value: "Hi, I'm Option 1" },
		{ value: "Yo, I'm Option 2" },
		{ value: "Cheers, I'm Option 3" },
		{ value: "Hey, I'm Option 4" },
		{ value: "Holla, I'm Opt 5" },
	];
	dropdownSelectedArray = [
		{ value: "Yo, I'm Option 2" },
		{ value: "Cheers, I'm Option 3" },
	];

	selectedArray = ['Hmmm', 'Delete Me ->', 'Avada Kadavra!!!'];
	defaultValue = 'Hello World!';

	filterDropdownSelections = ['Karen'];
	filterDropdownOptions = A([
		{ value: 'McDouble', param: 0, checked: false },
		{ value: 'Mocha Cookie Crumble [Venti]', param: 1, checked: false },
		{ value: 'Karen', param: 2, checked: true },
		{ value: 'Typescript', param: 3, checked: false },
		{ value: 'I miss Kevin', param: 4, checked: false },
	]);

	filterDropdownSelections2 = ['Ketchup'];
	filterDropdownOptions2 = A([
		{ value: 'Bath', param: 0, checked: false, faIcon: 'bath' },
		{ value: 'Mail', param: 1, checked: false, faIcon: 'envelope-open-o' },
		{ value: 'Bards and Nobles', param: 2, checked: false, faIcon: 'podcast' },
		{ value: 'Ketchup', param: 3, checked: true, faIcon: 'user-o' },
		{ value: 'Luxottica', param: 4, checked: false, faIcon: 'battery-quarter' },
	]);

	dateRangeFilterOptions = {
		startDate: moment('06/01/2019').toDate(),
		endDate: null,
	};

	@action
	testAction() {
		alert('Test Action Successfully Called!');
	}

	@action
	handleFilterDropdownChange(selections) {
		// this stuff would live in a parent containing component
		this.filterDropdownSelections = selections
			.map((selection) => {
				return selection.value;
			})
			.sortBy('param');

		this.filterDropdownOptions = this.filterDropdownOptions
			.map((option) => {
				if (selections.findBy('param', option.param)) {
					option.checked = true;
				} else {
					option.checked = false;
				}

				return option;
			})
			.sortBy('param');
	}

	@action
	handleFilterDropdownChange2(selections) {
		// this stuff would live in a parent containing component
		this.filterDropdownSelections2 = selections
			.sortBy('param')
			.map((selection) => {
				return selection.value;
			});

		this.filterDropdownOptions2 = this.filterDropdownOptions2
			.map((option) => {
				if (selections.findBy('param', option.param)) {
					option.checked = true;
				} else {
					option.checked = false;
				}

				return option;
			})
			.sortBy('param');
	}

	@action
	handleDateRangeFilterDropdownChange(selections) {
		this.dateRangeFilterOptions = selections;
	}
}
// """
