/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';

export default Route.extend({
	currentUser: service('current-user'),
	session: service(),
	ajax: service(),

	model(params) {
		this.set('params', params);
		return this.get('currentUser.user');
	},

	actions: {
		updateProfilePhoto(file) {
			const fd = new FormData();
			const user = this.get('currentUser.user');
			//Build file name from the user's name
			const filename =
				user.get('organization.name').toLowerCase() +
				'_' +
				user.get('lastName').toLowerCase() +
				'_' +
				user.get('id') +
				'.png';
			fd.append('picture', file, filename);
			const url = ENV.host + '/profile_pictures';

			this.controller.set('percentComplete', 0);
			let self = this;
			return this.ajax
				.post(url, {
					contentType: false,
					data: fd,
					processData: false,
					xhr: function () {
						const jqXHR = window.ActiveXObject
							? new window.ActiveXObject('Microsoft.XMLHTTP')
							: new window.XMLHttpRequest();
						jqXHR.upload.addEventListener(
							'progress',
							function (evt) {
								if (evt.lengthComputable) {
									const percentComplete = Math.round(
										(evt.loaded * 100) / evt.total,
									);
									self.controller.set('percentComplete', percentComplete);
								}
							},
							false,
						);
						return jqXHR;
					},
				})
				.then(() => {
					this.get('currentUser.user')
						.reload()
						.then(() => {
							this.controller.set('percentComplete', null);
							this.controller.set('imgToCrop', null);
						});
				})
				.catch((response) => {
					this.controller.set('percentComplete', null);
					this.controller.set('imgToCrop', null);
					if (response.payload && response.payload.errors) {
						window.alert(response.payload.errors[0].detail);
					} else {
						window.alert('Error uploading photo. Please try again later.');
					}
				});
		},

		willTransition() {
			const controller = this.controllerFor(
				'app.organization.settings.account',
			);
			controller.send('cancelEditing');
			return true;
		},
	},
});
