/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	classNames: 'client-cases-cell',
	hasMultipleCases: computed('value', function () {
		return this.get('value.length') > 1;
	}),
});
