/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class InfoSectionComponent extends Component {
	get tagName() {
		return 'div';
	}
	get classNameBindings() {
		return ['_requiredClassNames'];
	}

	get _requiredClassNames() {
		return 'row';
	}
}
