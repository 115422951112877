import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { v4 } from 'uuid';
import ENV from 'case-status/config/environment';

export default class AddToClioRoute extends Route {
	@service currentUser;
	@service router;
	@service session;
	isEu = false;
	redirectUri = encodeURI(`${window.location.origin}/app/clio`);

	beforeModel() {
		this.isEu = this.currentUser.user.get('firm.isEu');
	}

	model() {
		//* generate nonce and pass in the state qp to the /authorize endpoint
		const nonce = v4();
		const authUrl = `https://${
			this.isEu ? `eu.app.clio.com` : `app.clio.com`
		}/oauth/authorize?response_type=code&client_id=${
			this.isEu ? ENV.clioEUClientId : ENV.clioNAClientId
		}&redirect_uri=${this.redirectUri}&state=${nonce}`;
		window.location.replace(authUrl);
	}
}
