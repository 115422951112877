/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	checked: computed('selectedArray', function () {
		const value = this.value;
		const param = this.param;
		const selectedArray = this.selectedArray ? this.selectedArray : [];
		const isSelected = selectedArray.indexOf(param ? param : value);

		if (isSelected !== -1) {
			return true;
		} else {
			return false;
		}
	}),

	actions: {
		handleChange(value) {
			const onChangeHandler = this.onChangeHandler;
			if (onChangeHandler) {
				onChangeHandler(value);
			} else {
				throw new Error(
					'onChangeHandler action is required for component: control-checkbox',
				);
			}
		},
	},
});
