/** @format */

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import BREAKPOINTS from '../constants/breakpoints';

export default class ResponsiveService extends Service {
	@service thirdParty;
	@tracked showResponsiveUi = false;

	constructor() {
		super(...arguments);
		const isIFrame = this.thirdParty.isIFrame;
		this.showResponsiveUi = window.innerWidth < BREAKPOINTS.XS && isIFrame;
		window.addEventListener('resize', () => {
			this.showResponsiveUi = window.innerWidth < BREAKPOINTS.XS && isIFrame;
		});
	}
}
