/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Table from 'ember-light-table';
import { storageFor } from 'ember-local-storage';
import { computed } from '@ember/object';
import { dateDisplay } from 'case-status/helpers/date-display';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';

export default Component.extend({
	exportError: 'Export Error',

	currentUser: service(),
	company: service(),
	preferences: storageFor('preferences'),

	filterOptions: computed(function () {
		return ['client', 'attorney', 'caseId', ``];
	}),

	columns: computed(function () {
		const currentUserFirm = this.get('currentUser.user.firm');

		return [
			{
				label: 'Receipt ID',
				valuePath: 'stripeReceiptId',
				cellClassNames: 'bold',
				cellComponent: 'cell-handle-missing-value',
				sortable: true,
			},
			{
				label: `${capitalize(dynamicCaseLabel([this.company]))} ID`,
				valuePath: 'case.readableId',
				sortable: true,
				cellClassNames: 'ellipsis',
				cellComponent: 'transaction-case-id-cell',
			},
			{
				label: 'Client',
				valuePath: 'case.clients',
				cellClassNames: 'ellipsis',
				cellComponent: 'transaction-client-cell',
				sortable: false,
			},
			{
				label: 'Processed Date',
				valuePath: 'receivableProcessedDate',
				sortable: true,
				format: function (value) {
					if (value) {
						return dateDisplay([value, currentUserFirm]);
					} else {
						return 'No Date';
					}
				},
			},
			{
				label: 'Amount',
				valuePath: 'amount',
				sortable: true,
				format(value) {
					return `$${Number(value).toFixed(2)}`;
				},
			},
			{
				label: 'Invoice Type',
				valuePath: 'invoiceType',
				sortable: true,
			},
		];
	}),

	model: null,
	table: null,

	init() {
		this._super(...arguments);
		this.createTable();
	},

	createTable(...callbacks) {
		const cols = this.columns;
		const mod = this.model;
		const table = Table.create({ columns: cols, rows: mod, enableSync: true });
		const sortColumn = table.get('allColumns').findBy('valuePath', 'updatedAt');

		if (sortColumn) {
			// If case sortColumn wouldn't exist for some reason, we'd still want to minimize the bug
			// and have it just be that the table isn't sorted on load instead of the table never loads
			sortColumn.set('sorted', true);
			sortColumn.set('ascending', false);
		}

		this.set('table', table);
		this.set('columns', cols);
		this.set('model', mod);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			const scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});

		// Loop through callbacks passed and pass back an object
		// with the table, cols, mod, and opts to be viewed/used
		for (let i = 0; i < callbacks.length; i++) {
			const callback = callbacks[i];
			if (callback && typeof callback === 'function') {
				callback({ table, cols, mod });
			}
		}
	},

	selected: 'All',

	actions: {
		onScrolledToBottom() {
			this.fetchMoreCharges();
		},

		handleDropdownChange(selection) {
			this.set('searchType', selection);
		},

		onColumnClick(column) {
			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				this.updateSort(columnName, direction);
			}
		},
		setStartDate(value) {
			this.set('startDate', value);
		},
		setEndDate(value) {
			this.set('endDate', value);
		},
		exportClicked() {
			if (this.export && typeof this.export == 'function') {
				this.export();
			} else {
				this.set('showExportError', true);
			}
		},
	},
});
