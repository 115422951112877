/** @format */

import Service from '@ember/service';

export default class KeyBindingsService extends Service {
	// List of subscribed keys
	subscriptions = [];

	/**
	 * Subscribe to events on an element for the passed targetId, resulting event arguments are passed to callback
	 *
	 * @param {String} targetId ID of the element to be targeted (Do not include a #)
	 * @param {String} eventType eventType to subscribe to
	 * @param {Function} callback Called when event listener is fired, receives (this = element, ...EventArgs)
	 * @returns {Boolean} FALSE when not subscribed, TRUE when subscribed
	 * @memberof KeyBindingsService
	 */
	subscribe(targetId, eventType, callback) {
		if (
			!targetId ||
			!callback ||
			!eventType ||
			typeof eventType != 'string' ||
			typeof targetId != 'string' ||
			typeof callback != 'function'
		)
			return false;
		const target = document.getElementById(targetId);
		if (!target) return false;

		//* Prevent duplicate subscriptions
		const isSubscribed = this.subscriptions.find((sub) => {
			return sub.targetId == targetId && sub.eventType == eventType;
		});
		if (isSubscribed) return true;

		try {
			this.subscriptions.push({ targetId, eventType });

			target.addEventListener(eventType, function (...args) {
				const event = args[0];

				//* Only allow callbacks to fire on trusted events to prevent malicious actions from spoofed keystrokes
				//? This may affect testing as most of the test actions ran in TestProject are deemed isTrusted = false;
				if (event.isTrusted) {
					callback(this, ...args);
				}
			});
			return true;
		} catch (error) {
			return false;
		}
	}

	/**
	 * Unsubscribe element with ID of targetId from events of the specified eventType
	 *
	 * @param {String} targetId ID of the element to be targeted (Do not include a #)
	 * @param {String} eventType eventType to unsubscribe from
	 * @param {Boolean} shouldRemove (optional) Boolean to dictate whether the subscription pair should be removed from the subscriptions list once unsubscribed (Defaults to TRUE)
	 * @returns {Boolean} FALSE if unsuccessfully unsubscribed, TRUE is successfully unsubscribed
	 * @memberof KeyBindingsService
	 */
	unsubscribe(targetId, eventType, shouldRemove = true) {
		if (
			!targetId ||
			!eventType ||
			typeof targetId != 'string' ||
			typeof eventType != 'string'
		)
			return false;

		const target = document.getElementById(targetId);

		try {
			//* If the target is null or falsey, then it is destroyed and thus no longer subscribed, therefore we should remove it from our subscriptions
			if (target) {
				target.removeEventListener(eventType);
			}

			if (shouldRemove) {
				this.set(
					'subscriptions',
					this.subscriptions.filter((sub) => {
						return sub.targetId !== targetId || sub.eventType !== eventType;
					}),
				);
			}

			return true;
		} catch (error) {
			return false;
		}
	}

	/**
	 * Unsubscribe ALL currently subscribed elements in the subscriptions list from their paired IDs and eventTypes
	 *
	 * @returns {Boolean} TRUE if successful, FALSE if unsuccessful
	 * @memberof KeyBindingsService
	 */
	unsubscribeAll() {
		const subscriptions = this.subscriptions || [];
		if (!subscriptions.length) return true;

		//! Need to watch the subscriptions list, if this runs to fast in parallel then some subscriptions might get overwritten back in
		subscriptions.forEach((subscription) => {
			this.unsubscribe(subscription.targetId, subscription.eventType);
		});

		return true;
	}
}
