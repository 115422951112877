/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	tagName: 'p',
	attributeBindings: ['data-component', 'data-truncate', 'data-test-id'],
	length: 0,
	maxLength: 0,
	'data-test-id': 'component',
	'data-component': 'util',
	'data-truncate': computed('length', 'maxLength', function () {
		const length = this.length;
		const maxLength = this.maxLength;
		return `${length && maxLength && length > maxLength}`;
	}),
});
