/** @format */

import { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import User from 'case-status/models/user';
import { buildValidations, validator } from 'ember-cp-validations';
import ValidationErrors from '../constants/errors';
import { dateDisplay } from '../helpers/date-display';

const Validations = buildValidations({
	cellPhone: [
		validator('presence', {
			presence: true,
			message: ValidationErrors.presenceError,
			description: 'Phone Number',
		}),
	],
});

export default User.extend(Validations, {
	cases: hasMany('case', {
		inverse: 'clients',
	}),
	firm: belongsTo('firm', { async: false }),
	invite: attr('boolean'),
	showNps: attr(),
	openCaseIds: attr(),
	allCases: attr('number'),
	closedCaseIds: attr(),
	birthDate: attr('string'),
	ssn: attr('string'),
	formattedSSN: computed('ssn', function () {
		const ssn = this.ssn || '';

		if (!ssn || typeof ssn !== 'string') return '';

		return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5, 4)}`;
	}),
	last4SSN: computed('ssn', function () {
		const ssn = this.ssn || '';

		if (!ssn || typeof ssn !== 'string') return false;

		return ssn.substr(5, 4);
	}),

	//Used to combine invite and lastOpened for clients table on a case
	inviteData: computed('invite', 'lastOpened', 'lastInteraction', function () {
		return {
			invite: this.invite,
			lastOpened: this.lastOpened,
			lastInteraction: this.lastInteraction,
		};
	}),

	//Local property for selecting clients to add to a case
	isSelected: attr('bool'),

	//* Opted out of SMS messages
	optOut: attr('boolean'),
	sentOptOutMsg: attr('date'),

	//TODo Prep for polymorphic upgrade
	// subUser: belongsTo('sub-user'),
	user: belongsTo('user'),
	userChild: belongsTo('user-child'),
});
