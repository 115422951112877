/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

@classic
export default class MobileTopNavBarComponent extends Component {
	elementId = 'mobile-top-nav-bar';
	classNames = ['row middle-xs'];
	@service mobile;
	@service router;
	@service currentUser;
	@service global;
	@service customBranding;

	@tracked showUserProfile = false;

	get subRoute() {
		return this.currentUser.get('user.constructor.modelName') == 'client'
			? 'client'
			: 'firm';
	}

	get showUpdateInfoBtn() {
		if (this.router.currentRouteName === 'app.client.case.info') {
			return true;
		}

		return false;
	}

	@action
	toggleShowUserProfile() {
		this.toggleProperty('showUserProfile');
	}

	@action setWantsDesktop() {
		this.mobile.setWantsDesktop();
	}

	@action logout() {
		this.currentUser.logout();
	}

	@action requestUpdateInformation() {
		if (this.global?.requestUpdateInformation()) {
			this.set('showUserProfile', false);
		}
	}
}
