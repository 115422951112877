/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
	classNames: 'card--header',
	company: service(),
	permissions: service(),
	currentUser: service('current-user'),

	caseActivationCount: computed(
		'permissions.firmSettings.case_activation_count',
		function () {
			return this.permissions?.firmSettings?.case_activation_count;
		},
	),

	caseActivationQuota: computed(
		'permissions.firmSettings.case_activation_quota',
		function () {
			return this.permissions?.firmSettings?.case_activation_quota;
		},
	),

	caseActivationQuotaExceeded: computed(
		'permissions.firmSettings.case_activation_quota_exceeded',
		function () {
			return this.permissions?.firmSettings?.case_activation_quota_exceeded;
		},
	),

	init() {
		this._super(...arguments);
	},

	actions: {
		sendWelcomeMessage() {
			if (
				this.sendWelcomeMessage &&
				typeof this.sendWelcomeMessage === 'function'
			) {
				this.sendWelcomeMessage();
			}
		},
	},
});
