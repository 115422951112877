/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	urlForQuery(query) {
		if (query.caseId) {
			return `${this.host}/cases/${query.caseId}/chats`;
		}
	},
});
