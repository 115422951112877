/** @format */

export const referralSources = [
	{
		label: 'Google',
		value: 'Google',
	},
	{
		label: 'Facebook',
		value: 'Facebook',
	},
	{
		label: 'Attorney Referral',
		value: 'Attorney Referral',
		freeText: true,
	},
	{
		label: 'Client Referral',
		value: 'Client Referral',
		freeText: true,
	},
	{
		label: 'Internet Marketing',
		value: 'Internet Marketing',
	},
	{
		label: 'Other',
		value: 'Other',
		freeText: true,
	},
];

export const freeTextOptions = referralSources
	.filter((src) => src.freeText)
	.map((source) => source.label);

export default referralSources;
