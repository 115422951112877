/** @format */

import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { observer, computed } from '@ember/object';
import Errors from '../../../../constants/errors';
import RSVP, { defer, reject, resolve } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { infoNotifOptions } from 'case-status/constants/notif-options';

export default Controller.extend({
	isDeletingCaseType: false,
	isEditingCaseTypeTitle: false,
	isConfiguringStages: false,
	sortProps: ['number'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	caseTypeSortProps: ['name'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sortedCaseStatuses: sort('selectedCaseType.caseStatuses', 'sortProps'),
	sortedCaseTypes: sort('model', 'caseTypeSortProps'),
	notifications: service(),
	company: service(),
	defaultTruncatedNameMaxLength: 35,

	selectedCaseType: null,
	currentUser: service(),

	setSelectedCaseType: observer(
		'model',
		'sortedCaseTypes',
		'selectedCaseType',
		function () {
			this.resetSelectedCaseType();
		},
	),

	isDuplicatingCaseType: false,
	isEditingCaseType: false,
	@tracked isEditingStage: false,
	isCreatingCaseType: false,
	newCaseType: null,

	resetSelectedCaseType() {
		const selectedCaseType = this.selectedCaseType;
		const types = this.sortedCaseTypes;

		if (types.length && !selectedCaseType) {
			this.set('selectedCaseType', types[0]);
		}

		this.set('isDeletingCaseType', false);
	},

	caseTypeSubTabs: computed('currentUser.permissions.automation', function () {
		if (this.currentUser.get('permissions.automation')) {
			return ['Stages', 'Automation'];
		} else {
			return ['Stages'];
		}
	}), //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	selectedTab: 'Stages',

	actions: {
		reload(after = 0) {
			for (const stage of this.sortedCaseStatuses) {
				if (stage.number > after) {
					stage.reload();
				}
			}
		},
		setIsEditingStage(newValue) {
			this.set('isEditingStage', newValue);
		},
		setSelectedCaseType(caseType) {
			if (this.isEditingStage) {
				this.notifications.info(
					'You are editing a stage in the current Practice Area - please cancel or save your changes before viewing a different Practice Area.',
					infoNotifOptions,
				);
				return;
			}
			const newCaseType = this.newCaseType;
			if (newCaseType) {
				const confirm = window.confirm(
					"You haven't saved your new practice area yet. Switching to a different case type will delete the new case type.",
				);

				if (confirm) {
					newCaseType.deleteRecord();
					this.set('isCreatingCaseType', false);
					this.set('newCaseType', null);
				} else {
					return;
				}
			}
			this.set('errors', null);
			this.set('success', null);
			this.set('isEditingCaseTypeTitle', false);
			this.set('selectedTab', 'Stages');
			this.set('selectedCaseType', caseType);
		},

		saveCaseType(caseType) {
			this.set('caseTypeErrors', null);
			this.notifications.clearAll();

			// Pull in all the caseStatuses for the case type so we can patch them all
			const statuses = caseType.get('caseStatuses');

			// We have a lot of things to save and we want to handle errors/success all together,
			// so we'll add all of the save actions to an array of promises

			// Loop through each caseStatus
			let promises = statuses.map((status) => {
				return status.save();
			});

			// Then push the caseType save promise into the array
			promises.push(caseType.save());

			// Now we return all promises in the array and handle resolve/reject
			// for them all together
			return RSVP.all(promises)
				.then(() => {
					this.set('isEditingCaseType', false);
					// We need to reload the case type to make sure it doesn't come back as pending now that all the statuses are updated
					caseType.reload();
					this.notifications.success('Practice area saved successfully!', {
						autoClear: true,
						clearDuration: 7000,
						cssClasses: 'notification--success',
					});
				})
				.catch((errors) => {
					this.set('caseTypeErrors', Errors.mapResponseErrors(errors));
				});
		},

		newCaseType() {
			if (this.isEditingStage) {
				this.notifications.info(
					'You are editing a stage in the current Practice Area - please cancel or save your changes before creating a new Practice Area.',
					infoNotifOptions,
				);
				return;
			}
			this.set('isCreatingCaseType', true);
		},
		configureStages() {
			this.set('isConfiguringStages', true);
			this.set('isCreatingCaseType', true);
		},
		async saveNewCaseType(
			caseType,
			result = { resolve: () => {}, reject: () => {} },
		) {
			this.set('errors', []);
			this.set('success', null);
			this.set('caseTypeErrors', []);

			const statuses = await caseType
				.get('caseStatuses')
				.toArray()
				.sortBy('number');
			const oldStages = caseType.get('oldStages') || [];

			try {
				for (let i = 0; i < oldStages.length; i++) {
					const stage = oldStages[i];
					try {
						await stage.destroyRecord().catch((error) => {
							this.errors = [
								...this.errors,
								...Errors.mapResponseErrors(error),
							];
							return result.reject(this.errors);
						});
					} catch (error) {
						this.errors = [...this.errors, ...Errors.mapResponseErrors(error)];
						return result.reject(this.errors);
					}
				}
			} catch (error) {
				this.errors = [...this.errors, ...Errors.mapResponseErrors(error)];
				this.notifications.error(
					'An error has occurred trying to save the Practice Area.',
					{
						autoClear: true,
						canClose: true,
					},
				);
				return result.reject(this.errors);
			}

			if (this.errors && this.errors.length) return result.reject(this.errors);

			return caseType
				.save()
				.then(async (res) => {
					caseType.set('pending', false);

					//? Must iterate and await through stages else we get an API error saying the statues are out of order
					for (let i = 0; i < statuses.length; i++) {
						const status = statuses[i];
						try {
							await status.save();
						} catch (error) {
							this.caseTypeErrors = [
								...this.caseTypeErrors,
								...Errors.mapResponseErrors(error),
							];
							continue;
						}
					}

					if (this.caseTypeErrors.length) {
						return reject(this.caseTypeErrors);
					} else {
						return resolve(res);
					}
				})
				.catch((errors) => {
					this.errors = [...this.errors, ...Errors.mapResponseErrors(errors)];
					result.reject([...this.errors, ...this.caseTypeErrors]);
				})
				.finally(() => {
					if (!this.errors.length && !this.caseTypeErrors.length) {
						this.set('isConfiguringStages', false);
						this.set('isCreatingCaseType', false);
						this.set('newCaseType', null);
						caseType.set('oldStages', null);
						this.send('setSelectedCaseType', caseType);
						this.send('hideCaseTypeModal');
					}
				});
		},
		cancelNewCaseType() {
			const caseType = this.selectedCaseType;
			const isCreating = this.isCreatingCaseType;
			const caseTypes = this.sortedCaseTypes;

			if (isCreating) {
				caseType.deleteRecord();
				this.set('isCreatingCaseType', false);
				this.set('selectedCaseType', caseTypes[0]);
			}

			if (this.newCaseType) {
				this.set('newCaseType', null);
			}
		},

		showDeleteState() {
			this.set('deleteErrors', null);
			this.set('isDeletingCaseType', true);
		},
		hideDeleteState() {
			this.set('deleteErrors', null);
			this.set('isDeletingCaseType', false);
		},
		deleteCaseTypeAction(caseType) {
			const result = defer();

			this.send('deleteCaseType', caseType, result);

			return result.promise;
		},

		showEditModal() {
			this.set('isEditingCaseType', true);
		},
		showDuplicateModal() {
			this.set('isDuplicatingCaseType', true);
		},
		hideCaseTypeModal() {
			this.set('isEditingCaseType', false);
			this.set('isCreatingCaseType', false);
			this.set('isConfiguringStages', false);
			this.set('isDuplicatingCaseType', false);
		},

		selectSubTab(selection = '') {
			//* Do nothing and return null if there is no selection OR selection is not a string OR selection is not an available caseTypeSubTab
			if (
				!selection ||
				typeof selection != 'string' ||
				!this.caseTypeSubTabs.includes(selection.trim())
			)
				return null;

			this.set('selectedTab', selection.trim());
			return true;
		},
	},
});
