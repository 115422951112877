/** @format */

import { sort } from '@ember/object/computed';
import { A } from '@ember/array';
import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { paralegalDisplay } from 'case-status/helpers/paralegal-display';
import { inject as service } from '@ember/service';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend({
	searchString: null,
	lastNameSort: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	isBulkAction: false,
	company: service(),
	permissions: service(),

	init() {
		this._super(...arguments);

		this.set('paralegalsToAdd', []);
		this.set('isLoading', true);
		this.set('model', []);
		this.fetchNewData();
	},

	//* Reset properties using lifecycle methods
	didInsertElement() {
		$('html').addClass('has-modal');
		this.set('paralegalsToAdd', A());
	},

	willDestroyElement() {
		$('html').removeClass('has-modal');
		this.set('paralegalsToAdd', A());
		this.model.forEach((paralegal) => {
			paralegal.set('isSelected', false);
		});
	},

	async fetchNewData() {
		this.set('isLoading', true);

		if (this.isDestroyed || this.isDestroying) {
			return;
		}

		const paralegals = await this.store.findAll('paralegal', { reload: true });
		this.model.clear();
		this.model.pushObjects(paralegals.toArray());
		this.set('isLoading', false);
	},

	sortedAddParalegals: sort('paralegalsToAdd', 'lastNameSort'),
	sortedParalegals: sort('filteredParalegals', 'lastNameSort'),

	//Filters out paralegals based on the search text from the filteredModel set in the modelObserver
	filteredParalegals: computed(
		'clientSearchText',
		'filteredModel',
		'searchString',
		'paralegalsToAdd',
		function () {
			const paralegals = this.filteredModel;
			const paralegalsToAddIds = this.paralegalsToAdd.mapBy('id');
			if (!paralegals) {
				return null;
			}
			const searchText = this.searchString;
			if (searchText && searchText !== '') {
				return A(
					paralegals
						.map((paralegal) => {
							if (paralegalsToAddIds.includes(paralegal.get('id'))) {
								paralegal.set('isSelected', true);
							} else {
								paralegal.set('isSelected', false);
							}
							return paralegal;
						})
						.filter((paralegal) => {
							return paralegal
								.get('name')
								.toLowerCase()
								.includes(searchText.toLowerCase());
						}),
				);
			}
			return paralegals.map((paralegal) => {
				if (paralegalsToAddIds.includes(paralegal.get('id'))) {
					paralegal.set('isSelected', true);
				} else {
					paralegal.set('isSelected', false);
				}

				return paralegal;
			});
		},
	),

	//Watches the model and removes paralegals on the case
	modelObserver: observer('model.[]', 'case.paralegals.[]', function () {
		const filteredModel = [];
		if (!this.model) {
			this.set('filteredModel', []);
			return;
		} else if (!this.isBulkAction) {
			const selectedParalegalIds = this.paralegalsToAdd.mapBy('id');
			const currentIds = this.get('case.paralegals').mapBy('id');

			//Remove paralegals already on the case
			this.model.forEach((paralegal) => {
				if (selectedParalegalIds.includes(paralegal.get('id'))) {
					paralegal.set('isSelected', true);
				}
				const emailAddress = paralegal.get('emailAddress');
				const hasCaseStatusEmail =
					emailAddress && emailAddress.includes('@casestatus.com');

				if (
					currentIds.indexOf(paralegal.get('id')) === -1 &&
					!hasCaseStatusEmail
				) {
					filteredModel.push(paralegal);
				}
			});
		} else {
			this.model.forEach((paralegal) => {
				const emailAddress = paralegal.get('emailAddress');
				const hasCaseStatusEmail =
					emailAddress && emailAddress.includes('@casestatus.com');
				if (!hasCaseStatusEmail) {
					filteredModel.push(paralegal);
				}
			});
		}

		this.set('filteredModel', filteredModel);
	}).on('init'),

	paralegalsToAddCount: computed('paralegalsToAdd.[]', function () {
		const paralegalsToAdd = this.paralegalsToAdd;
		return paralegalsToAdd.get('length');
	}),

	actions: {
		addParalegalsButtonPressed() {
			const result = defer();
			this.set('errors', null);
			const firmSettings = this.permissions.currentUser.get(
				'user.firm.firmSettings.firmSettings',
			);

			if (!this.isBulkAction) {
				if (this.paralegalsToAddCount === 0) {
					this.set('errors', [
						`At least one ${paralegalDisplay([
							this.company,
						]).toLowerCase()} should be selected`,
					]);
					result.reject();
				} else if (
					this.case?.get('paralegals.length') + this.paralegalsToAddCount >
					firmSettings.max_paralegals
				) {
					this.set('errors', [
						`You can only add up to ${
							firmSettings.max_paralegals
						} ${paralegalDisplay([
							this.company,
							5,
							true,
						]).toLowerCase()} per ${dynamicCaseLabel([this.company])}.`,
					]);
					result.reject();
				} else {
					this.addParalegalsToCase(this.paralegalsToAdd)
						.then(() => {
							result.resolve();
							this.set('paralegalsToAdd', []);
						})
						.catch((resp) => {
							this.set('errors', Errors.mapResponseErrors(resp));
							result.reject();
						});
				}
			} else {
				if (this.paralegalsToAddCount === 0) {
					this.set('errors', [
						`At least one ${paralegalDisplay([
							this.company,
						]).toLowerCase()} should be selected`,
					]);
					result.reject();
				} else if (this.paralegalsToAddCount > firmSettings.max_paralegals) {
					this.set('errors', [
						`You can only add up to ${
							firmSettings.max_paralegals
						} ${paralegalDisplay([
							this.company,
							5,
							true,
						]).toLowerCase()} with bulk actions.`,
					]);
					result.reject();
				} else {
					this.addParalegalsToCase(this.paralegalsToAdd)
						.then(() => {
							result.resolve();
							this.set('paralegalsToAdd', []);
						})
						.catch((resp) => {
							this.set('errors', Errors.mapResponseErrors(resp));
							result.reject();
						});
				}
			}

			return result.promise;
		},

		onParalegalToggled(row, isAddingParalegal) {
			const paralegal = row.get('content');
			if (isAddingParalegal) {
				this.paralegalsToAdd.pushObject(paralegal);
			} else {
				this.paralegalsToAdd.removeObject(paralegal);
			}
		},
	},
});
