/** @format */

import Component from '@ember/component';
import $ from 'jquery';

export default Component.extend({
	init() {
		this._super(...arguments);
		$('html').addClass('has-modal');
	},
	didInsertElement() {
		$('html').addClass('has-modal');
	},
	willDestroyElement() {
		$('html').removeClass('has-modal');
	},
	actions: {
		close() {
			if (this.close && typeof this.close == 'function') {
				this.close();
			}
		},
	},
});
