/** @format */

import { belongsTo, attr } from '@ember-data/model';
import User from 'case-status/models/user';
import { buildValidations, validator } from 'ember-cp-validations';
import ValidationErrors from '../constants/errors';

const Validations = buildValidations({
	emailAddress: [
		validator('presence', {
			presence: true,
			message: ValidationErrors.presenceError,
			description: 'Email Address',
		}),
		validator('format', {
			type: 'email',
			message: 'Email Address is not valid',
		}),
	],
	cases: validator('has-many'),
});

export default User.extend(Validations, {
	bio: attr('string'),
	businessNumber: attr('string'),
	contactEmailAddress: attr('string'),
	wantsDailyDigest: attr('boolean'),

	//* Relationships
	profilePicture: belongsTo('profile-picture'),
	organization: belongsTo('organization', { async: false }),

	//TODo Prep for polymorphic upgrade
	// subUser: belongsTo('sub-user'),
	user: belongsTo('user'),
	userChild: belongsTo('user-child'),
});
