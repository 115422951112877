/** @format */

import Model, { belongsTo, attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Model.extend({
	content: attr('string'),
	completedDate: attr('date'),
	newDueDate: attr(), //* Nullable

	position: attr('number'),

	//* Relationships
	case: belongsTo('case'),
	chat: belongsTo('chat'),
	assignees: hasMany('user', { polymorphic: true }),

	dueDateDisplay: computed('newDueDate', function () {
		if (this.newDueDate) {
			return moment.utc(this.newDueDate).local().format('MM/DD/YY');
		} else {
			return null;
		}
	}),

	isDueSoon: computed('newDueDate', function () {
		if (this.newDueDate && moment(this.newDueDate, 'YYYY-MM-DD').isValid()) {
			const dueDate = moment(this.newDueDate).toDate();

			const isFuture = dueDate > new Date();
			const isWithin7Days = moment().subtract(1, 'd').toDate() <= dueDate;
			const isNotMoreThan7DaysOut = moment().add(1, 'd').toDate() >= dueDate;

			return isFuture && isWithin7Days && isNotMoreThan7DaysOut;
		} else {
			return null;
		}
	}),

	isOverdue: computed('newDueDate', function () {
		if (this.newDueDate && moment(this.newDueDate, 'YYYY-MM-DD').isValid()) {
			return new Date() >= moment(this.newDueDate).toDate();
		} else {
			return null;
		}
	}),
});
