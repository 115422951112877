/** @format */

import Controller from '@ember/controller';
import { getOwner } from '@ember/application';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AppointmentsController extends Controller {
	@service currentUser;
	@service permissions;
	@service store;
	@service thirdParty;
	showNewAppointmentForm = false;

	get appointmentCards() {
		let cards = [];
		let appointments = A(this.model.appointments);
		appointments.forEach((appointment) => {
			cards.push({
				appointment,
				editing: false,
			});
		});
		return cards;
	}

	get pastAppointments() {
		return this.appointmentCards
			.filter((card) => {
				return card.appointment.isPast;
			})
			.reverse();
	}

	get pendingAppointments() {
		return this.appointmentCards.filter((card) => {
			return !card.appointment.isPast;
		});
	}

	@action
	onAfterSave(changeset, card) {
		this.showNewAppointmentForm = false;
		if (card) {
			card.editing = false;
		}
		if (changeset) {
			const currentRouteInstance = getOwner(this).lookup(
				`route:app.firm.case.appointments`,
			);
			currentRouteInstance.refresh();
		}
	}

	@action
	onAfterDelete() {
		const currentRouteInstance = getOwner(this).lookup(
			`route:app.firm.case.appointments`,
		);
		currentRouteInstance.refresh();
	}
}
