/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	description: attr('string'),
	name: attr('string'),
	number: attr('number'),

	caseTypeTemplate: belongsTo('case-type-template'),
	firm: belongsTo('firm'),
});
