/** @format */

import { observer } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Errors from '../../../constants/errors';
import { defer } from 'rsvp';

export default Controller.extend({
	isShowingNewClientModal: false,
	isShowingEditClientModal: false,
	isShowingDeleteClientModal: false,
	notifications: service(),
	store: service('store'),

	searchString: null,
	page: 1,
	sort: '+name',

	queryChanged: observer('searchString', 'sort', function () {
		debounce(this, this.fetchNewData, 500);
	}),

	fetchNewData(clearFilters) {
		let queryParams = { filter: {}, page: {} };

		queryParams.page['size'] = 20;

		if (this.searchString && /[0-9]/g.test(this.searchString)) {
			queryParams.filter['cell_phone'] = this.searchString;
		} else {
			queryParams.filter['name'] = this.searchString;
		}
		if (clearFilters) {
			queryParams.filter = {};
			this.send('clearSearchString');
		}
		queryParams.sort = this.sort;

		this.set('queryParams', queryParams);

		this.store.query('client', queryParams).then((clients) => {
			this.model.clear();
			this.model.pushObjects(clients.toArray());
			this.set('meta', clients.get('meta'));
			this.set('page', 1);
			this.set('isLoading', false);
		});
	},

	actions: {
		clearSearchString() {
			this.set('searchString', '');
		},

		selectSearchType(value) {
			this.set('searchType', value);
		},

		toggleIsShowingNewClientModal() {
			this.set('errors', null);
			this.toggleProperty('isShowingNewClientModal');
		},

		saveNewClient(client) {
			const newClient = this.store.createRecord('client', client);
			return this.saveClientHelper(newClient, 'new');
		},

		toggleIsShowingEditClientModal(clientId) {
			this.set('errors', null);
			if (!this.isShowingEditClientModal) {
				this.set('selectedClient', this.store.peekRecord('client', clientId));
				this.set('isShowingEditClientModal', true);
			} else {
				this.set('isShowingEditClientModal', false);
			}
		},

		toggleIsShowingDeleteClientModal(clientId) {
			this.set('errors', null);
			if (!this.isShowingDeleteClientModal) {
				this.set('selectedClient', this.store.peekRecord('client', clientId));
				this.set('isShowingEditClientModal', false);
				this.set('isShowingDeleteClientModal', true);
			} else {
				this.set('isShowingDeleteClientModal', false);
			}
		},

		fetchMoreClients() {
			//if already loading clients, debounce fetching more else it might not happen
			if (this.isLoading === true) {
				debounce(
					this,
					() => {
						this.send('fetchMoreClients');
					},
					500,
				);
				return false;
			}

			if (this.get('meta.total_pages') > this.page) {
				this.set('isLoading', true);
				this.incrementProperty('page');
				const queryParams = this.queryParams;
				queryParams.page.number = this.page;
				this.store.query('client', queryParams).then((clients) => {
					this.model.pushObjects(clients.toArray());
					this.set('meta', clients.get('meta'));
					this.set('isLoading', false);
				});
			}
		},

		saveClient(client) {
			return this.saveClientHelper(client, 'editing');
		},

		deleteClient(client) {
			return this.saveClientHelper(client, 'deleting');
		},

		updateSort(sortColumn, sortDirection) {
			const sortString = `${sortDirection}${sortColumn.underscore()}`;
			this.set('sort', sortString);
			this.set('page', 1);
		},

		showClientOpenCases(client, isAll) {
			const clientName = client.name;

			this.transitionToRoute('app.firm.cases').then((casesRoute) => {
				casesRoute.controller.set('searchString', clientName);
				casesRoute.controller.set('searchType', 'client');
				casesRoute.controller.set('allCases', isAll);
				casesRoute.controller.setProperties({
					'preferences.myCases': false,
					closed: false,
					onHold: false,
					onboarding: false,
					removed: false,
				});
			});
		},
	},

	saveClientHelper(client, status) {
		this.notifications.clearAll();
		this.set('errors', null);

		const result = defer();
		const endpoint = status == 'deleting' ? 'delete' : 'save';

		this.send(endpoint, client, result);

		result.promise
			.then(() => {
				this.notifications.success(
					`${status == 'deleting' ? 'Deleted' : 'Saved'} client ${client.get(
						'name',
					)} successfully!`,
					{
						autoClear: true,
						clearDuration: 7000,
						cssClasses: 'notification--success',
					},
				);

				switch (status) {
					case 'new':
						this.toggleProperty('isShowingNewClientModal');
						//Refetch the data for the new client
						this.fetchNewData();
						break;
					case 'deleting':
						this.toggleProperty('isShowingDeleteClientModal');
						this.fetchNewData(true);
						break;
					default:
						this.toggleProperty('isShowingEditClientModal');
				}
			})
			.catch((err) => {
				//* Only omit the error when updating the client
				// todo for email as well
				if (
					!client?.get('id') ||
					(client?.get('id') &&
						!Errors.mapResponseErrors(err).includes(
							'Phone Number already exists!',
						))
				) {
					this.set('errors', Errors.mapResponseErrors(err));
				}
			});

		return result.promise;
	},
});
