/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import $ from 'jquery';
import Errors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';

@classic
export default class TemplateChecklistItemsComponent extends Component {
	@tracked addingItem = false;
	@tracked checklistItems = [];
	@tracked shouldRefreshList = false;
	@service notifications;

	get showNewForm() {
		return this.addingItem;
	}

	@action
	clickAddItem() {
		this.set('addingItem', true);
	}

	@action
	cancelAddItem() {
		this.set('addingItem', false);
	}

	@action
	updateItemPositions() {
		const checklistItems = this.checklistItems;

		checklistItems.forEach((item, i) => {
			item.set('position', i);
			item.save().catch((err) => {
				this.notifications.clearAll();
				this.notifications.error(Errors.mapResponseErrors(err), {
					canClose: true,
					autoClear: true,
					clearDuration: 2000,
				});
			});
		});

		//? there is a bug in the plugin where the opacity isn't resetting after dragEnd
		const targets = $('.draggable-object');
		targets.each((i, el) => {
			$(el).attr('style', null);
		});
	}

	@action refreshList() {
		this.set('shouldRefreshList', true);
		//* Must offset or the original list does not destroy. Even 1 ms achieves the goal but still has a bit of flashing
		setTimeout(() => {
			this.set('shouldRefreshList', false);
		}, 1);
	}
}
