/** @format */

import Component from '@ember/component';

export default Component.extend({
	classNames: ['image-lightbox'],

	actions: {
		hideImg() {
			this.hideImage();
		},
	},
});
