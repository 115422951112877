/** @format */

import moment from 'moment';
import { inject as service } from '@ember/service';
import { captureException } from 'logrocket';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const notifOptions = {
	canClose: true,
	autoClear: true,
	clearDuration: 5000,
};
export default class DisplayChecklistItem extends Component {
	@tracked editingItem = false;
	@tracked deletingItem = false;
	@service currentUser;
	constructor() {
		super(...arguments);
		this.notifications = service();
		// Args are automatically tracked
		this.item = this.args.item;
		this.isEditable = this.args.isEditable;
		this.isEditing = this.args.isEditing;
		this.showCheckedItems = this.args.showCheckedItems;
		this.refreshCase = this.args.refreshCase;
		this.deleteCallback = this.args.deleteCallback;
		this.isTemplate = this.args.isTemplate;
		this.index = this.args.index;
		this.draggable = this.args.draggable;
		this.isPreview = this.args.isPreview;
		this.isMobile = this.args.isMobile;
	}

	get itemChecked() {
		return this.item.completedDate;
	}

	get showItem() {
		if (!this.showCheckedItems) {
			return !this.itemChecked;
		}
		return this.itemChecked;
	}

	@action
	checkItem() {
		if (this.isTemplate || this.item.completedDate) return false;
		const tz = this.currentUser?.user?.firm.timezone || 'America/New_York';
		this.item.completedDate = moment().tz(tz).utc().toDate();
		return this.item
			.save()
			.then(() => {
				if (this.refreshCase && typeof this.refreshCase == 'function') {
					return this.refreshCase();
				}
			})
			.catch(() => {
				this.item.rollbackAttributes();
				this.notifications.error(
					'There was an error completing this task. Please try again.',
					notifOptions,
				);
			});
	}
	@action
	editClicked() {
		this.editingItem = true;
		this.item.isEditing = true;
	}
	@action
	cancelEditItem() {
		this.editingItem = false;
		this.item.isEditing = false;
	}
	@action
	deleteClicked() {
		this.deletingItem = true;
	}
	@action
	cancelDelete() {
		this.deletingItem = false;
	}
	@action
	confirmDelete() {
		return this.item
			.destroyRecord()
			.then(() => {
				//* Run the deleteCallback if present and is a function
				if (this.deleteCallback && typeof this.deleteCallback == 'function') {
					this.deleteCallback();
				}

				if (this.refreshCase && typeof this.refreshCase == 'function') {
					return this.refreshCase();
				}
			})
			.catch((err) => {
				this.deletingItem = false;
				this.item.rollbackAttributes();
				captureException(err);
			});
	}
}
