/** @format */

import { helper } from '@ember/component/helper';
import { pluralize } from 'ember-inflector';

/**
 * _addToCaseFormattedActionBtn()_
 * ==
 * Title Formatter for modal-add-attorneys and modal-add-paralegals
 * @type [required] Main title word to be used (ex: Paralegal, Attorney, or Solicitor)
 * @isBulkAction [optional] defaults to false
 * @caseCount [optional] defaults to 0
 * @isPlural [optional] defaults to true
 * @helper Also available as a hbs helper as add-to-case-formatted-title
 * @returns Dynamically formatted String
 *
 * -- _[Created by Matthew Aderhold on 9/6/19]_ --
 */
export function addToCaseFormattedActionBtn([
	type = '',
	typeCount = 0,
	isBulkAction = false,
	caseCount = 0,
	isPlural = true,
	caseLabel = 'case',
	isRemoving = false,
]) {
	if (!type) return null;
	const noCount = { withoutCount: true };
	const caseCountText = new String(caseCount);
	caseCount = caseCount && typeof caseCount == 'string' ? 0 : caseCount;
	type = type.toLowerCase();

	if (isPlural) {
		type = pluralize(typeCount, type, noCount);
	}

	if (!isBulkAction) {
		return `${isRemoving ? 'Remove' : 'Add'} (${typeCount}) ${type} ${
			isRemoving ? 'from' : 'to'
		} ${caseLabel}`;
	} else {
		const cases = pluralize(caseCount, caseLabel, noCount);
		return `${isRemoving ? 'Remove' : 'Add'} (${typeCount}) ${type} ${
			isRemoving ? 'from' : 'to'
		} (${caseCountText}) ${cases}`;
	}
}

export default helper(addToCaseFormattedActionBtn);
