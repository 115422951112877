/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	classNames: ['expandable-toggle-wrapper'],

	actionText: computed('isExpanded', function () {
		return this.isExpanded ? 'Collapse' : 'Expand';
	}),
	actionIcon: computed('isExpanded', function () {
		return this.isExpanded ? 'img/collapse.svg' : 'img/expand.svg';
	}),

	actions: {
		onClick() {
			const onClick = this.onClick;

			onClick();
		},
	},
});
