import Service, { inject as service } from '@ember/service';
import { errorNotifOptions } from 'case-status/constants/notif-options';
import Errors from 'case-status/constants/errors';
import { captureException } from 'logrocket';
import ENV from 'case-status/config/environment';
import { doesExist, isObject } from 'case-status/constants/data-types';

export default class ErrorHandlingService extends Service {
	constructor() {
		super(...arguments);

		this.isProduction = Boolean(
			`${ENV.environment}`.toLowerCase() === 'production',
		);
	}

	@service notifications;

	async report(err, lrInfo) {
		if (this.isProduction) {
			try {
				const info = doesExist(lrInfo)
					? isObject(lrInfo)
						? lrInfo
						: { extra: JSON.stringify(lrInfo) }
					: null;
				captureException(err, info);
			} catch (error) {
				//* At least give us visibility into log rocket reporting not working as intended
				console.warn(error);
				//* Toss it into the void afterwards
				void error;
			}
		}
	}

	async catch(err, lrInfo) {
		//* Make sure we have full visibility on the error;
		console.error(err);
		this.notifications.error(Errors.mapResponseErrors(err), errorNotifOptions);
		this.report(err, lrInfo);
		return Promise.reject(err);
	}

	async catchSilently(err, lrInfo) {
		//* Make sure we have full visibility on the error;
		console.error(err);
		this.report(err, lrInfo);
		return Promise.resolve(err);
	}

	async notify(...args) {
		this.notifications.error(Errors.mapResponseErrors(args), errorNotifOptions);
		return Promise.resolve();
	}
}
