import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if showChecklistItems}}\n\t<div class='row'>\n\t\t<div class='card checklist-card col-xs-12'>\n\t\t\t<h2>\n\t\t\t\tChecklist\n\t\t\t</h2>\n\t\t\t{{attorney-checklist-items\n\t\t\t\tchecklistItems=@model.checklistItems\n\t\t\t\tcaseId=@model.id\n\t\t\t\tdisableAddingNewItems=true\n\t\t\t\tallowEdit=true\n\t\t\t\tisMobile=true\n\t\t\t}}\n\t\t</div>\n\t</div>\n{{/if}}", {"contents":"{{#if showChecklistItems}}\n\t<div class='row'>\n\t\t<div class='card checklist-card col-xs-12'>\n\t\t\t<h2>\n\t\t\t\tChecklist\n\t\t\t</h2>\n\t\t\t{{attorney-checklist-items\n\t\t\t\tchecklistItems=@model.checklistItems\n\t\t\t\tcaseId=@model.id\n\t\t\t\tdisableAddingNewItems=true\n\t\t\t\tallowEdit=true\n\t\t\t\tisMobile=true\n\t\t\t}}\n\t\t</div>\n\t</div>\n{{/if}}","moduleName":"case-status/components/mobile-firm-checklist-tab.hbs","parseOptions":{"srcName":"case-status/components/mobile-firm-checklist-tab.hbs"}});
/** @format */

import Component from '@glimmer/component';

export default class MobilClientChecklistTabComponent extends Component {
	get showChecklistItems() {
		const checklistItems = this?.args.model?.get('checklistItems') || [];

		return Boolean(checklistItems.filter((ci) => !ci.completedDate).length);
	}
}
