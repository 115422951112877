/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import $ from 'jquery';

export default Component.extend({
	classNames: ['validated-ssn-input'],
	show: false,
	name: 'validated-ssn-input',
	required: true,

	options: computed(function () {
		return {
			showMaskOnHover: false,
			autoUnmask: true,
		};
	}),

	obfuscatedValue: computed('value', 'show', function () {
		const value = this.value || '';
		const length = value.length;
		let obfuscation = '';

		switch (length) {
			case 0:
				obfuscation = '';
				break;
			case 1:
				obfuscation = 'X';
				break;
			case 2:
				obfuscation = 'XX';
				break;
			case 3:
				obfuscation = 'XXX-';
				break;
			case 4:
				obfuscation = 'XXX-X';
				break;
			default:
				obfuscation = 'XXX-XX-';
				break;
		}

		return obfuscation + value.substring(5);
	}),

	computedValue: computed('value', 'show', function () {
		const show = this.show;

		if (show) {
			return this.value;
		} else {
			return this.obfuscatedValue;
		}
	}),

	init() {
		this._super();

		//* Allow them to see what they are doing if there isn't already a value
		if (!this.value) {
			this.set('show', true);
		}
	},

	handleUpdate(...args) {
		const [val1] = args;
		this.set('value', val1);
	},

	handleOnFocus() {
		//* This fires when the input is tabbed into but doesn't change the focus oddly.
		//* So need to be sure to make the tabindex -1 on the input being "skipped"
		$('#hidden-ssn').focus();
	},

	actions: {
		toggleShow() {
			this.toggleProperty('show');
		},
	},
});
