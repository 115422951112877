/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Errors from 'case-status/constants/errors';
import { next, debounce } from '@ember/runloop';

@classic
export default class ModalSelectMessageTemplate extends Component {
	defaultTemplate = '<i>Hover over a message to see its contents.</i>';
	@tracked currentTemplate = null;
	@tracked usageType = null;

	@service currentUser;
	@service company;
	@service permissions;
	@service store;
	@service notifications;
	notifOptions = {
		canClose: true,
		autoClear: true,
		clearDuration: 5000,
	};

	@tracked isLoading = true;
	@tracked shouldShowRefreshButton = false;

	//* This is the target to apply the template to, theCase prop is required along with this
	@tracked applyTo = null;
	@tracked theCase = null;

	@tracked templates = A([]);

	get filteredTemplates() {
		const templates = this.templates;

		//* These are the templates that are used by the API when sending automated messages
		//* Therefore they require more relationships than just the case provided
		//* So we filter them out to prevent unavailable shortcodes from being used in normal messages.
		const bannedTemplateTypes = [
			'status_changed',
			'appointment',
			'appointment_confirmation_request',
			'appointment_confirmation_receipt',
			'upcoming_appointment',
			'treatment_followup',
			'case_hold',
			'status_update',
			'case_closed_modal_title',
			'case_closed_modal_description',
		];
		const bannedTemplateTypes2 = [
			'case_closed',
			'completed_checklist_items',
			'completed_checklist_item',
			'created_checklist_items',
			'created_checklist_item',
			'case_off_hold',
			'case_reopened',
			'due_checklist_item',
			'overdue_checklist_item',
			'updated_appointment',
			'deleted_appointment',
		];

		const finalBannedTemplates =
			this.usageType == 'message'
				? [...bannedTemplateTypes, ...bannedTemplateTypes2]
				: bannedTemplateTypes;

		const filteredTemplates = templates.filter((temp) => {
			return !finalBannedTemplates.includes(temp.templateType);
		});

		return filteredTemplates;
	}

	set filteredTemplates(value) {
		//* Allowing to override the template list without getting errors
		//* If no setter is created and set is called anywhere, a fatal error is thrown
		if (this.isDestroyed || this.isDestroying) return;
		if (Array.isArray(value)) {
			this.set('templates', value);
		} else {
			this.set('templates', [value]);
		}
	}

	async init() {
		super.init(...arguments);

		await this.fetchNewData();

		if (!this.isDestroyed || !this.isDestroying) {
			this.set('currentTemplate', this.defaultTemplate);
		}
	}

	searchString = '';

	get searchTerm() {
		return this.searchString;
	}

	set searchTerm(value) {
		this.set('searchString', value);
		debounce(this, this.fetchNewData, 500);
	}

	async fetchNewData() {
		//* Set the isLoading state property to true
		this.set('isLoading', true);

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			include_defaults: 'true',
		};

		//* Set the filter property on the queryParams to our computed filters property
		if (this.searchString) {
			queryParams.filter.title = this.searchString;
		}

		//* Set the queryParams
		this.set('queryParams', queryParams);

		try {
			const templates = await this.store.query('message-template', queryParams);
			if (!this.isDestroyed || !this.isDestroying) {
				this.set('templates', templates.toArray());
			}

			if (!this.isDestroyed || !this.isDestroying) {
				this.set('isLoading', false);
			}
		} catch (err) {
			if (!`${err}`.includes('calling set on destroyed object')) {
				//* Sometimes the user will navigate away before this request finishes, resulting in the set being called on a destroyed object, no need to display the notif in that case
				this.notifications.error(
					`Error loading Message Templates: ${Errors.mapResponseErrors(err)}`,
					this.notifOptions,
				);
			}
		}
	}

	// willDestroy() {
	//   //? Might need to make sure to check the route to intelligently unload
	//   //? Experiment with the manage button taking the user to the message-templates route
	//   //? This might make some if not all templates disappear.
	//   this.store.unloadAll('message-template');
	// }

	@action
	applyTemplate(template) {
		if (
			this.selectMessageTemplate &&
			typeof this.selectMessageTemplate == 'function'
		) {
			this.selectMessageTemplate(template);
		} else if (this.applyTo != undefined) {
			const appliedTemplate = template.content;

			if (!this.isDestroyed || !this.isDestroying) {
				this.set('applyTo', appliedTemplate);
			}

			this.closeAction();
		}
	}

	@action
	showRefreshButton(a) {
		this.shouldShowRefreshButton = true;
	}

	@action
	displayTemplate(content) {
		//* Check if the content exists and is truthy is trimmed
		//* (using interpolation to ensure the string method exists)
		if (
			content &&
			`${content}`.trim() &&
			(!this.isDestroyed || !this.isDestroying)
		) {
			this.set('currentTemplate', content);
		} else if (!this.isDestroyed || !this.isDestroying) {
			//* If the content resolves as falsy, set the content back to the defaultTemplate
			this.set('currentTemplate', this.defaultTemplate);
		}
	}

	@action
	closeAction() {
		this.set('searchTerm', '');
		this.shouldShowRefreshButton = false;
		if (this.close && typeof this.close == 'function') {
			next(() => {
				this.close();
			});
		} else {
			document
				.getElementById('modal-add-message-template')
				?.classList?.add('hidden');
		}
	}

	@action
	async refresh() {
		await this.fetchNewData();
	}
}
