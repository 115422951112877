/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import FileHandler from '../modules/FileHandler';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Changeset from 'ember-changeset';

@classic
export default class DocumentList extends Component {
	//* Tracked variables passed in
	@tracked documents = [];
	@tracked caseId = null;

	@service currentUser;
	@service permissions;
	@service router;
	@service ajax;
	@service notifications;
	@service activityTracking;

	@tracked selectedDocument = null;
	@tracked documentSearchTerm = '';

	@tracked authorizedToDeleteDoc = true;

	get sortedFiles() {
		return this.searchedFiles?.sortBy(this.documentSort) || [];
	}

	get allowExportAll() {
		const userType = this.currentUser.userType;
		const currentRoute = this.router.currentRoute.name;

		//? Currently the API only allows Export All Documents for the Case Documents only
		if (currentRoute !== 'app.firm.case.info') {
			return false;
		}

		//? Currently the API only allows Export All Documents for non-client Firm users
		if (userType == 'attorney' || userType == 'paralegal') {
			return true;
		} else {
			return false;
		}
	}

	get showDocumentIntegrationStatus() {
		const currentRoute = this.router.currentRoute.name;

		if (
			currentRoute === 'app.firm.case.info' &&
			this.currentUser.user.firm.usesIntegration
		) {
			return true;
		} else {
			return false;
		}
	}

	@tracked exporting = false;
	@tracked exported = false;

	get searchedFiles() {
		const searchTerm = this.documentSearchTerm.toLowerCase();
		return this.documents?.filter((file) => {
			const fileName = file.get('fileName')?.toLowerCase();
			const formattedCreatedAt = file.get('formattedCreatedAt')?.toLowerCase();
			return (
				fileName.indexOf(searchTerm) > -1 ||
				formattedCreatedAt.indexOf(searchTerm) > -1
			);
		});
	}

	@tracked selectedDocumentSortOption = {
		label: 'Uploaded Date',
		value: 'sortableCreatedAt:desc',
	};

	get documentSort() {
		return this.selectedDocumentSortOption.value;
	}

	@tracked showViewDocumentModal = false;

	documentSortOptions = [
		{
			label: 'Uploaded Date',
			value: 'sortableCreatedAt:desc',
		},
		{
			label: 'File Name',
			value: 'fileName',
		},
	];

	@tracked renamingDocument = false;

	@action
	deleteDocument(document) {
		document.toggleProperty('confirmDelete');
	}

	@action
	cancelDeleteDocument(document) {
		document.toggleProperty('confirmDelete');
	}

	@action
	downloadDocument(document) {
		FileHandler.download(document.get('url'), document.get('fileName'));
	}

	@action
	confirmDeleteDocument(document) {
		return document.destroyRecord();
	}

	@action
	viewDocument(document) {
		this.set('selectedDocument', document);
		this.toggleProperty('showViewDocumentModal');
		this.activityTracking.trackByCase(this.caseId, 'viewedFile');
	}

	@action
	closeViewDocumentModal() {
		this.set('selectedDocument', null);
		this.toggleProperty('showViewDocumentModal');
	}

	@action
	selectDocumentSort(option) {
		this.set('selectedDocumentSortOption', option);
	}

	@action
	showLightboxImage(img) {
		this.set('lightboxImage', img);
	}

	@action
	hideLightboxImage() {
		this.set('lightboxImage', null);
	}

	@action
	sendEsignReminder(signatory) {
		if (this.sending) {
			this.notifications.clearAll();
			this.notifications.warning(
				'Reminder is being sent, please wait before sending another reminder',
			);
		} else {
			this.set('sending', true);
			const signatoryId = signatory.id;
			const url = `${ENV.host}/document_signatories/reminder/${signatoryId}`;
			const reminderSent = signatory.get('reminderSent');

			if (reminderSent) {
				this.notifications.clearAll();
				this.notifications.warning(
					'User has already been sent a reminder for this file.',
				);
			} else {
				this.ajax
					.post(url)
					.then(() => {
						this.set('sending', false);
						signatory.set('reminderSent', true);
						this.notifications.clearAll();
						this.notifications.success('Reminder has been sent', {
							autoClear: true,
							clearDuration: 5000,
						});
					})
					.catch(() => {
						this.set('sending', false);
						this.notifications.clearAll();
						this.notifications.error(
							'An error has occurred. Reminder was not sent',
						);
					});
			}
		}
	}

	@action
	exportAllDocuments() {
		const url = `${ENV.host}/cases/${this.caseId}/export_files`;
		this.set('exporting', true);
		return this.ajax
			.post(url)
			.then((res) => {
				this.set('exported', true);
				this.set('exporting', false);
				this.notifications.clearAll();
				if (res.message) {
					this.notifications.success(res.message, {
						canClose: true,
						autoClear: true,
						clearDuration: 10000, // Clear self after 10 seconds
					});
				}
			})
			.catch(() => {
				this.set('exporting', false);
				this.set('exported', false);
				this.notifications.clearAll();
				this.notifications.error(
					'An error has occurred requesting case documents.',
					{
						canClose: true,
						autoClear: true,
					},
				);
			});
	}

	@action
	toggleRenameDocumentModal(document) {
		this.set('renamingDocument', true);
		if (document) {
			this.set('documentToRename', new Changeset(document));
			this.set('document', document);
			this.set('ogDocumentName', document.fileName);
		} else {
			this.set('documentToRename', null);
			this.set('document', null);
			this.set('ogDocumentName', null);
			this.set('renamingDocument', false);
		}
	}

	@action
	renameDocument() {
		const document = this.documentToRename;
		return document
			.save()
			.then(() => {
				this.toggleRenameDocumentModal();
			})
			.catch(() => {
				this.document.fileName = this.ogDocumentName;
				this.notifications.clearAll();
				this.notifications.error(
					'An error has occurred. Unable to rename file. Please refresh and try again.',
				);
			});
	}
}
