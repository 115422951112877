/** @format */

import { modifier } from 'ember-modifier';

export default modifier((element, [eventName, listener]) => {
	element.addEventListener(eventName, listener);

	return () => element.removeEventListener(eventName, listener);
});

// list of events: https://www.w3schools.com/jsref/dom_obj_event.asp

/* 
    Use directly on elements to apply listeners

    `
        <div {{add-event-listener "scroll" this.listener}}>
            ...
        </di`v>
    `
*/
