/** @format */

import Route from '@ember/routing/route';
import FileHandler from 'case-status/modules/FileHandler';
import { inject as service } from '@ember/service';

export default class AppFileRoute extends Route {
	@service ajax;
	async model({ file_id }) {
		const file = await this.store.findRecord('file', file_id);
		FileHandler.download(file.get('url'), file.get('fileName'));
	}
}
