import { inject as service } from '@ember/service';
/** @format */

import Route from '@ember/routing/route';

export default class DownloadRoute extends Route {
	@service
	userAgent;

	beforeModel() {
		super.beforeModel(...arguments);
		if (this.userAgent.os.isIOS) {
			window.location.href =
				'https://itunes.apple.com/us/app/case-status-legal/id1268078389?ls=1&mt=8';
		} else if (this.userAgent.os.isAndroid) {
			window.location.href =
				'https://play.google.com/store/apps/details?id=com.briefcase.casestatus';
		} else {
			window.location.href = 'https://casestatus.com/download';
		}
	}
}
