/** @format */

import { defer, resolve } from 'rsvp';
import { computed, observer } from '@ember/object';
import moment from 'moment';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { debounce, next } from '@ember/runloop';

export default Component.extend({
	currentUser: service(),
	customBranding: service(),
	company: service(),
	store: service(),
	shortcode: service(),
	startDate: computed(function () {
		return moment(this.day).format('MM/DD/YYYY');
	}),
	selectedHour: 12,
	selectedMinute: '00',
	amOrPm: 'PM',
	minDate: computed(function () {
		return new Date();
	}),

	formattedScheduleDate: computed(
		'day',
		'selectedHour',
		'selectedMinute',
		'amOrPm',
		function () {
			const dayAsMoment = moment(this.day);
			const selectedMinute = this.selectedMinute;
			const amOrPm = this.amOrPm;
			let selectedHour = Number(this.selectedHour);

			if (selectedHour === 12) selectedHour = 0;
			if (amOrPm === 'PM') selectedHour += 12;

			return moment()
				.year(dayAsMoment.year())
				.month(dayAsMoment.month())
				.date(dayAsMoment.date())
				.hours(selectedHour)
				.minutes(Number(selectedMinute))
				.seconds(0);
		},
	),
	hasInviteMsg: false,

	messageObserver: observer('message', function () {
		const msgBody = this.message || '';

		if (msgBody.includes('app.casestatus.com/download')) {
			this.set('hasInviteMsg', true);
		} else {
			this.set('hasInviteMsg', false);
		}
	}).on('init'),

	inviteMsg: computed(
		'company.info.{inviteMessage,name}',
		'currentUser.user.firstName',
		function () {
			const firstName = this.get('currentUser.user.firstName');
			const firmName = this.get('company.info.name');
			const inviteMessage = this.get('company.info.inviteMessage');

			if (inviteMessage) {
				return inviteMessage;
			} else {
				return `This is ${firstName} from ${firmName}. We have started to use a new communication tool to keep you updated on the status of your ${dynamicCaseLabel(
					[this.company],
				)} and easily communicate with our firm. Please download the Case Status app with this link, <a href="https://app.casestatus.com/download" target="_blank">app.casestatus.com/download</a>. Message us through the app or reply to this message if you have any questions. Thanks, ${firstName}`;
			}
		},
	),

	init() {
		this._super(...arguments);

		this.set('hourOptions', [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
		this.set('minuteOptions', ['00', '30']);
		this.set('amOrPmOptions', ['AM', 'PM']);

		if (!this.day) {
			this.set('day', new Date());
		}

		if (this.caseId) {
			const theCase = this.store.peekRecord('case', this.caseId);
			if (theCase) {
				this.set('case', theCase);
			}
		}
	},

	showMessagePreview: false,
	messagePreview: '',
	valueObserver: observer('message', 'showMessagePreview', function () {
		if (!this.showMessagePreview) return false; //* Prevent unnecessary api calls

		debounce(this, 'getMessagePreview', 500);
	}),

	getMessagePreview() {
		const val = this.message;
		const caseId = this.caseId;
		this.shortcode
			.preview(val, caseId)
			.then((res) => {
				this.set('messagePreview', res.data?.formatted_message);
			})
			.catch(() => {
				this.set(
					'messagePreview',
					`<i class="red-text">An error has occurred.</i>`,
				);
			});
	},

	actions: {
		addInvite() {
			const hasInviteMsg = this.hasInviteMsg;
			const msgBody = this.message || '';
			const inviteMsg = this.inviteMsg || '';
			const newMsgBody = String(msgBody).trim()
				? msgBody + inviteMsg
				: inviteMsg;

			if (hasInviteMsg) return;

			this.set('message', newMsgBody);
		},
		cancel() {
			const cancel = this.cancel;

			if (this.theCase && this.theCase.get('isDirty')) {
				this.theCase.rollback();
				next(() => {
					cancel();
				});
			} else {
				cancel();
			}
		},
		setDate(date) {
			this.set('day', date);
			return date;
		},
		scheduleMessage() {
			const sendScheduledMessage = this.sendScheduledMessage;
			const message = this.message;

			const tz = this.get('currentUser.user.firm.timezone') || 'US/Eastern';
			const formattedScheduleDate = this.formattedScheduleDate.tz(tz, true);
			const now = moment(new Date()).tz(tz, true);
			this.set('errors', []);

			const result = defer();
			if (formattedScheduleDate.utc().isBefore(now)) {
				this.set('errors', ['Chose a date in the future.']);
				result.reject();
				return result;
			}

			if (!message) {
				this.set('errors', ['Include a message']);
				result.reject();
				return result;
			}

			/*  
        Due to normal messaging creates a message model, 
        the date typed attribute scheduledMessageDate needs 
        to be a date else it will make it null, but since 
        bulk schedule can have a file and thus be a multipart 
        request using FormData, the date isn't auto processed 
        into an iso format for the API so we have to make that 
        distinction.
       */
			const preparedDate = formattedScheduleDate.utc().toDate();

			sendScheduledMessage({
				message,
				day: this.isBulkAction ? preparedDate.toISOString() : preparedDate, // see comment above
				originalContent: message,
				translatedContent: this.translatedMessage,
				targetLanguage: this.targetLanguage,
				result,
				hasInvite: this.hasInviteMsg, //* Used in Bulk Schedule
				fileData: this.fileData,
				messageTemplateType: this.messageTemplateType,
				messageTemplateId: this.newMessageTemplateId,
				recommendedResponseUsed: this.recommendedResponseUsed,
			});
			result.promise.catch((err) => {
				this.set('errors', [err]);
			});
			return result;
		},
		toggleShowMessagePreview() {
			this.toggleProperty('showMessagePreview');
		},
	},
});
