/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
	checked: false,
	casesChanged: observer(
		'extra.{selectedCases,selectAllCases,deselectedCases}',
		function () {
			if (this.extra?.selectAllCases) {
				this.set(
					'checked',
					!this.extra?.deselectedCases?.includes(Number(this.value)),
				);
			} else {
				this.set(
					'checked',
					this.extra?.selectedCases?.includes(Number(this.value)),
				);
			}
		},
	),

	init() {
		this._super(...arguments);
		if (this.extra?.selectedCases) {
			let selectedCases = this.extra?.selectedCases || [];
			if (selectedCases && selectedCases.includes(Number(this.value))) {
				this.set('checked', true);
			}
		}

		if (
			this.extra?.selectAllCases &&
			this.extra?.deselectedCases?.length &&
			this.extra?.deselectedCases?.includes(Number(this.value))
		) {
			this.set('checked', false);
		} else if (this.extra?.selectAllCases) {
			this.set('checked', true);
		}
	},

	actions: {
		handleSelect(targetCase) {
			if (
				!this.extra?.selectAllCases &&
				this.tableActions?.selectCase &&
				typeof this.tableActions?.selectCase == 'function'
			) {
				this.tableActions?.selectCase(targetCase);
			} else if (
				this.extra?.selectAllCases &&
				this.tableActions?.deselectCase &&
				typeof this.tableActions?.deselectCase == 'function'
			) {
				this.tableActions?.deselectCase(targetCase);
			}
		},
	},
});
