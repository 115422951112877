/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend({
	notifications: service(),
	chatEnabled: false,
	shareStatusUpdates: false,
	shareClientNames: true,
	company: service(),

	init() {
		this._super(...arguments);
		this.set('chatEnabled', this.get('permissions.canChat'));
		this.set('shareStatusUpdates', this.get('permissions.showCaseStatus'));
		this.set('shareClientNames', this.get('permissions.showClientNames'));
	},

	actions: {
		removeCollaborator() {
			return this.removeCollaborator()
				.then(() => {
					this.notifications.success(
						`Successfully removed the collaborator from your ${dynamicCaseLabel(
							[this.company],
						)}.`,
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
					next(() => {
						this.close();
					});
				})
				.catch(() => {
					this.notifications.error(
						`An error has occurred while trying to remove a collaborator from your ${dynamicCaseLabel(
							[this.company],
						)}. Please try again later.`,
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
				});
		},
		updateCollaborator() {
			this.set('permissions.canChat', this.chatEnabled);
			this.set('permissions.showCaseStatus', this.shareStatusUpdates);
			this.set('permissions.showClientNames', this.shareClientNames);
			return this.updatePermissions()
				.then(() => {
					this.notifications.success(
						'Successfully updated collaborator permissions.',
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
					next(() => {
						this.close();
					});
				})
				.catch(() => {
					this.notifications.error(
						"An error has occurred while trying to update a collaborator's permissions. Please try again later.",
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
				});
		},
		updatePermissions(key) {
			this.set('shouldUpdate', true);
			this.set(`permissions.${key}`, !this.get(`permissions.${key}`));
		},
	},
});
