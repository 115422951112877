/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	//* Attributes
	contentType: attr('string'),
	extension: attr('string'),
	fileName: attr('string'),
	createdAt: attr('date'),
	brandingColor: attr('string'),
	activeTextHighlight: attr('string'),
	randomId: attr('string'),
	updatedAt: attr('date'),
	url: attr('string'),

	//* Relationships
	firm: belongsTo('firm'),
	organization: belongsTo('organization'),
});
