/** @format */

import Route from '@ember/routing/route';
import Errors from '../../../../constants/errors';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import BlobToBase64 from 'case-status/modules/blobToBase64';

export default Route.extend({
	currentUser: service(),
	beforeModel() {
		this._super(...arguments);
		this.send('gatekeeper', 'settings_user_management', 'settings.index');
	},
	model() {
		// return hash({
		//   attorneys: this.store.findAll('attorney', {reload: true}),
		//   paralegals: this.store.findAll('paralegal', {reload: true}),
		// });
		return A([]);
	},
	setupController(controller) {
		this._super(...arguments);
		controller.fetchNewUsersData();
	},
	actions: {
		async sendInvite(user, result) {
			const store = this.store;
			const roleModel = user.model.toLowerCase();
			const controller = this.controller;
			const profilePictureExtension = user?.stagedProfilePic?.type?.replace(
				'image/',
				'',
			);
			const stagedProfilePicture = await BlobToBase64(user.stagedProfilePic);

			user.set('profilePictureContent', stagedProfilePicture);
			user.set('profilePictureExtension', profilePictureExtension);

			//* Make sure to cache relationships to reattach them on newUser model
			const permissionsID = user.get('userPermissions.id');
			const permissions = permissionsID
				? this.store.peekRecord('userPermission', permissionsID)
				: null;

			//* Convert from a User Model to a basic vanilla object to be used to create the typed model
			const newUserObj = user.toJSON();

			//* Relationships seem to be striped when converting a Model to a vanilla object
			//* So reset the firm
			newUserObj.firm = this.currentUser.get('user.firm');
			newUserObj.userPermissions = permissions;

			//* Using the converted model object as a base, create a typed userModel with specified role
			const newUser = await store.createRecord(roleModel, newUserObj);

			//* Reset success/error states
			controller.set('inviteSentSuccess', false);
			controller.set('inviteSentErrors', null);

			newUser
				.save()
				.then(() => {
					controller.set('inviteSentSuccess', user.sendInvite);
					controller.set(
						'inviteSentSuccessMessage',
						`We sent a link to ${user.emailAddress}, that they can use to register for your firm.`,
					);
					result.resolve(newUser);
				})
				.catch((errors) => {
					controller.set('inviteSentErrors', Errors.mapResponseErrors(errors));
					result.reject(errors);
				});
		},

		deleteUser(user, result) {
			user
				.destroyRecord()
				.then(() => {
					result.resolve();
				})
				.catch((errors) => {
					result.reject(errors);
				});
		},
	},
});
