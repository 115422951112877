// These messages will be randomly selected
export const placeholderMessages = [
	'Compiling message thread history...',
	'Analyzing writing style and sentiment...',
	'Analyzing case data and past communication...',
	'Adjusting sentiment based on firm analyses...',
];

// This message will typically always be shown last
export const finalPlaceholderMessage = 'Generating Recommended Responses...';

export default {
	placeholderMessages,
	finalPlaceholderMessage,
};
