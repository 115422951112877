/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
	automationType: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Automation Type',
	}),
	content: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Content',
	}),
});

export default Model.extend(Validations, {
	automationType: attr('string'),
	lastRunDate: attr('date'),
	content: attr('string'),
	daysOfWeek: attr(),
	daysOfMonth: attr(),
	npsScores: attr(),
	hourOfDay: attr('number'),
	daysAfterEvent: attr('number'),
	errorMessage: attr('string'),
	createdAt: attr('date'),
	updatedAt: attr('date'),
	holdUntilActivation: attr('boolean'),
	projectedSendDate: attr('date'),
	sendDateCalculationLog: attr('string'),
	runClosed: attr('boolean'),
	runHold: attr('boolean'),
	fileId: attr('number'),
	messageTemplateId: attr('number'),
	stageValidationRequired: attr('boolean'), //* is the automationType one that requires a stage/consecutive stages be selected?

	callCount: attr('number'), //* How many times this automation has been called/ran
	callLimit: attr('number'), //* Max amount of times an automation should be called

	//* Relationships
	case: belongsTo('case'),
	firm: belongsTo('firm'),
	automationTemplate: belongsTo('automation-template'),

	//* Local Properties
	company: service(),
	store: service(),
	type: alias('automationType'),
	triggerTime: alias('hourOfDay'),
	triggerDays: alias('daysOfMonth'),
	frequencyAmount: attr('number'),
	frequencyType: attr('string'),
	typeLabel: computed('automationType', function () {
		const trigger = this.automationType;

		switch (trigger) {
			case 'no_case_changes':
				return `No ${capitalize(dynamicCaseLabel([this.company]))} Change`;
			case 'recurring_message':
				return 'Recurring Message';
			case 'treatment_reminder':
				return 'Treatment Reminder';
			case 'add_checklist_template':
				return 'Add Checklist Template';
			case 'add_checklist_template_chat':
				return 'Add Internal Checklist Template';
			case 'date_based':
				return 'Date Based Message';
			case 'status_message':
				return 'Single Message';
			case 'nps_message':
				return 'Single Message based on NPS';
			default:
				return null;
		}
	}),
	dateType: attr('string'),

	//? Set the triggerType on init for pre-existing automations
	triggerType: attr('string'),

	errors: [],
	clientId: attr('number'),
	checkForDoB() {
		if (this.automationType != 'date_based' || !this.clientId) return null;
		const dobError = `<b>There is no date of birth listed for the client on this ${dynamicCaseLabel(
			[this.company],
		)}</b><br>This automation will not run until one is entered.`;
		const client = this.store.peekRecord('client', this.clientId);
		const errors = [];

		if (!client || !client.birthDate) {
			errors.push(dobError);
		}
		//* Try to filter out any duplicates by using a Set with an Array clone as to not accidentally mutate the originating array
		this.set('errors', [...new Set(errors)]);
	},

	async init() {
		this._super(...arguments);
		//* Default the daysAfterEvent to 14 if not set
		if (!this.get('daysAfterEvent')) {
			this.set('daysAfterEvent', 14);
		}

		//* Figure out the triggerType
		let triggerType;
		const daysOfWeek = this.get('daysOfWeek') || [];
		if (this.get('daysOfWeek.length') || this.get('daysOfMonth.length')) {
			if (this.get('daysOfWeek.length')) {
				if (
					this.get('daysOfWeek.length') === 5 &&
					daysOfWeek[0] == 0 &&
					daysOfWeek[1] == 1 &&
					daysOfWeek[2] == 2 &&
					daysOfWeek[3] == 3 &&
					daysOfWeek[4] == 4
				) {
					triggerType = 'days';
				} else {
					triggerType = 'weeks';
				}
			}

			if (
				this.get('daysOfMonth.length') &&
				(this.get('daysOfMonth.length') === 1 ||
					this.get('daysOfMonth.length') === 2)
			) {
				triggerType = 'months';
			}
		}

		if (triggerType) {
			this.set('triggerType', triggerType);
		} else {
			this.set('daysOfWeek', null);
			this.set('daysOfMonth', null);
		}

		if (this.automationType == 'date_based') {
			this.checkForDoB();
		}

		//* Fetch File if fileId exists
		if (this.fileId) {
			const file = await this.store.findRecord('file', this.fileId);
			this.set('file', file);
		}
	},
});
