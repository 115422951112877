/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { capitalize } from '@ember/string';
import ENV from 'case-status/config/environment';
import RSVP from 'rsvp';
import FileHandler from '../../../modules/FileHandler';
import moment from 'moment';

export default Route.extend({
	currentUser: service('current-user'),
	session: service(),
	ajax: service(),
	npsURL: `${ENV.host}/net_promoter_score`,

	fetchScoreBoardDataUpdateController(controller, userType) {
		let npsUrl = `${ENV.host}/net_promoter_score/${userType}s`;

		return this.ajax
			.request(npsUrl, {
				contentType: 'application/json',
			})
			.then((response) => {
				const firmMembers = [];
				Object.keys(response).forEach((id) => {
					const firmMember = this.store.peekRecord(userType, id);
					// filter out all users who have case status in their name
					if (
						firmMember &&
						firmMember.name &&
						!firmMember.name.toLowerCase().includes('case status')
					) {
						firmMember.set('npsScore', response[id]);
						firmMember.set('isAttorney', userType === 'attorney');
						firmMembers.push(firmMember);
					}
				});
				controller.set(`nps${capitalize(userType)}Data`, firmMembers);
			});
	},

	fetchData(controller, queryParams, user) {
		let url = `${ENV.host}/firm_insights`;
		let userLoginsUrl = `${ENV.host}/firm_insights/user_logins`;
		let npsUrl = `${ENV.host}/firm_insights/promoter-scores`;

		if (queryParams) {
			url += queryParams;
			userLoginsUrl += queryParams;
			npsUrl += queryParams;
		}

		const userLoginsData = this.ajax
			.request(userLoginsUrl, {
				contentType: 'application/json',
			})
			.then((response) => {
				controller.set('userLoginsData', {
					total: response.user_logins,
					labels: response.dates,
					dataset: response.totals,
				});
			});

		const netPromoterScoresData = this.ajax
			.request(npsUrl, {
				contentType: 'application/json',
			})
			.then((response) => {
				let currentStatus = this.controller.get('disableFilter');

				if (currentStatus) {
					this.controller.set('disableFilter', !currentStatus);
				}

				controller.set('netPromoterScoresData', {
					total: response.net_promoter_score.net_promoter_score,
					labels: response.dates,
					dataset: response.net_promoter_score.totals,
				});
				controller.set('clientSatisfactionScore', {
					total: response.client_satisfaction_score.client_satisfaction_score,
					labels: response.dates,
					dataset: response.client_satisfaction_score.totals,
				});
			});

		const netPromoterScoresAttorneyData =
			this.fetchScoreBoardDataUpdateController(controller, 'attorney');
		const netPromoterScoresParalegalData =
			this.fetchScoreBoardDataUpdateController(controller, 'paralegal');

		const insightsData = this.ajax
			.request(url, {
				contentType: 'application/json',
				type: 'GET',
			})
			.then((response) => {
				controller.set('newCasesData', {
					total: response.new_cases.new_cases,
					labels: response.dates,
					dataset: response.new_cases.totals,
				});
				controller.set('openCasesData', {
					total: response.open_cases.open_cases,
					labels: response.dates,
					dataset: response.open_cases.totals,
				});
				controller.set('messagesData', {
					labels: response.dates,
					datasets: [
						{
							label: 'Total',
							total: response.messages.total_messages.total_messages,
							labels: response.dates,
							dataset: response.messages.total_messages.totals,
						},
						{
							label: 'Incoming',
							total: response.messages.incoming.total_incoming,
							labels: response.dates,
							dataset: response.messages.incoming.totals,
						},
						{
							label: 'Outgoing',
							total: response.messages.outgoing.total_outgoing,
							labels: response.dates,
							dataset: response.messages.outgoing.totals,
						},
					],
				});
				controller.set('responseTimeData', {
					total: response.average_response_time.average_response_time,
					labels: response.dates,
					dataset: response.average_response_time.totals,
				});

				let liveFeedItems;
				if (user && user.get('id')) {
					if (user.get('type') === 'paralegal') {
						liveFeedItems = this.store.query('liveFeedItem', {
							paralegalId: user.get('id'),
						});
					} else {
						liveFeedItems = this.store.query('liveFeedItem', {
							attorneyId: user.get('id'),
						});
					}
				} else {
					liveFeedItems = this.store.findAll('liveFeedItem');
				}
				liveFeedItems.then((items) => {
					controller.set('liveFeedItems', items);
				});

				if (!controller.startDate && !controller.endDate) {
					const lastDateIndex = response.dates.length - 1;
					const start = new Date(response.dates[0]);
					const end = new Date(response.dates[lastDateIndex]);
					controller.set('startDate', start);
					controller.set('endDate', end);
				}
				return RSVP.all([
					userLoginsData,
					insightsData,
					netPromoterScoresData,
					netPromoterScoresAttorneyData,
					netPromoterScoresParalegalData,
					liveFeedItems,
				]);
			});
	},

	setupController(controller) {
		this._super(...arguments);

		const minDate = new Date(this.get('currentUser.user.firm.createdAt'));

		const startDate = function () {
			const firstOfCurrentMonth = moment(new Date(), 'MM-DD-YYYY').date(1);
			const minDateMoment = moment(minDate, 'MM-DD-YYYY');

			if (firstOfCurrentMonth.toDate() <= minDateMoment.toDate()) {
				return minDateMoment.format('MM-DD-YYYY');
			} else {
				return firstOfCurrentMonth.format('MM-DD-YYYY');
			}
		};

		controller.set('minDate', minDate);
		controller.set('startDate', startDate());
		controller.set(
			'endDate',
			moment(new Date(), 'MM-DD-YYYY').format('MM-DD-YYYY'),
		);
		controller.set('disableFilter', true);

		const dataQueryParameters = controller.dataQueryParams;

		this.fetchData(controller, dataQueryParameters);

		this.store.findAll('live-feed-item').then((items) => {
			controller.set('isLoadingFeedItems', false);
			return controller.set('liveFeedItems', items);
		});

		controller.set('isLoadingScores', true);
		this.store.findAll('net-promoter-score').then((scores) => {
			scores = scores.map((score) => {
				const _case = this.store.peekRecord('case', score.get('case.id'));
				score.set(
					'case',
					this.store.createRecord('case', {
						readableId: _case.get('readableId'),
						primaryAttorney: _case.get('primaryAttorney'),
						caseStatus: _case.get('caseStatus'),
					}),
				);
				return score;
			});
			controller.set('scores', [...scores.toArray()]);
			controller.set('isLoadingScores', false);
		});

		// reset case filters
		this.controllerFor('app.firm.cases').send('clearFilters');
	},

	actions: {
		fetchInsightsData(params, user) {
			const controller = this.controller;
			controller.set('disabled', true);
			this.fetchData(controller, params, user);
		},

		fetchNewNPSData(sort, selectedUser) {
			const controller = this.controller;
			const userType = selectedUser.get('constructor.modelName');
			const queryFilter = {
				sort,
			};

			if (userType == 'attorney') {
				queryFilter.attorneyId = selectedUser.get('id');
			} else if (userType == 'paralegal') {
				queryFilter.paralegalId = selectedUser.get('id');
			}

			controller.set('isLoadingScores', true);
			return this.store
				.query('net-promoter-score', queryFilter)
				.then((scores) => {
					controller.set('scores', scores);
					controller.set('isLoadingScores', false);
				});
		},

		exportMessages(startDate, endDate, result) {
			const url =
				ENV.host +
				`/messages/download?startDate=${startDate}&endDate=${endDate}`;
			return this.ajax
				.request(url)
				.then(({ data }) => {
					// generate transitory element
					FileHandler.download(data.attributes.url, data.attributes.file_name);
					result.resolve();
				})
				.catch((error) => result.reject(error));
		},

		transitionToCase(id) {
			this.transitionTo('app.firm.case.info', id);
		},
	},
});
