/** @format */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';
import EmberError from '@ember/error';
import { next } from '@ember/runloop';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { v4 } from 'uuid';
export default class PillGeneratingTextInputComponent extends Component {
	constructor() {
		super(...arguments);
		if (Array.isArray(this.args.model)) {
			this.pillArray = A(this.args.model);
		} else if (this.args.model) {
			throw EmberError('Provided model value must be an array of strings.');
		} else {
			this.pillArray = A([]);
		}

		this.id = this.args.name ? this.args.name : v4();
		this.value = this.args.value || '';
		this.updateAction =
			typeof this.args.updateAction == 'function'
				? this.args.updateAction
				: undefined;

		this.label = this.args.noLabel
			? undefined
			: this.args.label
			? this.args.label
			: this.args.placeholder
			? this.args.placeholder
			: undefined;

		if (this.args.separator) {
			if (typeof this.args.separator === 'string') {
				this.separator = this.args.separator;
			} else {
				throw EmberError('Provided separator value must be a string.');
			}
		}
	}

	@service customBranding;

	@tracked pillArray;
	@tracked value;

	@action
	addPill(value) {
		if (!value) return;
		// remove whitespace surrounding value
		const trimmedPillValue = value.trim();
		// don't allow blank spaces to be submitted
		if (!trimmedPillValue) return;
		this.pillArray.pushObject(trimmedPillValue);
		this.value = '';
		$(`.pill-generating-input>input#${this.id}`).focus();

		if (this.updateAction) {
			next(() => this.updateAction(this.pillArray));
		}
	}

	@action removePill(value) {
		this.pillArray.removeObject(value);

		if (this.updateAction) {
			next(() => this.updateAction(this.pillArray));
		}
	}

	@action
	keyUp(value, event, ...args) {
		if (
			value.includes(this.separator) ||
			event.code == 'Enter' ||
			event.code == 'NumpadEnter'
		) {
			if (this.separator && value.includes(this.separator)) {
				const values = value.split(this.separator);

				for (let i = 0; i < values.length; i++) {
					const val = values[i];
					this.addPill(val);
				}
			} else {
				this.addPill(value);
			}
		}

		if (this.args.keyUp && typeof this.args.keyUp == 'function') {
			this.args.keyUp(value, event, ...args);
		}
	}
}
