/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	currentUser: service('current-user'),
	initials: computed('firstName', 'lastName', function () {
		if (!this.firstName || !this.lastName) return 'N/A';

		return `${this.firstName[0]} ${this.lastName[0]}`;
	}),
});
