/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import EmberObject, { computed, observer } from '@ember/object';
import { debounce } from '@ember/runloop';
import { sort, union } from '@ember/object/computed';
import moment from 'moment';

import { userDisplay } from '../../../helpers/user-display';

export default Controller.extend({
	queryParams: ['invoiceId'],
	invoiceId: '',

	isLoadingFeedItems: true,
	customBranding: service('customBranding'),
	company: service(),

	transactionFilterPhrase: '',
	transactionSortDirection: '',
	transactionSortColumn: '',

	init() {
		this._super(...arguments);

		this.set('attorneys', this.store.findAll('attorney'));
		this.set('paralegals', this.store.findAll('paralegal'));
		this.set('defaultOption', [
			EmberObject.create({
				name: 'Entire Firm',
			}),
		]);

		this.set('scores', []);
	},

	// settingsObserver: observer('currentUser.user.firm.'),

	isLoadingSettings: computed(
		'currentUser.user.firm.firmSettings.firmSettings',
		function () {
			return !this.currentUser.get('user.firm.firmSettings.firmSettings');
		},
	),

	dashboardUrl: computed('currentUser.user.firm.dashboardUrl', function () {
		return this.get('currentUser.user.firm.dashboardUrl');
	}),
	showDashboard: computed(
		'currentUser.user.firm.firmSettings.firmSettings.periscope_show_dashboard',
		'dashboardUrl',
		function () {
			return (
				this.get('dashboardUrl') &&
				this.get(
					'currentUser.user.firm.firmSettings.firmSettings.periscope_show_dashboard',
				)
			);
		},
	),
	showPartnerPortal: computed(
		'currentUser.permissions.partner_portal',
		'isLoadingSettings',
		function () {
			return (
				this.get('currentUser.permissions.partner_portal') &&
				!this.get('isLoadingSettings')
			);
		},
	),
	disableFilter: true,
	isLoadingScores: true,
	isLoadingTransactions: true,
	loadingDashboard: true,
	expanded: false,
	currentUser: service(),
	selectedUser: EmberObject.create({
		name: 'Entire Firm',
	}),
	sortDefinition: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sort: '',
	users: union('attorneys', 'paralegals'),
	sortedUsers: sort('users', 'sortDefinition'),
	formattedUsers: computed('users', function () {
		const entireFirm = EmberObject.create({
			name: 'Entire Firm',
			emailAddress: '',
		});
		let users = this.users;
		users.unshiftObject(entireFirm);

		return userDisplay([users]);
	}),

	liveFeedItemsSorting: ['createdAt:desc'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	npsAttorneySorting: ['npsScore:desc'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sortedLiveFeedItems: sort('liveFeedItems.[]', 'liveFeedItemsSorting'),

	npsLeaderBoardData: computed(
		'npsParalegalData',
		'npsAttorneyData',
		function () {
			let payload = [];
			const npsParalegalData = this.npsParalegalData;
			const npsAttorneyData = this.npsAttorneyData;
			if (npsParalegalData && npsParalegalData.length > 0)
				payload = [...npsParalegalData];
			if (npsAttorneyData && npsAttorneyData.length > 0)
				payload = [...payload, ...npsAttorneyData];

			return payload;
		},
	),
	sortedNPSData: sort('npsLeaderBoardData', 'npsAttorneySorting'),

	filteredLiveFeedItems: computed(
		'sortedLiveFeedItems.[]',
		'startDate',
		'endDate',
		function () {
			const sortedLiveFeedItems = this.sortedLiveFeedItems;
			const startDate = this.startDate;
			const endDate = this.endDate;

			return sortedLiveFeedItems.filter((feedItem) => {
				let isValid = true;

				// check startDate
				if (
					startDate &&
					moment(startDate).isAfter(moment(feedItem.get('createdAt')))
				) {
					isValid = false;
				}

				// check endDate set to last minute of the day, filter is not time specific just date specific
				if (
					endDate &&
					moment(new Date(endDate).setHours(23, 59, 59, 999)).isBefore(
						moment(feedItem.get('createdAt')),
					)
				) {
					isValid = false;
				}

				return isValid;
			});
		},
	),

	filteredNPSData: computed('sortedNPSData', 'selectedUser', function () {
		if (this.get('selectedUser.name') === 'Entire Firm')
			return this.sortedNPSData;

		return this.sortedNPSData.filterBy('id', this.get('selectedUser.id'));
	}),

	sortedTransactions: computed(
		'transactions.[]',
		'transactionSortDirection',
		'transactionSortColumn',
		function () {
			const transactions = this.transactions || [];
			const transactionSortDirection = this.transactionSortDirection;
			const sortColumn = this.transactionSortColumn;

			if (transactions.length > 0 && sortColumn) {
				return transactions.sort((a, b) => {
					const first = a.get(sortColumn);
					const second = b.get(sortColumn);

					// descending
					if (transactionSortDirection !== '+') {
						if (first < second) {
							return -1;
						}
						if (first > second) {
							return 1;
						}
						return 0;
					}

					// ascending
					if (first < second) {
						return 1;
					}
					if (first > second) {
						return -1;
					}
					return 0;
				});
			}

			return transactions;
		},
	),

	filtersUpdated: observer(
		'selectedUser',
		'startDate',
		'endDate',
		'internalId',
		function () {
			debounce(this, this.fetchNewData, 500);
		},
	),

	fetchNewData() {
		const dataQueryParams = this.dataQueryParams;
		this.send('fetchInsightsData', dataQueryParams, this.selectedUser);
	},

	dataQueryParams: computed(
		'selectedUser',
		'startDate',
		'endDate',
		'internalId',
		function () {
			let url = '';
			const startDate = this.startDate;
			const endDate = this.endDate;
			const userId = this.get('selectedUser.id');
			const userType = this.get('selectedUser.constructor.modelName');
			const internalId = this.internalId;

			if (
				moment(startDate, 'MM-DD-YYYY').isValid() &&
				moment(endDate, 'MM-DD-YYYY').isValid()
			) {
				let formattedStartDate = moment(startDate, 'MM-DD-YYYY').format(
					'MM-DD-YYYY',
				);
				let formattedEndDate = moment(endDate, 'MM-DD-YYYY').format(
					'MM-DD-YYYY',
				);
				url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
			}

			if (userId && userType) {
				url += `${url.length ? '&' : '?'}${userType}Id=${userId}`;
			}

			if (internalId) {
				url += `${url.length ? '&' : '?'}internalId=${
					Number(internalId) ? internalId : '0'
				}`;
			}

			return url;
		},
	),

	npsSortObserver: observer('sort', 'selectedUser', function () {
		this.send('fetchNewNPSData', this.sort, this.selectedUser);
	}),

	invoiceObserver: observer('invoiceId', 'transactions', function () {
		const invoiceId = this.invoiceId;
		const transactions = this.transactions || [];
		if (invoiceId && transactions.length > 0) {
			const transaction = transactions.find(
				(t) => t.get('internalId') == Number(invoiceId),
			);

			this.send('toggleShowReceiptModal', {
				id: transaction.internalId,
				amount: transaction.amount,
				date: transaction.date,
				summary: transaction.message,
				option: transaction.option,
			});
		}
	}),
	showReceiptModal: false,
	receiptDetails: null,

	actions: {
		handleOnLoad() {
			this.set('loadingDashboard', false);
		},
		selectUser(user) {
			this.set('selectedUser', user);
		},
		setEndDate(date) {
			this.set('endDate', date);
		},
		setStartDate(date) {
			this.set('startDate', date);
		},
		toggleExpanded() {
			this.toggleProperty('expanded');
		},

		updateSort(sortColumn, sortDirection) {
			switch (sortColumn) {
				case 'case':
					this.set('sort', `${sortDirection}case.id`);
					break;
				case 'client':
					this.set('sort', `${sortDirection}clients`);
					break;
				case 'attorney.name':
					this.set('sort', `${sortDirection}attorney.name`);
					break;
				case 'recent_stage':
					this.set('sort', `${sortDirection}recent_stage`);
					break;
				case 'nps_score':
					this.set('sort', `${sortDirection}nps_score`);
					break;
				case 'lastFeedback':
					this.set('sort', `${sortDirection}last_feedback`);
					break;

				default:
					return;
			}
		},
		scoreClicked(id) {
			this.send('transitionToCase', id);
		},

		fitlerTransactions(value) {
			this.set('internalId', value);
			this.set('transactions', []);
			this.set('isLoadingTransactions', true);
		},

		updateTransactionSort(sortColumn, sortDirection) {
			this.set('transactionSortColumn', sortColumn);
			this.set('transactionSortDirection', sortDirection);
		},

		toggleShowReceiptModal(receiptDetails) {
			if (!receiptDetails) {
				this.set('receiptDetails', null);
			} else {
				this.setProperties({ receiptDetails });
			}

			this.toggleProperty('showReceiptModal');
		},
	},
});
