/** @format */

import Controller from '@ember/controller';
import RSVP, { reject } from 'rsvp';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	email: validator('format', {
		type: 'email',
		message: 'Email Address is not valid.',
	}),
});

export default Controller.extend(Validations, {
	email: null,
	queryParams: ['tokenExpired'],
	tokenExpired: null,

	actions: {
		submitButtonPressed() {
			this.set('errors', null);

			const email = this.email;
			const result = RSVP.defer();
			const errors = this.get('validations.messages');
			this.set('showError', true);
			if (errors.length > 0) {
				reject();
			} else {
				this.send('sendResetEmail', email, result);
			}

			return result.promise;
		},
	},
});
