/** @format */

import Model, { hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';

export default Model.extend({
	createdAt: attr('date'),
	contentType: attr('string'),
	extension: attr('string'),
	fileName: attr('string'),
	size: attr('number'),
	senderId: attr('number'),
	url: attr('string'),
	messageId: attr(),
	apiUrl: attr('string'),

	caseId: attr('number'),

	integrationSuccess: attr('boolean'),
	integrationError: attr('string'),

	formattedCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).format('MMMM D, YYYY');
	}),

	sortableCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).valueOf();
	}),

	confirmDelete: false,

	// Relationships
	senderName: computed('senderId', function () {
		const user = this.store.peekRecord('user', this.senderId);
		if (user) {
			return user.get('name');
		}
		return null;
	}),

	isImage: computed('contentType', function () {
		return this.contentType.indexOf('image') > -1;
	}),

	fileType: computed('extension', function () {
		return this.extension.replace('.', '').toUpperCase();
	}),

	//* Signed Document File Attributes
	isSignedDocument: attr('boolean'),
	auditTrail: attr(),
	linkExpiration: attr('date'),
	uuid: attr('string'),
	randomId: attr('string'),
	isSignaturePending: true,

	documentSignatories: hasMany('document-signatory'),
	formattedDocumentSignatories: null,

	formatDocumentSignatories() {
		this.formattedDocumentSignatories =
			this.documentSignatories.sortBy('order');
	},

	setIsSignaturePending() {
		const formattedDocumentSignatories =
			this.formattedDocumentSignatories || [];

		const pendingBool = Boolean(
			formattedDocumentSignatories.find((signatory) => {
				return (
					signatory.status === 'pending' || signatory.status === 'upcoming'
				);
			}),
		);

		this.isSignaturePending = pendingBool;
	},

	init() {
		this._super(...arguments);
		this.formatDocumentSignatories();
		this.setIsSignaturePending();
	},
});
