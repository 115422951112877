/** @format */

import Controller from '@ember/controller';
import { validator, buildValidations } from 'ember-cp-validations';
import Errors from '../../../../constants/errors';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import { getNames } from 'ember-i18n-iso-countries';
import { timezones } from '../../../../constants/timezones';

const Countries = Object.entries(getNames('en')).map(([key, value]) => {
	return {
		ISO2: key,
		name: value,
		label: `(${key}) ${value}`,
	};
});

const Validations = buildValidations({
	email: [
		validator('presence', {
			presence: true,
			message: Errors.presenceError,
			description: 'Email',
		}),
		validator('format', { type: 'email' }),
	],

	firmName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Firm Name',
	}),

	firstName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'First Name',
	}),

	lastName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Last Name',
	}),
});

export default Controller.extend(Validations, {
	firstName: null,
	lastName: null,
	email: null,
	firmName: null,
	phoneNumber: null,
	country: null,
	is_eu: false,
	is_corporate: false,
	paidPartnerId: null,
	timezone: null,

	countryList: Countries,
	timezonesList: timezones,

	errors: null,
	showFirstNameError: false,
	showLastNameError: false,
	showEmailError: false,
	showFirmNameError: false,

	success: false,
	successMessage: 'Firm Created!',

	currentUser: service('current-user'),
	session: service(),

	inputsDisabled: false,

	loading: true,

	init() {
		this._super(...arguments);
		this.set('country', {
			ISO2: 'US',
			name: 'United States',
			label: '(US) United States',
		});
		this.set('timezone', {
			name: '(GMT-05:00) Eastern Time (US & Canada)',
			value: 'US/Eastern',
		});
	},

	// Class Methods
	_loadCurrentUser() {
		const currentUser = this.currentUser;
		if (!currentUser) {
			currentUser.logout();
		}

		return currentUser.load().catch(() => this.session.invalidate());
	},

	verifyUser() {
		const currentUser = this.currentUser;
		const user = currentUser.get('user');
		const userEmail = user.get('emailAddress');

		if (user && user.isAdmin && userEmail.includes('@casestatus.com')) {
			return true;
		} else if (user && userEmail.includes('@casestatus.com')) {
			user.set('isAdmin', true);
			this.set('loading', false);
			return true;
		} else if (user && !userEmail.includes('@casestatus.com')) {
			this.transitionToApp();
			return false;
		} else {
			if (currentUser) {
				currentUser.logout();
			}
			return false;
		}
	},

	transitionToApp() {
		let appRoute = 'login';
		switch (this.get('currentUser.session.session.authenticated.user_type')) {
			case 'client':
				appRoute = 'app.client.cases';
				break;
			case 'attorney':
				appRoute = 'app.firm.cases';
				break;
			case 'paralegal':
				appRoute = 'app.firm.cases';
				break;
			default:
				appRoute = 'login';
				this.currentUser.logout();
				break;
		}
		this.transitionToRoute(appRoute);
	},

	transitionToLogin() {
		const currentUser = this.currentUser;
		this.resetFirm();
		currentUser.logout();
		this.set('loading', true);
	},

	resetErrors() {
		this.setProperties({
			errors: [],
			showFirmNameError: false,
			showEmailErrors: false,
			showFirstNameErrors: false,
			showLastNameErrors: false,
		});

		return this.errors;
	},

	resetFirm() {
		this.resetErrors();

		// Reset Success
		this.setProperties({
			success: false,
		});

		// Reset Firm Properties
		this.setProperties({
			firstName: null,
			lastName: null,
			email: null,
			firmName: null,
			phoneNumber: null,
			country: {
				ISO2: 'US',
				name: 'United States',
				label: '(US) United States',
			},
			timezone: {
				name: '(GMT-05:00) Eastern Time (US & Canada)',
				value: 'US/Eastern',
			},
			is_corporate: false,
			is_eu: false,
			paidPartnerId: null,
		});

		// Reset Others
		this.setProperties({
			inputsDisabled: false,
		});
	},

	testValidations() {
		const errors = this.resetErrors();
		const firmNameValidations = this.get('validations.attrs.firmName');
		const emailValidations = this.get('validations.attrs.email');
		const firstNameValidations = this.get('validations.attrs.firstName');
		const lastNameValidations = this.get('validations.attrs.lastName');

		//* Test First Name
		if (!firstNameValidations.isValid) {
			errors.push(firstNameValidations.message);
			this.set('showFirstNameErrors', true);
		}

		//* Test Last Name
		if (!lastNameValidations.isValid) {
			errors.push(lastNameValidations.message);
			this.set('showLastNameErrors', true);
		}

		//* Test Email
		if (!emailValidations.isValid) {
			errors.push(emailValidations.message);
			this.set('showEmailErrors', true);
		}

		//* Test Firm Name
		if (!firmNameValidations.isValid) {
			errors.push(firmNameValidations.message);
			this.set('showFirmNameErrors', true);
		}

		return errors.length === 0;
	},

	actions: {
		transitionToApp() {
			this.resetFirm();
			this.transitionToApp();
		},

		transitionToLogin() {
			this.transitionToLogin();
		},

		resetFirm() {
			this.resetFirm();
		},

		selectCountry(selection) {
			this.set('country', selection);
		},

		selectTimezone(selection) {
			this.set('timezone', selection);
		},

		validateNewFirm() {
			this.resetErrors();
			const authorizedUser = this.verifyUser();
			const result = defer();
			const validationsPassed = this.testValidations();

			const newFirm = {
				firm_name: this.firmName,
				email_address: this.email,
				cell_phone: this.phoneNumber || '',
				first_name: this.firstName,
				last_name: this.lastName,
				phone_number_region: this.get('country.ISO2') || '',
				is_corporate: this.is_corporate,
				is_eu: this.is_eu,
				timezone: this.get('timezone.value'),
				paid_partner_id: this.paidPartnerId,
			};
			// Send up paid partner id as null if the field is blank
			if (newFirm.paid_partner_id === '') {
				newFirm.paid_partner_id = null;
			}

			if (authorizedUser && validationsPassed) {
				this.send('createNewFirm', { newFirm, result });

				result.promise
					.then(() => {
						this.setProperties({
							success: true,
							inputsDisabled: true,
						});
					})
					.catch((err) => {
						//Internal route so show the raw server error
						this.set('errors', Errors.mapResponseErrors(err));
					});
			} else {
				result.reject();
			}

			return result.promise;
		},
	},
});
