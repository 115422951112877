/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	session: service(),

	beforeModel() {
		this._super(...arguments);
		const authenticatedUserType = this.get(
			'session.data.authenticated.user_type',
		);

		switch (authenticatedUserType) {
			case 'client':
				this.replaceWith('app.client.cases');
				break;
			case 'member':
				this.replaceWith('app.organization.cases');
				break;
			default:
				this.replaceWith('app.firm.cases');
				break;
		}
	},
});
