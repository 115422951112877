import { capitalize } from '@ember/string';
import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { pluralize } from 'ember-inflector';

export function attorneyDisplay(params) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'attorney-display helper requires firm passed in as an array',
		);
	}

	let [firm, count, withoutCount] = params;
	if (count === undefined) {
		count = 1;
		withoutCount = true;
	}

	// if no firm default to Attorney
	if (!firm) {
		return pluralize(count, 'Attorney', { withoutCount });
	}

	// Update title based off firm content
	let title = '';
	// sometimes firm is already passed as the proxy, sometimes not
	if (firm.info) {
		firm = firm.info;
	}
	if (count !== 1) {
		title = capitalize(firm.get('attorneyLabelPlural') ?? 'attorneys');
	} else {
		title = capitalize(firm.get('attorneyLabelSingular') ?? 'attorney');
	}

	// if we display count, do so
	return withoutCount ? title : `${count} ${title}`;
}

export default helper(attorneyDisplay);
