/** @format */

import ApplicationAdapter from './application';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';

export default class MessageAdapter extends ApplicationAdapter {
	urlForQuery(slug = {}) {
		if (slug.caseId) {
			if (slug.params) {
				const queryString = formatObjectAsSearchParams(slug.params);
				return `${this.host}/cases/${slug.caseId}/messages?${queryString}`;
			} else {
				return `${this.host}/cases/${slug.caseId}/messages`;
			}
		}
	}
}
