/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

import Errors from '../../../../constants/errors';

export default Route.extend({
	store: service(),
	ajax: service(),
	session: service(),
	currentUser: service('current-user'),
	global: service(),
	activityTracking: service(),

	setupController(controller, model) {
		this._super(...arguments);

		this.global.set('caseController', controller);
		const caseTypeId = model.theCase.get('caseType.id');
		const caseType = this.store.findRecord('caseType', caseTypeId, {
			include: 'caseStatus',
		});
		caseType.then((type) => {
			controller.set('statuses', type.get('caseStatuses'));
		});

		const video = model.theCase.get('caseStatus.video');

		if (video) {
			controller.set('video', video);
		} else {
			controller.set('video', null);
		}

		this.activityTracking.trackByCase(model.theCase.id, 'viewedStatus');
	},

	resetController(controller, isExiting) {
		if (isExiting) {
			controller.setProperties({
				isWritingMessage: false,
				newMessage: null,
			});
			this.global.set('caseController', null);
		}
	},

	actions: {
		sendMessage(content, result) {
			const model = this.modelFor('app.client.case');
			const newMessage = this.store.createRecord('message', {
				case: model.theCase,
				content,
				createdAt: new Date(),
				senderName: this.get('currentUser.user.name'),
				senderType: this.get('currentUser.user').constructor.modelName,
			});
			newMessage
				.save()
				.then(() => {
					result.resolve();
				})
				.catch((response) => {
					newMessage.rollbackAttributes();
					const controller = this.controllerFor('app.client.case.info');
					controller.set('errors', Errors.mapResponseErrors(response));
					result.reject();
				});
		},
	},
});
