/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
	company: service(),

	esignLink: computed('message.signedDocumentLink', function () {
		const eSignUrl = this.get('message.signedDocumentLink') || '';

		return eSignUrl;
	}),

	actions: {
		navigateToDocument() {
			window.open(this.esignLink, '_blank');
		},
	},
});
