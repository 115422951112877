/** @format */

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
	// For any of the belongsTo relationships, we need to check it out
	serializeBelongsTo(snapshot, json, relationship) {
		// First, we pull the key for the belongsTo relationship
		const key = relationship.key;
		let belongsTo = snapshot.belongsTo(key);

		// If it's a case, we need to make sure we don't serialize it if it's null
		if (key === 'case') {
			// If the case is not null, the serializer can do it's thing. Otherwise, we
			// don't want it to do anything so that it leaves the case out of the request
			if (belongsTo) {
				this._super(...arguments);
			}
		} else {
			// If it's not a case, we can continue like normal
			this._super(...arguments);
		}
	},
});
