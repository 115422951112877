/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

export default class FirmCaseInfo extends Route {
	@service ajax;
	@service permissions;
	@service currentUser;

	setupController(controller, model) {
		super.setupController(...arguments);
		controller.set('isOnHold', model.theCase.get('onHold'));
		controller.set('isClosed', model.theCase.get('closed'));
		controller.set('wantsSms', model.theCase.get('wantsSms'));
		controller.set('group', model.theCase.get('group'));
		controller.set('oldCaseStatus', null);
		controller.set('shouldLinkAsCollaborator', false);
		controller.set('isWritingMessage', false);
		controller.set('newMessage', null);
		controller.set('messageTemplateType', null);
		controller.set('activityCheck', 0);
		this.controllerFor('app.firm.case').set('currentTab', 'info');
		const dynamicSetting = model.theCase.get('activatedDate')
			? 'automations_per_practice_area'
			: 'automations_per_practice_area,case_activation_quota';
		this.permissions.fetchDynamicFirmSettings({
			setting: dynamicSetting,
			case_type_id: model.theCase.get('caseType.id'),
			case_id: model.theCase.get('id'),
			session: 'replica',
		});

		// Some firms want the ability to make `Messages` the default subnav tab
		// regardless of whether or not they have new messages on a case. This new firm
		// setting affords that capability. If they have explicitly set `default_landing_tab_activity`
		// to false, then the `Messages` tab will be the default.
		const activityTrackingEnabled = model.theCase.get(
			'caseSettings.enable_activity_tracking',
		);
		const hasNewMessages = model.theCase.get('newMessages');
		const activityTabDefault =
			this.currentUser.get(
				'user.firm.firmSettings.firmSettings.default_landing_tab_activity',
			) !== false;
		const selectedSubNav =
			activityTrackingEnabled && !hasNewMessages && activityTabDefault
				? 'Activity'
				: 'Messages';
		const activityActive = selectedSubNav === 'Activity';
		const messagesActive = selectedSubNav === 'Messages';

		controller.setProperties({
			activityActive,
			messagesActive,
			selectedSubNav,
		});

		//* Setup messages in the model using the infinity loader

		if (
			this.permissions.get('case.allow_message_translation') &&
			!controller?.languages?.length
		) {
			const languages = this.store.peekAll('language');

			if (languages.length) {
				controller.set('languages', languages);
			} else {
				this.store.findAll('language').then((res) => {
					controller.set('languages', res);
				});
			}
		}
	}

	@action
	didTransition() {
		this.controller.set('shouldUpdateMessages', true);
		next(() => {
			this.controller.set('shouldUpdateMessages', false);
		});
	}

	resetController(controller, isExiting) {
		super.resetController(...arguments);
		if (isExiting) {
			controller.set('downloadTextSuccess', false);
			controller.set('downloadTextErrors', false);
			this.controllerFor('app.firm.case').set('currentTab', 'info');
			controller.set('showMessageFilter', false);
			controller.set('selectedReferralSource', null);
			controller.set('otherReferralSourceText', null);
			controller.set('documentsActive', false);
			controller.set('automationActive', false);
			controller.set('scheduleMessagesActive', false);
			controller.set('oldCaseType', null);
			controller.set('shouldSendWelcomeMessage', null);
			controller.set('isWritingMessage', false);
			controller.set('newMessage', null);
		}
	}
}
