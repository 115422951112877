export function getStatTooltips(activityType) {
	switch (activityType) {
		case 'message':
			return 'Total Communication related activities this period';
		case 'login':
			return 'Total Login related activities this period';
		case 'growth':
			return 'Total Engagement related activities this period';
		default:
			return null;
	}
}

export function getActivityTypeTooltip(activityType) {
	switch (activityType) {
		case 'message':
			return 'Communication Activity';
		case 'login':
			return 'Login Activity';
		case 'growth':
			return 'Engagement Activity';
		default:
			return null;
	}
}

export const activityIconMappings = {
	message: 'comment',
	growth: 'rocket',
	login: 'cs',
};
