import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
	isObject,
	isFunction,
	isNumber,
	doesExist,
} from 'case-status/constants/data-types';
import { A } from '@ember/array';
import { next } from '@ember/runloop';
import { action } from '@ember/object';
import { errorNotifOptions } from 'case-status/constants/notif-options';
import Errors from 'case-status/constants/errors';
import { resolve, reject, defer } from 'rsvp';

export default class PaginatedDataComponent extends Component {
	constructor() {
		super(...arguments);
		this.model = this.args.model;
		this.filters = this.args?.filters;
		this.page = this?.args?.page ?? this?.args?.meta?.page ?? 1;
		this.pageSize = this?.args?.pageSize ?? this.args?.meta?.size ?? 5;
		this.totalResults =
			this?.args?.totalResults ??
			this?.args?.meta?.total_results ??
			this?.args?.meta?.totalResults ??
			0;
		this.dataModel = this.args?.dataModel;

		this.fetchCallback = this.args?.fetchCallback;
		this.fetchNewCallback = this.args?.fetchNewCallback;
		this.fetchMoreCallback = this.args?.fetchMoreCallback;

		this.paginatedData = A([]);
	}
	@service ajax;
	@service store;
	@service notifications;

	@tracked meta;
	@tracked page;
	@tracked pageSize;
	@tracked totalResults;
	@tracked totalPages = 0;

	@tracked filters;
	@tracked queryParams;
	@tracked _sort;

	@tracked isLoading = true;
	@tracked errors;

	@tracked paginatedData;

	get queryParamsBase() {
		let baseStructure = {
			filter: this.filters,
			page: {
				number: this.page,
				size: this.pageSize,
			},
			sort: this.sort?.value ?? this.sort,
		};

		if (isObject(this.args.queryParams)) {
			baseStructure = { ...baseStructure, ...this.args.queryParams };
		}

		//* Remove nullish/incorrect query params to prevent api errors
		for (let param in baseStructure) {
			const testParam = baseStructure[param];
			if (!testParam || (!doesExist(testParam) && !isNumber(testParam))) {
				delete baseStructure[param];
			}
		}

		return baseStructure;
	}

	//* This way class extension can overwrite this with more readability and ease
	get queryParams() {
		return this.queryParamsBase;
	}

	handleError(error) {
		//* Show in console for CS Debugging via LogRocket and future Dev Debugging
		console.error(error);

		//* Map errors to hopefully be more readable;
		this.errors = Errors.mapResponseErrors(error);

		//* Display error notif so user knows something happened even if it errored
		this.notifications.error(this.errors, errorNotifOptions);
	}

	get sort() {
		return this._sort;
	}

	set sort(value) {
		this._sort = value;
		this.fetchNewData();
	}

	@action
	async fetchNewData() {
		//* Set the isLoading state property to true
		this.isLoading = true;

		//* Reset the page count to 1
		this.page = 1;

		//* Set cache-buster since this is new data being called;
		this.ajax.set('noCache', true);

		//* Query the store for data using the queryParams
		try {
			const response = await this.store.query(this.dataModel, this.queryParams);

			//* Clear the model since this is all new data
			this.paginatedData.clear();

			//* Set a constant as the array of the response
			const resArray = response.toArray();

			//* Clear model again to prevent duplicates
			this.paginatedData.clear();

			//* Push the new array's objects into the newly cleared model
			this.paginatedData.pushObjects(resArray);

			//* Set the meta property using the meta from the response
			this.meta = response.get('meta');

			//* Set the totalResults count since it may get wiped at some point.
			this.totalResults = response.get('meta.total_results');

			//* Set the totalPages count
			this.totalPages = response.get('meta.total_pages');

			//* In the next runloop we will set the isLoading state property to false and check for callbacks
			next(() => {
				this.isLoading = false;

				if (isFunction(this.fetchCallback)) {
					this.fetchCallback({
						context: this,
						data: this.paginatedData,
						response,
					});
				}

				if (isFunction(this.fetchNewCallback)) {
					this.fetchNewCallback({
						context: this,
						data: this.paginatedData,
						response,
					});
				}
			});
		} catch (error) {
			this.handleError(error);
		}

		return resolve();
	}

	@action
	async fetchMoreData() {
		//* Check to make sure there are more pages to fetch and that we are not currently fetching content
		if (this.totalPages > this.page && !this.isLoading) {
			//* Set the isLoading state property to true
			this.isLoading = true;

			//* Increment the current page number
			this.page = this.page + 1;

			try {
				//* Query the store for data using our updated queryParams
				const response = await this.store.query(
					this.dataModel,
					this.queryParams,
				);

				//* Update the meta property using the meta on the response
				this.meta = response.get('meta');

				//* Set a constant to the array from the response
				const resArray = response.toArray();

				//* Push the case Objects from the response array into the current model
				this.paginatedData.pushObjects(resArray);

				//* In the next runloop set the isLoading state property to false and check for callbacks
				next(() => {
					this.isLoading = false;

					if (isFunction(this.fetchCallback)) {
						this.fetchCallback({
							context: this,
							data: this.paginatedData,
							response,
						});
					}

					if (isFunction(this.fetchMoreCallback)) {
						this.fetchMoreCallback({
							context: this,
							data: this.paginatedData,
							response,
						});
					}
				});
			} catch (error) {
				this.handleError(error);
			}

			return resolve();
		}
	}
}
