import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='select' {{on 'click' (fn this.handleClick)}}>\n\tSelect\n</div>", {"contents":"<div class='select' {{on 'click' (fn this.handleClick)}}>\n\tSelect\n</div>","moduleName":"case-status/components/ai-select-response-button.hbs","parseOptions":{"srcName":"case-status/components/ai-select-response-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AiSelectResponseComponent extends Component {
	@action
	handleClick() {
		this.args.setInnerContent(this.args.text);
		this.args.onRecommendedResponseSelect(this.args.recommendedResponse);
		this.args.vote(true, this.args.recommendedResponse);
	}
}
