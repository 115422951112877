/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
	search: true,
	searchString: null,
	searchStringChanged: observer('searchString', function () {
		if (this.content) {
			let filteredContent = this.content.filter((option) => {
				return option.value
					.toLowerCase()
					.includes(this.searchString.toLowerCase());
			});
			this.set('displayContent', filteredContent);
		}
	}),

	init() {
		this._super(...arguments);
		this.set('displayContent', this.content);
	},
});
