/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	caseId: attr('number'),
	fileId: attr('number'),
	signedAt: attr('date'),
	signatoryName: attr('string'),
	createdAt: attr('date'),
	order: attr('number'),
	prefix: attr('string'),
	status: attr('string'),

	init() {
		this._super(...arguments);
		this.set('status', this.signedAt ? 'signed' : 'pending');
	},

	//* Relationships
	userChild: belongsTo('users-child'),
	file: belongsTo('file'),
});
