/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Errors from 'case-status/constants/errors';

export default Controller.extend({
	notifications: service(),
	company: service(),

	init() {
		this._super(...arguments);
	},

	resetErrors() {
		this.set('errors', null);
		this.set('showErrors', false);
		this.notifications.clearAll();
	},

	actions: {
		newMessageTemplate() {
			this.resetErrors();

			//* Create new Message Template local record in the Store
			const newMessageTemplate = this.store.createRecord('message-template', {
				title: 'New Message Template',
				content: null,
			});

			//* Push the newly created local record to the list of message templates to
			//* be displayed in the nav section with the preexisting templates
			this.messageTemplates.pushObject(newMessageTemplate);

			//* Set the newly created local record as the selected template
			this.set('selectedMessageTemplate', newMessageTemplate);
		},

		setSelectedMessageTemplate(messageTemplate) {
			this.resetErrors();
			this.set('selectedMessageTemplate', messageTemplate);
		},

		saveMessageTemplate() {
			this.resetErrors();

			const selectedMessageTemplate = this.selectedMessageTemplate;

			if (
				selectedMessageTemplate &&
				selectedMessageTemplate.save &&
				typeof selectedMessageTemplate.save === 'function'
			) {
				return selectedMessageTemplate
					.save()
					.then(() => {
						this.notifications.success('Message Template has been saved.', {
							autoClear: true,
							clearDuration: 5000,
						});
					})
					.catch((err) => {
						this.notifications.error('Message Template was not saved.');
						this.set('errors', Errors.mapResponseErrors(err));
						this.set('showErrors', true);
					});
			}
		},

		deleteMessageTemplate() {
			const selectedMessageTemplate = this.selectedMessageTemplate;
			this.resetErrors();

			if (this.isDeleting) return false;

			const messageTemplates = this.messageTemplates;
			const deletingId = selectedMessageTemplate.id;

			//* Set isDeleting to true to prevent them from click spamming the action
			this.set('isDeleting', true);

			return selectedMessageTemplate
				.destroyRecord()
				.then(() => {
					//* Set isDeleting to false since it is no longer deleting
					this.set('isDeleting', false);

					const newMessageTemplatesArray = messageTemplates.filter(
						(template) => {
							return template.id !== deletingId;
						},
					);

					this.set('messageTemplates', newMessageTemplatesArray);

					//* Set the selectedMessageTemplate to the first template available in the array
					this.set('selectedMessageTemplate', this.messageTemplates[0]);
				})
				.catch((err) => {
					this.set('isDeleting', false);
					this.set('errors', Errors.mapResponseErrors(err));
					this.set('showErrors', true);
					this.notifications.error('Message Template was not deleted.');
				});
		},
	},
});
