/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
0;

const availableTypeFormComponents = [
	'typeform-fields/date',
	'typeform-fields/file-upload',
	'typeform-fields/legal',
	'typeform-fields/long-text',
	'typeform-fields/multiple-choice',
	'typeform-fields/number',
	'typeform-fields/short-text',
	'typeform-fields/yes-no',
	'typeform-fields/phone-number',
];

export default Component.extend({
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'typeform-response row start-xs top-xs margin-left',
	attributeBindings: ['data-test-id'],
	'data-test-id': 'content',

	filteredQuestions: computed('model.questions', function () {
		if (!this.model || !this.get('model.questions')) return [];
		const questions = this.get('model.questions') || [];

		//* Filter out unavailable types so they don't catastrophically break everything and are, instead, just skipped
		return questions.filter((question) => {
			const isValidComponent = availableTypeFormComponents.includes(
				question.component,
			);

			return isValidComponent;
		});
	}),
});
