/** @format */

import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	store: service(),
	activityTracking: service(),

	model() {
		const theCase = this.modelFor('app.client.case').theCase;
		const treatmentTypes = this.store.peekAll('treatmentType');

		return hash({
			theCase: theCase,
			newTreatment: this.store.createRecord('appointment', { case: theCase }),
			treatmentTypes: treatmentTypes.length
				? treatmentTypes
				: this.store.findAll('treatmentType'),
			appointments: this.store.query('appointment', { case_id: theCase.id }),
			medicalProviders: this.store.query('medicalProvider', {
				case_id: theCase.id,
			}),
		});
	},

	setupController(controller, model) {
		this._super(...arguments);
		this.activityTracking.trackByCase(model?.theCase?.id, 'viewedAppointments');
	},

	actions: {
		cancelEditing() {
			this.controllerFor('app.client.case.appointments').set(
				'showNewAppointmentForm',
				false,
			);
		},

		newTreatmentForm() {
			this.controllerFor('app.client.case.treatments').set(
				'showNewTreatmentForm',
				true,
			);
			this.transitionTo('app.client.case.treatments');
		},
	},
});
