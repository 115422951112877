/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
	currentUser: service(),
	attributeBindings: ['id', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'typeform-date row col-xs-4 margin-bottom',
	id: alias('model.id'),
	'data-test-id': 'component',

	//* Property that provides either 'this.currentUser.user.firm' or 'this.currentUser.user.organization'
	//* depending of which is available, with firm's availability checked first then defaulting to organization there after.
	//* This is needed to pass to the date-display helper in the template for this component, which searches on the object for
	//* the 'isEu' property specifically
	isEu: computed('currentUser.user.{firm,organization}', function () {
		const firm = this.currentUser.user.firm;
		const org = this.currentUser.user.organization;
		return firm ? firm : org;
	}),
});
