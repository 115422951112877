/**
 * Created by kevin on 6/13/17.
 *
 * @format
 */

import { isEmpty } from '@ember/utils';

import { computed } from '@ember/object';
import { assign } from '@ember/polyfills';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import { warn } from '@ember/debug';
import { A } from '@ember/array';
import Oauth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import ENV from 'case-status/config/environment';

export default Oauth2PasswordGrant.extend({
	clientId: 'web',
	serverTokenEndpoint: ENV.host + '/token',
	serverTokenRevocationEndpoint: ENV.host + '/token/revoke',

	_clientIdHeader: computed('clientId', function () {
		const clientId = ENV.clientId;
		const clientSecret = ENV.clientSecret;

		if (!isEmpty(clientId)) {
			const base64ClientId = window.btoa(clientId + ':' + clientSecret);
			return { Authorization: `Basic ${base64ClientId}` };
		}
		return;
	}),

	authenticate: function (...options) {
		// options may include: emailAddress + password, username + pin, or token
		if (options.length === 1 && options[0]?.access_token) {
			// if there's only one option and it has an access_token
			return new RSVP.Promise((resolve) => {
				resolve(options[0]);
			});
		} else {
			// otherwise, process this as a typical authentication 
			return this._super(...options);
		}
	},

	invalidate(data) {
		const serverTokenRevocationEndpoint = this.serverTokenRevocationEndpoint;
		function success(resolve) {
			run.cancel(this._refreshTokenTimeout);
			delete this._refreshTokenTimeout;
			resolve();
		}
		return new RSVP.Promise((resolve) => {
			if (isEmpty(serverTokenRevocationEndpoint)) {
				success.apply(this, [resolve]);
			} else {
				const requests = [];
				A(['access_token', 'refresh_token']).forEach((tokenType) => {
					const token = data[tokenType];
					const grantType =
						tokenType === 'access_token' ? 'password' : tokenType;
					if (!isEmpty(token)) {
						requests.push(
							this.makeRequest(serverTokenRevocationEndpoint, {
								token_type_hint: tokenType,
								token,
								client_id: ENV.clientId,
								client_secret: ENV.clientSecret,
								grant_type: grantType,
							}),
						);
					}
				});
				const succeed = () => {
					success.apply(this, [resolve]);
				};
				RSVP.all(requests).then(succeed, succeed);
			}
		});
	},

	_refreshAccessToken(expiresIn, refreshToken) {
		const data = {
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: ENV.clientId,
			client_secret: ENV.clientSecret,
		};
		const serverTokenEndpoint = this.serverTokenEndpoint;
		return new RSVP.Promise((resolve, reject) => {
			this.makeRequest(serverTokenEndpoint, data).then(
				(response) => {
					run(() => {
						expiresIn = response['expires_in'] || expiresIn;
						refreshToken = response['refresh_token'] || refreshToken;
						const expiresAt = this._absolutizeExpirationTime(expiresIn);
						const data = assign(response, {
							expires_in: expiresIn,
							expires_at: expiresAt,
							refresh_token: refreshToken,
						});
						this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
						this.trigger('sessionDataUpdated', data);
						resolve(data);
					});
				},
				(response) => {
					warn(
						`Access token could not be refreshed - server responded with ${response.responseJSON}.`,
						false,
						{ id: 'ember-simple-auth.failedOAuth2TokenRefresh' },
					);
					reject();
				},
			);
		});
	},
});
