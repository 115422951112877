/**
 * Created by kevin on 7/17/17.
 *
 * @format
 */

import ApplicationSerializer from './application';
export default ApplicationSerializer.extend({
	attrs: {
		createdAt: { serialize: false },
		sentAt: { serialize: false },
		sender: { serialize: false },
		senderName: { serialize: false },
		senderType: { serialize: false },
		senderChildId: { serialize: false },
	},
});
