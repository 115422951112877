/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
	amount: attr('number'), //* Calculated property from receivable for readability

	caseId: attr('number'),

	createdAt: attr('date'),
	deletedAt: attr('date'),

	firmId: attr('number'),

	invoiceCategory: attr('string'),
	invoiceDate: attr('date'),
	invoiceType: attr('string'), //* Calculated property from invoice_category for readability

	payPaidPartnerOnResolution: attr('boolean'),

	payable: attr('number'),
	payableErrorMessage: attr('string'),
	payableProcessed: attr('number'),
	payableProcessedDate: attr('date'),
	payableProcessedMessage: attr('string'),

	receivable: attr('number'),
	receivableErrorMessage: attr('string'),
	receivableProcessed: attr('number'),
	receivableProcessedDate: attr('date'),
	receivableProcessedMessage: attr('string'),
	receivableSuccessMessage: attr('string'),
	processOnActivation: attr('boolean'),
	processOnResolution: attr('boolean'),

	status: attr('string'),

	stripeChargeId: attr('string'),
	stripeReceiptId: attr('string'),
	stripeRefundId: attr('string'),

	updatedAt: attr('date'),

	//* These seem to be calculated properties on the /invoices/case_invoices/:case_id response, not the /invoices response
	openCaseFeeReceivable: attr('number'),
	closeCaseFeeReceivable: attr('number'),
	refundCaseFeeCharged: attr('number'),

	//* Relationships
	case: belongsTo('case'),
});
