/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';

export default Component.extend({
	currentUser: service(),
	router: service(),
	thirdParty: service(),
	host: ENV.host,

	treatmentLogged: computed('messageType', function () {
		const messageType = this.messageType;

		return messageType === 'treatment_logged';
	}),

	logReminder: computed('messageType', function () {
		const messageType = this.messageType;

		return messageType === 'reminder_log_treatment';
	}),

	appointmentReminder: computed('messageType', function () {
		const messageType = this.messageType;

		return messageType === 'reminder_appointment';
	}),

	disableLogTreatmentButton: computed(
		'treatmentId',
		'hasLoggedTreatment',
		function () {
			const treatmentId = this.treatmentId;
			const hasLoggedTreatment = this.hasLoggedTreatment;

			return hasLoggedTreatment || !treatmentId;
		},
	),

	updateMessageData() {
		const message = this.message;

		// Should be updated on backend to true as well, so once it reloads from a refresh it should still be true
		message.set('hasLoggedTreatment', true);
	},

	actions: {
		logTreatment() {
			const router = this.router;
			const treatmentId = this.treatmentId;
			const caseId = this.get('message.case.id');
			let routePath = 'app.firm.case.treatments';

			if (this.get('currentUser.userType') === 'client') {
				routePath = 'app.client.case.treatments';
			}

			//* Open in a new tab/window if in an iFrame
			if (this.thirdParty.shouldRedirect || caseId) {
				let userTypePath = 'firm';

				if (this.get('currentUser.userType') === 'client') {
					userTypePath = 'client';
				}

				window.open(
					`${this.host}/app/${userTypePath}/case/${caseId}/treatments`,
					'',
				);

				return false;
			}

			if (router) {
				router
					.transitionTo(routePath)
					.then((route) => {
						const logController = route.controller;
						const treatmentCards = logController.get('treatmentCards') || [];

						if (treatmentCards.length) {
							logController.set('editId', treatmentId);
							//Set the call back for save or delete if the treatment exists and is available for edit
							logController.set('callback', () => {
								this.updateMessageData();
							});
						}
					})
					.finally(() => {
						setTimeout(() => {
							const treatmentCard = document.getElementById(
								`treatment-${treatmentId}`,
							);
							treatmentCard.scrollIntoView();
						}, 500);
					});
			}
		},
		viewTreatment() {
			const router = this.router;
			const treatmentId = this.treatmentId;
			const caseId = this.get('message.case.id');

			let routePath = 'app.firm.case.treatments';

			if (this.get('currentUser.userType') === 'client') {
				routePath = 'app.client.case.treatments';
			}

			//* Open in a new tab/window if in an iFrame
			if (this.thirdParty.shouldRedirect || caseId) {
				let userTypePath = 'firm';

				if (this.get('currentUser.userType') === 'client') {
					userTypePath = 'client';
				}

				window.open(
					`${this.host}/app/${userTypePath}/case/${caseId}/treatments`,
					'',
				);

				return false;
			}

			if (router) {
				router.transitionTo(routePath).finally(() => {
					setTimeout(() => {
						const treatmentCard = document.getElementById(
							`treatment-${treatmentId}`,
						);
						treatmentCard.scrollIntoView();
					}, 500);
				});
			}
		},
	},
});
