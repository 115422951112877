/** @format */

import Model, { hasMany, attr } from '@ember-data/model';

export default Model.extend({
	createdAt: attr('date'),
	contentType: attr('string'),
	extension: attr('string'),
	fileName: attr('string'),
	size: attr('number'),
	senderId: attr('number'),
	url: attr('string'),
	uuid: attr('string'),
	caseId: attr('number'),

	documentSignatories: hasMany('document-signatory'),
});
