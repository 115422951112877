/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import webNotification from 'simple-web-notification';
import { observer } from '@ember/object';

export default Route.extend({
	ajax: service(),
	currentUser: service('current-user'),
	session: service(),
	pubnub: service('pubnub-service'),

	notificationObserver: observer('pubnub.newMessage', function () {
		const newMessage = this.get('pubnub.newMessage');
		const _this = this;
		if (newMessage) {
			const newMessageContent = $(
				'<p>' + newMessage.get('content') + '</p>',
			).text();
			//Add the new notification to the case
			const theCase = this.store.peekRecord('case', newMessage.get('caseId'));
			theCase.get('notifications').pushObject(newMessage);
			webNotification.showNotification(
				'New message from ' + newMessage.get('senderName'),
				{
					body: newMessageContent,
					icon: 'img/favicon.png',
					onClick: function onNotificationClick() {
						_this.transitionTo(
							'app.client.case.info',
							newMessage.get('caseId'),
						);
					},
				},
			);
		}
	}).on('init'),

	actions: {
		sendFeedback(feedback, result) {
			const url = `${ENV.host}/net_promoter_score`;

			const stageKey = `feedback_stage_${feedback.stage}`;
			let data = {
				case_id: feedback.caseId,
				stage: feedback.stage,
				score: feedback.score,
				feedback: feedback.additionalFeedback,
			};

			if (feedback.feedback) {
				data[stageKey] = feedback.feedback;
			}
			return this.ajax
				.post(url, {
					data,
				})
				.then(({ data }) => {
					const controller = this.controllerFor('app.client.case.info');
					const model = controller.model.theCase;
					controller.set('npsScore', data);

					const showNps = this.get('currentUser.user.showNps');
					for (let i = 0; i < showNps.length; i++) {
						if (showNps[i] === +model.get('id')) {
							showNps.splice(i, 1);
						}
					}

					result.resolve(showNps);
				})
				.catch((error) => result.reject(error));
		},
	},
});
