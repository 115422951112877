/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';
import { isFunction } from 'case-status/constants/data-types';
import { inject as service } from '@ember/service';

export default Component.extend({
	errorHandling: service(),
	valueObserver: observer('value', function () {
		const onRowToggled = this?.tableActions?.onRowToggled;
		const onUserToggled = this?.tableActions?.onUserToggled;
		if (isFunction(onRowToggled)) {
			onRowToggled(this.row, this.value);
		} else if (isFunction(onUserToggled)) {
			onUserToggled(this.row, this.value);
		} else {
			this.errorHandling.catchSilently('onRowToggled is not a function');
		}
	}),
});
