/** @format */

import ApplicationAdapter from './application';

export default class ActivityStatisticPeriodAdapter extends ApplicationAdapter {
	urlForQuery(query) {
		if (query.caseId) {
			const result = `${this.host}/activities/statistics/${query.caseId}`;
			delete query.caseId;

			return result;
		}
	}
}
