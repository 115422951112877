/** @format */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MobileClientProcessTabComponent extends Component {
	@service company;

	get stages() {
		return this.args.model.get('caseType.caseStatuses');
	}

	get currentStageNumber() {
		return this.args.model.get('caseStatus.number');
	}

	get isClosed() {
		return Boolean(this.args.model.get('closed'));
	}
}
