/** @format */

import { observer } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { format, getCountryCallingCode } from 'libphonenumber-js';

export default Component.extend({
	init() {
		this._super(...arguments);

		const value = this.value;
		this.set('phone', value);

		if (value) {
			if (value.startsWith('+')) {
				const formattedNumber = format(value, 'International');

				if (formattedNumber) {
					const countryLength = formattedNumber.indexOf(' ');
					const country = formattedNumber.substring(1, countryLength);
					const phone = formattedNumber.substring(countryLength + 1);
					this.set('country', country);
					this.set('phone', phone.replace(/\s/g, ''));
				}
			}
		} else {
			const region =
				this.get('currentUser.firmRegion') ||
				this.get('currentUser.orgRegion') ||
				'US';
			const countryCode = getCountryCallingCode(region);
			this.set('country', countryCode);
		}
	},

	currentUser: service(),

	phoneNumberObserver: observer('country', 'phone', function () {
		const country = this.country;
		const phone = this.phone;
		const emitError = this.emitError;

		if (!country && phone && emitError) {
			emitError('Please provide a country code');
		} else if (emitError) {
			emitError('');
		}

		if (!country || !phone) {
			this.set('value', null);
		} else {
			const phoneNumber = '+' + country + phone;
			this.set('value', phoneNumber);
		}
	}),
});
