/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	content: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Message',
	}),
});

export default Model.extend(Validations, {
	//because content is a reserved word
	body: computed('content', function () {
		return this.content;
	}),
	createdAt: attr('date'),
	sentAt: attr('date'),
	content: attr('string'),
	integrationError: attr('string'),
	senderName: attr('string'),
	senderChildId: attr('number'),
	senderType: attr('string'),
	scheduledMessageSendDate: attr('date'),
	sentAsText: attr('boolean'),
	statusNumber: attr('number'),
	type: attr('string'),
	automatedMessageType: attr('string'),
	hasLoggedTreatment: attr('boolean'),
	treatmentId: attr('number'),
	checklistItemId: attr('number'),
	signedDocumentLink: attr('string'),
	messageReadBy: attr(),
	messageTemplateType: attr('string'),
	receivedAsText: attr('boolean'),
	smsErrors: attr(),
	originalContent: attr('string'),
	translatedContent: attr('string'),
	targetLanguage: attr('string'),
	messageTemplateId: attr('string'),
	translationEdited: attr('boolean'),
	recommendedResponseRequested: attr('date'),

	notifs: computed(
		'integrationError',
		'sentAsText',
		'receivedAsText',
		'smsErrors',
		function () {
			//* Usable notif types are "info", "warning", "error"
			const formattedNotifs = [];
			const integrationError = this.integrationError;
			const sentAsText = this.sentAsText;
			const receivedAsText = this.receivedAsText;
			const smsErrors = this.smsErrors;

			if (sentAsText) {
				formattedNotifs.push({
					type: 'info',
					content: 'Sent as text message',
				});
			}

			if (receivedAsText) {
				formattedNotifs.push({
					type: 'info',
					content: 'Received as text message',
				});
			}

			if (integrationError) {
				formattedNotifs.push({
					type: 'warning',
					content: integrationError,
				});
			}

			if (smsErrors && smsErrors.length > 0) {
				formattedNotifs.push({
					type: 'error',
					content: smsErrors + '',
					smsError: true,
				});
			}

			return formattedNotifs;
		},
	),

	// Relationships
	case: belongsTo('case', { inverse: 'messages' }),
	recommendedResponseUsed: belongsTo('recommendedResponse'),
	file: belongsTo('file'),
	sender: belongsTo('user'),
	notification: belongsTo('notification'),
});
