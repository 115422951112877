/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

@classic
export default class MessageNotifComponent extends Component {
	@tracked isLoading = false;
	model;

	@action
	onClick() {
		if (this.isLoading) return false;
		if (!this.clientSmsAllowed) return false;
		if (this.resend) {
			this.set('isLoading', true);
			this.resend()
				.then(() => {
					this.set('isLoading', false);
					this.set('model.hide', true);
				})
				.catch(() => {
					this.set('isLoading', false);
				});
		}
	}
}
