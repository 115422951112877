import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import ENV from 'case-status/config/environment';
import Errors from '../../../../constants/errors';
import moment from 'moment';
import RSVP from 'rsvp';
import { reviewDisplay } from '../../../../helpers/review-display';

const redirectUri = encodeURI(`${window.location.origin}/app/clio`);
const litifyRedirectUri = encodeURI(`${window.location.origin}/app/litify`);
const salesforceRedirectUri = encodeURI(
	`${window.location.origin}/app/salesforce`,
);
let smokeballRedirectUri = encodeURI(`${window.location.origin}/app/smokeball`);

export default Controller.extend({
	smartAdvocateCredentialsSuccess: false,
	smartAdvocateCredentialsErrors: null,

	init() {
		this._super(...arguments);
		const caseManagementOptions = [
			{
				name: 'CasePeer',
				requiresManual: true,
			},
			{
				name: 'Clio',
				requiresManual: false,
			},
			{
				name: 'Filevine',
				requiresManual: true,
			},
			{
				name: 'MerusCase',
				requiresManual: false,
			},
			{
				name: 'MyCase',
				requiresManual: false,
			},
			{
				name: 'SmartAdvocate',
				requiresManual: true,
			},
			{
				name: 'Practice Panther',
				requiresManual: true,
			},
			{
				name: 'Litify',
				requiresManual: false,
			},
			{
				name: 'Salesforce',
				requiresManual: false,
			},
			{
				name: 'Other',
				requiresManual: true,
			},
			{
				name: 'Zapier',
				requiresManual: true,
			},
			{
				name: 'Neos',
				requiresManual: true,
			},
			{
				name: 'Smokeball',
				requiresManual: false,
			},
		];

		this.set('caseManagementOptions', caseManagementOptions);
		this.set('caseMangementSorting', ['name']);
	},

	currentUser: service(),
	store: service(),
	company: service(),
	clioAuthorizationUrl: computed('model.firm.isEu', function () {
		return `https://${
			this.get('model.firm.isEu') ? `eu.app.clio.com` : `app.clio.com`
		}/oauth/authorize?response_type=code&client_id=${this.get('model.firm.isEu') ? ENV.clioEUClientId : ENV.clioNAClientId}&redirect_uri=${redirectUri}`;
	}),
	salesforceAuthorizationUrl: computed('model.firm.clientId', function () {
		return `https://login.salesforce.com/services/oauth2/authorize?client_id=${this.get(
			'model.firm.clientId',
		)}&redirect_uri=${salesforceRedirectUri}&response_type=code`;
	}),
	litifyAuthorizationUrl: computed('model.firm.clientId', function () {
		return `https://login.salesforce.com/services/oauth2/authorize?client_id=${this.get(
			'model.firm.clientId',
		)}&redirect_uri=${litifyRedirectUri}&response_type=code`;
	}),
	merusCaseAuthorizationUrl: computed(function () {
		return `https://meruscase.com/oauth/authorize?client_id=${ENV.merusCaseClientId}&response_type=code`;
	}),
	myCaseAuthorizationUrl: computed(function () {
		return `https://auth.${
			ENV.environment == 'production' ? 'mycase' : 'mycasekegging'
		}.com/login_sessions/new?client_id=${ENV.myCaseClientId}&response_type=code`;
	}),
	smokeballAuthorizationUrl: computed(function () {
		let val = `https://${
			ENV.environment == 'production'
				? 'auth.smokeball'
				: 'datastaging-auth.smokeball'
		}.com/oauth2/authorize?client_id=${
			ENV.smokeballClientId
		}&response_type=code&redirect_uri=${smokeballRedirectUri}`;
		return val;
	}),
	integration_type: null,

	integrationSuccessObserver: observer(
		'integration_success',
		'integration_type',
		function () {
			if (!this.integration_success) {
				return;
			}
			const firm = this.get('currentUser.user.firm');

			if (firm) {
				if (this.integration_type === 'merus_case') {
					firm.set('usesMerusCase', true);
				} else if (this.integration_type === 'clio') {
					firm.set('usesClio', true);
				} else if (this.integration_type === 'mycase') {
					firm.set('usesMyCase', true);
				} else if (this.integration_type === 'litify') {
					firm.set('usesLitify', true);
				} else if (this.integration_type === 'salesforce') {
					firm.set('usesSalesforce', true);
				} else if (this.integration_type === 'smokeball') {
					this.model.firm.reload();
				}
			}
		},
	),
	integration_errors: computed('integration_error', function () {
		if (this.integration_error) {
			return [this.integration_error];
		}
	}),

	sortedCaseManagementOptions: sort(
		'caseManagementOptions',
		'caseMangementSorting',
	),
	selectedCaseManagementOption: null,
	showCaseManagementIntegrationConfig: false,
	hasIntegration: computed(
		'model.firm.{usesClio,usesSmartAdvocate,usesLitify,usesMerusCase,usesFilevine,usesCasePeer,usesMyCase,usesSalesforce,usesNeos,usesSmokeball}',
		function () {
			return (
				this.get('model.firm.usesClio') ||
				this.get('model.firm.usesSmartAdvocate') ||
				this.get('model.firm.usesLitify') ||
				this.get('model.firm.usesMerusCase') ||
				this.get('model.firm.usesFilevine') ||
				this.get('model.firm.usesCasePeer') ||
				this.get('model.firm.usesMyCase') ||
				this.get('model.firm.usesSalesforce') ||
				this.get('model.firm.usesNeos') ||
				this.get('model.firm.usesSmokeball')
			);
		},
	),

	reauthExpired: computed('model.firm.reauthDate', function () {
		const now = moment(new Date());

		return (
			this.get('model.firm.reauthDate') &&
			moment(this.get('model.firm.reauthDate')).isBefore(now)
		);
	}),

	queryParams: ['integration_success', 'integration_error', 'integration_type'],
	reviewLink: null,
	formattedReviewLink: computed('reviewLink', function () {
		let formattedReviewLink = this.reviewLink;

		const isHttp = formattedReviewLink.indexOf('http:');
		const isHttps = formattedReviewLink.indexOf('https:');

		if (isHttp === -1 && isHttps === -1) {
			// Defaulting to http because non ssl sites don't redirect from https to http but most ssl sites do redirect from http to https
			formattedReviewLink = `http://${formattedReviewLink}`;
		} else if (isHttps > 0) {
			formattedReviewLink = formattedReviewLink.slice(0, isHttps);
		} else if (isHttp > 0) {
			formattedReviewLink = formattedReviewLink.slice(0, isHttp);
		}

		return formattedReviewLink;
	}),
	reviewErrors: null,
	reviewSuccess: null,

	actions: {
		updateReviewLink() {
			this.set('reviewSuccess', null);
			this.set('reviewErrors', null);

			this.store
				.findRecord('firm', this.get('model.firm.id'))
				.then((firm) => {
					firm.set('reviewLink', this.formattedReviewLink);
					firm
						.save()
						.then(() => {
							this.set('reviewLink', this.formattedReviewLink);
							this.set('model.firm', firm);
							this.set(
								'reviewSuccess',
								`${reviewDisplay([
									this.get('model.firm.reviewLink'),
								])} Review link saved!`,
							);
						})
						.catch((err) => {
							this.set('reviewErrors', Errors.mapResponseErrors(err));
						});
				})
				.catch((err) => {
					this.set('reviewErrors', Errors.mapResponseErrors(err));
				});
		},
		updateSmartAdvocate() {
			const results = RSVP.defer();
			const action = this.hasIntegration ? 'PATCH' : 'POST';

			this.set('smartAdvocateCredentialsSuccess', false);
			this.set('smartAdvocateCredentialsErrors', null);

			// if password and username is not set (for POST action) then throw error and return
			if (
				action === 'POST' &&
				(!this.get('credential.username') || !this.get('credential.password'))
			) {
				this.set('smartAdvocateCredentialsErrors', [
					'Please set a username and password for your SmartAdvocate account.',
				]);
				return new RSVP.Promise((resolve) => {
					resolve();
				});
			}

			this.send(
				'updateSmartAdvocateCredentials',
				this.get('credential.username'),
				this.get('credential.password'),
				action,
				results,
			);

			return results.promise
				.then(() => {
					this.set('smartAdvocateCredentialsSuccess', true);
				})
				.catch(({ payload }) => {
					// format errors
					const formattedErrors = payload.errors.map((e) => e.detail);

					this.set('smartAdvocateCredentialsErrors', formattedErrors);
				});
		},
		selectCaseManagementOption(option) {
			this.set('selectedCaseManagementOption', option);
		},
		toggleCaseManagementIntegrationConfig() {
			this.toggleProperty('showCaseManagementIntegrationConfig');
		},
	},
});
