/** @format */

import Controller from '@ember/controller';
import Validations from '../../../../constants/validations';
import { buildValidations } from 'ember-cp-validations';
import SettingsAccountMixin from '../../../../mixins/controller/settings/account';

export default Controller.extend(
	buildValidations(Validations.Password),
	SettingsAccountMixin,
	{
		actions: {
			toggleEmailNotification() {
				const currentValue = !!this.get('model.wantsEmailNotifications');
				this.set('model.wantsEmailNotifications', !currentValue);
				return this.saveModel()
					.then(() => {
						this.set('isEditingAccountInfo', false);
						this.set('showAccountError', false);
					})
					.catch(() => {
						this.set('showAccountError', true);
					});
			},
		},
	},
);
