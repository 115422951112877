/** @format */

import { defer } from 'rsvp';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import { next } from '@ember/runloop';

import ENV from 'case-status/config/environment';
import Errors from '../../../../constants/errors';
import VideoHandler from 'case-status/modules/VideoHandler';

const Validations = buildValidations({
	newMessage: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Message',
	}),
});

export default Controller.extend(Validations, {
	currentUser: service('current-user'),
	session: service('session'),
	company: service(),
	mobile: service(),
	activityTracking: service(),
	hideNps: false,
	hoveredStep: 0,
	messagesSort: ['sentAt:desc'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	shouldTruncateBio: true,
	showMessageActionsModal: false,
	isWritingMessage: false,
	newMessage: '',
	ajax: service(),
	messageSearchText: '',
	showMessageFilter: false,
	currentActiveTab: 'messages',
	shouldUpdateMessages: false,
	defaultCaseClosedModalDescription:
		"It's been a pleasure working with you. Please let me know if there's anything I can help you with in the future.",

	hasCompletedTeamProfile: computed(
		'model.theCase.{attorneys,paralegals}.@each.hasCompletedProfile',
		function () {
			const hasCompletedAttorneyProfile = this.get(
				'model.theCase.attorneys',
			).any((attorney) => {
				return attorney.get('hasCompletedProfile');
			});

			const hasCompletedParalegalProfile = this.get(
				'model.theCase.paralegals',
			).any((paralegals) => {
				return paralegals.get('hasCompletedProfile');
			});

			return hasCompletedAttorneyProfile || hasCompletedParalegalProfile;
		},
	),

	messagesToSort: computed('model.theCase.messages', function () {
		const messages = this.get('model.theCase.messages');

		if (messages) {
			return messages;
		}
		return [];
	}),

	sortedMessages: sort('messagesToSort', 'messagesSort'),

	truncatedBioObserver: observer(
		'model.theCase.attorneys.[]',
		'model.theCase.paralegals.[]',
		function () {
			const attorneys = this.get('model.theCase.attorneys.[]') || [];
			const paralegals = this.get('model.theCase.paralegals.[]') || [];

			attorneys?.forEach((attorney) => {
				attorney.set('showLongerBio', false);
			});

			paralegals?.forEach((paralegal) => {
				paralegal.set('showLongerBio', false);
			});
		},
	),

	currentStep: computed('model.theCase.caseStatus.number', function () {
		switch (this.get('model.theCase.caseStatus.number')) {
			case 1:
				return 'step-one';
			case 2:
				return 'step-two';
			case 3:
				return 'step-three';
			case 4:
				return 'step-four';
			case 5:
				return 'step-five';
			case 6:
				return 'step-six';
			case 7:
				return 'step-seven';
			case 8:
				return 'step-eight';
			case 9:
				return 'step-nine';
			case 10:
				return 'step-ten';
			case 11:
				return 'step-eleven';
			case 12:
				return 'step-twelve';
			default:
				return '';
		}
	}),

	selectedStatus: computed(
		'model.theCase.caseStatus',
		'hoveredStep',
		'statuses',
		function () {
			const hoveredStep = this.hoveredStep;
			const statuses = this.statuses || [];
			let selectedStatus;

			if (hoveredStep) {
				selectedStatus = statuses.findBy('number', hoveredStep);
			} else {
				selectedStatus = this.get('model.theCase.caseStatus');
			}

			if (selectedStatus) {
				const videoId = selectedStatus.get('videoId');
				if (videoId) {
					this.store.findRecord('video', videoId).then((video) => {
						this.set('video', video);
					});
				} else {
					this.set('video', null);
				}
			}

			return selectedStatus;
		},
	),

	selectedStep: computed('currentStep', 'hoveredStep', function () {
		const hoveredStep = this.hoveredStep;

		switch (hoveredStep) {
			case 1:
				return 'step-one';
			case 2:
				return 'step-two';
			case 3:
				return 'step-three';
			case 4:
				return 'step-four';
			case 5:
				return 'step-five';
			case 6:
				return 'step-six';
			case 7:
				return 'step-seven';
			case 8:
				return 'step-eight';
			case 9:
				return 'step-nine';
			case 10:
				return 'step-ten';
			case 11:
				return 'step-eleven';
			case 12:
				return 'step-twelve';
			default:
				return this.currentStep;
		}
	}),
	stepOneClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');

		if (!number) {
			return 'inactive';
		}
		return number === 1 ? 'current' : '';
	}),
	stepTwoClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 2) {
			return 'inactive';
		} else if (number === 2) {
			return 'current';
		}
		return '';
	}),
	stepThreeClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 3) {
			return 'inactive';
		} else if (number === 3) {
			return 'current';
		}
		return '';
	}),
	stepFourClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 4) {
			return 'inactive';
		} else if (number === 4) {
			return 'current';
		}
		return '';
	}),
	stepFiveClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 5) {
			return 'inactive';
		} else if (number === 5) {
			return 'current';
		}
		return '';
	}),
	stepSixClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 6) {
			return 'inactive';
		} else if (number === 6) {
			return 'current';
		}
		return '';
	}),

	stepSevenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 7) {
			return 'inactive';
		} else if (number === 7) {
			return 'current';
		}
		return '';
	}),

	stepEightClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 8) {
			return 'inactive';
		} else if (number === 8) {
			return 'current';
		}
		return '';
	}),

	stepNineClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 9) {
			return 'inactive';
		} else if (number === 9) {
			return 'current';
		}
		return '';
	}),

	stepTenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 10) {
			return 'inactive';
		} else if (number === 10) {
			return 'current';
		}
		return '';
	}),

	stepElevenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 11) {
			return 'inactive';
		} else if (number === 11) {
			return 'current';
		}
		return '';
	}),

	stepTwelveClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 12) {
			return 'inactive';
		} else if (number === 12) {
			return 'current';
		}
		return '';
	}),

	hasUncheckedItems: computed(
		'model.theCase.sortedChecklistItems.[]',
		function () {
			return this.get('model.theCase.sortedChecklistItems').filter((item) => {
				return !item.completedDate;
			});
		},
	),

	hideGavalIcon: computed(
		'currentUser.user.firm.firmSettings.firmSettings.disable_case_closed_gaval',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.disable_case_closed_gaval',
			);
		},
	),

	async init() {
		this._super(...arguments);

		this.set('errors', []);

		/* attempt to pull case_closed_modal_title and
		case_closed_modal_description from message templates */
		const messageTemplates = await this.store.findAll('message-template');
		const caseClosedModalDescriptionTemplate = messageTemplates.find(
			(x) => x.templateType == 'case_closed_modal_description',
		);
		const caseClosedModalTitleTemplate = messageTemplates.find(
			(x) => x.templateType == 'case_closed_modal_title',
		);

		this.caseClosedModalDescriptionContent =
			caseClosedModalDescriptionTemplate.content;
		this.caseClosedModalTitleContent = caseClosedModalTitleTemplate.content;

		/* use a default value for case closed description if firm hasn't set a custom one */
		if (!this.caseClosedModalDescriptionContent) {
			this.caseClosedModalDescriptionContent =
				this.defaultCaseClosedModalDescription;
		}
	},

	actions: {
		downloadVideo(video) {
			VideoHandler.download(video.get('url'), video.get('filename'));
		},

		setActiveTab(tabName) {
			this.set('currentActiveTab', tabName);
		},

		decreaseNewMessagesCount() {
			const unreadCount = this.get('model.theCase.newMessages');
			if (unreadCount > 0) {
				this.set('model.theCase.newMessages', unreadCount - 1);
			}
		},

		showMoreBio(attorney) {
			attorney.toggleProperty('showLongerBio');
		},

		postFile(formData, result) {
			formData.append('case_id', this.get('model.theCase.id'));

			const url = `${ENV.host}/files`;
			const data = formData;
			const model = this.model;

			this.ajax
				.post(url, {
					processData: false,
					contentType: false,
					data: data,
				})
				.then(() => {
					model.theCase.reload();
					return result.resolve();
				})
				.catch((response) => {
					return result.reject(Errors.mapResponseErrors(response));
				});
		},

		toggleShowMessageActionsModal() {
			this.toggleProperty('showMessageActionsModal');
		},

		toggleWritingMessage() {
			this.toggleProperty('isWritingMessage');
			this.set('showMessageError', false);
			this.set('newMessage', '');
		},

		validateNewMessage(result) {
			if (
				this.get('validations.attrs.newMessage.message') &&
				this.isWritingMessage
			) {
				this.set('showMessageError', true);
				return result.reject();
			} else {
				this.send('sendMessage', this.newMessage, result);
				return result.promise
					.then(() => {
						this.toggleProperty('isWritingMessage');
						this.set('newMessage', '');
						this.set('showMessageError', false);
					})
					.catch(() => {
						this.set('showMessageError', true);
					});
			}
		},

		showLightboxImage(img) {
			this.set('lightboxImage', img);
		},

		hideLightboxImage() {
			this.set('lightboxImage', null);
		},

		handleStepSelect(step) {
			if (this.get('model.theCase.onHoldExplanation')) return;
			this.set('hoveredStep', step);
		},

		toggleShowMessageFilter() {
			this.set('messageSearchText', '');
			this.toggleProperty('showMessageFilter');
		},

		markAllMessagesRead(result = defer()) {
			const caseModel = this.model.theCase;
			let url =
				ENV.host +
				'/cases/' +
				caseModel.get('id') +
				'/relationships/notifications/mark_as_read';
			return this.ajax
				.post(url)
				.then(() => {
					const notifications = this.store
						.peekAll('notification')
						.filter((notification) => {
							return notification.get('caseId') === caseModel.id;
						});
					notifications.forEach((notification) => {
						const readAt = notification.get('readAt');
						if (!readAt) {
							notification.set('readAt', new Date());
						}
					});
					caseModel.set('notifications', notifications);
					caseModel.set('newMessages', 0);
					result.resolve();
				})
				.catch((response) => {
					this.set('errors', Errors.mapResponseErrors(response));
					result.reject();
				});
		},

		saveCaseModel() {
			return this.model.theCase
				.save()
				.then(() => {
					this.set('shouldUpdateMessages', true);
				})
				.finally(() => {
					next(() => {
						this.set('shouldUpdateMessages', false);
					});
				});
		},
	},
});
