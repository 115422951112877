/** @format */

import Route from '@ember/routing/route';
import VideoHandler from 'case-status/modules/VideoHandler';
import { inject as service } from '@ember/service';

export default class AppVideoRoute extends Route {
	@service ajax;
	async model({ video_id }) {
		const video = await this.store.findRecord('video', video_id);
		VideoHandler.download(video.get('url'), video.get('filename'));
	}
}
