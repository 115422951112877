/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	//MISC
	lastFeedback: attr(),
	mostRecentScore: attr('number'),
	// Stage 1
	stage_1: attr('number'),
	feedbackStage_1: attr('string'),
	updatedAt_1: attr(),

	// Stage 2
	stage_2: attr('number'),
	feedbackStage_2: attr('string'),
	updatedAt_2: attr(),

	// Stage 3
	stage_3: attr('number'),
	feedbackStage_3: attr('string'),
	updatedAt_3: attr(),

	// Stage 4
	stage_4: attr('number'),
	feedbackStage_4: attr('string'),
	updatedAt_4: attr(),

	// Stage 5
	stage_5: attr('number'),
	feedbackStage_5: attr('string'),
	updatedAt_5: attr(),

	// Stage 6
	stage_6: attr('number'),
	feedbackStage_6: attr('string'),
	updatedAt_6: attr(),

	// Stage 7
	stage_7: attr('number'),
	feedbackStage_7: attr('string'),
	updatedAt_7: attr(),

	// Stage 8
	stage_8: attr('number'),
	feedbackStage_8: attr('string'),
	updatedAt_8: attr(),

	// Stage 9
	stage_9: attr('number'),
	feedbackStage_9: attr('string'),
	updatedAt_9: attr(),

	// Stage 10
	stage_10: attr('number'),
	feedbackStage_10: attr('string'),
	updatedAt_10: attr(),

	// Stage 11
	stage_11: attr('number'),
	feedbackStage_11: attr('string'),
	updatedAt_11: attr(),

	// Stage 12
	stage_12: attr('number'),
	feedbackStage_12: attr('string'),
	updatedAt_12: attr(),

	// Relationships
	firm: belongsTo('firm'),
	case: belongsTo('case'),
	client: belongsTo('client', { async: true }),
});
