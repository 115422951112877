/** @format */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { hash } from 'rsvp';

import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';
import { reviewDisplay } from 'case-status/helpers/review-display';
import FileHandler from 'case-status/modules/FileHandler';

export default class AppFirmCaseMessagesRoute extends Route {
	@service ajax;
	@service permissions;
	@service store;

	async model() {
		const theCase = this.modelFor('app.firm.case').theCase;
		const files = await this.store.query(
			'file',
			{ id: theCase.id },
			{ reload: true },
		);
		const notifications = this.modelFor('app.firm.case').notifications;
		const messageTemplates = this.modelFor('app.firm.case').messageTemplates;
		theCase.reload();

		// slight performance boost from making the requests in parallel
		Promise.all([
			this.ajax.request(`${ENV.host}/cases/${theCase.id}/appointments`), // appointments
			this.ajax.request(`${ENV.host}/cases/${theCase.id}/medical_providers`), // medical_providers
			this.ajax.request(`${ENV.host}/cases/${theCase.id}/chats`), // chats
			this.ajax.request(
				`${ENV.host}/notifications?filter%5Bcase_id%5D=${theCase.id}&page%5Bsize%5D=50`,
			), // notifications
			this.store.findAll('message-template'), // load up message templates
			this.store.findAll('treatmentType'), // load up treatment types
		]).then((results) => {
			// push to store
			this.store.pushPayload('appointment', results[0]);
			this.store.pushPayload('medicalProvider', results[1]);
			this.store.pushPayload('chat', results[2]);
			this.store.pushPayload('notification', results[3]);
			// we shouldn't need to explicitly push message-template and treatmentType here because findAll
			// under the hood should update the store and use a cached response upon later invocations
		});

		return hash({
			theCase,
			files,
			notifications,
			messageTemplates,
		});
	}

	async setupController(controller, model) {
		super.setupController(controller, model);
		if (model.theCase?.caseSettings) {
			this.set('permissions.case', model.theCase.caseSettings);
		}

		if (
			model.theCase?.caseSettings?.allow_message_translation &&
			!controller?.languages?.length
		) {
			let languages = this.store.peekAll('language');
			if (!languages.length) {
				languages = await this.store.findAll('language');
			}
			controller.languages = languages;
		}
		controller.activityCheck = 0;
	}

	resetController(controller, isExiting) {
		if (isExiting) {
			controller.downloadTextSuccess = false;
			controller.downloadTextErrors = false;
			controller.showMessageFilter = false;
			controller.isEditingStatus = false;
		}
	}

	@action
	requestReview() {
		// linter says not to access the controller outside of resetController and setupController
		this.controller.showMessageActionsModal = false;
		if (this.preventReviewRequest) {
			this.controller.notifications.warning(
				`You must wait at least 10 seconds before requesting another review.`,
				{
					autoClear: true,
					clearDuration: 7000,
					// cssClasses: 'notification--success'
				},
			);
			return false;
		}
		this.preventReviewRequest = true;
		const reviewLink = this.currentUser.user.firm.reviewLink;
		if (reviewLink) {
			const caseId = this.currentModel.theCase.id;
			const url = ENV.host + '/cases/' + caseId + '/request_review';
			return this.ajax
				.post(url)
				.then((msg) => {
					this.store.pushPayload('message', msg);
					this.controller.notifications.success(
						`We sent a message to the client requesting a ${reviewDisplay([
							reviewLink,
						])} review!`,
						{
							autoClear: true,
							clearDuration: 7000,
							cssClasses: 'notification--success',
						},
					);
					setTimeout(() => {
						this.preventReviewRequest = false;
					}, 10000);
				})
				.catch((errors) => {
					this.preventReviewRequest = false;
					Errors.mapResponseErrors(errors).forEach((err) => {
						this.controller.notifications.error(err, {
							autoClear: true,
							clearDuration: 7000,
						});
					});
				});
		}
	}

	@action
	exportMessages(result) {
		const caseModel = this.modelFor('app.firm.case.messages').theCase;
		const url = ENV.host + `/messages/download?caseId=${caseModel.get('id')}`;
		return this.ajax
			.request(url)
			.then(({ data }) => {
				FileHandler.download(data.attributes.url, data.attributes.file_name);
				result.resolve();
			})
			.catch((error) => result.reject(error));
	}

	@action
	markAllMessagesRead(result) {
		const caseModel = this.modelFor('app.firm.case.messages').theCase;
		let url =
			ENV.host +
			'/cases/' +
			caseModel.get('id') +
			'/relationships/notifications/mark_as_read';
		return this.ajax
			.post(url)
			.then(() => {
				const notifications = this.store
					.peekAll('notification')
					.filter((notification) => {
						return notification.get('caseId') === this.id;
					});
				notifications.forEach((notification) => {
					const readAt = notification.get('readAt');
					if (!readAt) {
						notification.set('readAt', new Date());
					}
				});
				caseModel.set('notifications', notifications);
				caseModel.set('newMessages', 0);
				result.resolve();
			})
			.catch((response) => {
				const controller = this.controllerFor('app.firm.case.messages');
				controller.set('errors', Errors.mapResponseErrors(response));
				result.reject();
			});
	}

	@action
	sendMessage({ content, scheduledMessageSendDate, result }) {
		const model = this.modelFor('app.firm.case.messages').theCase;

		const newMessage = this.store.createRecord('message', {
			case: model,
			content,
			scheduledMessageSendDate,
		});

		newMessage
			.save()
			.then((message) => {
				if (scheduledMessageSendDate !== null) {
					this.modelFor(
						'app.firm.case.messages',
					).theCase.scheduledMessages.pushObject(message);
				}
				result.resolve();
			})
			.catch((response) => {
				newMessage.rollbackAttributes();
				const controller = this.controllerFor('app.firm.case.messages');
				controller.set('errors', Errors.mapResponseErrors(response));
				result.reject();
			});
	}

	@action
	deleteMessage(message, result) {
		message.destroyRecord().then(() => result.resolve());
	}

	@action
	editMessage(message, content, selectedTime, result) {
		let url = ENV.host + '/messages/' + message.get('id');
		// currently the scheduled endpoint does not specify case id, so we must set it.
		const data = {
			data: {
				type: 'messages',
				attributes: {
					content: content,
					scheduled_message_send_date: selectedTime,
				},
				relationships: {
					case: {
						data: {
							type: 'cases',
							id: 13,
						},
					},
				},
			},
		};
		return this.ajax
			.patch(url, {
				data: JSON.stringify(data),
			})
			.then((resp) => {
				result.resolve(resp);
			});
	}
}
