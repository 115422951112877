/** @format */

import Component from '@ember/component';

export default Component.extend({
	required: true, //* Default true, but allow to be overridden

	didRender() {
		if (this.focusOnRender) {
			this.$('textarea').focus();
		}
	},
});
