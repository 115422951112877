/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import Errors from 'case-status/constants/errors';
import ENV from 'case-status/config/environment';
import RSVP, { reject, resolve } from 'rsvp';
import { next } from '@ember/runloop';

export default Component.extend({
	customBranding: service('custom-branding'),
	ajax: service(),
	notifications: service(),

	init() {
		this._super(...arguments);

		//Only clear the model if we are not editing an existing client
		if (!this.isEditing) {
			this.set('model', {});
		} else {
			const user = new Changeset(this.userToEdit);
			this.set('model', user);
		}
	},

	showMergePrompt: false,
	showMergeVerification: false,
	checkErrResponse(errRes) {
		if (
			this.get('model.id') &&
			Errors.mapResponseErrors(errRes).includes('Phone Number already exists!')
		) {
			this.set('showMergePrompt', true);
			this.set('mergingPhone', true);
		}
	},

	actions: {
		close() {
			this.close();
		},
		handlePhoneInputError(message) {
			if (message) {
				this.set('errors', [message]);
			} else {
				this.set('errors', []);
			}
		},
		save() {
			const client = this.model;
			return this.save(client).catch((err) => {
				if (this.isEditing && this.userToEdit) {
					this.userToEdit?.rollbackAttributes();
				}
				this.checkErrResponse(err);
			});
		},

		requestMerge() {
			const data = this.mergingPhone
				? {
						cell_phone: this.get('model.cellPhone'),
				  }
				: {
						email_address: this.get('model.emailAddress'),
				  };
			return this.ajax
				.post(`${ENV.host}/users/request_transfer`, { data })
				.then(() => {
					this.set('showMergePrompt', false);
					this.set('showMergeVerification', true);
				});
		},

		async verifyMerge() {
			if (this.get('model.id')) {
				const data = this.mergingPhone
					? {
							cell_phone: this.get('model.cellPhone'),
					  }
					: {
							email_address: this.get('model.emailAddress'),
					  };

				data.verification_code = this.get('verificationCode');

				return this.ajax
					.post(
						`${ENV.host}/users/${this.get('model.userId')}/confirm_transfer`,
						{ data },
					)
					.then((res) => {
						if (res.message) {
							// in case the message gets taken away or changed to another key
							this.notifications.success(res.message, {
								canClose: true,
								autoClear: true,
								clearDuration: 3000,
							});
						}

						this.set('mergingPhone', false);
						this.set('verificationCode', null);
						this.set('showMergeVerification', false);
						this.userToEdit.reload(); //* reload the user to show the updated number
						next(() => {
							this.close();
						});
					});
			} else return reject('model.id is missing');
		},

		cancelMerge() {
			this.set('verificationCode', null);
			this.set('showMergeVerification', false);
			this.set('mergingPhone', false);
		},
	},
});
