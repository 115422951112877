/** @format */

import Component from '@ember/component';
import { defer } from 'rsvp';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { attorneyDisplay } from '../helpers/attorney-display';
import { paralegalDisplay } from '../helpers/paralegal-display';

export default Component.extend({
	firm: alias('currentUser.user.firm'),
	currentUser: service('current-user'),

	init() {
		this._super(...arguments);

		this.setProperties({
			newUser: {},
		});
	},
	roleOptions: computed('firm', function () {
		const roleOptions = [
			{
				display: attorneyDisplay([this.firm]),
				value: 'attorney', //* Model name
			},
		];
		if (this.get('firm.isLawFirm')) {
			roleOptions.push({
				display: paralegalDisplay([this.firm]),
				value: 'paralegal', //* Model Name
			});
		}
		return roleOptions;
	}),

	actions: {
		selectNewUserRole(role) {
			this.set('selectedRole', role);
			this.set('newUser.role', role.display);
			this.set('newUser.model', role.value);
		},

		inviteNewUser() {
			const user = this.newUser;
			const result = defer();

			// Reset error states
			this.set('showNewUserFirstNameError', false);
			this.set('showNewUserLastNameError', false);
			this.set('showNewUserEmailError', false);
			this.set('showNewUserRoleError', false);

			// Check to make sure all fields are completed
			// If any fields are null, show inline errors on that field
			if (!user.firstName) {
				this.set('showNewUserFirstNameError', true);
				this.set('newUserFirstNameError', 'Enter a first name, please');
			}
			if (!user.lastName) {
				this.set('showNewUserLastNameError', true);
				this.set('newUserLastNameError', 'Enter a last name, please');
			}
			if (!user.emailAddress) {
				this.set('showNewUserEmailError', true);
				this.set('newUserEmailError', 'Enter an email, please');
			}
			if (!user.role && !this.noRole) {
				this.set('showNewUserRoleError', true);
				this.set('newUserRoleError', 'Select a role, please');
			} else if (!user.role && this.noRole) {
				user.role = this.newUserRole;
				user.model = this.newUserRole;
			}

			// If all fields are good, lets send that action!
			if (
				this.sendNewUserInvite &&
				user.firstName &&
				user.lastName &&
				user.emailAddress &&
				user.role &&
				user.model
			) {
				this.sendNewUserInvite(user, result);
			}
			return result.promise;
		},

		sendNewInvite() {
			this.set('newUser', {});
			this.set('inviteSentSuccess', false);
		},
	},
});
