/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { sort, alias } from '@ember/object/computed';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { paralegalDisplay } from '../helpers/paralegal-display';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import EmberObject from '@ember/object';
import { userDisplay } from 'case-status/helpers/user-display';

function clone(objToClone = {}) {
	//* This is a helper function used to cover the shortcomings of ember-changeset
	//* since a changeset still reflects changes to the original data model used,
	//* and you can't have multiple changesets of the same original data model

	//* We need an isolated cloned version of the userType's data but don't want any
	//* set properties, such as isSelected, to persist in the data store since that
	//* is reflected across the entire app, but is only relevant while a list is being
	//* displayed, this makes this a ver niche need for a function such as this,
	//* especially since the attributes specifically being cloned are very specific to
	//* the user data model and its children data models.
	if (!objToClone || typeof objToClone != 'object' || Array.isArray(objToClone))
		throw new Error('cloneObj must be passed an object');

	const cloner = {};
	cloner.id = objToClone.id;
	cloner.lastName = objToClone.lastName;
	cloner.firstName = objToClone.firstName;
	cloner.name = objToClone.name;
	cloner.emailAddress = objToClone.emailAddress;

	return EmberObject.create(Object.assign({}, cloner));
}

@classic
export default class ModalReplaceParalegalsComponent extends Component {
	//* Static Settings
	lastNameSort = ['lastName'];

	//* Services

	@service company;
	@service currentUser;

	//* Aliases
	@alias('company.info') firm;

	//* Tracked Properties

	@tracked searchString1;
	@tracked searchString2;
	@tracked paralegalsToRemove = A([]);
	@tracked isLoading = true;
	@tracked model = A([]);

	//* Sorted Computed Properties

	@sort('paralegalsToRemove', 'lastNameSort') sortedRemoveParalegals;
	@sort('filteredRemoveParalegals', 'lastNameSort') sortedToRemoveParalegals;
	@sort('filteredAddParalegals', 'lastNameSort') sortedToAddParalegals;

	//* Computed Properties

	get searchText1Valid() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check
		const searchText = this.searchString1;
		return (
			searchText &&
			typeof searchText == 'string' &&
			searchText !== '' &&
			searchText.trim() !== ''
		);
	}

	get searchText2Valid() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check
		const searchText = this.searchString2;
		return (
			searchText &&
			typeof searchText == 'string' &&
			searchText !== '' &&
			searchText.trim() !== ''
		);
	}

	get filteredRemoveParalegals() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check

		const paralegals = this.filteredModel;
		const selectedParalegalId = this.paralegalIdToRemove;

		//* Skip the rest of any computations and just return an empty array since the mode is empty
		if (!paralegals) {
			return A([]);
		}

		const searchText = this.searchString1;
		const searchTextValid = this.searchText1Valid;

		return this.filterSelectedParalegals({
			paralegals,
			searchText,
			searchTextValid,
			selectedParalegalId,
		});
	}

	get filteredAddParalegals() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check

		const paralegals = this.filteredModel;
		const selectedParalegalId = this.paralegalIdToAdd;

		//* Skip the rest of any computations and just return an empty array since the mode is empty
		if (!paralegals) {
			return A([]);
		}

		const searchText = this.searchString2;
		const searchTextValid = this.searchText2Valid;

		return this.filterSelectedParalegals({
			paralegals,
			searchText,
			searchTextValid,
			selectedParalegalId,
		});
	}

	get filteredModel() {
		if (!this.model) return A([]);
		return A(userDisplay([this.model]));
	}

	//* Using named params
	filterSelectedParalegals({
		paralegals,
		searchText,
		searchTextValid,
		selectedParalegalId,
	}) {
		if (searchTextValid) {
			return paralegals
				.map((paralegalRaw) => {
					const paralegal = clone(paralegalRaw);
					if (selectedParalegalId == paralegal.get('id')) {
						paralegal.set('isSelected', true);
					} else if (selectedParalegalId) {
						paralegal.set('isSelected', false);
					} else {
						paralegal.set('isSelected', false);
					}
					return paralegal;
				})
				.filter((paralegal) => {
					if (selectedParalegalId == paralegal.get('id')) {
						return true;
					} else if (selectedParalegalId) {
						return false;
					} else {
						return true;
					}
				})
				.filter((paralegal) => {
					return paralegal
						.get('name')
						.toLowerCase()
						.includes(searchText.toLowerCase());
				});
		} else {
			return paralegals
				.map((paralegalRaw) => {
					const paralegal = clone(paralegalRaw);
					if (selectedParalegalId == paralegal.get('id')) {
						paralegal.set('isSelected', true);
					} else if (selectedParalegalId) {
						paralegal.set('isSelected', false);
					} else {
						paralegal.set('isSelected', false);
					}
					return paralegal;
				})
				.filter((paralegal) => {
					if (selectedParalegalId == paralegal.get('id')) {
						return true;
					} else if (selectedParalegalId) {
						return false;
					} else {
						return true;
					}
				});
		}
	}

	//* Lifecycle Hooks

	init() {
		super.init(...arguments);
		this.fetchNewData();
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		$('html').addClass('has-modal');
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
		$('html').removeClass('has-modal');
		this.set('paralegalsToRemove', A([]));
		this?.model?.forEach((paralegal) => {
			paralegal?.set('isSelected', false);
		});
	}

	//* Methods

	fetchNewData() {
		this.set('isLoading', true);

		this.store
			.findAll('paralegal')
			.then((paralegals) => {
				//Avoid calling set on destroyed component
				if (this.isDestroyed || this.isDestroying) {
					return;
				}
				this.model.clear();
				this.model.pushObjects(paralegals.toArray());

				this.set('isLoading', false);
			})
			.catch((err) => {
				this.set('errors', Errors.mapResponseErrors(err));
				next(() => {
					this.set('isLoading', false);
				});
			});
	}

	get titleText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const type = paralegalDisplay([this.company]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Replace ${type} on (${caseCountText}) ${cases}`;
	}

	get actionableBtnText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const type = paralegalDisplay([this.company]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Replace ${type} on (${caseCountText}) ${cases}`;
	}

	updateParalegalsList() {
		//* This is to trigger the getters to rerender
		const model = A([...this.model]);
		this.set('model', A([]));
		this.set('model', model);
	}

	//* Actions
	@action
	removeParalegalsButtonPressed() {
		const result = defer();
		this.set('errors', null);

		if (!this.paralegalIdToRemove || !this.paralegalIdToAdd) {
			this.set('errors', [
				`At least one ${paralegalDisplay([
					this.currentUser.get('user.firm'),
				]).toLowerCase()} should be selected to remove and At least one ${paralegalDisplay(
					[this.currentUser.get('user.firm')],
				).toLowerCase()} should be selected to add in its place.`,
			]);
			result.reject();
		} else {
			this.replaceParalegalOnCase(
				this.paralegalIdToRemove,
				this.paralegalIdToAdd,
			)
				.then((res) => {
					this.set('paralegalIdToRemove', null);
					this.set('paralegalIdToAdd', null);
					result.resolve(res);
				})
				.catch((resp) => {
					this.set('errors', Errors.mapResponseErrors(resp));
					result.reject(resp);
				});
		}

		return result.promise;
	}

	@action
	onRemoveParalegalToggled(row, isRemovingParalegal) {
		const paralegal = row.get('content');
		if (isRemovingParalegal) {
			this.set('paralegalIdToRemove', paralegal.get('id'));
		} else {
			this.set('paralegalIdToRemove', null);
		}

		this.updateParalegalsList();
	}

	@action
	onAddParalegalToggled(row, isRemovingParalegal) {
		const paralegal = row.get('content');
		if (isRemovingParalegal) {
			this.set('paralegalIdToAdd', paralegal.get('id'));
		} else {
			this.set('paralegalIdToAdd', null);
		}

		this.updateParalegalsList();
	}
}
