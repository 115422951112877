/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import ENV from 'case-status/config/environment';

export default Controller.extend({
	company: service(),
	ajax: service(),
	notifications: service(),

	showReceiptModal: false,

	page: 1,
	meta: null,
	queryParams: null,

	isLoading: true,

	currentTab: 'Per Case',

	// Search properties
	searchTerm: '',
	searchType: 'caseId',

	// Search Types
	caseId: null,
	clientName: null,
	attorneyName: null,
	filterOptions: computed(function () {
		return ['client', 'attorney', 'caseId'];
	}),

	queryChanged: observer('filters.{}', 'sort', function () {
		debounce(this, this.fetchNewData, 1000);
	}),

	searchTermChanged: observer('searchTerm', function () {
		const searchTerm = this.searchTerm;

		switch (this.searchType) {
			case 'caseId':
				this.set('caseId', searchTerm);
				break;
			case 'client':
				this.set('clientName', searchTerm);
				break;
			case 'attorney':
				this.set('attorneyName', searchTerm);
				break;
		}
	}),

	searchTypeChanged: observer('searchType', function () {
		const searchTerm = this.searchTerm;

		if (searchTerm === '') {
			this.set('caseId', null);
			this.set('clientName', null);
			this.set('attorneyName', null);
		}

		switch (this.searchType) {
			case 'caseId':
				this.set('caseId', searchTerm);
				this.set('clientName', null);
				this.set('attorneyName', null);
				break;
			case 'client':
				this.set('clientName', searchTerm);
				this.set('attorneyName', null);
				this.set('caseId', null);
				break;
			case 'attorney':
				this.set('attorneyName', searchTerm);
				this.set('clientName', null);
				this.set('caseId', null);
				break;
			default:
				this.set('caseId', null);
				this.set('clientName', null);
				this.set('attorneyName', null);
				break;
		}
	}),

	filters: computed('clientName', 'attorneyName', 'caseId', function () {
		let activeFilters = {};

		const availableFilters = {
			'client.name': this.clientName,
			'attorney.name': this.attorneyName,
			readable_id: this.caseId,
		};

		for (let filterProperty in availableFilters) {
			let testValue = availableFilters[filterProperty];

			// Don't want to send useless filters like empty arrays
			if (testValue instanceof Array && testValue.length < 1) {
				testValue = false;
			}

			if (testValue) {
				// Some API endpoints yell at me if the boolean value on certain properties isn't formatted as a string 😢, luckily fetchNewData accepts it as a string or a boolean
				const formattedValue =
					typeof testValue == 'boolean' ? testValue.toString() : testValue;

				activeFilters[filterProperty] = formattedValue;
			}
		}
		return activeFilters;
	}),

	fetchNewData() {
		//* Only run when the model is properly set
		if (!this.model) return false;

		//* Clear the model
		this.model.clear();

		//* Set the isLoading state property to true
		this.set('isLoading', true);

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			page: {},
		};

		//* Set the filter property on the queryParams to our computed filters property
		queryParams.filter = this.filters;

		//* Set the page Object's size property to 25, eventually we should allow them to be able to choose different page sizes
		queryParams.page.size = 25;

		//* Set the sort on the queryParams to our current sortProperty
		queryParams.sort = this.sort;

		//* Set the queryParams
		this.set('queryParams', queryParams);

		//* Query the store for charges using the queryParams
		this.store.query('case-charge', queryParams).then((charges) => {
			//* Clear the model since this is all new data
			// this.model.clear();

			//* Set a constant as the array of the response
			const chargeArray = charges.toArray();

			//* Clear the model again to prevent duplicates
			this.model.clear();

			//* Push the new array's objects into the newly cleared model
			this.model.pushObjects(chargeArray);

			//* Set the meta property using the meta from the response
			this.set('meta', charges.get('meta'));

			//* Reset the page count to 1
			this.set('page', 1);

			//* In the next runloop we will set the isLoading state property to false
			next(() => {
				this.set('isLoading', false);
			});
		});
	},

	actions: {
		fetchMoreCharges() {
			//* Check to make sure there are more pages to fetch and that we are not currently fetching content
			if (this.get('meta.total_pages') > this.page && !this.isLoading) {
				//* Set the isLoading state property to true
				this.set('isLoading', true);

				//* Increment the current page number
				this.incrementProperty('page');

				//* Set a constant for the current queryParams
				const queryParams = this.queryParams;

				//* Update the requested page number on the queryParams
				queryParams.page.number = this.page;

				//* Query the store for charges using our updated queryParams
				this.store.query('case-charge', queryParams).then((charges) => {
					//* Update the meta property using the meta on the response
					this.set('meta', charges.get('meta'));

					//* Set a constant to the array from the response
					const chargeArray = charges.toArray();

					//* Push the charge Objects from the response array into the current model
					this.model.pushObjects(chargeArray);

					//* In the next runloop we will set the isLoading state property to false
					next(() => {
						this.set('isLoading', false);
					});
				});
			}
		},

		async toggleShowReceiptModal(charge) {
			const showReceiptModal = this.showReceiptModal;

			if (showReceiptModal) {
				this.toggleProperty('showReceiptModal');
				this.set('receiptDetails', null);
			} else {
				const chargeCase = await charge.get('case');

				this.toggleProperty('showReceiptModal');
				this.ajax
					.request(`${ENV.host}/invoices/case_invoices/${chargeCase.id}`)
					.then((res) => {
						const receiptDetails = {
							id: `${chargeCase.id}-${chargeCase.readableId}`,
							date: chargeCase.activatedDate,
							total: charge.accruedFees,
							charges: res.data.map((invoice) => {
								invoice.attributes.amount =
									invoice.attributes.amount.toFixed(2);
								return invoice.attributes;
							}),
						};

						this.set('receiptDetails', receiptDetails);
					});
			}
		},
	},
});
