/** @format */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class IframeTopNavBarComponent extends Component {
	elementId = 'iframe-top-nav-bar';
	classNames = ['row middle-xs'];
	@service mobile;
	@service router;
	@service currentUser;
	@service global;
	@service customBranding;
	@service thirdParty;

	@tracked showUserProfile = false;

	constructor() {
		super(...arguments);
		// gotta be a better way to do this, but we don't want both modal-app-wrapper and
		// iframe-top-nav-bar rendering at the same time
		const wrapper = document.getElementById('modal-app-wrapper');
		if (wrapper) {
			wrapper.style.display = 'none';
		}
	}

	willDestroy() {
		const wrapper = document.getElementById('modal-app-wrapper');
		if (wrapper) {
			wrapper.style.display = 'block';
		}
	}

	get subRoute() {
		return this.currentUser.get('user.constructor.modelName') == 'client'
			? 'client'
			: 'firm';
	}

	get showUpdateInfoBtn() {
		if (this.router.currentRouteName === 'app.client.case.info') {
			return true;
		}

		return false;
	}

	@action
	backToCaseList() {
		// remove reference to the integrationId otherwise it will redirect back to firm.case.messages
		this.thirdParty.integrationId = null;
		this.router.transitionTo('app');
	}

	@action
	toggleShowUserProfile() {
		this.toggleProperty('showUserProfile');
	}

	@action setWantsDesktop() {
		this.mobile.setWantsDesktop();
	}

	@action logout() {
		this.currentUser.logout();
	}

	@action requestUpdateInformation() {
		if (this.global?.requestUpdateInformation()) {
			this.set('showUserProfile', false);
		}
	}
}
