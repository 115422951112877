/** @format */

import Component from '@ember/component';
import { defer } from 'rsvp';

export default Component.extend({
	isEditing: false,

	actions: {
		showEditMode() {
			this.set('errors', null);
			this.set('success', null);
			this.set('isEditing', true);
		},
		hideEditMode() {
			this.set('errors', null);
			this.set('success', null);
			this.set('isEditing', false);
		},

		saveAction(status) {
			this.set('errors', null);
			this.set('success', null);

			const result = defer();
			this.saveAction(status, result);

			return result.promise
				.then(() => {
					this.set('success', 'Case status updated');
					this.set('isEditing', false);
				})
				.catch((errors) => {
					this.set('errors', errors);
				});
		},
	},
});
