/** @format */

import ApplicationAdapter from './application';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';

export default ApplicationAdapter.extend({
	urlForQueryRecord(slug = {}) {
		const queryString = formatObjectAsSearchParams(slug);
		return `/firm_settings/dynamic?${queryString}`;
	},
});
