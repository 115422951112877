/** @format */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ModalUserCreationStep2Component extends Component {
	@service permissions;

	constructor() {
		super(...arguments);

		this.sortedPermissions = this.args.extras.sortedPermissions;
	}

	@tracked errors = null;
	@tracked stagedProfilePic;
	@tracked sortedPermissions;

	@action stageProfilePicture(file) {
		this.stagedProfilePic = file;
		this.args.model.stagedProfilePic = file;
	}

	@action selectedUserPermissions(newPermissionSet) {
		this.args.model.set('userPermissions', newPermissionSet);
	}
}
