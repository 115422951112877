/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';

@classic
export default class PaginatedScrollTriggerComponent extends Component {
	@service inViewport;
	@tracked loaderInViewPort = false;
	@tracked isMax = false;
	@tracked isLoading = false;
	scrollableAreaOverride;

	get showLoader() {
		return this.isLoading && this.loaderInViewPort && !this.isMax;
	}

	didEnterViewPort() {
		this.fetchMore();
	}

	didExitViewPort() {
		this.loaderInViewPort = false;
	}

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and the max
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMore and making it look
		//* like, to the user, that there are no more messages

		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMore();
			}
		}, 500);
	}

	async fetchNew() {
		if (this?.fetchNewAction && typeof this.fetchNewAction == 'function') {
			await this.fetchNewAction();
			next(() => {
				this.checkIfStillInViewPort();
			});
		}
	}

	async fetchMore() {
		if (
			!this.isMax &&
			this?.fetchMoreAction &&
			typeof this.fetchMoreAction == 'function'
		) {
			await this.fetchMoreAction();
			next(() => {
				this.checkIfStillInViewPort();
			});
		}
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		this.setupInViewport();

		this.loaderInViewPort = true;

		// this.fetchNew();
	}

	willDestroy() {
		// need to manage cache yourself if you don't use the mixin
		const loader = document.getElementById('loader');
		this.inViewport.stopWatching(loader);

		super.willDestroy(...arguments);
	}

	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
			scrollableArea: this.scrollableAreaOverride,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	}
}
