/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	addingItem: false,

	showNewForm: computed('checklistItems.length', 'addingItem', function () {
		return this.checklistItems?.length < 1 || this.addingItem;
	}),

	init() {
		this._super(...arguments);

		if (this.checklistItems?.length < 1) {
			this.set('addingItem', true);
		}
	},

	actions: {
		clickAddItem() {
			this.set('addingItem', true);
		},

		cancelAddItem() {
			this.set('addingItem', false);
		},
	},
});
