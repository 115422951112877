/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';

@classic
export default class AutomationTemplateListComponent extends Component {
	classNameBindings = ['wrapperClassNames'];

	@service store;
	@service currentUser;
	@service customBranding;
	@service company;
	@service ajax;
	@service notifications;
	@service permissions;

	@tracked caseType = null;
	@tracked isCreatingNewAutomationTemplate = false;
	@tracked messageTemplates;
	// Because users can edit multiple automations at once, we track how many are being edited
	@tracked activeAutomationEditStates = 0;

	get showApplyAutomationsButton() {
		// Only show retroactive apply button when there is at least 1 case on the PA, and the PA has automation templates to apply, and while not creating or editing an automation template
		return (
			this.caseType.caseCount &&
			this.caseType.automationTemplates.length &&
			!this.isCreatingNewAutomationTemplate &&
			this.activeAutomationEditStates === 0
		);
	}

	get automationsQuotaExceeded() {
		return (
			this.currentUser.isPortalCustomer &&
			this.permissions.firmSettings
				?.automations_per_practice_area_quota_exceeded
		);
	}

	get automations_per_practice_area_count() {
		return this.permissions.firmSettings?.automations_per_practice_area_count;
	}

	updateDynamicSetting(recentWrite) {
		this.permissions.fetchDynamicFirmSettings({
			setting: 'automations_per_practice_area',
			case_type_id: this.caseType.id,
			session: recentWrite ? 'main' : 'replica',
		});
	}

	init() {
		super.init(...arguments);

		this.messageTemplates = this.store.peekAll('message-template');

		if (!this.messageTemplates || !this.messageTemplates.length) {
			//* Only make network request if there are none loaded yet
			this.messageTemplates = this.store.findAll('message-template');
		}
		if (this.currentUser.isPortalCustomer) {
			this.updateDynamicSetting();
		}
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
	}

	willRender() {
		super.willRender(...arguments);
	}

	didRender() {
		super.didRender(...arguments);
	}

	showRetroactivePaaConfirmationDialog = false;

	@action
	createNewAutomationTemplate(
		automationTemplate,
		result = { resolve: () => {}, reject: () => {} },
	) {
		try {
			return automationTemplate
				.save()
				.then((res) => {
					result.resolve(res);
					this.send('toggleIsCreatingNewAutomationTemplate');
					this.updateDynamicSetting(true);
				})
				.catch((err) => {
					result.reject(err);
				});
		} catch (error) {
			return result.reject(error);
		}
	}

	@action
	saveAutomationTemplate(
		automationTemplate,
		result = { resolve: () => {}, reject: () => {} },
	) {
		try {
			return automationTemplate
				.save()
				.then((res) => {
					result.resolve(res);
				})
				.catch((err) => {
					result.reject(err);
				});
		} catch (error) {
			return result.reject(error);
		}
	}

	@action
	async deleteAutomationTemplate(
		automationTemplate,
		result = { resolve: () => {}, reject: () => {} },
	) {
		try {
			//* Save the template first, to make sure the retroactive flag is correct
			await automationTemplate.save();
			return automationTemplate
				.destroyRecord()
				.then((res) => {
					result.resolve(res);
					this.updateDynamicSetting(true);
				})
				.catch((err) => {
					result.reject(err);
				});
		} catch (error) {
			return result.reject(error);
		}
	}

	@action
	toggleIsCreatingNewAutomationTemplate() {
		this.toggleProperty('isCreatingNewAutomationTemplate');
	}

	@action
	toggleShowRetroactivePaaConfirmationDialog() {
		this.toggleProperty('showRetroactivePaaConfirmationDialog');
	}

	@action
	applyPaaRetroactively() {
		const url = `${ENV.host}/case_types/${this.caseType.id}/automation_templates/apply`;
		return this.ajax
			.post(url)
			.then((r) => {
				this.notifications.success(r.message || r, {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});
				this.send('toggleShowRetroactivePaaConfirmationDialog');
			})
			.catch((err) => {
				this.notifications.error(Errors.mapResponseErrors(err), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});
			});
	}

	@action
	setAutomationEditState(bool = null) {
		if (typeof bool != 'boolean') return;

		if (bool) {
			this.incrementProperty('activeAutomationEditStates');
		} else {
			// we don't want this number to go into the negatives for any reason
			if (this.activeAutomationEditStates > 0) {
				this.decrementProperty('activeAutomationEditStates');
			}
		}
	}
}
