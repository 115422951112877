/** @format */

import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { later, cancel } from '@ember/runloop';
import AjaxService from 'ember-ajax/services/ajax';
import {
	isUnauthorizedError,
	isServerError,
	isAbortError,
} from 'ember-ajax/errors';

export default AjaxService.extend({
	session: service(),
	serviceWorker: service('service-worker'),
	host: ENV.host,
	contentType: 'application/vnd.api+json',
	trustedHosts: ['casestatus.com'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	noCache: false,
	cacheBusterValue: Math.floor(new Date().getTime() / 1000),
	headers: computed(
		'session.data.authenticated.{access_token,user_id,user_type}',
		'noCache',
		'cacheBusterValue',
		{
			get() {
				let headers = {
					Accept: 'application/vnd.api.v3+json',
					ChildId: this.get('session.data.authenticated.user_id'),
					ChildType: this.get('session.data.authenticated.user_type'),
				};

				const authToken = this.get('session.data.authenticated.access_token');

				if (authToken) {
					headers['Authorization'] = `Bearer ${authToken}`;
				}

				const noCache = this.get('noCache');
				const cacheBusterValue = this.get('cacheBusterValue');

				if (noCache && cacheBusterValue) {
					headers['Cache-Buster'] = cacheBusterValue;
				}
				return headers;
			},
		},
	),
	handleResponse() {
		const result = this._super(...arguments);

		// Cancel any pending cache buster disabling
		if (this.cacheBusterTimeout) {
			cancel(this.cacheBusterTimeout);
		}

		this.set('cacheBusterValue', Math.floor(new Date().getTime() / 1000));

		// Disable Cache Buster after 13 seconds (13 is the default caching time on cloudfront at the moment)
		this.cacheBusterTimeout = later(
			this,
			() => {
				this.set('noCache', false); //* Disable Cache Buster
				this.cacheBusterTimeout = null;
			},
			13000, // 13 seconds
		);

		// If server responds with status > 500 show error message
		if (isServerError(result) || isAbortError(result)) {
			// We don't want to show both
			if (
				!this.serviceWorker.get('serverDown') &&
				!this.serviceWorker.get('internetDown')
			) {
				if (isServerError(result)) {
					this.serviceWorker.set('serverDown', true);
				} else if (isAbortError(result)) {
					this.serviceWorker.set('internetDown', true);
				}
			}
		} else if (isUnauthorizedError(result)) {
			//! Temporary Disable 401 log outs
			// this.session.invalidate();
		} else if (this.isForbiddenError(result)) {
			return {
				// You can customize this object as per your needs
				isForbidden: true,
				message: 'You do not have permission to perform this operation.',
			};
		} else {
			if (ENV.environment != 'test' && this.serviceWorker.get('serverDown')) {
				this.serviceWorker.set('serverDown', false);
			}
			if (ENV.environment != 'test' && this.serviceWorker.get('internetDown')) {
				this.serviceWorker.set('internetDown', false);
			}
			return result;
		}
	},
});
