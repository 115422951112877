/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import Changeset from 'ember-changeset';
import { next, debounce } from '@ember/runloop';
import Errors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ENV from 'case-status/config/environment';
import { all } from 'rsvp';

@classic
export default class ModalEditUser extends Component {
	@service notifications;
	@service ajax;
	@service currentUser;

	@tracked errors = null;
	@tracked user = null;
	@tracked stagedProfilePic;
	@tracked percentComplete;

	state = {};

	get infoUpdateComplete() {
		return this?.state?.infoUpdateComplete || null;
	}
	set infoUpdateComplete(value) {
		this.state.infoUpdateComplete = value;
		this.triggerShowUpdateResults();
	}

	get avatarUpdateComplete() {
		return this?.state?.avatarUpdateComplete || null;
	}
	set avatarUpdateComplete(value) {
		this.state.avatarUpdateComplete = value;
		this.triggerShowUpdateResults();
	}

	get avatarUpdateErrorMessage() {
		const defaultErrorMessage =
			'Error uploading photo. Please try again later.';
		return this?.state?.avatarUpdateErrorMessage || defaultErrorMessage;
	}
	set avatarUpdateErrorMessage(value) {
		this.state.avatarUpdateErrorMessage = value;
	}

	init() {
		super.init(...arguments);
		//? Use Ember Changeset to not edit the data model directly
		this.set('changeset', new Changeset(this.user));
	}

	triggerShowUpdateResults() {
		debounce(this, this.showUpdateResults, 500);
	}

	showUpdateResults() {
		if (
			this.infoUpdateComplete &&
			(!this.stagedProfilePic || this.avatarUpdateComplete)
		) {
			const notifOptions = { canClose: true, autoClear: true };

			if (this.avatarUpdateSuccess) {
				this.notifications.success(
					'Profile Picture successfully updated',
					notifOptions,
				);
			} else if (this.avatarUpdateError) {
				this.notifications.error(this.avatarUpdateErrorMessage, notifOptions);
			}

			if (this.infoUpdateSuccess) {
				this.notifications.success('User successfully updated!', notifOptions);
			} else if (this.infoUpdateError) {
				this.notifications.error(
					'User updating user info. Please try again later.',
					notifOptions,
				);
			}

			this.refreshTable();
			this.send('cancel');
		}
	}

	uploadImage(file) {
		const fd = new FormData();
		//Build file name from the user's name
		const filename = `${this.changeset
			.get('firstName')
			.toLowerCase()}_${this.changeset
			.get('lastName')
			.toLowerCase()}_${this.usersChild.get('user.id')}.png`;
		fd.append('picture', file, filename);
		const url = `${ENV.host}/profile_pictures/${this.usersChild.get(
			'user.id',
		)}`;

		this.percentComplete = 0;
		const self = this;
		return this.ajax
			.post(url, {
				contentType: false,
				data: fd,
				processData: false,
				xhr: function () {
					const jqXHR = window.ActiveXObject
						? new window.ActiveXObject('Microsoft.XMLHTTP')
						: new window.XMLHttpRequest();
					jqXHR.upload.addEventListener(
						'progress',
						function (evt) {
							if (evt.lengthComputable) {
								const percentComplete = Math.round(
									(evt.loaded * 100) / evt.total,
								);
								self.percentComplete = percentComplete;
							}
						},
						false,
					);
					return jqXHR;
				},
			})
			.then(() => {
				this.avatarUpdateSuccess = true;
			})
			.catch((response) => {
				this.avatarUpdateSuccess = false;
				this.avatarUpdateError = true;
				if (response.payload && response.payload.errors) {
					this.profilePictureResult = response.payload.errors[0].detail;
				}
			})
			.finally(() => {
				this.avatarUpdateComplete = true;
				this.percentComplete = null;
			});
	}

	@action stageProfilePicture(file) {
		this.stagedProfilePic = file;
	}

	@action save() {
		const userProfilePictureUpdateRequest = this.stagedProfilePic
			? this.uploadImage(this.stagedProfilePic)
			: null;
		const userInfoUpdateRequest = this.changeset
			.save()
			.then(() => {
				this.infoUpdateSuccess = true;
			})
			.catch((err) => {
				this.infoUpdateSuccess = false;
				this.infoUpdateError = true;
				this.set('errors', Errors.mapResponseErrors(err));
			})
			.finally(() => {
				this.infoUpdateComplete = true;
			});

		const requests = [userInfoUpdateRequest];

		if (userProfilePictureUpdateRequest) {
			requests.push(userProfilePictureUpdateRequest);
		}

		return all(requests);
	}

	@action remove() {
		this.toggleShowRemoveUserModal(this.user);
		next(() => {
			this.close();
		});
	}

	@action cancel() {
		// Clear out the user property (this bubbles up to the controller as well)
		this.user = null;
		this.usersChild = null;
		this.error = null;

		next(() => {
			this.close();
		});
	}

	@action selectedUserPermissions(newPermissionSet) {
		this.changeset.set('userPermissions', newPermissionSet);
	}
}
