/** @format */

import { observer } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import webNotification from 'simple-web-notification';
import $ from 'jquery';
import RSVP from 'rsvp';
import { underscore } from '@ember/string';
import { storageFor } from 'ember-local-storage';
import Errors from 'case-status/constants/errors';
import { captureException } from 'logrocket';
import { defer } from 'rsvp';

export default Route.extend({
	preferences: storageFor('preferences'),
	infinity: service(),
	ajax: service(),
	pubnub: service('pubnub-service'),
	session: service(),
	notifications: service(),
	currentUser: service(),
	company: service(),

	notificationObserver: observer('pubnub.newMessage', function () {
		const newMessage = this.get('pubnub.newMessage');
		const _this = this;
		if (newMessage) {
			const newMessageContent = $(
				'<p>' + newMessage.get('content') + '</p>',
			).text();
			_this.pushNewNotification(newMessage, 'notifications');
			//Add the new notification to the case
			const theCase = this.store.peekRecord('case', newMessage.get('caseId'));
			theCase.get('notifications').pushObject(newMessage);
			webNotification.showNotification(
				'New message from ' + newMessage.get('senderName'),
				{
					body: newMessageContent,
					icon: 'img/favicon.png',
					onClick: function onNotificationClick() {
						_this.transitionTo('app.firm.case.info', newMessage.get('caseId'));
					},
				},
			);
		}
	}).on('init'),

	chatNotificationObserver: observer('pubnub.newChatMessage', function () {
		const newChatMessage = this.get('pubnub.newChatMessage');
		const _this = this;
		if (newChatMessage) {
			const newChatMessageContent = $(
				'<p>' + newChatMessage.get('content') + '</p>',
			).text();
			_this.pushNewNotification(newChatMessage, 'chatNotifications');
			// Add the new chat notification to the case
			const theCase = this.store.peekRecord(
				'case',
				newChatMessage.get('readableId'),
			);
			theCase.get('chatNotifications').pushObject(newChatMessage);

			webNotification.showNotification(
				'New chat message from ' + newChatMessage.get('senderName'),
				{
					body: newChatMessageContent,
					icon: 'img/favicon.png',
					onClick: function onNotificationClick() {
						//TODO: How do we navigate to the chat on the case?
						_this.store
							.findRecord('chat', newChatMessage.chatId)
							.then((chat) => {
								_this.transitionTo('app.firm.case.collaboration', chat.caseId);
							});
					},
				},
			);
		}
	}).on('init'),

	pushNewNotification(notification, type) {
		this.infinity.pushObjects(this.controller.get(`model.${type}`), [
			notification,
		]);
	},

	model() {
		const notifications = this.getNotificationInfinityModel();
		const chatNotifications = this.getChatNotificationInfinityModel();

		return RSVP.hash({
			notifications,
			chatNotifications,
		});
	},

	getNotificationInfinityModel() {
		return this.infinity.model('notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});
	},

	getChatNotificationInfinityModel() {
		return this.infinity.model('chat-notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});
	},

	markNotificationsAsRead(type, filter, result = defer()) {
		let url = `${ENV.host}/${underscore(type)}/mark_as_read${
			filter ? '?' + filter + '=True' : ''
		}`;

		return this.ajax.post(url).then(() => {
			let model = this.controller.model;
			if (type === 'notifications') {
				model = model.notifications;
			} else {
				model = model.chatNotifications;
			}
			let unreadNotifications = model.filterBy('readAt', null);
			unreadNotifications.forEach((notification) => {
				if (filter) {
					if (filter == 'internal') {
						// check for internal on chat
						const chatId = notification.get('chatId');
						const chat = this.store.peekRecord('chat', chatId);

						if (chat && chat.get('isInternal')) {
							notification.set('readAt', new Date());
						} else if (!chat) {
							this.store.findRecord('chat', chatId).then((chat) => {
								if (chat.get('isInternal')) {
									notification.set('readAt', new Date());
								}
							});
						}
					} else if (filter == 'collaboration') {
						// check that chat is not internal
						const chatId = notification.get('chatId');
						const chat = this.store.peekRecord('chat', chatId);

						if (chat && !chat.get('isInternal')) {
							notification.set('readAt', new Date());
						} else if (!chat) {
							this.store.findRecord('chat', chatId).then((chat) => {
								if (!chat.get('isInternal')) {
									notification.set('readAt', new Date());
								}
							});
						}
					}
				} else {
					notification.set('readAt', new Date());
				}
			});

			if (type === 'notifications') {
				//Also update all the cases newMessages to 0
				let cases = this.store.peekAll('case');
				cases.forEach((c) => {
					if (c.get('newMessages') > 0) {
						c.set('newMessages', 0);
					}
				});
			} else {
				// Update the notifications on every chat
				let chats = this.store.peekAll('chat');
				chats.map((chat) => chat.calculateUnreads());

				//Also update all the cases newChatMessages to 0
				let cases = this.store.peekAll('case');
				cases.forEach((c) => {
					if (c.get('newChatMessages') > 0) {
						c.set('newChatMessages', 0);
					}
					if (c.get('newInternalMessages') > 0) {
						c.set('newInternalMessages', 0);
					}
				});
			}
			result.resolve();
		});
	},

	actions: {
		markAllNotificationsAsRead(result) {
			const notificationsPromise =
				this.markNotificationsAsRead('notifications');
			const chatNotificationsPromise =
				this.markNotificationsAsRead('chatNotifications');

			return RSVP.all([notificationsPromise, chatNotificationsPromise])
				.then(() => {
					result.resolve();
				})
				.catch((response) => {
					this.notifications.error(
						'Unable to mark all notifications as read. Please try again later',
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
					result.reject(response);
				});
		},
		markFilteredNotificationsAsRead(filter, result = defer()) {
			try {
				switch (String(filter).toLowerCase()) {
					case 'all':
						this.send('markAllNotificationsAsRead', result);
						break;
					case 'unread':
						this.send('markAllNotificationsAsRead', result);
						break;
					case 'internal':
						this.markNotificationsAsRead(
							'chatNotifications',
							'internal',
							result,
						);
						break;
					case 'client':
						this.markNotificationsAsRead('notifications', null, result);
						break;
					case 'collaboration':
						this.markNotificationsAsRead(
							'chatNotifications',
							'collaboration',
							result,
						);
						break;
				}
			} catch (error) {
				this.notifications.error(Errors.mapResponseErrors(error), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});

				//* Send an "error" message to logRocket to note unintended behavior
				captureException(
					'ERROR: Received unexpected error when trying to mark filtered notifications as read.',
					{
						tags: {
							action: 'markFilteredNotificationsAsRead',
						},
						extra: {
							currentChildID: this?.currentUser?.user?.id,
							firmId: this?.company?.info?.id,
							targetUserType: this?.currentUser?.type,
							file: 'case-status/routes/app/firm.js',
							isCaughtError: true,
						},
					},
				);
			}
		},
	},
});
