/** @format */

import { helper } from '@ember/component/helper';
import moment from 'moment';
import EmberError from '@ember/error';

export function dateDisplay(params) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'date-display helper requires date and firm passed in as an array',
		);
	}
	const [value, firm, showTime, justTime] = params;
	let formattedDate;
	let format = 'MM/DD/YYYY';
	let timeFormat = 'h:mm a';

	if (firm && firm.get('isEu')) {
		format = 'DD-MM-YYYY';
		timeFormat = `HH:mm`;
	}

	if (showTime) {
		format = `${format} | ${timeFormat}`;
	}

	if (justTime) {
		format = `${timeFormat}`;
	}

	formattedDate = moment(value).format(format);

	if (formattedDate == 'Invalid date') {
		//* Safari seems to return "invalid date" when you don't tell Moment the incoming format of the date (2nd param)
		formattedDate = moment(value, 'MM/DD/YYYY').format(format);
	}

	return formattedDate;
}

export default helper(dateDisplay);
