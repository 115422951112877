/** @format */

import Component from '@ember/component';

export default Component.extend({
	actions: {
		close() {
			this.close();
		},
		shouldRequestReview() {
			this.shouldRequestReview();
		},
	},
});
