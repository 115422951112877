/** @format */

import Controller from '@ember/controller';

export default Controller.extend({
	currentTab: 'firm',

	actions: {
		selectTab(tab) {
			const currentTab = this.currentTab;

			if (!tab || currentTab == tab) return false;

			this.set('currentTab', tab);
		},
	},
});
