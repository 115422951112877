/** @format */

import { helper } from '@ember/component/helper';

function bytesToMB(bytes) {
	return bytes / 1000000;
}

/**
 * If fileBytes is greater than maxUploadFileMB, return an error message; otherwise, return null.
 * If optional notificationService is provided, it will be used to display the error message.
 */
export function createErrorIfFileTooBig([
	fileBytes,
	maxUploadFileMB,
	notificationService,
]) {
	var errorMsg = null;
	const fileMB = bytesToMB(fileBytes);

	if (fileMB > maxUploadFileMB) {
		errorMsg = `The file size (${fileMB} MB) is larger than the max file size allowed by the firm. Please upload a file less than ${maxUploadFileMB} MB.`;

		// if a notificationService was given, use that to show an error message
		if (notificationService != undefined) {
			notificationService.error(errorMsg, {
				canClose: true,
				autoClear: true,
				clearDuration: 10000,
			});
		}
	}

	return errorMsg;
}

export default helper(createErrorIfFileTooBig);
