/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject } from '@ember/service';

export default Component.extend({
	customBranding: inject('custom-branding'),
	company: inject(),

	acknowledgeRemoveUser: false,
	clientDeleteConfirmationValue: null,
	clientDeleteRequiredValue: computed('model', function () {
		return this.model.get('name');
	}),
	clientDeleteValid: computed(
		'clientDeleteConfirmationValue',
		'acknowledgeRemoveUser',
		function () {
			return (
				this.clientDeleteConfirmationValue === this.clientDeleteRequiredValue &&
				this.acknowledgeRemoveUser
			);
		},
	),

	actions: {
		handlePhoneInputError(message) {
			if (message) {
				this.set('errors', [message]);
			} else {
				this.set('errors', []);
			}
		},
		toggleAcknowledgeRemoveUser() {
			this.toggleProperty('acknowledgeRemoveUser');
		},
		save() {
			if (this.clientDeleteValid) {
				const client = this.model;
				return this.save(client);
			}
		},
	},
});
