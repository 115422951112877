/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import ENV from 'case-status/config/environment';
import { pluralize } from 'ember-inflector';
import Errors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

@classic
export default class UsersResendInviteCell extends Component {
	attributeBindings = ['data-test-id'];
	'data-test-id' = 'component';

	@service notifications;
	@service ajax;

	@action async resendInvite(user) {
		const userId = user?.childId;
		const url = `${ENV.host}/${pluralize(
			user?.type?.toLowerCase(),
		)}/${userId}/resend_invite`;

		try {
			await this.ajax.post(url);
			user.set('inviteSent', true);
			this.notifications.success('Invite has been sent!', { autoClear: true });
		} catch (err) {
			user.set('inviteSent', false);
			this.notifications.error(Errors.mapResponseErrors(err), {
				autoClear: true,
			});
		}
	}
}
