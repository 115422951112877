/** @format */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class RestoreRoute extends Route {
	@service('login') loginService;
	@service router;
	@service session;
	@service thirdParty;
	@storageFor('preferences') preferences;

	queryParams = {
		access_token: {},
		refresh_token: {},
		user_id: {},
		user_type: {},
		integrationType: {},
	};

	async model(params) {
		const token = {
			access_token: params.access_token,
			expires_in: 21600,
			token_type: 'Bearer',
			scope: 'all professionals users',
			refresh_token: params.refresh_token,
			user_id: Number(params.user_id),
			user_type: params.user_type,
		};
		this.session.authenticate('authenticator:oauth2', token).then(() => {
			set(this, 'preferences.selectedAccountType', token.user_type);
			set(this, 'preferences.selectedAccount', token.user_id);
			set(this, 'thirdParty.integrationType', params.integrationType);
			this.loginService.login();
		});
	}
}
