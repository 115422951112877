/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { htmlSafe } from '@ember/template';

//* Export the named function separately so that it can be imported in controllers,
//* components, routes, services, etc to be used as may be needed.
//* MUST be imported as a named import only, as the default export will solely be a
//* template helper
export function parseLinks([text]) {
	//* No text, just return that same nothing
	if (!text) return text;

	//* text param must be a string else the string
	//* prototype functions used with throw errors
	if (typeof text !== 'string') {
		//* Throw an
		throw new EmberError(
			'parse-links helper requires first param to be a string',
		);
	}

	//* If the string is falsey when trimmed, just send it back
	if (!text.trim()) return text;

	//* Stage a front and e3nd of string
	let front = '';
	let end = '';

	//* Check for an opening div tag and stage it in the front variable
	if (text.search('<div>') == 0) {
		front = '<div>';
		text = text.slice(5);
	}

	//* Check for a closing div tag and stage it in the end variable
	//* If text == 'hello' result in a "-1"
	if (text.length > 5 && text.search('</div>') == text.length - 6) {
		end = '</div>';
		text = text.slice(0, text.length - 6);
	}

	//* Split the string into a array of words to map over on every space
	const textArray = text.split(' ');

	//* linkRegex matches a string that has a minimum of 3 case-insensitive letters
	//* followed by a period, followed by a minimum of 2 case-insensitive letters
	const linkRegex = /[a-z0-9]{3,}\.[a-z]{2,}/gim;

	let inAnchor = false;

	const parsedTextArray = textArray.map((word) => {
		const linkIndex = word.search(linkRegex);

		if (word.includes('</a>')) {
			inAnchor = false;
			return word;
		}
		//* check and see if you are in an anchor element and return until out of the anchor element
		if (inAnchor) return word;
		if (word.includes('<a')) {
			inAnchor = true;
			return word;
		}
		//* If no linkRegex match just return and use that word as is
		if (linkIndex == -1) return word;

		//* avoid hrefs and alts
		if (word.includes('href=') || word.includes('alt=')) {
			return word;
		}

		//* If there is a linkRegex match then check to see if a protocol is already
		//* on the link word, if so then just use the link word as the href and linked text
		//* else add the http protocol to the front of the href used with the link word
		//* and use the word as is for the linked text.
		//? We use http as the default for all as not every site uses https and https not
		//? used does not redirect to http but http can be redirected to https, if set up.
		let href = word.search('http') == 0 ? word : 'http://' + word;
		href = href.replace(/,$/, ''); // regex matches on comma at the end of the string
		const linkedText = word;

		//? Use target="_blank" to open in a new window (so long as the href link has a
		//? protocol, ie. http or https)

		//? Use rel="noopener" to instruct the browser to navigate to the target resource
		//? without granting the new browsing context access to the document that opened it

		//? Use rel=noreferrer to instruct the browser, when navigating to the target
		//? resource, to omit the Referer header and otherwise leak no referrer information

		const link = `<a href="${href}" target="_blank" rel="noopener noreferrer">${linkedText}</a>`;

		//* Flag the newly constructed link as "html safe" to be inserted into the DOM
		//* and return it to be staged in place in the new parsedTextArray
		return htmlSafe(link);
	});

	//* Join the array back together using the same character(s) we split it on - spaces
	const parsedText = parsedTextArray.join(' ');

	//* Add the staged font and end strings to form the finalText, if none were
	//* actively staged, then they should have remained as empty strings which can
	//* still concatenate, but will ultimately make no difference at all as they
	//* were empty.
	const finalText = front + parsedText + end;

	//* return the parsed, mapped, and linked finalText
	return finalText;
}

//* Export the default as a template helper
export default helper(parseLinks);
