/** @format */

import { sort } from '@ember/object/computed';
import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import { A } from '@ember/array';
import Table from 'ember-light-table';
import { dateDisplay } from 'case-status/helpers/date-display';

export default Component.extend({
	sort: '-submittedAt',

	currentUser: service('current-user'),

	requestsSortingBy: A(['id:desc']),
	sortedRequests: sort('requests', 'requestsSortingBy.[]'),

	sortRequests: observer('requestsSortingBy.[]', function () {
		let table = Table.create({
			columns: this.columns,
			rows: this.sortedRequests,
			enableSync: true,
		});
		let sortColumn = table
			.get('allColumns')
			.findBy('valuePath', this.requestsSortingBy[0].split(':')[0]);
		let ascending = true;
		if (this.requestsSortingBy[0].split(':')[1] === 'desc') {
			ascending = false;
		}
		sortColumn.set('ascending', ascending);
		sortColumn.set('sorted', true);
		this.set('table', table);
	}),

	columns: computed(function () {
		return [
			{
				label: 'ID',
				valuePath: 'id',
				width: '70px',
			},
			{
				label: 'Case ID',
				valuePath: 'readableId',
				width: '190px',
			},
			{
				label: 'Submitted',
				valuePath: 'submittedAt',
				width: '190px',
				format: (value) => {
					return dateDisplay([value, this.currentUser.user.organization, true]);
				},
			},
			{
				label: 'Internal Notes',
				valuePath: 'organizationNotes',
				sortable: false,
				format: (value) => {
					return value ? value : 'N/A';
				},
			},
			{
				label: 'Request Notes',
				valuePath: 'requestNotes',
				sortable: false,
				format: (value) => {
					return value ? value : 'N/A';
				},
			},
			{
				label: 'Firm Name',
				valuePath: 'firm.name',
				format: (value) => {
					return value ? value : 'N/A';
				},
			},
			{
				label: 'Requested By',
				valuePath: 'requestedBy',
				format: (value) => {
					return value.name ? value.name : 'N/A';
				},
			},
		];
	}),

	model: null,
	table: null,

	init() {
		this._super(...arguments);
		let table = Table.create({
			columns: this.columns,
			rows: this.sortedRequests,
			enableSync: true,
		});
		let sortColumn = table.get('allColumns').findBy('valuePath', 'submittedAt');
		sortColumn.set('ascending', false);
		sortColumn.set('sorted', true);

		this.set('table', table);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			let scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});
	},

	actions: {
		rowClicked(row) {
			this.toggleShowRequest(row.content);
		},

		onColumnClick(column) {
			if (column.label == 'select') return;

			if (column.sorted) {
				const direction = column.ascending ? 'asc' : 'desc';

				let columnName = column.valuePath;
				this.set('requestsSortingBy', [`${columnName}:${direction}`]);
				this.set('requestsSortingByKey', columnName);
			}
		},
	},
});
