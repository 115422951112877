/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ENV from 'case-status/config/environment';

export default Component.extend({
	thirdParty: service(),
	router: service(),
	host: ENV.host,
	route: null,
	shouldOpenNewWindow: false,

	showLinkImg: computed(
		'thirdParty.shouldRedirect',
		'openInPlace',
		function () {
			if (this.openInPlace) {
				return false;
			} else if (this.thirdParty.shouldRedirect) {
				return true;
			} else {
				return false;
			}
		},
	),

	handleClick() {
		if (!this.route) return false;
		if (this.openInPlace === true) {
			return this.openHere();
		} else if (this.thirdParty.shouldRedirect || this.shouldOpenNewWindow) {
			return this.openNewWindow();
		} else {
			return this.openHere();
		}
	},

	openNewWindow() {
		const route = this.route || '';
		const path = route.replace(/\./g, '/');

		window.open(`${this.host}/${path}`, '');

		return false;
	},

	openHere() {
		const params = [this.route];

		if (this.model) {
			params.push(this.model);
		}

		if (this.options) {
			params.push(this.options);
		}

		this.router.transitionTo(...params);
		return false;
	},
}).reopenClass({
	positionalParams: ['route', 'model', 'options'],
});
