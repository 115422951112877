/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	company: service(),
	// Add / Remove has-modal class to body to lock scrolling behind modal
	didInsertElement() {
		$('html').addClass('has-modal');
	},
	willDestroyElement() {
		$('html').removeClass('has-modal');
	},

	actions: {
		close() {
			this.set('clientNameInput', null);
			this.close();
		},
	},
});
