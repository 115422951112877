/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Errors from 'case-status/constants/errors';
import Changeset from 'ember-changeset';
import { observer } from '@ember/object';
import { next, debounce } from '@ember/runloop';
import { A } from '@ember/array';

export default Controller.extend({
	notifications: service(),
	company: service(),
	size: 50,
	page: {
		//eslint-disable-line ember/avoid-leaking-state-in-ember-objects
		number: 1,
		size: 50,
	},

	checklistTemplates: A([]), //eslint-disable-line ember/avoid-leaking-state-in-ember-objects

	isLoading: true,

	selectedChecklistTemplateObserver: observer(
		'selectedChecklistTemplateOriginal',
		function () {
			//* Use changeset so unsaved changes do not persist across the app
			this.set(
				'selectedChecklistTemplate',
				this.selectedChecklistTemplateOriginal
					? new Changeset(this.selectedChecklistTemplateOriginal)
					: null,
			);
		},
	),

	resetErrors() {
		this.set('errors', null);
		this.set('showErrors', false);
		this.notifications.clearAll();
	},

	fetchChecklistTemplates() {
		this.set('isLoading', true);

		this.set('page', {
			number: 1,
			size: this.size,
		});

		this.checklistTemplates?.clear();

		this.store
			.query('checklistTemplate', { page: this.page })
			.then((response) => {
				this.set('checklistTemplates', response.toArray());
				this.set('meta', response.meta);
				if (this.checklistTemplates.length) {
					this.set(
						'selectedChecklistTemplateOriginal',
						this.checklistTemplates[0],
					);
				}

				next(() => {
					this.callFetchMoreTemplates();
				});
			});
	},

	fetchMoreTemplates() {
		this.set('isLoading', true);
		this.incrementProperty('page.number');

		const queryParams = {
			page: this.page,
		};
		this.store.query('checklist-template', queryParams).then((res) => {
			this.checklistTemplates.pushObjects(res.toArray());
			this.set('meta', res.meta);

			next(() => {
				this.callFetchMoreTemplates();
			});
		});
	},

	callFetchMoreTemplates() {
		if (
			this?.meta?.total_results > this?.checklistTemplates?.length &&
			this?.meta?.total_pages > this.page.number
		) {
			debounce(this, 'fetchMoreTemplates', 1000);
		} else {
			this.set('isLoading', false);
		}
	},

	metaObserver: observer(
		'meta.total_results',
		'checklistTemplates.length',
		function () {
			this.callFetchMoreTemplates();
		},
	).on('init'),

	actions: {
		newChecklistTemplate() {
			this.resetErrors();

			//* Create new Checklist Template local record in the Store
			const newChecklistTemplate = this.store.createRecord(
				'checklist-template',
				{
					title: 'New Checklist Template',
					content: null,
				},
			);

			//* Push the newly created local record to the list of checklist templates to
			//* be displayed in the nav section with the preexisting templates
			this.checklistTemplates.pushObject(newChecklistTemplate);

			//* Set the newly created local record as the selected template
			this.set('selectedChecklistTemplateOriginal', newChecklistTemplate);
		},

		setSelectedChecklistTemplate(checklistTemplate) {
			this.resetErrors();

			//* Null them out and then have it set in the next runloop to reset the items component
			this.set('selectedChecklistTemplateOriginal', null);

			next(() => {
				this.set('selectedChecklistTemplateOriginal', checklistTemplate);
			});
		},

		saveChecklistTemplate() {
			this.resetErrors();

			const selectedChecklistTemplate = this.selectedChecklistTemplate;

			if (
				selectedChecklistTemplate &&
				selectedChecklistTemplate.get('checklistItemTemplates.length') &&
				selectedChecklistTemplate.save &&
				typeof selectedChecklistTemplate.save === 'function'
			) {
				return selectedChecklistTemplate
					.save()
					.then(() => {
						this.notifications.success('Checklist Template has been saved.', {
							autoClear: true,
							clearDuration: 5000,
						});
					})
					.catch((err) => {
						this.notifications.error('Checklist Template was not saved.');
						this.set('errors', Errors.mapResponseErrors(err));
						this.set('showErrors', true);
						selectedChecklistTemplate?.rollbackAttributes();
					});
			}
		},

		confirmDeleteChecklistTemplate() {
			this.set('isDeleting', true);
		},

		cancelDeleteChecklistTemplate() {
			this.set('isDeleting', false);
		},

		deleteChecklistTemplate() {
			const selectedChecklistTemplate = this.selectedChecklistTemplateOriginal;
			this.resetErrors();

			const checklistTemplates = this.checklistTemplates;
			const deletingId = selectedChecklistTemplate.get('id');

			//* Set isDeleting to true to prevent them from click spamming the action
			this.set('isDeleting', true);

			return selectedChecklistTemplate
				.destroyRecord()
				.then(() => {
					//* Set isDeleting to false since it is no longer deleting
					this.set('isDeleting', false);

					const newChecklistTemplatesArray = checklistTemplates.filter(
						(template) => {
							return template.id !== deletingId;
						},
					);

					this.set('checklistTemplates', newChecklistTemplatesArray);

					//* Set the selectedChecklistTemplate to the first template available in the array
					this.set(
						'selectedChecklistTemplateOriginal',
						this.checklistTemplates[0],
					);
				})
				.catch((err) => {
					this.set('isDeleting', false);
					this.set('errors', Errors.mapResponseErrors(err));
					this.set('showErrors', true);
					this.notifications.error('Checklist Template was not deleted.');
					selectedChecklistTemplate?.rollbackAttributes();
				});
		},

		itemDeleteCallback() {
			if (
				this.selectedChecklistTemplate.get('checklistItemTemplates.length') ===
				0
			) {
				//* If the last item was deleted from the checklist_template, then delete the entire checklist_template
				this.send('deleteChecklistTemplate');
			}
		},
	},
});
