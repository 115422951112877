/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
	currentUser: service('current-user'),

	actions: {
		logout() {
			this.currentUser.logout();
		},
	},
});
