/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default Component.extend({
	currentUser: service(),

	client1: computed('clients', function () {
		return this.clients.toArray ? this.clients.toArray()[0] : null;
	}),

	clientList: computed('clients', function () {
		return this.clients.toArray
			? this.clients
					.toArray()
					.map((c) => c.name)
					.join(', ')
			: null;
	}),

	didRender() {
		this._super(...arguments);
		this.initTypeform();
	},

	initTypeform() {
		const options = {
			container: document.getElementById('typeform-widget'),
			opacity: '50',
			hideFooter: true,
			hideHeaders: true,
			onSubmit: this.onSubmit,
		};
		createWidget(this.formId, options);
	},
});
