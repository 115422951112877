/** @format */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Errors from '../constants/errors';

export default class ESignController extends Controller {
	@service ajax;

	@tracked confirmation = false;
	@tracked error = false;
	@tracked success = false;
	@tracked isLoading = false;
	@tracked documentLoaded = false;
	@tracked documentError = false;
	currentSigner;
	uuid;
	host = ENV.host;

	@action
	focusSign() {
		document.getElementById('e-sign-signature-input').focus();
		document.getElementById('e-sign-signature-input').select();
	}

	@action
	acceptConfirmation() {
		this.confirmation = true;

		setTimeout(() => {
			if (!this.documentLoaded) {
				const iframes = document.querySelectorAll('iframe');
				iframes.forEach((iframe) => {
					if (iframe.src && iframe.src.includes('drive.google.com')) {
						iframe.src += '';
					}
				});
			}
		}, 3500);
	}

	@action
	retrySigning() {
		window.location.reload();
	}

	@action
	loaded() {
		this.documentLoaded = true;
	}

	@action
	confirmSigning(signatureString) {
		this.isLoading = true;

		const data = {
			child_id: this.currentSigner.get('userChild.childId'),
			child_type: this.currentSigner.get('userChild.type'),
			case_id: this.model.caseId,
			signature_input: signatureString,
			prefix: this.currentSigner.get('prefix'),
		};

		const url = `${ENV.host}/files/signed_documents/${this.uuid}`;
		this.ajax
			.patch(url, {
				data,
			})
			.then(() => {
				this.success = true;
				this.isLoading = false;
			})
			.catch((err) => {
				this.error = true;
				this.errorMessage = Errors.mapResponseErrors(err);
				this.isLoading = false;
			});
	}
}
