/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
	caseId: attr('string'),
	readableId: attr('string'),
	createdAt: attr('date'),
	content: attr('string'),
	messageId: attr('string'),
	readAt: attr('date'),
	senderName: attr('string'),
	senderType: attr('string'),
	userId: attr('string'),
	senderChildId: attr('string'),

	message: belongsTo('message'),
	case: belongsTo('case'),
});
