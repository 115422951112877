/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	company: service(),

	stepOneClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 1 ? 'active' : 'inactive';
	}),

	stepTwoClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 2 ? 'active' : 'inactive';
	}),

	stepThreeClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 3 ? 'active' : 'inactive';
	}),

	stepFourClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 4 ? 'active' : 'inactive';
	}),

	stepFiveClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 5 ? 'active' : 'inactive';
	}),

	stepSixClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 6 ? 'active' : 'inactive';
	}),

	stepSevenClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 7 ? 'active' : 'inactive';
	}),

	stepEightClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 8 ? 'active' : 'inactive';
	}),

	stepNineClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 9 ? 'active' : 'inactive';
	}),

	stepTenClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 10 ? 'active' : 'inactive';
	}),

	stepElevenClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 11 ? 'active' : 'inactive';
	}),

	stepTwelveClass: computed('caseStatus.number', function () {
		return this.get('caseStatus.number') >= 12 ? 'active' : 'inactive';
	}),
});
