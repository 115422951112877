/** @format */

import { inject } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
	currentUser: inject('current-user'),
	customBranding: inject('custom-branding'),
	disableInvite: false,

	actions: {
		sendInvite() {
			this.tableActions.onInviteClient(this.get('row.content'));
			this.set('disableInvite', true);
		},
	},
});
