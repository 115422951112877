/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed, observer } from '@ember/object';
import camelize from 'camelize';

export default Model.extend({
	//! Attributes
	chatId: attr('string'),
	formId: attr('string'),
	readableId: attr('string'),
	formResponse: attr('string'),
	organizationNotes: attr('string'),
	requestNotes: attr('string'),

	//! Relationships
	firm: belongsTo('firm'),

	//! Computed Properties
	parsedFormResponse: computed('formResponse', function () {
		const formResponse = this.formResponse;
		const parsedFormResponse = camelize(JSON.parse(formResponse));

		return parsedFormResponse;
	}),

	//* Properties for Requests Table
	submittedAt: computed('parsedFormResponse', function () {
		const parsedFormResponse = this.parsedFormResponse;

		return parsedFormResponse.formResponse.submittedAt;
	}),

	requestedBy: null,

	parsedFormResponseObserver: observer('parsedFormResponse', function () {
		const parsedFormResponse = this.parsedFormResponse;
		const userType = parsedFormResponse.formResponse.hidden.senderType;
		const userId = parsedFormResponse.formResponse.hidden.userId;

		if (parsedFormResponse.formResponse.hidden.senderName) {
			this.set('requestedBy', {
				id: userId,
				type: userType,
				name: parsedFormResponse.formResponse.hidden.senderName,
			});
			return;
		}

		if (userType && userId) {
			this.store
				.findRecord(userType, userId)
				.then((res) => {
					this.set('requestedBy', res);
				})
				.catch(() => {
					this.set('requestedBy', { name: 'N/A' });
				});
		} else {
			this.set('requestedBy', { name: 'N/A' });
		}
	}).on('init'),

	formattedFormResponse: computed('parsedFormResponse', function () {
		const parsedFormResponse = this.parsedFormResponse;

		const title = parsedFormResponse.formResponse.definition.title; // String
		const variables = parsedFormResponse.formResponse.variables; // Array
		const hiddenFields = parsedFormResponse.formResponse.hidden; // Object
		const questions = parsedFormResponse.formResponse.definition.fields; // Array
		const answers = parsedFormResponse.formResponse.answers; // Array

		const formattedFormResponse = {};

		formattedFormResponse.title = title;
		formattedFormResponse.variables = variables;
		formattedFormResponse.hiddenFields = hiddenFields;
		formattedFormResponse.questions = questions.map((question) => {
			question.component = `typeform-fields/${question.type.replace('_', '-')}`;
			question.responses = answers.filter((answer) => {
				return (
					answer.field.id == question.id && answer.field.ref == question.ref
				);
			});
			return question;
		});

		return formattedFormResponse;
	}),

	init() {
		this._super(...arguments);
		this.set('requestedBy', { name: 'N/A' });
	},
});
