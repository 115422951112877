/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import { dynamicCaseLabel } from '../helpers/dynamic-case-label';
import { resetTableLocation } from 'case-status/components/cases-table';

export default Component.extend({
	//* Rendered Component Attributes
	tagName: 'div',
	_defaultClassNames: 'row around-xs filters-container',

	classNameBindings: ['_defaultClassNames'],

	//* Services
	customBranding: service(),
	company: service(),

	//* Static Properties
	availableFilters: A([]),

	//* State Properties
	showPreferencesDropdown: false,
	isMobile: false,

	//* Computed Properties
	activeFilters: computed('availableFilters', function () {
		const availableFilters = this.availableFilters || [];
		const activeFilters = availableFilters.filter((option) => {
			return option.checked || option.state;
		});
		const activeFiltersNameArray = activeFilters.map((filter) => {
			return filter.name || filter.title || filter.label || filter.value;
		});

		return activeFiltersNameArray || [];
	}),

	activeFiltersText: computed('activeFilters', function () {
		const activeFilters = this.activeFilters || [];
		const activeFiltersText = activeFilters.join(', ');

		return (
			activeFiltersText || `All Open ${dynamicCaseLabel([this.company, true])}`
		);
	}),

	//* Actions
	actions: {
		toggleShowPreferencesDropdown() {
			this.set('showPreferencesDropdown', true);
		},

		togglePreferencesDropdown() {
			this.toggleProperty('showPreferencesDropdown');
		},

		togglePreferenceOption(option) {
			this.togglePreferenceOption(option);
			this.toggleProperty('showPreferencesDropdown');
			resetTableLocation();
		},
	},
});
