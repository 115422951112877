/** @format */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import ValidationErrors from '../constants/errors';
import { isArray } from 'case-status/constants/data-types';

const Validations = buildValidations({
	firstName: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'First Name',
	}),
	lastName: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Last Name',
	}),
	cellPhone: validator('length', {
		min: 9,
		message: 'This is not a valid number.',
		disabled() {
			let model = this.model;
			return model.get('cellPhone') == null;
		},
	}),
});

export default Model.extend(Validations, {
	bio: attr('string'),
	contactEmailAddress: attr('string'),
	wantsDailyDigest: attr('boolean'),
	sendAsPrimary: attr('boolean'),

	wantsEmailNotifications: attr('boolean'),
	wantsEmailNotificationTypes: attr(),

	businessNumber: attr('string'), //* Displayed to client (Not usable for login) //? Is it unique in the DB?
	cellPhone: attr('string'), //* Used for login (Not Displayed to client)
	descriptor: attr('string'), //* Basically their job title

	deviceEndpoint: attr('string'), //* Mobile Device
	deviceToken: attr('string'), //* Mobile Device
	deviceType: attr('string'), //* Mobile Device

	emailAddress: attr('string'), //* Used for login (Not Displayed to Client)
	password: attr('string'), //* Used for when updating their password (Not received in response)
	loginVerificationCode: attr(),

	firstName: attr('string'),
	lastName: attr('string'),
	name: attr('string'),
	userLanguage: attr('string'),
	sendInvite: attr('boolean'),
	isSelected: attr('boolean'),

	initials: computed('firstName', 'lastName', function () {
		if (!this.firstName || !this.lastName) return 'N/A';

		return `${this.firstName[0]} ${this.lastName[0]}`;
	}),

	//* Legacy User Typing
	type: computed('constructor.modelName', function () {
		return this.get('constructor.modelName');
	}),

	lastOpened: attr('date'),
	lastInteraction: attr('date'),
	wantsExceptionReporting: attr('boolean'),

	profilePictureContent: attr(), //Used on creation process
	profilePictureExtension: attr('string'), //Used on creation process

	//* Calendar Integration Settings
	isGoogleOauthSet: attr('boolean'),
	isGoogleCalendarSet: attr('boolean'),
	googleCalendarId: attr('string'),
	wantsIcsInvites: attr('boolean'),
	calendarType: attr('string'),
	calendar: computed('wantsIcsInvites', 'isGoogleCalendarSet', 'calendarType', {
		get() {
			switch (this.calendarType) {
				case 'ics':
					return 'Outlook Calendar';
				case 'gcal':
					return 'Google Calendar';
				default:
					return 'None';
			}
		},
		set(key, value) {
			switch (value) {
				case 'Outlook Calendar':
					this.set('wantsIcsInvites', true);
					this.set('calendarType', 'ics');
					return value;
				case 'Google Calendar':
					this.set('wantsIcsInvites', false);
					this.set('calendarType', 'gcal');
					return value;
				default:
					this.set('wantsIcsInvites', false);
					this.set('isGoogleCalendarSet', false);
					this.set('calendarType', null);
					return value;
			}
		},
	}),

	//* Legacy User Settings
	columnsVisible: attr(),
	preferences: attr(),

	//* Parent User ID (Different from the [SubUser] ID on this model)
	userId: attr('number'),

	firm: belongsTo('firm', { async: false }),
	profilePicture: belongsTo('profile-picture', { async: true }),

	//* A dynamic property that will return whether an attorney or paralegal has notifications off for the case that they are being returned on.
	notificationsOffForCase: attr('boolean'),

	//* The setting that defines whether we are to have notifications off for that attorney/paralegal for future cases
	caseNotificationsOff: attr('boolean'),

	userPermissions: belongsTo('user-permission'),
	userChildren: hasMany('users-child'),
	userChild: belongsTo('user-child'),
});
