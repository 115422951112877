/** @format */

import { isArray } from '@ember/array';
import BaseValidator from 'ember-cp-validations/validators/base';

const ArrayLength = BaseValidator.extend({
	validate(value, options) {
		if (isArray(value)) {
			if (options.min && value.length >= options.min) {
				return true;
			}
			if (options.max && value.length <= options.max) {
				return true;
			}
		}
		return options.message || 'array length';
	},
});

export default ArrayLength;
