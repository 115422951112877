/** @format */

import ApplicationAdapter from './application';
import ENV from 'case-status/config/environment';

export default ApplicationAdapter.extend({
	host: ENV.host,
	urlForQuery(query) {
		if (query.case_id) {
			return `${this.host}/cases/${query.case_id}/medical_providers`;
		}
	},
});
