/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';

export default Component.extend({
	attributeBindings: ['id', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'typeform-number row col-xs-4 margin-bottom',
	id: alias('model.id'),
	'data-test-id': 'component',
});
