/** @format */

import Controller from '@ember/controller';

export default Controller.extend({
	showAddCaseModal: false,

	actions: {
		toggleAddCaseModal() {
			this.toggleProperty('showAddCaseModal');
		},
	},
});
