/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
	content: attr('string'),
	type: attr('string'),

	createdAt: attr('date'),
	updatedAt: attr('date'),
	position: attr('number'),

	checklistTemplate: belongsTo('checklistTemplate'),
});
