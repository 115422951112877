/** @format */

import $ from 'jquery';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import Errors from 'case-status/constants/errors';
import { computed, observer } from '@ember/object';
import Changeset from 'ember-changeset';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import ENV from 'case-status/config/environment';
import { A } from '@ember/array';
import { next, debounce } from '@ember/runloop';
import { MESSAGE_TEMPLATE_CONSTANTS } from 'case-status/constants/message-templates';

export default Controller.extend({
	notifications: service(),
	company: service(),
	shortcode: service(),
	permissions: service(),
	currentUser: service(),
	ajax: service(),
	scroller: service(),
	defaultTruncatedTitleMaxLength: 35,

	rawSelectedMessageTemplate: null,
	selectedMessageTemplate: null,

	acceptFileTypes: '.pdf,.png,.jpg,.jpeg,.mov,.mp4',

	isPersonalTemplate: computed(
		'selectedMessageTemplate.{typeAccess,isSystem}',
		'shortcode.options',
		{
			get() {
				const typeAccess =
					this?.selectedMessageTemplate?.get('typeAccess') || '';

				if (this.selectedMessageTemplate?.get('isSystem')) return false;

				switch (typeAccess) {
					case 'user':
						return true;
					default:
						return false;
				}
			},

			set(key, value) {
				const typeAccess =
					this?.selectedMessageTemplate?.get('typeAccess') || '';

				if (typeAccess == 'user' && value === false) {
					this?.selectedMessageTemplate?.set('typeAccess', 'firm');
				} else if (typeAccess == 'firm' && value === true) {
					this?.selectedMessageTemplate?.set('typeAccess', 'user');
				}

				return this.isPersonalTemplate;
			},
		},
	),

	templateTypes: computed(function () {
		return [
			{ display: 'General Message Template', value: null },
			{ display: 'Review Message Template', value: 'review' },
		];
	}),

	selectedMessageTemplateType: computed(
		'selectedMessageTemplate.templateType',
		'templateTypes',
		function () {
			return this.templateTypes.find(
				(type) =>
					type.value == this.selectedMessageTemplate.get('templateType'),
			);
		},
	),

	isFirmTemplate: computed('selectedMessageTemplate.{typeAccess,isSystem}', {
		get() {
			const typeAccess = this?.selectedMessageTemplate?.get('typeAccess') || '';

			if (this.selectedMessageTemplate?.get('isSystem')) return false;

			switch (typeAccess) {
				case 'firm':
					return true;
				default:
					return false;
			}
		},

		set(key, value) {
			const typeAccess = this?.selectedMessageTemplate?.get('typeAccess') || '';

			if (typeAccess == 'firm' && value === false) {
				this?.selectedMessageTemplate?.set('typeAccess', 'user');
			} else if (typeAccess == 'user' && value === true) {
				this?.selectedMessageTemplate?.set('typeAccess', 'firm');
			}

			return this.isFirmTemplate;
		},
	}),

	hasEditPermission: computed(
		'isPersonalTemplate',
		'selectedMessageTemplate.{isSystem,createdBy.id}',
		'currentUser.user.id',
		'permissions.user.{update_system_message_templates,update_firm_message_templates}',
		function () {
			const isPersonal = this.isPersonalTemplate;
			const isOwner =
				this.selectedMessageTemplate?.get('createdBy.id') ==
				this.currentUser.get('user.id');
			if (isPersonal || isOwner) return true;

			const isSystem = this.selectedMessageTemplate?.get('isSystem');
			const hasEditPermission = isSystem
				? this.permissions.user.update_system_message_templates
				: this.permissions.user.update_firm_message_templates;

			if (hasEditPermission) return true;

			return false;
		},
	),

	hasDeletePermission: computed(
		'isPersonalTemplate',
		'selectedMessageTemplate.{isSystem,createdBy.id}',
		'permissions.user.{delete_system_message_templates,delete_firm_message_templates}',
		'currentUser.user.id',
		function () {
			const isPersonal = this.isPersonalTemplate;
			const isOwner =
				this.selectedMessageTemplate?.get('createdBy.id') ==
				this.currentUser.get('user.id');
			const isSystem = this.selectedMessageTemplate?.get('isSystem');
			const hasDeletePermission = isSystem
				? this.permissions.user.delete_system_message_templates
				: this.permissions.user.delete_firm_message_templates;

			if (isSystem) return false;
			if (isPersonal || isOwner) return true;
			if (hasDeletePermission) return true;

			return false;
		},
	),

	templateHelperText: computed(
		'selectedMessageTemplate.templateType',
		'company',
		function () {
			//* Leave the initial helperText string blank in case it is returned when it shouldn't be
			let helperText = '';

			const templateType = this.selectedMessageTemplate?.get('templateType');

			//* Break out with a null return value if there is no templateType
			if (!templateType) return null;

			//* Known templateTypes that require helper text
			const templateTypesRequiringHelperText = [
				'invite',
				'review',
				'treatment_followup',
				'appointment',
				'upcoming_appointment',
				'status_update',
				'status_changed',
				'case_hold',
			];

			//* Check to see if the templateType is a known type to need helperText
			const requiresHelperText =
				templateTypesRequiringHelperText.includes(templateType);

			//* Set the initial state of the helperText string if the templateType is a known type to need helperText, otherwise return null value
			if (requiresHelperText) {
				helperText =
					'This message will be sent when the following action occurs: ';
			} else {
				return null;
			}

			//* Use the dynamicCaseLabel helper to set the caseLabel to be used, defaults to "case" if something goes wrong or is missing.
			const caseLabel = dynamicCaseLabel([this.company]);

			switch (templateType) {
				case 'invite':
					//* This is basically only when creating a case and selecting "Invite Client"
					helperText += `Client invite is requested`;
					break;

				case 'review':
					//* This is when they click the "Request Review" in the message actions modal on a case.
					//* (Might have been changed to just autofill message box when clicked)
					helperText += `Review is requested`;
					break;

				case 'treatment_followup':
					//? Isn't it a set amount of time after?
					helperText += `After a scheduled medical appointment`;
					break;

				case 'appointment':
					//? Isn't it a set amount of time before?
					helperText += `24 hours before an upcoming appointment`;
					break;

				case 'upcoming_appointment':
					helperText += `A future appointment has been created`;
					break;

				case 'status_update':
					//* Both incremented and decremented status updates
					helperText += `${capitalize(
						caseLabel,
					)} is closed, reopened, or taken off hold`;
					break;

				case 'status_changed':
					//? Doesn't this also fire when a case is reopened?
					helperText += `${capitalize(caseLabel)} status has been changed.`;
					break;

				case 'case_hold':
					//* Only when a case is put on hold, no message sent when a case comes off of hold (which I feel a client would want imo)
					helperText += `${capitalize(caseLabel)} is put on hold`;
					break;

				default:
					//* Return null value for templateTypes not set here as known to require helperText
					return null;
			}

			//* Test the trimmed helperText to make sure it didn't become blank somehow, if it did just return a null value
			return helperText.trim() ? helperText : null;
		},
	),

	didTypeAccessChange() {
		//* Use this to ascertain if there has been a change in the typeAccess to decide if a remap of the mode lists is needed
		return (
			this.selectedMessageTemplate?.get('typeAccess') !==
			this.rawSelectedMessageTemplate?.get('typeAccess')
		);
	},

	init() {
		this._super(...arguments);
		this.set(
			'caseClosedModalTitleNote',
			MESSAGE_TEMPLATE_CONSTANTS.CASE_CLOSED_MODAL_TITLE_NOTE,
		);
		this.set(
			'caseClosedModalDescriptionNote',
			MESSAGE_TEMPLATE_CONSTANTS.CASE_CLOSED_MODAL_DESCRIPTION_NOTE,
		);
		this.set(
			'defaultMessageTemplateNote',
			MESSAGE_TEMPLATE_CONSTANTS.DEFAULT_MESSAGE_TEMPLATE_NOTE,
		);
		this.set(
			'closedTitlePlaceholder',
			MESSAGE_TEMPLATE_CONSTANTS.CLOSED_TITLE_PLACEHOLDER,
		);
		this.set(
			'closedDescriptionPlaceholder',
			MESSAGE_TEMPLATE_CONSTANTS.CLOSED_DESCRIPTION_PLACEHOLDER,
		);
		this.set(
			'defaultPlaceholder',
			MESSAGE_TEMPLATE_CONSTANTS.DEFAULT_PLACEHOLDER,
		);
		if (this.model && this.model?.systemMessages[0]) {
			this.setSelectedMessageTemplate(this.model.systemMessages[0]);
		}
	},

	resetErrors() {
		this.set('errors', null);
		this.set('showErrors', false);
		this.notifications.clearAll();
	},

	remapModel() {
		//* Create a memo list to track the moved object

		//* When changing from Personal template to Firm Template
		const userTemplatesToRemove = [];
		for (let i = 0; i < this.model.userTemplates.length; i++) {
			const workingTemplate = this.model.userTemplates[i];
			if (workingTemplate.isSystem) {
				//* This should never happen, but just in case 🤷🏻‍♂️
				userTemplatesToRemove.push(workingTemplate);
				this.model.systemTemplates.pushObject(workingTemplate);
			} else if (workingTemplate.typeAccess == 'firm') {
				userTemplatesToRemove.push(workingTemplate);
				this.model.firmTemplates.pushObject(workingTemplate);
			}
		}

		//* Be careful using a Array.forEach, as the length grows of the array, the chance of the order not matching increases as well
		userTemplatesToRemove.forEach((dyingTemplate) => {
			this.model.userTemplates.removeObject(dyingTemplate);
		});

		//* Create a memo list to track the moved object
		const firmTemplatesToRemove = [];

		//* When changing from Firm template to Personal Template
		for (let i = 0; i < this.model.firmTemplates.length; i++) {
			const workingTemplate = this.model.firmTemplates[i];
			if (workingTemplate.isSystem) {
				//* This should never happen, but just in case 🤷🏻‍♂️
				firmTemplatesToRemove.push(workingTemplate);
				this.model.systemTemplates.pushObject(workingTemplate);
			} else if (workingTemplate.typeAccess == 'user') {
				firmTemplatesToRemove.push(workingTemplate);
				this.model.userTemplates.pushObject(workingTemplate);
			}

			//* Be careful using a Array.forEach, as the length grows of the array, the chance of the order not matching increases as well
			firmTemplatesToRemove.forEach((dyingTemplate) => {
				this.model.firmTemplates.removeObject(dyingTemplate);
			});
		}
	},

	setSelectedMessageTemplate(template) {
		this.resetErrors();
		this.scroller.scrollVertical('.card-header', { offset: -200 });
		this.set(
			'selectedMessageTemplate',
			template ? new Changeset(template) : null,
		);
		this.set('rawSelectedMessageTemplate', template ? template : null);
	},

	searchString: '',

	queryChanged: observer('searchString', function () {
		debounce(this, this.fetchNewData, 500);
	}),

	fetchNewData() {
		//* Set the isLoading state property to true
		this.set('isLoading', true);

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
		};

		//* Set the filter property on the queryParams to our computed filters property
		if (this.searchString) {
			queryParams.filter.title = this.searchString;
		}

		//* Set the queryParams
		this.set('queryParams', queryParams);

		//* Query the store for cases using the queryParams
		return this.store.query('messageTemplate', queryParams).then((response) => {
			const systemTemplateArray = A([]);
			const firmTemplateArray = A([]);
			const userTemplatesArray = A([]);
			const responseArray = response.toArray();

			for (let i = 0; i < responseArray.length; i++) {
				const workingTemplate = responseArray[i];
				if (workingTemplate.isSystem) {
					systemTemplateArray.pushObject(workingTemplate);
				} else if (workingTemplate.typeAccess == 'firm') {
					//* Firm templates are available for everyone in the firm
					firmTemplateArray.pushObject(workingTemplate);
				} else if (workingTemplate.typeAccess == 'user') {
					//* User templates are only available to that user
					userTemplatesArray.pushObject(workingTemplate);
				}
			}

			const data = {
				systemTemplates: systemTemplateArray,
				firmTemplates: firmTemplateArray,
				userTemplates: userTemplatesArray,
			};

			this.set('model', data);

			next(() => {
				if (responseArray.length) {
					if (systemTemplateArray.length) {
						this.setSelectedMessageTemplate(systemTemplateArray[0]);
					} else if (userTemplatesArray.length) {
						this.setSelectedMessageTemplate(userTemplatesArray[0]);
					} else if (firmTemplateArray.length) {
						this.setSelectedMessageTemplate(firmTemplateArray[0]);
					}
				} else {
					this.setSelectedMessageTemplate(null);
				}

				this.set('isLoading', false);
			});
		});
	},

	deleteFile(templateId) {
		const url = `${ENV.host}/message_templates/${templateId}/files`;
		return this.ajax.delete(url);
	},

	actions: {
		showFilePicker() {
			$('#file_picker').click();
		},

		attachFile(e) {
			const fd = new FormData();
			const file = e.target.files[0];
			fd.append('file', file, file.name);
			this.set('filename', file.name);
			this.set('fd', fd);
			this.set('error', null);
		},

		removeFile() {
			if (this.fd) {
				const fd = this.fd;
				fd.delete('file');
				this.set('filename', null);
				this.set('error', null);
				$('#file_picker').prop('value', '');
				this.set('fd', null);
			} else if (this.selectedMessageTemplate?.get('fileAttachment.id')) {
				this.selectedMessageTemplate.set('fileAttachment', null);
				return this.deleteFile(this.selectedMessageTemplate.get('id'));
			} else {
				return null;
			}
		},

		newMessageTemplate() {
			this.resetErrors();

			//* Create new Message Template local record in the Store
			const newMessageTemplate = this.store.createRecord('message-template', {
				title: 'New Message Template',
				content: null,
				typeAccess: 'user',
			});

			this.set('fd', null);
			this.set('filename', null);

			//* Push the newly created local record to the list of message templates to
			//* be displayed in the nav section with the preexisting templates
			this.model.userTemplates.pushObject(newMessageTemplate);

			//* Set the newly created local record as the selected template
			this.setSelectedMessageTemplate(newMessageTemplate);
		},

		setSelectedMessageTemplate(messageTemplate) {
			this.setSelectedMessageTemplate(messageTemplate);
			this.set('fd', null);
			this.set('filename', null);
			if (messageTemplate.get('file.fileName')) {
				this.set('filename', messageTemplate.get('file.fileName'));
			}
		},

		saveMessageTemplate() {
			this.resetErrors();

			const selectedMessageTemplate = this.selectedMessageTemplate;

			const shouldRemap = this.didTypeAccessChange();

			if (
				selectedMessageTemplate &&
				selectedMessageTemplate.save &&
				typeof selectedMessageTemplate.save === 'function'
			) {
				return selectedMessageTemplate
					.save()
					.then((res) => {
						this.notifications.success('Message Template updated.', {
							autoClear: true,
							clearDuration: 5000,
						});

						if (
							shouldRemap &&
							this.remapModel &&
							typeof this.remapModel == 'function'
						) {
							//* remap the model to have the updated templates show in their proper sections
							this.remapModel();
						}

						//Check if there is a new file to attach
						if (this.fd) {
							const templateId = res.id;
							const url = `${ENV.host}/message_templates/${templateId}/files`;

							this.ajax
								.post(url, {
									processData: false,
									contentType: false,
									data: this.fd,
								})
								.then(() => {
									this.set('fd', null);
									this.set('filename', null);
									this.rawSelectedMessageTemplate.reload();
								})
								.catch((err) => {
									this.notifications.error('Attached File was not saved.', {
										canClose: true,
										autoClear: true,
										clearDuration: 5000,
									});
									this.set('errors', Errors.mapResponseErrors(err));
									this.set('showErrors', true);
								});
						}
					})
					.catch((err) => {
						this.notifications.error('Message Template was not saved.', {
							canClose: true,
							autoClear: true,
							clearDuration: 5000,
						});
						this.set('errors', Errors.mapResponseErrors(err));
						this.set('showErrors', true);
					});
			}
		},

		deleteMessageTemplate() {
			const selectedMessageTemplate = this.rawSelectedMessageTemplate;
			this.resetErrors();

			if (this.isDeleting) return false;

			//* Set isDeleting to true to prevent them from click spamming the action
			this.set('isDeleting', true);

			return selectedMessageTemplate
				.destroyRecord()
				.then(() => {
					//* Set isDeleting to false since it is no longer deleting
					this.set('isDeleting', false);

					if (selectedMessageTemplate.typeAccess == 'user') {
						this.model.userTemplates.removeObject(selectedMessageTemplate);
					} else if (selectedMessageTemplate.typeAccess == 'firm') {
						this.model.firmTemplates.removeObject(selectedMessageTemplate);
					}

					//* Set the selectedMessageTemplate to the first template available in the array
					this.setSelectedMessageTemplate(this.model.systemTemplates[0]);
				})
				.catch((err) => {
					this.set('isDeleting', false);
					this.set('errors', Errors.mapResponseErrors(err));
					this.set('showErrors', true);
					this.notifications.error('Message Template was not deleted.');
				});
		},

		selectTemplateType(type) {
			this.set('selectedMessageTemplate.templateType', type.value);
		},
	},
});
