/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default Route.extend({
	ajax: service(),

	model() {
		return A([]);
	},

	setupController(controller) {
		this._super(...arguments);
		controller.fetchNewData();
	},
});
