/** @format */

import ApplicationAdapter from './application';

export default class ActivityAdapter extends ApplicationAdapter {
	urlForQuery(query) {
		if (query.caseId) {
			const result = `${this.host}/cases/${query.caseId}/activities`;
			delete query.caseId;

			return result;
		}
	}
}
