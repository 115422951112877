/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { safeHtml } from 'case-status/helpers/safe-html';

export default Component.extend({
	classNameBindings: ['defaultClassNames'],
	attributeBindings: ['style', 'data-test-id'],
	defaultClassNames: 'avatar',
	'data-test-id': 'component',
	style: computed('model', 'model.{initials,profilePicture.url}', function () {
		//If there is no model or if there are no initials and no PP url then display the case status logo
		if (
			!this.get('model') ||
			(!this.get('model.profilePicture.url') && !this.get('model.initials'))
		) {
			return safeHtml([`background-image: url('/img/cs_icon_150x150.png')`]);
		} else if (this.get('model.profilePicture.url')) {
			return safeHtml([
				`background-image: url('${this.get('model.profilePicture.url')}')`,
			]);
		} else if (
			!this.get('model.profilePicture.url') &&
			this.get('model.initials')
		) {
			return '';
		} else {
			return safeHtml([`background-image: url('/img/cs_icon_150x150.png')`]);
		}
	}),

	showInitials: computed(
		'model',
		'model.{initials,profilePicture.url}',
		function () {
			return (
				!this.get('model.profilePicture.url') && this.get('model.initials')
			);
		},
	),
});
