/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FirmSettings extends Controller {
	@service currentUser;
	@service permissions;
	@service router;

	@tracked subNav = null;

	init() {
		super.init(...arguments);
		//* Run once on init
		this.observeRouterCurrentRouteName();
		//* Create observer for otherwise
		this.addObserver(
			'router.currentRouteName',
			this,
			'observeRouterCurrentRouteName',
		);
	}

	observeRouterCurrentRouteName() {
		if (
			this.router.currentRouteName &&
			typeof this.router.currentRouteName == 'string' &&
			this.router.currentRouteName.includes('.templates.')
		) {
			this.set('subNav', 'templates');
		} else {
			//* Reset subNav to null if conditions not hit
			this.set('subNav', null);
		}
	}
}
