/** @format */

import Model, { attr } from '@ember-data/model';
import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	legacyUsername: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Username',
	}),

	legacyPassword: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Password',
	}),
});

export default Model.extend(Validations, {
	legacyUsername: attr('string'),
	legacyPassword: attr('string'),
});
