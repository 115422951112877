/** @format */

import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import Errors from '../constants/errors';
import { inject as service } from '@ember/service';

export default Route.extend(UnauthenticatedRouteMixin, {
	routeIfAlreadyAuthenticated: ENV.routeAfterAuthentication,
	ajax: service(),

	actions: {
		sendResetEmail(email, result) {
			const url = ENV.host + '/reset_password';
			const data = {
				email_address: email,
			};
			this.ajax
				.post(url, {
					contentType: 'application/json',
					data: JSON.stringify(data),
				})
				.then(() => {
					this.transitionTo('forgot-password-success');
				})
				.catch((response) => {
					result.reject();
					this.controller.set('errors', Errors.mapResponseErrors(response));
				});
		},
	},
});
