/** @format */
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import { action } from '@ember/object';

@classic
export default class AppointmentCard extends Component {
	tagName = '';
	@service store;
	@service permissions;
	@service currentUser;

	timezone = this.currentUser.get('user.firm.timezone');

	get showLogTreatment() {
		const now = moment(new Date());
		return (
			this.appointment.get('type') === 'medical' &&
			!this.appointment.get('treatmentNotes') &&
			moment(this.appointment.get('appointmentDate')).isBefore(now)
		);
	}

	get createUser() {
		if (this.createdActvity) {
			return (
				this.store.peekRecord(
					this.createdActvity.userType,
					this.createdActvity.childId,
				) ||
				this.store.findRecord(
					this.createdActvity.userType,
					this.createdActvity.childId,
				)
			);
		} else return null;
	}

	get updateUser() {
		if (this.updatedActivity) {
			return (
				this.store.peekRecord(
					this.updatedActivity.userType,
					this.updatedActivity.childId,
				) ||
				this.store.findRecord(
					this.updatedActivity.userType,
					this.updatedActivity.childId,
				)
			);
		}
		return null;
	}

	get formattedDate() {
		return `${moment
			.tz(this.appointment.appointmentDate, this.timezone)
			.format('MMM DD, YYYY h:mm a')} (${this.timezone})`;
	}
	get createdActvity() {
		const createdActivities = this.appointment.treatmentActivities.filter(
			(activity) => {
				return activity.action === 'created';
			},
		);
		if (createdActivities.length) {
			return createdActivities[0];
		}
		return null;
	}

	get formattedCreatedDate() {
		if (this.createdActvity) {
			return moment(this.createdActvity.createdAt).format('MMM DD, YYYY');
		} else return null;
	}

	get updatedActivity() {
		const updatedActivities = this.appointment.treatmentActivities
			.filter((activity) => {
				return activity.action === 'updated';
			})
			.sort((a, b) => {
				if (moment(a.createdAt).isAfter(b.createdAt)) {
					return -1;
				} else if (moment(b.createdAt).isAfter(a.createdAt)) {
					return 1;
				}
				return 0;
			});
		if (updatedActivities.length) {
			return updatedActivities[0];
		}
		return null;
	}

	get formattedUpdatedDate() {
		return moment(this.updatedActivity).format('MMM DD, YYYY');
	}

	@action
	onEditClickAction() {
		if (this.onEditClick) {
			this.onEditClick();
		}
	}
}
