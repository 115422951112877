/** @format */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';
import EmberError from '@ember/error';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { v4 } from 'uuid';

export default class PillGeneratingDropdownComponent extends Component {
	constructor() {
		super(...arguments);
		if (Array.isArray(this.args.model)) {
			this.pillArray = A(this.args.model);
		} else if (this.args.model) {
			throw EmberError('Provided model value must be an array.');
		} else {
			this.pillArray = A([]);
		}

		this.id = this.args.name ? this.args.name : v4();
		this.value = this.args.value || '';
		this.updateAction =
			typeof this.args.onChange == 'function' ? this.args.onChange : undefined;

		this.label = this.args.noLabel
			? undefined
			: this.args.label
			? this.args.label
			: this.args.placeholder
			? this.args.placeholder
			: undefined;
	}

	@service customBranding;

	@tracked pillArray;

	get options() {
		return A(this.args.options).filter((option) => {
			return !this.pillArray.find(
				(pill) => pill[this.args.identifier] == option[this.args.identifier],
			);
		});
	}

	@action
	addPill(value) {
		if (!value) return;
		this.pillArray.pushObject(value);

		if (this.updateAction) {
			next(() => this.updateAction(this.pillArray));
		}
	}

	@action
	removePill(value) {
		this.pillArray.removeObject(value);

		if (this.updateAction) {
			next(() => this.updateAction(this.pillArray));
		}
	}

	@action
	handleOnChange(array) {
		this.addPill(array[0]);
	}
}
