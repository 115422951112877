/** @format */

import Model, { hasMany, attr } from '@ember-data/model';

export default Model.extend({
	name: attr('string'),

	// relationships
	caseTypeTemplates: hasMany('case-type-template'),
});
