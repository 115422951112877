/** @format */

import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import { v4 } from 'uuid';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';

export default ApplicationAdapter.extend({
	ajax: service(),

	query(store, type, query) {
		const queryString = formatObjectAsSearchParams(query);

		return this.ajax
			.request(`/invoices/case_charges?${queryString}`)
			.then((res) => {
				for (let i = 0; i < res.data.length; i++) {
					const charge = res.data[i];
					charge.id = v4();
					res.data[i] = charge;
				}

				return res;
			});
	},
});
