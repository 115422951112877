/** @format */

import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	store: service(),
	thirdParty: service(),

	model() {
		let theCase = this.modelFor('app.firm.case').theCase;
		if (!theCase) {
			theCase = this.modelFor('app.firm.case.messages').theCase;
		}

		const treatmentTypes = this.store.findAll('treatmentType');

		// everything should be loaded into store from messages route if we got here in iframe
		// otherwise make a query
		let appointments;
		let medicalProviders;
		if (this.thirdParty.isIFrame) {
			appointments = this.store
				.peekAll('appointment')
				.filter((appt) => !!appt.id); // filter out the record created for newTreatment
			// we didn't find any appointments in store, so let's query to be sure
			if (!appointments.length) {
				appointments = this.store.query('appointment', { case_id: theCase.id });
			}
			medicalProviders = this.store.peekAll('medicalProvider');
		} else {
			appointments = this.store.query('appointment', { case_id: theCase.id });
			medicalProviders = this.store.query('medicalProvider', {
				case_id: theCase.id,
			});
		}

		return hash({
			theCase: theCase,
			newTreatment: this.store.createRecord('appointment', { case: theCase }),
			treatmentTypes,
			appointments,
			medicalProviders,
		});
	},

	resetController(controller, isExiting) {
		if (isExiting) {
			//* This will save on browser resources as well as reset the isPast property on appointments
			this.store.unloadAll('appointment');
		}
	},

	actions: {
		cancelEditing() {
			this.controllerFor('app.firm.case.appointments').set(
				'showNewAppointmentForm',
				false,
			);
		},
		newTreatmentForm() {
			this.controllerFor('app.firm.case.treatments').set(
				'showNewTreatmentForm',
				true,
			);
			this.transitionTo('app.firm.case.treatments');
		},
	},
});
