/** @format */

import Component from '@ember/component';
import Table from 'ember-light-table';
import { computed } from '@ember/object';

export default Component.extend({
	columns: computed('numClients', function () {
		const numClients = this.numClients;
		return [
			{
				cellClassNames: 'with-chevron',
				width: '10%',
				sortable: false,
			},
			{
				label: `${numClients} Client${numClients !== 1 ? 's' : ''}`,
				valuePath: 'name',
				cellComponent: 'cell-case-client-table-client-name',
				width: '50%',
				sortable: false,
			},
			{
				label: 'Last Interaction',
				valuePath: 'inviteData',
				cellComponent: 'cell-last-opened',
				sortable: false,
			},
		];
	}),

	model: null,
	table: null,

	didUpdateAttrs() {
		this._super(...arguments);
		const model = this.model;
		this.table.setRows(model || []);
		this.set('numClients', model ? model.length : 0);
		//Update client count
		this.table.setColumns(this.columns);
	},

	init() {
		this._super(...arguments);

		const model = this.model;
		this.set('numClients', model ? model.length : 0);

		let table = Table.create({
			columns: this.columns,
			rows: model,
			enableSync: true,
		});

		highlightRows(table.get('rows'));

		this.set('table', table);
	},
});

function highlightRows(rows) {
	if (rows?.content?.length > 0) {
		return rows.content.map(function (row) {
			const content = row.get('content');
			if (content && content.get('optOut')) {
				return row.set('classNames', ['red-bg']);
			} else {
				row.set('classNames', []);
			}
		});
	}
}
