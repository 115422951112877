/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Changeset from 'ember-changeset';
import { next } from '@ember/runloop';
import { A } from '@ember/array';
import moment from 'moment';
import Errors from 'case-status/constants/errors';

@classic
export default class ChecklistItemFormComponent extends Component {
	@service store;
	@service notifications;
	@service keyBindings;

	@tracked isSaving = false;

	classNames = ['row'];

	minDate = new Date();

	didRender() {
		super.didRender(...arguments);
		this.keyBindings.subscribe(
			this.itemToEdit
				? 'edit-checklist-item-form-input'
				: 'checklist-item-form-input',
			'keypress',
			(ele, event, ...args) => {
				if (event.key == 'Enter') {
					if (this.itemToEdit) {
						this.saveEditItem();
					} else {
						this.saveChecklistItem();
					}
				}
			},
		);
	}

	willDestroy() {
		super.willDestroy(...arguments);
		this.keyBindings.unsubscribe(
			this.itemToEdit
				? 'edit-checklist-item-form-input'
				: 'checklist-item-form-input',
			'keypress',
		);
	}

	init() {
		super.init(...arguments);
		if (this['case']) {
			this.safeSet('newChecklistItem', {
				case: this['case'],
				content: '',
				position: this['case'].get('checklistItems.length'),
			});
		} else if (this.chat) {
			this.safeSet('newChecklistItem', {
				chat: this.chat,
				content: '',
				position: this.chat.get('checklistItems.length'),
			});
		} else if (this.template) {
			this.safeSet('isTemplate', true);
			this.safeSet('newChecklistItem', {
				checklistTemplate: this.template,
				content: '',
				retroactive: false,
				type: 'task',
				position: this.template.get('checklistItemTemplates.length'),
			});
		} else {
			this.safeSet('newChecklistItem', {
				case: null,
				chat: null,
				content: '',
				position: 0,
			});
		}

		if (this.editItem) {
			//* Use a Changeset to avoid mutating the original record so we don't need to check for dirtyAttributes to rollback if they decide to cancel the edit.
			this.safeSet('itemToEdit', new Changeset(this.editItem));
		}
	}

	@tracked showChecklistTemplateModal = false;

	safeSet(prop = '', ...val) {
		if (!this.isDestroyed && !this.isDestroying) {
			this.set(prop, ...val);
		}
	}

	get assigneeList() {
		//* Get the list of assignable users relative to the housing model
		const model =
			this.chat ||
			this.case ||
			this.itemToEdit?.get('chat.content') ||
			this.itemToEdit?.get('case.content') ||
			null;
		if (!model) return A([]);

		if (this.chat || this.itemToEdit?.get('chat.content')) {
			const theChat = this.chat || this.itemToEdit?.get('chat.content');
			return theChat?.users || A([]);
		} else if (this.case || this.itemToEdit?.get('case.content')) {
			const theCase = this.case || this.itemToEdit?.get('case.content');
			const caseAttorneys = theCase?.attorneys?.toArray() || [];
			const caseParalegals = theCase?.paralegals?.toArray() || [];
			const caseClients = theCase?.clients?.toArray() || [];
			const caseUsers = A([
				...caseAttorneys,
				...caseParalegals,
				...caseClients,
			]).sortBy('lastName');
			return caseUsers;
		} else {
			//* This shouldn't be reached
			return A([]);
		}
	}

	get assignees() {
		if (this.itemToEdit) {
			return this.itemToEdit.get('assignees').toArray();
		} else return [];
	}

	async _saveChecklistItem(item) {
		let newItemBody;

		this.safeSet('isSaving', true);

		if (item) {
			if (this.case) {
				newItemBody = {
					content: item.content,
					case: this.case,
				};
			}

			if (this.isTemplate) {
				newItemBody = {
					content: item.content,
					type: 'task', // API yells if it is null
				};
			}

			if (this.chat) {
				newItemBody = {
					content: item.content,
					chat: this.chat,
				};
			}
		} else {
			newItemBody = this.newChecklistItem;
		}

		let modelType = 'checklistItem';

		if (this.isTemplate) {
			modelType = 'checklistItemTemplate';
		}

		const newItem = this.store.createRecord(modelType, newItemBody);

		try {
			//* Create the template on the API side first then save the item
			if (this.template) {
				if (this.template?.get('isNew')) {
					this.template = await this.template.save();
				}

				//* Push to the actual templates instead of the computed sorted ones, they should update accordingly
				await this.template.get('checklistItemTemplates').pushObject(newItem);
				newItem.checklistTemplate = this.template;
			}

			return newItem
				.save()
				.then(() => {
					if (this.refreshList && typeof this.refreshList == 'function') {
						this.refreshList();
					}
					this.safeSet('newChecklistItem.content', '');
					this.safeSet('isSaving', false);
					if (!item) {
						return this.cancelAddItem();
					}
				})
				.catch(() => {
					this.safeSet('isSaving', false);
				});
		} catch (error) {
			this.safeSet('isSaving', false);
			return this.notifications.error(
				'An error has occurred trying to save the checklist item',
				{
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				},
			);
		}
	}

	@action
	handleSelectAssignees(users) {
		if (this.itemToEdit) {
			this.safeSet('itemToEdit.assignees', A(users));
		} else {
			this.safeSet('newChecklistItem.assignees', A(users));
		}
	}

	@action
	setDueDate(val) {
		if (this.itemToEdit) {
			this.safeSet('itemToEdit.newDueDate', moment(val).toISOString());
		} else {
			this.safeSet('newChecklistItem.newDueDate', moment(val).toISOString());
		}
	}

	@action
	saveChecklistItem(item) {
		return this._saveChecklistItem(item);
	}

	@action
	saveEditItem() {
		this.safeSet('isSaving', true);
		return this.itemToEdit
			.save()
			.then(() => {
				this.safeSet('isSaving', false);
				next(() => {
					//* Close out in next runloop to avoid error of "setting 'isSaving' on a destroyed object"
					this.cancelAddItem();
				});
			})
			.catch((err) => {
				this.notifications.error(Errors.mapResponseErrors(err), {
					canClear: true,
					autoClear: true,
					clearDuration: 5000,
				});
				this.safeSet('isSaving', false);
			});
	}

	@action
	toggleShowChecklistTemplateModal() {
		this.toggleProperty('showChecklistTemplateModal');
	}

	@action
	async applyTemplate(template) {
		const result = defer();

		//* Use the sorted templates so it is applied in order, though order may not matter here, just in case though.
		if (!template.sortedChecklistItemTemplates?.length) {
			result.reject;
			return result.promise;
		} else {
			// We record the checklist items in the template *before* we save any new checklist items to prevent...
			// an infinite loop when the user tries to add a template to itself - see CP-168
			const newChecklistItems = template.sortedChecklistItemTemplates;
			const startingTemplateLength = newChecklistItems.length;

			for (let i = 0; i < startingTemplateLength; i++) {
				const item = newChecklistItems[i];
				if (this.case) {
					item.set('case', this.case);
				}
				await this._saveChecklistItem(item);

				if (i === startingTemplateLength - 1) {
					result.resolve();
				}
			}
			return result.promise;
		}
	}
}
