/** @format */

// app/instance-initializers/session-events.js
export function initialize(instance) {
	const applicationRoute = instance.lookup('route:application');
	const session = instance.lookup('service:session');
	session.on('authenticationSucceeded', function () {
		applicationRoute.transitionTo('app.firm.cases');
	});
	session.on('invalidationSucceeded', function () {
		//Force a reload to clear out old state on login page unless using admin login route
		if (applicationRoute.get('_router.currentPath') !== 'login-admin') {
			window.location.href = '/login';
		}
	});
}
export default {
	initialize,
	name: 'session-events',
	after: 'ember-simple-auth',
};
