/** @format */

import Component from '@ember/component';
import { Spinner } from 'spin.js';
import { v4 } from 'uuid';

const { observer } = Ember;

export default Component.extend({
	tagName: 'button',
	type: 'submit',
	inFlight: false,
	buttonStyle: 'expand-right',

	defaultTimeout: 10e3,
	startDelay: 100,

	attributeBindings: ['disabled', 'type', 'buttonStyle:data-style'],
	classNameBindings: ['inFlight:in-flight:ready', ':spin-button'],

	_timer: null,
	spinnerOpts: {
		lines: 13, // The number of lines to draw
		length: 38, // The length of each line
		width: 17, // The line thickness
		radius: 45, // The radius of the inner circle
		scale: 0.13, // Scales overall size of the spinner
		corners: 1, // Corner roundness (0..1)
		speed: 1, // Rounds per second
		rotate: 0, // The rotation offset
		animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
		direction: 1, // 1: clockwise, -1: counterclockwise
		color: '#FFFFFF', // CSS color or array of colors
		fadeColor: 'transparent', // CSS color or array of colors
		top: '5%', // Top position relative to parent
		left: '10%', // Left position relative to parent
		shadow: '0 0 1px transparent', // Box-shadow for the lines
		zIndex: 2000000000, // The z-index (defaults to 2e9)
		className: 'spinner', // The CSS class to assign to the spinner
		position: 'absolute', // Element positioning
	},

	click: function (event) {
		event.preventDefault();
		this.set('inFlight', true);
		if (this.attrs && 'function' === typeof this.attrs.action) {
			let actionResult = this.attrs.action();

			if (
				Ember.isPresent(actionResult) &&
				'function' === typeof actionResult.finally
			) {
				actionResult.finally(() => {
					this.set('inFlight', false);
				});
			}
		} else {
			this.sendAction('action');
		}
	},

	inFlightDidChange: observer('inFlight', function () {
		var element = this.get('element');
		if (!element) {
			return;
		}

		var inFlight = this.get('inFlight');

		if (inFlight) {
			if (this.get('startDelay') > 4) {
				Ember.run.later(
					this,
					this.createSpinner,
					element,
					this.get('startDelay'),
				);
			} else {
				this.createSpinner(element);
			}
		} else {
			this.setEnabled();
		}
	}),

	createSpinner(element) {
		if (!this._spinner) {
			this._spinner = new Spinner(this.spinnerOpts);
			this._spinner.spin(element.querySelector('.spin-button-spinner'));
		}

		if (this._timer) {
			Ember.run.cancel(this._timer);
		}

		var timeout = this.get('defaultTimeout');
		if (timeout > 4) {
			this._timer = Ember.run.later(this, this.setEnabled, timeout);
		}
	},

	disabled: Ember.computed.readOnly('inFlight'),

	setEnabled() {
		if (this._timer) {
			Ember.run.cancel(this._timer);
		}

		if (this._spinner) {
			this._spinner.stop();
			this._spinner = null;
		}

		if (!this.get('isDestroyed')) {
			this.setProperties({
				inFlight: false,
			});
		}
	},
});
