/** @format */

import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
	currentStep: computed('model.theCase.caseStatus.number', function () {
		switch (this.get('model.theCase.caseStatus.number')) {
			case 1:
				return 'step-one';
			case 2:
				return 'step-two';
			case 3:
				return 'step-three';
			case 4:
				return 'step-four';
			case 5:
				return 'step-five';
			case 6:
				return 'step-six';
			case 7:
				return 'step-seven';
			case 8:
				return 'step-eight';
			case 9:
				return 'step-nine';
			case 10:
				return 'step-ten';
			case 11:
				return 'step-eleven';
			case 12:
				return 'step-twelve';
			default:
				return '';
		}
	}),

	stepOneClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');

		if (!number) {
			return 'inactive';
		}
		return number === 1 ? 'current' : '';
	}),

	stepTwoClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 2) {
			return 'inactive';
		} else if (number === 2) {
			return 'current';
		}
		return '';
	}),

	stepThreeClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 3) {
			return 'inactive';
		} else if (number === 3) {
			return 'current';
		}
		return '';
	}),

	stepFourClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 4) {
			return 'inactive';
		} else if (number === 4) {
			return 'current';
		}
		return '';
	}),

	stepFiveClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 5) {
			return 'inactive';
		} else if (number === 5) {
			return 'current';
		}
		return '';
	}),

	stepSixClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 6) {
			return 'inactive';
		} else if (number === 6) {
			return 'current';
		}
		return '';
	}),

	stepSevenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 7) {
			return 'inactive';
		} else if (number === 7) {
			return 'current';
		}
		return '';
	}),

	stepEightClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 8) {
			return 'inactive';
		} else if (number === 8) {
			return 'current';
		}
		return '';
	}),

	stepNineClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 9) {
			return 'inactive';
		} else if (number === 9) {
			return 'current';
		}
		return '';
	}),

	stepTenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 10) {
			return 'inactive';
		} else if (number === 10) {
			return 'current';
		}
		return '';
	}),

	stepElevenClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 11) {
			return 'inactive';
		} else if (number === 11) {
			return 'current';
		}
		return '';
	}),

	stepTwelveClass: computed('model.theCase.caseStatus.number', function () {
		const number = this.get('model.theCase.caseStatus.number');
		if (!number || number < 12) {
			return 'inactive';
		} else if (number === 12) {
			return 'current';
		}
		return '';
	}),
});
