/** @format */

import Model, { hasMany, attr } from '@ember-data/model';
import { sort } from '@ember/object/computed';

export default Model.extend({
	title: attr('string'),
	createdAt: attr('date'),
	updatedAt: attr('date'),

	sortProp: ['position'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sortedChecklistItemTemplates: sort('checklistItemTemplates', 'sortProp'),
	checklistItemTemplates: hasMany('checklistItemTemplate'),
});
