/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Table from 'ember-light-table';
import { dateDisplay } from 'case-status/helpers/date-display';

export default Component.extend({
	currentUser: service('current-user'),

	columns: computed(function () {
		const currentUserFirm = this.get('currentUser.user.firm');
		return [
			{
				label: 'Case ID',
				valuePath: 'readableId',
				cellClassNames: 'light ellipsis',
				cellComponent: 'cell-overflow-hover',
			},
			{
				label: 'Client',
				valuePath: 'clients',
				cellClassNames: 'ellipsis',
				cellComponent: 'cell-cases-client',
			},
			{
				label: 'Firm',
				valuePath: 'firm.name',
				cellClassNames: 'ellipsis',
				cellComponent: 'cell-overflow-hover',
			},
			{
				label: 'Status',
				valuePath: 'caseStatus',
				cellComponent: 'status-cell',
			},
			{
				label: 'Updated',
				valuePath: 'updatedAt',
				format: function (value) {
					return dateDisplay([value, currentUserFirm]);
				},
				cellClassNames: 'italic',
				width: '130px',
			},
			{
				valuePath: 'newChatMessages',
				cellComponent: 'new-messages-cell',
				align: 'left',
				sortable: false,
			},
		];
	}),

	init() {
		this._super(...arguments);

		let table = Table.create({
			columns: this.columns,
			rows: this.model,
			enableSync: true,
		});
		let sortColumn = table.get('allColumns').findBy('valuePath', 'updatedAt');
		sortColumn.set('sorted', true);
		sortColumn.set('ascending', false);

		this.set('table', table);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			let scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});
	},

	actions: {
		rowClicked(row) {
			this.caseClicked(row.content.id);
		},

		onColumnClick(column) {
			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				//Format case status path
				if (columnName === 'caseStatus') {
					columnName = 'case_status.name';
				}

				this.updateSort(columnName, direction);
			}
		},

		onScrolledToBottom() {
			this.fetchMoreCases();
		},
	},
});
