/** @format */

import Service, { inject as service } from '@ember/service';
import moment from 'moment';

export default class ActivityTrackingService extends Service {
	@service currentUser;
	@service store;
	@service mobile;

	activityMapping = {
		login: {
			name: 'logged_in',
			type: 'login',
		},
		viewedStatus: {
			name: 'viewed_status',
			type: 'growth',
		},
		createdAppointment: {
			name: 'added_appointment',
			type: 'growth',
		},
		updatedAppointment: {
			name: 'updated_appointment',
			type: 'growth',
		},
		loggedTreatment: {
			name: 'added_treatment_log',
			type: 'growth',
		},
		updatedTreatment: {
			name: 'updated_treatment_log',
			type: 'growth',
		},
		readAutomatedMessage: {
			name: 'read_automated_message',
			type: 'message',
		},
		readMessage: {
			name: 'read_message',
			type: 'message',
		},
		sentMessage: {
			name: 'sent_message',
			type: 'message',
		},
		scrolledMessages: {
			name: 'scrolled_messages',
			type: 'message',
		},
		viewedTreatmentLog: {
			name: 'viewed_treatment_log',
			type: 'growth',
		},
		viewedAppointments: {
			name: 'viewed_appointment_tab',
			type: 'growth',
		},
		submittedNPS: {
			name: 'submitted_nps',
			type: 'growth',
		},
		viewedFile: {
			name: 'viewed_file',
			type: 'message',
		},
	};

	trackByCase(caseId, activityTypeKey, payloadData) {
		//* Blanket limit to only clients
		if (this.currentUser.get('user.constructor.modelName') !== 'client') return;

		const errors = [];
		const errMsgEnd = 'required to track activity for case.';
		if (!caseId) {
			errors.push('Case ID ' + errMsgEnd);
		}

		if (!activityTypeKey) {
			errors.push('Activity Type Key ' + errMsgEnd);
		}

		const { type, name } = this.activityMapping[activityTypeKey];

		const data = {
			activityName: name,
			activityType: type,
			caseId: caseId,
			recordedAt: moment(new Date()).utc().toDate().toISOString(),
			firmId: this.currentUser.get('user.firm.id'),
			payload: {
				meta: JSON.stringify({
					// Api doesn't like nested objects here, want's strings
					source: this.mobile.isMobile ? 'mobile_web_app' : 'web_app', // I want to know where this activity was tracked from and which version (mobile or not)
				}),
				data: JSON.stringify(payloadData) ?? 'null', // since api will yell if anything is set to actual null
			},
			subUserId: this.currentUser.get('user.id'),
			subUserType: this.currentUser.get('user.constructor.modelName'),
			userName: this.currentUser.get('user.name'),
		};

		const newActivity = this.store.createRecord('activity', data);

		newActivity.save().catch((err) => {
			// Api doesn't send the model back like Ember expects so we just throw the error into the void to keep the app moving forward
			void err;
		});
	}
}
