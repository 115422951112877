/**
 * Created by Matthew Aderhold (@AderCode on GitHub)
 *   On Fri Mar 20, 2020
 *
 * @format
 */

import { helper } from '@ember/component/helper';
import camelize from 'camelize';
import EmberError from '@ember/error';

export function typeformHiddenVariables(params /*, hash*/) {
	//* Standard params gotta be an array or hash else we cry in Ember
	if (!Array.isArray(params)) {
		throw new EmberError(
			'typeform-hidden-variables helper requires data passed in as an array',
		);
	}

	//* Deconstruct the params
	const [string, hiddenFields, variables] = params;

	//* No string, no work. Give them an empty string for good measure
	if (!string) {
		return '';
	}

	//* Mutable String Clone, not a reference!
	let workingString = `${string}`;

	//* Variable Regex that identifies anything thing between "{{var:" and "}}"
	//* which is how Typeform sends variables used in field titles: ie "You pay ${{var:score}}.00"
	//* where "score" is in the variables object array as a number property with a value of 200
	const varRegex = /({{var:)(.*?)(}})/g;

	//* Hidden Values Regex that identifies anything thing between "{{hidden:" and "}}"
	//* which is how Typeform sends Hidden Fields used in field titles:
	//* ie "Is {{hidden:client_name}} correct" where "client_name" is in the hiddenFields object as "Client PersonMan"
	const hiddenRegex = /({{hidden:)(.*?)(}})/g;

	//* Test if the string even needs parsing or not
	//* 1. Test if the string has a Typeform formatted variable used AND a variables Object Array is provided
	//*   - Without a variables Object Array we cannot replace the variable in the string and thus is false
	//* 2. Test if the string has a Typeform formatted hidden field used AND a hiddenFields Object is provided
	//*   - Same as with the variables, without a hiddenFields Object to process the string with we can't process it
	//TODO Possibly in the future we can throw an error if there is a hidden field or variables in the string but no
	//TODO hiddenFields Object or variables Object Array provided to process it with, tsk tsk
	//! The reason I forewent adding the Errors now is because of too many possibilities from all the types of forms
	//! that can be generated on Typeform and I didn't want that outside factor to cause users to experience broken
	//! pages because I went overboard on engineering and securing my helper. /shrug
	const needsParsing =
		(varRegex.test(string) && variables) ||
		(hiddenRegex.test(string) && hiddenFields);

	//* If we have surmised that the string does not need parsing, then we just return that string.
	//* No need to waste time and valuable resources.
	if (!needsParsing) return string;

	//* Now for the fun part

	//* Let's split the string into an array so we can easily map over each cluster of characters,
	//* from this point on referred to as "words" or "word"
	const wordArray = string.split(' ');

	//* Time to work through the words
	wordArray.forEach((word) => {
		//* forEach word we need to test if it contains, or is, a Typeform formatted variable or hidden field recall
		//* If not, cool we will just return that word into the void since this isn't a map function, but nonetheless
		//* breaking out and saving on valuable resources.
		//? NOTE: the forEach method is different than a standard for loop, whereas a for loop will loop one after another
		//? as it increments, the forEach method runs the callback function on all indices of the array asynchronously allowing us
		//? to parse through and process much faster than a standard for loop, but WILL NOT respect async await like a for loop will
		//? #DevFoodForThought
		if (!varRegex.test(string) && !hiddenRegex.test(string)) return word;

		//* Now that we have determined that this word indeed needs parsing and processing we make arrays of all matches
		//* using our handy dandy Regex from earlier in our show.
		let varMatches = word.match(varRegex);
		let hiddenMatches = word.match(hiddenRegex);

		//* The String match method returns falsey if no matches are found, so no need to check the length of the match array
		//* But let's make sure the variables array exists and is an array and not empty. Sometimes those guards we place at
		//* the front doors of our function will just let anyone through. Hard to find good helpers these days, buh dum tss!
		if (
			varMatches &&
			variables &&
			Array.isArray(variables) &&
			variables.length
		) {
			//* Sweet we have variables and matches for them!
			//* Let's place them into a Set so that way it will filter them for use and have every index value be unique!
			//* Then we will use the spread operator to spread the Set out into a normal array where everyone is unique in
			//* their own ways, and values of course
			varMatches = [...new Set(varMatches)];

			//* Now to work through the unique matches
			varMatches.forEach((varMatch) => {
				//* So our varMatch will be like this "{{var:score}}".
				//* The beginning of the variable will always be "{{var:"
				//* The end of the variable will always be "}}"
				//* So let's slice out the bits in between all of that and store it in a constant for later
				const v2 = `${varMatch}`.slice(6, varMatch.length - 2);

				//* Now for the tricky business
				//* We  filter the variables Object Array to only contain only the Object that has the key property who's value
				//* matches that bit we sliced out and stored in the v2 constant and store that in another constant called replacement
				const replacement = variables.filter((v) => {
					return v.key == v2;
				})[0];

				//* We take our workingString and use the replace method combined with a Regex dynamically generated using
				//* the varMatch from before (ie "{{var:score}}") and the replacement object which of whom has a type property
				//* which just so happens to be the name of the key for the property that holds the value we need to finish this parse
				//? NOTE: The reason that we use the dynamic Regex instead of just the stored string in v2 or the full match string in varMatch
				//? is because the replace method will only replace the first instance of the string if provided but will replace ALL instances
				//? of a string that matches a Regex if provided and the Regex must have the global flag `g` or /g.
				//? https://www.youtube.com/watch?v=GD6qtc2_AQA
				workingString = workingString.replace(
					new RegExp(`(${varMatch})`, `g`),
					replacement[replacement.type],
				);
			});
		}

		//* Now let's do that for hiddenFields, just handled slightly different due to it being an Object and the properties
		//* on hiddenFields have been camelized before they got here. Impatient little buggers...

		//* So here we check to see if there were any hiddenMatches found by our magical Regex we created in Season 1
		//* then we check to make sure that we were provided hiddenFields and that they are in an Object
		//* then we format their keys into an array and check it's length because someone thought it a brilliant idea
		//* to let an empty Object resolve as Truthy while an empty String resolves as Falsey and an empty Array resolves falsey in a
		//* straight equality check ie [] == true //false yet ![] (force conversion) or !Boolean([]) (transposition) is false and
		//* !![] or Boolean([]) is true...
		//* What a world we dev in!
		if (
			hiddenMatches &&
			hiddenFields &&
			typeof hiddenFields == 'object' &&
			Object.keys(hiddenFields).length
		) {
			//* Now that we've made it into the VIP section here in the back we can work on the hiddenFields

			//* First we make a unique Array again by iterating over a Set made from our hiddenMatches and spreading them inside an Array literal
			hiddenMatches = [...new Set(hiddenMatches)];

			//* Time to work through those hiddenMatches
			hiddenMatches.forEach((hiddenMatch) => {
				//* So similar to the variables in Typeform a hidden field recall looks like "{{hidden:client_name}}"
				//* Where the beginning will always be "{{hidden:"
				//* and the end will always be "}}"
				//* The middle between them being the key's name in the hiddenFields Object
				//* Again since we just want that ooey gooey middle, we slice off the crusty beginning and end and store the middle in a constant called h2
				const h2 = `${hiddenMatch}`.slice(9, hiddenMatch.length - 2);

				//* Since the hiddenFields was provided to us as a simple Object by our Typeform overlords we can easily replace all instances of the hiddenMatch
				//* by using the replace method and a dynamic Regex of the hiddenMatch with the global flag and camelizing the key, which is what was technically
				//* stored in the h2 constant, to gain entry to the hiddenFields object and retrieve the value we need in place of the hiddenMatch
				workingString = workingString.replace(
					new RegExp(`(${hiddenMatch})`, `g`),
					hiddenFields[camelize(h2)],
				);
			});
		}
	});

	//* And that concludes the parsing work we have done here today, my dudes!
	//* This production has been made possible by (re)viewers like you!
	return workingString;
}

export default helper(typeformHiddenVariables);
