/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Component.extend({
	session: service(),
	company: service(),
	userType: alias('session.data.authenticated.user_type'),

	actions: {
		markAllNotificationsAsRead(result) {
			this.markAllNotificationsAsRead(result);
		},
	},
});
