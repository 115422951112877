import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{on 'click' (fn this.handleClick)}}>\n\t{{#if this.isClicked}}\n\t\t<FaIcon\n\t\t\t@icon={{concat 'thumbs-' (if (eq @buttonType 'up') 'up' 'down')}}\n\t\t\tclass={{concat\n\t\t\t\t'icon '\n\t\t\t\t(if (eq @buttonType 'up') 'voted-up' 'voted-down')\n\t\t\t}}\n\t\t/>\n\t{{else}}\n\t\t<FaIcon\n\t\t\t@icon={{concat 'thumbs-' (if (eq @buttonType 'up') 'up' 'down')}}\n\t\t\tclass='icon'\n\t\t/>\n\t{{/if}}\n</span>", {"contents":"<span {{on 'click' (fn this.handleClick)}}>\n\t{{#if this.isClicked}}\n\t\t<FaIcon\n\t\t\t@icon={{concat 'thumbs-' (if (eq @buttonType 'up') 'up' 'down')}}\n\t\t\tclass={{concat\n\t\t\t\t'icon '\n\t\t\t\t(if (eq @buttonType 'up') 'voted-up' 'voted-down')\n\t\t\t}}\n\t\t/>\n\t{{else}}\n\t\t<FaIcon\n\t\t\t@icon={{concat 'thumbs-' (if (eq @buttonType 'up') 'up' 'down')}}\n\t\t\tclass='icon'\n\t\t/>\n\t{{/if}}\n</span>","moduleName":"case-status/components/ai-vote-on-response-button.hbs","parseOptions":{"srcName":"case-status/components/ai-vote-on-response-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AiVoteOnResponseButtonComponent extends Component {
	@tracked isClicked = false;

	@action
	handleClick(event) {
		if (!this.args.recommendedResponse && !this.args.getInnerContent()) {
			return;
		}
		this.isClicked = true;
		this.args.vote(this.args.approval, this.args.recommendedResponse);

		// Disable further voting
		let buttonContainer = event.target.closest('.vote-button-container');
		buttonContainer.style.pointerEvents = 'none';

		// Get all buttons (span elements) in the container
		let buttons = buttonContainer.querySelectorAll('span');

		// Iterate over buttons and grey out the icon of the one not clicked
		buttons.forEach((button) => {
			let icon = button.querySelector('.icon');
			if (icon && !button.contains(event.target)) {
				icon.classList.add('greyed-out');
			}
		});
	}
}
