/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	content: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Message',
	}),
});

export default Model.extend(Validations, {
	//because content is a reserved word
	body: computed('content', function () {
		return this.content;
	}),

	chatId: attr('number'),
	content: attr('string'),
	createdAt: attr('date'),
	senderId: attr('number'),
	senderChildId: attr('number'),
	senderName: attr('string'),
	senderType: attr('string'),
	type: attr('string'),
	statusNumber: attr('number'),

	// Relationships
	chat: belongsTo('chat', { inverse: 'chatMessages' }),
	chatNotifications: hasMany('chat-notification'),
	chatFile: belongsTo('chat-file'),
	sender: belongsTo('user'),
});

/*
  Wiki can be found here:
  https://github.com/casestatus/case-status-api/wiki/Chat-Message
*/
