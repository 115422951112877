/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import ENV from 'case-status/config/environment';
import { reject } from 'rsvp';

export default Controller.extend({
	company: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),
	hasCases: computed('model', 'model.length', function () {
		if (!this.model) false;
		if (this.model.length > 0) return true;
		return false;
	}),
	activeCases: computed('model', 'hasCases', function () {
		if (this.hasCases) {
			const activeCases = [...this.model.toArray()].filter((theCase) => {
				return theCase.activatedDate;
			});
			return activeCases;
		} else return [];
	}),

	inactiveCases: computed('model', 'hasCases', function () {
		if (this.hasCases) {
			const inactiveCases = [...this.model.toArray()].filter((theCase) => {
				return !theCase.activatedDate;
			});
			return inactiveCases;
		} else return [];
	}),

	actions: {
		requestAccess() {
			if (this.isRequestingAccess || this.requestedAccess) return reject();
			this.set('isRequestingAccess', true);
			return this.ajax
				.post(
					`${ENV.host}/chat_messages/${this.currentUser.user.get(
						'firm.id',
					)}/client_waiting`,
				)
				.then(() => {
					this.set('requestedAccess', true);
				})
				.catch(() => {
					this.notifications.error(
						'An error has occurred while request access, please refresh the page and try again.',
						{
							canClose: true,
							autoClear: true,
							clearDuration: 5000,
						},
					);
				})
				.finally(() => {
					this.set('isRequestingAccess', false);
				});
		},
	},
});
