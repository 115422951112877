/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	setupController(controller) {
		this._super(...arguments);
		controller.set('currentSubTab', 'Chat');
		this.controllerFor('app.firm.case').set('currentTab', 'internal');
		setTimeout(() => {
			//* Let template load in before setting observer
			controller.setupInViewport();
		}, 1000);
	},
	resetController(controller, isExiting) {
		if (isExiting) {
			const loader = document.getElementById('loader');
			controller.inViewport.stopWatching(loader);
			controller.pubnub.newChatMessageCallback = null;
		}
	},
	currentUser: service('current-user'),
});
