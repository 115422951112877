/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
	classNames: ['cell-overflow-hover--transaction--client'],
	valueObserver: observer('value', function () {
		const value = this.value;
		if (this.get('row.group')) {
			this.set('formattedValue', this.get('row.group'));
			this.set('isBold', true);
		} else if (value?.get('length') === 1) {
			this.set('formattedValue', value.objectAt(0).get('name'));
			this.set('isBold', true);
		} else {
			this.set(
				'formattedValue',
				`${value?.get('length') ? value?.get('length') : 0} Clients`,
			);
			this.set('isBold', false);
		}
	}).on('init'),
});
