/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { observer, computed } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import { validator, buildValidations } from 'ember-cp-validations';
import Errors from '../../../../constants/errors';
import { defer } from 'rsvp';
import ENV from 'case-status/config/environment';
import subTabs from '../../../../constants/chat-sub-tabs';
import { A } from '@ember/array';
import { captureException } from 'logrocket';

const Validations = buildValidations({
	newMessage: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Message',
	}),
});

export default Controller.extend(Validations, {
	// Services
	resourceMetadata: service(),
	customBranding: service(),
	currentUser: service(),
	notifications: service(),
	store: service(),
	ajax: service(),
	company: service(),
	pubnub: service('pubnub-service'),
	thirdParty: service(),

	hasChat: computed('chats.length', function () {
		return this.chats?.length > 0;
	}),

	isUserOnCase: computed('theCase.users.[]', 'currentUser.user', function () {
		const theCase = this.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}),

	//* Sub tabs
	subTabs,
	subTabsObserver: observer(
		'chat',
		'chat.chatFiles.[]',
		'chat.isInternal',
		'chat.sortedChecklistItems.[].{}',
		'pendingChecklistItemCount',
		function () {
			const hasRequestsOption = this.get('chat.organization.formId');
			const isInternalChat = this.get('chat.isInternal');
			let currentSubTabs = [...this.subTabs];

			//* If there is no formId for the chat Org, then make sure "Requests" is removed from the subTabs
			//* If there is a formId for the chat Org, make sure that "Requests" is included within subTabs
			if (!hasRequestsOption && currentSubTabs.includes('Requests')) {
				const spliceIndex = currentSubTabs.indexOf('Requests');
				currentSubTabs.splice(spliceIndex, 1);
			} else if (hasRequestsOption && !currentSubTabs.includes('Requests')) {
				currentSubTabs.splice(1, 0, 'Requests');
			}

			if (this.get('currentUser.permissions.checklist')) {
				if (isInternalChat && !currentSubTabs.includes('Checklist')) {
					const spliceIndex = currentSubTabs.indexOf('Documents');
					currentSubTabs.splice(spliceIndex + 1, 0, 'Checklist');
				} else if (!isInternalChat && currentSubTabs.includes('Checklist')) {
					const spliceIndex = currentSubTabs.indexOf('Checklist');
					currentSubTabs.splice(spliceIndex, 1);
				}
			}

			this.set('subTabs', currentSubTabs);
		},
	).on('init'),

	showSubTabs: computed('subTabs.length', function () {
		return this.subTabs.length > 1;
	}),

	currentSubTab: 'Chat',

	isChatTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Chat';
	}),

	isRequestTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Requests';
	}),

	isDocumentsTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Documents';
	}),

	isChecklistTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Checklist';
	}),

	sortedFiles: alias('chat.chatFiles'),

	collaborators: alias('theCase.firm.organizations'),
	firmName: alias('theCase.firm.name'),
	permissionsList: null, //* In metadata.case_organizations on theCase
	caseOrganizations: null,
	permissions: null, //* different and older than the permissions service
	isLoadingChat: false,
	// Note: not sure if i need to watch caseOrganizations
	chatObserver: observer('chat', function () {
		if (this.get('chat.organizationId')) {
			let permissions = {
				canChat: this.get('chat.canChat'),
				showCaseStatus: this.get('chat.showCaseStatus'),
				showClientNames: this.get('chat.showClientNames'),
			};

			this.set('permissions', permissions);
		} else {
			this.set('permissions', null);
		}
	}),

	theCase: alias('model.theCase'),
	// Chat Properties
	chats: alias('model.chats'),
	chat: null, //* Default to the first in the list in lifecycle method
	collaboratorName: computed('chat', function () {
		if (this.get('chat.isInternal')) {
			return this.firmName;
		} else {
			return this.get('chat.organization.name');
		}
	}),
	collaboratorDescription: computed('chat', function () {
		if (this.get('chat.isInternal')) {
			return 'Your firm';
		} else {
			const collaboratorDescription = this.get('chat.organization.descriptor');
			return collaboratorDescription ? collaboratorDescription : 'Collaborator';
		}
	}),
	chatMessageObserver: observer('chat.chatMessages.[]', function () {
		//* Only reload the chat because of message update once every second
		debounce(this, this.reloadChat, 1000);
	}),
	authorizedChat: computed('chat.users.[]', function () {
		const chatUsers = this.get('chat.users');
		const currentUser = this.get('currentUser.user');

		if (!chatUsers) return false;

		const currentUserAuthorized = chatUsers.find((user) => {
			return user.get('id') === currentUser.get('id');
		});
		return currentUserAuthorized;
	}),
	isRemovedChat: computed('permissions', 'chat.isInternal', function () {
		const perms = this.permissions;
		const isInternalChat = this.get('chat.isInternal');

		return !perms && !isInternalChat;
	}),

	pendingChecklistItemCount: 0,
	chatChecklistItemObserver: observer(
		'chat.checklistItems.[].completedDate',
		async function () {
			const checkListItems = (await this?.chat?.get('checklistItems')) || [];
			if (!checkListItems || !checkListItems.length) return;
			const count = checkListItems.reduce((total, item) => {
				if (!item.completedDate) {
					if (!total) {
						return 1;
					} else {
						return total + 1;
					}
				} else {
					return total;
				}
			});

			this.set('pendingChecklistItemCount', count);
		},
	),

	showAccessControl: false,
	showNewRequestModal: false,
	showViewRequestModal: false,

	// Message Properties
	showChatMessageError: false,
	newChatMessage: null,
	showChatMessageActionsModal: false,
	messagesAreLoading: true,
	messageSearchText: '',
	showMessageFilter: false,

	showUsersModal: false,
	showAddCollaboratorModal: false,

	modelObserver: observer('model', function () {
		if (!this.model) return;

		const caseChats = this.chats.toArray();
		const initialChat = caseChats[0];
		this.set('chat', initialChat);
		this.getChatOrganizations();
	}),

	//* Custom Methods *//

	getChatOrganizations() {
		let caseChats = this.chats.toArray();

		let caseOrganizations = caseChats
			.filter((chat) => !!chat.get('organization'))
			.map((chat) => {
				return chat.get('organization');
			});

		if (caseOrganizations) {
			this.set('caseOrganizations', caseOrganizations);
		} else {
			this.set('caseOrganizations', []);
		}
	},

	reloadChat() {
		const chat = this.chat;
		chat?.reload();
	},

	selectChat(chatToSelect) {
		this.set('isLoadingChat', true);

		this.store.findRecord('chat', chatToSelect.get('id')).then((chat) => {
			this.set('chat', chat);
			this.set('isLoadingChat', false);
		});
		this.set('currentSubTab', 'Chat');
	},

	//* Viewport Properties
	inViewport: service(),
	loaderInViewPort: false,
	showLoader: computed('isLoadingMessages', 'loaderInViewPort', function () {
		return this.isLoadingMessages;
	}),

	didEnterViewPort() {
		if (this.chat?.get('chatMessages.length')) {
			this.set('loaderInViewPort', true);
			if (this.page || this?.meta?.total_pages) {
				this.fetchMoreChatMessages();
			}
		}
	},

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreCaseMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreChatMessages();
			}
		}, 500);
	},

	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	},

	didExitViewPort() {
		this.set('loaderInViewPort', false);
	},

	async handleIncomingMessage(newMessage) {
		if (
			!newMessage ||
			(await newMessage?.chat?.get('id')) != this?.chat?.get('id')
		)
			return;

		//* Check to see if the message is already in the list
		const message = this.chatMessages.find((msg) => {
			return newMessage.id == msg.id;
		});

		if (message) return;

		this.chatMessages.unshiftObject(newMessage);
		return;
	},

	//* Chat Messages Properties
	size: 50,
	page: 1,
	meta: null,
	isLoadingMessages: true,
	chatMessages: A([]),
	initialization: function () {
		this.pubnub.newChatMessageCallbacks.push((...args) => {
			this.handleIncomingMessage(...args);
		});
	}.on('init'),
	chatChanged: observer('chat', function () {
		this.set('isLoadingMessages', true);
		debounce(this, 'fetchChatMessages', 500);
	}),

	fetchChatMessages() {
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.set('page', 1);

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Clear the old chatMessages
				this.chatMessages.clear();

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	fetchMoreChatMessages() {
		if (
			!this.meta ||
			this.meta.total_pages <= this.page ||
			this.meta.total_results <= this.chatMessages.length
		)
			return;
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.incrementProperty('page');

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	reloadChatsModel() {
		const result = defer();
		this.store
			.query('chat', { caseId: this.theCase?.id, filter: { external: 'true' } })
			.then((res) => {
				result.resolve(res);
				this.set('model.chats', res.toArray());
				if (!this.chat && this.hasChat) {
					this.selectChat(this.model.chats[0]);
				}
			})
			.catch((err) => {
				result.reject(err);
			});

		return result.promise;
	},

	debounceReloadChat(t) {
		debounce(this, 'reloadChat', t != undefined ? t : 3000);
	},

	actions: {
		addCollaborator(data) {
			const caseId = this.get('theCase.id');
			const url = `${ENV.host}/cases/${caseId}/organizations`;

			return this.ajax
				.post(url, {
					contentType: 'application/json',
					data,
				})
				.then((res) => {
					//* Deep Clone the response to pass to the store
					//* to preserve the properties on the response since
					//* the store will alter the response object since we
					//* were passing a reference object.
					const payload = Object.assign({}, res);
					//* Push payload to store to update data
					this.store.pushPayload(payload);
					//* Refresh the chats model to display the newly added collaborator
					this.reloadChatsModel();
					this.getChatOrganizations();
				});
		},

		markChatNotificationsAsRead(result = defer()) {
			const chatNotifications =
				this.get('chat.chatNotifications').toArray() || [];
			const theCase = this.theCase;

			this.set('errors', []);

			for (let i = 0; i < chatNotifications.length; i++) {
				const chatNotification = chatNotifications[i];
				chatNotification.set('readAt', new Date());
				chatNotification.save().then(() => {
					if (theCase.get('newChatMessages') > 0) {
						theCase.decrementProperty('newChatMessages');
					}
				});
			}

			this.chat.calculateUnreads();

			// Heave the pending animation show for an extra second to make sure the user sees that the button did something.
			setTimeout(() => {
				result.resolve();
			}, 1000);

			return result.promise;
		},

		prepareChatMessage(result = defer()) {
			const chat = this.chat;
			const chatId = chat.id;
			const message = this.newMessage;

			result = result.promise
				? result
				: result.result
				? result.result
				: defer();

			this.send('sendChatMessage', result, chatId, message);
		},

		removeCollaborator() {
			const caseId = this.get('theCase.id');
			const orgId = this.get('chat.organizationId');

			const url = `${ENV.host}/cases/${caseId}/organizations/${orgId}`;

			return this.ajax.delete(url).then((res) => {
				//* Deep Clone the response to pass to the store
				//* to preserve the properties on the response since
				//* the store will alter the response object since we
				//* were passing a reference object.
				const payload = Object.assign({}, res);
				//* Push payload to store to update data
				this.store.pushPayload(payload);

				this.reloadChatsModel();
				this.getChatOrganizations();
			});
		},

		requestSuccess() {
			this.send('toggleShowNewRequestModal');
			this.notifications.success('Your request has been sent successfully!', {
				autoClear: true,
			});
			setTimeout(() => {
				this.chat.fetchFormResponses();
			}, 5000); // 5 seconds
		},

		selectSubTab(tab = '') {
			const subTabs = this.subTabs;
			const isValidTab = subTabs.includes(tab);

			if (isValidTab) {
				if (tab == 'Requests') {
					//* Go ahead and get the latest set of requests when going to this tab
					this.chat.fetchFormResponses();
				}
				this.set('currentSubTab', tab);
			}
		},

		sendChatMessage(result = defer(), chatId = 0, content = '') {
			result = result.promise
				? result
				: result.result
				? result.result
				: defer();

			if (chatId && content) {
				this.send('postChatMessage', chatId, content, result);

				result.promise
					.then((msg) => {
						this.chatMessages.unshiftObject(msg);
					})
					.catch((err) => {
						try {
							if (typeof result?.result?.reject == 'function') {
								result.result.reject(err);
							} else if (typeof result?.reject == 'function') {
								result.reject(err);
							}
						} catch (error) {
							//* We will capture the error for more tracking in log rocket
							captureException(error);
						}
					});
			} else {
				try {
					if (typeof result?.result?.reject == 'function') {
						result.result.reject();
					} else if (typeof result?.reject == 'function') {
						result.reject();
					}
				} catch (error) {
					//* We will capture the error for more tracking in log rocket
					captureException(error);
				}
			}
		},

		toggleShowAccessControl() {
			this.toggleProperty('showAccessControl');
		},

		toggleShowMessageActionsModal() {
			this.toggleProperty('showMessageActionsModal');
		},

		toggleShowNewRequestModal() {
			this.toggleProperty('showNewRequestModal');
		},

		toggleShowViewRequestModal(requestResponse) {
			if (requestResponse && !this.showViewRequestModal) {
				this.set('requestResponse', requestResponse);
			} else {
				this.set('requestResponse', null);
			}

			this.toggleProperty('showViewRequestModal');
		},

		toggleShowUsersModal() {
			this.toggleProperty('showUsersModal');
		},

		toggleWritingMessage() {
			this.toggleProperty('isWritingMessage');
		},

		validateNewMessage(result = defer()) {
			result = result.promise
				? result
				: result.result
				? result.result
				: defer();

			if (
				this.get('validations.attrs.newMessage.message') &&
				this.isWritingMessage
			) {
				this.set('showMessageError', true);
				return result.reject();
			} else {
				this.send('prepareChatMessage', result);
				return result.promise
					.then(() => {
						this.setProperties({
							isWritingMessage: false,
							newMessage: '',
							showMessageError: false,
						});
					})
					.catch(() => {
						this.set('showMessageError', true);
					});
			}
		},

		showLightboxImage(img) {
			this.set('lightboxImage', img);
		},
		postFile(formData, result) {
			formData.append('chat_id', this.get('chat.id'));

			const url = `${ENV.host}/chat_files`;
			const data = formData;

			this.ajax
				.post(url, {
					processData: false,
					contentType: false,
					data: data,
				})
				.then(() => {
					this.fetchChatMessages();
					return result.resolve();
				})
				.catch((response) => {
					return result.reject(Errors.mapResponseErrors(response));
				});
		},

		toggleShowAddCollaboratorModal() {
			this.toggleProperty('showAddCollaboratorModal');
		},

		updatePermissions() {
			let canChat = this.get('permissions.canChat');
			let showCaseStatus = this.get('permissions.showCaseStatus');
			let showClientName = this.get('permissions.showClientNames');
			const orgId = this.get('chat.organizationId');
			const caseId = this.get('theCase.id');

			return this.ajax
				.post(`${ENV.host}/cases/${caseId}/organizations`, {
					contentType: 'application/json',
					data: {
						organization_id: orgId,
						can_chat: canChat,
						show_case_status: showCaseStatus,
						show_client_names: showClientName,
					},
				})
				.then(() => {
					this.reloadChat();
				});
		},

		hideLightboxImage() {
			this.set('lightboxImage', null);
		},
		refreshChat() {
			this.debounceReloadChat();
		},
	},
});
