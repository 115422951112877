/** @format */

export default function blobToBase64(blob) {
	if (!blob) return new Promise((resolve) => resolve(null));

	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result.split(',')[1]);
		reader.readAsDataURL(blob);
	});
}
