/** @format */

import Route from '@ember/routing/route';

export default Route.extend({
	setupController(controller) {
		this._super(...arguments);

		// reset case filters
		this.controllerFor('app.firm.cases').send('clearFilters');
	},
});
