/** @format */

import Model, { attr } from '@ember-data/model';

export default Model.extend({
	chatId: attr('number'),
	content: attr('string'),
	senderName: attr('string'),
	recipientType: attr('string'),
	readableId: attr('string'),
	recipientChildId: attr('number'),
	createdAt: attr('date'),
	readAt: attr('date'),
	chatMessageId: attr('number'),
	type: attr('string'),
});
