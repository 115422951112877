/** @format */

import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import Errors from '../constants/errors';
import { inject as service } from '@ember/service';

export default Route.extend(UnauthenticatedRouteMixin, {
	routeIfAlreadyAuthenticated: ENV.routeAfterAuthentication,
	ajax: service(),
	loginService: service('login'),
	model(params) {
		return {
			token: params.token,
		};
	},

	actions: {
		resetPassword(token, password, result) {
			const url = ENV.host + '/reset_password/' + token;
			let data = {
				password: password,
			};
			this.ajax
				.post(url, {
					contentType: 'application/json',
					dataType: 'json',
					data: JSON.stringify(data),
				})
				.then((response) => {
					this.session
						.authenticate(
							'authenticator:oauth2',
							response['email_address'],
							password,
						)
						.then(() => {
							this.loginService.login();
						})
						.catch(() => {
							this.controller.set('errors', Errors.mapResponseErrors(response));
							result.reject();
						});
				})
				.catch((response) => {
					result.reject();
					this.controller.set('errors', Errors.mapResponseErrors(response));
				});
		},
	},
});
