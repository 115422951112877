import { set, action } from '@ember/object';
import { capitalize } from '@ember/string';
import { observer } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { observes } from '@ember-decorators/object';
import { tracked } from '@glimmer/tracking';

import moment from 'moment';
import { resolve, reject, defer } from 'rsvp';
import { validator, buildValidations } from 'ember-cp-validations';

import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import Errors from 'case-status/constants/errors';
import ENV from 'case-status/config/environment';

export default class AppFirmCaseMessagesController extends Controller {
	@service ajax;
	@service company;
	@service currentUser;
	@service notifications;
	@service permissions;
	@service thirdParty;

	@tracked activityActive = true;
	@tracked automationActive = false;
	@tracked checklistActive = false;
	@tracked currentActiveTab = 'messages';
	@tracked documentsActive = false;
	@tracked messagesActive = false;
	@tracked scheduledMessageActive = false;
	@tracked showAccountMenu = false;
	@tracked selectedSubNav = 'Messages';
	@tracked activityCheck = 0;
	@tracked isEditingStatus = false;
	@tracked isSavingStatus = false;
	@tracked newStatus = null;

	@tracked isPlacingOnHold = false;
	@tracked isEditingOnHoldExplanation = false;
	@tracked showClosingConfirmationModal = false;
	@tracked showRemoveHoldConfirmationModal = false;
	@tracked showRemoveConfirmationModal = false;
	@tracked showMessageActionsModal = false;
	@tracked isWritingMessage = false;
	@tracked isRemovingHold = false;
	@tracked showCloseCaseModal = false;
	@tracked showCloseCaseErrors = false;
	@tracked showRequestReviewModal = false;
	@tracked messageTemplateType = null;
	@tracked messageTemplateFilterString = null;
	@tracked newMessage = '';
	@tracked languages = [];
	@tracked shouldUpdateMessages = false;
	@tracked selectedDocumentSortOption = {
		label: 'Uploaded Date',
		value: 'sortableCreatedAt:desc',
	};

	host = ENV.host;
	errors = [];
	onHoldExplanation = this.theCase.onHoldExplanation;
	messageSearchText = '';
	showMessageFilter = false;
	showCaseSubnavModal = false;
	isSchedulingMessage = false;
	lightboxImage = null;
	showClientMessagingDisabled = false;
	downloadTextSuccess = false;
	downloadTextErrors = false;
	smsErrors = null;
	isCreatingNewAction = false;
	showESignUploadModal = false;

	get theCase() {
		return this.model?.theCase ? this.model?.theCase : {};
	}

	get statuses() {
		const caseTypeId = this.theCase?.get('caseType.id');
		const peekedStatuses = this.store.peekAll('case-status');
		if (peekedStatuses.length > 0) {
			return peekedStatuses.filter(
				(ps) => ps.caseType?.get('id') === caseTypeId,
			);
		}
		const networkStatuses = this.store
			.findAll('case-status')
			.then((statuses) => {
				return statuses.filter((ns) => ns.caseType?.get('id') === caseTypeId);
			});
		return networkStatuses || [];
	}

	get sortedStatuses() {
		return this.statuses.sortBy('number');
	}

	get sortedAttorneysOnCase() {
		if (this.theCase.attorneys) {
			return this.theCase.attorneys.sortBy('lastName');
		}
		return [];
	}

	get sortedClients() {
		return this.theCase.clients.sortBy('lastName');
	}

	get sortedParalegalsOnCase() {
		if (this.theCase.paralegals) {
			return this.theCase.paralegals.sortBy('lastName');
		}
		return [];
	}

	get messagesToSort() {
		const messages = this.theCase.get('messages');
		return messages ? messages : [];
	}

	get sortedMessages() {
		return this.messagesToSort.sortBy('sentAt').reverse();
	}

	get filteredMessages() {
		return this.sortedMessages.filter((message) => {
			return (
				!message.get('scheduledMessageSendDate') ||
				moment(message.get('scheduledMessageSendDate')).isBefore(moment())
			);
		});
	}

	get unopenedClientCount() {
		return this.theCase.clients.filter((client) => {
			return client.lastOpened === null;
		}).length;
	}

	get currentStatus() {
		return this.theCase.caseStatus;
	}

	set currentStatus(newStatus) {
		this.theCase.caseStatus = newStatus;
	}

	get hasUnreadMessages() {
		return this.unreadCount > 0 ? 'alert' : '';
	}

	get unreadCount() {
		return this.theCase.newMessages;
	}

	get isOnHold() {
		return this.isPlacingOnHold || this.theCase.onHold;
	}

	get caseActions() {
		//* Dynamic holdAction dependant if case is or isn't on hold
		const caseLabel = dynamicCaseLabel([this.company]);
		const holdAction = `Place ${caseLabel} on hold`;
		const closeAction = `Close ${caseLabel}`;
		const removeAction = `Remove ${caseLabel}`;
		const actionsArray = [];

		//TODO Apply Permissions
		if (!this.isOnHold) {
			actionsArray.push(holdAction);
		}
		if (this.theCase.activatedDate && !this.theCase.closed) {
			actionsArray.push(closeAction);
		}

		actionsArray.push(removeAction);
		return actionsArray;
	}

	get subNavOptions() {
		const options = [
			{ text: 'Messages', value: 'Messages' },
			{ text: 'Documents', value: 'documents' },
			{ text: 'Automation', value: 'automation' },
			{ text: 'Checklist', value: 'checklist' },
		];
		if (this.showScheduledMessageTab)
			options.push({ text: 'Scheduled Messages', value: 'scheduledMessage' });
		if (this.theCase.newMessages)
			options[0][
				'tag'
			] = `<span class="new-messages-alert"><img src="img/new-messages.svg" class="new-messages-icon" alt="new message icon"></span>`;

		return options;
	}

	get selectedTargetLanguage() {
		const languages = this.languages || [];
		const searchLanguage = this.theCase.targetLanguage
			? this.theCase.targetLanguage
			: 'en';

		let targetLanguage = languages.find((lan) =>
			lan.language.includes(String(searchLanguage).toLowerCase()),
		);

		targetLanguage = targetLanguage
			? targetLanguage
			: languages.find((lan) => lan.language == 'en');
		return targetLanguage;
	}

	set selectedTargetLanguage(value) {
		const newValue = value.language || value;
		this.theCase.targetLanguage = newValue;
		const languages = this.languages || [];
		let targetLanguage = languages.find((lan) =>
			lan.language.includes(String(this.theCase.targetLanguage).toLowerCase()),
		);

		// set targetLanguage.language code (str) instead of whole object
		this.theCase.targetLanguage = targetLanguage?.language || 'en';
	}

	get selectedFirmLanguage() {
		const languages = this.languages || [];
		let firmLanguage = languages.find((lan) =>
			lan.language.includes(
				this.currentUser.user.firm
					.get('firmSettings.firmSettings.language')
					.toLowerCase(),
			),
		);
		return firmLanguage;
	}

	get isParalegal() {
		return this.currentUser.user.constructor.modelName === 'paralegal';
	}

	get isAttorney() {
		return this.currentUser.user.constructor.modelName === 'attorney';
	}

	get smsToggleDescription() {
		const smsUsers = this.theCase.clients.filter((client) => {
			return (
				!client.deviceEndpoint || !client.deviceToken || !client.deviceType
			);
		});
		const desc = `If turned on, ${smsUsers.length} client${
			smsUsers.length != 1 ? 's' : ''
		} will receive notifications about ${dynamicCaseLabel([
			this.company,
		])} updates and messages as text messages.`;
		return desc;
	}

	get showScheduledMessageTab() {
		return this.theCase?.unsentScheduledMessagesCount > 0;
	}

	get caseDeleted() {
		if (this.model && this.theCase && this.theCase.deletedAt) {
			this.notifications.warning(
				`This ${dynamicCaseLabel([
					this.company,
				])} has been removed in Case Status. Click here to open in Case Status to restore it.`,
				{
					onClick: this.openInCaseStatus,
				},
			);
			return true;
		}
		return false;
	}

	constructor() {
		super(...arguments);
		//* Call init on the thirdParty service so it can handle it's checks
		this.thirdParty.init();
		this.set('permissions.case', this.theCase.caseSettings);
	}

	onHoldExplanationObserver = observer(
		'theCase.onHoldExplanation',
		function () {
			if (this.model) {
				this.onHoldExplanation = this.theCase?.get('onHoldExplanation');
			}
		},
	);

	nameMatcher(option, searchTerm) {
		return option.get('name').indexOf(searchTerm);
	}

	userObserver = observer('currentUser.user', function () {
		if (this.currentUser.user !== null) {
			//Init user props
			this.sendAsPrimary = this.currentUser.user.sendAsPrimary;
		}
	});

	sendAsPrimaryObserver = observer('sendAsPrimary', function () {
		//Only update and save the currentUser if sendAsPrimary changes
		if (this.sendAsPrimary !== this.currentUser.user.sendAsPrimary) {
			this.currentUser.user.sendAsPrimary = this.sendAsPrimary;
			this.currentUser.user.save();
		}
	});

	@action
	async sendReviewRequest() {
		let templates = this.store.peekAll('message-template');
		templates = templates?.length
			? templates
			: await this.store.findAll('message-template');

		const reviewTemplates = templates
			?.toArray()
			?.filter(({ templateType }) => templateType == 'review');

		if (reviewTemplates.length === 1) {
			this.newMessage = reviewTemplates[0].content;
			this.messageTemplateType = reviewTemplates[0].templateType;
			this.send('sendOwnMessage');
		} else {
			this.messageTemplateFilterString = 'review';
			this.send('sendOwnMessage');

			next(() => {
				if (
					document
						.getElementById('modal-add-message-template')
						.classList.contains('hidden')
				) {
					document
						.getElementById('modal-add-message-template')
						.classList.remove('hidden');
				}
			});
		}
	}

	@action
	saveCaseModel() {
		return this.theCase
			.save()
			.then((theCase) => {
				this.errors = null;
				//* Toggle is back and forth to reload the case message component
				this.shouldUpdateMessages = true;
				return resolve(theCase);
			})
			.catch((response) => {
				const mappedErrors = Errors.mapResponseErrors(response) || [];
				this.notifications.error('Error: ' + mappedErrors.join(' '));
				this.errors = mappedErrors;
				return reject(response);
			})
			.finally(() => {
				next(() => {
					this.shouldUpdateMessages = false;
				});
			});
	}

	@action
	async eSignCallback() {
		await this.theCase.reload();
		this.model.files.update();
	}

	@action
	async refreshCase() {
		await this.theCase.reload();
	}

	@action
	onBackgroundClick(evt) {
		if (
			this.showClientActionsModal &&
			evt.target.id !== 'client-actions' &&
			evt.target.offsetParent &&
			evt.target.offsetParent.className.indexOf('client-actions-modal') === -1
		) {
			this.showClientActionsModal = false;
		}

		if (
			this.showMessageActionsModal &&
			evt.target.id !== 'message-actions' &&
			evt.target.offsetParent &&
			evt.target.offsetParent.className.indexOf('message-actions-modal') === -1
		) {
			this.showMessageActionsModal = false;
		}
	}

	@action
	decreaseNewMessagesCount() {
		const unreadCount = this.theCase.newMessages;
		if (unreadCount > 0) {
			set(this, 'model.theCase.newMessages', unreadCount - 1);
		}
	}

	@action
	postFile(formData, result) {
		formData.append('case_id', this.theCase.id);

		const url = `${ENV.host}/files`;
		const data = formData;
		const model = this.args.model;

		this.ajax
			.post(url, {
				processData: false,
				contentType: false,
				data: data,
			})
			.then(() => {
				model.theCase.reload();
				return result.resolve();
			})
			.catch((response) => {
				return result.reject(Errors.mapResponseErrors(response));
			});
	}

	@action
	toggleShowMessageActionsModal() {
		this.showMessageActionsModal = !this.showMessageActionsModal;
	}

	@action
	toggleWritingMessage() {
		this.isWritingMessage = !this.isWritingMessage;
		this.showMessageError = false;
		this.newMessage = '';
	}

	@action
	validateNewMessage(result) {
		if (this.validations.attrs.newMessage.message && this.isWritingMessage) {
			this.showMessageError = true;
			return result.reject();
		} else {
			this.send('sendMessage', { content: this.newMessage, result });
			return result.promise
				.then(() => {
					this.toggleWritingMessage();
				})
				.catch(() => {
					this.showMessageError = true;
				});
		}
	}

	@action
	showLightboxImage(img) {
		this.lightboxImage = img;
	}

	@action
	hideLightboxImage() {
		this.lightboxImage = null;
	}

	@action
	toggleShowMessageFilter() {
		this.messageSearchText = '';
		this.showMessageFilter = !this.showMessageFilter;
	}

	@action
	toggleActiveCardSection(section) {
		const cards = [
			'activityActive',
			'automationActive',
			'documentsActive',
			'messagesActive',
			'checklistActive',
			'scheduledMessageActive',
		];
		// use messages as the default card
		let activeSection = section + 'Active';
		activeSection = cards.includes(activeSection)
			? activeSection
			: 'messagesActive';

		// deactivate all cards
		cards.forEach((card) => (this[card] = false));
		this[activeSection] = true;

		// sets section to title case for mobile users and close modal
		this.selectedSubNav = section.charAt(0).toUpperCase() + section.slice(1);
		this.showCaseSubnavModal = !this.showCaseSubnavModal;
		this.activityCheck++;
	}

	@action
	selectDocumentSort(option) {
		this.selectedDocumentSortOption = option;
	}

	@action
	deleteScheduledMessage(message, result) {
		const routeResult = defer();
		this.send('deleteMessage', message, routeResult);
		routeResult.promise.then(() => {
			this.send('toggleActiveCardSection', 'messages');

			this.theCase.set(
				'unsentScheduledMessagesCount',
				this.theCase.get('unsentScheduledMessagesCount') - 1,
			);

			result.resolve();
		});
	}

	@action
	editScheduledMessage(message, content, selectedTime, result) {
		this.send('editMessage', message, content, selectedTime, result);
	}

	@action
	updateSmsSettings() {
		this.wantsSms = !this.wantsSms;
		const wantsSms = this.wantsSms;
		this.theCase.wantsSms = wantsSms;
		this.saveCaseModel()
			.then(() => {})
			.catch(() => {
				this.wantsSms = !this.wantsSms;
				this.theModel.case.rollbackAttributes();
			});
	}

	@action
	toggleESignModal() {}

	@action
	toggleScheduleModal() {
		this.isSchedulingMessage = !this.isSchedulingMessage;
	}

	@action
	sendScheduledMessage(message, day, result) {
		this.send('sendMessage', {
			content: message,
			scheduledMessageSendDate: day,
			result,
		});

		result.promise.then(() => {
			this.toggleWritingMessage();
			this.showMessageError = false;
			this.theCase.unsentScheduledMessagesCount =
				this.theCase.get('unsentScheduledMessagesCount') + 1;
		});
	}

	@action
	selectMessageTemplate(template) {
		this.newMessage = template;
		document
			.getElementById('modal-add-message-template')
			.classList.add('hidden');
	}

	@action
	sendWelcomeMessage() {
		this.shouldSendWelcomeMessage = true;
		this.shouldUpdateMessages = true;
		next(() => {
			this.shouldUpdateMessages = false;
		});
	}

	@action
	sendOwnMessage() {
		this.send('toggleActiveCardSection', 'messages');
		set(this, 'isWritingMessage', true);
	}

	@action
	sendDocumentUpload() {
		this.send('toggleActiveCardSection', 'messages');
		// Prevent state where if isWritingMessage is never toggled off (i.e switching tabs)
		// the showMessageActionsModal wont show due to isWritingMessage being toggled
		set(this, 'isWritingMessage', false);
		this.showMessageActionsModal = !this.showMessageActionsModal;
	}

	@action
	openInCaseStatus() {
		const caseId = this.theCase.id;
		const caseUrl = `${this.host}/app/firm/cases/${caseId}`;

		window.open(caseUrl, '');
		return false;
	}

	@action
	showIsEditingStatus() {
		this.newStatus = this.currentStatus;
		this.isEditingStatus = !this.isEditingStatus;
		this.sortedStatuses; // For some reason it only sorts on the second call, so we waste the first call here 🤷🏻‍♂️
	}

	@action
	increaseStatusStage() {
		const ind = this.sortedStatuses.findIndex((status) => {
			return status.get('number') == this.newStatus.get('number');
		});
		if (this.isEditingStatus && ind + 1 < this.sortedStatuses.length) {
			this.newStatus = this.sortedStatuses[ind + 1];
		}
	}

	@action
	decreaseStatusStage() {
		const ind = this.sortedStatuses.findIndex((status) => {
			return status.get('number') == this.newStatus.get('number');
		});
		if (this.isEditingStatus && ind > 0) {
			this.newStatus = this.sortedStatuses[ind - 1];
		}
	}

	@action
	saveNewStatus() {
		this.currentStatus = this.newStatus;
		this.isSavingStatus = true;
		this.saveCaseModel()
			.then(() => {
				this.isEditingStatus = !this.isEditingStatus;
				if (this.currentUser.user.firm.messagingOff) {
					this.notifications.warning(
						'No "Status Update" message was sent due to firm messaging currently being disabled',
						{ autoClear: true, clearDuration: 7000 },
					);
				}
			})
			.finally(() => {
				this.isSavingStatus = false;
			});
	}

	@action
	cancelNewStatus() {
		this.isEditingStatus = !this.isEditingStatus;
		this.newStatus = this.currentStatus;
	}

	@action
	selectCaseAction(selectedCaseAction) {
		//TODO Setup flows
		const caseLabel = dynamicCaseLabel([this.company]);
		switch (selectedCaseAction) {
			case `Place ${caseLabel} on hold`:
				//* Show the modal to enter a hold message
				this.isEditingOnHoldExplanation = true;
				this.isPlacingOnHold = true;
				break;
			case 'Remove hold':
				this.showRemoveHoldConfirmationModal = true;
				this.isRemovingHold = true;
				break;
			case `Close ${caseLabel}`:
				//* Show confirmation modal
				this.isClosing = true;
				this.showClosingConfirmationModal = true;
				break;
			case `Remove ${caseLabel}`:
				//* Show confirmation modal
				this.isRemoving = true;
				this.showRemoveConfirmationModal = true;
				break;
			default:
				//* Do absolutely nothing
				break;
		}
	}

	@action
	editOnHoldExplanation() {
		this.isEditingOnHoldExplanation = true;
	}

	@action
	cancelEditOnHoldExplanation() {
		this.isEditingOnHoldExplanation = false;
		set(this, 'isPlacingOnHold', false);
	}

	@action
	saveOnHoldExplanation() {
		if (this?.onHoldExplanation) {
			this.theCase.onHoldExplanation = this.onHoldExplanation;
			set(this, 'model.theCase.onHold', true);
			this.theCase.set('isOnHold', true);
			return this.saveCaseModel()
				.then(() => {
					this.isEditingOnHoldExplanation = false;
					this.showOnHoldError = false;
				})
				.catch(() => {
					this.showOnHoldError = true;
				});
		} else {
			this.onHoldErrorMessage = 'On Hold Explanation is a required field.';
			this.showOnHoldError = true;
			return reject();
		}
	}

	@action
	processConfirmation() {
		const action = this.isRemovingHold
			? 'removeHold'
			: this.isClosing
			? 'closeCase'
			: this.isRemoving
			? 'removeCase'
			: null;

		if (!action) return;
		const result = defer();

		switch (action) {
			case 'removeHold':
				set(this, 'model.theCase.onHold', false);
				this.theCase.onHoldExplanation = null;
				this.isPlacingOnHold = false;
				return this.saveCaseModel()
					.then(() => {
						this.isEditingOnHoldExplanation = false;
						this.showOnHoldError = false;
						this.showRemoveHoldConfirmationModal = false;
					})
					.catch(() => {
						this.showOnHoldError = true;
					});
			case 'removeCase':
				return this.send('deleteCase', result);
			case 'closeCase':
				break;
			default:
				return;
		}
	}

	@action
	closeConfirmationModal() {
		this.showRemoveHoldConfirmationModal = false;
		this.showClosingConfirmationModal = false;
		this.showRemoveConfirmationModal = false;
		this.isClosing = false;
		this.isRemoving = false;
		this.isRemovingHold = false;
	}

	@action
	reopenCase() {
		this.theCase.closed = false;
		return this.saveCaseModel()
			.then(() => {
				this.isClosed = false;
			})
			.catch(() => {
				this.theCase.closed = true;
				this.notifications.error(
					`Unable to reopen ${dynamicCaseLabel([
						this.company,
					])}. Please try again later`,
					{
						autoClear: true,
						clearDuration: 5000,
					},
				);
			});
	}

	@action
	async deleteCase() {
		try {
			await this.theCase.destroyRecord();
			this.notifications.success(
				`${capitalize(
					dynamicCaseLabel([this.company]),
				)} has been removed successfully! Please wait a moment for the window to reload.`,
				{
					autoClear: true,
					clearDuration: 7000,
					cssClasses: 'notification--success',
				},
			);
			this.send('closeConfirmationModal');
			this.showDeleteCaseErrors = true;
			// reload after delay so that read-replica db has time to catch up
			setTimeout(() => window.location.reload(), 7000);
		} catch {
			this.notifications.error(
				`There was an error removing this ${dynamicCaseLabel([this.company])}`,
				{
					autoClear: true,
					clearDuration: 7000,
				},
			);
		}
	}

	@action
	closeCase(deleteAutomations, result) {
		this.theCase.closed = true;
		return this.saveCaseModel()
			.then(() => {
				this.send('closeConfirmationModal');
				this.isClosed = true;
				if (deleteAutomations) {
					this.theCase?.get('actions').forEach((action) => {
						action.destroyRecord();
					});
				}

				if (result) {
					result.resolve();
				}

				next(() => {
					this.send('toggleShowRequestReviewModal');
				});
			})
			.catch(() => {
				this.showCloseCaseErrors = true;
				this.theCase.closed = false;
				this.notifications.error(
					`There was an error closing this ${dynamicCaseLabel([this.company])}`,
					{
						autoClear: true,
						clearDuration: 7000,
					},
				);
			});
	}

	@action
	closeCaseAndRequestReview(deleteAutomations) {
		this.send('requestReview');
		this.send('closeCase', deleteAutomations);
	}

	@action
	toggleShowAccountMenu() {
		this.showAccountMenu = !this.showAccountMenu;
	}

	@action
	toggleShowRequestReviewModal() {
		this.showRequestReviewModal = !this.showRequestReviewModal;
	}

	@action
	shouldRequestReview() {
		this.showRequestReviewModal = false;
		this.send('sendReviewRequest');
	}
}

const Validations = buildValidations({
	newMessage: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Message',
	}),
});

// apply the Validations mixin to our Controller
Object.assign(AppFirmCaseMessagesController.prototype, Validations);
