/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { sort, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import Errors from 'case-status/constants/errors';
import RSVP, { resolve } from 'rsvp';
import { next } from '@ember/runloop';

@classic
export default class MobileClientAppointmentsTabComponent extends Component {
	@service store;
	@service notifications;
	@service permissions;
	@service currentUser;

	@tracked isLoading = true;
	@tracked appointments = A([]);
	@tracked treatments = A([]);

	@reads('model.id') caseId;

	appointmentSortProperty = ['appointments.appointmentDate'];
	@sort('appointments', 'appointmentSortProperty') sortedAppointments;

	treatmentSortProperty = ['treatments.appointmentDate'];
	@sort('treatments', 'treatmentSortProperty') sortedTreatments;

	showPendingAppointments = false;

	get isClient() {
		return this.currentUser.user.type == 'client';
	}

	get appointmentCards() {
		let cards = [];
		let appointments = this.sortedAppointments;
		appointments.forEach((appointment) => {
			cards.push({
				appointment,
				editing: false,
			});
		});
		return cards;
	}

	get treatmentCards() {
		let cards = [];
		let treatments = this.sortedTreatments;
		treatments.forEach((treatment) => {
			cards.push({
				treatment,
				editing: false,
			});
		});
		return cards;
	}

	get upcomingAppointment() {
		// Returns Object
		//* Need to sort the appointments by date and grab the top result
		let nextAppointment = null;

		//* Make sure the pending appointments are an array and have a length, then
		//* make sure that the first appointment card's relative appointment is not
		//* passed.
		if (
			Array.isArray(this.pendingAppointments) &&
			this.pendingAppointments.length &&
			!this.pendingAppointments[0].appointment.isPast
		) {
			nextAppointment = this.pendingAppointments[0];
		}

		//* Return the set upcoming appointment or null if none
		//* Return the appointment property for readability sake in the hbs file
		return nextAppointment?.appointment
			? nextAppointment.appointment
			: nextAppointment;
	}

	get pastAppointments() {
		// Returns Array
		return this.appointmentCards
			.filter((card) => {
				return card.appointment.isPast;
			})
			.reverse();
	}

	get pendingAppointments() {
		// Returns Array
		return this.appointmentCards.filter((card) => {
			return !card.appointment.isPast;
		});
	}

	get pastTreatments() {
		return this.treatmentCards
			.filter((card) => {
				return card.treatment.feelingRating;
			})
			.reverse(); //todo Should not be reversed?
	}

	get pendingTreatments() {
		return this.treatmentCards.filter((card) => {
			return !card.treatment.feelingRating; //todo Should be reversed?
		});
	}

	get recentTreatment() {
		//TODO Need to still filter to only index 0 IF all treatments are logged
		//* Need to sort the treatments by date and grab the top result
		let recentTreatment = null;

		if (Array.isArray(this.treatmentCards) && this.treatmentCards.length) {
			recentTreatment = this.treatmentCards[0];
		}

		//* Return the set upcoming treatment or null if none
		//* Return the treatment property for readability sake in the hbs file
		return recentTreatment;
	}

	selectedAppointmentType = null;

	get appointmentTypeOptions() {
		const appointmentTypes = ['Meeting', 'Virtual'];

		if (this?.permissions?.case?.treatment_log) {
			appointmentTypes.push('Medical');
		}

		return appointmentTypes;
	}

	get componentName() {
		if (this.isClient) {
			return 'appointment-medical-form';
		} else {
			const selectedAppointment = this.selectedAppointment?.appointment
				? this.selectedAppointment?.appointment
				: this.selectedAppointment;

			switch (selectedAppointment?.type) {
				case 'meeting':
					return 'appointment-form';
				case 'virtual':
					return 'appointment-virtual-form';
				case 'medical':
					return 'appointment-medical-form';
				default:
					return 'null';
			}
		}
	}

	async willInsertElement() {
		await this.fetchData();

		//! Can't use peek anymore on treatmentTypes since there are treatmentTypes being included on appointments/treatments responses thus causing only those to be returned and making the remaining treatmentTypes be missing
		const treatmentTypes = await this.store.findAll('treatmentType', {
			reload: true,
		});

		this.set('treatmentTypes', treatmentTypes);

		// Call super last
		super.willInsertElement(...arguments);
	}

	async fetchData() {
		// Set Loading state to true
		this.set('isLoading', true);

		try {
			// Query for appointments and treatments
			const appointments = this.store.query('appointment', {
				case_id: this.caseId,
			});
			let treatments = A([]);

			if (this.get('permissions.case.treatment_log')) {
				treatments = this.store.query('treatment', { case_id: this.caseId });
			}

			//* Quicker to run all queries in parallel and just await for all to finish
			await RSVP.all([appointments, treatments]);

			// Clear the model in preparation for new data ONLY if data was successfully pulled
			this.appointments.clear();
			this.treatments.clear();

			// Push new data objects into model (Requires data set be native array)
			this.appointments.pushObjects(appointments.toArray());
			this.treatments.pushObjects(treatments.toArray());
		} catch (error) {
			// Show error notif with mapped error
			this.notifications.error(Errors.mapResponseErrors(error), {
				canClose: true,
				autoClear: true,
				clearDuration: 5000,
			});
		}

		// Set Loading state to false regardless if an error occurred or not
		this.set('isLoading', false);
	}

	@action
	togglePendingAppointments() {
		this.toggleProperty('showPendingAppointments');
	}

	@action
	togglePendingTreatments() {
		this.toggleProperty('showPendingTreatments');
	}

	@action
	viewTreatment(treatment) {
		this.set('selectedTreatment', treatment);
		this.set('showViewTreatment', true);
	}

	@action
	closeModals() {
		this.set('showViewTreatment', false);
		this.set('showViewAppointmentModal', false);
		this.set('showNewTreatmentModal', false);
		this.set('showNewAppointmentModal', false);
		this.set('showEditTreatment', false);
		this.set('selectedTreatment', null);
	}

	@action
	closeEditModals() {
		this.set('showEditTreatmentModal', false);
		this.set('showEditAppointmentModal', false);
		this.set('showNewTreatmentModal', false);
		this.set('showNewAppointmentModal', false);
	}

	@action
	logNewTreatment() {
		const newTreatment = this.store.createRecord('treatment', {
			case: this.store.peekRecord('case', this.caseId),
		});

		this.set('selectedTreatment', newTreatment);
		this.set('showNewTreatmentModal', true);

		return resolve();
	}

	@action
	addNewAppointment(selectedAppointmentType) {
		const newAppointment = this.store.createRecord('appointment', {
			case: this.store.peekRecord('case', this.caseId),
			type: String(selectedAppointmentType).toLowerCase(),
		});

		this.set('selectedAppointment', newAppointment);
		this.set('showNewAppointmentModal', true);
	}

	@action
	editTreatment() {
		this.set('showEditTreatmentModal', true);
	}

	@action
	editAppointment() {
		this.set('showEditAppointmentModal', true);
	}

	@action
	logTreatment(treatment) {
		this.set('selectedTreatment', treatment);
		this.editTreatment();
	}

	@action
	viewAppointment(selectedAppointment) {
		this.set('selectedAppointment', selectedAppointment);
		this.set('showViewAppointmentModal', true);
	}

	async onAfterSave() {
		// *Reload the data and then on next runloop close the modals
		await this.fetchData();
		next(() => {
			this.closeEditModals();
		});
	}
	async onAfterDelete() {
		// *Reload the data and then on next runloop close the modals
		await this.fetchData();
		next(() => {
			this.closeEditModals();
		});
	}
}
