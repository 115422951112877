/** @format */

import Component from '@ember/component';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Component.extend({
	company: service(),
	actions: {
		nextButtonPressed(clientsToAdd) {
			this.nextStep(clientsToAdd);
			return RSVP.resolve();
		},
	},
});
