/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	company: service(),

	beforeModel() {
		this._super(...arguments);
		this.send('gatekeeper', 'settings_checklist_templates', 'settings.firm');
	},

	setupController(controller) {
		controller.fetchChecklistTemplates();
	},

	resetController(isExiting) {
		if (isExiting) {
			this.store.unloadAll('checklist-template');
			this.store.unloadAll('checklist-template-item');
		}
	},
});
