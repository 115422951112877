import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { defer, resolve } from 'rsvp';
import Errors from 'case-status/constants/errors';
import Changeset from 'ember-changeset';
import { isFunction } from 'case-status/constants/data-types';
import {
	defaultNotifOptions,
	errorNotifOptions,
} from 'case-status/constants/notif-options';
import { action } from '@ember/object';
import { videoFileTypes } from '../constants/file-uploads';

export default class PAStage extends Component {
	@tracked isDeleting = false;
	@tracked isEditing = false;
	@tracked videoUploaded = null;
	@service ajax;
	@service company;
	@service notifications;
	@service store;
	@service permissions;

	@tracked isDeletingVideo = false;

	constructor() {
		super(...arguments);
		this.stageChangeset = new Changeset(this?.args?.stage);
		this.practiceArea = this?.args?.practiceArea;
		this.reloadParent = isFunction(this?.args?.reloadParent)
			? this.args.reloadParent
			: (args) => void args;
		this.setParentIsEditing = isFunction(this?.args?.setParentIsEditing)
			? this.args.setParentIsEditing
			: (args) => void args;

		this.videoUploaded = this.stageChangeset.get('video');
	}

	get hasIntegration() {
		return this.company.info.usesIntegration;
	}

	get acceptedFileTypes() {
		return videoFileTypes;
	}

	get videoName() {
		return this.videoUploaded ? this.videoUploaded.get('filename') : null;
	}

	@action
	showDeleteModal() {
		this.isDeleting = true;
	}

	@action
	hideDeleteModal() {
		this.isDeleting = false;
	}

	@action
	startEditingStage() {
		this.isEditing = true;
		this.setParentIsEditing(true);
	}

	@action
	deleteVideo() {
		this.isDeletingVideo = true;
	}

	@action
	cancelDeleteVideo() {
		this.isDeletingVideo = false;
	}

	@action
	hideDeleteVideoModal() {
		this.isDeletingVideo = false;
	}

	@action
	async confirmDeleteVideo() {
		this.notifications.clearAll();
		const video = this.store.peekRecord('video', this.args.stage.videoId);

		if (!video) {
			this.notifications.error(
				`Video is not available for ${this.args.stage.name} `,
			);
			return;
		}
		try {
			await video.destroyRecord();
			this.notifications.success(
				`Deleted video successfully!`,
				defaultNotifOptions,
			);
			this.ajax.set('noCache', true);
			await this.reloadParent(this.args.stage.number);
			this.ajax.set('noCache', false);
			this.isDeletingVideo = false;
			this.videoUploaded = null;
		} catch (error) {
			const errorMsg = Errors.mapResponseErrors(error);
			this.notifications.error(
				`Error deleting video: ${errorMsg}`,
				errorNotifOptions,
			);
		}
	}

	@action
	async cancelEditing() {
		try {
			await this.stageChangeset.rollback();
		} catch (error) {
			this.reloadParent();
		}
		this.isEditing = false;
		this.setParentIsEditing(false);
		this.isDeletingVideo = false;
	}

	@action
	async saveEdits() {
		this.notifications.clearAll();
		return this.stageChangeset
			.save()
			.then(() => {
				this.notifications.success(
					`Updated "${this.args.stage.name}" successfully!`,
					defaultNotifOptions,
				);
				this.isEditing = false;
				this.setParentIsEditing(false);
			})
			.catch((error) => {
				const errorMsg = Errors.mapResponseErrors(error);

				this.notifications.error(
					`Error trying to update stage: ${errorMsg}`,
					errorNotifOptions,
				);
			});
	}

	@action
	async deleteStage() {
		this.notifications.clearAll();
		const stageName = this.args.stage.name;
		const stageNumber = this.args.stage.number;
		const result = defer();

		await this.args.stage
			.destroyRecord()
			.then(async (res) => {
				this.notifications.success(
					`Deleted "${stageName}" successfully!`,
					defaultNotifOptions,
				);
				this.hideDeleteModal();

				this.ajax.set('noCache', true);
				await this.reloadParent(stageNumber);
				this.ajax.set('noCache', false);

				return resolve(res);
			})
			.catch((error) => {
				const errorMsg = Errors.mapResponseErrors(error);

				this.notifications.error(
					`Error trying to delete stage: ${errorMsg}`,
					errorNotifOptions,
				);

				return result.reject(error);
			});
	}

	@action
	showFilePicker() {
		document.querySelector('#dz-uploader').click();
	}

	@action
	async fileUploadCb(fileId) {
		this.notifications.clearAll();
		this.stageChangeset.set('videoId', fileId);
		await this.stageChangeset
			.save()
			.then(() => {
				this.notifications.success(
					`Updated "${this.args.stage.name}" successfully!`,
					defaultNotifOptions,
				);
				this.isEditing = false;
				this.setParentIsEditing(false);
				// Video record should have been added to store by file-uploader2
				// So we should be able to use peek here
				this.videoUploaded = this.store.peekRecord('video', fileId);
			})
			.catch((error) => {
				const errorMsg = Errors.mapResponseErrors(error);

				this.notifications.error(
					`Error trying to update stage: ${errorMsg}`,
					errorNotifOptions,
				);
			});
	}
}
