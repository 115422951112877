/** @format */

import ApplicationAdapter from './application';
import ENV from 'case-status/config/environment';

export default ApplicationAdapter.extend({
	host: ENV.host,
	urlForCreateRecord(modelName) {
		if (modelName === 'appointment') {
			return `${this.host}/appointments`;
		}
	},
	urlForQuery(query) {
		if (query.case_id) {
			return `${this.host}/cases/${query.case_id}/appointments`;
		}
	},
	urlForUpdateRecord(id) {
		return `${this.host}/appointments/${id}`;
	},
});
