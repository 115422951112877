/** @format */

import Component from '@ember/component';
import Changeset from 'ember-changeset';
import Errors from '../constants/errors';

export default Component.extend({
	Changeset,
	tagName: 'col-xs-12',

	init(...args) {
		this._super(...args);
		this.changeset = new this.Changeset(this.request);
	},

	actions: {
		submit(changeset) {
			return changeset
				.save()
				.then(() => {
					this.close();
				})
				.catch((errors) => {
					this.set('errors', Errors.mapResponseErrors(errors));
				});
		},
		rollback(changeset) {
			changeset.rollback();
			return this.close();
		},
	},
});
