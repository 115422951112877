/** @format */

import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

Storage.reopenClass({
	initialState() {
		return {
			myCases: true,
			wantsMobile: true,
		};
	},
});

export default Storage;
