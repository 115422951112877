/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	preventNetworkRequest: false,
	urlForFindRecord(id) {
		return `${this.host}/v2/videos/${id}`;
	},
	urlForDeleteRecord(id) {
		return `${this.host}/v2/videos/${id}`;
	},
});
