/* eslint-disable ember/no-classic-classes */
//case, message, content, approved

/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	content: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'recommendedResponse',
	}),
});

export default Model.extend(Validations, {
	approved: attr('boolean'),
	category: attr('string'),
	content: attr('string'),
	finalContent: attr('string'),
});
