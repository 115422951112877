/** @format */

import Controller from '@ember/controller';
import { validator, buildValidations } from 'ember-cp-validations';
import Errors from '../../../../constants/errors';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import { getNames } from 'ember-i18n-iso-countries';

const Countries = Object.entries(getNames('en')).map(([key, value]) => {
	return {
		ISO2: key,
		name: value,
		label: `(${key}) ${value}`,
	};
});

const Validations = buildValidations({
	memberEmail: [
		validator('presence', {
			presence: true,
			message: Errors.presenceError,
			description: 'Member Email',
		}),
		validator('format', { type: 'email' }),
	],

	orgName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Organization Name',
	}),

	memberFirstName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Member First Name',
	}),

	memberLastName: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Member Last Name',
	}),
	orgDescriptor: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Organization Descriptor',
	}),
	country: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Country',
	}),
});

export default Controller.extend(Validations, {
	//* Services
	currentUser: service(),
	session: service(),

	//* Member Details
	firstName: null,
	lastName: null,
	email: null,
	memberPhoneNumber: null,

	//* Org Details
	orgName: null,
	orgAbout: null,
	orgDescriptor: null,
	orgPhoneNumber: null,
	country: null,

	//* Data Lists
	countryList: Countries,

	//* Error States
	errors: null,
	showFirstNameError: false,
	showLastNameError: false,
	showEmailError: false,
	showOrgNameError: false,
	showOrgDescriptorError: false,
	showOrgCountryError: false,

	//* Success States
	success: false,
	successMessage: 'Organization Created!',

	//* Input States
	inputsDisabled: false,

	//* Class Methods
	resetErrors() {
		this.setProperties({
			errors: [],
			showOrgNameError: false,
			showOrgDescriptorError: false,
			showOrgCountryError: false,
			showEmailErrors: false,
			showFirstNameErrors: false,
			showLastNameErrors: false,
		});

		return this.errors;
	},

	resetOrg() {
		this.resetErrors();

		// Reset Success
		this.setProperties({
			success: false,
		});

		// Reset Org Properties
		this.setProperties({
			memberFirstName: null,
			memberLastName: null,
			memberEmail: null,
			memberPhoneNumber: null,
			orgName: null,
			orgPhoneNumber: null,
			orgAbout: null,
			orgDescriptor: null,
			country: null,
		});

		// Reset Others
		this.setProperties({
			inputsDisabled: false,
		});
	},

	testValidations() {
		const errors = this.resetErrors();
		const orgNameValidations = this.get('validations.attrs.orgName');
		const orgDescriptorValidations = this.get(
			'validations.attrs.orgDescriptor',
		);
		const orgCountryValidations = this.get('validations.attrs.country');
		const emailValidations = this.get('validations.attrs.memberEmail');
		const firstNameValidations = this.get('validations.attrs.memberFirstName');
		const lastNameValidations = this.get('validations.attrs.memberLastName');

		//* Test First Name
		if (!firstNameValidations.isValid) {
			errors.push(firstNameValidations.message);
			this.set('showFirstNameErrors', true);
		}

		//* Test Last Name
		if (!lastNameValidations.isValid) {
			errors.push(lastNameValidations.message);
			this.set('showLastNameErrors', true);
		}

		//* Test Email
		if (!emailValidations.isValid) {
			errors.push(emailValidations.message);
			this.set('showEmailErrors', true);
		}

		//* Test Org Name
		if (!orgNameValidations.isValid) {
			errors.push(orgNameValidations.message);
			this.set('showOrgNameErrors', true);
		}

		//* Test Org Descriptor
		if (!orgDescriptorValidations.isValid) {
			errors.push(orgDescriptorValidations.message);
			this.set('showOrgDescriptorErrors', true);
		}

		if (!orgCountryValidations.isValid) {
			errors.push(orgCountryValidations.message);
			this.set('showOrgCountryError', true);
		}

		return errors.length === 0;
	},

	actions: {
		resetOrg() {
			this.resetOrg();
		},

		selectCountry(selection) {
			this.set('country', selection);
		},

		validateNewOrg() {
			this.resetErrors();
			const result = defer();
			const validationsPassed = this.testValidations();

			const newOrg = {
				org_name: this.orgName,
				org_phone_number: this.orgPhoneNumber,
				org_about: this.orgAbout,
				org_descriptor: this.orgDescriptor,
				phone_number_region: this.get('country.ISO2') || '',
				last_name: this.memberLastName,
				first_name: this.memberFirstName,
				email_address: this.memberEmail,
				cell_phone: this.memberPhoneNumber || '',
			};

			if (validationsPassed) {
				this.send('createNewOrg', { newOrg, result });

				result.promise
					.then(() => {
						this.setProperties({
							success: true,
							inputsDisabled: true,
						});
					})
					.catch((err) => {
						//Internal route so show the raw server error
						this.set('errors', Errors.mapResponseErrors(err));
					});
			} else {
				result.reject();
			}

			return result.promise;
		},
	},
});
