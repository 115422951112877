/** @format */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ESignRoute extends Route {
	@service store;
	currentSigner;
	uuid;
	isLoading = false;
	confirmation;
	documentError;

	async model(params) {
		this.isLoading = true;
		try {
			// fetch signed document with uuid
			const signedDoc = await this.store.queryRecord('signedDocument', {
				uuid: params.uuid,
			});
			// set currentSigner to top priority signer that hasn't signed yet
			let currentSigner = null;
			let order = 100;
			signedDoc.documentSignatories.forEach((ds) => {
				if (!ds.signedAt && ds.order < order) {
					currentSigner = ds;
					order = ds.order;
				}
			});
			this.currentSigner = currentSigner;
			// Set uuid for post signature
			this.uuid = params.uuid;
			this.isLoading = false;
			return signedDoc;
		} catch (e) {
			this.documentError = true;
			this.confirmation = true;
			this.isLoading = false;
		}
	}

	setupController(controller, ...args) {
		super.setupController(controller, ...args);
		controller.currentSigner = this.currentSigner;
		controller.uuid = this.uuid;
		controller.isLoading = this.isLoading;
		controller.confirmation = this.confirmation;
		controller.documentError = this.documentError;
	}
}
