/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LogoutRoute extends Route {
	@service currentUser;

	beforeModel() {
		super.beforeModel(...arguments);
		this.currentUser.logout();
	}
}
