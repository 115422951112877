/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';

export default Component.extend({
	attributeBindings: ['id', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames:
		'typeform-file-upload row full-width col-xs-12 margin-bottom',
	id: alias('model.id'),
	'data-test-id': 'component',

	init() {
		this._super(...arguments);
		this.handleFileAdd = this.handleFileAdd.bind(this);
	},

	didInsertElement() {
		this._super(...arguments);
		this.element
			.querySelector('.file-upload')
			.addEventListener('change', this.handleFileAdd);
	},

	willDestroyElement() {
		this._super(...arguments);
		this.element
			.querySelector('.file-upload')
			.removeEventListener('change', this.handleFileAdd);
	},

	handleFileAdd(event) {
		let files = event.target.files;
		if (files.length > 0) {
			let file = files[0];
			// Create a fileClass object similar to what is expected by the action
			let fileClass = {
				blob: file,
				queue: {
					files: Array.from(files),
				},
			};
			this.onfileadd(fileClass);
		}
	},
});
