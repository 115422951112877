/** @format */

import { Promise } from 'rsvp';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { dynamicCaseLabel } from '../helpers/dynamic-case-label';

import DS from 'ember-data';

export default Component.extend({
	classNames: ['live-feed-item'],
	company: service(),

	type: computed('item', function () {
		const type = this.get('item.type');

		switch (type) {
			case 'message_sent':
			case 'message_received':
				return 'message';
			//Use the client icon for nps
			case 'nps_update':
				return 'client_added';
			default:
				return type;
		}
	}),

	showCaseLink: computed('item', function () {
		const itemCaseId = this.get('item.case.id');
		return itemCaseId !== null;
	}),

	itemString: computed(
		'item.{client.name,sender.name,case.primaryAttorney}',
		function () {
			const type = this.get('item.type');
			const client = this.get('item.client.name');
			const sender = this.get('item.sender.name');
			const itemCase = this.get('item.case.readableId');

			let string = null;
			let promise = null;
			switch (type) {
				case 'message_sent':
					string = `<span class="staff">${sender}</span> sent a message on the ${dynamicCaseLabel(
						[this.company],
					)} `;
					break;
				case 'message_received':
					string = `<span class="staff">${sender}</span> received a message on the ${dynamicCaseLabel(
						[this.company],
					)} `;
					break;
				case 'case_added':
					string = `<span class="staff">${sender}</span> added the ${dynamicCaseLabel(
						[this.company],
					)} `;
					break;
				case 'case_closed':
					string = `<span class="staff">${sender}</span> closed the ${dynamicCaseLabel(
						[this.company],
					)} `;
					break;
				case 'case_info_changed':
					string = `<span class="staff">${sender}</span> changed the ${dynamicCaseLabel(
						[this.company],
					)} information for the ${dynamicCaseLabel([this.company])} `;
					break;
				case 'case_on_hold':
					string = `<span class="staff">${sender}</span> put the ${dynamicCaseLabel(
						[this.company],
					)} ${itemCase} on hold`;
					break;
				case 'case_status_changed':
					string = `<span class="staff">${sender}</span> changed the status of the ${dynamicCaseLabel(
						[this.company],
					)} `;
					break;
				case 'client_added':
					string = `<span class="staff">${sender}</span> added the client <span class="client">${client}</span>`;
					break;
				case 'cases_imported':
					string = `<span class="staff">${sender}</span> imported ${dynamicCaseLabel(
						[this.company, true],
					)}`;
					break;
				case 'nps_update':
					promise = this.get('item.case.primaryAttorney').then((attorney) => {
						const name = attorney.get('name');
						return `<span class="staff">${name}</span> received feedback from <span class="client">${sender}</span> on the ${dynamicCaseLabel(
							[this.company],
						)}`;
					});
					break;
			}
			//Since we need to asychronously fetch the primary attorney name, everything needs to be a promise
			if (!promise) {
				promise = new Promise((resolve) => {
					resolve(string);
				});
			}
			return DS.PromiseObject.create({ promise });
		},
	),
});
