/** @format */

import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { A } from '@ember/array';

export default Service.extend({
	currentUser: service(),
	store: service(),
	type: computed('currentUser.user.{firm,organization}', function () {
		const isFirm = this.get('currentUser.user.firm.id');
		const isOrg = this.get('currentUser.user.organization.id');

		if (isFirm) {
			return 'firm';
		} else if (isOrg) {
			return 'organization';
		} else {
			return null;
		}
	}),

	info: computed('type', function () {
		return this.get(`currentUser.user.${this.type}`);
	}),

	users: computed(
		'type',
		'members',
		'attorneys',
		'paralegals',
		'usersLoaded',
		function () {
			let attorneys,
				paralegals = [];

			//* Fetch the users if they haven't already been fetched
			if (!this.usersFetched) {
				this.fetchUsers();
				return [];
			}

			if (this.type === 'organization') {
				return this.members ? this.members.toArray() : [];
			} else if (this.type === 'firm') {
				attorneys = this.attorneys ? this.attorneys.toArray() : [];
				paralegals = this.paralegals ? this.paralegals.toArray() : [];

				return [...attorneys, ...paralegals];
			}
		},
	),

	usersFetched: false,
	usersLoaded: computed(
		'membersLoaded',
		'attorneysLoaded',
		'paralegalsLoaded',
		'type',
		function () {
			if (this.type === 'organization') {
				return this.membersLoaded;
			} else if (this.type === 'firm') {
				return this.attorneysLoaded && this.paralegalsLoaded;
			}
		},
	),

	fetchUsers() {
		this.set('usersFetched', true);
		//* set a fetchedAt time to be able to assess if a new fetch is warranted
		this.set('usersFetchedAt', new Date());

		if (this.type === 'organization') {
			this.store.findAll('member').then((members) => {
				this.set('users', members);
				this.set('membersLoaded', true);
			});
		} else if (this.type === 'firm') {
			this.set('attorneys', A([]));
			this.set('paralegals', A([]));

			this.store.findAll('attorney').then((attorneys) => {
				this.set('attorneys', attorneys);
				this.set('attorneysLoaded', true);
			});

			this.store.findAll('paralegal').then((paralegals) => {
				this.set('paralegals', paralegals);
				this.set('paralegalsLoaded', true);
			});
		}
	},
});
