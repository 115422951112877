/** @format */

import $ from 'jquery';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import RSVP from 'rsvp';
import ENV from 'case-status/config/environment';

export default Component.extend({
	customBranding: service('custom-branding'),
	company: service(),
	notifications: service(),
	currentUser: service(),
	thirdParty: service(),

	hideESign: false,
	hideReview: false,
	hideDocumentUpload: false,
	fileChunkingEnabled: false,
	classNames: 'message-actions-modal',

	actions: {
		exportMessages() {
			const result = RSVP.defer();
			this.exportMessages(result);

			result.promise.then(() => {
				this.onRequestClose();
			});
		},
		showFilePicker() {
			if (this.fileChunkingEnabled) {
				$('#dz-uploader').click();
			} else {
				$('#file_picker').click();
			}
			this.onRequestClose();
		},
		triggerESign() {
			if (this.showESignModal && typeof this.showESignModal === 'function') {
				this.showESignModal();
				this.onRequestClose();
			} else {
				this.notifications.error(
					'An error has occurred. Please try again later.',
				);
			}
		},
		openInCaseStatus() {
			if (
				this.openInCaseStatus &&
				typeof this.openInCaseStatus === 'function'
			) {
				return this.openInCaseStatus();
			} else if (this.thirdParty.isIFrame && this.caseId) {
				const caseId = this.caseId;
				const caseUrl = `${ENV.host}/app/firm/cases/${caseId}`;

				window.open(caseUrl, '');
				return false;
			}
		},
		onRequestClose() {
			this.onRequestClose();
		},
		updateSmsSettings(...args) {
			this.updateSmsSettings(...args);
		},
	},
});
