/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import moment from 'moment';
import { reject } from 'rsvp';

import { MESSAGING_OFF_APPOINTMENTS_ERROR } from '../constants/errors';

export default Component.extend({
	Changeset,
	tagName: '',
	currentUser: service(),
	store: service(),
	permissions: service(),
	hourDate: null,
	minuteDate: null,
	monthDate: null,
	amOrPm: null,
	deleteState: false,
	dateChanged: false,

	ajax: service(),

	init(...args) {
		this._super(...args);
		this.timezone =
			this.currentUser.get('user.firm.timezone') || 'America/New_York';
		this.changeset = new this.Changeset(this.treatment);
		this.changeset.on('afterRollback', () => {
			if (this.onAfterRollback) {
				this.onAfterRollback();
			}
		});
		let localizeDateTime = moment.tz(
			this.get('treatment.appointmentDate'),
			this.timezone,
		);
		let currentMoment = moment();
		let minute = currentMoment.minute();

		if (this.isNewTreatment) {
			this.set('hourDate', currentMoment.format('hh'));
			if (minute < 30) {
				this.set('minuteDate', '30');
			} else {
				this.set('hourDate', currentMoment.add(1, 'hour').format('hh'));
				this.set('minuteDate', '00');
			}
			this.set('amOrPm', currentMoment.format('a'));
			this.set('monthDate', currentMoment.format('MM/DD/YYYY'));
			this.set(
				'changeset.confirmationEnabled',
				this.appointmentConfirmationDefault,
			);
			this.set(
				'changeset.confirmationDaysBefore',
				this.permissions?.case?.appointment_confirmation_days_before,
			);
			this.set(
				'changeset.internalNotificationDaysBefore',
				this.permissions?.case?.appointment_internal_notification_days_before,
			);
		} else {
			this.set('hourDate', localizeDateTime.format('hh'));
			this.set('minuteDate', localizeDateTime.format('mm'));
			this.set('amOrPm', localizeDateTime.format('a'));
			this.set('monthDate', localizeDateTime.format('MM/DD/YYYY'));
		}
	},

	hourOptions: computed(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	minuteOptions: computed(() => {
		return Array.from(Array(60).keys()).map((x) => {
			let str = x.toString(10);
			if (str < 10) {
				str = '0' + str;
			}
			return str;
		});
	}),
	amOrPmOptions: computed(() => ['AM', 'PM']),

	isNewTreatment: computed('treatment.createdAt', function () {
		return !this.get('treatment.createdAt');
	}),

	isTreatment: computed('changeset', function () {
		return false;
	}),

	internalNotificationEnabled: computed(
		'changeset.internalNotificationDaysBefore',
		'changeset.confirmationEnabled',
		function () {
			return (
				this.changeset.get('confirmationEnabled') &&
				!!this.changeset.get('internalNotificationDaysBefore') &&
				Number(this.changeset.get('internalNotificationDaysBefore')) > 0
			);
		},
	),

	showCalendarToggle: computed(
		'changeset.id',
		'currentUser.user.{googleCalendarId,isGoogleOauthSet,wantsIcsInvites,firm.firmSettings.firmSettings.ics_invites}',
		function () {
			if (
				!this.changeset.get('id') &&
				((this.currentUser.user.googleCalendarId &&
					this.currentUser.user.isGoogleOauthSet) ||
					(this.currentUser.user.wantsIcsInvites &&
						this.currentUser.user.firm?.get(
							'firmSettings.firmSettings.ics_invites',
						)))
			) {
				return true;
			} else if (
				!this.changeset.get('id') &&
				!this.currentUser.user.googleCalendarId &&
				!this.currentUser.user.isGoogleOauthSet
			) {
				let showCalendarToggle = false;
				const caseAttorneys = this.changeset.get('case.attorneys').toArray();
				const caseParalegals = this.changeset.get('case.paralegals').toArray();
				const caseWorkers = [...caseAttorneys, ...caseParalegals];

				for (let i = 0; i < caseWorkers.length; i++) {
					const caseWorker = caseWorkers[i];
					if (caseWorker.googleCalendarId && caseWorker.isGoogleOauthSet) {
						showCalendarToggle = true;
						break;
					}
				}

				return showCalendarToggle;
			} else {
				return false;
			}
		},
	),

	showCalendarReauthMessage: computed(
		'changeset.id',
		'currentUser.user.{googleCalendarId,isGoogleOauthSet,wantsIcsInvites,firm.firmSettings.firmSettings.ics_invites}',
		function () {
			const isNew = !this.changeset.get('id');
			const isGcalUnauthed =
				this.currentUser.user.googleCalendarId &&
				!this.currentUser.user.isGoogleOauthSet;
			const isIcsUnauthed =
				this.currentUser.user.wantsIcsInvites ||
				!this.currentUser.user.firm?.get(
					'firmSettings.firmSettings.ics_invites',
				) ||
				!this.currentUser.user.wantsIcsInvites ||
				this.currentUser.user.firm?.get(
					'firmSettings.firmSettings.ics_invites',
				);
			if (isNew && (isGcalUnauthed || isIcsUnauthed)) {
				return true;
			} else {
				return false;
			}
		},
	),

	reauthMessageType: computed(
		'showCalendarReauthMessage',
		'currentUser.user.{googleCalendarId,isGoogleOauthSet,wantsIcsInvites,firm.firmSettings.firmSettings.ics_invites}',
		function () {
			const user = this.currentUser?.get('user');
			const firm = user?.get('firm');

			if (
				user.wantsIcsInvites &&
				!firm?.get('firmSettings.firmSettings.ics_invites')
			) {
				return 'ics1';
			} else if (!user.wantsIcsInvites && user.googleCalendarId) {
				return 'gcal1';
			} else if (
				!user.wantsIcsInvites &&
				!user.googleCalendarId &&
				firm?.get('firmSettings.firmSettings.ics_invites')
			) {
				return 'ics2';
			} else {
				return null;
			}
		},
	),

	showCalendarEditHelperText: computed(
		'changset.id',
		'currentUser.user.{wantsIcsInvites,googleCalendarId}',
		function () {
			const isNew = !this.changeset?.get('id');
			const user = this.currentUser?.get('user');

			if (!isNew && (user.wantsIcsInvites || user.googleCalendarId)) {
				return true;
			} else {
				return false;
			}
		},
	),

	appointmentConfirmationEnabled: computed(
		'permissions.case.enable_appointment_confirmation',
		function () {
			return !!this.permissions?.case?.enable_appointment_confirmation;
		},
	),

	appointmentConfirmationDefault: computed(
		'permissions.case.appointment_confirmation_enabled_types',
		function () {
			const confirmationTypes = (
				this.permissions?.case?.appointment_confirmation_enabled_types || []
			).map((type) => type.toLowerCase());
			return confirmationTypes.includes('virtual');
		},
	),

	appointmentInternalConfirmationEnabled: computed(
		'permissions.case.appointment_internal_notification_enabled_types',
		function () {
			const internalConfTypes = (
				this.permissions?.case
					?.appointment_internal_notification_enabled_types || []
			).map((type) => type.toLowerCase());
			return internalConfTypes.includes('virtual');
		},
	),

	validate() {
		let isValid = true;
		let errors = null;

		if (!this.get('changeset.appointmentTitle')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.appointmentTitle = {
				message: 'You must include an appointment title',
			};
		}

		if (!this.get('changeset.description')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.description = { message: 'You must include a description' };
		}

		if (!this.get('changeset.address')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.address = { message: 'You must include a url' };
		}

		if (this.currentUser.user.firm.messagingOff) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.description = {
				message: MESSAGING_OFF_APPOINTMENTS_ERROR,
			};
		}

		const today = moment(new Date());
		const appointmentDate = this.changeset.get('appointmentDate');
		if (today > appointmentDate) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.server = {
				message: 'Appointments can not be created in the past',
			};
		}
		if (this.changeset.get('confirmationEnabled')) {
			const confirmDaysBefore = this.changeset.get('confirmationDaysBefore');
			const staffDaysBefore = this.changeset.get(
				'internalNotificationDaysBefore',
			);
			if (
				this.changeset.get('internalNotificationEnabled') &&
				confirmDaysBefore &&
				staffDaysBefore &&
				staffDaysBefore > confirmDaysBefore
			) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'Staff cannot be alerted before the Client Confirmation Request is sent. Please change the Staff Alert to be less days before the Client Confirmation Request.',
				};
			}
			if (today.add(confirmDaysBefore, 'd') > appointmentDate) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'The confirmation date must me set to tomorrow at the soonest',
				};
			}
			if (confirmDaysBefore < 1) {
				isValid = false;
				if (!errors) {
					errors = {};
				}
				errors.confRequest = {
					message:
						'The confirmation request must be at least one day before the scheduled appointment.',
				};
			}
		}
		return {
			isValid,
			errors,
		};
	},

	handleSuccessfulSave(changeset) {
		if (this.onAfterSave) {
			this.onAfterSave(changeset);
		} else {
			this.set('didSave', true);
			this.set('isSaving', false);
		}
		this.updateShowNewForm(false); // Ensure form is closed
	},

	isSaving: false,
	didSave: false,

	actions: {
		onDateChangeHandler(date) {
			this.set('monthDate', moment(date).format('MM/DD/YYYY'));
			this.set('dateChanged', true);
		},

		onHourChangeHandler(hour) {
			let hourString = hour.toString();
			if (hour < 10) {
				hourString = '0' + hourString;
			}
			this.set('hourDate', hourString);
			this.set('dateChanged', true);
		},

		onMinuteChangeHandler(minute) {
			this.set('minuteDate', minute.toString());
			this.set('dateChanged', true);
		},

		onAmpmChangeHandler(amOrPm) {
			this.set('amOrPm', amOrPm);
			this.set('dateChanged', true);
		},

		submit(changeset) {
			if (this.isSaving || this.didSave) return reject();
			this.set('isSaving', true);
			if (
				!this.isNewTreatment &&
				!changeset.get('isDirty') &&
				!this.dateChanged
			) {
				if (this.onAfterRollback) {
					this.onAfterRollback();
				}
				this.set('isSaving', false);
				return reject();
			}
			const timeString = `${this.monthDate} ${this.hourDate}:${
				this.minuteDate
			} ${this.amOrPm.toUpperCase()}`;

			const apptDateTime = moment
				.tz(timeString, 'MM/DD/YYYY hh:mm a', this.timezone)
				.utc()
				.toDate();
			this.set('changeset.appointmentDate', apptDateTime);
			this.set('changeset.type', 'virtual');

			if (!changeset.get('confirmationEnabled')) {
				this.set('changeset.confirmationDaysBefore', null);
				this.set('changeset.internalNotificationDaysBefore', null);
			}
			this.set(
				'changeset.internalNotificationEnabled',
				this.internalNotificationEnabled,
			);

			const validations = this.validate();
			if (validations.isValid) {
				return changeset
					.save()
					.then((changeset) => {
						this.handleSuccessfulSave(changeset);
					})
					.catch((error) => {
						if (error.isForbidden) {
							console.error('Forbidden Error:', error.message);
							this.handleSuccessfulSave(changeset); // Treat as successful save
						} else {
							this.set('isSaving', false);
							this.set('errors', {
								server: {
									message: 'There was an error saving this in our system.',
								},
							});
						}
					});
			} else {
				this.set('isSaving', false);
				this.set('errors', validations.errors);
			}
		},

		rollback(changeset) {
			let currentMoment = moment();
			let hour = moment(this.treatment.appointmentDate).format('hh');
			let amPm = moment(this.treatment.appointmentDate).format('a');
			let minute;

			if (
				currentMoment.isBefore(moment(this.treatment.appointmentDate), 'minute')
			) {
				minute = currentMoment.minute() < 30 ? '30' : '00';
				// If the minute is '00', add one hour to the current moment and update hour and AM/PM
				if (minute === '00') {
					hour = currentMoment.add(1, 'hour').format('hh');
					amPm = currentMoment.format('a');
				}
			} else {
				minute = moment(this.treatment.appointmentDate).format('mm');
			}

			// Set the hour, minute, month date, and AM/PM on the component
			this.set('hourDate', hour);
			this.set('minuteDate', minute);
			this.set(
				'monthDate',
				moment(this.treatment.appointmentDate).format('MM/DD/YYYY'),
			);
			this.set('amOrPm', amPm);
			return changeset.rollback();
		},

		delete() {
			this.treatment.deleteRecord();
			this.ajax.set('noCache', true);

			return this.treatment.save().then(() => {
				if (this.onAfterSave) {
					this.onAfterSave();
				}
				if (this.onAfterDelete) {
					this.onAfterDelete();
				}
			});
		},

		toggleWantsCalendarEvents() {
			this.toggleProperty('changeset.wantsCalendarEvents');
		},

		toggleAppointmentConf() {
			this.toggleProperty('changeset.confirmationEnabled');
		},

		updateConfirmationDaysBefore(days) {
			this.set('changeset.confirmationDaysBefore', days);
		},

		updateInternalNotificationDaysBefore(days) {
			this.set('changeset.internalNotificationDaysBefore', days);
		},
	},
});
