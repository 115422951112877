/** @format */
import { A } from '@ember/array';
import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { capitalize } from '@ember/string';
import { storageFor } from 'ember-local-storage';
import { tracked } from '@glimmer/tracking';

import { defer, reject, resolve } from 'rsvp';

import Errors from 'case-status/constants/errors';
import ENV from 'case-status/config/environment';
import NotifOptions from 'case-status/constants/notif-options';
import { dynamicAppointmentLabel } from 'case-status/helpers/dynamic-appointment-label';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default class AppFirmCaseController extends Controller {
	@controller('app.firm.case.info') infoController;

	@service ajax;
	@service company;
	@service('current-user') currentUser;
	@service global;
	@service mobile;
	@service notifications;
	@service permissions;
	@service router;
	@service session;
	@service thirdParty;
	@service chromeExtension;
	@tracked showCloseCaseModal = false;
	@tracked showRequestReviewModal = false;
	@tracked isClosed;
	@tracked showAccountMenu = false;
	@tracked showInboxModal = false;
	@tracked currentTab = 'messages'; //default to "messages"
	@tracked caseSyncSucceeded;

	preferences = storageFor('preferences');

	caseTimers = A([]);

	get theCase() {
		return this.model?.theCase ? this.model?.theCase : {};
	}

	get unreadCount() {
		this.chromeExtension.sendUnreadCount(this.theCase.newMessages);
		return this.theCase.newMessages;
	}

	get caseNavBarOptions() {
		return {
			activeTab: this.currentTab,
			availableTabs: this.availableTabs,
			actions: {
				navigate: this.selectTab,
			},
		};
	}

	get availableTabs() {
		return {
			messages: {
				allow: true,
				icon: 'envelope',
				name: 'Case Activity',
				prefix: 'far', // fontawesome regular style (opposed to solid)
			},
			appointments: {
				allow: this?.currentUser?.user?.get(
					'firm.firmSettings.firmSettings.appointments_tab_visible',
				),
				icon: 'calendar-days',
				name: capitalize(
					dynamicAppointmentLabel([this.permissions.case, true]),
				),
				prefix: 'far', // fontawesome regular style (opposed to solid)
			},
			internal: {
				allow: true,
				icon: 'bars',
				name: 'Internal Messaging',
				notifications: this.theCase.newInternalMessages,
			},
			collaboration: {
				allow: true,
				icon: 'eraser',
				name: 'Share Status',
				notifications: this.theCase.newChatMessages,
			},
		};
	}

	get activeCard() {
		return this.thirdParty.activeCard;
	}

	set activeCard(card) {
		this.thirdParty.activeCard = card;
	}

	constructor() {
		super(...arguments);
	}

	checkOnboarding(deferredAction, ...deferredActionParams) {
		const activatedDate = this.theCase.activatedDate;
		const doNotShowOnboardingModal = this.doNotShowOnboardingModal;
		const isDeferred = this.deferredAction;

		// If they have already selected do not show again, then just break out now
		if (doNotShowOnboardingModal) return false;

		// If the case is not onboarding (ie activated), then go ahead and break out now
		if (activatedDate) return false;

		// If there is already a deferred action queued, break out;
		if (isDeferred) return false;

		this.showOnboardingModal = !this.showOnboardingModal;

		this.deferredAction = deferredAction;
		this.deferredActionParams = deferredActionParams;

		return true;
	}

	setRefreshTimer() {
		const refreshTimerId = setInterval(() => {
			this.runRefresh();
		}, 300000); // 5min
		this.caseTimers.push(refreshTimerId);
	}

	runRefresh() {
		let shouldPause = false;
		const casePauseTimerConditions = this.global.casePauseTimerConditions || {};

		//* Loop through casePauseTimerConditions to see if any of the editingConditions are true
		//* If an editingCondition is true, set shouldPause to true and break from the loop early.
		for (const key in casePauseTimerConditions) {
			//* If shouldPause is already set to true, break out of loop early
			if (shouldPause) {
				break;
			}
			if (Object.hasOwnProperty.call(casePauseTimerConditions, key)) {
				const editingCondition = casePauseTimerConditions[key];
				if (editingCondition === true) {
					//* If editing condition is true, then set shouldPause to true
					shouldPause = true;
					//* Break out of loop early to save time and resources
					break;
				}
			}
		}
		if (!shouldPause) {
			this.theCase.reload();
		}
	}

	setCaseTimers() {
		this.setRefreshTimer();
	}

	cancelCaseTimers() {
		this.caseTimers.forEach((id) => {
			clearTimeout(id);
			clearInterval(id);
		});
	}

	saveCaseModel() {
		return this.theCase
			.save()
			.then((theCase) => {
				this.errors = null;
				return resolve(theCase);
			})
			.catch((response) => {
				const mappedErrors = Errors.mapResponseErrors(response) || [];
				this.notifications.error('Error: ' + mappedErrors.join(' '));
				this.errors = mappedErrors;
				return reject(response);
			});
	}

	@action
	postChatMessage(result = defer(), chatId = 0, content = '') {
		if (chatId && content) {
			this.send('postChatMessage', chatId, content, result);
		} else {
			result.reject();
		}
	}

	@action
	toggleOnHold() {
		if (this.checkOnboarding('toggleOnHold')) return;

		const result = defer();
		if (this.infoController.isOnHold && this.theCase.onHold) {
			this.infoController.set('model.theCase.onHold', false);
			this.infoController.set('model.theCase.onHoldExplanation', null);
			this.infoController
				.saveCaseModel()
				.then(() => {
					this.infoController.set('showOnHoldError', false);
					this.infoController.set('isOnHold', false);
					this.isOnHold = false;
					result.resolve();
				})
				.catch(() => {
					this.infoController.set('showOnHoldError', true);
					this.infoController.set('model.theCase.onHold', true);
					this.infoController.set('isOnHold', true);
					result.reject();
				});
		} else if (this.infoController && this.infoController.isOnHold) {
			this.infoController.send('cancelEditOnHoldExplanation');
			this.send('cancelEditOnHoldExplanation');
			result.resolve();
		} else {
			this.infoController.set('isOnHold', true);
			this.infoController.send('editOnHoldExplanation');
			this.send('editOnHoldExplanation');
			result.resolve();
		}
		return result.promise;
	}

	@action
	editOnHoldExplanation() {
		this.infoController.set(
			'onHoldExplanation',
			this.theCase.onHoldExplanation,
		);
		this.infoController.set('isEditingOnHoldExplanation', true);
		this.infoController.set('showOnHoldError', false);
	}

	@action
	toggleShowCloseCaseModal() {
		const isModalOpen = this.showCloseCaseModal;
		const isOnboarding = isModalOpen
			? false
			: this.checkOnboarding('toggleShowCloseCaseModal');

		if (isOnboarding) return false;
		this.showCloseCaseModal = !this.showCloseCaseModal;
		this.showCloseCaseErrors = false;
	}

	@action
	toggleShowRequestReviewModal() {
		this.showRequestReviewModal = !this.showRequestReviewModal;
	}

	@action
	selectTab(tabName) {
		//* Extra catch to prevent inaccessible tabs from being selected
		if (!this.availableTabs[tabName].allow) {
			//* Show an Error Notif so the user doesn't think the button isn't doing anything or that the app is broken/frozen
			this.notifications.error(
				'The selected tab is not available.',
				NotifOptions.error,
			);
			//* return to prevent tab from being updated
			return;
		}
		//* Set the currentTab to the targetTabName
		this.currentTab = tabName;
		this.router.transitionTo(`app.firm.case.${tabName}`, this.theCase.id);
	}

	@action
	reopenCase() {
		this.theCase.closed = false;
		return this.saveCaseModel()
			.then(() => {
				this.isClosed = false;
				this.infoController.set('isClosed', false);
			})
			.catch(() => {
				this.theCase.closed = true;
				this.notifications.error(
					`Unable to reopen ${dynamicCaseLabel([
						this.company,
					])}. Please try again later`,
					{
						autoClear: true,
						clearDuration: 5000,
					},
				);
			});
	}

	@action
	async restoreCase() {
		const url = `${ENV.host}/restore_case/${this.theCase.id}`;
		this.ajax.set('noCache', true);
		try {
			await this.ajax.patch(url);
			this.notifications.success(
				`${capitalize(
					dynamicCaseLabel([this.company]),
				)} has been restored successfully! Please wait a moment for the window to reload.`,
				{
					autoClear: true,
					clearDuration: 8000,
					cssClasses: 'notification--success',
				},
			);
			// reload after delay so that read-replica db has time to catch up
			setTimeout(() => window.location.reload(), 8000);
		} catch {
			this.notifications.error(
				`Unable to restore ${dynamicCaseLabel([
					this.company,
				])}. Please try again later`,
				{
					autoClear: true,
					clearDuration: 5000,
				},
			);
		}
	}

	@action
	closeCase(deleteAutomations, result) {
		this.model.theCase.closed = true;
		this.saveCaseModel()
			.then(() => {
				this.send('toggleShowCloseCaseModal');
				this.isClosed = true;
				this.infoController.set('isClosed', true);

				if (deleteAutomations) {
					this.theCase.actions.forEach((action) => {
						action.destroyRecord();
					});
				}

				if (result) {
					result.resolve();
				}

				next(() => {
					this.send('toggleShowRequestReviewModal');
				});
			})
			.catch((err) => {
				this.showCloseCaseErrors = true;
				this.model.theCase.closed = false;
				if (result) {
					result.reject(err);
				}
			});
	}

	@action
	shouldRequestReview() {
		this.showRequestReviewModal = false;
		this.infoController.send('sendReviewRequest');
	}

	@action
	requestCaseSync() {
		this.caseSyncRequested = true;
		const integrationId = this.theCase.integrationId;

		if (integrationId) {
			const url = `${ENV.host}/cases/import/case/${integrationId}`;

			return this.ajax
				.post(url)
				.then(() => {
					this.notifications.info(
						'Sync request successfully sent.',
						NotifOptions.info,
					);
					this.caseSyncSucceeded = true;
				})
				.catch((err) => {
					this.notifications.error(
						Errors.mapResponseErrors(err),
						NotifOptions.error,
					);
					this.caseSyncRequested = false;
				});
		}
	}

	@action
	refreshPage() {
		window.location.reload();
	}
}
