/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject } from '@ember/service';
import Table from 'ember-light-table';
import clickableRow from '../mixins/component/clickable-row';

export default Component.extend(clickableRow, {
	currentUser: inject('current-user'),

	columns: computed(function () {
		return [
			{
				label: ``,
				valuePath: 'isSelected',
				width: '35px',
				cellComponent: 'cell-select-user',
				sortable: false,
			},
			{
				label: ``,
				valuePath: 'name',
				sortable: false,
				cellClassNames: 'bold',
			},
		];
	}),

	model: null,
	table: null,

	didUpdateAttrs() {
		this._super(...arguments);
		this.table.setRows(this.model || []);
	},

	init() {
		this._super(...arguments);

		let table = Table.create({
			columns: this.columns,
			rows: this.model,
			enableSync: true,
		});
		this.set('table', table);
	},
});
