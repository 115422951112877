/** @format */

import Component from '@ember/component';
import Table from 'ember-light-table';
import { computed } from '@ember/object';

export default Component.extend({
	columns: computed(function () {
		return [
			{
				label: '',
				valuePath: 'name',
				width: '50%',
				cellClassNames: 'bold',
				sortable: false,
			},
			{
				label: '',
				valuePath: 'id',
				cellComponent: 'cell-remove',
				cellClassNames: 'cell-remove',
				sortable: false,
			},
			{
				label: '',
				valuePath: 'notificationsOffForCase',
				width: '20%',
				align: 'right',
				cellComponent: 'cell-case-users-notification-setting',
				cellClassNames: 'cell-case-users-notification-setting',
				sortable: false,
			},
		];
	}),

	model: null,
	table: null,

	didUpdateAttrs() {
		this._super(...arguments);
		const model = this.model;
		this.table.setRows(model || []);
	},

	init() {
		this._super(...arguments);

		const model = this.model;
		let table = Table.create({
			columns: this.columns,
			rows: model,
			enableSync: true,
		});
		this.set('table', table);
	},
});
