/** @format */

import ApplicationSerializer from './application';

export default class LanguageSerializer extends ApplicationSerializer {
	normalizeResponse(...args) {
		return {
			data: args[2].map((res) => {
				return {
					attributes: res,
					id: res.language,
					type: 'language',
				};
			}),
		};
	}
}
