/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { defer } from 'rsvp';
import { inject as service } from '@ember/service';

export default Component.extend({
	currentUser: service('current-user'),
	init() {
		this._super(...arguments);

		this.store.findAll('caseTypeTemplate').then((response) => {
			let types = [];
			response.forEach(function (type) {
				const statuses = type.get('caseStatusTemplates');

				let statusDescription = '';

				statuses.forEach(function (status) {
					statusDescription += status.get('name');

					if (status.get('number') < 6) {
						statusDescription += ' > ';
					}
				});

				types.push({
					selected: false,
					id: type.get('id'),
					name: type.get('name'),
					description: statusDescription,
				});
			});

			this.set('types', types);
		});
	},

	success: false,
	errors: null,

	hasNoSelectedCaseTypes: computed('types.@each.selected', function () {
		let noneSelected = true;
		const types = this.types;

		if (types) {
			types.forEach(function (type) {
				if (type.selected) {
					noneSelected = false;
				}
			});
		}

		return noneSelected;
	}),

	actions: {
		logout() {
			this.currentUser.logout();
		},
		close() {
			this.close();
		},
		setCaseTypes() {
			const types = this.types;
			let selectedTypes = [];

			types.forEach(function (type) {
				if (type.selected) {
					selectedTypes.push(type.id);
				}
			});

			const result = defer();
			this.setCaseTypes(selectedTypes, result);

			this.set('isLoading', true);
			result.promise
				.then(() => {
					this.set('success', true);
					this.set('isLoading', false);
				})
				.catch((errors) => {
					this.set('isLoading', false);
					this.set('errors', errors);
				});
		},
	},
});
