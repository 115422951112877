/** @format */

import Component from '@ember/component';

export default Component.extend({
	didRender() {
		if (this.focusOnRender) {
			this.$('input').focus();
		}
	},
});
