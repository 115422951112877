/** @format */

import ValidateData from './data-types';

export const handleCallbacks = (...callbacks) => {
	if (ValidateData(callbacks, 'array', true)) {
		const callbacksLen = callbacks.length;
		for (let i = 0; i < callbacksLen; i++) {
			const callback = callbacks[i];
			if (callback && typeof callback == 'function') {
				//* Use trycatch to allow callback failure to not break the event loop
				try {
					callback();
				} catch (error) {
					//? Should probably handle a callback failure in an informative and documented way
				}
			}
		}
		return true;
	}
	return false;
};

export const handleOptionsCallbacks = (options) => {
	if (ValidateData(options, 'object', true)) {
		const callback = options.callback;
		const callbacks = options.callbacks || [];

		if (callback) {
			handleCallbacks(callback);
			//? what if they fail?
		}

		if (callbacks) {
			handleCallbacks(...callbacks);
			//? what if they fail?
		}

		return true;
	}
	return false;
};
