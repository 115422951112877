/** @format */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class NpsClientFeedbackModal extends Component {
	@service('current-user') currentUser;

	@tracked tenToZero = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
	@tracked selectedValue;
	@tracked additionalFeedback = '';

	constructor() {
		super(...arguments);
		// Property initializations are already done above.
	}

	@action
	selectReferValue(value) {
		this.selectedValue = value;
	}
}
