/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
	attributeBindings: ['id', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'typeform-multiple-choice row col-xs-4 margin-bottom',
	id: alias('model.id'),
	'data-test-id': 'component',
	options: computed('model.{choices,responses.[].choice}', function () {
		if (!this.model) return [];
		return this.model.choices.map((option) => {
			const optionClone = Object.assign({}, option);
			this.model.responses.forEach((response) => {
				const responseClone = Object.assign({}, response);
				if (optionClone.label == responseClone.choice.label) {
					optionClone.selected = 'true';
				}
			});
			return optionClone;
		});
	}),
});
