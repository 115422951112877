/** @format */

import ApplicationSerializer from './application';
export default ApplicationSerializer.extend({
	attrs: {
		firm: { serialize: false },
		updatedAt: { serialize: false },
		netPromoterScores: { serialize: false },
		attorney: { serialize: false },
	},
});
