import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n\t{{on 'drop' this.dropItem}}\n\t{{on 'dragover' this.dragOver}}\n\t{{on 'dragleave' this.dropLeave}}\n\t{{on 'dragenter' this.dropEnter}}\n\tclass='drop-zone {{if this.overDropZone \"drop-zone-over\"}}'\n\trole='button'\n>\n\t{{#each this.cmsStageRecords as |cmsStage|}}\n\t\t<CmsStage @options={{cmsStageOptions}} @cmsStageRecord={{cmsStage}} />\n\t{{/each}}\n\n</div>", {"contents":"<div\n\t{{on 'drop' this.dropItem}}\n\t{{on 'dragover' this.dragOver}}\n\t{{on 'dragleave' this.dropLeave}}\n\t{{on 'dragenter' this.dropEnter}}\n\tclass='drop-zone {{if this.overDropZone \"drop-zone-over\"}}'\n\trole='button'\n>\n\t{{#each this.cmsStageRecords as |cmsStage|}}\n\t\t<CmsStage @options={{cmsStageOptions}} @cmsStageRecord={{cmsStage}} />\n\t{{/each}}\n\n</div>","moduleName":"case-status/components/cms-drag-and-drop.hbs","parseOptions":{"srcName":"case-status/components/cms-drag-and-drop.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// https://dev.to/dgavey/simple-drag-and-drop-in-ember-octane-466i
export default class CmsDragAndDrop extends Component {
	constructor() {
		super(...arguments);
	}
	@tracked statusText = '';
	@tracked overDropZone = false;
	@tracked cmsStageRecords = this.args.cmsStageRecords;

	cmsStageOptions = [
		{
			label: 'Remove',
			value: 'Remove',
			action: this.removeCmsRecord,
		},
		...this.args.cmsStageOptions,
	];
	recordInTransit = null;

	addCmsRecord(record) {
		if (record) {
			// Check if object already in list passed down to component
			let objectIndex = this.cmsStageRecords.findIndex(
				(x) => x.name === record.name,
			);
			// Add Object to list if it isn't already there
			if (objectIndex == -1) {
				this.cmsStageRecords = [...this.cmsStageRecords, record];
			}
			this.args.updateRecordsCB(this.cmsStageRecords);
		}
	}

	@action
	removeCmsRecord(record) {
		if (record) {
			// Check if object already in list passed down to component
			this.cmsStageRecords = this.cmsStageRecords.filter(function (obj) {
				return obj.name !== record.name;
			});
			this.args.updateRecordsCB(this.cmsStageRecords);
		}
	}

	@action dropItem(dragEvent) {
		// Per html specification only the drop event included the dataTransfer dataTransfer. As such
		// We are only aware of objects pulled into and dropped in an area, and not objects removed.
		let dataTransfer = dragEvent.dataTransfer;
		let payload = dataTransfer.getData('Text');
		let object = JSON.parse(payload);
		this.addCmsRecord(object);

		dragEvent.preventDefault();
		this.overDropZone = false;
	}

	@action dragOver(dragEvent) {
		dragEvent.preventDefault();
		dragEvent.dataTransfer.dropEffect = 'copy';
	}

	@action dropLeave() {
		this.overDropZone = false;
	}

	@action dropEnter() {
		this.overDropZone = true;
	}
}
