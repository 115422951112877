import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n\tclass='{{if this.isClicked \"bold\"}} {{unless this.args.isActive \"disabled\"}} '\n\t{{on\n\t\t'click'\n\t\t(fn (action 'requestNewRecommendedResponseLocal' @regenerateResponseType))\n\t}}\n>\n\t{{#if this.isClicked}}\n\t\t<span>\n\t\t\t<FaIcon @icon='circle-notch' @spin={{true}} />\n\t\t</span>\n\t{{else}}\n\t\t{{yield}}\n\t{{/if}}\n</span>", {"contents":"<span\n\tclass='{{if this.isClicked \"bold\"}} {{unless this.args.isActive \"disabled\"}} '\n\t{{on\n\t\t'click'\n\t\t(fn (action 'requestNewRecommendedResponseLocal' @regenerateResponseType))\n\t}}\n>\n\t{{#if this.isClicked}}\n\t\t<span>\n\t\t\t<FaIcon @icon='circle-notch' @spin={{true}} />\n\t\t</span>\n\t{{else}}\n\t\t{{yield}}\n\t{{/if}}\n</span>","moduleName":"case-status/components/ai-regenerate-response-button.hbs","parseOptions":{"srcName":"case-status/components/ai-regenerate-response-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AiRegenerateResponseButtonComponent extends Component {
	@tracked isClicked = false;

	constructor() {
		super(...arguments);
	}

	@action
	async requestNewRecommendedResponseLocal(regenerateResponseType) {
		this.isClicked = true;
		await this.args.requestNewRecommendedResponse(regenerateResponseType);
		this.isClicked = false;
	}
}
