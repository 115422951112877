import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	urlForFindRecord(uuid) {
		return `${this.host}/files/signed_documents/${uuid}`;
	},
	queryRecord(store, type, query) {
		return fetch(`${this.host}/files/signed_documents/${query['uuid']}`).then(
			(response) => response.json(),
		);
	},
});
