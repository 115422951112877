/** @format */

import Route from '@ember/routing/route';

export default Route.extend({
	beforeModel(transition) {
		if (window) {
			window.location.replace(`https://www.casestatus.com/terms-of-service`);
		}
		transition.abort();
	},
});
