/** @format */

import EmberObject from '@ember/object';

export const FrequencyTypes = Object.freeze({
	DAYS: 'days',
	WEEKS: 'weeks',
	MONTHS: 'months',
	YEARS: 'years',
});

export const automationOptions = EmberObject.create({
	triggerOptions: [
		'No Case Changes',
		'Recurring Message',
		'Treatment Reminder',
	],
	triggerTypeOptions: [
		'Every Weekday',
		'Weekly',
		'Monthly',
		'Twice Monthly',
		'Custom',
	],
	monthDayOptions: [
		{ value: 1, label: '1st' },
		{ value: 2, label: '2nd' },
		{ value: 3, label: '3rd' },
		{ value: 4, label: '4th' },
		{ value: 5, label: '5th' },
		{ value: 6, label: '6th' },
		{ value: 7, label: '7th' },
		{ value: 8, label: '8th' },
		{ value: 9, label: '9th' },
		{ value: 10, label: '10th' },
		{ value: 11, label: '11th' },
		{ value: 12, label: '12th' },
		{ value: 13, label: '13th' },
		{ value: 14, label: '14th' },
		{ value: 15, label: '15th' },
		{ value: 16, label: '16th' },
		{ value: 17, label: '17th' },
		{ value: 18, label: '18th' },
		{ value: 19, label: '19th' },
		{ value: 20, label: '20th' },
		{ value: 21, label: '21st' },
		{ value: 22, label: '22nd' },
		{ value: 23, label: '23rd' },
		{ value: 24, label: '24th' },
		{ value: 25, label: '25th' },
		{ value: 26, label: '26th' },
		{ value: 27, label: '27th' },
		{ value: 28, label: '28th' },
	],
	excludedMonthDayOptions: [
		{ value: 29, label: '29th' },
		{ value: 30, label: '30th' },
		{ value: 31, label: '31st' },
	],
	hourOptions: [
		'1:00',
		'2:00',
		'3:00',
		'4:00',
		'5:00',
		'6:00',
		'7:00',
		'8:00',
		'9:00',
		'10:00',
		'11:00',
		'12:00',
	],
	weekDayOptions: [
		{ value: 0, label: 'Monday', checked: false },
		{ value: 1, label: 'Tuesday', checked: false },
		{ value: 2, label: 'Wednesday', checked: false },
		{ value: 3, label: 'Thursday', checked: false },
		{ value: 4, label: 'Friday', checked: false },
		{ value: 5, label: 'Saturday', checked: false },
		{ value: 6, label: 'Sunday', checked: false },
	],
	monthOptions: [
		{ label: 'January', value: 1 },
		{ label: 'February', value: 2 },
		{ label: 'March', value: 3 },
		{ label: 'April', value: 4 },
		{ label: 'May', value: 5 },
		{ label: 'June', value: 6 },
		{ label: 'July', value: 7 },
		{ label: 'August', value: 8 },
		{ label: 'September', value: 9 },
		{ label: 'October', value: 10 },
		{ label: 'November', value: 11 },
		{ label: 'December', value: 12 },
	],
});

export const CustomFrequencyDefaults = {
	daysOfWeek: automationOptions.weekDayOptions.map((day) => day.value),
	daysOfMonth: [
		...automationOptions.monthDayOptions.map((day) => day.value),
		...automationOptions.excludedMonthDayOptions.map((day) => day.value),
	],
	monthsOfYear: automationOptions.monthOptions.map((day) => day.value),
};

export default automationOptions;
