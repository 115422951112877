/** @format */
import { capitalize } from '@ember/string';
import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';

export function paralegalDisplay(params) {
	if (params && !Array.isArray(params)) {
		throw new EmberError(
			'paralegal-display helper requires params passed in as an array',
		);
	}
	let [firm, count, withoutCount] = params || [];
	if (count === undefined) {
		count = 1;
		withoutCount = true;
	}

	// if no firm default to Case Manager
	let title = 'Case Manager';
	if (!firm) {
		if (count !== 1) {
			title = 'Case Managers';
		}
		return withoutCount ? title : `${count} ${title}`;
	}

	// Update title based off firm content
	// sometimes firm is already passed as the proxy, sometimes not
	if (firm.info) {
		firm = firm.info;
	}
	if (count !== 1) {
		title = firm.get('caseManagerLabelPlural') ?? 'case managers';
	} else {
		title = firm.get('caseManagerLabelSingular') ?? 'case manager';
	}
	title = title
		.split(' ')
		.map((word) => capitalize(word))
		.join(' ');

	// if we display count, do so
	return withoutCount ? title : `${count} ${title}`;
}

export default helper(paralegalDisplay);
