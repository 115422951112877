/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
	currentUser: service(),

	showRequestModal: false,
	requestResponse: null,

	actions: {
		toggleShowRequestModal(requestResponse) {
			if (requestResponse && !this.showRequestModal) {
				this.set('requestResponse', requestResponse);
			} else {
				this.set('requestResponse', null);
			}

			this.toggleProperty('showRequestModal');
			if (!this.howRequestModal) {
				this.model.update();
			}
		},
	},
});
