/** @format */

import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { A } from '@ember/array';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { sort } from '@ember/object/computed';

export default Model.extend({
	//! Services
	currentUser: service(),
	ajax: service(),

	//! Attributes
	caseId: attr('number'),
	organizationId: attr('number'),
	isInternal: attr('boolean'),

	// From Case Organization Table
	canChat: attr('boolean'),
	showCaseStatus: attr('boolean'),
	showClientNames: attr('boolean'),

	chatMessagesAreLoading: true,
	messageGroups: A([]),

	chatMessageObserver: observer(
		'chatMessages.[]',
		'chatNotifications.[]',
		function () {
			this.groupMessages();
		},
	),
	users: computed('attorneys.[]', 'paralegals.[]', 'members.[]', function () {
		//* Calculate Users
		const chatAttorneys = this.attorneys.toArray() || [];
		const chatParalegals = this.paralegals.toArray() || [];
		const chatMembers = this.members.toArray() || [];
		const users = A([
			...chatAttorneys,
			...chatParalegals,
			...chatMembers,
		]).sortBy('lastName');
		return users;
	}),

	firmUsers: computed('attorneys.[]', 'paralegals.[]', function () {
		//* Calculate Firm Users to display to Organizations
		const chatAttorneys = this.attorneys.toArray() || [];
		const chatParalegals = this.paralegals.toArray() || [];
		const users = A([...chatAttorneys, ...chatParalegals]).sortBy('lastName');
		return users;
	}),

	requestResponses: null,
	requestsAreLoading: true,

	//! Relationships
	chatMessages: hasMany('chat-message'),
	chatFiles: hasMany('chat-file'),
	case: belongsTo('case'),
	paralegals: hasMany('paralegal'),
	attorneys: hasMany('attorney'),
	members: hasMany('member'),
	organization: belongsTo('organization'),
	//? I can't seem to get the relationship to work on the formResponses just right for some reason
	formResponses: hasMany('formResponse'),

	checklistItemsSortProp: ['id'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	checklistItems: hasMany('checklistItem'),
	sortedChecklistItems: sort('checklistItems', 'checklistItemsSortProp'),

	//! Life Cycle
	init() {
		this._super(...arguments);
		this.calculateUnreads();
		this.groupMessages();
	},

	//! Custom Methods
	fetchFormResponses() {
		this.set('requestsAreLoading', true);

		if (!this.get('organization.formId')) {
			this.set('requestResponses', A([]));
			this.set('requestsAreLoading', false);
			return;
		}

		return this.store
			.query('formResponse', { chat_id: this.id })
			.then((res) => {
				this.set('requestResponses', res.sortBy('id').toArray().reverse());
			})
			.finally(() => {
				this.set('requestsAreLoading', false);
			});
	},

	calculateUnreads: function () {
		// Notifications aren't setup as relationships anymore so we need to manually set it on the case
		const notifications = this.store.peekAll('chat-notification');
		const chatNotifications = notifications.filter((n) => {
			const chatIdMatch = n.chatId == this.id;
			const isUnread = n.readAt == null;
			return (
				chatIdMatch &&
				isUnread &&
				n.recipientChildId == this.currentUser.get('user.id')
			);
		});
		this.set('chatNotifications', chatNotifications);
	},

	groupMessages() {
		this.set('chatMessagesAreLoading', true);
		const chatMessages =
			[...this.chatMessages.sortBy('createdAt').toArray()] || [];
		const chatMessageGroups = A([]);
		const chatMessageGroup = {
			title: null,
			date: null,
			messages: A([]),
		};

		let lastChatMessage = null;

		// Process a clone of the current message group and resets the working group
		function unshiftChatGrouping() {
			if (chatMessageGroup.messages.length > 0) {
				let now = new moment();
				let wasYesterday =
					moment(chatMessageGroup.date).calendar(now).split(' ')[0] ==
					'Yesterday';
				let isToday =
					moment(chatMessageGroup.date).calendar(now).split(' ')[0] == 'Today';

				if (wasYesterday) {
					chatMessageGroup.title = 'Yesterday';
				}

				if (isToday) {
					chatMessageGroup.title = 'Today';
				}

				// Check to see if grouping already exists
				let existingGroupIndex = chatMessageGroups.findIndex((o) => {
					return o.title === chatMessageGroup.title;
				});

				if (existingGroupIndex != -1) {
					const existingGroup = chatMessageGroups[existingGroupIndex];
					existingGroup.messages = A([
						...existingGroup.messages,
						...chatMessageGroup.messages,
					])
						.sortBy('createdAt')
						.toArray();
					return;
				}

				chatMessageGroups.unshift(Object.assign({}, chatMessageGroup)); // Using Object.assign to prevent overwriting reference objects
			}

			chatMessageGroup.date = null;
			chatMessageGroup.title = null;
			chatMessageGroup.messages = A([]);
		}

		for (let i = 0; i < chatMessages.length; i++) {
			let chatMessage = chatMessages[i];
			if (!chatMessage || !chatMessage.content) continue;
			const chatNotifications = this.chatNotifications;
			const chatNotificationArray = chatNotifications.toArray();

			if (chatNotificationArray && chatNotificationArray.length > 0) {
				const notification = chatNotificationArray.find((n) => {
					const messageIdMatch = n.chatMessageId == chatMessage.id;
					const userIdMatch =
						n.recipientChildId == this.get('currentUser.user.id');
					const isUnread = n.readAt === null;
					return messageIdMatch && userIdMatch && isUnread;
				});

				if (notification) {
					notification.set('sender', chatMessage.get('sender'));
					notification.set('chatFile', chatMessage.get('chatFile'));
					chatMessage = notification;
				}
			}

			let sendDate = moment(chatMessage.createdAt).format('MMM Do, YYYY');
			if (
				!lastChatMessage ||
				moment(lastChatMessage.createdAt).format('MMM Do, YYYY') != sendDate
			) {
				unshiftChatGrouping();
			}

			if (chatMessageGroup.date == null) {
				chatMessageGroup.date = chatMessage.createdAt;
				chatMessageGroup.title = sendDate;
			}

			chatMessageGroup.messages.unshift(chatMessage);
			lastChatMessage = chatMessage;

			// If this is the last message to process, then push the working group
			if (i + 1 == chatMessages.length) {
				unshiftChatGrouping();
			}
		}

		setTimeout(() => {
			this.set('chatMessagesAreLoading', false);
		}, 2000);
		this.set('messageGroups', chatMessageGroups);
	},
});
