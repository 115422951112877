/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	store: service(),

	actions: {
		markMessageRead() {
			const message = this.message;
			message.set('readAt', new Date());
			message.save().catch((err) => this.set('error', `Error: ${err}`));
		},
		markMessageUnread() {
			const message = this.message;
			message.set('readAt', null);
			message.save().catch((err) => this.set('error', `Error: ${err}`));
		},
	},
});
