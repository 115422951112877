/** @format */

import Component from '@ember/component';

export default Component.extend({
	classNames: ['multi-option-toggle'],

	actions: {
		selectOption(option) {
			this.onSelect(option);
		},
	},
});
