/** @format */

import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ModelEditMedicalProviderComponent extends Component {
	@action
	close() {
		//TODO rollback changes when editable
		this?.args?.closeCallback();
	}

	@action
	updateProviderType(/* selected */) {
		//TODO Use this to handle the onCHange of the dropdown for Provider Type
		return null;
	}
}
