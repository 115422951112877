/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { pluralize } from 'ember-inflector';

export function dynamicCaseLabel(params /*, hash*/) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'dynamic-case-label helper requires data passed in as an array',
		);
	}

	const [company, isPlural, defaultOverride] = params;

	//* If no defaultOverride is provided, then the default is "case"
	const defaultCaseLabel = defaultOverride || 'case';

	//* If no company was passed then return the defaultCaseLabel
	if (!company) {
		if (isPlural) {
			return pluralize(defaultCaseLabel);
		} else {
			return defaultCaseLabel;
		}
	}

	//* If isPlural is falsey and there is no caseLabelSingular value set/passed return the defaultCaseLabel
	if (
		!isPlural &&
		!company.caseLabelSingular &&
		company.info &&
		!company.info.caseLabelSingular
	) {
		return defaultCaseLabel;
	} else if (
		!isPlural &&
		(company.caseLabelSingular ||
			(company.info && company.info.caseLabelSingular))
	) {
		return company.caseLabelSingular || company.info.caseLabelSingular;
	}

	//* If isPlural is truthy and there is no caseLabelPlural value set/passed return the defaultCaseLabel
	if (
		isPlural &&
		!company.caseLabelPlural &&
		company.info &&
		!company.info.caseLabelPlural
	) {
		return pluralize(defaultCaseLabel);
	} else if (
		isPlural &&
		(company.caseLabelPlural || (company.info && company.info.caseLabelPlural))
	) {
		return company.caseLabelPlural || company.info.caseLabelPlural;
	}

	//* if it makes it here just return the defaultCaseLabel for now
	return isPlural ? pluralize(defaultCaseLabel) : defaultCaseLabel;
}

export default helper(dynamicCaseLabel);
