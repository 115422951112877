import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless @hide}}\n\t<div class='row between-xs col-xs-12 {{@class}}'>\n\t\t<div class='col-xs-12 col-sm-12'>\n\t\t\t<div\n\t\t\t\tclass='sender-checkbox has-hover-text'\n\t\t\t\tdata-title='If checked, all messages will appear to come from the primary. If unchecked, all messages will appear to come from you.'\n\t\t\t>\n\t\t\t\t<label class='control control--checkbox'>\n\t\t\t\t\t{{input type='checkbox' checked=sendAsPrimary}}\n\t\t\t\t\t<span class='checkbox-text'>Send all messages as primary</span>\n\t\t\t\t\t<div class='control__indicator'></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n{{/unless}}", {"contents":"{{#unless @hide}}\n\t<div class='row between-xs col-xs-12 {{@class}}'>\n\t\t<div class='col-xs-12 col-sm-12'>\n\t\t\t<div\n\t\t\t\tclass='sender-checkbox has-hover-text'\n\t\t\t\tdata-title='If checked, all messages will appear to come from the primary. If unchecked, all messages will appear to come from you.'\n\t\t\t>\n\t\t\t\t<label class='control control--checkbox'>\n\t\t\t\t\t{{input type='checkbox' checked=sendAsPrimary}}\n\t\t\t\t\t<span class='checkbox-text'>Send all messages as primary</span>\n\t\t\t\t\t<div class='control__indicator'></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n{{/unless}}","moduleName":"case-status/components/toggles/send-as-primary.hbs","parseOptions":{"srcName":"case-status/components/toggles/send-as-primary.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TogglesSendAsPrimaryComponent extends Component {
	@service currentUser;
	@service errorHandling;

	get sendAsPrimary() {
		return this.currentUser.get('user.sendAsPrimary');
	}

	set sendAsPrimary(value) {
		try {
			if (typeof value !== 'boolean')
				throw Error('When setting sendAsPrimary a boolean must be passed!');

			this.currentUser.set('user.sendAsPrimary', value);
			this.currentUser.get('user')?.save();
		} catch (error) {
			this.currentUser.get('user')?.rollbackAttributes();
			this.errorHandling.catchSilently(error);
			this.errorHandling.notify(
				'An error has occurred while attempting to update the primary sender.',
			);
		}
	}
}
