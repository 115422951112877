/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';
import { captureException } from 'logrocket';
import { next } from '@ember/runloop';

const notifOptions = {
	canClose: true, //* User is allowed to close notif
	autoClear: true, //* Notif will auto clear itself after a set clearDuration
	clearDuration: 5000, //* Milliseconds until notif auto clears itself if not already closed
};

@classic
export default class CellCaseAttorneysNotificationSettingComponent extends Component {
	row;
	@tracked value;
	tableActions;
	extra;

	host = ENV.host;

	@service ajax;
	@service notifications;
	@service currentUser;
	@service company;
	@service permissions;

	@action
	toggleUserCaseNotificationsSetting() {
		this.set('isLoading', true);
		const childID = this?.row?.get('id');
		const userType = this?.row?.get('type');
		const caseID = this?.extra?.theCase?.get('id');

		if (!caseID || !childID || !userType) {
			this.set('isLoading', false);
			return false;
			//* might instead want to throw an error and/or report back to logRocket
		}

		const url = `${this.host}/cases/${caseID}/${userType}s/${childID}`; //* Needs work

		this.ajax
			.patch(url)
			.then((res) => {
				//* Using optional chaining for cleaner and more readable code but also just in case the response
				//* does not comply with the expected format, it will just set ti undefined thus triggering the
				//* fallback in the related HBS file to display "N/A" instead of an icon.

				const resValue = res?.data?.attributes?.notifications_off_for_case;

				//* Since the response is not the case with the userType's respective relationship array updated,
				//* but is instead just the updated userType model, this means that the model used for the
				//* table hasn't updated and thus doesn't trigger a rerender, so we must update the value
				//* ourselves to reflect the change in the response, persistence will rely on the API having
				//* that updated info in the case's respective relationship list (case.attorneys or case.paralegals)

				this.set('value', resValue);

				//* When using optional chaining, if any entry is missing then instead of throwing a fatal error
				//* it just returns as undefined, so we can check for that and display and error notif to help
				//* explain when the cell is now displaying the fallback option of "N/A"
				//* Navigating away from the case and back or refreshing the page will both refresh the case data and
				//* help alleviate this break in data.

				if (resValue === undefined) {
					this.notifications.error(
						'There was an error retrieving the updated data, please refresh the page',
						notifOptions,
					);

					//? Another possible option here is to access the this.extras.theCase and see if we can call theCase.reload()
					//? To force a data reload of the case data, and then we may not need to display that there was an error at
					//? all, but in that case we should still report to logRocket that the unintended behavior is happening
					//? rather than just sweep it under the rug.

					//* Send an "error" message to logRocket to note unintended behavior
					captureException(
						"ERROR: Received unexpected response to PATCH request to update target user's notification settings on a case",
						{
							tags: {
								action: 'Customizable Case Notifications',
							},
							extra: {
								currentchildID: this?.currentUser?.user?.id,
								firmId: this?.company?.info?.id,
								targetUserType: userType,
								targetchildID: childID,
								targetCaseId: caseID,
								targetEndpoint: url,
								file: 'case-status/components/cell-case-users-notification-setting.js',
								isCaughtError: false,
							},
						},
					);
				}
			})
			.catch((err) => {
				//* Show a notif to display the error to the user
				this.notifications.error(Errors.mapResponserErrors(err), notifOptions);

				//* Pass the err and some extra details along to logRocket
				captureException(err, {
					tags: {
						action: 'Customizable Case Notifications',
					},
					extra: {
						currentchildID: this?.currentUser?.user?.id,
						firmId: this?.company?.info?.id,
						targetUserType: userType,
						targetchildID: childID,
						targetCaseId: caseID,
						targetEndpoint: url,
						file: 'case-status/components/cell-case-users-notification-setting.js',
						isCaughtError: true,
					},
				});
			})
			.finally(() => {
				//* Success or Failure, set the loading state back to false
				next(() => {
					this.set('isLoading', false);
				});
			});
	}
}
