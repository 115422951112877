/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';
import EmberError from '@ember/error';
import { next } from '@ember/runloop';

@classic
export default class ModalChecklistTemplatesComponent extends Component {
	@service store;
	@service router;

	@tracked templates = A([]);
	@tracked isLoading = true;
	@tracked isApplying = false;

	@tracked page = {
		number: 1,
		size: 25, //* API max allowed
	};

	@tracked totalResults = 1;
	@tracked totalPages = 2;

	@tracked errors;

	init() {
		super.init(...arguments);
		this.fetchChecklistTemplates();
	}

	fetchChecklistTemplates() {
		if (
			this.totalResults <= this?.templates?.length ||
			this.totalPages < this?.page?.number
		)
			return false;
		this.safeSet('isLoading', true);
		const queryParams = {};
		queryParams.page = this.page;

		this.store
			.query('checklist-template', queryParams)
			.then((res) => {
				this.templates?.pushObjects(res?.toArray());
				this.safeSet('totalResults', res?.meta?.total_results);
				this.safeSet('totalPages', res?.meta?.total_pages);
				next(() => {
					if (
						this.totalResults > this?.templates?.length &&
						this.totalPages > this?.page?.number
					) {
						this.incrementProperty('page.number');
						this.fetchChecklistTemplates();
					} else {
						this.safeSet('isLoading', false);
					}
				});
			})
			.catch((err) => {
				this.safeSet('errors', err);
				next(() => {
					this.safeSet('isLoading', false);
				});
			});
	}

	willDestroy() {
		//* Only unload the templates if not in routes that would need them loaded
		if (
			this.router.currentRouteName !=
				'app.firm.settings.templates.checklist-templates' &&
			this.router.currentRouteName != 'app.firm.settings.case-types'
		) {
			this.store.unloadAll('checklist-template');
			this.store.unloadAll('checklist-item-template');
		}
	}

	safeSet(prop, ...val) {
		//* Prevent errors from promises when component is destroyed or destroying
		if (!this.isDestroyed && !this.isDestroying) {
			this.set(prop, ...val);
		}
	}

	@action
	_close() {
		if (this.isApplying) return false;
		if (this.close && typeof this.close == 'function') {
			try {
				this.close();
			} catch (error) {
				throw new EmberError(error);
			}
		} else {
			const err = this.applyTemplate
				? 'CS ERROR: 1409'
				: typeof this.applyTemplate != 'function'
				? 'CS ERROR: 1410'
				: 'CS ERROR: 1411';

			/*
        CS ERRORS:
          1409: close method missing or not passed
          1410: close "method" supplied must be a function and was determined to not be a function
          1411: No clue what went wrong, we shouldn't be here... Panic...
       */

			throw new EmberError(err);
		}
	}

	@action
	displayTemplate(template) {
		if (this.isApplying) return false;
		this.safeSet('displayedTemplate', template);
	}

	@action
	async selectTemplate(template) {
		if (this.isApplying) return false;
		if (this.applyTemplate && typeof this.applyTemplate == 'function') {
			try {
				this.safeSet('isApplying', true);
				//* applyTemplate must be a promise
				await this.applyTemplate(template);
				this.safeSet('isApplying', false);
				next(() => {
					this._close();
				});
			} catch (error) {
				this.safeSet('isApplying', false);
				throw new EmberError(error);
			}
		} else {
			//* Creating errors that doesn't tell the user what is wrong but we can reference a table for.
			const err = this.applyTemplate
				? 'CS ERROR: 1412'
				: typeof this.applyTemplate != 'function'
				? 'CS ERROR: 1413'
				: 'CS ERROR: 1414';

			/*
        CS ERRORS:
          1412: applyTemplate method missing or not passed
          1413: applyTemplate "method" supplied must be a function and was determined to not be a function
          1414: No clue what went wrong, we shouldn't be here... Panic...
       */

			this.safeSet('isApplying', false);
			throw new EmberError(err);
		}
	}
}
