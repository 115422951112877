/** @format */

import Component from '@ember/component';

export default Component.extend({
	showProfileComplete: false,

	actions: {
		next() {
			this.next();
		},

		toggleShowProfileComplete() {
			this.toggleProperty('showProfileComplete');
		},

		completeProfile() {
			this.completeProfile();
		},
	},
});
