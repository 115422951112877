/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';

export default Model.extend({
	//* Properties
	confirmDelete: false,

	//* Attributes
	createdAt: attr('date'),
	contentType: attr('string'),
	extension: attr('string'),
	fileName: attr('string'),
	size: attr('number'),
	senderChildId: attr('number'),
	senderType: attr('string'),
	url: attr('string'),
	chatMessageId: attr('number'),

	//* Computed Properties
	formattedCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).format('MMMM D, YYYY');
	}),

	sortableCreatedAt: computed('createdAt', function () {
		return moment(this.createdAt).valueOf();
	}),

	senderName: computed('senderChildId', function () {
		const user = this.store.peekRecord(this.senderType, this.senderChildId);

		if (user) {
			return user.get('name');
		}

		return 'N/A';
	}),

	isImage: computed('contentType', function () {
		return this.contentType.indexOf('image') > -1;
	}),

	fileType: computed('extension', function () {
		return this.extension.replace('.', '').toUpperCase();
	}),

	//* Relationships
	chat: belongsTo('chat', { inverse: 'chatFiles' }),
	chatMessage: belongsTo('chat-message'),
});
