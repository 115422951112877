import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { tzAbvFromDate } from 'case-status/constants/datetime';

export default helper(function tzAbv(params /*, hash*/) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'date-display helper requires date and firm passed in as an array',
		);
	}

	return tzAbvFromDate(params[0]);
});
