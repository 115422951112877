/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import Error from '../../constants/errors';
import { integrations } from '../../constants/integrations';

const redirectUri = encodeURI(`${window.location.origin}/app/clio`);
const integrationsCallback = 'https://app.clio.com/app_integrations_callback';

export default Route.extend({
	currentUser: service('current-user'),
	session: service(),
	ajax: service(),
	queryParams: {
		code: {},
		state: {},
	},
	model(params) {
		if (params.code) {
			const user = this.get('currentUser.user');
			const data = {
				code: params.code,
				redirect_uri: redirectUri,
			};
			const url = `${ENV.host}/firms/${user.get('firm.id')}/clio`;
			return this.ajax
				.post(url, {
					contentType: 'application/json',
					data: JSON.stringify(data),
					dataType: 'json',
				})
				.then((response) => {
					//* if we have a state param this was initiated from the "add to clio" flow
					if (params.state) {
						window.location.replace(integrationsCallback);
					} else {
						this.transitionTo('app.firm.settings.integrations', {
							queryParams: {
								integration_success: response.message,
								integration_type: integrations.CLIO,
							},
						});
					}
				})
				.catch((response) => {
					const error = Error.mapResponseErrors(response)[0];
					this.transitionTo('app.firm.settings.integrations', {
						queryParams: { integration_error: error },
					});
				});
		} else {
			this.transitionTo('app.firm.settings.integrations', {
				queryParams: {
					integration_error:
						'The authorization code was not provided. Please try again later',
				},
			});
		}
	},
});
