/** @format */

import Route from '@ember/routing/route';

export default Route.extend({
	model() {
		this._super(...arguments);
		this.set('dummySummaryJson', {
			'Overview': 'Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.',
			'Blockers and client sentiment': 'Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.',
			'Follow up items and open questions': {
				1: 'Borem ipsum dolor sit amet, consectetur adipiscing elit.',
				2: 'Etiam eu turpis molestie, dictum est a, mattis tellus.',
				3: 'Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.',
				4: 'Maecenas eget condimentum velit, sit amet feugiat lectus.'
			}
		});
		return {'dummySummaryJson': this.get('dummySummaryJson')};
	}
});
