/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	urlForDeleteRecord(store, type, snapshot) {
		//* Currently there is no option given to the user to not "remove_all"
		return `${this.host}/automation_templates/${store}${
			snapshot.__attributes.retroactive ? '?remove_all=True' : ''
		}`;
	},
});
