/* eslint-disable ember/no-classic-classes */
//case, message, content, approved

/** @format */

import Model, { attr } from '@ember-data/model';


export default class AiCaseSummaryModel extends Model {
	@attr('date') createdAt;
	@attr('number') caseId;
	@attr() summary;
};
