/** @format */

export default class {
	static download(dataurl, filename) {
		const a = document.createElement('a');

		a.href = dataurl;
		a.download = filename;
		a.target = '_blank';

		a.click();
		return false;
	}
}
