/** @format */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AvatarUpdaterComponent extends Component {
	classNames = ['row'];
	@service notifications;

	@tracked imgToCrop;
	@tracked stagedFile;

	get stagedFileURL() {
		if (this.stagedFile) {
			return URL.createObjectURL(this.stagedFile);
		} else {
			return null;
		}
	}

	@action stageFile(file) {
		this.args.stageProfilePicture(file);
		this.stagedFile = file;
		this.imgToCrop = null;
	}

	@action setImgToCrop(e) {
		const file = e.target.files[0];
		const reader = new FileReader();
		const _this = this;

		reader.readAsDataURL(file);
		reader.onloadend = function () {
			const base64data = reader.result;
			_this.imgToCrop = base64data;
		};
	}

	@action showFilePicker() {
		document.querySelector('#file_uploader').click();
	}
}
