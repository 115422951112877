/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';
import moment from 'moment';

export default Component.extend({
	init() {
		this._super(...arguments);

		this.set('options', ['AM', 'PM']);

		// Set the selected meridiem. This value can be passed in
		// from the template helper, otherwise we set it AM
		const selected = this.defaultMeridiem || 'AM';
		this.set('selected', selected);

		// If a value is passed in, we take that and convert it
		// from 24-hour glued time to 12-hour glued time.
		let value = this.value;
		if (value < 1000 && value[0] != 0) value = `0${value}`;

		const inputValue = moment(value, 'HHmm').format('hhmm');

		// Then we set it as the inputValue, which is what the
		// input in the template will receive and update
		this.set('inputValue', inputValue);

		// Now we need to make sure the meridiem selected is right.
		// If it's noon or later, we set to PM. Otherwise it's AM.
		if (value > 1159) {
			this.set('selected', 'PM');
		} else if (value < 1200) {
			this.set('selected', 'AM');
		}
	},

	classNames: ['validated-time-input'],

	selected: 'AM',

	// For updating the inputValue back to 24-hour glued time and setting the value
	updateValue: observer('inputValue', 'selected', function () {
		// First, create a string with the inputValue time and the selected meridiem
		const valueString = this.inputValue + this.selected;

		// Then we use moment to format it back into 24-hour glued time
		// which is the format that got passed in initially.
		const value = moment(valueString, 'hhmm A').format('HHmm');

		// Once it's formatted, we set the value that got passed in
		this.set('value', value);
	}),

	actions: {
		update(unmasked) {
			this.set('inputValue', unmasked);
		},
	},
});
