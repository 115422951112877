/** @format */

import ApplicationAdapter from './application';
import ENV from 'case-status/config/environment';

export default ApplicationAdapter.extend({
	host: ENV.host,
	urlForQuery(query) {
		if (query.userId) {
			return `${this.host}/user_children`;
		}

		if (query.path) {
			const path = `${query.path}`;
			delete query.path;
			return `${this.host}${path}`;
		}
	},
});
