/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
	automationType: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Automation Type',
	}),

	//* Will need to be dummy content when using checklist template
	//TODO Need to look into how to have these validation be dynamic based on the automationType's value
	content: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Content',
	}),
	statuses: validator('array-length', {
		min: 1,
		dependantKeys: ['statuses.[]'],
		message: 'Must assign a minimum of 1 status',
		description: 'Status',
	}),
	'caseType.content': validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Practice Area',
	}),
});

export default Model.extend(Validations, {
	content: attr('string'),
	statuses: attr(), //* Empty attr covers lists/arrays
	retroactive: attr('boolean'),
	automationType: attr('string'),
	daysOfWeek: attr(), //* Empty attr covers lists/arrays
	daysOfMonth: attr(), //* Empty attr covers lists/arrays
	monthsOfYear: attr(), //* Empty attr covers lists/arrays
	hourOfDay: attr('number'),
	npsScores: attr(),
	daysAfterEvent: attr('number'),
	projectedSendDate: attr('date'),
	createdAt: attr('date'),
	updatedAt: attr('date'),
	errorMessage: attr('string'),

	callCount: attr('number'), //* How many times this automation has been called/ran
	callLimit: attr('number'), //* Max amount of times an automation should be called

	runClosed: attr('boolean'),
	runHold: attr('boolean'),

	//* Relationships
	caseType: belongsTo('caseType'),
	case: belongsTo('case'),
	firm: belongsTo('firm'),
	messageTemplate: belongsTo('messageTemplate'),
	checklistTemplate: belongsTo('checklistTemplate', { inverse: null }),

	//* Local Properties
	company: service(),
	type: alias('automationType'),
	triggerTime: alias('hourOfDay'),
	triggerDays: alias('daysOfMonth'),
	frequencyAmount: attr('number'),
	frequencyType: attr('string'),
	customWeekDayOptions: attr(),
	selectedMonth: attr('string'),
	selectedDay: attr('number'),
	typeLabel: computed('automationType', function () {
		const trigger = this.automationType;
		switch (trigger) {
			case 'no_case_changes':
				return `No ${capitalize(dynamicCaseLabel([this.company]))} Change`;
			case 'recurring_message':
				return 'Recurring Message';
			case 'treatment_reminder':
				return 'Treatment Reminder';
			case 'add_checklist_template':
				return 'Add Checklist Template';
			case 'add_checklist_template_chat':
				return 'Add Internal Checklist Template';
			case 'date_based':
				return 'Date Based Message';
			case 'status_message':
				return 'Single Message';
			case 'delayed_status_message':
				return 'Single Delayed Message';
			case 'nps_message':
				return 'Single Message based on NPS';
			default:
				return null;
		}
	}),
	dateType: attr('string'),

	//* Set the triggerType on init for pre-existing automations
	triggerType: attr('string'),
	holdUntilActivation: attr('boolean'),

	init() {
		this._super(...arguments);

		if (!this.id) {
			//* Default to true when new
			this.set('holdUntilActivation', true);
		}

		//* Default the daysAfterEvent to 14 if not set
		if (!this.daysAfterEvent) {
			this.set('daysAfterEvent', 14);
		}

		//* Figure out the triggerType
		let triggerType;
		const daysOfWeek = this.daysOfWeek || [];
		const daysOfMonth = this.daysOfMonth || [];
		const monthsOfYear = this.monthsOfYear || [];
		if (this.frequencyAmount && this.frequencyType) {
			triggerType = 'custom';
		} else if (daysOfWeek?.length || daysOfMonth?.length) {
			if (daysOfWeek?.length) {
				if (
					daysOfWeek?.length === 5 &&
					daysOfWeek[0] == 0 &&
					daysOfWeek[1] == 1 &&
					daysOfWeek[2] == 2 &&
					daysOfWeek[3] == 3 &&
					daysOfWeek[4] == 4
				) {
					triggerType = 'days';
				} else {
					triggerType = 'weeks';
				}
			}

			if (
				daysOfMonth?.length &&
				(daysOfMonth?.length === 1 || daysOfMonth?.length === 2)
			) {
				triggerType = 'months';
			}
		}

		if (triggerType) {
			this.set('triggerType', triggerType);
		} else {
			this.set('daysOfWeek', null);
			this.set('daysOfMonth', null);
		}
	},
});
