/**
 * Created by Aderhold on 8/07/19.
 *
 * @format
 */

export const link = {
	name: 'link',
	icon: '<span class="link-icon"></span>',
	result: () => {
		// At this point we can design our own input
		// that can do even more instead of using the window prompt
		// ¯\_(ツ)_/¯
		const url = window.prompt('Enter the link URL', 'http://');
		const selectedText = document.getSelection().toString();
		let formattedUrl;
		let formattedLink;
		if (url) {
			formattedUrl = url;
			const isHttp = formattedUrl.indexOf('http:');
			const isHttps = formattedUrl.indexOf('https:');

			if (isHttp === -1 && isHttps === -1) {
				// Defaulting to http because non ssl sites don't redirect from https to http but most ssl sites do redirect from http to https
				formattedUrl = `http://${formattedUrl}`;
			} else if (isHttps > 0) {
				formattedUrl = formattedUrl.slice(0, isHttps);
			} else if (isHttp > 0) {
				formattedUrl = formattedUrl.slice(0, isHttp);
			}
			formattedLink = `<a href="${formattedUrl}" target="_blank" alt="${formattedUrl}">${
				selectedText ? selectedText : formattedUrl
			}</a>`;

			//TODO: Could add link validation that on bad links doesn't insert the link and gives the user a notification on such. A simple HEAD request at first, 200 OK passes, if fails, or responds with '405 Method Not Allowed' fallback and try a GET request. This will minimize bandwidth as much as possible.

			// Using 'insertHTML' instead of 'createLink' because 'createLink' doesn't have an option to set the target or alt attributes
			document.execCommand('insertHTML', false, formattedLink);
		}
		return true;
	},
};

export const templates = {
	name: 'templates',
	icon: '<span class="template-icon" tooltip="Message Templates"></span>',
	title: 'Message Templates',
	result: () => {
		document
			.getElementById('modal-add-message-template')
			.classList.remove('hidden');
		return true;
	},
};

export default { link, templates };
