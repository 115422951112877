/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Error from '../../constants/errors';
import { integrations } from '../../constants/integrations';
import { action } from '@ember/object';

const redirectUri = encodeURI(`${window.location.origin}/app/smokeball`);

export default class SmokeballRoute extends Route {
	@service currentUser;
	@service session;
	@service ajax;

	queryParams = {
		code: {},
	};

	@action
	async model(params) {
		if (params.code) {
			const user = this.currentUser.user;
			const data = {
				code: params.code,
				redirect_uri: redirectUri,
			};
			const url = `${ENV.host}/firms/${user.firm.id}/smokeball`;

			try {
				const response = await this.ajax.post(url, {
					contentType: 'application/json',
					data: JSON.stringify(data),
					dataType: 'json',
				});

				await this.transitionTo('app.firm.settings.integrations', {
					queryParams: {
						integration_success: response.message,
						integration_type: integrations.SMOKEBALL,
					},
				});
			} catch (errorResponse) {
				const error = Error.mapResponseErrors(errorResponse)[0];
				await this.transitionTo('app.firm.settings.integrations', {
					queryParams: { integration_error: error },
				});
			}
		} else {
			await this.transitionTo('app.firm.settings.integrations', {
				queryParams: {
					integration_error:
						'The authorization code was not provided. Please try again later',
				},
			});
		}
	}
}
