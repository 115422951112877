/** @format */
import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ThirdPartyController extends Controller {
	@controller('app.third_party.case.index') caseIndexController;
	@service currentUser;
	@service thirdParty;
	@service permissions;
	showAccountMenu = false;

	@action
	toggleShowAccountMenu() {
		this.toggleProperty('showAccountMenu');
	}

	@action
	selectNav(target) {
		this.caseIndexController.send('toggleActiveCardSection', target);
	}
}
