import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless isLoading}}\n\t<FilterDropdown2\n\t\t@title='Practice Area'\n\t\t@allowSearch={{true}}\n\t\t@options={{options}}\n\t\t@selections={{selections}}\n\t\t@sortKey='value'\n\t\t@isLoading={{isLoading}}\n\t\t@onChange={{action 'handleOnChange'}}\n\t/>\n{{/unless}}", {"contents":"{{#unless isLoading}}\n\t<FilterDropdown2\n\t\t@title='Practice Area'\n\t\t@allowSearch={{true}}\n\t\t@options={{options}}\n\t\t@selections={{selections}}\n\t\t@sortKey='value'\n\t\t@isLoading={{isLoading}}\n\t\t@onChange={{action 'handleOnChange'}}\n\t/>\n{{/unless}}","moduleName":"case-status/components/cases-filters/case-type-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/case-type-filters.hbs"}});
import BaseCasesFilterTemplate, { A, action } from './base-filter-template';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CasesFiltersMessageFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);
		this.options = A([]);

		this.fetchCaseTypes();

		//? We should set this up for pagination in preparation or response
		//? to the api response becoming paginated.
	}

	async fetchCaseTypes() {
		let caseTypes = [];

		caseTypes = await this.store.findAll('case-type');

		this.isLoading = false;

		this.options = caseTypes?.toArray().map((ct) => {
			return {
				value: ct.name,
				param: ct.id,
				checked: this.args.selections?.includes(ct.id),
			};
		});
	}

	@service store;

	@tracked isLoading = true;

	@action
	handleOnChange(selections = []) {
		//handle how it will be set on the cases controller and format that as an object
		super.handleOnChange(selections);

		// handle how it will be set on the cases controller and format that as an object
		this.onChange({ bulkTypes: selections.map((sel) => sel.param) });
	}
}
