/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { paralegalDisplay } from '../helpers/paralegal-display';

export default Component.extend({
	currentUser: service('current-user'),
	valueObserver: observer('value', function () {
		const value = this.value;
		if (value.get('length') === 1) {
			this.set('formattedValue', value.objectAt(0).get('name'));
			this.set('isBold', false);
		} else if (value.get('length') === 0) {
			this.set('isBold', false);
			this.set('isItalic', true);
			this.set(
				'formattedValue',
				`No ${paralegalDisplay([this.get('currentUser.user.firm')])}s`,
			);
		} else {
			this.set(
				'formattedValue',
				`${value.get('length')} ${paralegalDisplay([
					this.get('currentUser.user.firm'),
				])}s`,
			);
			this.set('isBold', false);
		}
	}).on('init'),
});
