/** @format */

import Route from '@ember/routing/route';
import InboxRouteMixin from '../../../mixins/route/inbox';
import RSVP from 'rsvp';
import { observer } from '@ember/object';

export default Route.extend(InboxRouteMixin, {
	notificationObserver: observer('pubnub.newMessage', function () {
		const controller = this.controllerFor('app.firm.inbox');
		if (this.pubnub.newMessage) {
			controller.reloadModel();
		}
	}),

	model() {
		const notifications = this.infinity.model('notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});

		const chatNotifications = this.infinity.model('chat-notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});

		return RSVP.hash({
			notifications,
			chatNotifications,
		});
	},

	setupController(controller) {
		this._super(...arguments);

		// reset case filters
		this.controllerFor('app.firm.cases').send('clearFilters');
	},
});
