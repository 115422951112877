/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { next, debounce } from '@ember/runloop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'case-status/config/environment';
import { defer } from 'rsvp';
import Errors from 'case-status/constants/errors';

@classic
export default class ChatAddCollaboratorModal extends Component {
	@service currentUser;
	@service company;
	@service store;
	@service ajax;

	@tracked _searchTerm = '';
	get searchTerm() {
		if (!this._searchTerm) return '';
		return this._searchTerm;
	}

	set searchTerm(value) {
		this._searchTerm = value;
		this.isLoading = true;
		debounce(this, this.fetchCollaborators, 500);
	}

	get filteredCollaborators() {
		// We do not want to return organizations that are already connected to the case via chats

		const firmCollaborators = this.collaborators || [];
		const connectedCollaborators = this.caseOrganizations || [];
		const filteredCollaborators = firmCollaborators.filter((fc) => {
			const activeCollaborator = connectedCollaborators.find((cc) => {
				return fc.get('id') == cc.get('id');
			});

			return !activeCollaborator;
		});

		return filteredCollaborators;
	}

	@tracked isLoading = false;
	@tracked selectedCollaborator = null;
	@tracked permissions = {
		//eslint-disable-line ember/avoid-leaking-state-in-ember-objects
		canChat: true,
		showCaseStatus: true,
		showClientNames: true,
	};

	@tracked collaboratorsList = [];
	get collaborators() {
		return this.collaboratorsList;
	}
	set collaborators(value) {
		this.collaboratorsList = value;
	}

	fetchCollaborators() {
		//Avoid calling set on destroyed component
		if (this.isDestroyed || this.isDestroying) {
			return;
		}
		const searchTerm = this.searchTerm.trim();
		let queryParams = { filter: {} };

		queryParams.filter['member.email_address'] = searchTerm;
		queryParams.filter['organization.name'] = searchTerm;

		this.queryParams = queryParams;

		this.store
			.query('organization', queryParams)
			.then((collaborators) => {
				this.collaborators = collaborators;
			})
			.finally(() => {
				// Run in next runloop
				next(() => {
					//Avoid calling set on destroyed component
					if (this.isDestroyed || this.isDestroying) {
						return;
					}
					this.isLoading = false;
				});
			});
	}

	@tracked isNew = false;
	@tracked _newOrgName = '';
	@tracked newOrgNameError = null;
	@tracked _newOrgFirstName = '';
	@tracked newOrgFirstNameError = null;
	@tracked _newOrgLastName = '';
	@tracked newOrgLastNameError = null;
	@tracked _newOrgEmail = '';
	@tracked newOrgEmailError = null;

	get newOrgName() {
		return this._newOrgName;
	}

	set newOrgName(value) {
		this.newOrgNameError = null;
		this._newOrgName = value;
		if (value.trim().length <= 0) {
			this.newOrgNameError = 'Organization Name is required.';
		}
	}

	get newOrgFirstName() {
		return this._newOrgFirstName;
	}

	set newOrgFirstName(value) {
		this.newOrgFirstNameError = null;
		this._newOrgFirstName = value;
		if (value.trim().length <= 0) {
			this.newOrgFirstNameError = 'First Name is required.';
		}
	}

	get newOrgLastName() {
		return this._newOrgLastName;
	}

	set newOrgLastName(value) {
		this.newOrgLastNameError = null;
		this._newOrgLastName = value;
		if (value.trim().length <= 0) {
			this.newOrgLastNameError = 'Last Name is required.';
		}
	}

	get newOrgEmail() {
		return this._newOrgEmail;
	}

	set newOrgEmail(value) {
		this.newOrgEmailError = null;
		this._newOrgEmail = value;
		if (value.trim().length <= 0) {
			this.newOrgEmailError = 'Email is required.';
		}
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
		this.set('permissions', {
			canChat: true,
			showCaseStatus: true,
			showClientNames: true,
		});
		this.set('selectedCollaborator', null);
	}

	validateNewOrg() {
		return (
			!this.newOrgNameError &&
			!this.newOrgFirstNameError &&
			!this.newOrgLastNameError &&
			!this.newOrgEmailError
		);
	}

	@action
	selectCollaborator(collaborator) {
		this.errors = null;
		this.set('selectedCollaborator', collaborator);
	}

	@action
	deselectCollaborator() {
		this.errors = null;
		this.set('selectedCollaborator', null);
		this.set('permissions', {
			canChat: true,
			showCaseStatus: true,
			showClientNames: true,
		});
	}

	@action
	addCollaborator() {
		this.errors = null;
		const data = {
			organization_id: this.get('selectedCollaborator.id'),
			can_chat: this.get('permissions.canChat'),
			show_case_status: this.get('permissions.showCaseStatus'),
			show_client_names: this.get('permissions.showClientNames'),
		};
		return this._addCollaborator(data).then(() => {
			this.close();
		});
	}

	@action
	cancel() {
		this.errors = null;
		this.close();
	}

	@action
	toggleCreateCollaborator() {
		this.errors = null;
		if (this.isNew) {
			this.newOrgEmail = '';
			this.newOrgFirstName = '';
			this.newOrgLastName = '';
			this.newOrgName = '';
		} else {
			this.newOrgEmail = this.searchTerm;
			this.newOrgEmailError = null;
			this.newOrgFirstNameError = null;
			this.newOrgLastNameError = null;
			this.newOrgNameError = null;
		}

		this.toggleProperty('isNew');
	}

	@action
	createCollaborator() {
		const result = defer();
		if (!this.validateNewOrg()) {
			result.reject();
			return result.promise;
		}

		this.errors = null;

		const newOrg = {
			org_name: this.newOrgName,
			org_phone_number: '',
			org_about: '',
			org_descriptor: '',
			phone_number_region: '',
			last_name: this.newOrgLastName,
			first_name: this.newOrgFirstName,
			email_address: this.newOrgEmail,
			cell_phone: '',
		};

		const url = `${ENV.host}/organizations`;
		const data = newOrg;

		this.ajax
			.post(url, { data })
			.then((res) => {
				this.store.pushPayload(res);
				const collaborator = this.store.peekRecord('organization', res.data.id);
				this.set('selectedCollaborator', collaborator);
				this.isNew = false;
				result.resolve();
			})
			.catch((err) => {
				this.errors = Errors.mapResponseErrors(err);
				result.reject();
			});

		return result.promise;
	}
}
