/** @format */

import ApplicationAdapter from './application';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';


export default class ChatMessageAdapter extends ApplicationAdapter {
	urlForQuery(slug = {}) {
		if (slug.chatId) {
			if (slug.params) {
				const queryString = formatObjectAsSearchParams(slug.params);
				return `${this.host}/chats/${slug.chatId}/chat_messages?${queryString}`;
			} else {
				return `${this.host}/chats/${slug.chatId}/chat_messages`;
			}
		}
	}
}
