import { A } from '@ember/array';
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';
import NotifOptions from 'case-status/constants/notif-options';
import { dynamicAppointmentLabel } from 'case-status/helpers/dynamic-appointment-label';

@classic
export default class AppointmentCardList extends Component {
	@service ajax;
	@service currentUser;
	@service permissions;
	@service notifications;

	@tracked appointmentsSyncSucceeded;
	@tracked appointmentsSyncRequested = false;
	@tracked newAppointmentType = '';
	@tracked showNewForm = false;
	@tracked canShowSyncButton;

	client = false;

	get appointmentTypes() {
		const meetingTypes = ['meeting', 'virtual'];
		const clientMeetingTypeOptions = [];
		if (this.permissions.case?.treatment_log) {
			meetingTypes.push('medical');
			clientMeetingTypeOptions.push('medical');
		}
		return this.client ? A(clientMeetingTypeOptions) : A(meetingTypes);
	}

	init() {
		super.init(...arguments);
		if (!this.get('permissions.case.treatment_log')) {
			this.set('newAppointmentType', 'meeting');
		}
		this.setConditionals();
	}

	setConditionals() {
		this.canShowSyncButton =
			!this.client && this.permissions.firmSettings.calendar_appointment_sync;
	}

	handleSyncResponse(response) {
		const { message } = response;

		this.notifications.info(message, NotifOptions.info);

		this.appointmentsSyncSucceeded = true;
		this.appointmentsSyncRequested = false;
	}

	@action
	selectNewAppointmentType(type) {
		this.set('newAppointmentType', type);
	}

	@action
	updateShowNewForm(newState) {
		this.set('showNewForm', newState);
	}

	@action
	requestAppointmentsSync() {
		this.appointmentsSyncRequested = true;

		const caseId = this.model.theCase.id;

		if (caseId) {
			const url = `${ENV.host}/cases/${caseId}/sync_appointments`;

			// Display initial notification that syncing has started
			this.notifications.info(
				`We are processing your ${dynamicAppointmentLabel([
					this.permissions.case,
					true,
				])}. This may take a few minutes.`,
				NotifOptions.info,
			);

			return this.ajax
				.post(url)
				.then((response) => {
					// Notification of endpoint response message
					this.handleSyncResponse(response);
				})
				.catch((err) => {
					this.notifications.error(
						Errors.mapResponseErrors(err),
						NotifOptions.error,
					);
					this.appointmentsSyncRequested = false;
				});
		}
	}

	@action
	refreshPage() {
		window.location.reload();
	}
}
