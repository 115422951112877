/** @format */

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
	serialize() {
		let json = this._super(...arguments);

		switch (json.data.attributes.automation_type) {
			case 'date_based':
				delete json.data.attributes.days_of_week;
				delete json.data.attributes.days_after_event;
				break;
			case 'treatment_reminder':
				delete json.data.attributes.days_after_event;
				break;
			case 'recurring_message':
				delete json.data.attributes.days_after_event;
				break;
			case 'status_message':
				delete json.data.attributes.days_after_event;
				delete json.data.attributes.days_of_week;
				delete json.data.attributes.days_of_month;
				delete json.data.attributes.hour_of_day;
				break;
			case 'add_checklist_template':
				delete json.data.attributes.days_after_event;
				delete json.data.attributes.days_of_week;
				delete json.data.attributes.days_of_month;
				break;
			case 'add_checklist_template_chat':
				delete json.data.attributes.days_after_event;
				delete json.data.attributes.days_of_week;
				delete json.data.attributes.days_of_month;
				break;
		}

		return json;
	},
});
