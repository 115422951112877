/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default Route.extend({
	currentUser: service(),
	session: service(),

	model: function () {
		return A([]);
	},

	setupController(controller) {
		this._super(...arguments);
		controller.fetchNewData();
	},

	actions: {
		logout() {
			this.currentUser.logout();
		},

		transitionToCase(id) {
			this.transitionTo('app.organization.case', id);
		},
	},
});
