/** @format */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { paralegalDisplay } from 'case-status/helpers/paralegal-display';
import { tracked } from '@glimmer/tracking';

export default class ModalUserCreationStep1Component extends Component {
	@service currentUser;
	@tracked selectedRole;

	get roleOptions() {
		const firm = this.currentUser.get('user.firm');
		const roleOptions = [
			{
				display: attorneyDisplay([firm]),
				value: 'attorney', //* Model name
			},
		];
		if (firm?.isLawFirm) {
			roleOptions.push({
				display: paralegalDisplay([firm]),
				value: 'paralegal', //* Model Name
			});
		}
		return roleOptions;
	}

	get multipleRoleOptions() {
		if (this.roleOptions.length === 1) {
			this.selectNewUserRole(this.roleOptions[0]);
			return false;
		}
		return true;
	}

	get hasErrors() {
		return (
			this.showNewUserFirstNameError ||
			this.showNewUserLastNameError ||
			this.showNewUserEmailError
		);
	}

	get showNewUserFirstNameError() {
		return !this.args.model.firstName;
	}

	get requiredError() {
		return '*required';
	}

	get showNewUserLastNameError() {
		return !this.args.model.lastName;
	}

	get showNewUserEmailError() {
		return !this.args.model.emailAddress;
	}

	get showNewUserRoleError() {
		return !this.selectedRole;
	}

	@action
	selectNewUserRole(role) {
		this.selectedRole = role;
		this.args.model.role = role.display;
		this.args.model.model = role.value;
		this.args.model.newRole = role.value;
	}
}
