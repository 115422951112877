/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import Table from 'ember-light-table';
import { inject as service } from '@ember/service';
import { attorneyDisplay } from '../helpers/attorney-display';

export default Component.extend({
	searchTransaction: '',
	currentUser: service('current-user'),

	didUpdateAttrs() {
		this._super(...arguments);

		this.table.setRows(this.model || []);
	},

	columns: computed(function () {
		return [
			{
				label: 'Invoice ID',
				width: '150px',
				cellClassNames: 'light ellipsis',
				valuePath: 'invoiceId',
				cellComponent: 'transaction-internal-id-cell',
			},
			{
				label: 'Case ID',
				width: '150px',
				cellClassNames: 'light ellipsis',
				valuePath: 'caseId',
				cellComponent: 'transaction-case-id-cell',
			},
			{
				label: 'Internal ID',
				width: '150px',
				cellClassNames: 'light ellipsis',
				valuePath: 'internalId',
				cellComponent: 'transaction-internal-id-cell',
			},
			{
				label: 'Client',
				width: '250px',
				cellClassNames: 'light ellipsis',
				valuePath: 'readableClientId',
				cellComponent: 'transaction-client-cell',
			},
			{
				label: `Primary ${attorneyDisplay([
					this.get('currentUser.user.firm'),
				])}`,
				width: '250px',
				cellClassNames: 'light ellipsis',
				valuePath: 'primaryAttorneyName',
				cellComponent: 'transaction-primary-attorney-cell',
			},
			{
				label: 'Invoice Date',
				width: '250px',
				cellClassNames: 'light ellipsis',
				valuePath: 'date',
				cellComponent: 'transaction-invoice-date-cell',
			},
			{
				width: '200px',
				cellClassNames: 'light ellipsis',
				cellComponent: 'transaction-view-receipt-cell',
				sortable: false,
			},
		];
	}),

	table: null,

	init() {
		this._super(arguments);

		this.set(
			'table',
			Table.create({
				columns: this.columns,
				rows: this.model,
				enableSync: true,
			}),
		);
	},

	actions: {
		updateFilteredTransactions(value) {
			this.filterTransactions(value);
		},
		onColumnClick(column) {
			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				this.sortTransactions(columnName, direction);
			}
		},
	},
});
