/** @format */

import Component from '@ember/component';

export default Component.extend({
	isClicking: false,
	name: 'files',
	accepts: 'none',
	multiple: false, // Note: If set to true, file handler must be setup to handle multiple files
	icon: false,
	iconFlip: false,
	iconSize: false,

	actions: {
		triggerFilePicker() {
			if (!this.isClicking) {
				// Set isClicking to true to prevent multiple event stacks
				// When the file picker is clicked it opens a window prompt which pauses javascript mid functions and resumes once the prompt is closed
				// Once the javascript resumes set isClicking to false
				this.set('isClicking', true);
				let fileInput = this.element.querySelector('.file-upload');
				if (fileInput) {
					fileInput.click();
				}
				this.set('isClicking', false);
			}
		},
	},
});
