/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { A } from '@ember/array';
import { reject } from 'rsvp';
import { next } from '@ember/runloop';
import Errors from 'case-status/constants/errors';

@classic
export default class ModalBulkActionAssignPrimaryAttorneyComponent extends Component {
	@service store;
	@service currentUser;
	@service customBranding;

	isLoading = true;
	errors = [];
	showConfirmModal = false;

	get attorneys() {
		return this.store
			.peekAll('attorney')
			.filter(
				(attr) => !String(attr?.emailAddress).includes('@casestatus.com'),
			);
	}

	get sortedAttorneys() {
		return this.attorneys?.sortBy('lastName');
	}

	attorneysToAssign = A([]);

	get tooManySelectedError() {
		return this.sortedAddAttorney.length <= 1
			? false
			: 'Only 1 Primary can be selected';
	}

	didInsertElement(...args) {
		super.didInsertElement(...args);
		this.set('isLoading', false);
	}

	validate() {
		this.set('errors', []);
		if (this.attorneysToAssign.length < 1) {
			this.errors.push('Select a Primary to be assigned');
		}

		if (this.attorneysToAssign.length > 1) {
			this.errors.push('Only 1 Primary can be assigned');
		}

		if (this.errors.length > 0) {
			this.set('showErrors', true);
			return false;
		}

		return true;
	}

	@action
	onAttorneyToggled(row, isRemoving) {
		const attorney = row.get('content');
		if (isRemoving) {
			this.attorneysToAssign.pushObject(attorney);
		} else {
			this.attorneysToAssign.removeObject(attorney);
		}
	}

	@action
	submit() {
		const isValid = this.validate();
		if (!isValid) return reject;

		this.set('showConfirmModal', true);
	}

	@action
	confirmBulkAction() {
		return this.submitCallback(this.attorneysToAssign).catch((errs) => {
			this.set('errors', Errors.mapResponseErrors(errs));
		});
	}
}
