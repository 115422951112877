/** @format */

import Model, { attr } from '@ember-data/model';
import { reads } from '@ember/object/computed';

export default class LanguageModel extends Model {
	@attr language;
	@attr name;

	@reads('language') code;

	//* Some languages share the same name, but have different codes.
	//* Creating a display attr to standardize how we display this
	//? Note: On the cases page the code comes first as that is what the API is sorting by
	get display() {
		return `${this.name} (${this.code})`;
	}
}
