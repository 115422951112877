/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	// Add / Remove has-modal class to body to lock scrolling behind modal
	didInsertElement() {
		$('html').addClass('has-modal');
	},
	willDestroyElement() {
		$('html').removeClass('has-modal');
	},

	stepCaseInfo: computed('step', function () {
		return this.step === 2;
	}),

	stepClientInfo: computed('step', function () {
		return this.step === 1;
	}),
	isCreatingNewClient: false,

	step: 1,

	init() {
		this._super(...arguments);
		this.set('case', this.store.createRecord('case'));
		this.set('clients', []);
	},

	actions: {
		close() {
			this.case.rollbackAttributes();
			this.close();
		},

		nextStep(model, result, showAddCaseModal = false) {
			if (this.step === 1) {
				this.set('clients', model);
				this.set('step', 2);
			} else if (this.step === 2) {
				if (!model.get('description')) {
					model.set('description', '');
				}
				this.set('case', model);
				this.addCase(this.case, this.clients, showAddCaseModal, result);
			}
			return result;
		},

		previousStep() {
			const step = this.step;
			this.set('step', step - 1);
		},
	},
});
