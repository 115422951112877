/** @format */

import Component from '@ember/component';
import { alias, reads } from '@ember/object/computed';
import { observer, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { dynamicCaseLabel } from '../helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';
import { resolve, reject } from 'rsvp';
import { isString } from 'case-status/constants/data-types';

const updateTypes = [
	'status_change',
	'on_hold',
	'off_hold',
	'closed',
	'nps_notification',
];
const reminderTypes = [
	'reminder_appointment',
	'reminder_log_treatment',
	'treatment_logged',
];

// eslint-disable-next-line ember/no-classic-classes, ember/require-tagless-components
export default Component.extend({
	currentUser: service(),
	session: service(),
	store: service(),
	company: service(),
	notifications: service(),
	ajax: service(),
	permissions: service(),
	activityTracking: service(),
	router: service(),

	userType: alias('session.data.authenticated.user_type'),
	translatedContent: reads('message.translatedContent'),
	originalContent: reads('message.originalContent'),

	showReadReceipts: false,
	hasReadReceipts: computed.gt('message.messageReadBy.length', 0),

	unread: computed('notification.readAt', function () {
		if (!this.notification) {
			return false;
		}
		return this?.notification?.get('readAt') === null;
	}),

	currentUserLanguage: computed('currentUser', function () {
		const lang =
			this?.currentUser?.get('user.userLanguage') ||
			this?.currentUser?.get('user.firm.firmSettings.firmSettings.language');
		if (isString(lang)) {
			return lang.substring(0, 2);
		} else return null;
	}),

	messageLanguage: computed(
		'message.{targetLanguage,sender.userLanguage}',
		'caseModel.targetLanguage',
		function () {
			let lang =
				this.message?.get('targetLanguage') ||
				this.caseModel?.get('targetLanguage') ||
				this.message?.get('sender.userLanguage');
			if (isString(lang)) {
				return lang.substring(0, 2);
			} else return null;
		},
	),

	showTranslateButton: computed(
		// eslint-disable-next-line ember/use-brace-expansion
		'isClient',
		'message.senderType',
		'translatedContent',
		'permissions.case.allow_message_translation',
		'caseModel.translationEnabled',
		'message.file.url',
		'notification.file.url',
		'messageLanguage',
		'currentUserLanguage',
		function () {
			return (
				!this.isClient &&
				this?.message?.get('senderType') == 'client' &&
				!this?.translatedContent &&
				this?.permissions?.get('case.allow_message_translation') &&
				this.caseModel?.get('translationEnabled') &&
				!this?.message?.get('file.url') &&
				!this?.notification?.get('file.url') &&
				this.messageLanguage &&
				this.currentUserLanguage &&
				this.messageLanguage != this.currentUserLanguage
			);
		},
	),

	integrationErrorObserver: observer('message.integrationError', function () {
		this.set('integrationError', this?.message?.get('integrationError'));
	}),

	init() {
		this._super(...arguments);

		this.setup();
	},

	isOwner: computed(
		'message.senderChildId',
		'currentUser.user.id',
		function () {
			return (
				this?.message?.get('senderChildId') == this?.currentUser?.get('user.id')
			);
		},
	),

	setup() {
		const notification = this.notification;
		if (notification) {
			//If the notification is a chat notification get the caseId from the chat
			if (notification.constructor.modelName === 'chat-notification') {
				const chat = this.store.peekRecord('chat', notification.chatId);
				if (chat && chat.caseId) {
					this.set('caseId', chat.caseId);
				} else {
					let _this = this;
					this.store
						.findRecord('chat', notification.get('chatId'))
						.then((chat) => {
							_this.set('caseId', chat.get('caseId'));
							if (chat.isInternal) {
								this.set('goToInternalTab', true);
							} else {
								this.set('goToCollaborationTab', true);
							}
						});
				}
			} else {
				this.set('caseId', notification.get('caseId'));
			}
			this.set('senderName', notification.get('senderName'));
			this.set('content', notification.get('content'));
			this.set('createdAt', notification.get('createdAt'));
			this.set('readableId', notification.get('readableId'));
		}

		const message = this.message;
		if (message) {
			this.set('messageId', message.get('id'));
			this.set('caseId', message.get('case.id'));
			this.set('senderName', message.get('senderName'));

			this.set('content', message.get('content'));
			this.set('createdAt', message.get('createdAt'));
			this.set('sentAt', message.get('sentAt'));
			this.set('integrationError', message.get('integrationError'));
			this.set('sentAsText', message.get('sentAsText'));
		}
		//Trigger observer
		this.senderNameObserver;
		this.caseNotificationsObserver;

		//* Don't show mark options on own messages
		if (
			this.currentUser.get('user.id') == this.message?.get('senderChildId') ||
			this.currentUser.get('user.id') == this.notification?.get('senderChildId')
		) {
			this.set('hasMarkRead', false);
		}
	},
	truncatedReadableId: computed('readableId', function () {
		const content = this.readableId;

		//If there's no readableId, nothing to truncate.
		if (!content) {
			return null;
		}

		if (content.length > 15) {
			return content.substring(0, 15) + '...';
		}
		return this.readableId;
	}),
	caseNotificationsObserver: observer(
		'caseNotifications',
		'caseNotifications.[]',
		function () {
			const caseNotifications = this.caseNotifications;

			if (caseNotifications) {
				//Find the matching notification from the case (if available) and set the notification to enable mark as read button
				const notification = caseNotifications.find((n) => {
					return n.get('messageId') === this.messageId;
				});
				if (notification) {
					this.set('notification', notification);
				}
			}
		},
	).on('init'),
	isAudioRequest: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'audio_request';
	}),
	isAudioVideoRequest: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'audio_video_request';
	}),
	isVideoRequest: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'video_request';
	}),
	isUpdate: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'update';
	}),

	isChecklistItemCompleted: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'checklist_item_completed';
	}),

	isESign: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;

		return ['signature_requested'].includes(messageComponentType);
	}),

	isESignRatified: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;

		return ['document_ratified'].includes(messageComponentType);
	}),

	isReminder: computed('messageComponentType', function () {
		const messageComponentType = this.messageComponentType;
		return messageComponentType === 'reminder';
	}),

	messageType: alias('message.type'),
	messageComponentType: computed('messageType', function () {
		const messageType = this.messageType;
		const isUpdate = updateTypes.includes(messageType);
		const isReminder = reminderTypes.includes(messageType);

		return isUpdate ? 'update' : isReminder ? 'reminder' : messageType;
	}),

	isFileImage: computed('message.file.extension', function () {
		if (!this.message || !this?.message?.get('file')) {
			return false;
		}
		//Only allowable image files
		return ['jpg', 'jpeg', 'png'].includes(
			this?.message?.get('file.extension'),
		);
	}),

	updateType: computed('message.{type,statusNumber}', function () {
		const messageType = this?.message?.get('type');
		const statusNumber = this?.message?.get('statusNumber');

		if (reminderTypes.includes(messageType)) {
			if (statusNumber) return `status-${statusNumber}`;

			return 'status-1'; //* Default styling just in case
		}

		switch (messageType) {
			case 'status_change':
				return `status-${statusNumber}`;
			case 'on_hold':
				return 'on-hold';
			case 'off_hold':
				return 'off-hold';
			case 'closed':
				return 'closed';
			default:
				return 'msg';
		}
	}),

	updateTitle: computed('message.type', 'company', function () {
		const messageType = this?.message?.get('type');
		const caseLabel = capitalize(dynamicCaseLabel([this.company]));

		switch (messageType) {
			case 'status_change':
				return 'Status Updated';
			case 'on_hold':
				return `${caseLabel} On Hold`;
			case 'off_hold':
				return `${caseLabel} Taken Off Hold`;
			case 'closed':
				return `${caseLabel} Closed`;
			case 'reminder_appointment':
				return 'Upcoming Appointment';
			case 'reminder_log_treatment':
				return 'Reminder to Log Treatment';
			case 'treatment_logged':
				return 'New Treatment Logged';
			default:
				return 'msg';
		}
	}),

	sentAsText: computed('message.sentAsText', 'isUpdate', function () {
		const isSms = this?.message?.get('sentAsText');
		const isUpdate = this.isUpdate;

		// No updates should come through as sentAsText, but just in case it did, we will make sure it's not displayed incorrectly.
		return isSms && !isUpdate;
	}),

	hideFromSearch: computed(
		'messageSearchText',
		'message.{senderName,content,file,createdAt}',
		'updateTitle',
		function () {
			if (this.messageSearchText) {
				if (
					this.message
						.get('content')
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					this.message
						.get('senderName')
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					this.updateTitle
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					moment(this.message.get('createdAt'))
						.format('MM/DD/YYYY hh:mm')
						.toString()
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					(this.message.get('file') &&
						this.message.get('file').get('fileName') &&
						this.message
							.get('file')
							.get('fileName')
							.toLowerCase()
							.includes(this.messageSearchText.toLowerCase()))
				) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	),

	senderNameObserver: observer(
		'message.senderName',
		'notification.sender.name',
		function () {
			if (this?.message?.get('senderName') != null) {
				this.set('senderName', this?.message?.get('senderName'));
			} else if (this?.notification?.get('sender.name') != null) {
				this.set('senderName', this?.notification?.get('sender.name'));
			}
		},
	),

	showNotifs: false,
	hasErrors: computed('message.notifs.@each.{smsError,hide}', function () {
		return (
			this.message.get('notifs')?.filter((notif) => {
				return notif.smsError && !notif.hide;
			})?.length > 0
		);
	}),
	hasWarnings: computed('message.integrationError', function () {
		return this.message.get('integrationError');
	}),

	get isInInbox() {
		return `${this.router.get('currentRoute.name')}`.includes('inbox');
	},

	get clientSmsAllowed() {
		// if errors exist, evaluate
		if (this.hasErrors) {
			// if a case has multiple clients
			if (this.sortedClients.length > 1) {
				// set vars to tally if clients have opt out set to true or false
				let clientAllowSms = 0;
				// iterate through clients
				for (const client of this.sortedClients) {
					// increment clientAllowSms count by 1 if opt out is false
					if (!client.optOut) clientAllowSms++;
				}
				// if any client wants SMS: return true, else false
				return clientAllowSms > 0 ? true : false;
				// else one client
			} else {
				return !this.sortedClients[0].optOut;
			}
		}
	},

	get safeHtmlOptions() {
		return { allowedClasses: { p: ['display-inline'] } }
	},

	showMarkAsRead: computed(
		'isUserOnCase',
		'isOwner',
		'currentUser.user.notificationsOffForCase',
		'isInInbox',
		'notification',
		function () {
			const { isOwner, isUserOnCase, currentUser, isInInbox, notification } =
				this;
			if (isInInbox) return true;

			if (!notification) return false;

			const notificationsOffForCase = currentUser.get(
				'user.notificationsOffForCase',
			);

			if (isOwner || !isUserOnCase || notificationsOffForCase) return false;

			return true;
		},
	),

	actions: {
		markMessageRead() {
			const notification = this.notification;
			this.ajax
				.post(
					`${ENV.host}/messages/mark_as_read?message_id=${
						this.message?.id || this.notification?.messageId
					}`,
				)
				.then(() => {
					notification.set('readAt', new Date());
					this.caseModel.set(
						'newMessages',
						this.caseModel.get('newMessages') - 1,
					);

					try {
						//* Try to save locally only
						this.caseModel.localSave();
					} catch (error) {
						//* If localSave fails or errors for any reason, full patch
						this.caseModel.save();
					}

					let activityType;

					switch (this.message.type) {
						case 'message':
							activityType = 'readMessage';
							break;
						default:
							activityType = 'readAutomatedMessage';
							break;
					}

					if (
						this.currentUser.get('user.constructor.modelName') == 'client' &&
						activityType
					) {
						this.activityTracking.trackByCase(this.caseId, activityType);
					}
				})
				.catch(() => {
					this.set('error', 'Error! Please try again later.');
				});
		},
		markMessageReadForTeam() {
			const notification = this.notification;
			this.ajax
				.post(
					`${ENV.host}/messages/mark_as_read?message_id=${
						this.message?.id || this.notification?.messageId
					}&read_by_all=true`,
				)
				.then(() => {
					notification.set('readAt', new Date());
					this.caseModel.set(
						'newMessages',
						this.caseModel.get('newMessages') - 1,
					);

					try {
						//* Try to save locally only
						this.caseModel.localSave();
					} catch (error) {
						//* If localSave fails or errors for any reason, full patch
						this.caseModel.save();
					}
				})
				.catch(() => {
					this.set('error', 'Error! Please try again later.');
				});
		},
		unmarkMessageRead() {
			const notification = this.notification;
			this.ajax
				.post(
					`${ENV.host}/messages/mark_as_read?message_id=${
						this.message?.id || this.notification?.messageId
					}&is_read=false`,
				)
				.then(() => {
					notification.set('readAt', null);
					this.caseModel.set(
						'newMessages',
						this.caseModel.get('newMessages') + 1,
					);

					try {
						//* Try to save locally only
						this.caseModel.localSave();
					} catch (error) {
						//* If localSave fails or errors for any reason, full patch
						this.caseModel.save();
					}
				})
				.catch(() => {
					this.set('error', 'Error! Please try again later.');
				});
		},
		unmarkMessageReadForTeam() {
			const notification = this.notification;
			this.ajax
				.post(
					`${ENV.host}/messages/mark_as_read?message_id=${
						this.message?.id || this.notification?.messageId
					}&is_read=false`,
				)
				.then(() => {
					notification.set('readAt', null);
					this.caseModel.set(
						'newMessages',
						this.caseModel.get('newMessages') + 1,
					);

					try {
						//* Try to save locally only
						this.caseModel.localSave();
					} catch (error) {
						//* If localSave fails or errors for any reason, full patch
						this.caseModel.save();
					}
				})
				.catch(() => {
					this.set('error', 'Error! Please try again later.');
				});
		},

		toggleReadReceipts() {
			this.toggleProperty('showReadReceipts');
		},

		toggleNotifs() {
			this.toggleProperty('showNotifs');
		},

		resendAsTextMessage() {
			return this.ajax
				.post(
					`${ENV.host}/messages/${
						this.message?.id || this.notification?.messageId
					}/resend_sms`,
				)
				.then(() => {
					this.notifications.success('Request successfully sent', {
						canClose: true,
						autoClear: true,
						clearDuration: 5000,
					});
				})
				.catch((err) => {
					this.notifications.error(Errors.mapResponseErrors(err), {
						canClose: true,
						autoClear: true,
						clearDuration: 5000,
					});
				});
		},

		async translateMessage() {
			try {
				const translatedMessage = await this.ajax.post(
					`${ENV.host}//translate/message/${this.message.get('id')}`,
				);

				//* By pushing the returned updated model to the store, this should auto update the message and thus it's view as well
				this.store.pushPayload(translatedMessage);

				return resolve(translatedMessage);
			} catch (error) {
				this.notifications.error(Errors.mapResponseErrors(error), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});

				return reject(error);
			}
		},

		showImage(...args) {
			this.activityTracking.trackByCase(this.caseId, 'viewedFile');

			if (args[0] && this.showImage && typeof this.showImage == 'function') {
				this.showImage(...args);
			}
		},
	},
});
