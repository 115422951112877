/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';

import ValidationErrors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	type: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Type',
	}),
	interval: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Interval',
	}),
	message: validator('presence', {
		presence: true,
		message: ValidationErrors.presenceError,
		description: 'Message',
	}),
});

export default Model.extend(Validations, {
	createdAt: attr('date'),

	lastRunDate: attr('date'),
	lastRun: computed('lastRunDate', {
		get() {
			return this.lastRunDate;
		},
		set(key, value) {
			return this.set('lastRunDate', value);
		},
	}),

	interval: attr('number'),
	message: attr('string'),

	type: attr('string'),

	triggerType: attr('string'),
	triggerDays: attr(),

	triggerTime: attr('string'),
	company: service(),

	// Relationships
	case: belongsTo('case'),
	firm: belongsTo('firm'),

	// Computed
	typeLabel: computed('type', function () {
		const trigger = this.type;

		switch (trigger) {
			case 'no_case_changes':
				return `No ${capitalize(dynamicCaseLabel([this.company]))} Change`;
			case 'recurring_message':
				return 'Recurring Message';
			case 'treatment_reminder':
				return 'Treatment Reminder';
			default:
				return null;
		}
	}),
});
