/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { sort, alias } from '@ember/object/computed';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { attorneyDisplay } from '../helpers/attorney-display';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

@classic
export default class ModalRemoveAttorneysComponent extends Component {
	//* Static Settings
	lastNameSort = ['lastName'];

	//* Services

	@service company;
	@service currentUser;

	//* Aliases
	@alias('company.info') firm;

	//* Tracked Properties

	@tracked searchString;
	@tracked attorneysToRemove = A([]);
	@tracked isLoading = true;
	@tracked model = A([]);

	//* Sorted Computed Properties

	@sort('attorneysToRemove', 'lastNameSort') sortedRemoveAttorneys;
	@sort('filteredAttorneys', 'lastNameSort') sortedAttorneys;

	//* Computed Properties

	get searchTextValid() {
		const searchText = this.searchString;
		return (
			searchText &&
			typeof searchText == 'string' &&
			searchText !== '' &&
			searchText.trim() !== ''
		);
	}

	get filteredAttorneys() {
		const attorneys = this.filteredModel;
		const selectedAttorneyIds = this.attorneysToRemove.mapBy('id');
		if (!attorneys) {
			return A([]);
		}

		const searchText = this.searchString;
		const searchTextValid = this.searchTextValid;

		if (searchTextValid) {
			return A(
				attorneys
					.map((attorney) => {
						if (selectedAttorneyIds.includes(attorney.get('id'))) {
							attorney.set('isSelected', true);
						} else {
							attorney.set('isSelected', false);
						}
						return attorney;
					})
					.filter((attorney) => {
						return attorney
							.get('name')
							.toLowerCase()
							.includes(searchText.toLowerCase());
					}),
			);
		}

		return attorneys.map((attorney) => {
			if (selectedAttorneyIds.includes(attorney.get('id'))) {
				attorney.set('isSelected', true);
			} else {
				attorney.set('isSelected', false);
			}
			return attorney;
		});
	}

	get filteredModel() {
		if (!this.model) return A([]);
		return this.model.filter((attorney) => {
			return !attorney.get('emailAddress').includes('@casestatus.com');
		});
	}

	get attorneysToRemoveCount() {
		const attorneysToRemove = this.attorneysToRemove || A([]);

		//* Default to 0 if length property is missing
		return attorneysToRemove?.length || 0;
	}

	//* Lifecycle Hooks

	init() {
		super.init(...arguments);
		this.fetchNewData();
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		$('html').addClass('has-modal');
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
		$('html').removeClass('has-modal');
		this.set('attorneysToRemove', A([]));
		this?.model?.forEach((attorney) => {
			attorney?.set('isSelected', false);
		});
	}

	//* Methods

	fetchNewData() {
		this.set('isLoading', true);

		this.store
			.findAll('attorney')
			.then((attorneys) => {
				//Avoid calling set on destroyed component
				if (this.isDestroyed || this.isDestroying) {
					return;
				}
				this.model.clear();
				this.model.pushObjects(attorneys.toArray());

				next(() => {
					this.set('isLoading', false);
				});
			})
			.catch((err) => {
				this.set('errors', Errors.mapResponseErrors(err));
				next(() => {
					this.set('isLoading', false);
				});
			});
	}

	get titleText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const type = attorneyDisplay([this.company.info, 2, true]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Remove ${type} from (${caseCountText}) ${cases}`;
	}

	get actionableBtnText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const attorneysToAddCount = this.attorneysToRemove?.length || 0;
		const type = attorneyDisplay([
			this.company.info,
			attorneysToAddCount,
			true,
		]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Remove (${attorneysToAddCount}) ${type} from (${caseCountText}) ${cases}`;
	}

	//* Actions
	@action
	removeAttorneysButtonPressed() {
		const result = defer();
		this.set('errors', null);

		if (this.attorneysToRemoveCount === 0) {
			this.set('errors', [
				`At least one ${attorneyDisplay([
					this.currentUser.get('user.firm'),
				]).toLowerCase()} should be selected`,
			]);
			result.reject();
		} else {
			this.removeAttorneysFromCase(this.attorneysToRemove)
				.then((res) => {
					this.set('attorneysToRemove', A([]));
					result.resolve(res);
				})
				.catch((resp) => {
					this.set('errors', Errors.mapResponseErrors(resp));
					result.reject(resp);
				});
		}

		return result.promise;
	}

	@action
	onAttorneyToggled(row, isRemovingAttorney) {
		const attorney = row.get('content');
		if (isRemovingAttorney) {
			this.attorneysToRemove.pushObject(attorney);
		} else {
			this.attorneysToRemove.removeObject(attorney);
		}
	}
}
