/** @format */

import { defer } from 'rsvp';
import Component from '@ember/component';

const markOptions = ['for me', 'for team'];

export default Component.extend({
	tagName: '',
	isLoading: false,

	options: markOptions,

	selectedOption: null,

	actions: {
		buttonClicked() {
			this?.set('isLoading', true);
			const result = defer();
			this?.markAsRead(result);
			result.promise
				.then(() => {
					this?.set('isLoading', false);
				})
				.catch((err) => {
					this?.set('error', 'Error! Please try again.');
					this?.set('isLoading', false);
				});
		},

		handleMouseEnter() {
			this?.set('expand', true);
		},
		handleMouseExit() {
			this?.set('expand', false);
		},
	},
});
