/** @format */

import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import { alias } from '@ember/object/computed';
import moment from 'moment';
import { defer } from 'rsvp';
import { validator, buildValidations } from 'ember-cp-validations';
import Errors from '../../../constants/errors';
import ENV from 'case-status/config/environment';
import { inject as service } from '@ember/service';
import CaseMixin from '../../../mixins/controller/case';
import subTabs from '../../../constants/chat-sub-tabs';
import { debounce, next } from '@ember/runloop';
import { A } from '@ember/array';
import { captureException } from 'logrocket';

const Validations = buildValidations({
	newMessage: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Message',
	}),
});

export default Controller.extend(Validations, CaseMixin, {
	resourceMetadata: service(),
	currentUser: service(),
	store: service(),
	ajax: service(),
	pubnub: service('pubnub-service'),

	subTabs,

	currentSubTab: 'Chat',

	organization: alias('currentUser.user.organization'),

	//* Permissions
	chat: null,
	permissions: computed('chat', function () {
		return {
			canChat: this.chat?.get('canChat'),
			showCaseStatus: this.chat?.get('showCaseStatus'),
			showClientNames: this.chat?.get('showClientNames'),
		};
	}),
	canChat: alias('permissions.canChat'),
	showCaseStatus: alias('permissions.showCaseStatus'),
	showClientName: alias('permissions.showClientNames'),

	isUserOnCase: computed('theCase.users.[]', 'currentUser.user', function () {
		const theCase = this.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}),

	//* Case Info
	theCase: alias('model.theCase'),
	caseId: alias('theCase.id'),
	caseDate: computed('theCase.date', function () {
		const caseDate = this.get('theCase.date');

		if (caseDate) return moment(caseDate).format('MMM DD, YYYY');
		else return 'No Date';
	}),
	caseType: alias('theCase.caseType.name'),
	caseStage: alias('theCase.caseStatus.name'),
	caseUpdatedAt: computed('theCase.updatedAt', function () {
		const updatedAt = this.get('theCase.updatedAt');
		return moment(updatedAt).format('MM/DD/YYYY');
	}),

	//* Chat Info
	chatObserver: observer('model', function () {
		this.store.query('chat', { caseId: this.theCase.id }).then((chats) => {
			const chatsArray = chats.toArray();
			const basicChat = chatsArray[0];
			// The BE is expecting a case relationship on the Chat, when
			// We query for all case chats, we are not returning the case relationship
			// for perfomance reasons
			basicChat.case = this.theCase;
			this.set('chat', basicChat);
		});
	}),

	//* Message Info
	isWritingMessage: false,
	showMessageError: false,
	messageErrors: null,
	newMessage: null,

	showMessageActionsModal: false,
	showViewRequestModal: false,
	messageSearchText: '',

	showUsersModal: false,

	//* Life Cycle Methods *//

	//* Custom Methods *//
	reloadChat() {
		const chat = this.chat;
		chat.reload();
		chat.fetchFormResponses();
	},

	refreshRequestList() {
		const chat = this.chat;
		chat.fetchFormResponses();
	},

	//* Viewport Properties
	inViewport: service(),
	loaderInViewPort: false,
	showLoader: computed('isLoadingMessages', 'loaderInViewPort', function () {
		return this.isLoadingMessages;
	}),

	didEnterViewPort() {
		if (this.chat?.get('chatMessages.length')) {
			this.set('loaderInViewPort', true);
			if (this.page || this?.meta?.total_pages) {
				this.fetchMoreChatMessages();
			}
		}
	},

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreCaseMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreChatMessages();
			}
		}, 500);
	},

	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	},

	didExitViewPort() {
		this.set('loaderInViewPort', false);
	},

	async handleIncomingMessage(newMessage) {
		if (
			!newMessage ||
			(await newMessage?.chat?.get('id')) != this?.chat?.get('id')
		)
			return;

		//* Check to see if the message is already in the list
		const message = this.chatMessages.find((msg) => {
			return newMessage.id == msg.id;
		});

		if (message) return;

		this.chatMessages.unshiftObject(newMessage);
		return;
	},

	//* Chat Messages Properties
	size: 50,
	page: 1,
	meta: null,
	isLoadingMessages: true,
	chatMessages: A([]),
	initialization: function () {
		this.pubnub.newChatMessageCallbacks.push((...args) => {
			this.handleIncomingMessage(...args);
		});
	}.on('init'),
	chatChanged: observer('chat', function () {
		this.set('isLoadingMessages', true);
		debounce(this, 'fetchChatMessages', 500);
	}),

	fetchChatMessages() {
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.set('page', 1);

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Clear the old chatMessages
				this.chatMessages.clear();

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	fetchMoreChatMessages() {
		if (
			!this.meta ||
			this.meta.total_pages <= this.page ||
			this.meta.total_results <= this.chatMessages.length
		)
			return;
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.incrementProperty('page');

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	//* Actions *//
	actions: {
		markChatNotificationsAsRead() {
			const chatNotifications =
				this.get('chat.chatNotifications').toArray() || [];
			const theCase = this.theCase;

			chatNotifications.forEach((chatNotification) => {
				chatNotification.set('readAt', new Date());
				chatNotification.save();
				if (theCase.get('newChatMessages') > 0) {
					theCase.decrementProperty('newChatMessages');
				}
			});
			this.chat.calculateUnreads();
		},

		postFile(formData = new FormData(), result = defer()) {
			formData.append('chat_id', this.get('chat.id'));

			const url = `${ENV.host}/chat_files`;
			const data = formData;

			this.ajax
				.post(url, {
					processData: false,
					contentType: false,
					data: data,
				})
				.then(() => {
					this.fetchChatMessages();
					return result.resolve();
				})
				.catch((response) => {
					return result.reject(Errors.mapResponseErrors(response));
				});
		},
		selectSubTab(tab) {
			if (this.currentSubTab == tab) return false;
			const subTabs = this.subTabs || [];
			const notValidTab = !subTabs.includes(tab);

			if (notValidTab) return false;

			if (tab == 'Requests') {
				//* Go ahead and get the latest set of requests when going to this tab
				this.chat.fetchFormResponses();
			}

			this.set('currentSubTab', tab);
		},

		async sendChatMessage(result = defer()) {
			const chat = this.chat;
			const content = this.newMessage;

			//* Add 10 seconds to try and account for variance in message time and system time
			const createdAt = moment().add(10, 'seconds').toDate();

			if (chat) {
				if (content) {
					const newChatMessage = this.store.createRecord('chat-message', {
						content,
						chat,
						createdAt,
						senderName: this.get('currentUser.user.name'),
					});

					return newChatMessage
						.save()
						.then((msg) => {
							/* 
                For some reason this keeps flip flopping from allowing 
                result.resolve to result.result.resolve and cause a full break
                in the application, so I'm implementing a check for if one or
                the other exists to use that, as well as a try catch to catch the 
                error before it can bubble up and cause the action to crash and
                make it look like the message is not sending without a full page/app 
                refresh
              */

							this.chatMessages.unshiftObject(msg);

							try {
								if (typeof result?.result?.resolve == 'function') {
									result.result.resolve(msg);
								} else if (typeof result?.resolve == 'function') {
									result.resolve(msg);
								}
							} catch (error) {
								//* We will capture the error for more tracking in log rocket
								captureException(error);
							}
						})
						.catch((err) => {
							newChatMessage.rollbackAttributes();
							this.set('errors', Errors.mapResponseErrors(err));

							try {
								if (typeof result?.result?.reject == 'function') {
									result.result.reject(err);
								} else if (typeof result?.reject == 'function') {
									result.reject(err);
								}
							} catch (error) {
								//* We will capture the error for more tracking in log rocket
								captureException(error);
							}
						});
				} else {
					this.set(
						'errors',
						Errors.mapResponseErrors({
							payload: { error: 'Message cannot be blank' },
						}),
					);

					try {
						if (typeof result?.result?.reject == 'function') {
							return result.result.reject(err);
						} else if (typeof result?.reject == 'function') {
							return result.reject(err);
						}
					} catch (error) {
						//* We will capture the error for more tracking in log rocket
						captureException(error);
					}
				}
			} else {
				try {
					if (typeof result?.result?.reject == 'function') {
						return result.result.reject();
					} else if (typeof result?.reject == 'function') {
						return result.reject();
					}
				} catch (error) {
					//* We will capture the error for more tracking in log rocket
					captureException(error);
				}
			}
		},

		showLightboxImage(img) {
			this.set('lightboxImage', img);
		},

		toggleShowMessageActionsModal() {
			this.toggleProperty('showMessageActionsModal');
		},

		toggleShowViewRequestModal(requestResponse) {
			if (requestResponse && !this.showViewRequestModal) {
				this.set('requestResponse', requestResponse);
			} else {
				this.set('requestResponse', null);
				this.chat.fetchFormResponses();
			}

			this.toggleProperty('showViewRequestModal');
		},

		toggleWritingMessage() {
			this.toggleProperty('isWritingMessage');
		},

		toggleShowUsersModal() {
			this.toggleProperty('showUsersModal');
		},

		validateNewMessage(result = defer()) {
			if (
				this.get('validations.attr.newMessage.message') &&
				this.isWritingMessage
			) {
				this.set('showMessageError', true);
				return result.reject();
			} else {
				this.send('sendChatMessage', result);
				result = result.promise
					? result
					: result.result
					? result.result
					: defer();
				result.promise
					.then(() => {
						this.set('isWritingMessage', false);
						this.set('newMessage', '');
						this.set('showMessageError', false);
					})
					.catch((error) => {
						this.set('showMessageError', true);
					});
			}
		},
	},
});
