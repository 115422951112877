/** @format */

import ApplicationSerializer from './application';
export default ApplicationSerializer.extend({
	attrs: {
		createdAt: { serialize: false },
		sender: { serialize: false },
		senderName: { serialize: false },
		senderType: { serialize: false },
		senderChildId: { serialize: false },
	},
});
