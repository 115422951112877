/** @format */

import Route from '@ember/routing/route';

export default class ThirdPartyErrorRoute extends Route {
	setupController(controller, model) {
		super.setupController(controller, model);
		controller.set('errorMessage', model);
	}
}
