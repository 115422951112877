/** @format */

import ApplicationAdapter from './application';

export default class LanguageAdapter extends ApplicationAdapter {
	pathForType() {
		return `translate/languages`;
	}

	urlForFindRecord() {
		return this.urlForFindAll();
	}

	urlForQuery() {
		return this.urlForFindAll();
	}
}
