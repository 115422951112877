/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	firm: belongsTo('firm'),
	imported: attr('boolean'),
	name: attr('string'),
	pending: attr('boolean'),
	caseCount: attr('number'),
	importName: attr('string'),
	importNames: attr(),

	// Relationships
	caseStatuses: hasMany('case-status'),
	automationTemplates: hasMany('automation-template'),
});
