import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import Color from 'color';
import { htmlSafe } from '@ember/template';

export default Service.extend({
	currentUser: service('current-user'),

	activeTextHighlight: alias('currentUser.user.branding.activeTextHighlight'),
	brandingColor: alias('currentUser.user.branding.brandingColor'),
	brandingColorFunc: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				const brandingColor = this.get(
					'currentUser.user.branding.brandingColor',
				);
				const color = Color(`#${brandingColor}`);
				return color;
			} else {
				return null;
			}
		},
	),
	brandingColorModalOverlay: computed('brandingColorFunc', function () {
		if (this.brandingColorFunc) {
			return htmlSafe([this.brandingColorFunc.alpha('0.6').rgb().string()]);
		} else {
			return '';
		}
	}),

	backgroundColorStyle: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				return htmlSafe([
					`background-color: #${this.get(
						'currentUser.user.branding.brandingColor',
					)}`,
				]);
			} else {
				return '';
			}
		},
	),

	borderColorStyle: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				return htmlSafe([
					`border-color: #${this.get(
						'currentUser.user.branding.brandingColor',
					)} !important`,
				]);
			} else {
				return '';
			}
		},
	),

	buttonColorStyle: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				return htmlSafe(['color: white !important']);
			} else {
				return '';
			}
		},
	),

	brandingColorStyle: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				return htmlSafe([
					`color:#${this.get(
						'currentUser.user.branding.brandingColor',
					)} !important`,
				]);
			} else {
				return '';
			}
		},
	),

	/* If custom branding is enabled, attach the .white class to nav links, making them white by default */
	linkToClass: computed(
		'currentUser',
		'currentUser.user.branding.brandingColor',
		function () {
			if (this.get('currentUser.user.branding.brandingColor')) {
				return 'white';
			} else {
				return '';
			}
		},
	),

	/* If custom branding is enabled, attempt to use activeTextHighlight to style active/hovered nav links */
	linkHoverStyle: computed(
		'currentUser',
		'currentUser.user.branding.activeTextHighlight',
		function () {
			if (this.get('currentUser.user.branding.activeTextHighlight')) {
				return htmlSafe([
					`--hover-color: #${this.get(
						'currentUser.user.branding.activeTextHighlight',
					)} !important`,
				]);
			} else {
				return '';
			}
		},
	),

	logoStyle: computed(
		'currentUser',
		'currentUser.user.branding.url',
		function () {
			if (this.get('currentUser.user.branding.url')) {
				return htmlSafe([
					`background-image: url(${this.get('currentUser.user.branding.url')})`,
				]);
			} else {
				return '';
			}
		},
	),
});
