import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class ModalsBaseModalComponent extends Component {
  constructor() {
    super(...arguments);
    document.documentElement.classList.add("has-modal");
  }

  willDestroy() {
    super.willDestroy(...arguments);
    document.documentElement.classList.remove("has-modal");
  }

  @action close() {
    const { onClose } = this.args;
    if (typeof onClose === "function") {
      onClose();
    }
  }
}
