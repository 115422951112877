/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Changeset from 'ember-changeset';
import moment from 'moment';
import { next } from '@ember/runloop';

export default Component.extend({
	Changeset,
	tagName: '',
	currentUser: service(),
	permissions: service(),
	store: service(),
	hourDate: null,
	minuteDate: null,
	monthDate: null,
	amOrPm: null,
	deleteState: false,
	dateChanged: false,

	init(...args) {
		this._super(...args);
		this.timezone =
			this.currentUser.get('user.firm.timezone') || 'America/New_York';

		this.changeset = new this.Changeset(this.treatment);
		let localizeDateTime = moment.tz(
			this.get('treatment.appointmentDate'),
			this.timezone,
		);
		if (this.isNewTreatment) {
			this.set('hourDate', moment().format('hh'));
			this.set('minuteDate', moment().format('mm'));
			this.set('amOrPm', moment().format('a'));
			this.set('monthDate', moment().add(1, 'days').format('MM/DD/YYYY'));
		} else {
			this.set('hourDate', localizeDateTime.format('hh'));
			this.set('minuteDate', localizeDateTime.format('mm'));
			this.set('amOrPm', localizeDateTime.format('a'));
			this.set('monthDate', localizeDateTime.format('MM/DD/YYYY'));
		}
	},

	hourOptions: computed(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	minuteOptions: computed(() => {
		return Array.from(Array(60).keys()).map((x) => {
			let str = x.toString(10);
			if (str < 10) {
				str = '0' + str;
			}
			return str;
		});
	}),
	amOrPmOptions: computed(() => ['AM', 'PM']),

	isNewTreatment: computed('treatment.createdAt', function () {
		return !this.get('treatment.createdAt');
	}),

	isTreatment: computed('changeset', function () {
		return true;
	}),

	appointmentDateError: computed(
		'monthDate',
		'hourDate',
		'minuteDate',
		'amOrPm',
		'currentUser.user.firm.timezone',
		function () {
			if (this.isTreatment) {
				return false;
			}
			const timeString = `${this.monthDate} ${this.hourDate}:${
				this.minuteDate
			} ${this.amOrPm.toUpperCase()}`;

			const now = moment(new Date()).tz(this.timezone, true);
			if (
				moment(timeString, 'MM/DD/YYYY hh:mm a')
					.tz(this.timezone, true)
					.isBefore(now)
			) {
				return true;
			}
			return false;
		},
	),

	validate() {
		let isValid = true;
		let errors = null;

		if (!this.get('changeset.officeName')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.officeName = { message: 'You must include a provider name' };
		}

		if (!this.get('changeset.treatmentType.name')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.treatmentType = {
				message: 'You must indicate a type of treatment',
			};
		}

		if (!this.get('changeset.description')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.description = { message: 'You must include a description' };
		}

		if (this.isTreatment && !this.get('changeset.feelingRating')) {
			isValid = false;
			if (!errors) {
				errors = {};
			}
			errors.feelingRating = {
				message: 'You must indicate a feeling about the experience',
			};
		}

		return {
			isValid,
			errors,
		};
	},

	actions: {
		selectTreatmentType(treatmentType) {
			this.set('changeset.treatmentType', treatmentType);
		},

		onDateChangeHandler(date) {
			this.set('monthDate', moment(date).format('MM/DD/YYYY'));
			this.set('dateChanged', true);
		},

		onHourChangeHandler(hour) {
			let hourString = hour.toString();
			if (hour < 10) {
				hourString = '0' + hourString;
			}
			this.set('hourDate', hourString);
			this.set('dateChanged', true);
		},

		onMinuteChangeHandler(minute) {
			this.set('minuteDate', minute.toString());
			this.set('dateChanged', true);
		},

		onAmpmChangeHandler(amOrPm) {
			this.set('amOrPm', amOrPm);
			this.set('dateChanged', true);
		},

		submit(changeset) {
			if (
				!this.isNewTreatment &&
				!changeset.get('isDirty') &&
				!this.dateChanged
			) {
				if (this.onAfterRollback) {
					this.onAfterRollback();
				}
				return;
			}
			const timeString = `${this.monthDate} ${this.hourDate}:${
				this.minuteDate
			} ${this.amOrPm.toUpperCase()}`;

			const apptDateTime = moment
				.tz(timeString, 'MM/DD/YYYY hh:mm a', this.timezone)
				.utc()
				.toDate();

			const now = moment(new Date()).tz(this.timezone, true);
			if (
				!this.isTreatment &&
				moment(timeString, 'MM/DD/YYYY hh:mm a')
					.tz(this.timezone, true)
					.isBefore(now)
			) {
				return;
			}
			this.set('changeset.appointmentDate', apptDateTime);
			if (this.isTreatment) {
				this.set(
					'changeset.treatmentDate',
					moment().tz(this.timezone).utc().toDate(),
				);
			}
			const validations = this.validate();
			if (validations.isValid) {
				return changeset
					.save()
					.then((res) => {
						if (this.onAfterSave) {
							this.onAfterSave(res);
						}

						if (this.onAfterRollback) {
							this.onAfterRollback();
						}
					})
					.catch(() => {
						this.set('errors', {
							server: {
								message: 'There was an error saving this in our system.',
							},
						});
					});
			} else {
				this.set('errors', validations.errors);
			}
		},

		rollback(callback) {
			this.set(
				'hourDate',
				moment(this.get('treatment.appointmentDate')).format('hh'),
			);
			this.set(
				'minuteDate',
				moment(this.get('treatment.appointmentDate')).format('mm'),
			);
			this.set(
				'monthDate',
				moment(this.get('treatment.appointmentDate')).format('MM/DD/YYYY'),
			);
			this.set(
				'amOrPm',
				moment(this.get('treatment.appointmentDate')).format('a'),
			);

			const res = this.changeset.rollback();

			if (callback && typeof callback == 'function') {
				// run the callback in the next runloop
				next(() => {
					callback();
				});
			}

			return res;
		},

		delete() {
			this.treatment.deleteRecord();
			this.treatment.save().then(() => {
				if (this.onAfterSave) {
					this.onAfterSave();
				}
				if (this.onAfterDelete) {
					this.onAfterDelete();
				}
			});
		},

		selectMedicalProvider(medicalProvider) {
			this.set('selectedMedicalProvider', medicalProvider);

			this.changeset.setProperties({
				providerName: medicalProvider?.doctorName,
				officeName: medicalProvider?.officeName,
				treatmentType: this.store
					.peekAll('treatmentType')
					.find((tt) => tt.name == medicalProvider.providerType),
				address: medicalProvider.address,
			});
		},

		clearSelectedMedicalProvider() {
			this.set('selectedMedicalProvider', null);
			this.send('rollback');
		},

		cancel() {
			this.send('rollback', () => {
				if (this.onAfterRollback && typeof this.onAfterRollback == 'function') {
					this.onAfterRollback();
				}
			});
		},
	},
});
