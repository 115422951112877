/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	tagName: 'div',
	attributeBindings: ['style'],
	classNameBindings: ['tableClasses', '_defaultClassNames'],
	_defaultClassNames: 'cs-table col-xs-12 row full-width',

	_hasNoData: computed('model', function () {
		const model = this.model;
		return !model || model.length == 0;
	}),

	style: computed('height', 'width', function () {
		if (!this.height && !this.width) return null;

		let style = '';

		if (this.height) {
			style += ` height: ${this.height};`;
		}

		if (this.width) {
			style += ` width: ${this.width};`;
		}

		`${style}`.trim();

		if (style) return style;
		return null;
	}),

	columns: computed(function () {
		return [];
	}),

	headers: computed('columns', function () {
		const columns = this.columns;
		return columns.map((c) => {
			return {
				label: c.label,
				colSize: c.colSize,
			};
		});
	}),

	rows: computed('model', 'columns', function () {
		const columns = this.columns;
		const model = this.model;

		if (!model || !columns || !model.length || !columns.length) return [];

		return model.map((m) => {
			const row = {};
			row.cells = columns.map((c) => {
				row.onRowClick = c.onRowClick;
				row.style = c.rowStyle;
				row.rowActions = this.rowActions || {};
				row.rowComponent = c.rowComponent;
				row.extraActions = this.extraActions || {};
				return {
					value:
						c.format && typeof c.format == 'function'
							? c.format(m[c.value])
							: m[c.value],
					content: c.content,
					cellClassNames: c.cellClassNames,
					cellComponent: c.cellComponent,
					cellActions: this.cellActions || {},
					extraActions: this.extraActions || {},
					style: c.cellStyle,
					hidden: c.hidden,
					colSize: c.colSize,
					align: c.align,
					onCellClick: c.onCellClick,
					rawData: m,
				};
			});
			return row;
		});
	}),

	headerClasses: '',
	rowClasses: '',
	cellClasses: '',

	cellActions: null,
	rowActions: null,
	extraActions: null,
});
