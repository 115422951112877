/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { observer } from '@ember/object';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend({
	currentUser: service(),
	company: service(),
	store: service(),
	permissions: service(),
	firm: alias('currentUser.user.firm'),
	hasInviteMsg: false,
	msgBody: '',
	msgAttachment: alias('fileData.fd'),
	msgBodyObserver: observer('msgBody', function () {
		const msgBody = this.msgBody || '';

		if (msgBody.includes('app.casestatus.com/download')) {
			this.set('hasInviteMsg', true);
		} else {
			this.set('hasInviteMsg', false);
		}
	}).on('init'),

	inviteMsg: '',

	get _languages() {
		if (!this.permissions.firmSettings.allow_message_translation) return null;
		if (this.languages) return this.languages;
		const peekedLanguages = this.store.peekAll('language');
		if (peekedLanguages.length > 0) return peekedLanguages;

		return this.store.findAll('language').then((res) => res);
	},

	get _targetLanguage() {
		return this.targetLanguage;
	},

	set _targetLanguage(value) {
		void value;
	},

	init() {
		this._super(...arguments);
		this.getInviteMessage();
		if (!this.fileData) {
			this.set('fileData', {});
		}
	},

	getInviteMessage() {
		const firstName = this.get('currentUser.user.firstName');
		const firmName = this.get('firm.name');

		//* Set the default as a fallback just incase the actual invite message isn't set for some reason.
		let inviteMessage = `This is ${firstName} from ${firmName}. We have started to use a new communication tool to keep you updated on the status of your ${dynamicCaseLabel(
			[this.company],
		)} and easily communicate with our firm. Please download the Case Status app with this link, <a href="https://app.casestatus.com/download" target="_blank">app.casestatus.com/download</a>. Message us through the app or reply to this message if you have any questions. Thanks, ${firstName}`;

		this.store
			.findAll('message-template')
			.then((res) => {
				const invMsg = res.toArray().find((template) => {
					return template.templateType == 'invite';
				});

				if (invMsg && invMsg?.content) {
					inviteMessage = invMsg.content;
				}

				this.set('inviteMsg', inviteMessage);
			})
			.catch(() => {
				this.set('inviteMsg', inviteMessage);
			});
	},

	acceptFileTypes: '.pdf,.png,.jpg,.jpeg,.mov,.mp4',

	actions: {
		addInvite() {
			const hasInviteMsg = this.hasInviteMsg;
			const msgBody = this.msgBody || '';
			const inviteMsg = this.inviteMsg || '';
			const newMsgBody = String(msgBody).trim()
				? msgBody + inviteMsg
				: inviteMsg;

			if (hasInviteMsg) return;

			this.set('msgBody', newMsgBody);
		},
		close() {
			this.set('msgBody', '');
			this.set('fileData', { filename: null, fd: null, fileId: null });
			this.set('newMessageTemplateId', null);
			this.set('messageTemplateType', null);
			this.close();
		},
	},
});
