/**
 * Created by kevin on 7/5/17.
 *
 * @format
 */

import { underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';
import { pluralize } from 'ember-inflector';

export default JSONAPISerializer.extend({
	keyForAttribute: function (attr) {
		return underscore(attr);
	},

	keyForRelationship: function (rawKey) {
		return underscore(rawKey);
	},

	payloadKeyFromModelName: function (name) {
		return pluralize(underscore(name));
	},
});
