/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	attributeBindings: ['onClick', 'style'],
	classNameBindings: [
		'_defaultClassNames',
		'rowClasses',
		'row.rowClasses',
		'onClickClass',
	],
	_defaultClassNames: `cs-table-row col-xs-12 row full-width hover-light`,
	onClick: computed('row.onRowClick', function () {
		if (this.row && typeof this.row.onRowClick == 'function')
			return this.row.onRowClick;
		return null;
	}),
	onClickClass: computed('onClick', function () {
		if (this.onClick) return 'clickable';
		return null;
	}),
});
