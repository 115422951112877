/** @format */

import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';
import { observer } from '@ember/object';
import moment from 'moment';
import { resetTableLocation } from 'case-status/components/cases-table';

export default Mixin.create({
	//* Services
	customBranding: service(),
	store: service(),
	notifications: service(),
	currentUser: service(),
	company: service(),
	permissions: service(),

	preferences: storageFor('preferences'),

	//* Properties
	page: 1,
	sort: '-updated_at',
	size: 20,
	meta: null,
	isLoading: true,

	searchString: null,

	//* Mobile Properties
	showFilters: false,

	//* Filter Properties
	caseId: null,
	newMessages: false,

	dateRanges: null,
	dateRangesValid: false,
	dateRangesChanged: observer(
		'dateRanges.startDate',
		'dateRanges.endDate',
		function () {
			this.set('dateRangesValid', false);
			const startNoEnd =
				moment(this.dateRanges.startDate).isValid() &&
				!moment(this.dateRanges.endDate).isValid() &&
				!this.dateRanges.endDate;
			const endNoStart =
				moment(this.dateRanges.endDate).isValid() &&
				!moment(this.dateRanges.startDate).isValid() &&
				!this.dateRanges.startDate;
			const startBeforeEnd =
				moment(this.dateRanges.startDate).isValid() &&
				moment(this.dateRanges.endDate).isValid() &&
				moment(this.dateRanges.startDate) < moment(this.dateRanges.endDate);
			const startEqEnd =
				moment(this.dateRanges.startDate).isValid() &&
				moment(this.dateRanges.endDate).isValid() &&
				moment(this.dateRanges.startDate).toString() ==
					moment(this.dateRanges.endDate).toString();

			this.set(
				'dateRangesValid',
				startNoEnd || endNoStart || startBeforeEnd || startEqEnd,
			);
		},
	),
	bulkMsg: null,
	bulkTypes: null,
	bulkStatus: null,
	filtersActive: false,

	caseTypes: null,
	caseTypesChanged: observer('model', function () {
		let typesList = [];
		this.store.findAll('caseType').then((types) => {
			const typesArray = types.toArray();

			if (typesArray.length) {
				for (let i = 0; i < typesArray.length; i++) {
					let typeName = '[Name Unavailable]';
					if (typesArray[i].name) {
						typeName = typesArray[i].name;
					}
					if (typesArray[i].id) {
						let type = {
							value: typeName,
							param: typesArray[i].id,
							checked: false,
						};
						typesList.push(type);
					}
				}
			}
		});
		this.set('caseTypes', typesList);
	}),

	filterChanged: observer('filters.{}', function () {
		this.set('filtersActive', false);
		const filters = this.filters;
		for (let filterProperty in filters) {
			//eslint-disable-line no-unused-vars
			this.set('filtersActive', true);
			break;
		}
	}),

	//* Methods

	//* Actions
	actions: {
		clearSearchString() {
			this.set('searchString', '');
			this.set('caseIds', null);
		},

		selectSearchType(value) {
			this.set('searchType', value);
		},

		toggleFilters() {
			this.toggleProperty('showFilters');
			resetTableLocation();
		},
	},
});
