/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
	attributeBindings: ['onClick', 'style'],
	classNameBindings: [
		'_defaultClassNames',
		'cellClasses',
		'cell.cellClasses',
		'cellColSizeClass',
		'onClickClass',
		'cellAlignClass',
	],
	_defaultClassNames: `cs-table-cell dark-grey-text`,
	cellColSizeClass: computed('cell.colSize', function () {
		if (this.cell && this.cell.colSize) return `col-xs-${this.cell.colSize}`;
		return null;
	}),
	cellAlignClass: computed('cell.align', function () {
		if (this.cell) return `text-${this.cell.align}`;
		return null;
	}),
	onClick: computed('cell.onCellClick', function () {
		if (this.cell && typeof this.cell.onCellClick == 'function')
			return this.cell.onCellClick;
		return null;
	}),
	onClickClass: computed('onClick', function () {
		if (this.onClick) return 'clickable';
		return null;
	}),
});
