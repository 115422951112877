import Service from '@ember/service';


export default class AppSecService extends Service {
	//* Lock app down when this is falsey, attempts will be made by this service to rectify and resecure the app, but in the event that it doesn't then it should remain locked down.
	get secure() {
		// add more conditions here as the requirements and checks grow
		// returning false will cause the app to display SecurityCover component
		return true;
	}

	check() {
		// check app security
	}
}
