/** @format */

import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
	customBranding: service('custom-branding'),

	classNames: 'hamburger-actions-modal',

	actions: {
		handleSelection(value) {
			const { onHandleSelection } = this;

			onHandleSelection(value);
		},
	},
});
