/** @format */

import Component from '@ember/component';
import { computed } from '@ember/object';
import { format } from 'libphonenumber-js';
import { inject as service } from '@ember/service';

export default Component.extend({
	permissions: service(),

	formattedPhoneNumber: computed('row.cellPhone', function () {
		let phoneNumberOriginal = this.get('row.cellPhone');
		if (phoneNumberOriginal) {
			return format(phoneNumberOriginal, 'International');
		}
	}),

	showSSN: false,

	actions: {
		removeClientClicked() {
			this.get('tableActions.removeClientClicked')(this.row);
		},
		toggleShowSSN() {
			this.toggleProperty('showSSN');
		},
	},
});
