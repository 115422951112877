/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { or, reads } from '@ember/object/computed';
import { next } from '@ember/runloop';

export default Component.extend({
	_defaultClassNames: computed('isMobile', function () {
		if (this.isMobile) return 'filters--cases bulk-mobile';
		return `checkbox-group row around-xs filters--container`;
	}),
	classNameBindings: ['_defaultClassNames'],

	customBranding: service(),
	permissions: service(),

	showFiltersSelector: false,
	filtersSelectorType: null,
	filterStyle: null,
	filtersAvailable: or(
		'showPracticeAreaFilter',
		'showDateRangeFilter',
		'showStatusFilter',
		'showMessageFilter',
		'showClientInteractionsFilter',
		'showLanguageFilter',
	),

	showPracticeAreaFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];

		return availableSelectors.includes('practice area');
	}),

	showDateRangeFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];

		return availableSelectors.includes('date');
	}),

	showStatusFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];

		return availableSelectors.includes('status');
	}),

	showNpsFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];

		return availableSelectors.includes('nps');
	}),

	showMessageFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];

		return availableSelectors.includes('message');
	}),

	showClientInteractionsFilter: computed('bulkFilterOptions', function () {
		const availableSelectors = this.bulkFilterOptions || [];
		return availableSelectors.includes('client interactions');
	}),

	showLanguageFilter: reads(
		'permissions.firmSettings.allow_message_translation',
	),

	actions: {
		toggleFiltersSelector(type = '', filterStyle) {
			let availableSelectors = this.bulkFilterOptions;

			if (
				type == 'close' ||
				typeof type != 'string' ||
				type == this.filtersSelectorType
			) {
				this.set('showFiltersSelector', false);
				this.set('filtersSelectorType', null);
			} else if (type && availableSelectors.includes(type.toLowerCase())) {
				this.set('filterStyle', filterStyle);
				this.set('filtersSelectorType', type);
				this.set('showFiltersSelector', true);
			} else {
				this.set('showFiltersSelector', false);
				this.set('filtersSelectorType', null);
			}
		},

		toggleFilterOption(option = '') {
			let bulkList = [];
			let shouldAddOption = false;

			this?.filterUpdateCallback();

			switch (this.filtersSelectorType) {
				case 'Practice Area':
					bulkList = [...this.bulkTypes];
					shouldAddOption = !bulkList.includes(option);
					if (shouldAddOption) {
						bulkList.push(option);
						this.set('bulkTypes', bulkList);
					} else {
						let optionIndex = bulkList.indexOf(option);
						if (optionIndex != -1) {
							bulkList.splice(optionIndex, 1);
							this.set('bulkTypes', bulkList);
						}
					}
					break;
				case 'Status':
					bulkList = [...this.bulkStatus];
					shouldAddOption = !bulkList.includes(option);
					if (shouldAddOption) {
						bulkList.push(option);
						this.set('bulkStatus', bulkList);
					} else {
						let optionIndex = bulkList.indexOf(option);
						if (optionIndex != -1) {
							bulkList.splice(optionIndex, 1);
							this.set('bulkStatus', bulkList);
						}
					}
					break;
				case 'nps':
					bulkList = [...this.bulkNps];
					shouldAddOption = !bulkList.includes(option);
					if (shouldAddOption) {
						bulkList.push(option);
						this.set('bulkNps', bulkList);
					} else {
						let optionIndex = bulkList.indexOf(option);
						if (optionIndex != -1) {
							bulkList.splice(optionIndex, 1);
							this.set('bulkNps', bulkList);
						}
					}
					break;
				case 'Message':
					bulkList = [...this.bulkMsg];
					shouldAddOption = !bulkList.includes(option);
					if (shouldAddOption) {
						bulkList.push(option);
						this.set('bulkMsg', bulkList);
					} else {
						let optionIndex = bulkList.indexOf(option);
						if (optionIndex != -1) {
							bulkList.splice(optionIndex, 1);
							this.set('bulkMsg', bulkList);
						}
					}
					this.toggleProperty(option);
					break;
				case 'Client Interactions':
					bulkList = [...this.bulkClientInteractions];
					shouldAddOption = !bulkList.includes(option);
					if (shouldAddOption) {
						bulkList.push(option);
						this.set('bulkClientInteractions', bulkList);
					} else {
						let optionIndex = bulkList.indexOf(option);
						if (optionIndex != -1) {
							bulkList.splice(optionIndex, 1);
							this.set('bulkClientInteractions', bulkList);
						}
					}
					this.toggleProperty(option);
					break;
			}
		},

		clearFilters() {
			this.changeHandlers.updateFilters({
				bulkTypes: [],
				bulkStatus: [],
				bulkMsg: [],
				bulkNps: [],
				bulkClientInteractions: [],
				hasUsedApp: false,
				hasNotUsedApp: false,
				hasSentMessages: false,
				hasNotSentMessages: false,
				hasSentReview: false,
				hasNotSentReview: false,
				dateRanges: { startDate: null, endDate: null },
				newCollaborationMessages: false,
				newInternalMessagesRaw: false,
				newClientMessagesRaw: false,
				anyNewMessages: false,
				searchString: '',
				targetLanguage: null,
				anyUserHasUnreadClientMessages: false,
			});

			if (
				this.clearFiltersCallback &&
				typeof this.clearFiltersCallback == 'function'
			) {
				this.clearFiltersCallback();
			}

			//* Once all filters are updated then we can do a better way to clear the filters that ins't potentially so jumpy
			this.setProperties({
				showClientInteractionsFilter: false,
				showLanguageFilter: false,
				showNpsFilter: false,
				showMessageFilter: false,
				showPracticeAreaFilter: false,
				showStatusFilter: false,
				showDateRangeFilter: false,
			});

			// There has got to be a better way to "data down" the clear
			next(() => {
				this.setProperties({
					showClientInteractionsFilter: true,
					showLanguageFilter:
						this.permissions?.firmSettings?.allow_message_translation,
					showNpsFilter: true,
					showMessageFilter: true,
					showPracticeAreaFilter: true,
					showStatusFilter: true,
					showDateRangeFilter: true,
				});
			});
		},
	},
});
