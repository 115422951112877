/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
	currentUser: service(),
	session: service(),

	init() {
		this._super(...arguments);
		this._loadCurrentUser().then(() => {
			this.verifyUser();
		});
	},

	_loadCurrentUser() {
		const currentUser = this.currentUser;
		if (!currentUser) {
			currentUser.logout();
		}

		return currentUser.load().catch(() => this.session.invalidate());
	},

	verifyUser() {
		const currentUser = this.currentUser;
		const user = currentUser.get('user');
		const userEmail = user.get('emailAddress');

		if (user && user.isAdmin && userEmail.includes('@casestatus.com')) {
			return true;
		} else if (user && userEmail.includes('@casestatus.com')) {
			user.set('isAdmin', true);
			this.set('loading', false);
			return true;
		} else if (user && !userEmail.includes('@casestatus.com')) {
			this.transitionToApp();
			return false;
		} else {
			if (currentUser) {
				currentUser.logout();
			}
			return false;
		}
	},

	transitionToApp() {
		let appRoute = 'login';
		switch (this.get('currentUser.session.session.authenticated.user_type')) {
			case 'client':
				appRoute = 'app.client.cases';
				break;
			case 'attorney':
				appRoute = 'app.firm.cases';
				break;
			case 'paralegal':
				appRoute = 'app.firm.cases';
				break;
			default:
				appRoute = 'login';
				this.currentUser.logout();
				break;
		}
		this.transitionToRoute(appRoute);
	},

	actions: {
		logout() {
			this.currentUser.logout();
		},
	},
});
