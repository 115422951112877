import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import sanitizeHtml from 'sanitize-html';
import { isArray, isString } from 'case-status/constants/data-types';
import EmberError from '@ember/error';

export function safeHtml(params) {
	if (!isArray(params)) return EmberError('safeHtml must be passed an array');
	const [suspiciousString, options] = params;
	if (!isString(suspiciousString)) return htmlSafe(null);
	return htmlSafe(sanitizeHtml(suspiciousString, options));
}

export default helper(safeHtml);
