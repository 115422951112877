/** @format */

import Model, { attr } from '@ember-data/model';

export default Model.extend({
	attorneyId: attr('number'),
	contentType: attr('string'),
	size: attr('number'),
	url: attr('string'),
});
