/** @format */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'case-status/config/environment';

export default class SandboxIframeController extends Controller {
	@tracked integrationType = 'litify';
	@tracked integrationID;
	@tracked showIFrame = false;

	get iFrameUrl() {
		return `${
			window.location.hostname == 'localhost'
				? window.location.origin
				: ENV.host
		}/login?integrationType=${this.integrationType}&integrationId=${
			this.integrationID
		}`;
	}

	@action
	resetIFrame() {
		this.set('showIFrame', false);
		setTimeout(() => {
			this.set('showIFrame', true);
		}, 50);
	}
}
