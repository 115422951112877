/** @format */

import ApplicationSerializer from './application';
import { singularize } from 'ember-inflector';
import camelize from 'camelize';
import { capitalize } from '@ember/string';
import {
	getActivityTypeTooltip,
	activityIconMappings,
} from 'case-status/constants/activity-feed-helpers';
import { dateDisplay } from 'case-status/helpers/date-display';
import { inject as service } from '@ember/service';

export default class ActivityStatisticPeriodSerializer extends ApplicationSerializer {
	@service company;

	normalizeResponse(store, primaryModelClass, payload, id, requestType) {
		const activityDescriptions = store.peekAll('activityDescription');

		const pl = payload.data.reduce(
			(documentHash, item) => {
				const normalizer = (itemToNormalize) => {
					const normalizedItem = { data: { attributes: {} } };

					for (let key in itemToNormalize.data.attributes) {
						const value = itemToNormalize.data.attributes[key];
						normalizedItem.data.attributes[camelize(key)] = value;
					}

					normalizedItem.data.id = itemToNormalize.data.id;
					normalizedItem.data.type = camelize(
						singularize(itemToNormalize.data.type),
					);
					normalizedItem.data.relationships = {
						activityGroup: {
							id: item.id,
							type: camelize(singularize(item.type)),
						},
					};

					return normalizedItem.data.attributes;
				};

				const normalizedActivities = item.attributes.activities.map(
					(activity) => {
						const normalizedActivity = normalizer(activity);
						normalizedActivity.description =
							activityDescriptions.find(
								(desc) =>
									desc?.activityName == normalizedActivity?.activityName,
							)?.description ||
							normalizedActivity?.activityName ||
							'[ an error has occurred ]';
						const names = normalizedActivity.userName.split(' ');

						if (names.length) {
							const firstInitial = names[0][0] ?? '';
							const secondInitial = names[1] ? names[1][0] ?? '' : '';
							normalizedActivity.userInitials = `${firstInitial}${secondInitial}`;
						} else {
							normalizedActivity.userInitials = null;
						}

						if (!normalizedActivity.userName?.length) {
							normalizedActivity.userName = `${capitalize(
								normalizedActivity.subUserType ?? '',
							)} User`;
						}

						normalizedActivity.icon =
							activityIconMappings[normalizedActivity.activityType];
						normalizedActivity.iconTooltipText = getActivityTypeTooltip(
							normalizedActivity.activityType,
						);

						normalizedActivity.displayTime = dateDisplay([
							normalizedActivity.recordedAt,
							this.company,
							false,
							true,
						]);

						return normalizedActivity;
					},
				);

				const normalizedStatistics = item.attributes.statistics.map(
					(statistic) => {
						return normalizer(statistic);
					},
				);

				for (const key in activityIconMappings) {
					if (
						![...normalizedStatistics].find((stat) => stat.activityType == key)
					) {
						normalizedStatistics.push({
							activityType: key,
							total: 0,
						});
					}
				}

				item.attributes.activities = normalizedActivities;
				item.attributes.statistics = normalizedStatistics;

				const { data } = this.normalize(primaryModelClass, item);

				documentHash.data.push(data);

				return documentHash;
			},
			{
				data: [],
				included: payload.included?.length ? payload.included : [],
				meta: payload.meta ?? {},
				links: payload.links ?? {},
			},
		);

		return pl;
	}
}
