/** @format */

import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	store: service(),
	currentUser: service(),
	permissions: service(),
	activityTracking: service(),

	beforeModel() {
		if (!this.get('permissions.case.treatment_log')) {
			this.replaceWith('app.client.case.info');
		}
	},

	model() {
		const theCase = this.modelFor('app.client.case').theCase;
		return hash({
			theCase: theCase,
			newTreatment: this.store.createRecord('treatment', { case: theCase }),
			treatmentTypes: this.store.findAll('treatmentType'),
			treatments: this.store.query('treatment', { case_id: theCase.id }),
			medicalProviders: this.store.query('medical-provider', {
				case_id: theCase.id,
			}),
		});
	},

	setupController(controller, model) {
		this._super(...arguments);
		this.activityTracking.trackByCase(model?.theCase?.id, 'viewedTreatmentLog');
	},

	actions: {
		cancelEditing() {
			this.controllerFor('app.client.case.treatments').set(
				'showNewTreatmentForm',
				false,
			);
		},
	},

	resetController(controller, isExiting) {
		this._super.apply(this, arguments);
		if (isExiting) {
			controller.set('showNewTreatmentForm', false);
			const theCase = controller.model;
			if (theCase.reload && typeof theCase.reload == 'function') {
				theCase.reload();
			}
		}
		controller.set('editId', null);
	},
});
