/** @format */

import Mixin from '@ember/object/mixin';
import ENV from 'case-status/config/environment';
import { pluralize } from 'ember-inflector';
import Errors from '../../../constants/errors';

export default Mixin.create({
	actions: {
		resendInvite(user) {
			const userType = user.constructor.modelName;
			const userId = user.id;
			const url = `${ENV.host}/${pluralize(
				userType.toLowerCase(),
			)}/${userId}/resend_invite`;

			this.ajax
				.post(url)
				.then(() => {
					user.set('inviteSent', true);
					this.controller.notifications.success('Invite has been sent!', {
						autoClear: true,
					});
				})
				.catch((err) => {
					user.set('inviteSent', false);
					this.controller.notifications.error(Errors.mapResponseErrors(err));
				});
		},
	},
});
