/** @format */

import Route from '@ember/routing/route';
import Errors from '../../../../constants/errors';

export default Route.extend({
	beforeModel() {
		this._super(...arguments);
		this.send('gatekeeper', 'settings_practice_areas', 'settings.integrations');
	},

	model() {
		return this.store.findAll('caseType', { reload: true });
	},

	setupController(controller) {
		this._super(...arguments);

		this.store.findAll('caseTypeTemplate').then((response) => {
			controller.set('caseTypeTemplates', response);
		});

		this.store.findAll('practiceAreaTemplate').then((response) => {
			controller.set('practiceAreaTemplates', response);
		});

		controller.set('selectedTab', 'Stages');
	},

	resetController(controller, isExiting) {
		if (isExiting) {
			controller.set('selectedTab', 'Stages');
		}
	},

	actions: {
		deleteCaseType(caseType, result = { resolve: () => {}, reject: () => {} }) {
			const controller = this.controller;

			// TODO: put the logic to reset the selectedCaseType in the controller
			this.store
				.findRecord('caseType', caseType)
				.then((type) => {
					type
						.destroyRecord()
						.then(() => {
							result.resolve();
							const types = controller.sortedCaseTypes;
							controller.set('selectedCaseType', types[0]);
							controller.set('deleteErrors', null);
							controller.set('isDeletingCaseType', false);
						})
						.catch((errors) => {
							result.reject(errors);
							type.rollbackAttributes();
							controller.set('deleteErrors', Errors.mapResponseErrors(errors));
						});
				})
				.catch((errors) => {
					result.reject(errors);
					controller.set('deleteErrors', Errors.mapResponseErrors(errors));
				});
		},
	},
});
