/** @format */

import Controller from '@ember/controller';
import { defer, resolve } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ClientCaseController extends Controller {
	@service('current-user') currentUser;
	@service company;
	@service mobile;
	@service permissions;
	@service activityTracking;

	queryParams = ['showMobile'];
	@tracked showMobile = false;
	@tracked errors = [];
	@tracked score = null;
	@tracked hideNps = false;

	get hasUnreadMessages() {
		return this.unreadCount > 0 ? 'alert' : '';
	}

	get unreadCount() {
		const notifications = this.model.theCase.notifications || [];
		return notifications.filter(Boolean).filterBy('readAt', null).length;
	}

	get showNPS() {
		const caseId = this.model.theCase.id;
		let showNps = false;

		this.currentUser.user.showNps.forEach((showNpsCase) => {
			showNps = showNpsCase === +caseId;
		});

		return showNps;
	}

	get feedback() {
		// Submit feedback for the previous stage
		const currentStage = this.model.theCase.caseStatus.get('number');
		const caseClosed = this.model.theCase.closed;
		// Client should not get prompted at stage 1, but if they are, submit feedback for stage 1
		let stageToSubmit = 0;
		if (currentStage === 6 && caseClosed) {
			stageToSubmit = 6;
		} else if (currentStage > 1) {
			stageToSubmit = currentStage - 1;
		} else {
			stageToSubmit = currentStage;
		}
		return {
			caseId: this.model.theCase.id,
			stage: stageToSubmit,
			score: this.score,
			additionalFeedback: '',
		};
	}

	@action
	sendNPSFeedback(additionalFeedback, score) {
		this.errors = [];
		let localFeedback = this.feedback;
		localFeedback.score = score;
		localFeedback.additionalFeedback = additionalFeedback;
		if (score || score === 0) {
			const result = defer();
			this.send('sendFeedback', localFeedback, result);
			result.promise.then(() => {
				this.hideNps = true;
				this.activityTracking.trackByCase(
					this.model.theCase.id,
					'submittedNPS',
				);
			});
		} else {
			this.errors = ['Please select a score.'];
			return resolve();
		}
	}
}
