/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';

export default Controller.extend({
	company: service(),
	ajax: service(),
	currentUser: service(),
	thirdParty: service(),
	permissions: service(),

	syncSuccess: null,
	syncError: null,
	refreshTimer: 10,

	syncable: computed(
		'currentUser.user.firm.firmSettings.firmSettings.allow_import_individual_case_endpoint',
		'thirdParty.isLitifyOrSalesforce',
		function () {
			return (
				this.currentUser.user.firm.get(
					'firmSettings.firmSettings.allow_import_individual_case_endpoint',
				) && !this.thirdParty.isLitifyOrSalesforce
			);
		},
	),

	actions: {
		sync() {
			const url = `${ENV.host}/cases/import/case/${this.thirdParty.integrationId}`;

			this.set('syncSuccess', null);
			this.set('syncError', null);

			return this.ajax
				.post(url, {
					contentType: 'application/json',
				})
				.then((response) => {
					this.set('syncSuccess', response);
					const refreshCounter = setInterval(() => {
						if (this.refreshTimer) {
							this.decrementProperty('refreshTimer');
						} else {
							clearInterval(refreshCounter);
						}
					}, 1000);
				})
				.catch((response) => {
					this.set('syncError', Errors.mapResponseErrors(response));
				});
		},

		refresh() {
			return window.location.reload();
		},

		logout() {
			this.currentUser.logout();
		},
	},
});
