/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject } from '@ember/service';

export default Component.extend({
	classNames: ['date-range-picker'],

	currentUser: inject('current-user'),

	pickadayPlaceholderStartDate: computed('placeholderStartDate', function () {
		const placeholderStartDate = this.placeholderStartDate;

		return placeholderStartDate ? placeholderStartDate : 'Start';
	}),

	actions: {
		setStartDate(date) {
			this.set('startDate', date);
			if (this.onStartDateChange) {
				this.onStartDateChange(date);
			}
		},
		setEndDate(date) {
			this.set('endDate', date);
			if (this.onEndDateChange) {
				this.onEndDateChange(date);
			}
		},
	},
});
