/** @format */

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
	urlForQuery(query) {
		if (query.chatId) {
			return `${this.host}/chat_files/chat/${query.chatId}`;
		}
	},
});
