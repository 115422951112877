/** @format */

import ApplicationAdapter from './application';
import { pluralize } from 'ember-inflector';

export default ApplicationAdapter.extend({
	urlForDeleteRecord(store, type /* , snapshot */) {
		//* Currently there is no option given to the user to not "remove_all"
		return `${this.host}/${pluralize(
			type,
		).toLowerCase()}/${store}?remove_all=True`;
	},
});
