/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	store: service(),

	async model() {
		return this.store.findAll('form-response', { reload: true });
	},
});
