import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.args.requestInFlight}}\n\t<span>\n\t\t<FaIcon @icon='circle-notch' @spin={{true}} />\n\t</span>\n{{else}}\n\t<span\n\t\tclass='{{if this.isClicked \"bold\"}}\n\t\t\t{{unless this.args.isActive \"disabled\"}}\n\t\t\t'\n\t\t{{on\n\t\t\t'click'\n\t\t\t(fn this.args.regenerateRecommendedResponses @eligibleMessage)\n\t\t}}\n\t>\n\t\t<FaIcon @icon='refresh' class='refresh icon' />\n\t</span>\n{{/if}}", {"contents":"{{#if this.args.requestInFlight}}\n\t<span>\n\t\t<FaIcon @icon='circle-notch' @spin={{true}} />\n\t</span>\n{{else}}\n\t<span\n\t\tclass='{{if this.isClicked \"bold\"}}\n\t\t\t{{unless this.args.isActive \"disabled\"}}\n\t\t\t'\n\t\t{{on\n\t\t\t'click'\n\t\t\t(fn this.args.regenerateRecommendedResponses @eligibleMessage)\n\t\t}}\n\t>\n\t\t<FaIcon @icon='refresh' class='refresh icon' />\n\t</span>\n{{/if}}","moduleName":"case-status/components/ai-regenerate-responses-button.hbs","parseOptions":{"srcName":"case-status/components/ai-regenerate-responses-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AiRegenerateResponsesButtonComponent extends Component {
	@tracked error = null;
	@service ajax;
	@service store;
	@service notifications;
	@tracked requestInFlight = false;

	constructor() {
		super(...arguments);
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
}
