/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash, reject } from 'rsvp';

export default Route.extend({
	//* Services
	session: service(),
	store: service(),
	ajax: service(),
	//*

	searchString: '',

	//* Life Cycle Methods
	model(params) {
		const caseId = params.case_id;
		this.set('id', caseId);

		return hash({
			theCase: this.store.findRecord('case', caseId, { reload: true }),
		});
	},
	//*

	//* Custom Methods
	post(url = '', data = {}) {
		if (
			url &&
			data.constructor === Object &&
			Object.entries(data).length != 0
		) {
			const ajax = this.ajax;
			return ajax.post(url, {
				contentType: 'application/json',
				data,
			});
		} else {
			const errors = [];

			//* Missing URL Param Error
			if (!url) {
				errors.push({
					detail: 'Missing valid url',
				});
			}

			//* Data Param Must Be An Object Error
			if (data.constructor !== Object) {
				errors.push({
					detail: 'Data parameter must be an Object',
				});

				//* Data Param Must Not Be An Empty Object Error
			} else if (Object.entries(data).length == 0) {
				errors.push({
					detail: 'Data parameter must not be an empty Object',
				});
			}

			//* When there are errors, send as mappable payload property
			if (errors.length > 0) {
				return reject({
					payload: { errors },
				});

				//* If no custom errors, yet checks were still failed, send unknown error with location info
			} else if (errors.length <= 0) {
				return reject({
					payload: {
						error: 'An unknown error has occurred in organization/case route',
					},
				});
			}
		}
	},
	//*

	//* Actions
	actions: {},
	//*
});
