import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TabbedSelectableTables\n\t@tabOptions={{tabOptions}}\n\t@onSubmitHandler={{action 'handleSubmit'}}\n/>", {"contents":"<TabbedSelectableTables\n\t@tabOptions={{tabOptions}}\n\t@onSubmitHandler={{action 'handleSubmit'}}\n/>","moduleName":"case-status/components/cases/modals/bulk-actions/manage-staff.hbs","parseOptions":{"srcName":"case-status/components/cases/modals/bulk-actions/manage-staff.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { userDisplay } from 'case-status/helpers/user-display';
import { capitalize } from '@ember/string';
import { isFunction } from 'case-status/constants/data-types';
import { paralegalDisplay } from 'case-status/helpers/paralegal-display';

export default class CasesModalsBulkActionsManageStaffComponent extends Component {
	constructor() {
		super(...arguments);
		this.modelName = this?.args?.modelName;

		this.model = userDisplay([
			this.store.peekAll(this.modelName).toArray() ?? [],
			null,
			this.company,
		]);

		this.tabOptions = [
			{
				id: 0,
				actionType: 'add',
				display: `Add`,
				tabType: 'single',
				modelLabel: this.modelLabel,
				model: this.model,
				onSelect: () => {
					const updateModalSize = this?.args?.updateModalSize;
					if (isFunction(updateModalSize)) {
						updateModalSize();
					}
				},
				tableOptions: {
					limit:
						(this.permissions.firmSettings[`max_${this.modelName}s`] ?? 5) -
						(this.modelName === 'attorney' ? 1 : 0),
				},
			},
			{
				id: 1,
				actionType: 'remove',
				display: `Remove`,
				tabType: 'single',
				modelLabel: this.modelLabel,
				model: this.model,
				onSelect: () => {
					const updateModalSize = this?.args?.updateModalSize;
					if (isFunction(updateModalSize)) {
						updateModalSize();
					}
				},
			},
			{
				id: 2,
				actionType: 'replace',
				display: `Replace`,
				tabType: 'double',
				title: `Select 1 ${this.modelLabelSingular} to Remove`,
				title2: `Select 1 ${this.modelLabelSingular} to Add`,
				class: 'table1',
				class2: 'table2',
				modelLabel: this.modelLabel,
				model: this.model,
				model2: this.model,
				onSelect: () => {
					const updateModalSize = this?.args?.updateModalSize;
					if (isFunction(updateModalSize)) {
						updateModalSize('lg');
					}
				},
				tableOptions: {
					hideSelectedList: true,
					limit: 1,
				},
			},
		];
	}

	@service store;
	@service notifications;
	@service company;
	@service permissions;

	@tracked isLoading = true;

	get modelLabel() {
		switch (this.modelName) {
			case 'attorney':
				return capitalize(attorneyDisplay([this.company, 2, true]) ?? '');
			case 'paralegal':
				return capitalize(paralegalDisplay([this.company, 2, true]) ?? '');
			default:
				return capitalize(this.modelName);
		}
	}

	get modelLabelSingular() {
		switch (this.modelName) {
			case 'attorney':
				return capitalize(attorneyDisplay([this.company, 1, true]) ?? '');
			case 'paralegal':
				return capitalize(paralegalDisplay([this.company, 1, true]) ?? '');
			default:
				return capitalize(this.modelName);
		}
	}

	@action
	handleSubmit(...args) {
		const onSubmitHandler = this?.args?.onSend;

		if (isFunction(onSubmitHandler)) {
			return onSubmitHandler(...args);
		}

		return Promise.reject('No submit handler found in manage-attorneys.js');
	}
}
