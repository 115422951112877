/** @format */

import Mixin from '@ember/object/mixin';

export default Mixin.create({
	actions: {
		rowClicked(row, event) {
			if (
				event.target.className.includes('checkbox') ||
				event.target.className.includes('control')
			)
				return;
			if (event.target.children[0]) {
				if (
					event.target.children[0].className &&
					event.target.children[0].className.includes('checkbox')
				)
					return;
				if (event.target.children[0].children[0]) {
					if (
						event.target.children[0].children[0].className &&
						event.target.children[0].children[0].className.includes('checkbox')
					)
						return;
				}
			}
			if (event.target.parentElement.nodeName === 'TR') {
				event.target.parentElement.children[0].children[0].children[0].children[0].checked =
					!row.get('content.isSelected');
				row.set('content.isSelected', !row.get('content.isSelected'));
			} else if (event.target.parentElement.nodeName === 'TD') {
				if (event.target.parentElement.parentElement.nodeName === 'TR') {
					event.target.parentElement.parentElement.children[0].children[0].children[0].children[0].checked =
						!row.get('content.isSelected');
					row.set('content.isSelected', !row.get('content.isSelected'));
				}
			}
		},
	},
});
