import Model, { belongsTo, attr } from '@ember-data/model';
import { safeHtml } from 'case-status/helpers/safe-html';
import { computed } from '@ember/object';

export default Model.extend({
	description: attr('string'),
	name: attr('string'),
	number: attr('number'),
	importNames: attr(),
	videoId: attr('number'),

	caseType: belongsTo('case-type'),
	firm: belongsTo('firm'),
	video: belongsTo('video'),

	displayDescription: computed('description', function () {
		return safeHtml([this.description]);
	}),
});
