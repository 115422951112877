/** @format */

import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
	serialize() {
		let json = this._super(...arguments);

		//Strip dashes from phone number
		let phoneNumber = json.data.attributes.cell_phone;
		if (phoneNumber) {
			phoneNumber = phoneNumber.replace(/[^+\d]+/g, '');
			//Strip out numbers that only have the country code
			if (phoneNumber.length < 4 && phoneNumber.indexOf('+') > -1) {
				json.data.attributes.cell_phone = null;
			} else {
				json.data.attributes.cell_phone = phoneNumber;
			}
		}

		//* Trim excess spaces off of their names
		if (json.data.attributes.first_name) {
			json.data.attributes.first_name = json.data.attributes.first_name.trim();
		}

		if (json.data.attributes.last_name) {
			json.data.attributes.last_name = json.data.attributes.last_name.trim();
		}

		//* Remove blank email
		const emailAddress = json.data.attributes.email_address;
		if (emailAddress === '') {
			json.data.attributes.email_address = null;
		}

		//* Remove blank Birth Date
		const birthDate = json.data.attributes.birth_date;
		if (birthDate === '') {
			json.data.attributes.birth_date = null;
		}

		//* Remove blank SSN
		const ssn = json.data.attributes.ssn;
		if (ssn === '') {
			json.data.attributes.ssn = null;
		}

		//* Only on existing users for edits, new users throw an error on save otherwise
		if (json.data.id) {
			delete json.data.relationships.user_child;
		}

		return json;
	},
});
