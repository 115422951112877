/** @format */

import { defer } from 'rsvp';
import { computed, observer } from '@ember/object';
import { on } from '@ember/object/evented';
import moment from 'moment';
import Component from '@ember/component';
import Errors from 'case-status/constants/errors';

export default Component.extend({
	selectedHour: 12,
	selectedMinute: '00',
	amOrPm: 'AM',
	isDeleting: false,
	isEditing: false,
	selectedTime: null,
	content: '',
	errors: null,
	startDate: computed('isEditing', function () {
		const tz = this.get('currentUser.user.firm.timezone') || 'US/Eastern';
		return moment.tz(this.get('message.scheduledMessageSendDate'), tz).toDate();
	}),
	minDate: computed(function () {
		return new Date();
	}),

	// eslint-disable-next-line ember/no-on-calls-in-components
	scheduleObserver: on(
		'init',
		observer('selectedHour', 'selectedMinute', 'day', 'amOrPm', function () {
			const dayAsMoment = moment(this.day);
			const selectedMinute = this.selectedMinute;
			const amOrPm = this.amOrPm;

			let selectedHour = Number(this.selectedHour);
			if (selectedHour === 12) selectedHour = 0;
			if (amOrPm === 'PM') selectedHour += 12;
			this.set(
				'selectedTime',
				moment()
					.year(dayAsMoment.year())
					.month(dayAsMoment.month())
					.date(dayAsMoment.date())
					.hours(selectedHour)
					.minutes(Number(selectedMinute))
					.seconds(0),
			);
		}),
	),

	init() {
		this._super(...arguments);

		this.set('hourOptions', [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
		this.set('minuteOptions', ['00', '30']);
		this.set('amOrPmOptions', ['AM', 'PM']);

		this.set('content', this.get('message.content'));

		const tz = this.get('currentUser.user.firm.timezone') || 'US/Eastern';
		const time = moment.tz(this.get('message.scheduledMessageSendDate'), tz);
		this.set('day', time.toDate());
		this.set('selectedHour', time.format('hh'));
		this.set('selectedMinute', String(time.format('mm')));
		this.set('amOrPm', String(time.format('A')));
	},

	actions: {
		deleteMessage() {
			const deleteScheduledMessage = this.deleteScheduledMessage;
			const message = this.message;
			const result = defer();

			deleteScheduledMessage(message, result);

			return result.promise.then(() => {
				this.toggleProperty('isDeleting');
			});
		},

		editMessage() {
			const editScheduledMessage = this.editScheduledMessage;
			const message = this.message;
			const tz = this.get('currentUser.user.firm.timezone') || 'US/Eastern';
			const selectedTime = this.selectedTime.tz(tz, true);

			const content = this.content;
			const result = defer();

			if (moment(selectedTime).isBefore(moment(moment.now()).tz(tz, true))) {
				this.set('errors', ['You must choose a date and time in the future.']);
				result.reject();
				return result.promise;
			}
			editScheduledMessage(message, content, selectedTime, result);

			return result.promise
				.then(() => {
					this.errors = null;
					message.set('content', content);
					message.set('scheduledMessageSendDate', selectedTime);
					this.send('updateMessageState', '');
				})
				.catch((err) => {
					this.errors = Errors.mapResponseErrors(err);
				});
		},

		setDate(date) {
			this.set('errors', null);
			this.set('day', date);
			return date;
		},

		setSelectedHour(val) {
			this.set('errors', null);
			this.set('selectedHour', val);
		},

		setSelectedMinute(val) {
			this.set('errors', null);
			this.set('selectedMinute', val);
		},

		setAmPm(val) {
			this.set('errors', null);
			this.set('amOrPm', val);
		},

		updateMessageState(state) {
			this.errors = null;
			switch (state) {
				case 'deleting':
					this.set('isDeleting', true);
					this.set('isEditing', false);
					break;
				case 'editing':
					this.set('isDeleting', false);
					this.set('isEditing', true);
					break;
				default:
					this.set('isDeleting', false);
					this.set('isEditing', false);
			}
		},
	},
});
