/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { dynamicCaseLabel } from '../../../helpers/dynamic-case-label';

export default Route.extend({
	store: service(),
	notifications: service(),
	company: service(),
	permissions: service(),
	activityTracking: service(),
	queryParams: {
		showMobile: false,
	},

	model(params) {
		return hash({
			theCase: this.store.findRecord('case', params.case_id, {
				include: 'messages,caseType.caseStatus',
				reload: true,
			}),
			files: this.store.query('file', { id: params.case_id }, { reload: true }),
		});
	},

	afterModel(model) {
		if (!model.theCase.activatedDate) {
			//* If the case navigated to is not activated, display an error notif and transition back to cases view
			this.notifications.error(
				`Error viewing ${dynamicCaseLabel([this.company])} ${
					model.theCase.id
				}.`,
				{
					autoClear: true,
					clearDuration: 5000,
					canClose: true,
				},
			);

			this.transitionTo('app.client.cases');
		}
	},

	setupController(controller, model) {
		//* Setup the permissions service with the caseSettings
		this.set('permissions.case', model.theCase.caseSettings);

		//? For some reason the model doesn't get set when we have this hook so we have to set it here
		controller.set('model', model);

		this.activityTracking.trackByCase(model.theCase.id, 'login');
	},

	resetController(controller, isExiting) {
		if (isExiting) {
			//* Remove the case from permissions service
			this.set('permissions.case', null);
		}
	},
});
