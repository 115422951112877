/** @format */

import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Model.extend({
	activityTracking: service(),
	currentUser: service(),

	appointmentDate: attr('date'),
	createdAt: attr('date'),
	updateAt: attr('date'),
	description: attr('string'),
	officeName: attr('string'),
	providerName: attr('string'),
	address: attr('string'),
	type: attr('string'),
	appointmentTitle: attr('string'),
	appointmentAttendees: attr('string'),
	treatmentNotes: attr('string'),
	isPast: computed('appointmentDate', function () {
		const apptDate = moment(this.appointmentDate);
		const now = moment.now();

		return now >= apptDate;
	}),
	formattedDate: computed('appointmentDate', function () {
		const apptDate = this.get('appointmentDate');
		return moment(apptDate).format('MM/DD/YYYY');
	}),
	formattedTime: computed('appointmentDate', function () {
		const apptDate = this.get('appointmentDate');
		return moment(apptDate).format('hh:mm A');
	}),
	wantsCalendarEvents: attr('boolean'), //* Only used on creation and default is true

	treatmentActivities: hasMany('treatmentActivity', { inverse: 'appointment' }),
	treatmentType: belongsTo('treatmentType', { inverse: 'appointment' }),
	case: belongsTo('case'),

	confirmationEnabled: attr('boolean'),
	internalNotificationEnabled: attr('boolean'),
	confirmationDaysBefore: attr('number'),
	internalNotificationDaysBefore: attr('number'),
	internalNotificationSendDate: attr('date'),
	confirmationSendDate: attr('date'),
	confirmationSentDate: attr('date'),
	internalNotificationSentDate: attr('date'),

	init() {
		this._super(...arguments);

		//* Atm this property is only used when creating an appointment and must default to true
		if (
			this.wantsCalendarEvents !== false &&
			this.wantsCalendarEvents !== true
		) {
			this.set('wantsCalendarEvents', true);
		}

		if (
			this.confirmationEnabled &&
			this.internalNotificationSendDate &&
			!this.internalNotificationDaysBefore
		) {
			const apptDate = this.get('appointmentDate');
			const internalSendDate = this.get('internalNotificationSendDate');
			const intNotificationDaysBefore = Math.abs(
				moment(apptDate).diff(moment(internalSendDate), 'days'),
			);
			this.set('internalNotificationDaysBefore', intNotificationDaysBefore);
		}

		if (
			this.confirmationEnabled &&
			this.confirmationSendDate &&
			!this.confirmationDaysBefore
		) {
			const apptDate = this.get('appointmentDate');
			const confirmSendDate = this.get('confirmationSendDate');
			const confDaysBefore = Math.abs(
				moment(apptDate).diff(moment(confirmSendDate), 'days'),
			);

			this.set('confirmationDaysBefore', confDaysBefore);
		}
	},

	save(...args) {
		//* Extend the save method to track activity;
		const activityType = this.id ? 'updatedAppointment' : 'createdAppointment';

		return this._super(...args).then((res) => {
			//* Only Track Client Activity
			if (this.currentUser.get('user.constructor.modelName') == 'client') {
				this.activityTracking.trackByCase(this.case.get('id'), activityType);
			}
			return res;
		});
	},
});
