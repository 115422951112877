/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import ENV from 'case-status/config/environment';

export default Route.extend(UnauthenticatedRouteMixin, {
	session: service(),
	loginService: service('login'),
	serviceWorker: service('service-worker'),

	routeIfAlreadyAuthenticated: null, // Making null because the plugin's default is /login unless overridden, will handle in the setupController hook instead

	beforeModel() {
		this._super(...arguments);
		//* Clear the store
		this.store.unloadAll();
		//* invalidate any existing session
		this.session.invalidate();
	},

	actions: {
		login(username, pin, result) {
			this.session
				.authenticate('authenticator:oauth2', username, pin)
				.then(() => {
					//* The authenticate method fires and renders the loginService method useless
					//* It was originally added in case the authenticate didn't fire correctly
					this.loginService.login();
				})
				.catch((err) => {
					if (!err) {
						this.serviceWorker.set('internetDownLogin', true);
						result.reject();
					} else if (err && err['errors'] && err['errors'][0]['status'] > 500) {
						this.serviceWorker.set('serverDownLogin', true);
						result.reject();
					} else {
						this.controller.set('errors', [
							'Your username and pin combination is not correct. Please try again.',
						]);
						result.reject();
					}
				});
		},
	},
});
