/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';
import { dateDisplay } from 'case-status/helpers/date-display';
import { inject as service } from '@ember/service';

// look in ActivityStatisticPeriodSerializer
export default class ActivityModel extends Model {
	@service company;

	@attr('string') userName;
	@attr('string') activityName;
	@attr('string') activityType;
	@attr('string') recordedAt;

	@attr('string') subUserType;

	@attr() payload;

	get userInitials() {
		const names = this.userName.split(' ');

		return `${names[0][0]}${names[1][0]}`;
	}

	get displayDate() {
		return dateDisplay([this.recordedAt, this.company]);
	}

	get displayTime() {
		return dateDisplay([this.recordedAt, this.company, false, true]);
	}

	// @belongsTo('case') case;
	@attr('number') caseId;
	// @belongsTo('firm') firm;
	@attr('number') firmId;

	@attr('number') subUserId;

	// @belongsTo('activity-statistic-period', {inverse: 'activities' }) activityGroup;
}
