/** @format */

import Component from '@ember/component';
import { inject } from '@ember/service';
import { computed, observer } from '@ember/object';
import RSVP from 'rsvp';
import { dateDisplay } from 'case-status/helpers/date-display';
import { typeOf } from '@ember/utils';

export default Component.extend({
	classNames: ['insights-module'],
	classNameBindings: ['hoverDisplayData:is-hovering'],
	fetchingMessages: false,

	currentUser: inject('current-user'),
	customBranding: inject('custom-branding'),
	stageTimestamp: false,

	init() {
		this._super(...arguments);

		let _this = this;

		let chartOptions = {
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							display: false,
						},
						gridLines: {
							display: false,
							drawBorder: false,
						},
					},
				],
				xAxes: [
					{
						ticks: {
							display: false,
						},
						gridLines: {
							display: false,
							drawBorder: false,
						},
					},
				],
			},
			layout: {
				padding: {
					left: -8,
					right: -10,
					top: 50,
					bottom: -10,
				},
			},
			legend: {
				display: false,
			},
			tooltips: {
				backgroundColor: 'rgba(0,0,0,0)',
				titleFontColor: 'rgba(0,0,0,0)',
				enabled: true,
				intersect: false,
				mode: 'index',
				callbacks: {
					label: function (tooltipItem) {
						_this.printData(tooltipItem);
					},
				},
				custom: function (tooltipModel) {
					// Tooltip Element
					var tooltipEl = document.getElementById('chartjs-tooltip');

					if (tooltipModel.dataPoints === undefined) {
						return;
					}

					// Create element on first render
					if (!tooltipEl && tooltipModel.dataPoints !== undefined) {
						tooltipEl = document.createElement('div');
						tooltipEl.id = 'chartjs-tooltip';
						tooltipEl.classNames = 'chartjs-tooltip-item';
						document.body.appendChild(tooltipEl);
					}

					// This will be the overall tooltip
					var position = this._chart.canvas.getBoundingClientRect();

					// Display, position, and set styles for font
					tooltipEl.style.opacity = 1;
					tooltipEl.style.position = 'absolute';
					tooltipEl.style.left =
						position.left + (tooltipModel.caretX - 2) + 'px';
					tooltipEl.style.top =
						window.scrollY + position.top + tooltipModel.caretY + 'px';
					tooltipEl.style.height = `calc(${position.height}px - ${tooltipModel.caretY}px)`;
				},
			},
			hover: {
				intersect: false,
				mode: 'index',
				events: ['mouseout'],
				onHover: function (e) {
					if (e.type === 'mouseout') {
						_this.resetHoverDisplayData();
					}
				},
			},
		};

		this.set('chartOptions', chartOptions);
	},

	resetHoverDisplayData() {
		this.set('hoverDisplayData', null);
		const tooltipEl = document.getElementById('chartjs-tooltip');
		if (tooltipEl) {
			document.body.removeChild(tooltipEl);
		}
	},
	printData(data) {
		let hoverData = data;
		const stageTimestamp = this.stageTimestamp;

		if (this.selectedDataset) {
			let dataset = this.dataset;
			if (dataset.datasets) {
				let feedback =
					dataset.datasets[this.get('selectedDataset.value')].feedback;
				if (feedback) {
					hoverData.feedback = feedback[data.index];
				}
			} else {
				//If there is only one dataset, read the feedback off it
				if (dataset && dataset.feedback) {
					let feedback = dataset.feedback;
					if (feedback) {
						hoverData.feedback = feedback[data.index];
					}
				}
			}
		}

		//* Adjust label and xLabel dates for proper display
		hoverData.label = stageTimestamp
			? hoverData.label
			: dateDisplay([hoverData.label, this.get('currentUser.user.firm')]);
		hoverData.xLabel = stageTimestamp
			? hoverData.xLabel
			: dateDisplay([hoverData.xLabel, this.get('currentUser.user.firm')]);

		this.set('hoverDisplayData', hoverData);
	},

	// This will initially set the selectedDataset
	setDatasetObserver: observer(
		'dataset.datasets.@each',
		'dataset',
		function () {
			let dataset = this.get('dataset.datasets.firstObject');

			if (dataset) {
				this.set('selectedDataset', {
					value: 0,
					label: dataset.label,
					dataset: dataset.dataset,
					labels: dataset.labels,
				});
			} else {
				//If we just have one dataset, set the selected option to the single one
				const singleDataSet = this.dataset;
				if (singleDataSet && singleDataSet.dataset) {
					this.set('selectedDataset', {
						value: 0,
						label: singleDataSet.label,
						labels: singleDataSet.labels,
						feedback: singleDataSet.feedback,
					});
				} else {
					// We'll just use the title by default in case the dataset isn't defined yet
					let label = this.title;

					// If we have a label, we'll set it here
					if (dataset && dataset.label) {
						label = dataset.label;
					}

					// Now we set the selectedDataset with the default/first value
					this.set('selectedDataset', {
						value: 0,
						label: label,
					});
				}
			}
		},
	).on('init'),

	// Show options to toggle between datasets
	datasetOptions: computed('dataset.datasets.@each', function () {
		// We grab the datasets array from the dataset
		let datasets = this.get('dataset.datasets');
		// We'll be returning this options var so we set it to null by default.
		// If there are no datasets, we want datasetOptions to be null so template can work with it more easily.
		let options = null;

		// If there are datasets, we update what the options var is
		if (datasets) {
			options = datasets.map((dataset, index) => {
				return {
					value: index,
					label: dataset.label,
					labels: dataset.labels,
					feedback: dataset.feedback,
				};
			});
		} else {
			let dataset = this.dataset;
			if (dataset) {
				return [
					{
						value: 0,
						label: dataset.label,
						labels: dataset.labels,
						feedback: dataset.feedback,
					},
				];
			}
		}

		// At this point, options is either null or an array of options
		return options;
	}),

	// Instead of using a hard reference to the dataset total, we need to
	// make this dynamic to support multiple datasets. So we're watching the selectedDataset,
	// as well as the dataset object and datasets array on that object
	displayTotal: computed(
		'dataset',
		'dataset.datasets.@each',
		'selectedDataset.value',
		function () {
			// If there's anything in the datasets array, we'll want to set it from there
			if (this.get('dataset.datasets.length') && this.selectedDataset) {
				// We need to figure out the selected dataset so we display the right one
				// This will correspond to an index on the datasets array
				const selected = this.get('selectedDataset.value');
				const dataset = this.get('dataset.datasets')[selected];

				// We return the total from the proper dataset
				return dataset.total;
			}

			// Otherwise, we just return the dataset.total
			return this.get('dataset.total');
		},
	),
	shouldDisplayTotal: computed('displayTotal', 'title', function () {
		let total = this.displayTotal;
		let title = this.title;

		// in this case we would prefer to return "Not enough data";
		if (title === 'Client Satisfaction' && total === null) return false;

		return typeOf(total) === 'number';
	}),
	chartData: computed('dataset', 'selectedDataset', function () {
		let data = this.dataset;
		let title = this.title;

		// If the data contains multiple datasets, we need to treat it a little differently
		if (data && data.datasets && data.datasets.length) {
			// Get the index value from the selectedDataset
			let selectedDataset = this.get('selectedDataset.value');
			// Then set the data variable as the proper dataset
			data = data.datasets[selectedDataset];
			// Once we do this, everything else is good as this dataset is the same as other single ones
		}

		if (data !== undefined) {
			let options = {
				labels: data.labels,
				datasets: [
					{
						label: title,
						data: data.dataset,
						backgroundColor: this.get('customBranding.brandingColorFunc')
							? this.get('customBranding.brandingColorFunc')
									.alpha(0.1)
									.rgb()
									.string()
							: 'rgba(65, 158, 247, 0.1)',
						borderColor: this.get('customBranding.brandingColorFunc')
							? this.get('customBranding.brandingColorFunc').rgb().string()
							: 'rgb(65, 158, 247)',
						borderJoinStyle: 'round',
						lineTension: 0,
						pointRadius: 0,
						pointHitRadius: 0,
					},
				],
			};

			if (this.showPoints) {
				options.datasets[0].pointRadius = 5;
				options.datasets[0].pointHitRadius = 5;
				options.datasets[0].pointBorderWidth = 3;
				options.datasets[0].pointBorderColor = this.get(
					'customBranding.brandingColorFunc',
				)
					? this.get('customBranding.brandingColorFunc').rgb().string()
					: 'rgb(65, 158, 247)';
				options.datasets[0].pointBackgroundColor = 'rgba(255, 255, 255, 1)';
			}

			return options;
		}
	}),

	selectedDatasetLastFeedbackLabel: computed('selectedDataset', function () {
		const selectedDataset = this.selectedDataset;
		if (!selectedDataset || !selectedDataset.feedback) {
			return null;
		}

		const selected = this.get('selectedDataset.value');
		let dataset = null;

		if (this.get('dataset.datasets')) {
			dataset = this.get('dataset.datasets')[selected].dataset;
		} else {
			dataset = this.dataset.dataset;
		}

		let label = null;
		for (var i = dataset.length - 1; i >= 0; i--) {
			const data = dataset[i];
			label = selectedDataset.labels[i];
			if (data) {
				break;
			}
		}
		return label;
	}),

	selectedDatasetLastFeedback: computed('selectedDataset', function () {
		const selectedDataset = this.selectedDataset;
		if (!selectedDataset || !selectedDataset.feedback) {
			return null;
		}

		const selected = this.get('selectedDataset.value');
		let dataset = null;

		if (this.get('dataset.datasets')) {
			dataset = this.get('dataset.datasets')[selected].dataset;
		} else {
			dataset = this.dataset.dataset;
		}

		let feedback = null;
		for (var i = dataset.length - 1; i >= 0; i--) {
			const data = dataset[i];
			feedback = selectedDataset.feedback[i];
			if (data) {
				break;
			}
		}
		return feedback;
	}),

	dateRange: computed('dataset', function () {
		const start = this.get('dataset.labels.firstObject');
		const end = this.get('dataset.labels.lastObject');
		const stageTimestamp = this.stageTimestamp;

		if (start !== undefined && end !== undefined) {
			const startDisplay = stageTimestamp
				? start
				: dateDisplay([start, this.get('currentUser.user.firm')]);
			const endDisplay = stageTimestamp
				? end
				: dateDisplay([end, this.get('currentUser.user.firm')]);
			return `${startDisplay} to ${endDisplay}`;
		}

		return '';
	}),

	//For use if feedback is available
	selectedClientFirstName: computed('selectedDataset', function () {
		const selectedDataset = this.selectedDataset;
		if (selectedDataset && selectedDataset.label) {
			return selectedDataset.label.split(' ')[0];
		} else {
			return null;
		}
	}),

	showOnlyOneClientText: computed(
		'multiClient',
		'datasetOptions.[]',
		function () {
			return this.multiClient && this.get('datasetOptions.length') === 1;
		},
	),

	actions: {
		selectDataset(d) {
			if (d) {
				this.set('selectedDataset', d);
			}
		},
		requestExportMessages() {
			if (this.fetchingMessages) return;

			this.set('fetchingMessages', true);
			const result = RSVP.defer();
			const start = this.get('dataset.labels.firstObject');
			const end = this.get('dataset.labels.lastObject');

			this.exportMessages(start, end, result);

			result.promise.then(() => {
				this.set('fetchingMessages', false);
			});
		},
	},
});
