/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	thirdParty: service(),

	model(...params) {
		const transition = params[1];
		const transitionURL =
			transition.intent?.url || transition.intent?.contexts[0] || '';

		if (transitionURL.includes('sfdcIFrameOrigin')) {
			this.thirdParty.isLitifyAdmin = true;
		}
	},

	setupController() {
		this.transitionTo('app');
	},
});
