// /** @format */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';

// eslint-disable-next-line ember/no-classic-classes
export default Service.extend({
	currentUser: service(),
	store: service(),
	firmSettingsObjDynamic: null,
	user: reads('currentUser.user.permissions'),
	company: reads('currentUser.permissions'),
	firm: reads('company'),
	org: reads('company'),
	case: null, //* Set and removed in the case route setupController/resetController lifecycle hooks
	firmSettings: computed(
		'currentUser.user.firm.firmSettings.firmSettings',
		'firmSettingsObjDynamic.firmSettings',
		function () {
			if (this.firmSettingsObjDynamic) {
				return this.get('firmSettingsObjDynamic.firmSettings');
			} else {
				// Since Ember 3.1, this is usually fine as you no longer need to use `.get()`
				// to access computed properties. However, in this case, the object in question
				// is a special kind of Ember object (a proxy). Therefore, it is still necessary
				// to use `.get('firmSettings')` in this case.
				return this.get('currentUser.user.firm.firmSettings.firmSettings');
			}
		},
	),
	validateQueryParams(queryParams) {
		// Check if queryParams.setting exists
		if (!queryParams || !queryParams.setting) {
			throw new Error('queryParams.setting is required');
		}

		// Split the settings by comma and trim whitespace
		let settings = queryParams.setting
			.split(',')
			.map((setting) => setting.trim());

		// Validate each setting
		settings.forEach((setting) => {
			if (!this.firmSettings.hasOwnProperty(setting)) {
				throw new Error(`Invalid setting: ${setting}`);
			}
		});

		// Return the valid settings
		return settings;
	},

	fetchDynamicFirmSettings(queryParams) {
		// This should take the form of {"setting": "automations_per_practice_area"}
		// We should only be calling this when and where we need these dynamic settings
		try {
			let baseSettings = this.validateQueryParams(queryParams);
			// Check if any of the base settings have values
			let isRelevant = baseSettings.some(
				(setting) => this.firmSettings[setting] != null,
			);
			if (isRelevant) {
				let firmSettingsObj = this.store.queryRecord(
					'firm-setting',
					queryParams,
				);
				this.set('firmSettingsObjDynamic', firmSettingsObj);
			}
		} catch (error) {
			console.error('Error fetching new firm settings:', error);
		}
	},
});
