/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	childId: attr('number'),
	type: attr('string'),
	caseCount: attr('number'),

	firm: belongsTo('firm'),
	organization: belongsTo('organization'),
	user: belongsTo('user'),

	documentSignatory: hasMany('document-signatory'),
	userPermissionId: attr('number'),
	userPermissions: attr(),

	permissionsType: attr('string'),
});
