/** @format */

import { computed, observer } from '@ember/object';
import Controller from '@ember/controller';
import Validations from '../../../../constants/validations';
import { buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import EmberError from '@ember/error';
import ENV from 'case-status/config/environment';
import calendarOptions from '../../../../constants/calendar-options';
import Errors from 'case-status/constants/errors';
import { resolve, reject } from 'rsvp';
import { format } from 'libphonenumber-js';
import { next } from '@ember/runloop';
import { createErrorIfFileTooBig } from '../../../../helpers/validate-file-size';

export default Controller.extend(buildValidations(Validations.Password), {
	chromeExtensionUrl:
		'https://chromewebstore.google.com/detail/case-status-for-google-ch/npofhepaljhejbmhheffcjgcafdcnaac',
	queryParams: ['success'],
	success: null,
	company: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),
	calendarOptions,
	isAttorney: computed('model', function () {
		return this.model.get('constructor.modelName') === 'attorney';
	}),

	successParamObserver: observer('success', function () {
		if (
			this.success == 'True' &&
			this.currentUser.user.isGoogleOauthSet &&
			this.currentUser.user.googleCalendarId &&
			this.model.get('calendarType') != 'gcal'
		) {
			this.model.set('calendarType', 'gcal');
			this.send('saveUser');
		}
	}),

	showCaseNotificationsModal: false,
	wantsClientNotifications: computed(
		'model.wantsEmailNotificationTypes',
		function () {
			const notificationTypes = this.get('model.wantsEmailNotificationTypes');
			return notificationTypes && notificationTypes.includes('client');
		},
	),
	wantsTreatmentNotifications: computed(
		'model.wantsEmailNotificationTypes',
		function () {
			const notificationTypes = this.get('model.wantsEmailNotificationTypes');
			return notificationTypes && notificationTypes.includes('treatment_activity');
		},
	),
	wantsNpsNotifications: computed(
		'model.wantsEmailNotificationTypes',
		function () {
			const notificationTypes = this.get('model.wantsEmailNotificationTypes');
			return notificationTypes && notificationTypes.includes('nps_notification');
		},
	),


	maxUploadFileMB: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
			);
		},
	),

	caseNotificationsOn: computed('model.caseNotificationsOff', {
		get() {
			return !this?.model.get('caseNotificationsOff');
		},

		set(key, value) {
			if (typeof value != 'boolean') {
				throw new EmberError('Value must be a boolean');
			}
			this.set('model.caseNotificationsOff', !value);
			return value;
		},
	}),

	toggleCaseNotifications() {
		this.toggleProperty('caseNotificationsOn');
		this.model.save().catch((err) => {
			this.set('errors', err);
			this.model?.rollbackAttributes();
		});
	},

	formattedBusinessNumber: computed('model.businessNumber', function () {
		let phoneNumberOriginal = this.get('model.businessNumber');
		if (phoneNumberOriginal) {
			return format(phoneNumberOriginal, 'International');
		}
	}),

	formattedCellNumber: computed('model.cellPhone', function () {
		let phoneNumberOriginal = this.get('model.cellPhone');
		if (phoneNumberOriginal) {
			return format(phoneNumberOriginal, 'International');
		}
	}),

	profileUpdateObserver: observer('shouldCrop', 'updatedPhoto', function () {
		if (this.shouldCrop && this.updatedPhoto) {
			this.send('saveBio');

			this.set('shouldCrop', false);
			this.set('updatedPhoto', false);
		}
	}),

	isEditingAccountInfo: false,
	isEditingBio: false,
	shouldCrop: false,
	updatedPhoto: false,

	showMergePrompt: false,

	saveModel() {
		return this.model
			.save()
			.then(() => {
				this.set('errors', null);
				return resolve();
			})
			.catch((response) => {
				//* Parse for specific errors to trigger certain events
				const errors = Errors.mapResponseErrors(response) || [];

				if (
					Array.isArray(errors) &&
					errors.length &&
					errors.includes(
						'There is already a user with that cell phone number.',
					)
				) {
					this.set('mergingPhone', true);
					this.set('showMergePrompt', true);
				} else {
					this.set('errors', errors);
				}

				return reject(response);
			});
	},

	actions: {
		toggleShowCaseNotificationsModal() {
			this.toggleProperty('showCaseNotificationsModal');
		},

		confirmToggleCaseNotifications() {
			this.toggleCaseNotifications();
			this.toggleProperty('showCaseNotificationsModal');
		},

		syncGCalButtonPressed() {
			const url = `${ENV.host}/google/token?redirect_uri=${ENV.host}/app/firm/settings/account`;
			this.ajax
				.request(url)
				.then((res) => {
					window.location = res;
				})
				.catch((err) => {
					this.set('errors', err);
				});
		},
		selectCalendar(value) {
			this.set('currentUser.user.calendar', value);
		},
		saveUser() {
			return this.currentUser.user
				.save()
				.then(() => {
					this.notifications.success('Settings updated', {
						canClose: true,
						autoClear: true,
					});
				})
				.catch((err) => {
					this.notifications.error(Errors.mapResponseErrors(err), {
						canClose: true,
						autoClear: true,
					});
				});
		},

		cancelEditing() {
			if (this.isEditingAccountInfo) {
				this.send('cancelEditAccountInformation');
			}
			if (this.isEditingBio) {
				this.send('cancelEditBio');
			}
			this.set('passwordSuccessMessage', null);
		},

		cancelEditAccountInformation() {
			if (this.oldFirstName) {
				this.set('model.firstName', this.oldFirstName);
				this.set('model.lastName', this.oldLastName);
				this.set('model.emailAddress', this.oldEmailAddress);
				this.set('model.cellPhone', this.oldCellPhone);
			}

			this.set('model.contactEmailAddress', this.oldContactEmailAddress);

			this.set('isEditingAccountInfo', false);
			this.set('showAccountError', false);
		},

		cancelEditBio() {
			if (this.oldBio) {
				this.set('model.bio', this.oldBio);
				//The business number is displayed to the client
				this.set('model.businessNumber', this.oldBusinessNumber);
			}

			this.set('isEditingBio', false);
			this.set('showBioError', false);
		},

		editAccountInformation() {
			const firstName = this.get('model.firstName');
			const lastName = this.get('model.lastName');
			const emailAddress = this.get('model.emailAddress');
			const contactEmailAddress = this.get('model.contactEmailAddress');
			const cellPhone = this.get('model.cellPhone');

			this.set('oldFirstName', firstName);
			this.set('oldLastName', lastName);
			this.set('oldEmailAddress', emailAddress);
			this.set('oldCellPhone', cellPhone);
			this.set('oldContactEmailAddress', contactEmailAddress);

			this.set('isEditingAccountInfo', true);
		},

		editBio() {
			this.set('oldBio', this.get('model.bio'));
			this.set('oldBusinessNumber', this.get('model.businessNumber'));

			this.set('isEditingBio', true);
		},

		saveAccountInformation() {
			const isValid = this.get('model.validations.isValid');
			const accountPhoneNumberErrorsLength = this.get(
				'accountPhoneNumberErrors.length',
			);

			if (!isValid || accountPhoneNumberErrorsLength >= 1) {
				this.set('showAccountError', true);
				return reject();
			}

			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},

		saveBio() {
			const isValid = this.get('model.validations.isValid');
			const phoneNumberErrorsLength = this.get('phoneNumberErrors.length');

			if (this.imgToCrop && !this.shouldCrop) {
				this.set('shouldCrop', true);
				return;
			}

			if (!isValid || phoneNumberErrorsLength >= 1) {
				this.set('showBioError', true);
				return reject();
			}
			return this.saveModel()
				.then(() => {
					this.set('isEditingBio', false);
					this.set('showBioError', false);
				})
				.catch(() => {
					this.set('showBioError', true);
				});
		},

		showFilePicker() {
			document.querySelector('#file_uploader').click();
		},

		toggleWantsDailyDigest(value) {
			this.set('model.wantsDailyDigest', value);
			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},

		toggleMessageNotification(value) {
			// options for value are notification types: nps_notification | treatment_activity | client
			// if the option is in wantsEmailNotificationTypes, remove it... otherwise add it
			const wantsEmailNotificationTypes =
				this.get('model.wantsEmailNotificationTypes') || [];
			if (wantsEmailNotificationTypes.includes(value)) {
				const newNotificationTypes = wantsEmailNotificationTypes.filter(
					(notification) => notification != value,
				);
				if (newNotificationTypes.length === 0) {
					this.set('model.wantsEmailNotificationTypes', null);
					this.set('model.wantsEmailNotifications', false);
				} else {
					this.set('model.wantsEmailNotificationTypes', newNotificationTypes);
				}
			} else {
				this.set('model.wantsEmailNotificationTypes', [
					value,
					...wantsEmailNotificationTypes,
				]);
				this.set('model.wantsEmailNotifications', true);
			}
			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},

		uploadFile(e) {
			const file = e.target.files[0];
			const reader = new FileReader();
			const _this = this;

			const errorMsg = createErrorIfFileTooBig([
				file.size,
				this.maxUploadFileMB,
				this.notifications,
			]);
			if (errorMsg != null) {
				return;
			}

			reader.readAsDataURL(file);
			reader.onloadend = function () {
				const base64data = reader.result;
				_this.set('imgToCrop', base64data);
			};
		},

		updatePasswordPressed() {
			const isValid = this.get('validations.isValid');
			if (!isValid) {
				this.set('showPasswordError', true);
				return reject();
			} else {
				this.set('model.password', this.password);
				return this.saveModel()
					.then(() => {
						this.set(
							'passwordSuccessMessage',
							'Password changed successfully!',
						);
						this.set('showPasswordError', false);
					})
					.catch(() => {
						this.set('passwordSuccessMessage', null);
						this.set('showPasswordError', true);
						this.set('model.password', null);
					});
			}
		},

		uploadImage(img) {
			this.send('updateProfilePhoto', img);

			this.set('updatedPhoto', true);
		},

		handleAccountPhoneInputError(message) {
			if (message) {
				this.set('accountPhoneNumberErrors', [message]);
			} else {
				this.set('accountPhoneNumberErrors', []);
			}
		},

		handleBioPhoneInputError(message) {
			if (message) {
				this.set('bioPhoneNumberErrors', [message]);
			} else {
				this.set('bioPhoneNumberErrors', []);
			}
		},

		requestMerge() {
			const data = this.mergingPhone
				? {
						cell_phone: this.get('model.cellPhone'),
				  }
				: {
						email_address: this.get('model.emailAddress'),
				  };
			return this.ajax
				.post(`${ENV.host}/users/request_transfer`, { data })
				.then(() => {
					this.set('showMergePrompt', false);
					this.set('showMergeVerification', true);
				});
		},

		async verifyMerge() {
			if (this.get('model.id')) {
				const data = this.mergingPhone
					? {
							cell_phone: this.get('model.cellPhone'),
					  }
					: {
							email_address: this.get('model.emailAddress'),
					  };

				data.verification_code = this.get('verificationCode');

				return this.ajax
					.post(
						`${ENV.host}/users/${this.get('model.userId')}/confirm_transfer`,
						{ data },
					)
					.then((res) => {
						if (res.message) {
							// in case the message gets taken away or changed to another key
							this.notifications.success(res.message, {
								canClose: true,
								autoClear: true,
								clearDuration: 3000,
							});
						}

						this.set('mergingPhone', false);
						this.set('verificationCode', null);
						this.set('showMergeVerification', false);
						this.model.reload(); //* reload the user to show the updated number
						next(() => {
							this.set('ajax.noCache', true);
							this.saveModel();
						});
					});
			} else return reject('model.id is missing');
		},

		cancelMerge() {
			this.set('verificationCode', null);
			this.set('showMergeVerification', false);
			this.set('mergingPhone', false);
		},

		toggleWantsExceptionReporting(value) {
			this.model.set('wantsExceptionReporting', value);

			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},
	},
});
