/** @format */

import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import { createErrorIfFileTooBig } from '../helpers/validate-file-size';

export default Component.extend({
	notifications: service(),
	currentUser: service(),

	init() {
		this._super(...arguments);
		const fd = new FormData();
		this.set('fd', fd);
	},

	acceptFileTypes: '.pdf,.png,.jpg,.jpeg,.mov,.mp4,.doc,.docx',
	filename: null,
	error: null,

	maxUploadFileMB: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
			);
		},
	),

	actions: {
		attachFile(e) {
			const fd = this.fd;
			const file = e.target.files[0];
			fd.append('file', file, file.name);

			const errorMsg = createErrorIfFileTooBig([
				file.size,
				this.maxUploadFileMB,
				this.notifications,
			]);
			if (errorMsg != null) {
				this.set('error', errorMsg);
				return;
			}
			this.set('filename', file.name);
		},
		removeFile() {
			const fd = this.fd;
			fd.delete('file');
			this.set('filename', null);
			this.set('error', null);
			this.$('#file_picker').prop('value', '');
		},
		postFile() {
			const fd = this.fd;
			const result = defer();
			this.postFile(fd, result);

			return result.promise
				.catch((error) => {
					this.set('error', error);
				})
				.finally(() => {
					this.set('filename', null);
					fd.delete('file');
				});
		},
	},
});
