/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';

export default Component.extend({
	customBranding: service('custom-branding'),
	currentUser: service(),
	company: service(),

	deleteAutomations: true,

	// Add / Remove has-modal class to body to lock scrolling behind modal
	didInsertElement() {
		$('html').addClass('has-modal');
	},

	willDestroyElement() {
		$('html').removeClass('has-modal');
	},

	actions: {
		closeCase() {
			const result = defer();
			this.closeCase(this.deleteAutomations, result);
			return result.promise;
		},
	},
});
