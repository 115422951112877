/** @format */

import $ from 'jquery';
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Table from 'ember-light-table';
import { storageFor } from 'ember-local-storage';
import { dateDisplay } from 'case-status/helpers/date-display';
import { action } from '@ember/object';

@classic
export default class SettingsUsersTable extends Component {
	attributeBindings = ['data-test-id'];
	'data-test-id' = 'component';
	@service currentUser;
	@service company;
	@service permissions;
	@storageFor('preferences') preferences;

	get columns() {
		const currentUserFirm = this.currentUser?.user?.firm;
		const userPermissions = this.permissions.get('user');

		return [
			{
				label: 'Profile Picture',
				// valuePath: 'profilePicture.url',
				width: '50px',
				sortable: false,
				cellComponent: 'settings-user-profile-picture-cell',
			},
			{
				label: `Name`,
				valuePath: 'user.name',
				width: '250px',
				sortable: false,
				cellClassNames: 'ellipsis bold',
			},
			{
				label: `Email`,
				valuePath: 'user.emailAddress',
				sortable: false,
				cellClassNames: 'ellipsis',
				cellComponent: 'cell-overflow-hover',
			},
			{
				label: 'Last Active',
				valuePath: 'user.lastOpened',
				width: '175px',
				align: 'left',
				cellClassNames: 'light italic',
				sortable: false,
				format(value) {
					// Format date Display
					if (value === null) {
						return '';
					} else {
						return `Last active ${dateDisplay([value, currentUserFirm])}`;
					}
				},
			},
			...(userPermissions.invite_users
				? [
						{
							label: 'Resend Invite',
							valuePath: 'user.lastOpened',
							align: 'right',
							width: '125px',
							sortable: false,
							cellComponent: 'users-resend-invite-cell',
						},
				  ]
				: []),
			{
				label: 'Edit',
				valuePath: 'id',
				align: 'right',
				width: '75px',
				sortable: false,
				cellComponent: 'users-edit-cell',
			},
		];
	}

	model;
	table;

	init() {
		super.init(...arguments);
		this.createTable();
	}

	createTable(...callbacks) {
		const cols = this.columns;
		const mod = this.model;
		const table = Table.create({ columns: cols, rows: mod, enableSync: true });
		const sortColumn = table.get('allColumns').findBy('valuePath', 'updatedAt');

		if (sortColumn) {
			// If case sortColumn wouldn't exist for some reason, we'd still want to minimize the bug
			// and have it just be that the table isn't sorted on load instead of the table never loads
			sortColumn.set('sorted', true);
			sortColumn.set('ascending', false);
		}

		this.set('table', table);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			const scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});

		// Loop through callbacks passed and pass back an object
		// with the table, cols, mod, and opts to be viewed/used
		for (let i = 0; i < callbacks.length; i++) {
			const callback = callbacks[i];
			if (callback && typeof callback === 'function') {
				callback({ table, cols, mod });
			}
		}
	}

	@action onScrolledToBottom() {
		if (typeof this?.tableActions?.onScrolledToBottom === 'function') {
			this.tableActions.onScrolledToBottom();
		}
	}
}
