import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<SelectableTable\n\t@model={{model}}\n\t@modelLabel={{modelLabel}}\n\t@btnText={{btnText}}\n\t@title={{title}}\n\t@tableOptions={{tableOptions}}\n\t@onSubmitHandler={{action 'handleSubmit'}}\n/>", {"contents":"<SelectableTable\n\t@model={{model}}\n\t@modelLabel={{modelLabel}}\n\t@btnText={{btnText}}\n\t@title={{title}}\n\t@tableOptions={{tableOptions}}\n\t@onSubmitHandler={{action 'handleSubmit'}}\n/>","moduleName":"case-status/components/cases/modals/bulk-actions/assign-primary.hbs","parseOptions":{"srcName":"case-status/components/cases/modals/bulk-actions/assign-primary.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isFunction } from 'case-status/constants/data-types';
import { inject as service } from '@ember/service';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';

export default class CasesModalsBulkActionsAssignPrimaryComponent extends Component {
	constructor() {
		super(...arguments);
	}
	@service store;
	@service company;

	get model() {
		return this.store.peekAll('attorney').toArray();
	}

	get title() {
		return `Update the Primary on selected ${String(
			dynamicCaseLabel([this.company, true]),
		).toLowerCase()}`;
	}

	get btnText() {
		const selectedCasesText = this?.args?.selectedCasesText;
		const btnTextEnding = selectedCasesText ? ' on ' + selectedCasesText : '';

		return `Update the Primary${btnTextEnding}`;
	}

	get modelLabel() {
		return capitalize(attorneyDisplay([this.company, 2, true]) ?? '');
	}

	get tableOptions() {
		return {
			limit: 1,
		};
	}

	@action
	handleSubmit(selected) {
		const onSubmitHandler = this?.args?.onSend;

		if (isFunction(onSubmitHandler)) {
			return onSubmitHandler(selected);
		}

		return Promise.reject('No submit handler found in assign-primary.js');
	}
}
