import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class='progress-circle'>\n\t<svg\n\t\twidth='{{@size}}'\n\t\theight='{{@size}}'\n\t\tviewBox='0 0 {{@size}} {{@size}}'\n\t\tclass='circular-progress'\n\t\tstyle='\n\t\t\t--size: {{@size}}px;\n\t\t\t--half-size: calc(var(--size) / 2);\n\t\t\t--progress: {{progress}};\n\t\t\t--stroke-width: {{strokeSize}}px;\n\t\t'\n\t>\n\t\t<circle class='bg' style='stroke: {{bgStroke}}'></circle>\n\t\t<circle class='fg' style='stroke: {{fgStroke}}'></circle>\n\t</svg>\n\n</span>", {"contents":"<span class='progress-circle'>\n\t<svg\n\t\twidth='{{@size}}'\n\t\theight='{{@size}}'\n\t\tviewBox='0 0 {{@size}} {{@size}}'\n\t\tclass='circular-progress'\n\t\tstyle='\n\t\t\t--size: {{@size}}px;\n\t\t\t--half-size: calc(var(--size) / 2);\n\t\t\t--progress: {{progress}};\n\t\t\t--stroke-width: {{strokeSize}}px;\n\t\t'\n\t>\n\t\t<circle class='bg' style='stroke: {{bgStroke}}'></circle>\n\t\t<circle class='fg' style='stroke: {{fgStroke}}'></circle>\n\t</svg>\n\n</span>","moduleName":"case-status/components/progress-circle.hbs","parseOptions":{"srcName":"case-status/components/progress-circle.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ProgressCircleComponent extends Component {
	@tracked bgStroke;
	@tracked fgStroke;
	@tracked strokeSize = 3;

	constructor() {
		super(...arguments);
		this.bgStroke = this.args.bgColor;
		this.fgStroke = this.args.fgColor;
		this.strokeSize = this.args.strokeSize;
	}

	get progress() {
		return this.args.progress > 100 ? 100 : this.args.progress;
	}
}
