/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
	//* Services
	routing: service('-routing'),
	currentUser: service(),
	session: service(),
	customBranding: service(),
	company: service(),
	mobile: service(),

	//* State Properties
	showAccountMenu: false,

	//* Actions
	actions: {
		toggleShowAccountMenu() {
			this.toggleProperty('showAccountMenu');
		},
	},
});
