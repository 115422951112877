/** @format */

import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { getOwner } from '@ember/application';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default Controller.extend({
	getOwner,
	showNewTreatmentForm: false,
	customBranding: service('custom-branding'),
	currentUser: service(),
	permissions: service(),

	treatmentCards: computed('model.treatments', 'editId', function () {
		let cards = [];
		let treatments = A(this.model.treatments);
		const editId = this.editId;
		treatments
			.sortBy('appointmentDate')
			.reverse()
			.forEach((treatment) => {
				cards.push({
					treatment,
					editing: editId ? (treatment.id == editId ? true : false) : false,
				});
			});
		return cards;
	}),

	actions: {
		onAfterSave(changeset, card) {
			const saveCallback = this.saveCallback || this.callback;
			this.set('showTreatmentForm', false);

			if (card) {
				card.editing = false;
			}

			if (changeset) {
				const currentRouteInstance = this.getOwner(this).lookup(
					`route:app.firm.case.treatments`,
				);
				currentRouteInstance.refresh();
			}

			if (saveCallback && typeof saveCallback === 'function') {
				saveCallback();
			}
		},
		onAfterDelete() {
			const deleteCallback = this.deleteCallback || this.callback;

			const currentRouteInstance = this.getOwner(this).lookup(
				`route:app.firm.case.treatments`,
			);
			currentRouteInstance.refresh();

			if (deleteCallback && typeof deleteCallback === 'function') {
				deleteCallback();
			}
		},

		toggleShowMedicalProviderModal(medicalProvider) {
			if (medicalProvider) {
				this.set('selectedMedicalProvider', medicalProvider);
				this.set('showMedicalProviderModal', true);
			} else {
				this.set('selectedMedicalProvider', null);
				this.set('showMedicalProviderModal', false);
			}
		},
	},
});
