/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	company: service(),

	beforeModel() {
		this._super(...arguments);
		this.send('gatekeeper', 'settings_message_templates', 'settings.firm');
	},

	setupController(controller) {
		controller.fetchNewData();
	},

	resetController(controller) {
		this._super(...arguments);
		controller.setSelectedMessageTemplate(null);
	},
});
