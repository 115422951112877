import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='video-container' style={{this.containerStyle}}>\n\t<video\n\t\tclass='responsive-iframe'\n\t\tcontrols\n\t\tsrc={{this.video.url}}\n\t\ttype='video/{{this.video.extension}}'\n\t>\n\t\tYour browser does not support the video tag.\n\t</video>\n</div>", {"contents":"<div class='video-container' style={{this.containerStyle}}>\n\t<video\n\t\tclass='responsive-iframe'\n\t\tcontrols\n\t\tsrc={{this.video.url}}\n\t\ttype='video/{{this.video.extension}}'\n\t>\n\t\tYour browser does not support the video tag.\n\t</video>\n</div>","moduleName":"case-status/components/video-display.hbs","parseOptions":{"srcName":"case-status/components/video-display.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class VideoDisplayComponent extends Component {
	@tracked video;
	@tracked paddingTop;

	constructor() {
		super(...arguments);
		this.video = this.args.video;
		this.computePaddingTop();
	}

	computePaddingTop() {
		// Check if the video and its aspect ratio are defined
		if (this.video && this.video.aspectRatio) {
			// Split the aspect ratio string into width and height parts
			const aspectRatioParts = this.video.aspectRatio.split(':');
			// Ensure the aspect ratio string is in the correct format (two parts)
			if (aspectRatioParts.length === 2) {
				// Parse the width and height parts as numbers
				const width = Number(aspectRatioParts[0]);
				const height = Number(aspectRatioParts[1]);
				// Check if both width and height are valid numbers and greater than zero
				if (!isNaN(width) && !isNaN(height) && width > 0 && height > 0) {
					// Compute the padding-top percentage based on the aspect ratio
					this.paddingTop = (height / width) * 100;
					return;
				}
			}
		}
		// Fallback to default 16:9 aspect ratio if aspect ratio is invalid or not provided
		this.paddingTop = 56.25;
	}

	get containerStyle() {
		return `padding-top: ${this.paddingTop}%;`;
	}
}
