/** @format */

import Service, { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import PubNub from 'pubnub';
import { A } from '@ember/array';
import { captureException } from 'logrocket';

// eslint-disable-next-line ember/no-classic-classes
export default Service.extend({
	client: null,
	listener: null,
	newMessage: null,
	store: service(),
	currentUser: service(),
	company: service(),
	chromeExtension: service(),

	newMessageCallbacks: A([]),
	newChatMessageCallbacks: A([]),
	newRecommendedResponseCallbacks: A([]),

	cleanNewMessageCallbacks(targetId) {
		const index = this.newMessageCallbacks.findIndex((callbackObj) => {
			return callbackObj.targetId === targetId;
		});

		if (index !== -1) {
			this.newMessageCallbacks.splice(index, 1);
		}

		return true;
	},

	cleanNewChatMessageCallbacks(targetId) {
		const index = this.newChatMessageCallbacks.findIndex((callbackObj) => {
			return callbackObj.targetId === targetId;
		});

		if (index !== -1) {
			this.newChatMessageCallbacks.splice(index, 1);
		}

		return true;
	},

	init() {
		this._super(...arguments);

		const client = new PubNub({
			//authKey: ENV.pubnubAuthKey,
			//cipherKey: ENV.pubnubCipherKey,
			subscribeKey: ENV.pubnubSubscribeKey,
			secretKey: ENV.pubnubSecretKey,
			ssl: true,
			uuid: ENV.uuid,
		});

		this.set('client', client);
	},

	subscribe(childId, type, firmId) {
		const client = this.client;
		client.subscribe({
			channels: [`pubnub-${type}-${childId}`, `pubnub-firm-${firmId}`],
		});

		const _this = this;
		const listener = {
			message: async function (m) {
				const msg = m.message;
				try {
					if (msg.data.type === 'unread_message_count') {
						_this.chromeExtension.sendUnreadCount(msg.data.content);
					} else if (msg.data.type == 'recommended_responses') {
						if (
							_this.newRecommendedResponseCallbacks &&
							_this.newRecommendedResponseCallbacks.length
						) {
							for (
								let i = 0;
								i < _this.newRecommendedResponseCallbacks.length;
								i++
							) {
								const callbackObj = _this.newRecommendedResponseCallbacks[i];
								if (callbackObj) {
									callbackObj(msg.data.attributes.content);
								}
							}
						}
					} else if (msg.data.type === 'files') {
						const file = _this
							.get('store')
							.push(_this.get('store').normalize('file', msg.data));
						let message;

						if (msg.data.attributes.message_id) {
							message = _this
								.get('store')
								.peekRecord('message', msg.data.attributes.message_id);
						}

						if (message) {
							message.set('file', file);
							if (
								_this.newMessageCallbacks &&
								_this.newMessageCallbacks.length
							) {
								for (let i = 0; i < _this.newMessageCallbacks.length; i++) {
									const callbackObj = _this.newMessageCallbacks[i];
									if (
										callbackObj.callback &&
										typeof callbackObj.callback === 'function'
									) {
										callbackObj.callback(message);
									}
								}
							}
						} else if (file.messageId) {
							//* If the message hasn't come through yet, stage the file in the
							//* callback array to be attached once the certain message comes through
							const fileMessageCallback = (msg) => {
								if (msg && msg.id === file.messageId) {
									msg.set('file', file);
									_this.cleanNewMessageCallbacks(file.messageId);
								}
							};
							//* Setup a callbackObj with an identifying targetId and targetType
							//* with a the callback function as a prop to be called
							const callbackObj = {
								type: 'file',
								status: 'pending',
								targetId: file.messageId,
								targetType: 'message',
								payload: file,
								callback: fileMessageCallback,
							};
							//* Push the new callbackObj into the callback array
							_this.newMessageCallbacks.push(callbackObj);
						}
					} else if (msg.data.type === 'chat_files') {
						let chatMessage;
						const chatFile = _this
							.get('store')
							.push(_this.get('store').normalize('chat-file', msg.data));

						chatMessage.set('chatFile', chatFile);

						if (msg.data.attributes.chat_message_id) {
							chatMessage = _this
								.get('store')
								.peekRecord('message', msg.data.attributes.message_id);
						}

						if (chatMessage) {
							chatMessage.set('chatFile', chatFile);

							//* Check for callbacks and run them passing in the chatMessage
							if (
								_this.newMessageCallbacks &&
								_this.newMessageCallbacks.length
							) {
								for (let i = 0; i < _this.newMessageCallbacks.length; i++) {
									const callbackObj = _this.newMessageCallbacks[i];
									if (
										callbackObj.callback &&
										typeof callbackObj.callback === 'function'
									) {
										callbackObj.callback(chatMessage);
									}
								}
							}
						} else if (chatFile.messageId) {
							//* If the message hasn't come through yet, stage the file in the
							//* callback array to be attached once the certain message comes through
							const chatFileMessageCallback = (msg) => {
								if (msg && msg.id === chatFile.messageId) {
									msg.set('chatFile', chatFile);
									_this.cleanNewChatMessageCallbacks(chatFile.messageId);
								}
							};

							//* Setup a callbackObj with an identifying targetId and targetType
							//* with a the callback function as a prop to be called
							const callbackObj = {
								type: 'chat-file',
								status: 'pending',
								targetId: chatFile.messageId,
								targetType: 'chat-message',
								payload: chatFile,
								callback: chatFileMessageCallback,
							};
							//* Push the new callbackObj into the callback array
							_this.newChatMessageCallbacks.push(callbackObj);
						}
					} else if (msg.data.type === 'notifications') {
						const newMessage = _this
							.get('store')
							.push(_this.get('store').normalize('notification', msg.data));
						const theCase = _this
							.get('store')
							.peekRecord('case', newMessage.get('caseId'));
						// Check to see if the message already exists
						let message = _this.store.peekRecord(
							'message',
							newMessage.messageId,
						);
						if (!message) {
							//Create dummy message record from notification
							message = _this.get('store').createRecord('message', {
								createdAt: new Date(),
								sentAt: new Date(),
								id: newMessage.get('messageId'),
								content: newMessage.get('content'),
								case: theCase,
								notifications: [newMessage],
								sender: _this.get('store').createRecord('user', {
									name: newMessage.get('senderName'),
								}),
								senderName: newMessage.get('senderName'),
								senderType: newMessage.get('senderType'),
							});
						}

						// Update the newMessages count, mostRecentMessageContent, and mostRecentMessageSentAt on the case
						const newMessagesCount = theCase.get('newMessages');
						theCase.set('newMessages', newMessagesCount + 1);
						theCase.set('mostRecentMessageContent', newMessage.get('content'));
						theCase.set('mostRecentMessageSentAt', message.sentAt);

						_this.set('newMessage', newMessage);

						if (_this.newMessageCallbacks && _this.newMessageCallbacks.length) {
							for (let i = 0; i < _this.newMessageCallbacks.length; i++) {
								const callback = _this.newMessageCallbacks[i];
								if (callback && typeof callback === 'function') {
									callback(message);
								}
							}
						}
					} else if (msg.data.type === 'chat_notifications') {
						let newChatMessage = _this
							.get('store')
							.push(
								_this.get('store').normalize('chat-notification', msg.data),
							);
						//Create dummy message record from notification
						_this
							.get('store')
							.findRecord('chat', newChatMessage.get('chatId'))
							.then((chat) => {
								const theCase = _this
									.get('store')
									.peekRecord('case', chat.get('caseId'));

								//Check if the message exists
								let message = _this
									.get('store')
									.peekRecord(
										'chat-message',
										newChatMessage.get('chatMessageId'),
									);

								if (!message) {
									message = _this.get('store').createRecord('chat-message', {
										createdAt: new Date(),
										id: newChatMessage.get('chatMessageId'),
										content: newChatMessage.get('content'),
										chat: chat,
										chatNotifications: [newChatMessage],
										sender: _this.get('store').createRecord('user', {
											name: newChatMessage.get('senderName'),
										}),
										senderName: newChatMessage.get('senderName'),
										type: newChatMessage.get('type'),
									});
								}

								if (theCase) {
									if (chat.isInternal) {
										theCase.incrementProperty('newInternalMessages');
									} else {
										theCase.incrementProperty('newChatMessages');
									}
								}
								_this.set('newChatMessage', newChatMessage);

								//Update chat unreads
								chat.calculateUnreads();

								if (
									_this.newChatMessageCallback &&
									typeof _this.newChatMessageCallback === 'function'
								) {
									_this.newMessageCallback(newChatMessage);
								}

								if (
									_this.newChatMessageCallbacks &&
									_this.newChatMessageCallbacks.length
								) {
									for (
										let i = 0;
										i < _this.newChatMessageCallbacks.length;
										i++
									) {
										const callback = _this.newChatMessageCallbacks[i];
										if (callback && typeof callback === 'function') {
											callback(message);
										}
									}
								}
							});
					} else if (msg.data.type === 'live_feed_items') {
						let newLiveFeedItem = _this
							.get('store')
							.push(_this.get('store').normalize('live-feed-item', msg.data));
						_this.set('newLiveFeedItem', newLiveFeedItem);
					}
				} catch (error) {
					//* Send an "error" message to logRocket to note unintended behavior
					captureException(
						'ERROR: Received unexpected error when attempting to process incoming PubNub message',
						{
							tags: {
								action: 'Incoming PubNub Message',
							},
							extra: {
								currentchildID: this?.currentUser?.user?.id,
								firmId: this?.company?.info?.id,
								pubnubMessage: m,
							},
						},
					);
				}
			},
		};

		client.addListener(listener);
		this.set('listener', listener);
	},

	unsubscribe() {
		const client = this.client;
		client.removeListener(this.listener);
		client.unsubscribeAll();
	},
});
