/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';

export function filenameFromUrl(params /*, hash*/) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'filename-from-url helper requires data passed in as an array',
		);
	}

	const [url] = params;

	//* If there isn't a url provided, or it evaluates to falsey, return an empty string
	//* that way we don't have "null" or "undefined" showing somewhere or causing errors to throw
	if (!url) {
		return '';
	}

	const lastIndex = `${url}`.lastIndexOf('/');

	if (lastIndex == -1) return url;

	return url.slice(lastIndex + 1);
}

export default helper(filenameFromUrl);
