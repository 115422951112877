import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { defer, resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

@classic
export default class PracticeAreaModal extends Component {
	@tracked searchText = '';
	@tracked searchCaseTypeText = '';

	@tracked selectedPracticeArea = null;
	caseType = null;
	@tracked errors = [];
	@tracked stagesCache = [];
	isBlankStage = false;
	@tracked _selectedCaseType = null;
	@service company;
	stagesSwapped = false;
	@tracked verifyingSwap = false;
	@tracked newStages = 0;

	init() {
		super.init(...arguments);
		const isConfiguringStages = this.isConfiguringStages;
		const isEditing = this.isEditing;
		const isDuplicating = this.isDuplicating;
		const caseType = this.caseType;

		// if we are not passing in a caseType lets generate one and set it to a pending state or if we are
		// are not configuring stages but are editing
		if (!caseType || (!isConfiguringStages && !isEditing && !isDuplicating)) {
			const freshCaseType = this.store.createRecord('case-type', {
				pending: true,
				name: '',
				imported: false,
				firm: '',
			});

			this.set('caseType', freshCaseType);
		} else if (caseType && isDuplicating) {
			const caseStatuses = caseType
				.get('caseStatuses')
				.toArray()
				.map((status) => {
					const importNames = status.importNames?.length
						? status.importNames
						: [];
					return this.store.createRecord('case-status', {
						name: status.name || '',
						description: status.description || '',
						number: status.number || 0,
						importNames: [...importNames],
					});
				});
			const importNames = caseType.get('importNames.length')
				? caseType.get('importNames')
				: [];
			const dupedCaseType = this.store.createRecord('case-type', {
				pending: caseType.get('pending'),
				name: `${caseType.get('name')} (copy)`,
				imported: false,
				importName: `${caseType.get('importName')}`,
				importNames: [...importNames],
				firm: '',
				caseStatuses,
			});

			this.set('caseType', dupedCaseType);
			this.set('stagesCache', caseType.get('caseStatuses').sortBy('number'));
		}
	}

	get validations() {
		const stageNumbers = this.stageNumbers;
		const uniqueStages = new Set(stageNumbers);
		return {
			attrs: {
				caseType: {
					name: {
						isValid: this?.caseType?.name ? true : false,
						message: 'Practice Area Name is a required field.',
					},
					caseStatuses: {
						isValid: uniqueStages.size === stageNumbers.length,
						message: 'Stage numbers must be unique within a Practice Area.',
					},
				},
			},
		};
	}

	get actionText() {
		return this.isEditing ? 'Update' : 'Finish & Save';
	}

	get searchablePracticeAreaTemplates() {
		const practiceAreaTemplates = this.practiceAreaTemplates || [];
		const searchText = this.searchText;

		return practiceAreaTemplates.filter((template) => {
			return template
				.get('name')
				.toLowerCase()
				.includes(searchText.toLowerCase());
		});
	}

	get searchableCaseTypeTemplates() {
		const templates = this.selectedPracticeArea.get('caseTypeTemplates');
		const searchCaseTypeText = this.searchCaseTypeText;

		return templates.filter((template) => {
			return template
				.get('name')
				.toLowerCase()
				.includes(searchCaseTypeText.toLowerCase());
		});
	}

	get hasIntegration() {
		return this.company.info.usesIntegration;
	}

	get stageNumbers() {
		return this.caseType
			.get('caseStatuses')
			.toArray()
			.map((status) => status.number);
	}

	get sortedCaseStatuses() {
		return this.caseType.get('caseStatuses').sortBy('number');
	}

	set sortedCaseStatuses(val) {
		if (val && Array.isArray(val)) {
			this.caseType.set(
				'caseStatuses',
				val.map((stage, i) => {
					stage.set('number', i + 1);
					return stage;
				}),
			);
		}
	}

	get disableDrag() {
		if (!this.caseType) return true;
		return this.caseType.get('automationTemplates.length');
	}

	get selectedCaseType() {
		return this._selectedCaseType;
	}

	set selectedCaseType(value) {
		this._selectedCaseType = value;
		const selectedCaseType = this.selectedCaseType || value;
		const isConfiguringStages = this.isConfiguringStages;
		const caseType = this.caseType;

		if (selectedCaseType && !isConfiguringStages) {
			caseType.set('name', selectedCaseType.get('name'));
			caseType.set(
				'caseStatuses',
				selectedCaseType.get('caseStatusTemplates').map((template) => {
					return this.store.createRecord('case-status', {
						name: template.get('name'),
						description: template.get('description'),
						number: template.get('number'),
					});
				}),
			);
		} else if (selectedCaseType && isConfiguringStages) {
			caseType.get('caseStatuses').then((caseStatuses) => {
				if (caseStatuses.get('length') > 0) {
					caseStatuses.forEach((status) => {
						// find the template to copy
						const templateToCopy = selectedCaseType
							.get('caseStatusTemplates')
							.filter((t) => t.get('number') === status.get('number'))[0];
						status.set('name', templateToCopy.get('name'));
						status.set('description', templateToCopy.get('description'));
					});
				} else {
					// Some caseTypes may not have any caseStatuses at all, this accounts for that
					caseType.set(
						'caseStatuses',
						selectedCaseType.get('caseStatusTemplates').map((template) => {
							return this.store.createRecord('case-status', {
								name: template.get('name'),
								description: template.get('description'),
								number: template.get('number'),
							});
						}),
					);
				}
			});
		}
	}

	rollbackDirtyCaseStatuses(caseStatus) {
		if (caseStatus.hasDirtyAttributes) {
			caseStatus.rollbackAttributes();
		}
	}

	@action
	back() {
		if (this.selectedCaseType) {
			this.set('selectedCaseType', null);
		} else if (this.selectedPracticeArea) {
			this.set('selectedPracticeArea', null);
		}
	}

	@action
	close() {
		const caseType = this.caseType;

		if (this.isDuplicating) {
			caseType.deleteRecord();
		} else if (this.stagesCache?.length) {
			caseType.caseStatuses = this.stagesCache;
			this.stagesCache = [];
		} else {
			this.caseType.caseStatuses.forEach(this.rollbackDirtyCaseStatuses);
		}
		this.newStages = 0;
		this._close();
	}

	@action
	recordCasesSwapped() {
		this.stagesSwapped = true;
	}

	@action
	closeVerifySwapModal() {
		this.verifyingSwap = false;
	}

	@action
	verifyAndSave(caseType) {
		if (this.stagesSwapped && Number(caseType.get('caseCount'))) {
			this.verifyingSwap = true;
		} else {
			this.save(caseType);
		}
	}

	@action
	save(caseType) {
		const save = this._save;
		const result = defer();
		this.errors = [];

		if (
			this.validations.attrs.caseType.name.isValid &&
			this.validations.attrs.caseType.caseStatuses.isValid
		) {
			save(caseType, result);

			result.promise
				.then(() => {
					this.stagesCache = [];
					this.newStages = 0;
					this.set('isDuplicating', false);
					this.set('stagesSwapped', false);
					this.set('verifyingSwap', false);
					this.send('close');
				})
				.catch((errors) => {
					this.errors = [...this.errors, ...errors];
					if (this.errors.length && this?.stagesCache?.length) {
						caseType.set('caseStatuses', this.stagesCache);
						this.stagesCache = [];
						caseType.oldStages = [];
						this.newStages = 0;
					}
				});
		} else if (!this.validations.attrs.caseType.name.isValid) {
			this.set('showCaseTypeNameError', true);
			this.set('errors', [this.validations.attrs.caseType.name.message]);
			result.reject();
		} else {
			this.set('errors', [
				this.validations.attrs.caseType.caseStatuses.message,
			]);
			result.reject();
		}

		return result.promise;
	}

	@action
	handlePracticeAreaSelection(area) {
		this.set('selectedPracticeArea', area);
	}

	@action
	handleCaseTypeTemplateSelection(caseType) {
		this.set('selectedCaseType', caseType);
	}

	@action
	onBlankStageClick() {
		const caseType = this.caseType;

		//* For some reason a caseType can be flagged as pending but does have stages, so only create blank stages if the caseType has none already
		if (!caseType.get('caseStatuses.length')) {
			const blankCaseStatuses = [];
			for (let i = 1; i <= 6; i += 1) {
				blankCaseStatuses.push(
					this.store.createRecord('case-status', {
						name: '',
						description: '',
						number: i,
					}),
				);
			}

			caseType.set('caseStatuses', blankCaseStatuses);
		}

		this.toggleProperty('isBlankStage');
	}

	@action
	async addStage() {
		const stages = await this.caseType.get('caseStatuses').sortBy('number');
		if (!this.stagesCache.length) {
			this.set('stagesCache', [...stages]);
		}
		const newStage = this.store.createRecord('case-status', {
			name: null,
			description: null,
			number: stages.length + 1,
			caseType: this.caseType,
		});
		stages.pushObject(newStage);
		this.newStages += 1;

		return resolve();
	}

	@action
	async removeStage() {
		const stages = await this.caseType.get('caseStatuses').sortBy('number');
		if (!this.stagesCache.length) {
			this.set('stagesCache', [...stages]);
		}
		const oldStages = this.caseType.oldStages || [];
		const deletedStage = stages.popObject();
		oldStages.push(deletedStage);
		this.set('caseType.oldStages', oldStages);
		this.set('caseType.caseStatuses', stages);

		// if deletedStage does not have an id, this was a new stage that we just added
		if (!deletedStage.id) {
			this.newStages -= 1;
		}

		return resolve();
	}
}
