/** @format */

import Component from '@ember/component';

export default Component.extend({
	actions: {
		close() {
			if (this.close && typeof this.close == 'function') {
				this.close();
			}
		},
	},
});
