/**
 * Created by kevin on 7/19/17.
 *
 * @format
 */

import UserSerializer from './user';
export default UserSerializer.extend({
	attrs: {
		case: { serialize: false },
		firm: { serialize: false },
		password: { serialize: false },
		name: { serialize: false },
		isSelected: { serialize: false },
	},
});
