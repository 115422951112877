/**
 * eslint-disable no-prototype-builtins
 *
 * @format
 */

import Component from '@glimmer/component';
import EmberError from '@ember/error';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class FilterDropdown2 extends Component {
	constructor() {
		super(...arguments);
		this.search = this.args.allowSearch || false;
		this.searchString =
			typeof this.args.defaultSearch === 'string'
				? this.args.defaultSearch.trim()
				: '';

		const content = A([]);
		const { options, isLoading } = this.args;
		let hasIcons = false;
		let style = 'multiSelect';

		if (
			typeof options === 'object' &&
			(options['startDate'] || options['startDate'] === null) &&
			(options['endDate'] || options['endDate'] === null)
		) {
			style = 'dateRange';
		}
		// For some of our filters we are requesting values from the API and are rerendering the component
		// when those value are returned. If that is not the paradigm, then 'isLoading' will not be a tracked field
		if (
			(typeof isLoading == 'boolean' && isLoading === false) ||
			typeof isLoading != 'boolean'
		) {
			if (style !== 'dateRange' && (!options || !Array.isArray(options))) {
				throw EmberError(
					'filter-dropdown must be passed `options` but options equaled ',
					options,
				);
			}

			if (!Array.isArray(options) && style !== 'dateRange') {
				throw EmberError(
					'filter-dropdown `options` value must be an array or an object with properties of `startDate` and `endDate`!',
				);
			}

			if (style !== 'dateRange') {
				options.forEach((option) => {
					if (typeof option !== 'object' || Array.isArray(option)) {
						// Arrays count as object typing
						throw EmberError('filter-dropdown options must be objects');
					}

					if (!option.hasOwnProperty('value')) {
						throw EmberError(
							'filter-dropdown options must have a `value` property',
						);
					}

					if (
						option.hasOwnProperty('value') &&
						(option.value === null ||
							option.value === undefined ||
							option.value === false ||
							typeof option.value !== 'string' ||
							!option.value.trim().length)
					) {
						//* This was throwing an error before, which prevented the cases list from rendering...
						//* Would it not be better to just exclude this option from the dropdown?
						return;
					}

					if (!option.hasOwnProperty('param')) {
						throw EmberError(
							'filter-dropdown options must have a `param` property',
						);
					}

					if (
						option.hasOwnProperty('param') &&
						(option.param === null ||
							option.param === undefined ||
							option.param === false)
					) {
						throw EmberError(
							"filter-dropdown options' param property cannot be null, undefined, or false",
						);
					}

					option.value = option.value.trim();

					if (option.icon) {
						if (typeof option.icon !== 'string') {
							throw EmberError(
								"filter-dropdown options' icon property must be a string",
							);
						}

						option.icon = option.icon.trim();
						hasIcons = true;
					}

					if (option.faIcon) {
						if (typeof option.faIcon !== 'string') {
							throw EmberError(
								"filter-dropdown options' faIcon property must be a string",
							);
						}

						option.faIcon = option.faIcon.trim();
						hasIcons = true;
					}

					if (option.imgSrc) {
						if (typeof option.imgSrc !== 'string') {
							throw EmberError(
								"filter-dropdown options' imgSrc property must be a string",
							);
						}

						if (options.icon) {
							option.imgSrc = option.icon.trim();
						}

						hasIcons = true;
					}

					option.checked =
						option.checked ||
						Boolean(A(this.args.selections).findBy('param', option.param)) ||
						false;

					content.pushObject(option);
				});
			}
		}

		if (hasIcons === true) {
			style += 'WithIcon';
		}

		this.sortKey = this.args.sortKey || 'value';
		this.content = content.sortBy(this.sortKey);
		this.options = options;
		this.hasIcons = hasIcons;
		this.selections = this.content.filterBy('checked', true);
		this.showDropdown = false;
		this.style = style;
		this.limit = this.args.limit;

		if (this.args.onChange && typeof this.args.onChange === 'function') {
			this.handleOnChange = this.args.onChange;
		} else {
			this.handleOnChange = (args) => void args;
		}
	}

	//* Services
	@service customBranding;

	//* Set tracked properties
	@tracked searchString;
	@tracked showDropdown;
	@tracked style;
	@tracked options;
	@tracked content;

	//* Computed Properties (Getters/Setters)
	get displayContent() {
		if (this.options['startDate'] || this.options['startDate'] === null)
			return true;

		const selections = this.selections;
		const filteredContent = this.content.filter((option) => {
			return (
				!this.selections.findBy('param', option.param) &&
				option.value.toLowerCase().includes(this.searchString.toLowerCase())
			);
		});
		return [
			...selections.sortBy(this.sortKey),
			...filteredContent.sortBy(this.sortKey),
		];
	}

	get isActive() {
		return (
			this.selections.length > 0 ||
			this.options.startDate ||
			this.options.endDate
		);
	}

	get activeClasses() {
		const activeClasses = ['col-xs-12', 'filter-btn-text'];

		if (this.showDropdown === true) {
			activeClasses[1] = 'filter-btn-text-selected';
		}

		if (this.isActive) {
			activeClasses.push('active');
		}

		return activeClasses.join(' ');
	}

	get startDate() {
		return this.options?.startDate;
	}

	get endDate() {
		return this.options?.endDate;
	}

	set startDate(value) {
		this.options.startDate = value;

		this.handleOnChange({
			dateRanges: { startDate: this.startDate, endDate: this.endDate },
		});
	}

	set endDate(value) {
		this.options.endDate = value;

		this.handleOnChange({
			dateRanges: { startDate: this.startDate, endDate: this.endDate },
		});
	}

	//* Actions
	@action
	handleSelection(selection) {
		if (this.selections.findBy('param', selection.param)) {
			this.selections.removeObject(selection);
		} else if (selection) {
			this.selections.pushObject(selection);
		} else {
			throw EmberError('Selection was falsey!');
		}

		if (this.limit && this.selections.length == this.limit) {
			this.content = this.content.map((opt) => {
				if (this.selections.findBy('param', opt.param)) {
					return opt;
				} else {
					opt.disabled = true;
					return opt;
				}
			});

			this.showDropdown = false;
		} else if (this.limit) {
			this.content = this.content.map((opt) => {
				opt.disabled = false;
				return opt;
			});
		}

		//* Run in next runloop to give everything a chance to update first
		next(() => {
			this.handleOnChange(this.selections);
		});
	}

	@action
	toggleShowDropdown() {
		this.showDropdown = !this.showDropdown;
	}
}
