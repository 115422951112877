/** @format */

import Route from '@ember/routing/route';
import InboxRouteMixin from '../../../mixins/route/inbox';
import RSVP from 'rsvp';

export default Route.extend(InboxRouteMixin, {
	model() {
		const chatNotifications = this.infinity.model('chat-notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});

		return RSVP.hash({
			chatNotifications,
		});
	},
});
