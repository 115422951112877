/** @format */

import Controller from '@ember/controller';
import InboxControllerMixin from '../../../mixins/controller/inbox';
import { computed } from '@ember/object';
import { A } from '@ember/array';

export default Controller.extend(InboxControllerMixin, {
	inbox: computed('model.chatNotifications.[]', function () {
		const chatNotifications = this.get('model.chatNotifications').toArray();

		return A([...chatNotifications]);
	}),
});
