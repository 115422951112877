/** @format */

import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { getOwner } from '@ember/application';
import { A } from '@ember/array';

export default Controller.extend({
	getOwner,
	showNewAppointmentForm: false,
	appointmentCards: computed('model.appointments', function () {
		let cards = [];
		let appointments = A(this.model.appointments);
		appointments.forEach((appointment) => {
			cards.push({
				appointment,
				editing: false,
			});
		});
		return cards;
	}),
	pastAppointments: computed('appointmentCards', function () {
		return this.appointmentCards
			.filter((card) => {
				return card.appointment.isPast;
			})
			.reverse();
	}),
	pendingAppointments: computed('appointmentCards', function () {
		return this.appointmentCards.filter((card) => {
			return !card.appointment.isPast;
		});
	}),
	actions: {
		onAfterSave(changeset, card) {
			this.set('showNewAppointmentForm', false);
			if (card) {
				card.editing = false;
			}
			if (changeset) {
				const currentRouteInstance = this.getOwner(this).lookup(
					`route:app.client.case.appointments`,
				);
				currentRouteInstance.refresh();
			}
		},
		onAfterDelete() {
			const currentRouteInstance = this.getOwner(this).lookup(
				`route:app.client.case.appointments`,
			);
			currentRouteInstance.refresh();
		},
	},
});
