/* eslint-disable ember/no-classic-classes */
/** @format */

import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import ENV from 'case-status/config/environment';
import { defer } from 'rsvp';
import Errors from 'case-status/constants/errors';

export default Service.extend({
	options: A([]),
	conditionalOptions: A([]),
	ajax: service(),
	notifications: service(),
	loaded: false,
	error: false,

	init() {
		this._super(...arguments);
		this.refresh();
	},

	/**
	 * Preview formatted text to see what values are used in place of the Case Status shortcodes
	 *
	 * @param {String} text String to be formatted, may or may not contain shortcodes
	 * @param {Number} caseId Case ID related to the message being previewed
	 * @param {Number} clientId Client ID related to the client that is specified in the specific message being previewed
	 * @returns a promise (will trigger a formatted error notif on catch but will also still pass the err along for further processing as needed)
	 */
	preview(text, caseId, clientId) {
		const result = defer();
		if (!text) {
			result.reject('text param missing');
			return result.promise;
		}

		const data = { data: { message_content: text } };

		// More maintainable and readable way to handle query params
		const queryParams = new URLSearchParams();
		if (caseId) {
			queryParams.append('case_id', caseId);
			queryParams.append('request_as_sender', 'true');
		}
		if (clientId) {
			queryParams.append('client_id', clientId);
		}

		this.ajax
			.post(`${ENV.host}/message_templates/format?${queryParams.toString()}`, {
				data,
			})

			.then((res) => {
				return result.resolve(res);
			})
			.catch((err) => {
				this.notifications.error(Errors.mapResponseErrors(err), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});
				return result.reject(err);
			});

		return result.promise;
	},

	refresh() {
		this.set('loaded', false);
		this.set('error', false);

		this.ajax
			.request(`${ENV.host}/message_templates/short_codes`)
			.then((res) => {
				this.set('options', res.data.short_codes);
				this.set('descriptionKeys', res.data.short_code_descriptions);
				this.set('conditionalOptions', res.data.conditional_codes);
				this.set('loaded', true);

				const formattedDescriptionsKeys = [];
				for (let key in res.data.short_code_descriptions) {
					formattedDescriptionsKeys.push(
						`<br><div><b>${key}</b>:<br>${res.data.short_code_descriptions[key]}</div>`,
					);
				}

				this.set('formattedDescriptionsKeys', formattedDescriptionsKeys);
			})
			.catch((err) => {
				this.set('error', err);
			});
	},
});
