import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! first placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p class='placeholder-content typewriter'>\n\t\t\t{{this.firstPlaceholderMessage}}</p>\n\t</div>\n</div>\n{{! second placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p\n\t\t\tclass={{concat\n\t\t\t\t'placeholder-content typewriter '\n\t\t\t\t(if this.showSecondPlaceholder '' 'hidden')\n\t\t\t}}\n\t\t>{{this.secondPlaceholderMessage}}</p>\n\t</div>\n</div>\n{{! third placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p\n\t\t\tclass={{concat\n\t\t\t\t'placeholder-content typewriter '\n\t\t\t\t(if this.showThirdPlaceholder '' 'hidden')\n\t\t\t}}\n\t\t>{{this.finalPlaceholderMessage}}</p>\n\t</div>\n</div>", {"contents":"{{! first placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p class='placeholder-content typewriter'>\n\t\t\t{{this.firstPlaceholderMessage}}</p>\n\t</div>\n</div>\n{{! second placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p\n\t\t\tclass={{concat\n\t\t\t\t'placeholder-content typewriter '\n\t\t\t\t(if this.showSecondPlaceholder '' 'hidden')\n\t\t\t}}\n\t\t>{{this.secondPlaceholderMessage}}</p>\n\t</div>\n</div>\n{{! third placeholder message }}\n<div class='response-box--fixed'>\n\t<div class='content-wrapper--centered'>\n\t\t<p\n\t\t\tclass={{concat\n\t\t\t\t'placeholder-content typewriter '\n\t\t\t\t(if this.showThirdPlaceholder '' 'hidden')\n\t\t\t}}\n\t\t>{{this.finalPlaceholderMessage}}</p>\n\t</div>\n</div>","moduleName":"case-status/components/ai-placeholder-response.hbs","parseOptions":{"srcName":"case-status/components/ai-placeholder-response.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
	finalPlaceholderMessage,
	placeholderMessages,
} from '../constants/recommended-responses';

export default class AiPlaceholderResponse extends Component {
	@tracked showSecondPlaceholder = false;
	@tracked showThirdPlaceholder = false;
	shuffledMessages = [...placeholderMessages].sort(() => 0.5 - Math.random());

	constructor() {
		super(...arguments);

		// In the scss we are setting the typewriter effect for 2 seconds
		// Delay 2.5 seconds before typing successive messages should give
		// a 0.5 second buffer between one message ending and the next starting.
		setTimeout(() => {
			this.showSecondPlaceholder = true;
		}, 2500);

		setTimeout(() => {
			this.showThirdPlaceholder = true;
		}, 5000);
	}

	get firstPlaceholderMessage() {
		return this.shuffledMessages.pop();
	}

	// same logic as firstPlaceholderMessage but we want to independently control what renders for each
	get secondPlaceholderMessage() {
		return this.shuffledMessages.pop();
	}

	get finalPlaceholderMessage() {
		return finalPlaceholderMessage;
	}
}
