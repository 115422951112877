/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { reject } from 'rsvp';
import notifOptions from 'case-status/constants/notif-options';
import Errors from 'case-status/constants/errors';
import VideoHandler from 'case-status/modules/VideoHandler';

@classic
export default class MobileStatusDisplayComponent extends Component {
	@service notifications;
	@service currentUser;
	@service store;

	@tracked showFullStatusDescription = false;
	@tracked video = null;

	@action
	async toggleStatusDescription() {
		this.showFullStatusDescription = !this.showFullStatusDescription;
	}

	get isClient() {
		return this.currentUser.get('user.constructor.modelName') == 'client';
	}

	get showChecklistItems() {
		const checklistItems = this?.model?.get('checklistItems') || [];
		return checklistItems.filter((ci) => !ci.completedDate).length;
	}

	get currentStep() {
		switch (this?.model?.get('caseStatus.number')) {
			case 1:
				return 'step-one';
			case 2:
				return 'step-two';
			case 3:
				return 'step-three';
			case 4:
				return 'step-four';
			case 5:
				return 'step-five';
			case 6:
				return 'step-six';
			case 7:
				return 'step-seven';
			case 8:
				return 'step-eight';
			case 9:
				return 'step-nine';
			case 10:
				return 'step-ten';
			case 11:
				return 'step-eleven';
			case 12:
				return 'step-twelve';
			default:
				return '';
		}
	}

	get stepOneClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number) {
			return 'inactive';
		}
		return number === 1 ? 'current' : '';
	}

	get stepTwoClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 2) {
			return 'inactive';
		} else if (number === 2) {
			return 'current';
		}
		return '';
	}

	get stepThreeClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 3) {
			return 'inactive';
		} else if (number === 3) {
			return 'current';
		}
		return '';
	}

	get stepFourClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 4) {
			return 'inactive';
		} else if (number === 4) {
			return 'current';
		}
		return '';
	}

	get stepFiveClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 5) {
			return 'inactive';
		} else if (number === 5) {
			return 'current';
		}
		return '';
	}

	get stepSixClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 6) {
			return 'inactive';
		} else if (number === 6) {
			return 'current';
		}
		return '';
	}

	get stepSevenClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 7) {
			return 'inactive';
		} else if (number === 7) {
			return 'current';
		}
		return '';
	}

	get stepEightClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 8) {
			return 'inactive';
		} else if (number === 8) {
			return 'current';
		}
		return '';
	}

	get stepNineClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 9) {
			return 'inactive';
		} else if (number === 9) {
			return 'current';
		}
		return '';
	}

	get stepTenClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 10) {
			return 'inactive';
		} else if (number === 10) {
			return 'current';
		}
		return '';
	}

	get stepElevenClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 11) {
			return 'inactive';
		} else if (number === 11) {
			return 'current';
		}
		return '';
	}

	get stepTwelveClass() {
		const number = this?.model?.get('caseStatus.number');
		if (!number || number < 12) {
			return 'inactive';
		} else if (number === 12) {
			return 'current';
		}
		return '';
	}

	async didReceiveAttrs() {
		super.didReceiveAttrs(...arguments);
		const videoId = this?.model?.get('caseStatus.videoId');
		if (videoId) {
			this.video = await this.store.findRecord('video', videoId);
		} else {
			this.video = null;
		}
	}

	@action
	async downloadVideo() {
		try {
			const video = this.video;
			if (video) {
				VideoHandler.download(video.url, video.filename);
			} else {
				this.notifications.error(
					'No video available to download.',
					notifOptions.error,
				);
			}
		} catch (err) {
			this.notifications.error(
				Errors.mapResponseErrors(err),
				notifOptions.error,
			);
		}
	}

	@action
	save(savableObj, cb = () => {}) {
		if (savableObj.save && typeof savableObj.save === 'function') {
			return savableObj
				.save()
				.then(cb)
				.catch((err) => {
					this.notifications.error(
						Errors.mapResponseErrors(err),
						notifOptions.error,
					);
				});
		} else {
			return reject();
		}
	}
}
