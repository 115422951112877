import Component from '@glimmer/component';

export default class LowCostBanner extends Component {
	buttonTitle = this.args.buttonTitle || 'Upgrade now';
	displayType = this.args.displayType || 'column';
	buttonLink = this.args.buttonLink || '';

	get colorClass() {
		switch (this.args.color) {
			case 'green':
				return 'green-color';
			case 'red':
				return 'red-color';
			default:
				return 'yellow-color';
		}
	}

	get activationFailedReason() {
		return this.args.progress >= 100 && this.args.activationFailedReason;
	}
}
