/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Model.extend({
	activityTracking: service(),
	currentUser: service(),

	appointmentDate: attr('date'),
	treatmentDate: attr('date'),
	createdAt: attr('date'),
	updateAt: attr('date'),
	description: attr('string'),
	treatmentNotes: attr('string'),
	officeName: attr('string'),
	providerName: attr('string'),
	address: attr('string'),
	feelingRating: attr('number'),

	isPast: computed('appointmentDate', function () {
		//? Is it the appointment date or treatment date that makes it isPast?
		const apptDate = moment(this.appointmentDate);
		const now = moment.now();

		return now >= apptDate;
	}),

	feelingRatingText: computed('feelingRating', function () {
		const feelingRating = this.feelingRating;
		switch (feelingRating) {
			case 1:
				return 'Terrible';
			case 2:
				return 'Bad';
			case 3:
				return 'Okay';
			case 4:
				return 'Good';
			case 5:
				return 'Great';
		}
		return 'N/A';
	}),

	feelingRatingClass: computed('feelingRating', function () {
		const feelingRating = this.feelingRating;
		return 'treatment-face-' + feelingRating;
	}),

	formattedDate: computed('appointmentDate', function () {
		const apptDate = this.get('appointmentDate');
		return moment(apptDate).format('MM/DD/YYYY');
	}),
	formattedTime: computed('appointmentDate', function () {
		const apptDate = this.get('appointmentDate');
		return moment(apptDate).format('hh:mm A');
	}),

	treatmentActivities: hasMany('treatmentActivity'),
	treatmentType: belongsTo('treatmentType', { inverse: 'treatment' }),
	case: belongsTo('case'),

	save(...args) {
		//* Extend the save method to track activity;
		const activityType = this.id ? 'updatedTreatment' : 'loggedTreatment';

		return this._super(...args).then((res) => {
			//* Only Track Client Activity
			if (this.currentUser.get('user.constructor.modelName') == 'client') {
				this.activityTracking.trackByCase(this.case.get('id'), activityType);
			}

			return res;
		});
	},
});
