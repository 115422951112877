import Service, { inject as service } from '@ember/service';
import Moment from 'moment';
import { isFunction, isString } from 'case-status/constants/data-types';
import { dateDisplay } from 'case-status/helpers/date-display';

export default class DateTimeService extends Service {
	constructor() {
		super(...arguments);
	}

	@service company;

	//* Make moment fully accessible from service;
	moment = Moment;

	get timezone() {
		return this.company.info.timezone;
	}

	display(date, { withTime = false, justTime = false }) {
		return dateDisplay([date, this.company.info, withTime, justTime]);
	}

	displayFormatted(date, { withTime = false, justTime = false, time }) {
		return dateDisplay([
			this.formatDateTime(date, time),
			this.company.info,
			withTime,
			justTime,
		]);
	}

	convertTimezone(date, tz) {
		if (date) {
			if (date instanceof Moment && isFunction(date?.tz)) {
				return date.tz(tz ?? this.company.info.timezone);
			}

			if (date instanceof Date) {
				const dateMoment = Moment(date);
				return this.setTimezone(dateMoment);
			}

			throw Error(
				"Check the date param you're trying to convert the timezone on",
			);
		}

		throw Error(
			'Date param is missing or falsey while attempting to convert the timezone',
		);
	}

	setTimezone(date, tz) {
		if (date) {
			const timezone = tz ?? this.company.info.timezone;
			if (date instanceof Moment && isFunction(date?.tz)) {
				return date.tz(timezone, true);
			}

			if (date instanceof Date) {
				const dateMoment = Moment(date);
				return this.setTimezone(dateMoment);
			}

			throw Error("Check the date param you're trying to set the timezone on");
		}

		throw Error(
			'Date param is missing or falsey while attempting to set the timezone',
		);
	}

	setTime(date, time) {
		if (date instanceof Date) {
			const dateMoment = Moment(date);
			return this.setTime(dateMoment, time);
		}

		if (
			date &&
			date instanceof Moment &&
			isString(time) &&
			this.isValidTime(time)
		) {
			const hourAdjustment = time.includes('p') || time.includes('P') ? 12 : 0;
			const hr = Number(time.slice(0, time.indexOf(':'))) + hourAdjustment;
			const min = time.slice(time.indexOf(':') + 1, time.indexOf(':') + 3);

			if (hr > 24) {
				throw Error('Hour variable is greater than 24 while trying to setTime');
			}

			const rawDate = date.toDate();
			rawDate.setHours(hr == 24 ? '12' : hr == 12 ? '00' : hr);
			rawDate.setMinutes(min);
			rawDate.setSeconds(0);
			return this.moment(rawDate);
		}

		throw Error(
			'Date param is missing or falsey while attempting to set the timezone',
		);
	}

	isValidTime(str) {
		// Regex to check valid
		// time in 12-hour format
		let regex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);

		//  if str
		// is empty return false
		if (str == null) {
			return false;
		}

		// Return true if the str
		// matched the ReGex
		if (regex.test(str) == true) {
			return true;
		} else {
			return false;
		}
	}

	formatDateTime(date, time, preserveTZ = false) {
		let workingDate = date;
		if (!preserveTZ) {
			workingDate = this.setTimezone(workingDate);
		}

		if (isString(time) && this.isValidTime(time)) {
			workingDate = this.setTime(workingDate, time);
		}

		return workingDate;
	}

	prepareForApi(date) {
		//* Api wants every dateTime as UTC
		return Moment.utc(date);
	}
}
