/** @format */

import { A } from '@ember/array';
import Route from '@ember/routing/route';

export default Route.extend({
	model: function () {
		return A([]);
	},

	setupController(controller) {
		this._super(...arguments);
		controller.fetchNewData();
		controller.set('isLoading', true);

		// reset case filters
		this.controllerFor('app.firm.cases').send('clearFilters');
	},

	actions: {
		save(client, result) {
			client
				.save()
				.then(() => {
					result.resolve();
				})
				.catch((err) => {
					result.reject(err);
				});
		},
		delete(client, result) {
			client
				.destroyRecord()
				.then(() => {
					result.resolve();
				})
				.catch((err) => {
					result.reject(err);
				});
		},
	},
});
