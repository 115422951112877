/** @format */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SandboxMobileController extends Controller {
	@tracked showTopNav = false;
	@tracked showCasesBottomNav = false;
	@tracked showLoremIpsum = false;

	@action
	toggleTopNav() {
		this.toggleProperty('showTopNav');
	}

	@action
	toggleCasesBottomNav() {
		this.toggleProperty('showCasesBottomNav');
	}

	@action
	toggleShowLoremIpsum() {
		this.toggleProperty('showLoremIpsum');
	}
}
