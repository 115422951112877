/** @format */

import { observer } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import webNotification from 'simple-web-notification';
import $ from 'jquery';
import RSVP from 'rsvp';
import { underscore } from '@ember/string';
import { storageFor } from 'ember-local-storage';

export default Route.extend({
	preferences: storageFor('preferences'),
	infinity: service(),
	ajax: service(),
	pubnub: service('pubnub-service'),
	session: service(),
	notifications: service(),
	currentUser: service('current-user'),

	chatNotificationObserver: observer('pubnub.newChatMessage', function () {
		const newChatMessage = this.get('pubnub.newChatMessage');
		const _this = this;
		if (newChatMessage) {
			const newChatMessageContent = $(
				'<p>' + newChatMessage.get('content') + '</p>',
			).text();
			_this.pushNewNotification(newChatMessage, 'chatNotifications');

			webNotification.showNotification(
				'New chat message from ' + newChatMessage.get('senderName'),
				{
					body: newChatMessageContent,
					icon: 'img/favicon.png',
					onClick: function onNotificationClick() {
						//TODO: How do we navigate to the chat on the case?
						_this.store
							.findRecord('chat', newChatMessage.chatId)
							.then((chat) => {
								_this.transitionTo('app.organization.case', chat.caseId);
							});
					},
				},
			);
		}
	}).on('init'),

	pushNewNotification(notification, type) {
		this.infinity.pushObjects(this.controller.get(`model.${type}`), [
			notification,
		]);
	},

	model() {
		const chatNotifications = this.getChatNotificationInfinityModel();

		return RSVP.hash({
			chatNotifications,
		});
	},

	getChatNotificationInfinityModel() {
		return this.infinity.model('chat-notification', {
			perPage: 100,
			startingPage: 1,
			perPageParam: 'page[size]',
			pageParam: 'page[number]',
			totalPagesParam: 'meta.total_pages',
			'filter[all]': true,
		});
	},

	markNotificationsAsRead(type) {
		let url = `${ENV.host}/${underscore(type)}/mark_as_read`;

		return this.ajax.post(url).then(() => {
			let model = this.controller.model.chatNotifications;
			let unreadNotifications = model.filterBy('readAt', null);
			unreadNotifications.forEach((notification) => {
				notification.set('readAt', new Date());
			});

			// Update the notifications on every chat
			let chats = this.store.peekAll('chat');
			chats.map((chat) => chat.calculateUnreads());

			//Also update all the cases newChatMessages to 0
			let cases = this.store.peekAll('case');
			cases.forEach((c) => {
				if (c.get('newChatMessages') > 0) {
					c.set('newChatMessages', 0);
				}
			});
		});
	},

	actions: {
		markAllNotificationsAsRead(result) {
			const chatNotificationsPromise =
				this.markNotificationsAsRead('chatNotifications');

			return RSVP.all([chatNotificationsPromise])
				.then(() => {
					result.resolve();
				})
				.catch((response) => {
					this.notifications.error(
						'Unable to mark all notifications as read. Please try again later',
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
					result.reject(response);
				});
		},
	},
});
