/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';

export function reviewDisplay(params = []) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			`review-display helper requires params passed in as an array`,
		);
	}

	//* link must be a String to use String methods,
	//* but must be allowed to be undefined therefore
	//* defaulting to an empty String as a fallback
	const link = params[0] && typeof params[0] == `string` ? params[0] : ``;

	if (link.includes(`google.com`)) {
		return `Google`;
	} else if (link.includes(`yelp.com`)) {
		return `Yelp`;
	} else if (link.includes(`avvo.com`)) {
		return `Avvo`;
	} else {
		return ``;
	}
}

export default helper(reviewDisplay);
