/** @format */

import Route from '@ember/routing/route';
import AdblockDetector from 'adblock-detector';

export default Route.extend({
	setupController(controller) {
		this._super(...arguments);
		controller.set('userHasAdblock', AdblockDetector.detect());
	},
});
