import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title='Status'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n/>", {"contents":"<FilterDropdown2\n\t@title='Status'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n/>","moduleName":"case-status/components/cases-filters/status-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/status-filters.hbs"}});
import BaseCasesFilterTemplate, { A, action } from './base-filter-template';

export default class CasesFiltersStatusFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		const options = A([
			{
				value: 'Stage 1',
				param: 1,
				index: '00',
			},
			{
				value: 'Stage 2',
				param: 2,
				index: '01',
			},
			{
				value: 'Stage 3',
				param: 3,
				index: '02',
			},
			{
				value: 'Stage 4',
				param: 4,
				index: '03',
			},
			{
				value: 'Stage 5',
				param: 5,
				index: '04',
			},
			{
				value: 'Stage 6',
				param: 6,
				index: '05',
			},
			{
				value: 'Stage 7',
				param: 7,
				index: '06',
			},
			{
				value: 'Stage 8',
				param: 8,
				index: '07',
			},
			{
				value: 'Stage 9',
				param: 9,
				index: '08',
			},
			{
				value: 'Stage 10',
				param: 10,
				index: '09',
			},
			{
				value: 'Stage 11',
				param: 11,
				index: '10',
			},
			{
				value: 'Stage 12',
				param: 12,
				index: '11',
			},
		]);
		options.forEach((option) => {
			option.checked = this.args.selections?.includes(option.param);
		});
		this.options = options;
	}

	@action
	handleOnChange(selections = []) {
		super.handleOnChange(selections);

		// handle how it will be set on the cases controller and format that as an object
		this.onChange({ bulkStatus: selections.map((sel) => sel.param) });
	}
}
