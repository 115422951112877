/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	customBranding: service(),
	attributeBindings: ['disabled', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'checkbox-group',
	'data-test-id': 'content',
});
