/** @format */

import Route from '@ember/routing/route';

export default Route.extend({
	gateCheck: 0,
	beforeModel() {
		this._super(...arguments);
		this.incrementProperty('gateCheck');

		// Let the settings sub-routes gate check loop only 3 times before forcing cases as the fallback route
		const fallbackRoute = this.gateCheck < 3 ? 'settings.case-types' : 'cases';
		this.send('gatekeeper', 'settings_user_account', fallbackRoute, () => {
			this.replaceWith('app.firm.settings.account');
		});
	},
});
