/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import moment from 'moment';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';
import ENV from 'case-status/config/environment';
import Errors from '../../../../constants/errors';

export default Controller.extend({
	company: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),

	timeouts: null,

	page: 1,
	meta: null,
	queryParams: null,

	isLoading: true,

	currentTab: 'Per Charge',

	// Search properties
	searchTerm: '',
	searchType: 'receiptId',

	// Search Types
	receiptId: null,
	filterOptions: computed(function () {
		return ['receiptId'];
	}),

	queryChanged: observer('filters.{}', 'sort', function () {
		debounce(this, this.fetchNewData, 1000);
	}),

	searchTermChanged: observer('searchTerm', function () {
		const searchTerm = this.searchTerm;

		switch (this.searchType) {
			case 'receiptId':
				this.set('receiptId', searchTerm);
				break;
		}
	}),

	searchTypeChanged: observer('searchType', function () {
		const searchTerm = this.searchTerm;

		if (searchTerm === '') {
			this.set('receiptId', null);
		}

		switch (this.searchType) {
			case 'receiptId':
				this.set('receiptId', searchTerm);
				break;
			default:
				this.set('receiptId', null);
				break;
		}
	}),

	isExporting: false,
	isExported: false,
	exportError: null,
	exportSuccess: null,
	showExportError: false,
	showExportResponseModal: false, //* maybe just make a closable notif

	minDate: computed('currentUser.user.firm.createdAt', function () {
		const momentMinDate = moment(this.get('currentUser.user.firm.createdAt'));
		return momentMinDate.isValid()
			? momentMinDate.toDate()
			: moment('01-01-1967').toDate();
	}),

	startDate: null,

	endDate: null,

	apiFormattedStartDate: computed('startDate', function () {
		if (!this.startDate) return null;
		const momentStartDate = moment(this.startDate);

		if (momentStartDate.isValid()) {
			return moment(momentStartDate).format('YYYY-MM-DD');
		} else return null;
	}),

	apiFormattedEndDate: computed('endDate', function () {
		if (!this.endDate) return null;
		const momentEndDate = moment(this.endDate);

		if (momentEndDate.isValid()) {
			return moment(momentEndDate).format('YYYY-MM-DD');
		} else return null;
	}),

	filters: computed(
		'receiptId',
		'apiFormattedStartDate',
		'apiFormattedEndDate',
		function () {
			let activeFilters = {};
			const startDate = this.apiFormattedStartDate;
			const endDate = this.apiFormattedEndDate;

			const availableFilters = {
				stripe_receipt_id: this.receiptId ? 'like:' + this.receiptId : false,
				start_date: startDate,
				end_date: endDate,
			};

			for (let filterProperty in availableFilters) {
				let testValue = availableFilters[filterProperty];

				// Don't want to send useless filters like empty arrays
				if (testValue instanceof Array && testValue.length < 1) {
					testValue = false;
				}

				if (testValue) {
					// Some API endpoints yell at me if the boolean value on certain properties isn't formatted as a string 😢, luckily fetchNewData accepts it as a string or a boolean
					const formattedValue =
						typeof testValue == 'boolean' ? testValue.toString() : testValue;

					activeFilters[filterProperty] = formattedValue;
				}
			}
			return activeFilters;
		},
	),

	fetchNewData() {
		//* Only run when the model is properly set
		if (!this.model) return false;

		//* Clear the model
		this.model.clear();

		//* Set the isLoading state property to true
		this.set('isLoading', true);

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			page: {},
		};

		//* Set the filter property on the queryParams to our computed filters property
		queryParams.filter = this.filters;

		//* Set the page Object's size property to 25, eventually we should allow them to be able to choose different page sizes
		queryParams.page.size = 25;

		//* Set the sort on the queryParams to our current sortProperty
		queryParams.sort = this.sort;

		//* Set the queryParams
		this.set('queryParams', queryParams);

		//* Query the store for charges using the queryParams
		this.store.query('invoice', queryParams).then((charges) => {
			//* Clear the model since this is all new data
			// this.model.clear();
			//* Set a constant as the array of the response
			const chargeArray = charges.toArray();
			//* Clear the model again to prevent duplicates
			this.model.clear();

			//* Push the new array's objects into the newly cleared model
			this.model.pushObjects(chargeArray);

			//* Set the meta property using the meta from the response
			this.set('meta', charges.get('meta'));

			//* Reset the page count to 1
			this.set('page', 1);

			//* In the next runloop we will set the isLoading state property to false
			next(() => {
				this.set('isLoading', false);
				this.set('isExported', false);
				this.set('isExporting', false);
			});
		});
	},

	actions: {
		fetchMoreCharges() {
			//* Check to make sure there are more pages to fetch and that we are not currently fetching content
			if (this.meta?.total_pages > this.page && !this.isLoading) {
				//* Set the isLoading state property to true
				this.set('isLoading', true);

				//* Increment the current page number
				this.incrementProperty('page');

				//* Set a constant for the current queryParams
				const queryParams = this.queryParams;

				//* Update the requested page number on the queryParams
				queryParams.page.number = this.page;

				//* Query the store for charges using our updated queryParams
				this.store.query('invoice', queryParams).then((charges) => {
					//* Update the meta property using the meta on the response
					this.set('meta', charges.get('meta'));

					//* Set a constant to the array from the response
					const chargeArray = charges.toArray();

					//* Push the charge Objects from the response array into the current model
					this.model.pushObjects(chargeArray);

					//* In the next runloop we will set the isLoading state property to false
					next(() => {
						this.set('isLoading', false);
					});
				});
			}
		},

		updateSort(sortColumn, sortDirection) {
			const sortString = `${sortDirection}${sortColumn.underscore()}`;
			this.set('sort', sortString);
		},

		export() {
			this.set('isExporting', true);
			//TODO query and set isExported, set success or error and toggle the showModal with response reset them after a couple seconds
			const queryParams = {};

			//* Set the filter properties on the queryParams to our computed filters property
			const filters = this.get('filters');

			for (let property in filters) {
				queryParams[`filter[${property}]`] = filters[property];
			}

			const params = formatObjectAsSearchParams(queryParams);

			const url = `${ENV.host}/invoices/export${
				params.trim() ? '?' + params.trim() : ''
			}`;

			return this.ajax
				.post(url)
				.then((res) => {
					this.set('isExported', true);
					if (res.message) {
						this.notifications.success(res.message, {
							canClose: true,
							clearDuration: 10000,
							autoClear: true,
						});
					}
					this.set('isExported', true);
					this.set('isExporting', false);
				})
				.catch((err) => {
					this.notifications.error(Errors.mapResponseErrors(err), {
						canClose: true,
						clearDuration: 10000,
						autoClear: true,
					});
				})
				.finally(() => {
					const to = setTimeout(() => {
						this.set('isExported', false);
					}, 5000);

					this.timeouts?.push(to);
				});
		},
	},
});
