/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

@classic
export default class CellCaseClientTableClientNameComponent extends Component {
	@action
	resendInvite() {
		this.tableActions.onInviteClient(this.get('row.content'));
		this.set('disableInvite', true);
	}
}
