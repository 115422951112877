/** @format */

import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { observer } from '@ember/object';

export default Mixin.create({
	infinity: service(),
	pubnub: service('pubnub-service'),

	chatNotificationObserver: observer('pubnub.newChatMessage', function () {
		const newChatMessage = this.get('pubnub.newChatMessage');
		const _this = this;
		if (newChatMessage) {
			_this.pushNewNotification(newChatMessage, 'chatNotifications');
		}
	}).on('init'),

	pushNewNotification(notification, type) {
		const infinityModelInstance = this.controller?.get(`model.${type}`);
		if (infinityModelInstance) {
			this.infinity.pushObjects(infinityModelInstance, [notification]);
		}
	},
});
