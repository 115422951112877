/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { next } from '@ember/runloop';
import Errors from 'case-status/constants/errors';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

@classic
export default class ModalSyncIntegrationComponent extends Component {
	allowMultipleIds = false; //* Future Feature
	minIdsAllowed = 1;
	maxIdsAllowed = 5;

	@service company;
	@service ajax;

	@tracked integrationPartner;
	@tracked idsArray = [{ value: null }];

	@tracked selectedOption = 0;

	errors = null;

	get title() {
		if (this.integrationPartner) {
			return `Sync with ${this.integrationPartner}`;
		} else {
			return `Sync with Integration`;
		}
	}

	options = [
		`All ${dynamicCaseLabel([this.company, true])}`,
		`Individual ${dynamicCaseLabel([this.company, this.allowMultipleIds])}`,
	];

	get ids() {
		return this.idsArray;
	}

	sync(data = '') {
		if (this.syncing) return;
		this.resetErrors();
		this.set('syncing', true);
		const url = `${ENV.host}/cases/import${
			data && typeof data === 'string' ? `/case/${data}` : ''
		}`;
		const params = [url];
		if (typeof data !== 'string') {
			params.push({ data });
		}

		return this.ajax
			.post(...params)
			.then((res) => {
				this.set('syncMessage', 'Success!');
				this.set('syncSuccess', true);
				return res;
			})
			.catch((err) => {
				this.set('errors', Errors.mapResponseErrors(err));
				this.set('showErrors', true);
				return err;
			})
			.finally(() => {
				next(() => {
					this.set('syncing', false);
				});
			});
	}

	resetErrors() {
		this.set('errors', null);
	}

	@action
	chooseOption(option) {
		if (this.syncing) return;
		this.resetErrors();
		this.selectedOption = option;
	}

	@action
	syncAll() {
		if (this.syncing) return;
		this.set(
			'successMessage',
			`Success! We are importing your ${dynamicCaseLabel([
				this.company,
				true,
			])} now and will send you an email when it is done.`,
		);
		this.set('syncSuccess', true);
		return this.sync();
	}

	@action
	addId() {
		if (this.syncing) return;
		const memo = [...this.idsArray];
		memo.push({ value: null });
		this.set('idsArray', memo);
	}

	@action
	removeId(index) {
		if (this.syncing) return;
		const memo = [...this.idsArray];
		memo.splice(index, 1);
		this.set('idsArray', memo);
	}

	@action
	syncIds() {
		//TODO
		if (this.syncing) return;
		const memo = [...this.idsArray];
		const filteredMemo = memo.filter((obj) => {
			return `${obj.value}`.trim();
		});
		const data = filteredMemo.map((obj) => {
			return `${obj.value}`.trim();
		});

		return this.sync(data[0]).then((res) => {
			this.set('successMessage', res.message.response_msg || `Success!`);
		});
	}

	@action
	callClose() {
		if (this.syncing) return;
		if (this.close && typeof this.close === 'function') {
			return this.close();
		}
	}
}
