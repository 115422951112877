/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Table from 'ember-light-table';
import { storageFor } from 'ember-local-storage';
import { computed } from '@ember/object';
import { dateDisplay } from 'case-status/helpers/date-display';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';

export default Component.extend({
	currentUser: service(),
	company: service(),
	preferences: storageFor('preferences'),

	filterOptions: computed(function () {
		return ['client', 'attorney', 'caseId', ``];
	}),

	columns: computed(function () {
		const currentUserFirm = this.get('currentUser.user.firm');

		return [
			{
				label: 'Opened',
				valuePath: 'case.activatedDate',
				width: '250px',
				cellClassNames: 'bold',
				sortable: false,
				format: function (value) {
					if (value) {
						return dateDisplay([value, currentUserFirm]);
					} else {
						return 'No Date';
					}
				},
			},
			{
				label: `${capitalize(dynamicCaseLabel([this.company]))} ID`,
				valuePath: 'case.readableId',
				width: '250px',
				sortable: false,
				cellClassNames: 'ellipsis',
				cellComponent: 'transaction-case-id-cell',
			},
			{
				label: 'Client',
				valuePath: 'case.clients',
				cellClassNames: 'ellipsis',
				cellComponent: 'transaction-client-cell',
				sortable: false,
			},
			{
				label: 'Accrued Fees',
				valuePath: 'accruedFees',
				width: '250px',
				align: 'right',
				sortable: false,
				format(value) {
					return `$${Number(value).toFixed(2)}`;
				},
			},
			{
				label: '',
				valuePath: 'case.id',
				cellComponent: 'transaction-view-receipt-cell',
				align: 'right',
				sortable: false,
			},
		];
	}),

	model: null,
	table: null,

	init() {
		this._super(...arguments);
		this.createTable();
	},

	createTable(...callbacks) {
		const cols = this.columns;
		const mod = this.model;
		const table = Table.create({ columns: cols, rows: mod, enableSync: true });
		const sortColumn = table.get('allColumns').findBy('valuePath', 'updatedAt');

		if (sortColumn) {
			// If case sortColumn wouldn't exist for some reason, we'd still want to minimize the bug
			// and have it just be that the table isn't sorted on load instead of the table never loads
			sortColumn.set('sorted', true);
			sortColumn.set('ascending', false);
		}

		this.set('table', table);
		this.set('columns', cols);
		this.set('model', mod);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			const scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});

		// Loop through callbacks passed and pass back an object
		// with the table, cols, mod, and opts to be viewed/used
		for (let i = 0; i < callbacks.length; i++) {
			const callback = callbacks[i];
			if (callback && typeof callback === 'function') {
				callback({ table, cols, mod });
			}
		}
	},

	actions: {
		onScrolledToBottom() {
			this.fetchMoreCharges();
		},

		handleDropdownChange(selection) {
			this.set('searchType', selection);
		},
	},
});
