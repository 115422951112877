/** @format */

import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'case-status/config/environment';

// This will disable the use of the Ember Inspector tool in the production app. To add a small layer of data protection.
if (config.environment === 'production') {
	window.NO_EMBER_DEBUG = true;
}

export default class App extends Application {
	modulePrefix = config.modulePrefix;
	podModulePrefix = config.podModulePrefix;
	Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
