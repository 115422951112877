/** @format */

import { sort, uniqBy } from '@ember/object/computed';
import { A } from '@ember/array';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { dynamicCaseLabel } from '../helpers/dynamic-case-label';

export default Component.extend({
	customBranding: service('customBranding'),
	company: service(),
	currentUser: service(),

	maxClientCount: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_clients',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_clients',
			);
		},
	),

	searchString: null,
	page: 1,
	sort: '+name',
	showNewClientForm: false,

	init() {
		this._super(...arguments);
		if (this.selectedClients) {
			this.set('clientsToAdd', this.selectedClients);
		} else {
			this.set('clientsToAdd', A());
		}

		this.set('isLoading', true);
		this.set('model', []);
		this.set('pendingClients', []);
		this.set('clientToAdd', this.store.createRecord('client'));
		this.fetchNewData();
	},

	uniqueClientsToAdd: uniqBy('sortedAddClients', 'id'),
	sortedAddClients: sort('clientsToAdd', 'lastNameSort'),
	lastNameSort: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects

	queryChanged: observer('searchString', 'sort', function () {
		debounce(this, this.fetchNewData, 500);
	}),

	fetchNewData() {
		this.set('isLoading', true);
		let queryParams = { filter: {}, page: {} };
		this.model.clear();

		queryParams.page['size'] = 20;
		queryParams.filter['name'] = this.searchString;
		queryParams.sort = this.sort;

		this.set('queryParams', queryParams);

		this.store.query('client', queryParams).then((clients) => {
			//Avoid calling set on destroyed component
			if (this.isDestroyed || this.isDestroying) {
				return;
			}
			this.model.clear();
			this.model.pushObjects(clients.toArray());
			this.set('meta', clients.get('meta'));
			this.set('page', 1);
			this.set('isLoading', false);
		});
	},

	modelObserver: observer(
		'model.[]',
		'case.clients.[]',
		'showNewClientForm',
		function () {
			if (!this.model) {
				this.set('filteredModel', []);
				return;
			}
			const selectedClientIds = this.clientsToAdd.mapBy('id');
			//If no case is passed in, then no clients to filter out
			if (!this.case) {
				this.model.forEach((client) => {
					if (selectedClientIds.includes(client.get('id'))) {
						client.set('isSelected', true);
					} else {
						client.set('isSelected', false);
					}
				});
				this.set(
					'filteredModel',
					this.model.sort((client) =>
						this.pendingClients.includes(client.get('id')),
					),
				);
			} else {
				const currentIds = this.get('case.clients').mapBy('id');

				//Remove clients already on the case

				const filteredModel = [];
				this.model.forEach((client) => {
					if (selectedClientIds.includes(client.get('id'))) {
						client.set('isSelected', true);
					} else {
						client.set('isSelected', false);
					}
					if (currentIds.indexOf(client.get('id')) === -1) {
						filteredModel.push(client);
					}
				});

				this.set(
					'filteredModel',
					this.model.sort((client) =>
						this.pendingClients.includes(client.get('id')),
					),
				);
			}
		},
	).on('init'),

	clientsToAddCount: computed('uniqueClientsToAdd.[]', function () {
		const clientsToAdd = this.uniqueClientsToAdd;
		return clientsToAdd.get('length');
	}),

	actionButtonText: computed('newCase', 'clientsToAddCount', function () {
		return this.newCase
			? `Next`
			: `Add ${this.clientsToAddCount} clients to ${dynamicCaseLabel([
					this.company,
			  ])}`;
	}),

	//* Reset properties using lifecycle methods
	didInsertElement() {
		$('html').addClass('has-modal');
		this.set('clientsToAdd', A());
	},

	willDestroyElement() {
		$('html').removeClass('has-modal');
		this.set('clientsToAdd', A());
	},

	actions: {
		addClientsButtonPressed() {
			const result = defer();
			const numClientsOnCase = this.case ? this.case?.get('clients.length') : 0;
			this.set('errors', null);
			if (this.clientsToAddCount === 0) {
				this.set('errors', ['At least one client should be selected']);
				result.reject();
			} else if (
				this.clientsToAddCount + numClientsOnCase >
				this.maxClientCount
			) {
				this.set('errors', [
					`You can only add up to ${
						this.maxClientCount
					} clients per ${dynamicCaseLabel([this.company])}.`,
				]);
				result.reject();
			} else {
				this.addClientsToCase(this.clientsToAdd)
					.then(() => {
						result.resolve();
					})
					.catch((resp) => {
						this.set('errors', Errors.mapResponseErrors(resp));
						result.reject();
					});
			}

			return result.promise;
		},

		fetchMoreClients() {
			if (this.get('meta.total_pages') > this.page && !this.isLoading) {
				this.set('isLoading', true);
				this.incrementProperty('page');
				const queryParams = this.queryParams;
				queryParams.page.number = this.page;
				this.store
					.query('client', queryParams)
					.then((clients) => {
						//Avoid calling set on destroyed component
						if (this.isDestroyed || this.isDestroying) {
							return;
						}
						this.model.pushObjects(
							clients.toArray().filter((client) => {
								const clientIds = this.model.map((c) => c.id);
								return !clientIds.includes(client.get('id'));
							}),
						);
						this.set('meta', clients.get('meta'));
						this.set('isLoading', false);
					})
					.catch(() => {
						this.isLoading;
					});
			}
		},

		handlePhoneInputError(message) {
			if (message) {
				this.set('errorsClient', [message]);
			} else {
				this.set('errorsClient', []);
			}
		},

		onClientToggled(row, isAddingClient) {
			const client = row.get('content');
			if (isAddingClient) {
				this.clientsToAdd.pushObject(client);
			} else {
				this.clientsToAdd.removeObject(client);
			}
		},

		toggleNewClientForm() {
			this.toggleProperty('showNewClientForm');
		},

		save() {
			this.set('errorsClient', null);

			return this.clientToAdd
				.save()
				.then((results) => {
					const currentPendingClients = this.pendingClients;

					this.clientsToAdd.pushObject(results);
					this.set('clientToAdd', this.store.createRecord('client'));
					this.model.unshiftObject(results);
					this.toggleProperty('showNewClientForm');
					this.send('fetchMoreClients');

					// add the pending client id to the list to push clients to top of the list
					this.set('pendingClients', [
						...currentPendingClients,
						results.get('id'),
					]);
				})
				.catch((errors) => {
					this.set('errorsClient', Errors.mapResponseErrors(errors));
				});
		},
	},
});
