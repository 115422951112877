/** @format */

import Service, { inject as service } from '@ember/service';
export default class ShortCodesService extends Service {
	@service company;
	insertShortCodes(template, theCase, senderName) {
		template = template
			.replace(/{{firm_name}}/g, theCase.get('firm.name'))
			.replace(/{{sender_name}}/g, senderName)
			.replace(/{{firm_review_link}}/g, theCase.get('firm.reviewLink'))
			.replace(/{{firm_invite_link}}/g, theCase.get('firm.inviteLink'))
			.replace(
				/{{primary_attorney_name}}/g,
				theCase.get('primaryAttorney.name'),
			)
			.replace(
				/{{client_first_name}}/g,
				theCase.get('clients.firstObject.firstName'),
			)
			.replace(
				/{{client_last_name}}/g,
				theCase.get('clients.firstObject.lastName'),
			)
			.replace(/{{incident_date}}/g, theCase.get('date'));
		let clients = theCase.get('clients');
		if (clients.length == 2) {
			let clientString = '';
			clients.forEach((client, idx, array) => {
				if (idx === array.length - 1) {
					clientString += `and ${client.name}`;
				} else {
					clientString += `${client.name} `;
				}
			});
			template = template.replace('{{client_names}}', clientString);
		} else if (clients.length > 2) {
			let clientString = '';
			clients.forEach((client, idx, array) => {
				if (idx === array.length - 1) {
					clientString += `and ${client.name}`;
				} else {
					clientString += `${client.name}, `;
				}
			});
			template = template.replace(/{{client_names}}/g, clientString);
		} else {
			template = template.replace(
				/{{client_names}}/g,
				`${clients.firstObject.name}`,
			);
		}
		return template;
	}

	insertFirmShortCodes(template, firm, senderName) {
		template = template
			.replace(/{{firm_name}}/g, firm.get('name'))
			.replace(/{{sender_name}}/g, senderName)
			.replace(/{{firm_review_link}}/g, firm.get('reviewLink'))
			.replace(/{{firm_invite_link}}/g, firm.get('inviteLink'));
		return template;
	}
}
