/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { pluralize } from 'ember-inflector';

export function dynamicAppointmentLabel(params /*, hash*/) {
	if (!Array.isArray(params)) {
		throw new EmberError(
			'dynamic-appointment-label helper requires data passed in as an array',
		);
	}

	const [caseSettings, isPlural, defaultOverride] = params;

	//* If no defaultOverride is provided, then the default is "appointment"
	const defaultAppointmentLabel = defaultOverride || 'appointment';

	//* If no caseSettings was passed then return the defaultAppointmentLabel
	if (!caseSettings) {
		if (isPlural) {
			return pluralize(defaultAppointmentLabel);
		} else {
			return defaultAppointmentLabel;
		}
	}

	//* If isPlural is falsey and there is no appointment_label_singular value set/passed return the defaultAppointmentLabel
	if (!isPlural && !caseSettings.appointment_label_singular) {
		return defaultAppointmentLabel;
	} else if (
		!isPlural &&
		(caseSettings.appointment_label_singular ||
			(caseSettings.info && caseSettings.info.appointment_label_singular))
	) {
		return (
			caseSettings.appointment_label_singular ||
			caseSettings.info.appointment_label_singular
		);
	}

	//* If isPlural is truthy and there is no appointment_label_plural value set/passed return the defaultAppointmentLabel
	if (isPlural && !caseSettings.appointment_label_plural) {
		return pluralize(defaultAppointmentLabel);
	} else if (isPlural && caseSettings.appointment_label_plural) {
		return (
			caseSettings.appointment_label_plural ||
			caseSettings.info.appointment_label_plural
		);
	}

	//* if it makes it here just return the defaultAppointmentLabel for now
	return isPlural
		? pluralize(defaultAppointmentLabel)
		: defaultAppointmentLabel;
}

export default helper(dynamicAppointmentLabel);
