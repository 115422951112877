/** @format */

import { helper } from '@ember/component/helper';
import EmberError from '@ember/error';
import { isArray } from 'case-status/constants/data-types';
import { A } from '@ember/array';

export function userDisplay(params) {
	if (params && !Array.isArray(params)) {
		throw new EmberError(
			'user-display helper requires params passed in as an array',
		);
	}

	let [users, targetProp, company] = params;

	if (users && !Array.isArray(users)) {
		throw new EmberError(
			'user-display helper requires users passed in as an array',
		);
	}

	if (!users) return [];
	if (users.length == 0) return users;

	return users.filter((user) => {
		try {
			const testString = targetProp
				? user.get(targetProp) || ''
				: user.get('emailAddress') || '';
			const emailTest = !testString.includes('@casestatus.com');

			const companyTest = company
				? user.get('firm.id') === company.get('info.id')
				: true; // default to true when company is missing for legacy compatibility

			return emailTest && companyTest;
		} catch (error) {
			//eslint-disable-line no-unused-vars
			// If the try block fails for any reason, then whatever the cause is should probably be filtered out before causing more problems.
			return false;
		}
	});
}

export default helper(userDisplay);
