/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce, next } from '@ember/runloop';

@classic
export default class ScrollIndicatorComponent extends Component {
	attributeBindings = ['data-test-id'];
	'data-test-id' = 'component';
	@tracked scrollableElement = null;
	@tracked _showIndicator = false;
	@tracked resizeListenerSetup = false;
	@tracked scrollListenerSetup = false;

	get showIndicator() {
		return Boolean(this._showIndicator);
	}

	set showIndicator(value) {
		this.set('_showIndicator', Boolean(value));
	}

	checkHeightTolerance() {
		if (!this.scrollableElement) return null;
		const element = document.querySelector(this.scrollableElement);
		if (!element) return null;
		const styles = getComputedStyle(element);
		if (!styles) return null;
		const maxHeight = Number(styles.maxHeight.replace('px', ''));
		if (!maxHeight) return null;
		const height = element.offsetHeight;
		if (!height) return null;

		if (height == maxHeight) {
			//* Only show indicator if there is potential overflow
			this.set('showIndicator', true);
		} else if (height < maxHeight && !this.resizeListenerSetup) {
			//* Setup listener if scrollableElement could resize and listener isn't already setup
			this.setupResizeListener();
		}
	}

	checkScrollHeight() {
		//TODO check scrollHeight to hide indicator when bottom of scrollableElement is reached, but is displayed again when they scroll away from said bottom or if more elements added push the bottom out of view
		if (!this.scrollableElement) return null;
		const element = document.querySelector(this.scrollableElement);
		if (!element) return null;
		const styles = getComputedStyle(element);
		if (!styles) return null;
		const maxHeight = Number(styles.maxHeight.replace('px', ''));
		if (!maxHeight) return null;
		// const scrollHeight = element.scrollTop;
	}

	setupResizeListener() {
		if (this.resizeListenerSetup) return null;
		const _this = this;
		if (!this.scrollableElement) return null;
		const element = document.querySelector(this.scrollableElement);
		if (!element) return null;

		element.addEventListener('resize', function () {
			//* debounce the check call to prioritize performance
			debounce(_this, 'checkHeightTolerance', 500, false);
		});

		//* Set state property for this listener
		this.set('resizeListenerSetup', true);
	}

	setupScrollListener() {
		const _this = this;
		if (!this.scrollableElement) return null;
		const element = document.querySelector(this.scrollableElement);
		if (!element) return null;

		element.addEventListener('scroll', function () {
			//* debounce the check call to prioritize performance
			debounce(_this, 'checkScrollHeight', 500, false);
		});

		//* Set state property for this listener
		this.set('scrollListenerSetup', true);
	}

	//TODO When to revoke listener?
	revokeListener(eventListenerType = '') {
		if (!eventListenerType) return null;
		if (!this.scrollableElement) return null;
		const element = document.querySelector(this.scrollableElement);
		if (!element) return null;
		element.removeEventListener(eventListenerType);

		//* Reset state property for listener type
		this.set(`${eventListenerType}ListenerSetup`, false);
	}

	init(...args) {
		super.init(...args);
		this.setupResizeListener();
		this.setupScrollListener();
		this.checkHeightTolerance();
	}
}
