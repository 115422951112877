import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title='NPS'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n/>", {"contents":"<FilterDropdown2\n\t@title='NPS'\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n/>","moduleName":"case-status/components/cases-filters/nps-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/nps-filters.hbs"}});
import BaseCasesFilterTemplate, { A, action } from './base-filter-template';

export default class CasesFiltersNpsFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		const maxNps = 10;

		this.options = A([]);

		for (let i = 0; i <= maxNps; i++) {
			this.options.pushObject({
				value: String(i),
				param: i,
				index: i,
				checked: this.args.selections?.includes(i),
			});
		}
	}

	@action
	handleOnChange(selections) {
		this.selections = A(selections);
		super.handleOnChange(selections);

		this.onChange({
			bulkNps: selections.mapBy('param'),
		});
	}
}
