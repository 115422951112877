/**
 * Created by kevin on 6/15/17.
 *
 * @format
 */

import { underscore } from '@ember/string';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { isPresent } from '@ember/utils';
import { pluralize } from 'ember-inflector';
import ENV from 'case-status/config/environment';
import AjaxServiceSupport from 'ember-ajax/mixins/ajax-support';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import AdapterMixin from 'ember-resource-metadata/adapter-mixin';

export default JSONAPIAdapter.extend(
	DataAdapterMixin,
	AjaxServiceSupport,
	AdapterMixin,
	{
		host: ENV.host,

		authorize(xhr) {
			let { access_token } = this.get('session.data.authenticated');
			if (isPresent(access_token)) {
				xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
			}
		},

		pathForType: function (type) {
			const plural = pluralize(type);
			return underscore(plural);
		},
	},
);
