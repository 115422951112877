/** @format */

import { belongsTo } from '@ember-data/model';
import User from 'case-status/models/user';
import { buildValidations, validator } from 'ember-cp-validations';
import ValidationErrors from '../constants/errors';
import { computed } from '@ember/object';

const Validations = buildValidations({
	emailAddress: [
		validator('presence', {
			presence: true,
			message: ValidationErrors.presenceError,
			description: 'Email Address',
		}),
		validator('format', {
			type: 'email',
			message: 'Email Address is not valid',
		}),
	],
	contactEmailAddress: [
		validator('format', {
			type: 'email',
			message: 'Contact Email Address is not valid',
			disabled: computed.not('model.contactEmailAddress'),
		}),
	],
	cases: validator('has-many'),
});

export default User.extend(Validations, {
	hasCompletedProfile: computed(
		'profilePicture',
		'profilePicture.content.url',
		'bio',
		function () {
			return this.get('profilePicture.content.url') && this.bio;
		},
	),

	user: belongsTo('user'),
});
