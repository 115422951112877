/**
 * Created by Austin on 5/24/18.
 *
 * @format
 */

import { inject as service } from '@ember/service';

import ApplicationAdapter from './application';
import { underscore } from '@ember/string';

export default ApplicationAdapter.extend({
	currentUser: service('current-user'),

	pathForType(type) {
		return 'firm_insights/' + underscore(type) + 's';
	},
});
