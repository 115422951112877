/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	type: attr('string'),
	createdAt: attr('date'),

	//Relationships
	case: belongsTo('case'),
	client: belongsTo('client'),
	firm: belongsTo('firm'),
	sender: belongsTo('user'),
});
