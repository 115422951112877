/**
 * Created by Austin on 5/24/18.
 *
 * @format
 */

import { inject as service } from '@ember/service';

import ApplicationAdapter from './application';
import { underscore } from '@ember/string';

export default ApplicationAdapter.extend({
	currentUser: service('current-user'),
	session: service(),

	pathForType(type) {
		if (this.get('session.data.authenticated.user_type') === 'client') {
			return underscore(type) + 's';
		}

		if (this.get('currentUser.user.organization')) {
			const orgId = this.get('currentUser.user.organization.id');
			return (
				'organizations/' + orgId + '/relationships/' + underscore(type) + 's'
			);
		} else {
			const firmId = this.get('currentUser.user.firm.id');
			return 'firms/' + firmId + '/relationships/' + underscore(type) + 's';
		}
	},
});
