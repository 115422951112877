/** @format */

import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { observer } from '@ember/object';
import $ from 'jquery';
import Errors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';

export default Component.extend({
	addingItem: false,

	showNewForm: reads('addingItem'),
	notifications: service(),
	global: service(),
	shouldRefreshList: false,

	showNewFormObserver: observer('showNewForm', function () {
		this.set(
			'global.casePauseTimerConditions.isEditingChecklist',
			this.showNewForm,
		);
	}),

	init() {
		this._super(...arguments);

		//* We want the showNewForm to be true when there are no items, but we do not want to have this property recomputed every time there is another item added .
		if (!this.checklistItems?.length) {
			this.set('addingItem', true);
		}
	},

	get isEditable() {
		if (this.allowEdit === true || this.allowEdit === false) {
			return this.allowEdit;
		}

		return true;
	},

	actions: {
		clickAddItem() {
			this.set('addingItem', true);
		},

		cancelAddItem() {
			this.set('addingItem', false);
		},

		updateItemPositions() {
			const checklistItems = this.checklistItems;

			checklistItems.forEach((item, i) => {
				item.set('position', i);
				item.save().catch((err) => {
					this.notifications.clearAll();
					this.notifications.error(Errors.mapResponseErrors(err), {
						canClose: true,
						autoClear: true,
						clearDuration: 2000,
					});
				});
			});

			//? there is a bug in the plugin where the opacity isn't resetting after dragEnd
			const targets = $('.draggable-object');
			targets.each((i, el) => {
				$(el).attr('style', null);
			});
		},

		refreshList() {
			this.set('shouldRefreshList', true);
			//* Must offset or the original list does not destroy. 5ms also does not make the section flash either. 👍🏻
			setTimeout(() => {
				this.set('shouldRefreshList', false);
			}, 5);
		},
	},
});
