/** @format */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalUserCreationComponent extends Component {
	@tracked currentStep = 1;
	maxSteps = 3;

	@tracked modelMemo;
	@tracked extras = {};

	get model() {
		return this.modelMemo ? this.modelMemo : this.args.model;
	}

	set model(value) {
		this.modelMemo = value;
	}

	get currentComponentName() {
		return this.args[`step${this.currentStep}ComponentName`];
	}

	@action
	submit(model) {
		if (this.currentStep != this.args.maxSteps) {
			return this.nextStep(model);
		} else {
			return this.args.saveAction(model);
		}
	}

	@action
	nextStep(model) {
		if (model) {
			this.model = model;
		}

		if (this.currentStep < this.maxSteps) {
			this.currentStep = this.currentStep + 1;
		}
	}

	@action
	previousStep() {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}
}
