/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	accruedFees: attr(),
	maxFees: attr(),
	serviceCharges: attr(),
	unpaidFees: attr(),

	// Relationships
	case: belongsTo('case'),
});
