/** @format */

import { observer } from '@ember/object';
import Component from '@ember/component';
import Cropper from 'cropperjs';

export default Component.extend({
	//cropper configs
	previewClass: '.cropper-preview',
	cropperContainer: '.cropper-container > img',
	aspectRatio: 1,
	crop: null,
	viewMode: 2,
	dragMode: 'crop',
	responsive: true,
	center: true,
	checkCrossOrigin: true,
	background: true,
	modal: true,
	guides: true,
	highlight: true,
	autoCrop: true,
	autoCropArea: 0.8,
	dragDrop: true,
	movable: true,
	resizable: true,
	zoomable: true,
	zoomOnWheel: true,
	zoomOnTouch: true,
	cropBoxMovable: true,
	cropBoxResizable: true,
	toggleDragModeOnDblclick: true,
	rotateable: true,
	minContainerWidth: 200,
	minContainerHeight: 100,
	minCropBoxWidth: 0,
	minCropBoxHeight: 0,
	build: null,
	built: null,
	dragStart: null,
	dragMove: null,
	dragEnd: null,
	zoomin: null,
	zoomout: null,
	ready: null,
	data: null,
	//initialize cropper on did insert element
	didInsertElement() {
		this._super(...arguments);
		let properties = this.getProperties(
			'cropperContainer',
			'aspectRatio',
			'crop',
			'previewClass',
			'viewMode',
			'dragMode',
			'responsive',
			'center',
			'checkCrossOrigin',
			'toggleDragModeOnDblclick',
			'background',
			'modal',
			'guides',
			'highlight',
			'autoCrop',
			'autoCropArea',
			'dragDrop',
			'movable',
			'resizable',
			'zoomable',
			'zoomOnWheel',
			'zoomOnTouch',
			'cropBoxMovable',
			'cropBoxResizable',
			'rotateable',
			'minContainerWidth',
			'minContainerHeight',
			'minCropBoxWidth',
			'minCropBoxHeight',
			'build',
			'built',
			'dragStart',
			'dragMove',
			'dragEnd',
			'zoomin',
			'zoomout',
			'ready',
			'data',
		);
		properties['preview'] = properties['previewClass'];
		delete properties['previewClass'];
		let image = document.querySelector(properties['cropperContainer']);
		this.set('cropper', new Cropper(image, properties));
	},
	willDestroyElement() {
		this._super(...arguments);
		let cropper = this.cropper;
		if (cropper['data']) {
			cropper.destroy();
		}
	},

	shouldCropObserver: observer('shouldUpdate', function () {
		if (this.shouldUpdate) return this.send('getCroppedAvatar');
	}),

	actions: {
		getCroppedAvatar() {
			let cropper = this.cropper;
			const croppedImage = cropper.getCroppedCanvas();

			this.set('croppedAvatar', croppedImage);
			const _this = this;
			croppedImage.toBlob(function (blob) {
				_this.get('uploadImage')(blob);
			});
		},
	},
});
