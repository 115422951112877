/** @format */

import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
	invoiceId: attr('string'),
	caseId: attr('number'),
	internalId: attr('string'),
	amount: attr('number'),
	clientNames: attr(),
	date: attr('string'),
	message: attr('string'),
	primaryAttorneyName: attr('string'),
	option: attr('string'),
	group: attr('string'),

	// Computed
	readableClientId: computed('clientNames', 'group', function () {
		const clientNames = this.clientNames;
		const group = this.group;

		if (group) return [group];

		return clientNames;
	}),
});
