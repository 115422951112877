import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import Errors from 'case-status/constants/errors';

export default class ThirdPartyRoute extends Route {
	@service router;
	@service permissions;

	async model(...params) {
		const transition = params[1];
		const transitionURL =
			transition.intent.url || transition.intent.contexts[0];
		const integrationId = transitionURL.slice(
			transitionURL.lastIndexOf('/') + 1,
		);
		const theCase = await this.store
			.queryRecord('case', { integrationId }, { reload: true })
			.catch((response) => {
				throw new Error(Errors.mapResponseErrors(response));
			});
		this.case_id = theCase.id;
		const files = await this.store.query(
			'file',
			{ id: theCase.id },
			{ reload: true },
		);
		const notifications = await this.store.query('notification', {
			filter: { case_id: theCase.id },
			page: { size: 50 },
		});
		const messageTemplates = this.store.findAll('message-template');
		theCase.reload();
		return hash({
			theCase,
			files,
			notifications,
			messageTemplates,
		});
	}

	afterModel(model) {
		this.router.transitionTo('app.firm.case.messages', model.theCase.id);
	}
}
