import Component from '@ember/component';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { reject } from 'rsvp';
import Errors from 'case-status/constants/errors';

export default Component.extend({
	notifications: service(),
	ajax: service(),
	currentUser: service(),
	permissions: service(),

	get isClient() {
		return this.currentUser.get('user.constructor.modelName') == 'client';
	},

	actions: {
		showNewAppointmentForm() {
			this.set('showNewForm', true);
			this.set('isTreatment', false);
		},
		showNewTreatmentForm() {
			this.set('showNewForm', true);
			this.set('isTreatment', true);
		},
		exportTreatmentLogs() {
			if (!this.model.theCase.id) return reject();
			return this.ajax
				.post(`${ENV.host}/treatments/${this.model.theCase.id}/export`)
				.then((res) => {
					this.notifications.success(res.message, {
						canClose: true,
						autoClear: true,
						clearDuration: 5000,
					});
				})
				.catch((err) => {
					this.notifications.success(Errors.mapResponseErrors(err), {
						canClose: true,
						autoClear: true,
						clearDuration: 5000,
					});
				});
		},
	},
});
