/** @format */

import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
	customBranding: service('custom-branding'),

	classNames: 'client-actions-modal',

	humanizedClient: computed('numClients', function () {
		const numClients = this.numClients;

		return numClients === 1 ? 'client' : 'clients';
	}),

	sendOrResend: computed('clients', function () {
		const hasUninvitedClient = this.clients.some((client) => !client.invite);

		return hasUninvitedClient ? 'Send' : 'Resend';
	}),
});
