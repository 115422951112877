/** @format */

import { defer } from 'rsvp';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
	customBranding: service('custom-branding'),

	didRender() {
		if (this.focusOnRender) {
			this.$('textarea').focus();
		}
	},

	characterCounter: computed('value', function () {
		let value = this.get('value.length');
		if (isNaN(value)) {
			value = 0;
		}
		return this.maxLength - value;
	}),

	actions: {
		buttonAction() {
			this.primaryAction();
		},

		sendAction() {
			const result = defer();
			this.primaryAction(result);
			return result.promise;
		},
	},
});
