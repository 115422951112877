/** @format */

import Component from '@ember/component';
import { link, templates } from '../constants/pell-extensions';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
	shortcode: service(),
	shortcodeOptions: computed(
		'shortcode.{options,conditionalOptions}',
		'specificShortcodeOptions',
		function () {
			const options = this?.shortcode?.options || [];
			const specifics = this?.specificShortcodeOptions || [];

			const filterFunc = (o) => {
				return !this.shortcode.conditionalOptions.includes(o);
			};

			if (specifics && specifics.length) {
				//* Using Sets to create filter out duplicates
				return new Set([...options, ...specifics].filter(filterFunc));
			} else return new Set([...options].filter(filterFunc));
		},
	),

	shortcodeConditionalOptions: computed(
		'shortcode.conditionalOptions',
		function () {
			const conditionalOptions = this.shortcode.conditionalOptions;
			return new Set([...conditionalOptions]);
		},
	),

	init() {
		this._super(...arguments);

		const pellOptions = {
			actions: ['bold', 'italic', link],
		};

		if (this.messageTemplates) {
			pellOptions.actions.push(templates);
		}

		this.set('pellOptions', pellOptions);
	},

	didRender() {
		if (this.focusOnRender) {
			this.$('.pell-content').focus();
		}
	},

	actions: {
		addShortcode(shortcode) {
			const shortcodeContent = `{{${shortcode}}}`;
			const val = this.value || '';

			//TODO: Need to find the cursor position to insert in place somehow

			// append to "end" of value
			if (`${val}`.slice(val.length - 6, val.length) === '</div>') {
				this.set(
					'value',
					`${val}`.slice(0, val.length - 6) + shortcodeContent + '</div>',
				);
			} else {
				this.set('value', `${val}` + shortcodeContent);
			}
		},
		selectMessageTemplate(template) {
			if (
				this.selectMessageTemplate &&
				typeof this.selectMessageTemplate === 'function'
			) {
				this.selectMessageTemplate(template);
			}
			this.set('value', template.content);
			this.set('fileId', template.get('fileAttachment.id'));
			this.set('messageTemplateType', template.get('templateType'));
			this.set('filename', template.get('fileAttachment.name'));
			document
				.getElementById('modal-add-message-template')
				.classList.add('hidden');
			this.set('activeMessageTemplate', template);
		},
	},
});
