/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
	appSec: service(),
	beforeModel() {
		this._super(...arguments);
		this.replaceWith('app.firm.cases');
	},
});
