/** @format */

import Service from '@ember/service';
import { next } from '@ember/runloop';

export default class GlobalService extends Service {
	requestUpdateInformation() {
		if (this.mobileCaseDisplay) {
			this.mobileCaseDisplay.set('currentTab', 'messages');

			next(() => {
				if (this.mobileClientMessagingTab) {
					this.mobileClientMessagingTab.requestUpdateInformationMessage();
				}
			});

			return true;
		}

		return false;
	}

	//* Used in the case controller for model refresh checks
	casePauseTimerConditions = {
		isWritingMessage: false,
		isEditingChecklist: false,
		isEditingAutomation: false,
		isEditingCaseInfo: false,
	};
}
