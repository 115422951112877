/** @format */

import { helper } from '@ember/component/helper';

export function formatObjectAsSearchParams(nestedObj) {
	/**
	 *  Accepts an object as {page: {size: 20, number: 3}, filter: {search: ""}}
	 *  and returns a URLSearchParams string in the format expected by the case-status-api
	 *  ie: filter[search]=&page[size]=20&page[number]=3
	 */
	const qsParams = new URLSearchParams();
	Object.keys(nestedObj).forEach((key) => {
		if (nestedObj[key] && typeof nestedObj[key] === 'object') {
			Object.keys(nestedObj[key]).forEach((subKey) => {
				// CP-1650: In mobile view, filter[search] is undefined which sets the param to 'undefined' and as a result
				// the api filters on that exact text, causing no messages to be returned -- set it to the empty string in this case
				let computedValue = nestedObj[key][subKey];
				if (
					key === 'filter' &&
					subKey === 'search' &&
					computedValue === undefined
				) {
					computedValue = '';
				}
				qsParams.append(`${key}[${subKey}]`, computedValue);
			});
		}
	});
	return qsParams.toString();
}

export default helper(formatObjectAsSearchParams);
