/** @format */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import Errors from '../../../../constants/errors';
import { defer } from 'rsvp';

export default Controller.extend({
	//* Services
	currentUser: service(),
	notifications: service(),
	permissions: service(),
	//*

	//* Members List Properties
	isLoading: computed('pendingMembers', function () {
		if (this.members === null) {
			this.fetchUsers();
		}

		return this.pendingMembers;
	}),
	pendingMembers: true,
	members: null,
	sortedMembers: sort('members', 'sortLastName'),
	sortLastName: null,
	//*

	//* Invite New User Properties
	newUser: null, //* Set to an Object literal upon init
	showInviteModal: false,
	inviteSentErrors: null,
	inviteSentSuccess: false,
	//*

	//* Delete User Properties
	isShowingRemoveUserModal: false,
	//*

	//* Life Cycle Methods
	init() {
		this._super(...arguments);
		this.set('newUser', {});
		this.set('sortLastName', ['lastName']);
	},
	//*

	//* Custom Methods
	fetchUsers() {
		const result = defer();
		this.fetchMembers().then(() => {
			if (!this.pendingMembers) {
				result.resolve();
			}
		});
		return result.promise;
	},

	fetchMembers() {
		const result = defer();
		this.set('pendingMembers', true);
		this.store
			.findAll('member')
			.then((members) => {
				this.set('members', members);
			})
			.then(() => {
				this.set('pendingMembers', false);
				result.resolve();
			});
		return result.promise;
	},

	calculateUserCount(count) {
		return `${Number(count) - 3}`;
	},
	//*

	//* Actions
	actions: {
		inviteNewUser(user, result) {
			const notifOptions = {
				autoClear: true,
				clearDuration: 10000,
			};
			this.send('sendInvite', user, result);
			result.promise.then(() => {
				this.set('showInviteModal', false);
				if (this.inviteSentSuccess && this.inviteSentSuccessMessage) {
					this.notifications.success(
						this.inviteSentSuccessMessage,
						notifOptions,
					);
				}
				this.fetchUsers();
			});
		},

		removeUser(user) {
			this.set('errors', null);
			const result = defer();
			this.send('deleteUser', user, result);
			return result.promise
				.then(() => {
					this.set('isShowingRemoveUserModal', false);
					this.notifications.success(
						`Removed ${user.get('name')} successfully!`,
						{
							autoClear: true,
							clearDuration: 7000,
						},
					);
					this.fetchUsers();
				})
				.catch((err) => {
					this.set('errors', Errors.mapResponseErrors(err));
				});
		},

		selectRole(role) {
			this.set('newUser.role', role);
		},

		sendNewInvite() {
			this.resetNewUser();
			this.set('inviteSentSuccess', false);
		},

		toggleInviteModal() {
			this.toggleProperty('showInviteModal');
		},

		toggleShowRemoveUserModal(user) {
			this.set('userToRemove', user);
			this.set('errors', null);
			this.toggleProperty('isShowingRemoveUserModal');
		},
	},
	//*
});
