/** @format */

import ApplicationAdapter from './application';
import ENV from 'case-status/config/environment';

export default ApplicationAdapter.extend({
	host: ENV.host,
	urlForQuery(query) {
		if (query.chat_id) {
			return `${this.host}/chats/${query.chat_id}/form_responses`;
		}
	},
});
