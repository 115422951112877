/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import NotifOptions from 'case-status/constants/notif-options';
import { dynamicAppointmentLabel } from 'case-status/helpers/dynamic-appointment-label';

@classic
export default class MobileCaseDisplayComponent extends Component {
	@tracked currentTab = 'status'; //default to "status"
	@service global;
	@service notifications;
	@service permissions;

	//* Options to be passed to to the MobileCasesNavBar component
	get caseNavBarOptions() {
		return {
			activeTab: this.currentTab,
			availableTabs: this.availableTabs,
			actions: {
				navigate: this.selectTab,
			},
		};
	}

	get availableTabs() {
		//* Using OOP for the Tabs helps us minimize all the places needed to be touched if ever a tab permission, name, component, icon, etc ever needs updating.

		/* Returned OOP Tab Structure
    
      "key" { String used to set the currentTab and matched for the "active" class on the tab display
      allow: Boolean used to determine if the tab should be displayed or transitioned to,
      icon: String for the FontAwesome v4 icon name to be used for the display,
      name: String used for the tab's display name shown to the user,
      component: String for the ember/glimmer component used to display/transition to the tab
      }

    */

		const availableTabs = {
			status: {
				allow: true,
				icon: 'eraser',
				name: 'Status',
				component: 'mobile-status-display',
			},
			messages: {
				allow: true,
				icon: 'envelope',
				name: 'Messages',
				component: 'mobile-client-messaging-tab',
				prefix: 'far', // fontawesome regular style (opposed to solid)
			},
			'your-team': {
				allow: false, //TODO: Enable when the component is made
				icon: 'user',
				name: 'Your Team',
				component: null,
			},
			appointments: {
				allow: this.permissions.case.appointments_tab_visible,
				icon: 'calendar-days',
				name: dynamicAppointmentLabel([this.permissions.case, true]),
				component: 'mobile-client-appointments-tab',
				prefix: 'far', // fontawesome regular style (opposed to solid)
			},
			process: {
				allow: !this.model.get('caseType.pending'),
				icon: 'bars',
				name: 'Process',
				component: 'mobile-client-process-tab',
			},
		};

		if (this.isFirm && this.permissions.firm.checklist) {
			availableTabs['checklist'] = {
				allow: true,
				icon: 'square-check',
				name: ' Checklist',
				component: 'mobile-firm-checklist-tab',
				prefix: 'far', // fontawesome regular style (opposed to solid)
			};
		}

		return availableTabs;
	}

	didRender() {
		//* Set this component in the global service
		this.set('global.mobileCaseDisplay', this);
		super.didRender(...arguments);
	}

	willDestroy() {
		//* Remove this component from the global service
		this.set('global.mobileCaseDisplay', null);
		super.willDestroy(...arguments);
	}

	@action
	selectTab(tabName) {
		//* Extra catch to prevent inaccessible tabs from being selected
		if (!this.availableTabs[tabName].allow) {
			//* Show an Error Notif so the user doesn't think the button isn't doing anything or that the app is broken/frozen
			this.notifications.error(
				'The selected tab is not available.',
				NotifOptions.error,
			);

			//* return to prevent tab from being updated
			return;
		}

		//* Set the currentTab to the targetTabName
		this.currentTab = tabName;
	}
}
