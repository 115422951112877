/** @format */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';

export default class AppFirmImportCaseController extends Controller {
	queryParams = ['id'];
	@tracked id = null;
	@tracked cs_case_id = null;

	@service company;
	@service ajax;
	@service currentUser;

	@tracked _syncCountdown = 10;
	_syncTimer = null;
	@tracked syncing = false;
	@tracked syncSuccess = null;
	@tracked syncError = null;

	get syncCountdown() {
		return this._syncCountdown;
	}

	set syncCountdown(value) {
		this._syncCountdown = value;
	}

	_syncSuccessMsg = null;

	get syncSuccess() {
		return this._syncSuccessMsg;
	}

	set syncSuccess(value) {
		this._syncSuccessMsg = value;
	}

	_syncErrorMsg = null;

	get syncError() {
		return this._syncErrorMsg;
	}

	set syncError(value) {
		this._syncErrorMsg = value;
	}

	init() {
		super.init(...arguments);
		const _this = this;
		setTimeout(() => {
			_this.startSyncTimer();
		}, 2000);
	}

	startSyncTimer() {
		if (!this.id) return;
		const _this = this;
		_this._syncCountdown = 10;
		_this._syncTimer = setInterval(() => {
			_this.syncCountdown = _this.syncCountdown - 1;
			if (_this.syncCountdown <= 0) {
				_this._sync();
			}
		}, 1000);
	}

	_sync() {
		clearInterval(this._syncTimer);
		this.syncing = true;
		const url = `${ENV.host}/cases/import/case/${this.id}`;

		return this.ajax
			.post(url)
			.then((res) => {
				if (res.message.success_message) {
					this.syncSuccess = res.message.success_message;
					this.cs_case_id = res.message.cs_case_id || null;
				} else {
					this.syncError = res.message.error_message;
				}
			})
			.catch((err) => {
				this.syncError = Errors.mapResponseErrors(err);
			})
			.finally(() => {
				this.syncing = false;
			});
	}

	@action
	sync() {
		this._sync();
	}

	@action
	logout() {
		return this.currentUser.logout();
	}

	@action
	goToCase() {
		if (this.cs_case_id) {
			return this.transitionToRoute('app.firm.case', this.cs_case_id);
		}
	}

	@action
	cancel() {
		clearInterval(this._syncTimer);
	}
}
