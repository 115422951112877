/** @format */

import Controller from '@ember/controller';
import RSVP, { Promise } from 'rsvp';
import Validations from '../constants/validations';
import { buildValidations } from 'ember-cp-validations';
import { observer } from '@ember/object';
import moment from 'moment';

export default Controller.extend(buildValidations(Validations.Password), {
	queryParams: [
		{
			expiresAt: 'expires_at',
		},
	],
	expiresAt: null,
	confirmPassword: null,
	password: null,

	checkTokenExpiration: observer('expiresAt', function () {
		const expiresAt = this.expiresAt;
		const now = moment().utc(new Date());
		const nowUnix = moment().unix(now);

		if (nowUnix >= expiresAt) {
			this.transitionToRoute('forgot-password', {
				queryParams: { tokenExpired: true },
			});
		}
	}),

	actions: {
		saveButtonPressed() {
			this.set('errors', null);

			const result = RSVP.defer();
			const errors = this.get('validations.messages');
			this.set('showError', true);
			if (errors.length > 0) {
				this.set('errors', errors);
				return new Promise(function (resolve, reject) {
					reject();
				});
			} else {
				const password = this.password;
				const token = this.get('model.token');
				this.send('resetPassword', token, password, result);
			}

			return result.promise;
		},
	},
});
