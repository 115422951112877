/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { sort, alias } from '@ember/object/computed';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { attorneyDisplay } from '../helpers/attorney-display';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import EmberObject from '@ember/object';

function clone(objToClone = {}) {
	//* This is a helper function used to cover the shortcomings of ember-changeset
	//* since a changeset still reflects changes to the original data model used,
	//* and you can't have multiple changesets of the same original data model

	//* We need an isolated cloned version of the userType's data but don't want any
	//* set properties, such as isSelected, to persist in the data store since that
	//* is reflected across the entire app, but is only relevant while a list is being
	//* displayed, this makes this a ver niche need for a function such as this,
	//* especially since the attributes specifically being cloned are very specific to
	//* the user data model and its children data models.
	if (!objToClone || typeof objToClone != 'object' || Array.isArray(objToClone))
		throw new Error('cloneObj must be passed an object');

	const cloner = {};
	cloner.id = objToClone.id;
	cloner.lastName = objToClone.lastName;
	cloner.firstName = objToClone.firstName;
	cloner.name = objToClone.name;
	cloner.emailAddress = objToClone.emailAddress;

	return EmberObject.create(Object.assign({}, cloner));
}

@classic
export default class ModalReplaceAttorneysComponent extends Component {
	//* Static Settings
	lastNameSort = ['lastName'];

	//* Services

	@service company;
	@service currentUser;

	//* Aliases
	@alias('company.info') firm;

	//* Tracked Properties

	@tracked searchString1;
	@tracked searchString2;
	@tracked attorneysToRemove = A([]);
	@tracked isLoading = true;
	@tracked model = A([]);

	//* Sorted Computed Properties

	@sort('attorneysToRemove', 'lastNameSort') sortedRemoveAttorneys;
	@sort('filteredRemoveAttorneys', 'lastNameSort') sortedToRemoveAttorneys;
	@sort('filteredAddAttorneys', 'lastNameSort') sortedToAddAttorneys;

	//* Computed Properties

	get searchText1Valid() {
		const searchText = this.searchString1;
		return (
			searchText &&
			typeof searchText == 'string' &&
			searchText !== '' &&
			searchText.trim() !== ''
		);
	}

	get searchText2Valid() {
		const searchText = this.searchString2;
		return (
			searchText &&
			typeof searchText == 'string' &&
			searchText !== '' &&
			searchText.trim() !== ''
		);
	}

	get filteredRemoveAttorneys() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check

		const attorneys = this.filteredModel;
		const selectedAttorneyId = this.attorneyIdToRemove;

		//* Skip the rest of any computations and just return an empty array since the mode is empty
		if (!attorneys) {
			return A([]);
		}

		const searchText = this.searchString1;
		const searchTextValid = this.searchText1Valid;

		return this.filterSelectedAttorneys({
			attorneys,
			searchText,
			searchTextValid,
			selectedAttorneyId,
		});
	}

	get filteredAddAttorneys() {
		//* This is a computed property that, to trigger an update and/or rerender,
		//* requires that the dependant tracked properties be defined directly in
		//* this getter function as it does not look into any helper functions used
		//* to check if any tracked properties are used within them, it is a shallow check

		const attorneys = this.filteredModel;
		const selectedAttorneyId = this.attorneyIdToAdd;

		//* Skip the rest of any computations and just return an empty array since the mode is empty
		if (!attorneys) {
			return A([]);
		}

		const searchText = this.searchString2;
		const searchTextValid = this.searchText2Valid;

		return this.filterSelectedAttorneys({
			attorneys,
			searchText,
			searchTextValid,
			selectedAttorneyId,
		});
	}

	get filteredModel() {
		if (!this.model) return A([]);
		return this.model.filter((attorney) => {
			return !attorney.get('emailAddress').includes('@casestatus.com');
		});
	}

	//* Using named params
	filterSelectedAttorneys({
		attorneys,
		searchText,
		searchTextValid,
		selectedAttorneyId,
	}) {
		if (searchTextValid) {
			return attorneys
				.map((attorneyRaw) => {
					const attorney = clone(attorneyRaw);
					if (selectedAttorneyId == attorney.get('id')) {
						attorney.set('isSelected', true);
					} else if (selectedAttorneyId) {
						attorney.set('isSelected', false);
					} else {
						attorney.set('isSelected', false);
					}
					return attorney;
				})
				.filter((attorney) => {
					if (selectedAttorneyId == attorney.get('id')) {
						return true;
					} else if (selectedAttorneyId) {
						return false;
					} else {
						return true;
					}
				})
				.filter((attorney) => {
					return attorney
						.get('name')
						.toLowerCase()
						.includes(searchText.toLowerCase());
				});
		} else {
			return attorneys
				.map((attorneyRaw) => {
					const attorney = clone(attorneyRaw);
					if (selectedAttorneyId == attorney.get('id')) {
						attorney.set('isSelected', true);
					} else if (selectedAttorneyId) {
						attorney.set('isSelected', false);
					} else {
						attorney.set('isSelected', false);
					}
					return attorney;
				})
				.filter((attorney) => {
					if (selectedAttorneyId == attorney.get('id')) {
						return true;
					} else if (selectedAttorneyId) {
						return false;
					} else {
						return true;
					}
				});
		}
	}

	//* Lifecycle Hooks

	init() {
		super.init(...arguments);
		this.fetchNewData();
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		$('html').addClass('has-modal');
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
		$('html').removeClass('has-modal');
		this.set('attorneysToRemove', A([]));
		this?.model?.forEach((attorney) => {
			attorney?.set('isSelected', false);
		});
	}

	//* Methods

	fetchNewData() {
		this.set('isLoading', true);

		this.store
			.findAll('attorney')
			.then((attorneys) => {
				//Avoid calling set on destroyed component
				if (this.isDestroyed || this.isDestroying) {
					return;
				}
				this.model.clear();
				this.model.pushObjects(attorneys.toArray());

				this.set('isLoading', false);
			})
			.catch((err) => {
				this.set('errors', Errors.mapResponseErrors(err));
				next(() => {
					this.set('isLoading', false);
				});
			});
	}

	get titleText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const type = attorneyDisplay([this.company.info]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Replace ${type} on (${caseCountText}) ${cases}`;
	}

	get actionableBtnText() {
		const caseCount = this?.selectedCasesCount || 0;
		const caseCountText = new String(caseCount);
		const type = attorneyDisplay([this.company.info]).toLowerCase();
		const cases = dynamicCaseLabel([this.company, caseCount != 1]);

		return `Replace ${type} on (${caseCountText}) ${cases}`;
	}

	updateAttorneysList() {
		//* This is to trigger the getters to rerender
		const model = A([...this.model]);
		this.set('model', A([]));
		this.set('model', model);
	}

	//* Actions
	@action
	removeAttorneysButtonPressed() {
		const result = defer();
		this.set('errors', null);

		if (!this.attorneyIdToRemove || !this.attorneyIdToAdd) {
			this.set('errors', [
				`At least one ${attorneyDisplay([
					this.currentUser.get('user.firm'),
				]).toLowerCase()} should be selected to remove and At least one ${attorneyDisplay(
					[this.currentUser.get('user.firm')],
				).toLowerCase()} should be selected to add in its place.`,
			]);
			result.reject();
		} else {
			this.replaceAttorneyOnCase(this.attorneyIdToRemove, this.attorneyIdToAdd)
				.then((res) => {
					this.set('attorneyIdToRemove', null);
					this.set('attorneyIdToAdd', null);
					result.resolve(res);
				})
				.catch((resp) => {
					this.set('errors', Errors.mapResponseErrors(resp));
					result.reject(resp);
				});
		}

		return result.promise;
	}

	@action
	onRemoveAttorneyToggled(row, isRemovingAttorney) {
		const attorney = row.get('content');
		if (isRemovingAttorney) {
			this.set('attorneyIdToRemove', attorney.get('id'));
		} else {
			this.set('attorneyIdToRemove', null);
		}

		this.updateAttorneysList();
	}

	@action
	onAddAttorneyToggled(row, isRemovingAttorney) {
		const attorney = row.get('content');
		if (isRemovingAttorney) {
			this.set('attorneyIdToAdd', attorney.get('id'));
		} else {
			this.set('attorneyIdToAdd', null);
		}

		this.updateAttorneysList();
	}
}
