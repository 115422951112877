/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

@classic
export default class ModalTransactionReceipt extends Component {
	@tracked receiptDetails;
	@alias('receiptDetails.id') id;
	@alias('receiptDetails.summary') summary;
	@alias('receiptDetails.option') option;

	get date() {
		const unmodifiedDate = this.receiptDetails.date;
		const displayDate = moment(unmodifiedDate).format('MMMM DD, YYYY');
		return displayDate;
	}

	get total() {
		let total = this.receiptDetails.total;
		total = Number(total);
		total = total.toFixed(2);
		return `$${total}`;
	}

	init() {
		super.init(...arguments);
		document.querySelector('html').classList.add('has-modal');
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		document.querySelector('html').classList.add('has-modal');
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);
		document.querySelector('html').classList.remove('has-modal');
	}

	@action printReceipt() {
		document.body.className += 'no-print';

		if (!document.execCommand('print', true, 'HELLO WORLD!!!')) {
			//* Works in: Chrome, Safari (return false is fails)
			window.print(); //* Works in: Chrome, Firefox
		}

		document.body.classList.remove('no-print');
	}
}
