export const checkDataType = (value) => {
	const dataType =
		typeof value == 'object'
			? Array.isArray(value)
				? 'array'
				: typeof value
			: typeof value;

	return dataType;
};

export const doesExist = (value, checkLengthOrKeys) => {
	//* NAN and 0 exist, but null and undefined shall not be considered as such
	//* explicit false exists
	if (typeof value === 'undefined' || value === undefined || value === null)
		return false;

	if (value || value === 0 || isNaN(value) || value === false) {
		if (checkDataType(value) == 'array' && checkLengthOrKeys === true) {
			return Boolean(value.length);
		}

		if (checkDataType(value) == 'object' && checkLengthOrKeys === true) {
			return Boolean(Object.keys(value).length);
		}

		if (checkDataType(value) == 'number' && value === 0) {
			return true;
		}

		return true;
	}

	return false;
};

export const isBoolean = (value) => {
	//* Will not count 0, null, undefined as false
	return doesExist(value) && checkDataType(value) === 'boolean';
};

export const isString = (value, countEmpty = true, shouldTrim = false) => {
	//* Counts empty strings ("", " ", "     ", etc) unless specified otherwise
	if (doesExist(value) && checkDataType(value) === 'string') {
		if (countEmpty === false && shouldTrim === false && value === '')
			return false;

		if (countEmpty === false && shouldTrim === true && value.trim() === '')
			return false;

		return true;
	}

	return false;
};

export const isNumber = (value) => {
	//* NaN data type is number, but should NOT return those as true
	return doesExist(value) && checkDataType(value) === 'number' && !isNaN(value);
};

export const isObject = (value, checkKeys = true) => {
	//* Defaults to true if dataType passed is not a boolean or nothing passed
	//* So 0, null, undefined do not count as false here
	const shouldCheckKeys = isBoolean(checkKeys) ? checkKeys : true;

	//* null data type is 'object' so double check for that
	return (
		doesExist(value, shouldCheckKeys) &&
		checkDataType(value) === 'object' &&
		value !== null
	);
};

export const isArray = (value, checkLength) => {
	//* Defaults to true if dataType passed is not a boolean or nothing passed
	//* So 0, null, undefined do not count as false here
	const shouldCheckLength = isBoolean(checkLength) ? checkLength : true;

	return (
		doesExist(value, shouldCheckLength) && checkDataType(value) === 'array'
	);
};

export const isFunction = (value) => {
	//* Defaults to true if dataType passed is not a boolean or nothing passed

	//* null data type is 'object' so double check for that
	return (
		doesExist(value) && checkDataType(value) === 'function' && value !== null
	);
};

export const validateDataType = (value, dataType, checkLenOrKeys = true) => {
	//* Defaults to true if dataType passed is not a boolean or nothing passed
	//* So 0, null, undefined do not count as false here
	const shouldCheckLenOrKeys = isBoolean(checkLenOrKeys)
		? checkLenOrKeys
		: true;

	switch (dataType.toLowerCase()) {
		case 'string':
			return isString(value);
		case 'object':
			return isObject(value, shouldCheckLenOrKeys);
		case 'array':
			return isArray(value, shouldCheckLenOrKeys);
		case 'number':
			return isNumber(value);
		case 'boolean':
			return isBoolean(value);
		default:
			return false;
	}
};

export const validateData = (value, dataType, checkLenOrKeys = true) => {
	if (!doesExist(value)) return false;
	if (!doesExist(dataType) || !isString(dataType)) return true;

	//* Only validate the data type if dataType exists and is a string

	//* Defaults to true if dataType passed is not a boolean or nothing passed
	//* So 0, null, undefined do not count as false here
	const shouldCheckLenOrKeys = isBoolean(checkLenOrKeys)
		? checkLenOrKeys
		: true;

	return validateDataType(value, dataType, shouldCheckLenOrKeys);
};

export default validateData;
