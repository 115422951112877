/** @format */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { resolve } from 'rsvp';
import { A } from '@ember/array';
import NotifOptions from 'case-status/constants/notif-options';
import Errors from 'case-status/constants/errors';

export default class CasesFiltersBaseFilterTemplateComponent extends Component {
	constructor() {
		super(...arguments);

		//* Need to find a better way
		this.selections = this.args.selections;

		if (this.args.onChange && typeof this.args.onChange === 'function') {
			this.onChange = this.args.onChange;
		} else {
			this.onChange = (...args) => resolve(args);
		}
	}

	ENV = ENV;

	@service ajax;
	@service store;
	@service permissions;
	@service currentUser;
	@service notifications;
	NotifOptions = NotifOptions;
	Errors = Errors;

	@tracked selections;
	@tracked options;

	@action
	handleOnChange(selections = []) {
		const results = {};

		this.options.forEach((option) => {
			const isChecked = Boolean(
				selections?.find((sel) => sel.param === option.param),
			);
			results[option.param] = isChecked;
			option.checked = isChecked;
		});

		return this.onChange(results);
	}
}

export { A, tracked, action };
