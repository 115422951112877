/** @format */

import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
	createdAt: attr('date'),
	childId: attr('number'),
	userId: attr('number'),
	userType: attr('string'),
	action: attr('string'),
	fieldsUpdated: attr('string'),

	treatment: belongsTo('treatment'),
	appointment: belongsTo('appointment'),
	user: belongsTo('user'),
});
