/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { computed } from '@ember/object';
import Errors from '../constants/errors';

export default Component.extend({
	currentUser: service(),
	company: service(),
	customBranding: service(),
	store: service(),
	ajax: service(),
	notifications: service(),

	lastNameSort: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	sortedUsers: sort('company.users.[]', 'lastNameSort'),
	errors: null,
	usersAreLoading: true,
	filteredUsers: computed('sortedUsers.[]', 'chatUsers.[]', function () {
		return this.sortedUsers.filter((u) => {
			return !this.chatUsers.find((u2) => {
				return u.id === u2.id;
			});
		});
	}),

	chatUsers: computed('chat.users.[]', 'company.type', function () {
		const companyType = this.get('company.type');
		const chatMembers = this.get('chat.members')?.toArray() || [];
		const chatAttorneys = this.get('chat.attorneys')?.toArray() || [];
		const chatParalegals = this.get('chat.paralegals')?.toArray() || [];

		//* Orgs can only manage members
		if (companyType === 'organization') {
			return [...chatMembers] || [];
		}

		if (companyType === 'firm') {
			return [...chatAttorneys, ...chatParalegals];
		}
	}),

	showSaveBtn: computed('oldChatAttorneys', 'oldChatParalegals', function () {
		const oldChatAttorneys = this.oldChatAttorneys;
		const oldChatParalegals = this.oldChatParalegals;

		if (oldChatAttorneys || oldChatParalegals) {
			return true;
		} else {
			return false;
		}
	}),

	preserveChatUsers() {
		const chat = this.chat;
		const oldChatAttorneys = this.oldChatAttorneys;
		const oldChatParalegals = this.oldChatParalegals;
		const oldChatMembers = this.oldChatMembers;

		//* Make a copy to rollback to if one does not already exist
		if (!oldChatAttorneys) {
			const attorneys = chat.get('attorneys')?.toArray() || [];
			this.set('oldChatAttorneys', [...attorneys]);
		}

		if (!oldChatParalegals) {
			const paralegals = chat.get('paralegals')?.toArray() || [];
			this.set('oldChatParalegals', [...paralegals]);
		}

		if (!oldChatMembers) {
			const members = chat.get('members')?.toArray() || [];
			this.set('oldChatMembers', [...members]);
		}
	},

	rollbackChatUsers() {
		const chat = this.chat;
		const oldChatAttorneys = this.oldChatAttorneys;
		const oldChatParalegals = this.oldChatParalegals;
		const oldChatMembers = this.oldChatMembers;

		if (oldChatAttorneys) {
			chat.set('attorneys', oldChatAttorneys);
		}

		if (oldChatParalegals) {
			chat.set('paralegals', oldChatParalegals);
		}

		if (oldChatMembers) {
			chat.set('members', oldChatMembers);
		}

		this.set('oldChatAttorneys', null);
		this.set('oldChatParalegals', null);
		this.set('oldChatMembers', null);
	},

	resetErrors() {
		this.set('errors', null);
	},

	actions: {
		addUserToChat(userToAdd) {
			const chat = this.chat;
			this.preserveChatUsers();
			this.resetErrors();

			chat
				.get(`${userToAdd.get('constructor.modelName')}s`)
				.pushObject(userToAdd);
		},

		cancel() {
			this.rollbackChatUsers();
			this.close();
		},

		removeUserFromChat(userToRemove) {
			const chat = this.chat;
			const chatUsers = this.chatUsers;

			//* Make sure at least one company user is on the chat
			if (chatUsers.length === 1) {
				this.notifications.error('One user must remain on the thread.', {
					autoClear: true,
					clearDuration: 5000,
				});
				return;
			}

			const chatProp = `${userToRemove.get('constructor.modelName')}s`;
			const newUserArray = chat.get(chatProp).filter((u) => {
				return u.get('id') !== userToRemove.get('id');
			});

			this.preserveChatUsers();
			this.resetErrors();

			chat.set(chatProp, newUserArray);
		},

		reset() {
			this.rollbackChat();
		},

		save() {
			const chat = this.chat;

			return chat
				.save()
				.then(() => {
					this.notifications.clearAll();
					this.notifications.success('Thread users successfully updated!', {
						autoClear: true,
						clearDuration: 5000,
					});
					this.resetErrors();
					this.set('oldChatAttorneys', null);
					this.set('oldChatParalegals', null);
					this.close();
				})
				.catch((err) => {
					this.set('errors', Errors.mapResponseErrors(err));
					this.notifications.clearAll();
					this.notifications.error('There was an error updating thread users', {
						autoClear: true,
						clearDuration: 5000,
					});
				});
		},
	},
});
