/** @format */

import { sort, alias } from '@ember/object/computed';
import { A } from '@ember/array';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import Errors from '../constants/errors';
import { defer } from 'rsvp';
import $ from 'jquery';
import { attorneyDisplay } from '../helpers/attorney-display';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend({
	searchString: null,
	lastNameSort: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	isBulkAction: false,
	firm: alias('currentUser.user.firm'),
	permissions: service(),

	currentUser: service(),
	company: service(),

	init() {
		this._super(...arguments);

		this.set('attorneysToAdd', []);
		this.set('isLoading', true);
		this.set('model', []);
		this.fetchNewData();
	},

	//* Reset properties using lifecycle methods
	didInsertElement() {
		$('html').addClass('has-modal');
		this.set('attorneysToAdd', A());
	},

	willDestroyElement() {
		$('html').removeClass('has-modal');
		this.set('attorneysToAdd', A());
		this.model.forEach((attorney) => {
			attorney.set('isSelected', false);
		});
	},

	async fetchNewData() {
		this.set('isLoading', true);

		if (this.isDestroyed || this.isDestroying) {
			return;
		}

		const attorneys = await this.store.findAll('attorney', { reload: true });
		this.model.clear();
		const attrArray = await attorneys.toArray();
		this.model.pushObjects(attrArray);
		this.set('isLoading', false);
	},

	sortedAddAttorneys: sort('attorneysToAdd', 'lastNameSort'),
	sortedAttorneys: sort('filteredAttorneys', 'lastNameSort'),

	//Filters out attorneys based on the search text from the filteredModel set in the modelObserver
	filteredAttorneys: computed(
		'model.[]',
		'clientSearchText',
		'filteredModel.[]',
		'searchString',
		'attorneysToAdd',
		function () {
			const attorneys = this.filteredModel;
			const selectedAttorneyIds = this.attorneysToAdd.mapBy('id');
			if (!attorneys) {
				return null;
			}

			const searchText = this.searchString;
			if (searchText && searchText !== '') {
				return A(
					attorneys
						.map((attorney) => {
							if (selectedAttorneyIds?.includes(attorney.get('id'))) {
								attorney.set('isSelected', true);
							} else {
								attorney.set('isSelected', false);
							}
							return attorney;
						})
						.filter((attorney) => {
							return attorney
								.get('name')
								.toLowerCase()
								?.includes(searchText.toLowerCase());
						}),
				);
			}

			return attorneys.map((attorney) => {
				if (selectedAttorneyIds?.includes(attorney.get('id'))) {
					attorney.set('isSelected', true);
				} else {
					attorney.set('isSelected', false);
				}
				return attorney;
			});
		},
	),

	//Watches the model and removes attorneys on the case
	modelObserver: observer('model.[]', 'case.attorneys.[]', function () {
		const filteredModel = [];

		if (!this.model) {
			this.set('filteredModel', []);
			return;
		} else if (!this.isBulkAction) {
			const selectedAttorneyIds = this.attorneysToAdd.mapBy('id');
			const currentIds = this.get('case.attorneys').mapBy('id');

			//Remove attorneys already on the case
			this.model.forEach((attorney) => {
				if (selectedAttorneyIds?.includes(attorney.get('id'))) {
					attorney.set('isSelected', true);
				}
				if (
					currentIds.indexOf(attorney.get('id')) === -1 &&
					!attorney.get('emailAddress')?.includes('@casestatus.com')
				) {
					filteredModel.push(attorney);
				}
			});
		} else {
			this.model.forEach((attorney) => {
				if (!attorney.get('emailAddress')?.includes('@casestatus.com')) {
					filteredModel.push(attorney);
				}
			});
		}
		this.set('filteredModel', filteredModel);
	}).on('init'),

	attorneysToAddCount: computed('attorneysToAdd.[]', function () {
		const attorneysToAdd = this.attorneysToAdd;
		return attorneysToAdd.get('length');
	}),

	actions: {
		addAttorneysButtonPressed() {
			const result = defer();
			this.set('errors', null);
			const firmSettings = this.permissions.currentUser.get(
				'user.firm.firmSettings.firmSettings',
			);
			if (!this.isBulkAction) {
				if (this.attorneysToAddCount === 0) {
					this.set('errors', [
						`At least one ${attorneyDisplay([
							this.get('currentUser.user.firm'),
						]).toLowerCase()} should be selected`,
					]);
					result.reject();
				} else if (
					this.case.get('attorneys.length') + this.attorneysToAddCount >
					firmSettings?.max_attorneys
				) {
					this.set('errors', [
						`You can only add up to ${
							firmSettings.max_attorneys
						} ${attorneyDisplay([
							this.get('currentUser.user.firm'),
						]).toLowerCase()}s per ${dynamicCaseLabel([this.company])}.`,
					]);
					result.reject();
				} else {
					this.addAttorneysToCase(this.attorneysToAdd)
						.then(() => {
							this.set('attorneysToAdd', []);
							result.resolve();
						})
						.catch((resp) => {
							this.set('errors', Errors.mapResponseErrors(resp));
							result.reject();
						});
				}
			} else {
				if (this.attorneysToAddCount === 0) {
					this.set('errors', [
						`At least one ${attorneyDisplay([
							this.get('currentUser.user.firm'),
						]).toLowerCase()} should be selected`,
					]);
					result.reject();
				} else if (this.attorneysToAddCount > 4) {
					this.set('errors', [
						`You can only add up to 4 ${attorneyDisplay([
							this.get('currentUser.user.firm'),
						]).toLowerCase()}s with bulk actions.`,
					]);
					result.reject();
				} else {
					this.addAttorneysToCase(this.attorneysToAdd)
						.then(() => {
							this.set('attorneysToAdd', []);
							result.resolve();
						})
						.catch((resp) => {
							this.set('errors', Errors.mapResponseErrors(resp));
							result.reject();
						});
				}
			}
			return result.promise;
		},

		onAttorneyToggled(row, isAddingAttorney) {
			const attorney = row.get('content');
			if (isAddingAttorney) {
				this.attorneysToAdd.pushObject(attorney);
			} else {
				this.attorneysToAdd.removeObject(attorney);
			}
		},
	},
});
