/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import $ from 'jquery';
import ENV from 'case-status/config/environment';
import Errors from 'case-status/constants/errors';
import { next } from '@ember/runloop';

@classic
export default class MobileNewCaseMessageModalComponent extends Component {
	@service currentUser;
	@service store;
	@service ajax;

	@tracked newMessageContent;

	caseId;

	@action
	async validateNewMessage() {
		this.newMessage = await this.store.createRecord('message', {
			case: this.store.peekRecord('case', this.caseId),
			content: this.newMessageContent,
		});

		return this.sendNewMessage(this.newMessage);
	}

	@action
	sendNewMessage(newMessage) {
		// update this.wasSaved when saved successfully
		return newMessage.save().then(() => {
			this.unshiftNewMessage(newMessage);
			next(() => {
				this.close();
			});
		});
	}

	@action
	showFilePicker() {
		$('#file_picker').click();
	}

	@action
	postFile(formData, result) {
		const theCaseRaw = this.store.peekRecord('case', this.caseId);
		formData.append('case_id', theCaseRaw.get('id'));

		const url = `${ENV.host}/files`;
		const data = formData;

		// const files = this.files;

		this.ajax
			.post(url, {
				processData: false,
				contentType: false,
				data: data,
			})
			.then(() => {
				// theCaseRaw.reload();
				// files?.update();
				this?.refreshMessages(); // Gotta be a better way...
				next(() => this?.close());
				return result.resolve();
			})
			.catch((response) => {
				const errors = Errors.mapResponseErrors(response);
				this.notifications.error(errors.join(', '));
				return result.reject(errors);
			});
	}
}
