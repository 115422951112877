/** @format */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import $ from 'jquery';
import ENV from 'case-status/config/environment';
import { defer } from 'rsvp';
import Errors from '../constants/errors';
import { validator, buildValidations } from 'ember-cp-validations';
import { createErrorIfFileTooBig } from '../helpers/validate-file-size';

const Validations = buildValidations({
	stagedFile: validator('presence', {
		presence: true,
		message: 'A PDF file is required',
		description: 'File',
	}),

	caseId: validator('presence', {
		presence: true,
	}),

	selectedSignatories: validator('array-length', {
		min: 1,
		dependantKeys: ['selectedSignatories.[]'],
		message: 'Must assign a minimum of 1 signatory',
		description: 'Signatory',
	}),
});

export default Component.extend(Validations, {
	company: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),
	sortedAvailableSignatories: sort(
		'availableSignatories',
		'availableSignatoriesSort',
	),
	availableSignatoriesSort: null,
	availableSignatories: computed(
		'clients.[]',
		'attorneys.[]',
		'paralegals.[]',
		'theCase.{clients.[],attorneys.[],paralegals.[]}',
		'users.{clients.[],attorneys.[],paralegals.[]}',
		function () {
			const clients =
				this.clients ||
				this.get('theCase.clients') ||
				this.get('users.clients') ||
				[];
			const attorneys =
				this.attorneys ||
				this.get('theCase.attorneys') ||
				this.get('users.attorneys') ||
				[];
			const paralegals =
				this.paralegals ||
				this.get('theCase.paralegals') ||
				this.get('users.paralegals') ||
				[];

			const availableSignatories = [...clients, ...attorneys, ...paralegals];

			return availableSignatories;
		},
	),

	maxUploadFileMB: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
			);
		},
	),

	filteredAvailableSignatories: computed(
		'sortedAvailableSignatories.[]',
		'selectedSignatories.[]',
		function () {
			const availableSignatories = this.sortedAvailableSignatories || [];
			const selectedSignatories = this.selectedSignatories || [];
			return availableSignatories.filter((availableSignatory) => {
				return !selectedSignatories.find((selectedSignatory) => {
					return availableSignatory.id === selectedSignatory.id;
				});
			});
		},
	),
	selectedSignatories: null,
	prefix: null,

	init() {
		this._super(...arguments);
		this.setProperties({
			selectedSignatories: [],
			availableSignatoriesSort: ['lastName'],
			prefix: '',
		});

		this.resetErrors();
	},

	newSignatory(user) {
		const newSignatory = {
			child_id: user.id,
			child_type: user.constructor.modelName,
			prefix: this.prefix || null,
		};
		return newSignatory;
	},

	resetErrors() {
		this.setProperties({
			errors: [],
			showErrors: false,
			fileError: false,
			signatoryError: false,
			uploadError: false,
			internalError: false,
		});

		return this.errors;
	},

	validate() {
		const errors = this.resetErrors();
		const fileValidation = this.get('validations.attrs.stagedFile');
		const signatoriesValidation = this.get(
			'validations.attrs.selectedSignatories',
		);
		const caseIdValidation = this.get('validations.attrs.caseId');

		if (!fileValidation.isValid) {
			errors.push(fileValidation.message);
			this.set('fileError', true);
		}

		if (!signatoriesValidation.isValid) {
			errors.push(signatoriesValidation.message);
			this.set('signatoriesError', true);
		}

		if (!caseIdValidation.isValid) {
			errors.push('An error has occurred please try again later');
			this.set('internalError', true);
		}

		return errors.length === 0;
	},

	actions: {
		addSignatory(signatoryToAdd) {
			this.set('signatoriesError', false);
			this.selectedSignatories.pushObject(signatoryToAdd);
		},

		removeSignatory(signatoryToRemove) {
			const selectedSignatories = this.selectedSignatories || [];
			const newSelectedSignatories = selectedSignatories.filter(
				(selectedSignatory) => {
					return selectedSignatory.id !== signatoryToRemove.id;
				},
			);

			this.set('signatoriesError', false);
			this.set('selectedSignatories', newSelectedSignatories);
		},

		stageFile(fileClass) {
			this.set('fileError', false);

			if (fileClass && fileClass.queue && fileClass.queue.files) {
				fileClass.queue.files.pop();
				if (fileClass.blob) {
					let file = fileClass.blob;

					const errorMsg = createErrorIfFileTooBig([
						file.size,
						this.maxUploadFileMB,
						this.notifications,
					]);
					if (errorMsg != null) {
						return;
					}

					this.set('stagedFile', file);
				} else {
					console.error('fileClass.blob is undefined');
					this.set('fileError', true);
				}
			} else {
				console.error(
					'fileClass or fileClass.queue or fileClass.queue.files is undefined',
				);
				this.set('fileError', true);
			}
		},

		send() {
			const stagedFile = this.stagedFile;
			const caseId = this.caseId;
			const signatories = JSON.stringify(
				this.selectedSignatories.map((user) => this.newSignatory(user)),
			);
			const formData = new FormData();

			const filesUrl = `${ENV.host}/files/signed_documents`;
			const stagedResult = defer();

			// Reset Error States and check for validation
			const isValid = this.validate();

			if (!isValid) {
				// If errors are found, display them and break out of the action
				this.set('showErrors', true);
				stagedResult.reject();
				return stagedResult.promise;
			}

			// If there are no errors, then process the data and send the file to the API
			formData.append('file', stagedFile, stagedFile.name);
			formData.append('case_id', caseId);
			formData.append('signatories', signatories);
			formData.append('prefix', this.prefix);

			return this.ajax
				.post(filesUrl, {
					processData: false,
					contentType: false,
					data: formData,
				})
				.then(() => {
					if (
						this.onSuccessCallback &&
						typeof this.onSuccessCallback === 'function'
					) {
						this.onSuccessCallback();
					}
					this.notifications.success('File successfully submitted for E-Sign', {
						autoClear: true,
						clearDuration: 5000,
					});
					if (this.close && typeof this.close === 'function') {
						this.close();
					}
				})
				.catch((err) => {
					this.set('errors', Errors.mapResponseErrors(err));
					this.set('showErrors', true);
				});
		},

		triggerFilePicker() {
			if (!this.isClicking) {
				// Set isClicking to true to prevent multiple event stacks
				// When the file picker is clicked it opens a window prompt which pauses javascript mid functions and resumes once the prompt is closed
				// Once the javascript resumes set isClicking to false
				this.set('isClicking', true);
				let fileInput = this.element.querySelector('.file-upload');
				if (fileInput) {
					fileInput.click();
				}
				this.set('isClicking', false);
			}
		},
	},
});
