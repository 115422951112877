import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and showFilter (not isLoading))}}\n\t{{! Don't load in until options are mapped }}\n\t<FilterDropdown2\n\t\t@title='Language'\n\t\t@options={{options}}\n\t\t@selections={{selections}}\n\t\t@limit={{1}}\n\t\t@onChange={{action 'handleOnChange'}}\n\t/>\n{{/if}}", {"contents":"{{#if (and showFilter (not isLoading))}}\n\t{{! Don't load in until options are mapped }}\n\t<FilterDropdown2\n\t\t@title='Language'\n\t\t@options={{options}}\n\t\t@selections={{selections}}\n\t\t@limit={{1}}\n\t\t@onChange={{action 'handleOnChange'}}\n\t/>\n{{/if}}","moduleName":"case-status/components/cases-filters/target-language-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/target-language-filters.hbs"}});
import BaseCasesFilterTemplate, {
	A,
	tracked,
	action,
} from './base-filter-template';
import { next } from '@ember/runloop';

export default class CasesFiltersTargetLanguageFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		const languages = this.store.peekAll('language').toArray() || A([]);

		const mapOptions = () => {
			this.options = A([]);

			this.ajax
				.request(
					`${this.ENV.host}/firms/${this.currentUser.get(
						'user.firm.id',
					)}/target-languages`,
				)
				.then((res) => {
					const targetLanguages = Object.keys(res);

					this.options = A(targetLanguages)
						.map((tarLang) => {
							const language = languages.findBy('code', tarLang);

							return {
								value: language?.display || tarLang,
								checked: this.args.targetLanguage == tarLang,
								classNames: 'filter-lg',
								param: tarLang,
							};
						})
						.uniq()
						.sortBy('value');
				})
				.catch((err) => {
					this.notifications.error(
						this.Errors.mapResponseErrors(err),
						this.NotifOptions.error,
					);
				})
				.finally(() => {
					this.isLoading = false;
					if (!this.options?.length) {
						this.showFilter = false;
					}
				});
		};

		if (!languages.length) {
			//* I can't imagine this should ever need to fire, but just in case.
			//* ... but it does because the peekAll doesn't load right away...

			this.store.findAll('language').then((res) => {
				//* So just check again for them if they are missing
				if (!languages.length) {
					languages.pushObjects(res.toArray());
				}
				mapOptions();
			});
		} else {
			mapOptions();
		}
	}

	@tracked isLoading = true;
	@tracked showFilter = true;

	@action
	handleOnChange(value) {
		this.selections = Array.isArray(value) ? value : [value];
		super.handleOnChange(this.selections);

		//* Flip the isLoading to force the filter to rerender
		this.isLoading = true;
		next(() => {
			//* Must use next runloop else the change doesn't get registered.
			this.isLoading = false;
		});

		this.onChange({ targetLanguage: value[0]?.param || null });
	}
}
