/** @format */

import Service, { inject as service } from '@ember/service';

export default Service.extend({
	router: service(),
	session: service(),
	thirdParty: service(),
	login() {
		const userType = this.get('session.data.authenticated.user_type');

		const integrationType = this.get('thirdParty.integrationType');
		const integrationId = this.get('thirdParty.integrationId');
		const shouldRedirect = this.get('thirdParty.shouldRedirect');
		const redirectTo = this.get('thirdParty.redirectTo');

		switch (userType) {
			case 'client':
				this.transitionTo('app.client.cases');
				break;
			case 'member':
				this.transitionTo('app.organization.cases');
				break;
			//* attorney and paralegal apply the same logic
			case 'attorney':
			case 'paralegal':
				if (integrationType && integrationId && shouldRedirect) {
					this.transitionTo(`app.third_party.case.index`, integrationId);
				} else {
					const location = redirectTo || 'app.firm.cases';
					if (integrationType) {
						this.router.transitionTo(location, {
							queryParams: { integrationType: integrationType },
						});
					} else {
						this.router.transitionTo(location);
					}
				}
				break;
			default:
				//* If the authenticated userType isn't one of our predefined four, invalidate the session and send them back to /login
				this.session.invalidate();
				break;
		}
	},
});
