/** @format */

import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import Component from '@ember/component';

export default Component.extend({
	customBranding: service('custom-branding'),
	company: service(),

	hasUnreadMessages: computed('unreadCount', function () {
		return this.unreadCount > 0 ? 'alert' : '';
	}),

	unreadCount: computed('case.newMessages', function () {
		return this.case.newMessages;
	}),

	truncatedDescription: computed('case.description', function () {
		const content = this.get('case.description');

		//If there's no description, nothing to truncate.
		if (!content) {
			return null;
		}

		if (content.length > 125) {
			return content.substring(0, 125) + '...';
		}
		return this.get('case.description');
	}),

	hasCompletedTeamProfile: computed(
		'case.{attorneys,paralegals}.@each.hasCompletedProfile',
		function () {
			const hasCompletedAttorneyProfile = this.get('case.attorneys').any(
				(attorney) => {
					return attorney.get('hasCompletedProfile');
				},
			);

			const hasCompletedParalegalProfile = this.get('case.paralegals').any(
				(paralegals) => {
					return paralegals.get('hasCompletedProfile');
				},
			);

			return hasCompletedAttorneyProfile || hasCompletedParalegalProfile;
		},
	),
	alertConditionMet: computed('case.{onHold,closed}', function () {
		return this.case.onHold || this.case.closed;
	}),
});
