/** @format */

import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { integrations } from '../constants/integrations';

export default Service.extend({
	router: service(),
	chromeExtension: service(),
	thirdPartyInfo: { intType: null, intId: null },
	isLitifyAdmin: false,
	hasParams: false,
	intType: null,
	intId: null,
	redirect: null,
	integrationType: computed('intType', 'thirdPartyInfo', {
		get() {
			const intType = this.intType || this.get('thirdPartyInfo.intType');
			return intType;
		},
		set(key, value) {
			this.set('intType', value);
			this.set('thirdPartyInfo.intType', value);
			return value;
		},
	}),
	integrationId: computed('intId', {
		get() {
			const intId = this.intId || this.get('thirdPartyInfo.intId');
			return intId;
		},
		set(key, value) {
			this.set('intId', value);
			this.set('thirdPartyInfo.intId', value);
			return value;
		},
	}),
	redirectTo: computed('redirect', {
		get() {
			return this.redirect;
		},
		set(key, value) {
			this.set('redirect', value);
			return value;
		},
	}),
	limited: computed('router.currentURL', function () {
		const currentURL = this.router.currentURL || '';
		const inThirdPartyRoute = currentURL.includes('third_party');
		const inLoginWithThirdPartyInfo =
			currentURL.includes('third_party') &&
			this.integrationType &&
			this.integrationId;

		return inThirdPartyRoute || inLoginWithThirdPartyInfo;
	}),
	isLitify: computed('integrationType', function () {
		return (
			window.parent.location !== window.location &&
			this.integrationType === integrations.LITIFY
		);
	}),

	isLitifyOrSalesforce: computed('integrationType', function () {
		const inLitifyMessagingCompanion =
			this.integrationType === integrations.LITIFY;
		const forceReferrer =
			document.referrer.includes('lightning') ||
			document.referrer.includes('salesforce');
		return inLitifyMessagingCompanion || forceReferrer;
	}),

	isIFrame: computed(function () {
		return window.parent.location !== window.location;
	}),
	showIframeUi: computed('integrationType', 'isLitifyAdmin', function () {
		return (
			!this.isLitifyAdmin &&
			window.parent.location !== window.location &&
			(this.integrationType === integrations.LITIFY ||
				this.integrationType === integrations.THIRD_PARTY)
		);
	}),

	shouldRedirect: computed('hasParams', function () {
		//* This checks if the site is loaded in a iFrame or not
		const isIFrame = window.parent.location !== window.location;
		//* This is set when there are params for the thirdParty integration
		//* This MUST be present to rightfully say that the app "shouldRedirect" along with being in the iFrame
		const hasParams = this.hasParams;
		return isIFrame && hasParams;
	}),
});
