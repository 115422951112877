import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { safeHtml } from 'case-status/helpers/safe-html';
import { inject as service } from '@ember/service';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { sort } from '@ember/object/computed';
import Errors from 'case-status/constants/errors';

@classic
export default class ModalBulkUpdatePracticeAreaComponent extends Component {
	@tracked closeAction;
	@tracked selectedCasesCount;
	@tracked errors;
	@tracked selectedPracticeArea;
	@tracked selectedStage;

	@service company;
	@service store;

	stagesSortAttributes = ['number'];
	@sort('stages', 'stagesSortAttributes') sortedStages;
	practiceAreasSortAttributes = ['name'];
	@sort('practiceAreas', 'practiceAreasSortAttributes') sortedPracticeAreas;

	get practiceAreas() {
		return this.store.peekAll('case-type');
	}

	get stages() {
		if (!this.selectedPracticeArea) return [];
		return this.selectedPracticeArea.caseStatuses;
	}

	get title() {
		return safeHtml([
			`Update the Practice Area for <br> <b>${
				this.selectedCasesCount
			} ${dynamicCaseLabel([this.company, this.selectedCasesCount > 1])}</b>?`,
		]);
	}

	//actions
	@action close() {
		if (this.closeAction && typeof this.closeAction == 'function') {
			this.closeAction();
		}
	}

	@action cancel() {
		this.send('close');
	}

	@action selectPracticeArea(practiceArea) {
		this.set('selectedPracticeArea', practiceArea);
		this.set('selectedStage', null);
	}

	@action selectStage(stage) {
		this.set('selectedStage', stage);
	}

	@action submit() {
		if (
			this.selectedPracticeArea &&
			this.selectedStage &&
			this.submitBulkPracticeAreaUpdate &&
			typeof this.submitBulkPracticeAreaUpdate == 'function'
		) {
			return this.submitBulkPracticeAreaUpdate(
				this.selectedPracticeArea,
				this.selectedStage,
			).catch((err) => {
				this.set('errors', Errors.mapResponseErrors(err));
			});
		}
	}
}
