/** @format */

import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import CasesMixin from '../../../mixins/controller/cases';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Controller.extend(CasesMixin, {
	//* Filters
	firmName: null,
	caseId: null,
	clientName: null,
	ajax: service(),

	searchType: 'firm',
	searchStringChanged: observer('searchString', function () {
		const searchString = this.searchString;
		const searchType = this.searchType;

		switch (searchType) {
			case 'firm':
				this.set('firmName', searchString);
				break;
			case 'caseId':
				this.set('caseId', searchString);
				break;
			case 'client':
				this.set('clientName', searchString);
				break;
		}
	}),
	searchTypeChanged: observer('searchType', function () {
		this.set('firmName', null);
		this.set('caseId', null);
		this.set('clientName', null);

		this.searchStringChanged();
	}),

	queryChanged: observer('sort', 'filters.{}', function () {
		if (this.model) {
			this.model.clear();
			this.set('isLoading', true);
			debounce(this, this.fetchNewData, 500);
		}
	}),
	preferenceFilterOptions: computed('closed', function () {
		return [
			{
				value: 'Closed Cases',
				checked: this.closed,
			},
		];
	}),
	filters: computed(
		'caseId',
		'firmName',
		'newMessages',
		'bulkTypes',
		'dateRanges.{startDate,endDate}',
		'bulkStatus',
		'closed',
		'onHold',
		'clientName',
		function () {
			let activeFilters = {};
			const availableFilters = {
				'firm.name': this.firmName,
				'client.name': this.clientName,
				readable_id: this.caseId,
				'case_type.name': this.bulkTypes,
				new_messages: this.newMessages,
				closed: this.closed,
				start_date: moment(this.dateRanges.startDate).isValid()
					? moment(this.get('dateRanges.startDate'))
							.format('YYYY-MM-DD')
							.toString()
					: false,
				end_date: moment(this.dateRanges.endDate).isValid()
					? moment(this.get('dateRanges.endDate'))
							.format('YYYY-MM-DD')
							.toString()
					: false,
				'case_status.number': this.bulkStatus,
			};

			for (let filterProperty in availableFilters) {
				let testValue = availableFilters[filterProperty];

				// Don't want to send useless filters like empty arrays
				if (testValue instanceof Array && testValue.length < 1) {
					testValue = false;
				}

				if (testValue) {
					// API endpoint for sendBulkMessage yells at me if the boolean value on 'my_cases' isn't a string 😢, luckily fetchNewData accepts it as a string or a boolean
					const formattedValue =
						typeof testValue == 'boolean' ? testValue.toString() : testValue;

					activeFilters[filterProperty] = formattedValue;
				}
			}
			return activeFilters;
		},
	),

	//* Custom Methods
	fetchNewData() {
		this.set('isLoading', true);

		const queryParams = { filter: {}, page: {} };

		queryParams.filter = this.filters;
		queryParams.page.size = this.size;
		queryParams.sort = this.sort;

		this.set('queryParams', queryParams);

		//* Set cache-buster since this is new data being called;
		this.set('ajax.noCache', true);

		this.store.query('case', queryParams).then((cases) => {
			this.model.clear();
			const caseArray = cases.toArray();
			this.model.pushObjects(caseArray);
			this.set('meta', cases.get('meta'));
			this.set('page', 1);

			next(() => {
				this.set('isLoading', false);
			});
		});
	},

	//* Lifecycle Methods
	init() {
		this._super(...arguments);

		this.setProperties({
			searchTypeOptions: ['firm', 'caseId', 'client'],
			bulkFilterOptions: ['practice area', 'date', 'status'],
			bulkMsg: [],
			bulkTypes: [],
			bulkStatus: [],
			dateRanges: { startDate: null, endDate: null },
			statusTypes: [
				{
					value: 'Stage 1',
					param: 1,
					icon: 'status-color-md stage-1',
				},
				{
					value: 'Stage 2',
					param: 2,
					icon: 'status-color-md stage-2',
				},
				{
					value: 'Stage 3',
					param: 3,
					icon: 'status-color-md stage-3',
				},
				{
					value: 'Stage 4',
					param: 4,
					icon: 'status-color-md stage-4',
				},
				{
					value: 'Stage 5',
					param: 5,
					icon: 'status-color-md stage-5',
				},
				{
					value: 'Stage 6',
					param: 6,
					icon: 'status-color-md stage-6',
				},
			],
			messageFilterOptions: [
				{
					value: 'Has unread messages',
					imgSrc: 'img/new-messages.svg',
					alt: 'new messages',
					checked: this.newMessages,
					classNames: 'filter-md',
					param: 'newMessages',
				},
			],
		});
	},

	//* Actions
	actions: {
		fetchMoreCases() {
			if (this.get('meta.total_pages') > this.page) {
				this.set('isLoading', true);
				this.incrementProperty('page');

				const queryParams = this.queryParams;
				queryParams.page.number = this.page;

				this.store.query('case', queryParams).then((cases) => {
					this.set('meta', cases.get('meta'));
					const caseArray = cases.toArray();

					this.model.pushObjects(caseArray);
					next(() => {
						this.set('isLoading', false);
					});
				});
			}
		},

		updateSort(sortColumn, sortDirection) {
			if (sortColumn == 'case_status.name') {
				sortColumn = 'case_status.number';
			}

			const sortString = `${sortDirection}${sortColumn.underscore()}`;
			this.set('sort', sortString);
		},
		togglePreferenceOption(option) {
			switch (option) {
				case 'My Cases':
					this.toggleProperty('preferences.myCases');
					break;

				case 'Closed Cases':
					this.toggleProperty('closed');
					break;

				case 'Cases On Hold':
					this.toggleProperty('onHold');
					break;

				case 'Onboarding Cases':
					this.toggleProperty('onboarding');
					break;

				case 'Removed Cases':
					this.toggleProperty('removed');
					break;
			}
		},
	},
});
