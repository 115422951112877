/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
	attributeBindings: ['id', 'data-test-id'],
	classNameBindings: ['_defaultClassNames'],
	_defaultClassNames: 'typeform-yes-no row col-xs-4 margin-bottom',
	id: alias('model.id'),
	'data-test-id': 'component',
	options: computed('model.responses.[].boolean', function () {
		if (!this.model) return [];
		const answer = this.get('model.responses')[0].boolean;
		const options = [
			{
				label: 'Yes',
				selected: answer,
			},
			{
				label: 'No',
				selected: !answer,
			},
		];

		return options;
	}),
});
