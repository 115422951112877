/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
	//* Attributes
	createdAt: attr('date'),
	updatedAt: attr('date'),
	deletedAt: attr('date'),
	serviceStartDate: attr('string'),
	serviceEndDate: attr('string'),
	officeName: attr('string'),
	address: attr('string'),
	doctorName: attr('string'),
	officePhoneNumber: attr('string'),
	integrationId: attr('string'),
	providerType: attr('string'),
	createdByIntegration: attr('boolean'),

	//* Relationships
	case: belongsTo('case'),
	firm: belongsTo('firm'),
});
