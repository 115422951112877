export const MESSAGE_TEMPLATE_CONSTANTS = {
	CASE_CLOSED_MODAL_TITLE_NOTE:
		'Note: Changing the content of this will change the title of the Case Closed Modal that appears as a banner when a case is closed. This change will be for the entire firm.',
	CASE_CLOSED_MODAL_DESCRIPTION_NOTE:
		'Note: Changing the content of this will change the content of the Case Closed Modal that appears as a banner when a case is closed. This change will be for the entire firm.',
	DEFAULT_MESSAGE_TEMPLATE_NOTE:
		'Note: Changing the content of this message will change the automated messages for the entire firm.',
	CLOSED_TITLE_PLACEHOLDER: 'Case Closed',
	CLOSED_DESCRIPTION_PLACEHOLDER:
		"It's been a pleasure working with you. Please let me know if there's anything I can help you with in the future.",
	DEFAULT_PLACEHOLDER: 'Type your message here',
};
