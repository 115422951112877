/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
	checked: false,
	indeterminate: false,
	casesChanged: observer(
		'extra.{selectedCases,deselectedCases,selectAllCase}',
		function () {
			this.set('checked', this.extra.selectAllCases);
			if (
				(!this.extra?.selectAllCases &&
					this.extra?.selectedCases?.length > 0) ||
				(this.extra?.selectAllCases && this.extra?.deselectedCases?.length > 0)
			) {
				this.set('indeterminate', true);
			} else {
				this.set('indeterminate', false);
			}
		},
	),

	init() {
		this._super(...arguments);
		if (this.extra?.selectedCases) {
			const selectedCases = this.extra?.selectedCases || [];
			const deselectedCases = this.extra?.deselectedCases || [];
			if (selectedCases?.length > 0 || deselectedCases?.length > 0) {
				this.set('indeterminate', true);
			}
		}
		this.set('checked', this.extra?.selectAllCases);
	},

	actions: {
		handleSelect(targetCase) {
			if (this.extra?.disableCaseSelect) {
				this.set('checked', false);
			} else if (
				!this.extra?.selectAllCases &&
				this.tableActions?.selectCase &&
				typeof this.tableActions?.selectCase == 'function'
			) {
				this.tableActions?.selectCase(targetCase);
			} else if (
				this.extra?.selectAllCases &&
				this.tableActions?.deselectCase &&
				typeof this.tableActions?.deselectCase == 'function'
			) {
				this.tableActions?.deselectCase(targetCase);
			}
		},
	},
});
