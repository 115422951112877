/** @format */

import Service, { inject as service } from '@ember/service';

export default class MobileService extends Service {
	@service currentUser;

	get wantsMobile() {
		return this.currentUser.get('preferences.wantsMobile');
	}

	set wantsMobile(value) {
		this.set('currentUser.preferences.wantsMobile', value);
	}

	get isMobile() {
		const ua = navigator.userAgent,
			isMobile = / mobile/i.test(ua);
		return isMobile;
	}

	get showMobile() {
		return this.isMobile && this.wantsMobile;
	}

	setWantsDesktop() {
		this.set('wantsMobile', false);
	}

	setWantsMobile() {
		this.set('wantsMobile', true);
	}
}
