/** @format */

import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
	classNames: ['cell-overflow-hover'],
	currentUser: service(),
	valueObserver: observer('value', function () {
		const value = this.value;
		if (this.get('row.group')) {
			this.set('formattedValue', this.get('row.group'));
			this.set('isBold', true);
		} else if (value.get('length') === 1) {
			this.set('formattedValue', value.objectAt(0).get('name'));
			this.set('isBold', true);
		} else if (
			!this.get('currentUser.user.organization.id') ||
			value.get('length')
		) {
			this.set('formattedValue', `${value.get('length')} Clients`);
			this.set('isBold', false);
		} else {
			this.set('formattedValue', `Client Name Not Shared`);
			this.set('isBold', false);
		}
	}).on('init'),
});
