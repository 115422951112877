import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#light-table\n\ttable\n\theight=height\n\ttableActions=(hash onRowToggled=(action 'handleRowToggled'))\n\tas |t|\n}}\n\t{{t.head iconAscending='sort-asc' iconDescending='sort-desc' fixed=true}}\n\n\t{{#t.body onRowClick=(action 'handleRowClicked') as |body|}}\n\n\t\t{{#if @isLoading}}\n\t\t\t{{#body.loader}}\n\t\t\t\t<LoadingAnimation @inline='true' />\n\t\t\t{{/body.loader}}\n\t\t{{else}}\n\t\t\t{{#if table.isEmpty}}\n\t\t\t\t{{#body.no-data}}\n\t\t\t\t\tNo\n\t\t\t\t\t{{lowercase @modelLabel}}\n\t\t\t\t\tfound\n\t\t\t\t{{/body.no-data}}\n\t\t\t{{/if}}\n\t\t{{/if}}\n\t{{/t.body}}\n{{/light-table}}", {"contents":"{{#light-table\n\ttable\n\theight=height\n\ttableActions=(hash onRowToggled=(action 'handleRowToggled'))\n\tas |t|\n}}\n\t{{t.head iconAscending='sort-asc' iconDescending='sort-desc' fixed=true}}\n\n\t{{#t.body onRowClick=(action 'handleRowClicked') as |body|}}\n\n\t\t{{#if @isLoading}}\n\t\t\t{{#body.loader}}\n\t\t\t\t<LoadingAnimation @inline='true' />\n\t\t\t{{/body.loader}}\n\t\t{{else}}\n\t\t\t{{#if table.isEmpty}}\n\t\t\t\t{{#body.no-data}}\n\t\t\t\t\tNo\n\t\t\t\t\t{{lowercase @modelLabel}}\n\t\t\t\t\tfound\n\t\t\t\t{{/body.no-data}}\n\t\t\t{{/if}}\n\t\t{{/if}}\n\t{{/t.body}}\n{{/light-table}}","moduleName":"case-status/components/table.hbs","parseOptions":{"srcName":"case-status/components/table.hbs"}});
import Component from '@glimmer/component';
import Table from 'ember-light-table';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isFunction } from 'case-status/constants/data-types';

export default class TableComponent extends Component {
	constructor() {
		super(...arguments);

		this.columns = this?.args?.columns;
		this.model = this?.args?.model;
		this.height = this?.args?.height ?? '350px';

		this.table = Table?.create({
			columns: this.columns,
			rows: this.model,
			enableSync: true,
		});
	}

	/* 
		Columns Structure:
		Must be an object array

		Object attributes used by Table (ember-light-table)
		{
			label: STRING,
			valuePath: STRING,
			width: STRING,
			cellClassNames: STRING,
			cellComponent: STRING,
			sortable: BOOLEAN,
			draggable: BOOLEAN,
			resizable: BOOLEAN,
		}

	*/

	@tracked model;
	@tracked table;
	@tracked columns;
	@tracked _rows;

	get rows() {
		return this._rows;
	}

	set rows(value) {
		this._rows = value;
		// Do stuff to rows here
	}

	@action
	handleRowClicked(row, event) {
		if (
			event.target.className.includes('checkbox') ||
			event.target.className.includes('control')
		)
			return;
		if (event.target.children[0]) {
			if (
				event.target.children[0].className &&
				event.target.children[0].className.includes('checkbox')
			)
				return;
			if (event.target.children[0].children[0]) {
				if (
					event.target.children[0].children[0].className &&
					event.target.children[0].children[0].className.includes('checkbox')
				)
					return;
			}
		}

		if (event.target.parentElement.nodeName === 'TR') {
			// event.target.parentElement.children[0].children[0].children[0].children[0].checked =
			// 	!row.get('content.isSelected');
			// row.set('content.isSelected', !row.get('content.isSelected'));
			return;
		} else if (event.target.parentElement.nodeName === 'TD') {
			if (event.target.parentElement.parentElement.nodeName === 'TR') {
				// event.target.parentElement.parentElement.children[0].children[0].children[0].children[0].checked =
				// !row.get('content.isSelected');
				// row.set('content.isSelected', !row.get('content.isSelected'));
				return;
			}
		}

		this.handleRowToggled(row.get('content'));
	}

	@action
	handleRowToggled(rowContent) {
		const { rowToggleHandler } = this?.args;
		if (isFunction(rowToggleHandler)) return rowToggleHandler(rowContent);
	}
}
