import Component from '@glimmer/component';
import { UPGRADE_URL } from '../constants/urls';

export default class LowCostMultiBannerWrapper extends Component {
	constructor() {
		super(...arguments);
	}

	get buttonLink() {
		return this.args.buttonLink || UPGRADE_URL;
	}

	get buttonTitle() {
		return this.args.buttonTitle || 'Upgrade now';
	}

	get colorClass() {
		switch (this.args.color) {
			case 'green':
				return 'green-color';
			case 'red':
				return 'red-color';
			default:
				return 'yellow-color';
		}
	}
}
