/** @format */

import Model, { attr, belongsTo } from '@ember-data/model';
import activityIconMappings from 'case-status/constants/activity-feed-helpers';

// look in ActivityStatisticPeriodSerializer

export default class ActivityStatisticModel extends Model {
	@attr('string') activityType;
	@attr('number') total;
	@attr('number') trend;

	get trendDirection() {
		return this.trend > 0 ? 'up' : 'down';
	}

	get trendIcon() {
		if (!this.trend && this.trend != 0) return '';
		return `chevron-${this.trendDirection}`;
	}

	get trendString() {
		if (!this.trend && this.trend != 0) return '--';
		return `${this.trend}%`;
	}

	get icon() {
		return activityIconMappings[this.activityType];
	}

	@belongsTo('activity-statistic-period', { inverse: 'statistics' })
	activityGroup;
}
