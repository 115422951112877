/** @format */

import Controller from '@ember/controller';
import InboxControllerMixin from '../../../mixins/controller/inbox';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default Controller.extend(InboxControllerMixin, {
	filterOptions: computed(function () {
		return ['All', 'Internal', 'Client', 'Collaboration', 'Unread'];
	}),
	company: service(),
	inbox: computed('model.{chatNotifications,notifications}.[]', function () {
		const notifications = this.get('model.notifications').toArray();
		const chatNotifications = this.get('model.chatNotifications').toArray();
		return A([...notifications, ...chatNotifications]);
	}),
});
