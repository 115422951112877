/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Table from 'ember-light-table';
import { dateDisplay } from 'case-status/helpers/date-display';
import { capitalize } from '@ember/string';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default Component.extend({
	currentUser: service('current-user'),
	company: service(),

	columns: computed(function () {
		const currentUserFirm = this.get('currentUser.user.firm');
		return [
			{
				label: 'Name',
				valuePath: 'name',
				width: '250px',
				cellClassNames: 'bold',
				sortable: true,
			},
			{
				label: null,
				valuePath: 'optOut',
				sortable: false,
				cellComponent: 'cell-client-opt-out',
				width: '16px',
			},
			{
				label: 'Phone Number',
				valuePath: 'cellPhone',
				sortable: false,
			},
			{
				label: 'Email',
				valuePath: 'emailAddress',
				sortable: false,
			},
			{
				label: 'Last Login',
				valuePath: 'lastOpened',
				format: function (value) {
					if (value) {
						return dateDisplay([value, currentUserFirm, true]);
					} else {
						return 'No Date';
					}
				},
				align: 'left',
				sortable: true,
			},
			{
				label: `Open ${capitalize(dynamicCaseLabel([this.company, true]))}`,
				valuePath: 'openCaseIds',
				cellComponent: 'client-open-cases-cell',
				align: 'right',
				width: '220px',
				sortable: false,
			} /* {
      label: `All ${capitalize(dynamicCaseLabel([this.company, true]))}`,
      valuePath: 'allCases',
      cellComponent: 'client-cases-cell',
      align: 'right',
      width: '220px',
      sortable: false
    } */,
		];
	}),

	model: null,
	table: null,

	init() {
		this._super(...arguments);

		let table = Table.create({
			columns: this.columns,
			rows: this.model,
			enableSync: true,
		});
		let sortColumn = table.get('allColumns').findBy('valuePath', 'name');

		// Setup initial sort column
		if (sortColumn) {
			sortColumn.set('sorted', true);
			sortColumn.set('ascending', true);
		}

		this.set('table', table);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			let scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});
	},

	actions: {
		rowClicked(row, event) {
			// Don't process row click if clicked cell has a checkbox
			if (event.target.className.includes('client-cases-cell')) {
				return this.clientCasesClicked(row.content, true);
			} else if (event.target.className.includes('client-open-cases-cell')) {
				return this.clientCasesClicked(row.content, false);
			}

			if (event.target.children[0]) {
				if (
					event.target.children[0].children[0].className &&
					event.target.children[0].children[0].className.includes(
						'client-cases-cell',
					)
				) {
					return this.clientCasesClicked(row.content, true);
				} else if (
					event.target.children[0].children[0].className &&
					event.target.children[0].children[0].className.includes(
						'client-open-cases-cell',
					)
				) {
					return this.clientCasesClicked(row.content, false);
				}

				if (event.target.children[0].children[0]) {
					if (
						event.target.children[0].children[0].className &&
						event.target.children[0].children[0].className.includes(
							'client-cases-cell',
						)
					) {
						return this.clientCasesClicked(row.content, true);
					} else if (
						event.target.children[0].children[0].className &&
						event.target.children[0].children[0].className.includes(
							'client-open-cases-cell',
						)
					) {
						return this.clientCasesClicked(row.content, false);
					}
				}
			}
			this.clientClicked(row.content.id);
		},
		onColumnClick(column) {
			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				this.updateSort(columnName, direction);
			}
		},

		onScrolledToBottom() {
			this.fetchMoreClients();
		},
	},
});
