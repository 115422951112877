/** @format */

import Table from 'ember-light-table';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { attorneyDisplay } from '../helpers/attorney-display';
import { dynamicCaseLabel } from '../helpers/dynamic-case-label';
import { capitalize } from '@ember/string';

export default Component.extend({
	didUpdateAttrs() {
		this._super(...arguments);
		this.table.setRows(this.model || []);
	},
	currentUser: service('current-user'),
	company: service(),
	columns: computed(function () {
		return [
			{
				label: `${capitalize(dynamicCaseLabel([this.company]))}`,
				width: '110px',
				cellClassNames: 'light ellipsis',
				valuePath: 'case',
				cellComponent: 'firm-insights-case-cell',
			},
			{
				label: 'Client',
				width: '140px',
				valuePath: 'client',
				cellComponent: 'firm-insights-client-cell',
			},
			{
				label: `Primary ${attorneyDisplay([
					this.get('currentUser.user.firm'),
				])}`,
				width: '175px',
				valuePath: 'case',
				cellComponent: 'firm-insights-attorney-cell',
			},
			{
				label: 'Recent Stage',
				width: '175px',
				valuePath: 'case',
				cellComponent: 'firm-insights-recent-stage-cell',
			},
			{
				label: 'Last Feedback',
				width: '175px',
				valuePath: 'lastFeedback',
				cellComponent: 'firm-insights-last-feedback-cell',
			},
			{
				label: 'NPS',
				cellClassNames: 'bold',
				valuePath: 'mostRecentScore',
				cellComponent: 'firm-insights-last-feedback-score-cell',
			},
		];
	}),

	table: null,

	init() {
		this._super(arguments);

		this.set(
			'table',
			Table.create({
				columns: this.columns,
				rows: this.model,
				enableSync: true,
			}),
			{ async: true },
		);
	},
	actions: {
		scoreClicked(score) {
			const scoreClicked = this.scoreClicked;

			scoreClicked(score.get('case.id'));
		},
		onColumnClick(column) {
			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				if (
					column.label ===
					`Primary ${attorneyDisplay([this.get('currentUser.user.firm')])}`
				) {
					columnName = 'attorney.name';
				}

				if (column.label === 'Recent Stage') {
					columnName = 'recent_stage';
				}

				if (column.label === 'NPS') {
					columnName = 'nps_score';
				}

				this.updateSort(columnName, direction);
			}
		},
	},
});
