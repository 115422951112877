/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { next } from '@ember/runloop';
import { formatObjectAsSearchParams } from 'case-status/helpers/qs-params';

@classic
export default class CaseScheduledMessagesComponent extends Component {
	@service currentUser;
	@service inViewport;
	@service ajax;
	@service store;

	@tracked scheduledMessages = A([]);
	@tracked loaderInViewPort = false;
	@tracked isLoading = true;
	@tracked meta = { total_pages: 0 };
	@tracked page = 1;
	size = 20;
	@tracked isLoadingNew = true;
	scrollableAreaOverride;

	get showLoader() {
		return this.isLoading && this.loaderInViewPort;
	}

	didEnterViewPort() {
		if (this.theCase.get('unsentScheduledMessageCount')) {
			this.loaderInViewPort = true;
			if (this.page || this.meta.total_pages) {
				this.fetchMoreScheduledMessages();
			}
		}
	}

	didExitViewPort() {
		this.loaderInViewPort = false;
	}

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreScheduledMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreScheduledMessages();
			}
		}, 500);
	}

	fetchNewScheduledMessages() {
		//* Set loading state to true
		this.isLoading = true;
		this.isLoadingNew = true;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		this.queryParams.page.size = this.size;

		//* Increment the page number
		this.page = 1;

		//* Set the page number on the queryParams object
		this.queryParams.page.number = this.page;

		//* Structure the query string for the url
		const queryString = formatObjectAsSearchParams(this.queryParams);

		//* Clear the current list of case messages
		this.scheduledMessages.clear();

		//* Make query request
		return this.ajax
			.request(
				`cases/${this?.theCase?.get('id')}/scheduled_messages?${queryString}`,
			)
			.then((res) => {
				//* Since we couldn't use the ember store to query this, we must push the payload to the data store manually
				this.store.pushPayload(res);

				const scheduledMessages = res.data.map((msg) => {
					return this.store.peekRecord('message', msg.id);
				});

				//* Update the shouldUpdate trigger
				this.shouldUpdate = false;

				//* Update meta data
				this.meta = res.meta;

				//* Clear old messages
				this.scheduledMessages.clear();

				//* Push in new messages
				this.scheduledMessages.pushObjects(scheduledMessages);

				//* Set isLoadingNew to false
				this.isLoadingNew = false;

				this.set('errorFetchingMessages', false);
			})
			.catch(() => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
				});
			});
	}

	fetchMoreScheduledMessages() {
		if (!this.meta.total_pages || this.page + 1 > this.meta.total_pages) return;
		//* Set loading state to true
		this.isLoading = true;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		this.queryParams.page.size = this.size;

		//* Increment the page number
		this.page = this.page + 1;

		//* Set the page number on the queryParams object
		this.queryParams.page.number = this.page;

		//* Structure the query string for the url
		const queryString = formatObjectAsSearchParams(this.queryParams);

		//* Make query request
		return this.ajax
			.request(
				`cases/${this?.theCase?.get('id')}/scheduled_messages?${queryString}`,
			)
			.then((res) => {
				//* Update meta data
				this.meta = res.meta;

				this.store.pushPayload(res);

				const scheduledMessages = res.data.map((msg) => {
					return this.store.peekRecord('message', msg.id);
				});

				//* Push in new messages
				this.scheduledMessages.pushObjects(scheduledMessages);
				this.set('errorFetchingMessages', false);
			})
			.catch((err) => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
				});
			});
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		this.setupInViewport();

		this.loaderInViewPort = true;

		if (this?.theCase?.get('id')) {
			this.fetchNewScheduledMessages();
		}
	}

	willDestroy() {
		// need to manage cache yourself if you don't use the mixin
		const loader = document.getElementById('loader');
		this.inViewport.stopWatching(loader);

		//? Maybe unload messages from the store to save user on resources?

		super.willDestroy(...arguments);
	}

	@action
	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
			scrollableArea: this.scrollableAreaOverride,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	}
}
