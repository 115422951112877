/** @format */

import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { observer, computed } from '@ember/object';
import moment from 'moment';
import Errors from '../../../../constants/errors';
import RSVP from 'rsvp';
import timezones from '../../../../constants/timezones';

export default Controller.extend({
	stripev3: service(),
	timezones: timezones,

	init() {
		this._super(...arguments);

		this.set('stripeOptions', {
			onBlur: true,
			style: {
				base: {
					color: '#333',
				},
			},
		});
	},

	// Observer to set the time values in 24-hour glued time from
	// the midnights from midnight units we get from the backend
	setTimes: observer(
		'model.firm.hoursStart',
		'model.firm.hoursEnd',
		function () {
			const hoursEnd = Number(this.get('model.firm.hoursEnd'));
			const hoursStart = Number(this.get('model.firm.hoursStart'));

			// Create a 24-hour glued time using moment
			let endGluedTime = moment('00:00', 'HH:mm')
				.add(hoursEnd, 'm')
				.format('HHmm');
			let startGluedTime = moment('00:00', 'HH:mm')
				.add(hoursStart, 'm')
				.format('HHmm');

			// Now we have a glued time for start and end hours, so we set those here
			// This is the value that will be passed to the time inputs. We'll use
			// these to set the model.firm on the save action
			this.set('hoursEnd', endGluedTime);
			this.set('hoursStart', startGluedTime);
		},
	),

	enabledOutsideHoursAutomation: false,
	hoursErrors: null,
	hoursSuccess: null,
	currentUser: service(),
	notifications: service(),
	hasStripeIntegration: alias('currentUser.user.firm.hasStripeIntegration'),
	selectedTimezone: computed('model.firm.timezone', 'timezones', function () {
		const firmTimezone = this.get('model.firm.timezone');
		const timezones = this.timezones;

		return (
			timezones.find((timezone) => {
				return timezone.value == firmTimezone;
			}) || null
		);
	}),

	actions: {
		submitStripe(stripeElement) {
			const results = RSVP.defer();
			const action = this.hasStripeIntegration ? 'PATCH' : 'POST';
			let stripe = this.stripev3;

			this.set('stripeIntegrationSuccess', false);
			this.set('stripIntegrationErrors', null);

			return stripe
				.createToken(stripeElement)
				.then(({ token }) => {
					stripeElement.clear();
					if (token && token['id']) {
						this.set('token', token['id']);
						// CREATE CUSTOMER
						this.send('updateStripeIntegration', this.token, action, results);
						return results.promise
							.then(() => {
								this.set('stripeIntegrationSuccess', true);
								this.set('currentUser.user.firm.hasStripeIntegration', true);
							})
							.catch(({ payload }) => {
								// Format errors
								const formattedErrors = payload.errors.map((e) => e.detail);
								this.set('stripeIntegrationErrors', formattedErrors);
							});
					} else {
						this.set('stripeIntegrationErrors', [
							'Error with card information! Please try again',
						]);
					}
				})
				.catch(() => {
					this.set('stripeIntegrationErrors', [
						'Error connecting with Stripe! Please try again',
					]);
				});
		},

		saveFirmHours() {
			// Reset success and error messages
			this.set('hoursErrors', null);
			this.set('hoursSuccess', null);

			const hoursEnd = this.hoursEnd;
			const hoursStart = this.hoursStart;

			// Set moment values for midnight and the start/end hours that we'll use to calculate duration
			const midnight = moment('00:00', 'HH:mm');
			const end = moment(hoursEnd, 'Hmm');
			const start = moment(hoursStart, 'Hmm');

			// Now we take the duration from midnight in minutes of the start and end hours
			const mfmEnd = moment.duration(end.diff(midnight)).as('minutes');
			const mfmStart = moment.duration(start.diff(midnight)).as('minutes');

			// Now that we have the time in minutes from midnight, we set
			// it on the model.firm and then save the model.firm
			let firm = this.model.firm;
			firm.set('hoursEnd', mfmEnd);
			firm.set('hoursStart', mfmStart);

			return firm
				.save()
				.then(() => {
					this.set(
						'hoursSuccess',
						"Your firm's business hours have been updated.",
					);
				})
				.catch((response) => {
					this.set('hoursErrors', Errors.mapResponseErrors(response));
				});
		},

		toggleOutsideHoursAutomation(value) {
			this.set('model.firm.autoResponderEnabled', value);
		},

		selectTimezone(newTimezone) {
			if (!newTimezone.value) return;
			this.set('model.firm.timezone', newTimezone.value);
		},
	},
});
