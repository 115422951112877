/** @format */

import { defer } from 'rsvp';
import { computed, observer } from '@ember/object';
import Component from '@ember/component';
import { link, templates } from '../constants/pell-extensions';
import { inject as service } from '@ember/service';
import { debounce, next } from '@ember/runloop';
import ENV from 'case-status/config/environment';
import { resolve, reject } from 'rsvp';
import Errors from 'case-status/constants/errors';
import $ from 'jquery';
import moment from 'moment';

export default Component.extend({
	store: service(),
	shortcode: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),

	init() {
		this._super(...arguments);

		const pellOptions = {
			actions: ['bold', 'italic', link],
		};

		if (this.messageTemplates) {
			pellOptions.actions.push(templates);
		}

		this.set('pellOptions', pellOptions);
	},

	formattedScheduleDate: null,

	messagePreview: '',
	valueObserver: observer('value', 'showMessagePreview', function () {
		if (!this.showMessagePreview) return false; //* Prevent unnecessary api calls

		debounce(this, 'getMessagePreview', 500);
	}),

	getMessagePreview() {
		const val = this.value;
		const caseId = this.caseId || this.theCase.get('id');

		this.shortcode
			.preview(val, caseId)
			.then((res) => {
				this.set('messagePreview', res.data?.formatted_message);
			})
			.catch(() => {
				this.set(
					'messagePreview',
					`<i class="red-text">An error has occurred.</i>`,
				);
			});
	},

	tooltipText: computed(
		'sendingMessage',
		'messageTargetLanguage.code',
		'value',
		'translatedMessage',
		'currentUser.user.firm.messagingOff',
		function () {
			if (this.sendingMessage) {
				if (this.currentUser.user.firm?.messagingOff) {
					return 'Messaging is disabled for your firm. Reach out to support to have it enabled';
				}

				if (!this.value) {
					return 'Message required';
				}
			}

			return null;
		},
	),

	disablePrimaryButton: computed(
		'sendingMessage',
		'messageTargetLanguage.code',
		'value',
		'translatedMessage',
		'currentUser.user.firm.messagingOff',
		function () {
			if (this.sendingMessage) {
				// No value, no send
				if (!this.value) {
					return true;
				}

				return !!this.currentUser.user.firm?.messagingOff;
			}

			// default
			return false;
		},
	),

	didRender() {
		if (this.focusOnRender && !this.wasFocused) {
			const $el = this.$('.pell-content');
			$el.focus();

			this.set('wasFocused', true);
		}
	},

	characterCounter: computed('value', function () {
		let value = this.get('value.length');
		if (isNaN(value)) {
			value = 0;
		}
		return this.maxLength - value;
	}),

	showMessagePreview: false,

	actions: {
		handleRecommendedResponseUsed(recommendedResponseUsed) {
			this.set('recommendedResponseUsed', recommendedResponseUsed);
			this.handleRecommendedResponseCaseThread(recommendedResponseUsed);
			this.set('shouldShowComponents', true);
		},

		showFilePicker() {
			$('#file_picker').click();
		},

		attachFile(e) {
			const fd = new FormData();
			const file = e.target.files[0];
			fd.append('file', file, file.name);
			this.set('newMessageFile.filename', file.name);
			this.set('newMessageFile.fd', fd);
			this.set('error', null);
		},

		removeFile() {
			const fd = this.newMessageFile.fd;
			if (fd) {
				fd.delete('file');
			}
			this.set('newMessageFile', {
				filename: null,
				fd: null,
				file: null,
				fileId: null,
				id: null,
			});
			this.set('error', null);
			$('#file_picker').prop('value', '');
		},

		buttonAction() {
			this.primaryAction();
		},

		handleClearSchedule() {
			this.set('formattedScheduleDate', null);
		},

		handleSchedule(scheduleDate) {
			this.set('formattedScheduleDate', scheduleDate);
		},

		sendAction() {
			const result = defer();
			const originalContent = this?.value;
			const translatedContent = this?.translatedMessage;
			const targetLanguage = this?.messageTargetLanguage?.code;
			const recommendedResponseUsed = this?.recommendedResponseUsed;
			const formattedScheduleDate = this.formattedScheduleDate;
			const tz = this.get('currentUser.user.firm.timezone');
			const now = moment(new Date()).tz(tz, true);

			this.set('errors', []);

			if (formattedScheduleDate && formattedScheduleDate.utc().isBefore(now)) {
				this.set('errors', ['Chose a date in the future.']);

				next(() => {
					result.reject();
				});

				return result.promise;
			}

			/*  
        Due to normal messaging creates a message model, 
        the date typed attribute scheduledMessageDate needs 
        to be a date else it will make it null, but since 
        bulk schedule can have a file and thus be a multipart 
        request using FormData, the date isn't auto processed 
        into an iso format for the API so we have to make that 
        distinction.
       */
			const preparedDate = formattedScheduleDate?.utc()?.toDate();
			const day = this.isBulkAction
				? preparedDate?.toISOString()
				: preparedDate;

			this.primaryAction(
				{
					result,
					originalContent,
					translatedContent,
					targetLanguage,
					recommendedResponseUsed,
				},
				Boolean(this.translatedContentEdited),
				formattedScheduleDate ? day : null,
			);

			return result.promise;
		},

		triggerScheduleModal() {
			const triggerScheduleModal = this.triggerScheduleModal;

			triggerScheduleModal();
		},

		toggleShowMessagePreview() {
			this.toggleProperty('showMessagePreview');
		},

		async translateMessage() {
			this.send('resetTranslation');

			const input = this?.value;
			const target_language = this?.messageTargetLanguage?.language;
			const caseId = this.caseId;

			const data = {
				input,
				target_language,
				case_id: caseId,
			};

			if (!caseId) {
				delete data.case_id;
			}

			try {
				const translatedMessageResponse = await this.ajax.post(
					`${ENV.host}/translate`,
					{
						data,
					},
				);

				const translatedMessage = translatedMessageResponse.translated_text;
				this.set('translatedMessage', translatedMessage);
				this.set('showTranslatedPreview', true);
				this.set('showMessagePreview', true);
			} catch (error) {
				this.notifications.error(Errors.mapResponseErrors(error), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});
				reject();
			}

			resolve();
		},

		resetTranslation() {
			this.set('translatedMessage', null);
			this.set('showTranslatedPreview', false);
			this.set('showMessagePreview', false);
			this.set('translatedContentEdited', false);
		},

		cancelInput() {
			this.send('resetTranslation');
			if (this.cancel && typeof this.cancel == 'function') {
				this.cancel();
			}
		},

		templateCallback(...args) {
			this.send('resetTranslation');

			if (
				this.templateCallback &&
				typeof this.templateCallback === 'function'
			) {
				this.templateCallback(...args);
			}
		},

		continueEditingOriginal() {
			this.set('showEditedTranslatedContentWarning', false);
			this.set('isEditingTranslatedContent', false);
		},

		editTranslatedContent() {
			this.set('isEditingTranslatedContent', true);
			this.set('translatedContentEdited', true);
		},

		setDate(date) {
			this.set('day', date);
			return date;
		},
	},
});
