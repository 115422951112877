/** @format */

export const DEFAULT_ADMIN_PERMISSIONS = {
	user_permissions: {
		can_update_case_status: true,
		bulk_actions: true,
		can_delete_case: true,
		can_delete_documents: true,
		collaboration_tab: true,
		create_case: true,
		has_app_access: true,
		has_web_app_access: true,
		settings_firm: true,
		settings_integrations: true,
		settings_message_templates: true,
		settings_practice_areas: true,
		settings_user_account: true,
		settings_user_management: false,
		update_all_users: false,
		upload_csv: true,
		view_all_cases: true,
		view_clients_tab: true,
		view_firm_insights: true,
		view_invoices_tab: true,
	},
};

export const DEFAULT_CLIENT_PERMISSIONS = {
	user_permissions: {
		can_update_case_status: false,
		bulk_actions: false,
		can_delete_case: false,
		can_delete_documents: false,
		collaboration_tab: false,
		create_case: false,
		has_app_access: true,
		has_web_app_access: true,
		settings_firm: false,
		settings_integrations: false,
		settings_message_templates: false,
		settings_practice_areas: false,
		settings_user_account: true,
		settings_user_management: false,
		update_all_users: false,
		upload_csv: false,
		view_all_cases: false,
		view_clients_tab: false,
		view_firm_insights: false,
		view_invoices_tab: false,
	},
};

export const DEFAULT_MEMBER_PERMISSIONS = {
	user_permissions: {
		can_update_case_status: false,
		bulk_actions: false,
		can_delete_case: false,
		can_delete_documents: false,
		collaboration_tab: true,
		create_case: false,
		has_app_access: false,
		has_web_app_access: true,
		settings_firm: false,
		settings_integrations: false,
		settings_message_templates: false,
		settings_practice_areas: false,
		settings_user_account: true,
		settings_user_management: false,
		update_all_users: false,
		upload_csv: false,
		view_all_cases: false,
		view_clients_tab: false,
		view_firm_insights: false,
		view_invoices_tab: false,
	},
};

export const DEFAULT_FALLBACK_PERMISSIONS = {
	user_permissions: {
		can_update_case_status: false,
		bulk_actions: false,
		can_delete_case: false,
		can_delete_documents: false,
		collaboration_tab: false,
		create_case: false,
		has_app_access: false,
		has_web_app_access: true,
		settings_firm: false,
		settings_integrations: false,
		settings_message_templates: false,
		settings_practice_areas: false,
		settings_user_account: false,
		settings_user_management: false,
		update_all_users: false,
		upload_csv: false,
		view_all_cases: false,
		view_clients_tab: false,
		view_firm_insights: false,
		view_invoices_tab: false,
	},
};
