/** @format */

import PellEditor from 'ember-pell/components/pell-editor';
import { observer } from '@ember/object';

export default PellEditor.extend({
	pasteObserver: observer('pell', function () {
		const editor = this.pell;

		editor.addEventListener('paste', function (e) {
			e.stopPropagation();
			e.preventDefault();
			const clipboardData = e.clipboardData || window.clipboardData;
			const clipboardText = clipboardData.getData('text');
			document.execCommand('insertHTML', false, clipboardText);
			return true;
		});
	}),
});
